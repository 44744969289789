
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  CALCULATE_USAGE,
  VERIFY_AND_UPGRADE_PLAN,
  VERIFY_BILLING_ACCOUNT,
  VERIFY_PAYMENT,
} from "./server-urls";
import { toast } from "react-toastify";
import myfunction from "./data";
import AppLayout from "../../components/layouts/AppLayout";
import React, { useEffect, useState } from "react";
import Profile from "./profile";
import BillingInfo from "./billingInfo";
import Usage from "./usage";
import AccessManagement from "./accessManagement";
import useAuthStore from "../../store/authStore";
interface entSub {
  ADMIN: boolean;
  MEMBER: boolean;
}
interface ent {
  MAIN: boolean;
  SUB: entSub;
}
export interface userTypeProps {
  ENT: ent;
  OND: boolean;
}

const Admin: React.FC = () => {
  let [active, setActive] = useState(0);
  let [success, setSuccess] = useState<any>(null);
  let { search } = useLocation();
  // let { user, setUsageInMb, userType } = useCurrentUser();
  let { user, setUsageInMb, userType } = myfunction();

  const auth = useAuthStore();

  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const paramField1 = query.get("create-billing-acc");
  const paramField2 = query.get("session_id");
  const paramField3 = query.get("pay_session_id");
  const paramField4 = query.get("upgrade");
  let [billingInfo, setBillingInfo] = useState<any>(null);

  const [msg, setMsg] = useState("");

  useEffect(() => {
    console.log(paramField1, paramField2, paramField3, paramField4);

    if (paramField4) setActive(parseInt(paramField4));
    if (paramField1) {
      setActive(parseInt(paramField1));
    }
    if (paramField2) {
      axios
        .post(VERIFY_PAYMENT, { sessionId: paramField2, userId: user?.userId })
        .then((res) => {
          if (res.data.ok) {
            setSuccess(1);
            toast(res.data.msg, { type: "success" });
          } else {
            setSuccess(0);
            toast(res.data.msg, { type: "info" });
          }
          setMsg(res.data.msg);
          // history.push("/app/admin?success=0");
          setActive(1);
        })
        .catch(console.error);
    }
    if (paramField3) {
      axios
        .post(VERIFY_AND_UPGRADE_PLAN, {
          sessionId: paramField3,
          userId: user?.userId,
        })
        .then((res) => {
          if (res.data.result) {
            setSuccess(1);
            toast(res.data.msg, { type: "success" });
          } else {
            setSuccess(0);
            toast(res.data.msg, { type: "info" });
          }
          setMsg(res.data.msg);
          // history.push("/app/admin?success=0");
          setActive(1);
        })
        .catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramField1, paramField4]);

  useEffect(() => {
    if (billingInfo) {
      axios
        .post("CALCULATE_USAGE", { userId: user?.userId })
        .then((res) => {
          setUsageInMb([
            Number((res.data.totalSize + "").slice(0, 5)),
            billingInfo.dataLimit,
          ]);
        })
        .catch(console.error);
    }
    return () => { };
  }, [user?.userId, billingInfo]);
  console.log(useAuthStore().userDetails)
  useEffect(() => {
    axios
      .post("VERIFY_BILLING_ACCOUNT", {
        creatorUserId: user?.userId,
        createdBy: user?.createdBy,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setBillingInfo(res.data.result.data);
        }
      })
      .catch(console.error);
  }, []);

  return (
    <AppLayout>
      <div className="w-full">
        <div className="font-[550] mb-[2vw] text-[2.3vw]">
          Account Summary
        </div>
        <div className="-mx-[3vw]"
          style={{
            borderBottom: "0.15vw solid #c4c4c4",
          }}>
          <div className=" mx-[3vw] my-[0.7vw] flex justify-start space-x-[3.5vw] ">
            <p
              className="cursor-pointer text-[1.1vw] pb-[0.7vw] px-[1.5vw]"
              style={{
                color: `${active === 0 ? "#1BA94C" : "#000"}`,
                borderBottom: active === 0 ? "2.0px solid #1BA94C" : "none",
                transform: "translateY(0.8vw)"
              }}
              onClick={() => setActive(0)}
            >
              Usage
            </p>

            {(() => {
              if (auth.userDetails?.role === "ADMIN") {
                return (
                  <p
                    className="cursor-pointer text-[1.1vw] px-[1.5vw]"
                    style={{
                      color: `${active === 1 ? "#1BA94C" : "#000"}`,
                      borderBottom: active === 1 ? "2.0px solid #1BA94C" : "none",
                      transform: "translateY(0.8vw)"
                    }}
                    onClick={() => setActive(1)}
                  >
                    Billing Information
                  </p>
                );
              }
            })()}
            <p
              className="cursor-pointer text-[1.1vw] pb-[0.7vw] px-[1.5vw]"
              style={{
                color: `${active === 2 ? "#1BA94C" : "#000"}`,
                borderBottom: active === 2 ? "2.0px solid #1BA94C" : "none",
                transform: "translateY(0.8vw)"
              }}
              onClick={() => setActive(2)}
            >
              Your Profile
            </p>

            {(() => {
              if (auth.userDetails?.role === "ADMIN") {
                return (
                  <p
                    className="cursor-pointer text-[1.1vw] pb-[0.7vw] px-[1.5vw]"
                    style={{
                      color: `${active === 3 ? "#1BA94C" : "#000"}`,
                      borderBottom: active === 3 ? "2.0px solid #1BA94C" : "none",
                      transform: "translateY(0.8vw)"
                    }}
                    onClick={() => setActive(3)}
                  >
                    Access Management
                  </p>
                );
              }
            })()}
          </div>
        </div>

        <div className="py-[2vw]">
          {active === 0 && <Usage />}
          {active === 1 && <BillingInfo />}
          {active === 2 && <Profile />}
          {active === 3 && <AccessManagement />}
        </div>
      </div>
    </AppLayout >
  );
};
export default Admin;
