import { over } from 'lodash';
import React,{useEffect,useState} from 'react';
import Chart from 'react-apexcharts';
import useWorkflowStore from '../../store/useWorkflowStore';
import Axios from '../../utils/Axios';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { batch } from 'react-redux';
// import { d } from 'msw/lib/glossary-36cf4e2d';

interface HeatmapProps{
    // series: any,
    // options:any
    stage:number,
    batchNumber:number,
    seriesData:[],
    options:any,
    loading:boolean
}

const Heatmap:React.FC<HeatmapProps> = ({stage,batchNumber,seriesData,loading,options})=> {
    
    // console.log("=== Loading  ===",loading)
     //console.log("=== Series Data  ===",seriesData);
    seriesData && seriesData.sort((s1:any,s2:any)=>s1.name>s2.name?1:-1);

      seriesData && seriesData.forEach((s:any)=>{
          s.data.sort((d1:any,d2:any)=>d1.x>d2.x?1:-1);
      })
    const heatmapOptions = {

      responsive:[
        {
            breakpoint:2600,
            options:{
                chart:{
                    width:1400,
                    height:550
                }
            },
          
        },
        {
            breakpoint:2000,
            options:{
                chart:{
                    width:1200,
                    height:500
                }
            },
          
        },
        {
            breakpoint:1700,
            options:{
                chart:{
                    width:900,
                    height:450
                }
            },
          
        },
        {
            breakpoint:1370,
            options:{
                chart:{
                    width:700,
                    height:350
                }
            },
          
        },
          {
              breakpoint:1000,
              options:{
                  chart:{
                      width:600,
                      height:300
                  }
              },
            
          },
         
       
         

      ],
      dataLabels:{
        enabled:true
      },
      legend:{
          show:false
      },
        plotOptions:{
            
            heatmap:{
                
   
                colorScale:{
                    ranges:[
                        {
                            from:0,
                            to:5,
                            color:"#8f9bc2", //"#9da6c4",
                            name:"less than 5% overlap"
                            
                        },
                        {
                            from:5,
                            to:100,
                            color:"#11256D",
                            name:"overlap percentage"
                        }
                    ]
                },
              
               
            },
        },
       colors: ['#11256D'],
      xaxis: {
          //type:"category",
          
          position: 'top',
          // offsetY:'20px',
          // offsetX:'50px'
      },
      yaxis:{
          reversed:true
      },
 
    
      }
     

  return (
  <>
            {loading? <div data-testid="loading" className=' h-full flex justify-center items-center p-[1.2vw] '>
                  <AiOutlineLoading3Quarters size={40} color={"gray"} className=" animate-spin" />
                </div>
                :(
                <div className='w-full items-center flex flex-col p-7  '>
                <div className="w-full text-[1.1vw] text-[#1BA94C] font-bold  mx-5">Heatmap Overlap Statistics</div>
                {seriesData && seriesData.length>0 && <Chart options={heatmapOptions} width={700} height={350}  type={"heatmap"} series={seriesData} />}
                </div>
                )
                }
  </>
  
  )
}

export default Heatmap;
