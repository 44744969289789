
import * as React from "react";
import useWorkflowStore from "../../../../store/useWorkflowStore";
import saveAs from "file-saver";
export interface APIDocumentationProps {}


const FtAPIDocumentation: React.SFC<APIDocumentationProps> = () => {

 const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
 const predictionUrl = process.env.REACT_APP_PREDICTION_URL

  return (
    <div style={{ width: "95%" }}>
      <div>
        <h1 className="font-medium text-[1.8vw] mb-[1vw]">DataNeuron Prediction API</h1>
        <p className="text-[1vw] my-[0.8vw]">
          Welcome to the DataNeuron Prediction API Documentation! This page will
          guide you through some general information about the terminology and
          about formatting and authenticating requests. You can use the menu on
          the left to jump to the documentation of the endpoints directly.
        </p>
      </div>

  
      <div className="text-[1vw]">
        <span
          className="font-medium text-[1.4vw] pb-[0.3vw] border-b border-black"
          >
          API Endpoints
        </span>
        <p className="font-medium my-[0.8vw]">
          <span style={{ color: "#FBBB24" }} className="mr-3">
            POST
          </span>
          Inititaing a Prediction Task
        </p>
        <p
          className="p-3 text-[1vw] border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "400px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          {`http://${predictionUrl}/prediction_API`}
        </p>
        <p className="mb-5">
          This API endpoint lets you initiate a prediction task on your input
          data.
        </p>
        <div>
          <h3 className="font-medium mb-5">Headers:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>x-access-token: valid API token</p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Inputs:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
               workflowId: valid workflow ID ( <span className="font-semibold italic">{currentWorkflow?.workflowId}</span> ), corresponding to the
                model to be used for prediction.
              </p>
            </li>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                data: input data on which prediction is to be performed in the
                form of a dictionary (<span>&#123;</span>id: paraText
                <span>&#125;</span>).
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Returns:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                Task id for your initiated task, to be saved and later used for
                checking the status and extracting results.
              </p>
            </li>
          </ul>
        </div>
        <p className="text-md mb-5">
          <span className="font-medium text-xl mr-2">BODY</span>raw
        </p>
        <div
          className="p-3 text-[1vw] border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "100%",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            <span>&#123;</span>
          </p>
          <p className="ml-10" style={{ color: "#38A700" }}>
            "workflow": '{currentWorkflow?.workflowId}',
          </p>
          <p className="ml-10" style={{ color: "#38A700" }}>
            "data": <span>&#123;</span>"id_1": "paraText_1", "id_2":
            "paraText_2", "id_3":"paraText_3"<span>&#125;</span>
          </p>
          <p>
            <span>&#125;</span>
          </p>
        </div>
        <p className="font-medium">
          Example Request- Initiating a Prediction Task
        </p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            <p>
              curl --location --request{" "}
              <span style={{ color: "#FBBB24" }}>POST</span>{" "}
              <span style={{ color: "#38A700" }}>
                {`http://${predictionUrl}/prediction_API`}
              </span>{" "}
              \
            </p>
            <p>
              --header{" "}
              <span style={{ color: "#38A700" }}>
                'x-access-token: API token'
              </span>
              \
            </p>
            <p>
              --data-raw '<span className="text-white">&#123;</span>
            </p>
            <p>
              <span style={{ color: "#38A700" }}>
                "workflow": '{currentWorkflow?.workflowId}',
              </span>
            </p>
            <p>
              <span style={{ color: "#38A700" }}>
                "data": <span className="text-white">&#123;</span>"id_1":
                "paraText_1", "id_2": "paraText_2", "id_3":"paraText_3"
                <span className="text-white">&#125;</span>
              </span>
            </p>
            <span className="text-white">&#125;</span>'
          </p>
        </div>
        <p className="font-medium">Example Response - Body (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "500px",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            Content-Type: text/html;{" "}
            <span style={{ color: "#FBBB24" }}>charset=utf-8</span>
          </p>
          <p>
            Content-Length: <span style={{ color: "#FBBB24" }}>36 </span>
          </p>
          <p>
            Server:{" "}
            <span style={{ color: "#FBBB24" }}>
              Werkzeug/1.0.1 Python/3.6.9
            </span>
          </p>
          <p>
            Date:{" "}
            <span style={{ color: "#FBBB24" }}>
              Wed, 30 Jun 2021 08:52:51 GMT
            </span>
          </p>
        </div>
        <p className="font-medium">Example Response - Header (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "500px",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>04f243f0-350a-42d5-bb3c-9c7fb5a2584c</p>
        </div>
        <p className="font-medium">
          <span className="mr-3" style={{ color: "#38A700" }}>
            GET
          </span>
          Check Status and Fetch Results
        </p>
        <p
          className="p-3 text-[1vw] border-2 mb-5"
          style={{
            background: "#EFEFEF",
            width: "570px",
            fontWeight: 100,
            fontFamily: "Roboto mono",
          }}
        >
         {` http://${predictionUrl}/prediction_results`}/&lt;task_id&gt;
        </p>
        <p className="mb-5">
          This API endpoint enables you to check the status of your prediction
          task and extract the predicted results.
        </p>
        <div>
          <h3 className="font-medium mb-5">Headers:</h3>
          <ul>
            <li className="flex mb-3 items-center">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>x-access-token: valid API token</p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Inputs:</h3>
          <ul>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                task_id: The task id of the initiated prediction process,
                returned by the API endpoint when initiating prediction.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-5">Returns:</h3>
          <ul>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                state: The current status of the prediction task
                (PENDING/SUCCESS/FAILURE). Ensure that the correct task id is
                passed as input.
              </p>
            </li>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                result: A short description of the current state, in case of
                FAILURE it describes the reason for failure.
              </p>
            </li>
            <li className="flex mb-3">
              <div
                className="mx-3 my-3"
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                }}
              ></div>
              <p>
                predicted_results: The predicted results in the form of a
                dictionary (<span>&#123;</span>id: predicted_result
                <span>&#125;</span>). Its value is null in case of PENDING or
                FAILURE state.
              </p>
            </li>
          </ul>
        </div>
        <p className="font-medium">
          Example Request - Check Status and Fetch Results
        </p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            curl --location --request{" "}
            <span style={{ color: "#FBBB24" }}>GET</span>{" "}
            <span style={{ color: "#38A700" }}>
              {`http://${predictionUrl}/prediction_results/`}
              &lt;task_id&gt;{" "}
            </span>{" "}
            \
          </p>
          <p>
            --header{" "}
            <span style={{ color: "#38A700" }}>
              'x-access-token: API token'
            </span>
            \
          </p>
          <p>--data-raw </p>
        </div>
        <p className="font-medium">Example Response - Body (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            <span>&#123;</span>
          </p>
          <p className="ml-8">
            <span style={{ color: "#38A700" }}>"predicted_results":</span>{" "}
            <span>&#123;</span>
          </p>
          <p className="ml-14" style={{ color: "#38A700" }}>
            {" "}
            "id_1": "Issue_1",
          </p>
          <p className="ml-14" style={{ color: "#38A700" }}>
            {" "}
            "id_2": "Issue_2",
          </p>
          <p className="ml-14" style={{ color: "#38A700" }}>
            {" "}
            "id_3": "Issue_3"
          </p>
          <p className="ml-8">
            <span>&#125;</span>,
          </p>
          <p className="ml-8" style={{ color: "#38A700" }}>
            "result": "Prediction success",
          </p>
          <p className="ml-8" style={{ color: "#38A700" }}>
            "state": "SUCCESS"
          </p>
          <span>&#125;</span>
        </div>
        <p className="font-medium">Example Response - Header (200 OK)</p>
        <div
          className="p-3 border-2 mb-5 "
          style={{
            background: "#000",
            width: "100%",
            fontWeight: 100,
            color: "#EEEADE",
            fontFamily: "Roboto mono",
          }}
        >
          <p>
            Content-Type:{" "}
            <span style={{ color: "#FBBB24" }}> application/json</span>
          </p>
          <p>
            Content-Length: <span style={{ color: "#FBBB24" }}>206 </span>
          </p>
          <p>
            Server:{" "}
            <span style={{ color: "#FBBB24" }}>
              Werkzeug/1.0.1 Python/3.6.9
            </span>
          </p>
          <p>
            Date:{" "}
            <span style={{ color: "#FBBB24" }}>
              Wed, 30 Jun 2021 09:27:11 GMT
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FtAPIDocumentation;
