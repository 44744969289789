import { Divider, Modal } from "antd";
import { current } from "immer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContentProps } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import useAuthStore from "../../../store/authStore";
import useWorkflowStore from '../../../store/useWorkflowStore';
import { TrainStatus, checkCurrentTrainStatus } from "../../../utils/navigationHelper";
import ConfirmIterateModel from "./ConfirmIterateModel";



interface StageCardProps {
}

const LLM_StageCard: React.FC<StageCardProps> = () => {
 
  

  const navigator = useNavigate();
  const {currentWorkflow,currentProject} = useWorkflowStore();
  const userDetails = useAuthStore(state=>state.userDetails)
  const active = currentWorkflow?.stages.find(stage=>stage.name==="validation")?.active;
  const [iterateBoxOpen,setIterateBoxOpen] = useState<boolean>(false);
  const isCreator = currentProject?.createdBy === userDetails?.userId;

  const checkForRetrain = async()=>{

    if(!currentWorkflow) return;
    let shouldNavigate:boolean = true;
    let message = "";
      switch(checkCurrentTrainStatus(currentWorkflow)){



        case TrainStatus.NotTrained:
              //alert("You have not trained the model");
              break;
        case TrainStatus.InModelSelection:
              //alert("you are in model selection");
              message="please complete the model training first!"
              shouldNavigate =false;
              break;
        case TrainStatus.InModelTuning:
              //alert("you are in model tuning");
              message="please complete the model training first!"
              shouldNavigate=false;
              break;
        case TrainStatus.InTraining:
              //alert("You are in training right now");
              message="please complete the model training first!"
              shouldNavigate=false;
              break;
        case TrainStatus.Trained:
              shouldNavigate=false;
              //alert("You want to start iterate");
              isCreator ? setIterateBoxOpen(true)
                        :toast.warn("Please ask project admin to trigger iteration to access validation.");
              break;
        default:
            //alert("could not find anything")


      }
      if(message!== ""){
        toast.warn(message);
      }

      if(shouldNavigate){
        //alert("Navigating to validation page now");
        if(currentWorkflow.masterlistLocked){

          navigator("/workflow/validation?workflowId="+currentWorkflow?.workflowId);
        }else{
          toast.warn("Please trigger annotation first");
        }
                
      }
    


  }

  //console.log("=== active value in stage card ===",active)

  return (
    <div>
         <Modal
      footer={<ConfirmIterateModel
        callBatch={false}
        hasStages={false}
        resetStage={true}
        open={iterateBoxOpen}
        setOpen={setIterateBoxOpen} />}
      width={"45vw"}
      open={iterateBoxOpen}
      onCancel={()=>setIterateBoxOpen(false)}
      title={"Iterative training of the model triggered"}
      
      >
        <p >
        This will take the workflow back to the validation stage, 
where you can validate more paragraphs and retrigger the training process to get a new model.
Once you are in validation stage, you wont be able to access the previous training results.

        </p>
        <br/>
        <p className="">
        Your saved prediction API will continue to work.
        </p>
      </Modal>
    <div
      className="flex items-center w-full  shadow-lg"
      style={{
        width: "20vw",
        height: "15vw",
      }}      
      onClick={() => {
       
      }}
    >
      <div
        className="flex flex-col justify-center  items-center  bg-white w-full overflow-hidden w-[20vw] h-[15vw] relative  "
           
      >
        <div className="flex  space-x-2 items-center  absolute left-[0.5vw] top-[0.5vw] ">
          <img
             className="w-[1.5vw] h-[1.5vw]"
            
            src="/images/circle_tick.svg"
            alt="validate"
          ></img>
          <p className="text-[1.1vw] font-medium ">Validate</p>
          <div className="flex items-center justify-center cursor-pointer ">
          {/* <p className="mx-1 text-green-400" style={{ color: "#7A7A7A" }}> */}
          

          </div>
        
        
        </div>
        {/* {active >2 ? ( */}
          {true ? (
          <div
          className="w-full  h-full flex justify-center items-center"
          
          
          >

            <div className="flex flex-col items-center justify-center  ">
              
            <div
              className={`text-[1vw] mb-2 flex justify-center items-center text-center
              w-[15vw] h-[2.5vw] 
              ${active ? "cursor-pointer hover:bg-primarycolor hover:text-white text-primarycolor " 
                : "cursor-not-allowed bg-gray-300 text-white"
              }`}
              onClick={() =>
               {
                  checkForRetrain();
               }
              }
              style={{
                border: `1px solid ${active? "#2C2C2C" : "white"}`,
               
              }}
            >
            Validation
            </div>
           { 
           //currentProject?.createdBy === userDetails?.userId 
           false && 
           <div
              className={`text-[1vw]  flex justify-center items-center text-center
              w-[15vw] h-[2.5vw] 
              ${active?
                 "cursor-pointer hover:bg-primarycolor hover:text-white text-primarycolor" 
                 : "cursor-not-allowed bg-gray-300 text-white"
              }`}
             
              onClick={() =>
               {
                if(active)
                navigator("/workflow/validation-summary?workflowId="+currentWorkflow?.workflowId);
        
                else{
                   toast("create a masterlist and initiate stage 1 to see validation summary",{type:toast.TYPE.WARNING});
                }
               }}
               
              style={{
                border: `1px solid ${active? "#2C2C2C" : "white"}`,
                
              
              }}
            >
            Validation Summary
            </div>}

            </div>

            

          </div>
        ) : (
         <div></div>
       
        )}
      </div>
    </div>
    </div>
  );
};

export default LLM_StageCard;
