import { useFormik } from "formik";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContainer from "../../components/auth/AuthContainer";
import Button from "../../components/controls/Button";
import ErrorComp from "../../components/controls/ErrorComp";
import InputField from "../../components/controls/InputField";
import Axios from "../../utils/Axios";
import {ReactComponent as LoadingWhite} from '../../assets/loading_white.svg';

interface ResetForm {
    recaptchaToken: string;
    email: string;
}

let ResetPassword: React.FC = () => {
    let navigator = useNavigate();
    let [resetError, setResetError] = useState({
        show: false,
        msg: "",
    });
    let [success, setSuccess] = useState(false);
    let [loading, setLoading] = useState(false);

    let validation = (values: ResetForm) => {
        let errors: any = {};

        if (!values.email) {
            errors.email = "Please enter a valid Email Id";
        }
        else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(values.email) === false) {
            errors.email = "Invalid Email Id";
        }

        return errors;
    };

    let submitForm = async (values: ResetForm) => {
        setLoading(true);
        toast("Password Reset Request has been Sent.", {
            type: "info"
        })
        try {
            let res = await Axios.post("/user/sendResetLink", {
                email: values.email,
            });
            console.log(res)
            if (res.data.result) setSuccess(true);
            setResetError({
                show: false,
                msg: "",
            });
            toast("Reset Link Sent Successfully.", {
                type: "info"
            })
        }
        catch (err) {
            setResetError({
                show: true,
                msg: "Internal Server Error",
            });
            toast("Internal Server Error.", {
                type: "error"
            })
        }
    };

    let formik = useFormik<ResetForm>({
        initialValues: {
            email: "",
            recaptchaToken: "",
        },
        validate: validation,
        onSubmit: submitForm,
    });

    return (
        <AuthContainer>
            <ErrorComp show={resetError.show} errorMsg={resetError.msg} onClose={() => setResetError({ show: false, msg: "" })} />
            <div className="space-y-[2.5vw]">
                {success ? (
                    <>
                        <div
                            className="text-[1.1vw] text-center"
                        >
                            Password reset link has been sent to your Email Address <b>"{formik.values.email}"</b>
                        </div>

                        <div
                            className="bg-primarycolor cursor-pointer text-center rounded-[0.25vw]"
                            onClick={() => navigator("/signin")}
                        >
                            <button
                                className="text-white text-[1vw] py-[0.7vw] "
                                disabled={formik.isSubmitting}
                                type={"submit"}
                            >
                                Go to Login Page
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-center font-medium text-[1.1vw]">
                            Forgot your password?
                        </div>
                        <div>
                            <InputField
                                value={formik.values.email}
                                name={"email"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="text"
                                placeholder="Email Address"
                            />
                            <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched && formik.errors.email}</div>
                        </div>

                        <div className="bg-primarycolor cursor-pointer text-center rounded-[0.25vw]" onClick={() => {
                            formik.submitForm();
                        }}>
                            <button className="text-white text-[1vw] h-[3vw] w-full "

                                disabled={formik.isSubmitting}
                                type={"submit"}
                            >
                                 
                                  {formik.isSubmitting?<LoadingWhite width={"1.8vw"} height="2vw" />:"Send Password Reset Link"}   
                            </button>
                        </div>

                        <div className=" flex space-x-[0.5vw] text-[1vw]" >
                            <span>
                                Remember your password?
                            </span>
                            <span className="underline font-semibold cursor-pointer" onClick={() => navigator("/signin")}>Sign In</span>
                        </div>


                    </>
                )}
            </div>

        </AuthContainer>
    );
};

export default ResetPassword;
