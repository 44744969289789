import saveAs from 'file-saver';
import React from 'react'
import { AiOutlineCloudDownload } from 'react-icons/ai';

export default function DownloadTemplates({Seed,Raw}:any) {
  return (
    <div className="flex">

    {(Raw === undefined || Raw )&&<div
        onClick={() => {

            saveAs("/doc/Template_csv_file.csv", "Template_csv_file");
            saveAs("/doc/Template_For_Zip_File.zip", "Template_For_Zip_File");
            saveAs("/doc/Template_Text_File.txt", "Template_Text_File");
            // saveAs("/doc/MultiClass Seed Paragraphs Template.xlsx", "MultiClass Seed Paragraphs Template");
            // saveAs("/doc/MultiLabel Seed Paragraphs Template.xlsx", "MultiLabel Seed Paragraphs Template")
        }}
        className={
            ` cursor-pointer 
        duration-300 
        group
        flex items-center justify-center gap-[0.5vw] 
         px-[0.5vw] text-[1vw] py-[0.5vw]
        text-center rounded-md text-primarycolor
        h-[3vw] overflow-hidden
        `
        }
    >
        <div className="border-[0.15vw] rounded-full 
                    group-hover:scale-110 group-hover:border-primaryBlue 
                    p-[0.5vw] group-hover:scale-110 duration-200">

            <AiOutlineCloudDownload className="w-[1.2vw] h-[1.2vw]" />
        </div>
        <p className="
                  
                  duration-300">Download Unvalidated Paragraph Templates</p>
    </div>}
    
    
    {(Seed === undefined || Seed )&&<div
        onClick={() => {

            // saveAs("/doc/Template_csv_file.csv", "Template_csv_file");
            // saveAs("/doc/Template_For_Zip_File.zip", "Template_For_Zip_File");
            // saveAs("/doc/Template_Text_File.txt", "Template_Text_File");
            saveAs("/doc/MultiClass Seed Paragraphs Template.xlsx", "MultiClass Seed Paragraphs Template");
            saveAs("/doc/MultiLabel Seed Paragraphs Template.xlsx", "MultiLabel Seed Paragraphs Template")
        }}
        className={
            ` cursor-pointer 
        duration-300 
        group
        flex items-center justify-center gap-[0.5vw] 
         px-[0.5vw] text-[1vw] py-[0.5vw]
        text-center rounded-md text-primarycolor
        h-[3vw] overflow-hidden
        `
        }
    >
        <div className="border-[0.15vw] rounded-full 
                    p-[0.5vw] 
                    group-hover:scale-110 group-hover:border-primaryBlue 
                    duration-200 ">

            <AiOutlineCloudDownload className="w-[1.2vw] h-[1.2vw]" />
        </div>
        <p className="
                  
                  duration-300">Download Seed Paragraph Templates</p>
    </div>}
</div>
  )
}
