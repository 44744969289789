import React, { useEffect, useState } from "react";
import usePermissionStore from '../../store/usePermissionStore';
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { Modal, Button } from 'antd';
import "./index.css";
import { toast } from "react-toastify";
import useNotification from "../../store/useNotifications";

interface AccessManagerProps {
    RestrictedComp: () => JSX.Element,
    RequestedPerm: string,
    SecondRequestedPerm?: string
}

let AccessControl: React.FC<AccessManagerProps> = ({ children, RestrictedComp, RequestedPerm, SecondRequestedPerm }) => {

    const authStore = useAuthStore();
    const navigate = useNavigate();
    const { permissions } = usePermissionStore();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [exhaustedUsage, setExhaustedUsage] = useState<Array<string>>([]);
    const {addNotifications} = useNotification();

    useEffect(() => {
        switch (RequestedPerm) {
            case "canCreateUsers":
                setExhaustedUsage(["Can't add user", "You have created the maximum number of users for this plan"]);
                break;
            case "canCreateProjects":
                SecondRequestedPerm && authStore.usage && authStore.activePlan && authStore.usage.details.noOfWorkflows >= authStore?.activePlan.details.noOfWorkflows ? 
                setExhaustedUsage(["Can't create project", "You have created the maximum number of projects or workflows for this plan"]) : 
                setExhaustedUsage(["Can't create project", "You have created the maximum number of projects for this plan"]);
                break;
            case "canCreateWorkflows":
                setExhaustedUsage(["Can't create workflow", "You have created the maximum number of workflows for this plan"]);
                break;
            case "canCreateNodes":
                setExhaustedUsage(["Can't add class", "You have created the maximum number of classes for this plan"]);
                break;
            case "canUpload":
                setExhaustedUsage(["Can't upload", "You have exhausted all the usage data for this plan"]);
                break;
        }
    }, []);

    return (
        <>
            {
                showPopup &&
                <Modal
                    title={exhaustedUsage[0]}
                    centered
                    width="45vw"
                    open={showPopup}
                    onCancel={() => setShowPopup(false)}
                    footer={[
                        <button onClick={() => setShowPopup(false)} className="text-[#fff] text-[1vw] bg-[#aaa] px-[0.9vw] py-[0.5vw] rounded-[0.4vw]">
                            Close
                        </button>,
                        authStore.userDetails && authStore.userDetails.isBillingAccount &&
                        <button onClick={() => navigate("/billing")} className="text-[#fff] text-[1vw] bg-primaryBlue px-[1.3vw] py-[0.5vw] rounded-[0.4vw] ml-[0.5vw]">
                            Upgrade
                        </button>
                    ]}
                >
                    <p>{exhaustedUsage[1]}</p>
                </Modal>
            }

            {
                !permissions[RequestedPerm as keyof typeof permissions] 
                    ?
                    <span onClick={() => {
                        if(RequestedPerm ==="canCreateNodes")
                        {
                            addNotifications({
                                notificationId:"create class errr",
                                type:"warning",
                                errorCode:"",
                                receiverType:"user",
                                userInfo:{

                                },
                                message:"You have created the maximum number of classes for this plan"
                            })
                            toast("You have created the maximum number of classes for this plan",{type:toast.TYPE.WARNING});
                            return
                        }
                        setShowPopup(true)
                    
                    }}
                        
                        ><RestrictedComp /></span>
                    
                    :
                    SecondRequestedPerm && !permissions[SecondRequestedPerm as keyof typeof permissions]
                        ?
                        <span onClick={() => setShowPopup(true)}><RestrictedComp /></span>
                        :
                        children
            }
        </>
    );
};

export default AccessControl;
