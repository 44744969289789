import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { nodesAtom, layersAtom } from '../atoms'
import Axios from '../../../utils/Axios';

import "../masterlist.module.css"

//Loading
import { ReactComponent as Loading } from '../../../assets/loading_circle.svg'
import { ReactComponent as LoadingWhite } from '../../../assets/loading_white.svg'


import { toast } from 'react-toastify';
import { markAsModalClass } from '../utils/helper';
import useWorkflowStore from '../../../store/useWorkflowStore';

import {callCoverageAPI} from './_apiCalls';

import CoverageTab from './CoverageTab';
import template from '../../../template';
import useMasterlistSummary from '../../../store/useMasterlistSummary';

interface IMasterlistSummary {
    isModalOpen: boolean;
    setIsModalOpen: (open: boolean) => void;
    lockMasterlist: () => void;
}

export interface IWarning {
    className: string,
    warningType: -1 | 0 | 1, //-1:No Tag - 0:Class does not exist - 1:Class is not model class
    noOfPara: number,
    paraIds: Array<string>
}

export default function MasterlistSummary({
    isModalOpen,
    setIsModalOpen,
    lockMasterlist

}: IMasterlistSummary) {

    const workflow = useWorkflowStore(state => state.currentWorkflow);
    const project = useWorkflowStore(state=>state.currentProject);
    const summaryStore = useMasterlistSummary();


    const [isLoading, setIsLoading] = useState<boolean>(false); //Warnings loading state
    const [isCoverageLoading,setCoverageLoading] = useState<boolean>(false); //Data Coverage loading state
    const [loading,setLoading] = useState<boolean>(false);

    const masterlistCoverageProgress = workflow?.alpdsInfo?.percentageStatus?.masterlistCoverage
    const isDCLoading =  masterlistCoverageProgress === undefined || masterlistCoverageProgress === 100?false:true  ;


    const [shouldCallCoverageApi,setShouldCallCoverageApi] = useState<boolean>(false); // data coverage
    const DCData = workflow?.alpdsInfo?.masterlistCoverage || [];
    const [page, setPage] = useState<number>(1); //tab index state
    const [nodes, setNodes] = useRecoilState(nodesAtom);
    const [layers, setLayers] = useRecoilState(layersAtom);
    const [avgKeyWrd, setAvgKeyWrd] = useState<number>(0);
    //const [page,setPage] = useState<number>(1);
    const [warnings, setWarnings] = useState<Array<IWarning>>([]);
    const [dataSummary, setDataSummary] = useState<any>({
        seedClasses: 0,
        totalParas: 0,
        seedParas: 0,
    })


    const modalNodes: number = nodes.filter((node: any) => node.is_model_class).length;

    const projectType:string = project?.projectType || "";
    const workflowType:string = project?.workflowType || "";
    const masterlistSummaryTemplate = template[projectType][workflowType]?.masterlist?.summary;
    const SummaryTabComp = masterlistSummaryTemplate?.summaryTab;


    let selectAsModelClass = async(className: string) => {

        try {
            await Axios.post("/workflow/setUseNoTag", {
                workflowId: workflow?.workflowId,
                useNoTag: true
            });
        }
        catch {
            toast("Could Not Change No Tag");
      
        }







        // const node = nodes.find((node) => node.node_name === className);
        // if (node !== null) {
        //     //console.log("=== selecting node ===", node);

        //     if (node && node.is_model_class !== true) {
        //         let new_state = markAsModalClass(node, nodes, layers);
        //         setNodes(new_state.nodes);
        //         setLayers(new_state.layers);
        //     } else {
        //         toast("The selected node is already a model node.", { type: "success" });
        //     }
        // } else {
        //     toast("Select a node to mark it as a model node.", { type: "success" });
        // }
    };

    const updateNodeId = async (paraIds: Array<string>, nodeId: string) => {

        try {
            const response = await Axios.post("/masterlist/updateSeedParaNodeId", {
                workflowId: workflow?.workflowId,
                paraIds,
                nodeId
            })

        } catch (err) {

            toast("Could not update data", { type: "error" });
        }

    }

    const generateWarnings = (data: Array<any>) => {
        let tempWarnings: Array<IWarning> = [];
        let tempUserAction:any ={};

        const formattedData:Array<any>= [];

        const NoTagEle =data.find(d=>d._id === "No Tag");

        if(NoTagEle) formattedData.push(NoTagEle);

        data
        .sort((d1,d2)=>d1._id.toLowerCase()>d2._id.toLowerCase()?1:-1)
        .forEach((d:any)=>{
            if(d._id!== "No Tag") formattedData.push(d);
        });

      
       // console.log("prev user actions",summaryStore.userActions);
        formattedData.forEach((data: any) => {

            if(summaryStore.userActions[data._id]){
                tempUserAction[data._id] = summaryStore.userActions[data._id];

            }else{
                tempUserAction[data._id] = {
                    paraIds:data.paraIds,
                    node_id:null

                }
            }

            let nodeFound = nodes.find((node: any) => node.node_name === data._id || node.node_id === data._id);
            if(data._id === "No Tag"){
                tempWarnings.push({
                    warningType: -1,
                    className: data._id,
                    noOfPara: data.paraIds.length,
                    paraIds: data.paraIds

                })
            //console.log("node found",nodeFound)

            
            }else if (!nodeFound) {

                tempWarnings.push({
                    warningType: 0,
                    className: data._id,
                    noOfPara: data.paraIds.length,
                    paraIds: data.paraIds

                })
            } else if (!nodeFound?.is_model_class) {
                tempWarnings.push({
                    warningType: 1,
                    className: nodeFound.node_name,
                    noOfPara: data.paraIds.length,
                    paraIds: data.paraIds

                })
            } else if(nodeFound){
                tempWarnings.push({
                    warningType: 0,
                    className: data._id,
                    noOfPara: data.paraIds.length,
                    paraIds: data.paraIds

                })

            }



        })


       // console.log("=== user actions ===",tempUserAction);
       // summaryStore.setUserActions(tempUserAction);
        setWarnings(tempWarnings);
        SyncUserActions(tempUserAction);

    }

    const refreshWarnings = () => {
        let tempWarnings: Array<IWarning> = [];
        warnings.forEach((data) => {
            let nodeFound = nodes.find((node: any) => node.node_name === data.className);
            //console.log("node found",nodeFound)
            if (!nodeFound) {
                tempWarnings.push({
                    warningType: 0,
                    className: data.className,
                    noOfPara: data.paraIds.length,
                    paraIds: data.paraIds

                })
            } else if (!nodeFound.is_model_class) {
                tempWarnings.push({
                    warningType: 1,
                    className: nodeFound.node_name,
                    noOfPara: data.paraIds.length,
                    paraIds: data.paraIds

                })
            }



        })
        setWarnings(tempWarnings);

    }

    const fetchDCData = async()=>{
        if(!workflow?.workflowId) return;
       
       
        if(!isModalOpen) return ;
        if(isDCLoading || isCoverageLoading) return;

        try{
           
            setCoverageLoading(true);
            await callCoverageAPI(workflow?.workflowId);
            setTimeout(()=>{
                setCoverageLoading(false);
            },1000);
           

        }catch{
            
        }finally{
            
            setTimeout(()=>{
                //console.log("coverage loading turned off");
                setCoverageLoading(false)
            },2000);
            
        }


    }

    const SyncUserActions = (userActions:any)=>{
        
            
            // alert("Updating user actions ");
             const tempUserActions = userActions;
 
             Object.keys(tempUserActions).forEach((key:string)=>{
                 const node = nodes.find(node=>node.node_id === tempUserActions[key].node_id);
                 if(tempUserActions[key].node_id){
                    if(tempUserActions[key]?.autoSynced){
                        if(key !== node?.node_name){
                            tempUserActions[key].node_id =null;
                            tempUserActions[key].autoSynced =false;
                        }
                    }

                     if(!node){
                         tempUserActions[key].node_id = null;
                     }
                 }else{
                     let node = nodes
                     .find(node=>(node.node_name.toLowerCase() === key.toLowerCase()) 
                     && node.is_model_class=== true);
                     if(node){
                         tempUserActions[key].node_id = node?.node_id || null;
                         tempUserActions[key].autoSynced = true;
                     }
 
                 }
     
     
             });
     
             console.log("-- sync user actions --",tempUserActions);
             summaryStore.setUserActions(tempUserActions);
         




    }

    useEffect(() => {

        const fetchSummary = async () => {
           
            setIsLoading(true);
            //let data=mockData;
            const response: any = await Axios.post("/masterlist/getMasterlistSummary", {
                workflowId: workflow?.workflowId
            });


            //console.log("fetched masterlist Summary", response.data.data);

            // setDataSummary({
            //     totalParas: response?.data?.data?.totalParas || 0,
            //     seedClasses: response.data?.data?.seedClasses.length || 0,
            //     seedParas: response?.data?.data?.totalSeedParas || 0
            // })
            summaryStore.setClasses({
                totalParas: response?.data?.data?.totalParas || 0,
                seedClasses: response.data?.data?.seedClasses.length || 0,
                seedParas: response?.data?.data?.totalSeedParas || 0

            })
            const data = response.data?.data?.seedClasses;
           // console.log("=== data ===",data);
            
           // generateWarnings(data);
            summaryStore.setSummary(data);
            summaryStore.setFetchSummary(false);
            setIsLoading(false);


        }

       // SyncUserActions();

        if (isModalOpen && summaryStore.fetchMasterlistSummary) {

           // setShouldCallCoverageApi(true);
            fetchSummary();
            

        }else{
            setPage(1);
            //setDCData(undefined);
            //setDCLoading(false);
           setShouldCallCoverageApi(true);
           // abortController.abort();
        }
        if (nodes) {

            let temp = 0;
            let model_classes = 0;
            nodes.forEach((node: any, i: number) => {

                if (node.is_model_class) {
                    model_classes += 1;
                }

                if (node.is_model_class === true) {

                    temp += node.keywords[0] !== "" ? node.keywords.length : 0;
                }
            });

            setAvgKeyWrd(temp / model_classes);
        }


    }, [isModalOpen]);

    // useEffect(() => {
    //     refreshWarnings();
    // }, [nodes]);

    useEffect(()=>{
        if(!summaryStore.fetchMasterlistSummary){
           // console.log("=== generating warnings ===")
            generateWarnings(summaryStore.summary);
            
        }
    },[summaryStore.fetchMasterlistSummary,nodes]);

    useEffect(() => {
        
        if(DCData.length>0 || isDCLoading) return;

        fetchDCData();

    }, [page]);

    useEffect(()=>{
       
        // const userActions = summaryStore.userActions;

        // SyncUserActions(userActions);



    },[nodes])




    return (
        <Modal
            title="Masterlist Summary"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            centered
            okButtonProps={{ className: "hidden" }}
            cancelButtonProps={{ className: "hidden" }}
            width={"50vw"}
            footer={null}

        >

            {page === 1 ?
             isLoading ? <div className='flex h-[34.5vw] items-center justify-center '>
                <Loading
                    className='w-[5vw] h-[5vw] animate-spin'
                />

            </div>

                : <div className=' '>
                    <SummaryTabComp
                        lockMasterlist={lockMasterlist}
                        updateNodeId={updateNodeId}
                        setIsModalOpen={setIsModalOpen}
                        selectAsModelClass={selectAsModelClass}


                        nodes={nodes}
                        modalNodes={modalNodes}
                        avgKeyWrd={avgKeyWrd}
                        dataSummary={dataSummary}
                        warnings={warnings}
                    />


                </div>:null}

            {page === 2 ?(isDCLoading || isCoverageLoading )?<div className='flex h-[34.5vw] items-center justify-center '>
                <Loading
                    className='w-[5vw] h-[5vw] animate-spin'
                />

            </div>: <div>
                <CoverageTab
                data={DCData}
                isDCLoading={isDCLoading || isCoverageLoading}
                RefreshCoverageData={fetchDCData}
                shouldCallCoverageApi={!isCoverageLoading}
                />
            </div>:null

            }

            {/* Confirm Buttons */}
            <div className='flex gap-[1vw] mt-[1vw] justify-end pb-[1vw]'>

                <div className='uppercase 
    flex justify-center items-center cursor-pointer
    w-[7vw] h-[2.5vw] text-center rounded-[0.2vw]
    hover:border-primaryBlue border border-transparent hover:scale-110 duration-200
    '
                    onClick={() => setIsModalOpen(false)}
                >
                    <span className='text-[1vw]'>Cancel</span>
                </div>
                { page ===2 &&<div className='uppercase 
    flex justify-center items-center cursor-pointer
    w-[7vw] h-[2.5vw] text-center rounded-[0.2vw]
    hover:border-primaryBlue border border-transparent hover:scale-110 duration-200
    '
                    onClick={() => setPage(1)}
                >
                    <span className='text-[1vw]'>Prev</span>
                </div>}


                <div className={`uppercase bg-primaryBlue cursor-pointer
    flex justify-center items-center hover:scale-110 duration-200
    text-white w-[14vw] h-[2.5vw] text-center rounded-[0.2vw]`}
    onClick={() => {
        if(page === 1)
        {
            setPage(2);
            return;
        }

        if (nodes.filter(node => node.is_model_class).length < 2) {
            toast("The number of model nodes should be more than 1 and should be less than 101",
                {
                    type: "warning"
                })
        } else {
            lockMasterlist();
            setLoading(true);
        }
    }}
    >
                    <span className='text-[1vw]'
                        
                    >{
                    
                    page===1?"Next":
                    loading?<LoadingWhite className='w-[1.5vw]' />
                    :"Start Auto Annotation"}</span>
                </div>
            </div>
        </Modal>
    )
}
