import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { saveAs } from "file-saver";
import AppLayout from "../../components/layouts/AppLayout";
import DownloadTemplates from "../../components/DownlaodTemplates";

import { BiCopy } from 'react-icons/bi';
import {toast} from 'react-toastify';


const CopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Text was copied!");
}

const Code = ({ children, styles }: any) => {
    return <code
        className={`block w-[40vw] font-[Inter]

                        rounded-[0.2vw] text-[1vw]
                        px-[1vw] my-[1vw] py-[0.8vw] 
                        bg-[#EFEFEF]
                        border border-[0.1vw] border-[#C4C4C4] ${styles}`}
    >
        {children}
    </code>
}

const CodeLine = ({ text, styles }: any) => {
    return <div
        className={`flex justify-between items-center ${styles}`}
    >
        <p>$ {text}</p>
        <div><BiCopy
            onClick={() => CopyToClipboard(text)}
            color="#7A7A7A"

            className="w-[1.5vw] h-[1.5vw] cursor-pointer rotate-270"
        /></div>
    </div>
}

const Container =({children}:{children:ReactNode})=>{
    return <div className="space-y-[1vw]">{children}</div>
}

const Div = ({children}:{children:ReactNode})=>{
    return <div className="space-y-[0.2vw]">{children}</div>
}

const BoldSpan = ({ children }: any) => {

    return <span className="font-bold">{children}</span>
}

const Image = ({ src }: any) => {
    return <img
        className="my-4"
        src={src}
    />
}

const UL = ({ children, type }: any) => {
    return <ul className={`ml-[2.5vw] mt-[1.5vw] ${type ? type : "list-decimal"}`}>{children}</ul>
}


let HelpAndGuideline: React.FC = () => {
    let [current, setCurrent] = useState<number>(0);
    const contentDivRef = useRef<HTMLDivElement>(null);

    let help_data = [
        {
            key: 0,
            title: "CREATE PROJECT",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]  ">CREATE PROJECT</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">

                    To initiate a new project,user can follow these simple steps:

                        <p><BoldSpan>Step 1: </BoldSpan>
                        Click on the "Create Project" button located on the project dashboard. This action will redirect users to the project setup page.

                        </p>

                        <p>
                            <BoldSpan>Step2: </BoldSpan>On the project setup page, users will be required to choose a project type that best suits their specific use case. 
                            These are four available project types to select from:
                        </p>

                        <ul className="ml-[2.5vw] mt-[1.5vw] list-decimal">
                            <li>
                                <BoldSpan>MultiClass: </BoldSpan> This project type is designed for classification
                                tasks where each data point is predicted to belong to a single class.

                            </li>
                            <li>
                                <BoldSpan>MultiLabel: </BoldSpan> Choose this option for classification projects where
                                a data point can belong to more than one class.

                            </li>

                            {/* <li>
                                <b>Billing Account:</b> The billing account associated with the project.

                            </li> */}
                            <li>
                                <BoldSpan>Named Entity Recognition: </BoldSpan> This project type is suitable for tasks involving the detection and categorization of essential entities within text data.

                            </li>
                            <li>
                                <BoldSpan>LLM Customization: </BoldSpan>To create labeled dataset for fine-tuning a Instruction based large language model using custom prompts,
                                select this project type.

                            </li>

                        </ul>
                        <br />
                        <p>
                        Users can simply click on the project type that matches the requirements to proceed with setting up the workflow.

                        </p>

                        <Image src="/images/choose_project.png" />

                        <p><BoldSpan>Step3:
                        </BoldSpan> Click the "Next" button to proceed to the Workflow Setup screen.</p>


                    </div>

                    <div className="my-8">
                        <p className="font-bold">Workflow Setup</p>
                        <p> Once the user has selected the project type, it's time to configure the project's workflow.
                             The options user see may vary based on the project type they have chosen,
                             but here are the fields that they will need to complete to move forward.</p>
                        <Image src="/images/setup_workflow.png" />
                        <p>Now, let's configure the project's workflow. This section requires following information:</p>

                        <UL>
                            <li><BoldSpan>Project Name:</BoldSpan> Enter the title of the project.</li>
                            <li><BoldSpan>Billing Account:</BoldSpan>  Select the associated billing account that is linked to the user profile. This ensures proper billing and usage tracking.</li>
                            <li><BoldSpan>Workflow Type: </BoldSpan>
                                Depending on the project type, users will have different options for workflow types:
                                <UL type={"list-type-none -mt-[1px]"}>
                                    <li><BoldSpan>a. Annotation Workflow:</BoldSpan>  A straightforward annotation process.</li>
                                    <li><BoldSpan>b. Complete Workflow:</BoldSpan>  This encompasses an end-to-end NLP pipeline.</li>

                                    <li><BoldSpan>c. Instruct Fine Tune:</BoldSpan> Fine-tune Language Models (LLMs) to achieve specific responses.</li>
                                    <li><BoldSpan>d. Instruct Select:</BoldSpan> Choose the most suitable response structure from an LLM.</li>
                                    <li><BoldSpan>e. Instruct Rank: </BoldSpan>Select and Rank desired responses from an LLM.</li>

                                </UL>

                            </li>
                            <li><BoldSpan>Model:</BoldSpan> This option is available for prompt-based workflows. Select the specific LLM to generate responses.</li>

                            <li><BoldSpan>API Key:</BoldSpan> If applicable,the user can provide an API key for the selected model. If a user is just starting or working within a trial project, they will have a limit of 100 API calls.</li>
                            <li><BoldSpan>Users:</BoldSpan> If a project supports multi-user annotation, Users can add multiple members and assign their roles in the project.Users can specify each member’s role to define their permissions and responsibilities within the project.</li>

                        </UL>

                        <p>
                            By providing the necessary information in these fields, you'll be well on your way to configuring your project's workflow to meet your unique requirements.

                            Remember that the specific fields you need to complete may differ based on your project type, so make sure to tailor your inputs accordingly.

                        </p>
                    </div>


                </div>
            ),
        },
        {
            key: 1,
            title: "FILE UPLOAD",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw] ">FILE UPLOAD</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">

                        <p>
                            The user can upload files that will contain the paragraphs which will be used by the DataNeuron ALP platform to perform annotation. The User can click on the upload button and select the file they want to upload.
                            The file upload Status can be seen in the progress bar.
                        </p>
                        <div className="mt-[1.5vw] mb-[0.5vw]">
                            The user can upload their data sources following different formats:
                        </div>
                        <div>
                            <div className="flex justify-end">
                                <div className="ml-[2.5vw]">
                                    <ul className={"list-outside list-decimal"}>

                                        <li className={"list-item"}>
                                            <b> CSV</b> -  A “.csv” file that should have one column of paragraph text and each row must contain one paragraph. CSV file does not have any header
                                        </li>
                                        <li className={"list-item"}>
                                            <b>TEXT</b> - A “.txt” file that should  have multiple paragraphs separated by a single blank line
                                        </li>
                                        <li className={"list-item"}>
                                            <b>ZIP</b> -  A “.zip” file that should have multiple text files with one para per single txt file.
                                            The compressed folder needs to be of extension.zip.
                                        </li>
                                        <li className={"list-item"}>
                                            <b>XLSX</b>
                                            - A ".xlsx" file with paragraph text in a single column and row. XLSX file does not have any header.

                                        </li>
                                        <li className={"list-item"}>File greater than 100 MB is not allowed</li>
                                        <li>
                                            Encoding is assumed to be “utf-8” <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br />
                            <DownloadTemplates />
                         
                        </div>
                    </div>
                </div>
            ),
        },
        {
            key: 2,
            title: "DEFINE MASTERLIST",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">DEFINING MASTERLIST</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">

                        <div className="font-bold text-[1.2vw]">Defining Class Taxonomy</div>

                        <p className="mt-[0.3vw]">
                            Users can define any class hierarchy/ taxonomy that can be part of the masterlist for the scope created. Users can only create one masterlist per scope.
                            The masterlist can be created up to any level.
                        </p>
                        <div className="mt-[1vw]">
                            Here is an example shown for a two-level masterlist.
                            Users can define each node-specific to their particular use case or problem statement.
                        </div>
                        <img src="/images/masterlist_sample_diagram.jpg" className="w-[80%] mx-auto mt-[1vw]" alt="help pic" />
                        <div className="mt-[1vw]">Single Level of Masterlist can be created if taxonomy or hierarchy is not available.</div>
                        <div className="font-bold text-[1.2vw] mt-[1vw]">Adding Keywords</div>
                        <div className="mt-[0.5vw]">
                            User to add keywords for each model node defined in the masterlist. The keywords basically help understand the class better. The keywords are important for model nodes only, but users can define them for every node. More keywords help the algorithm annotate a wider range of paragraphs.
                            Also, the keyword must uniquely identify with the class.
                        </div>
                        <div className="mt-[0.5vw]">
                            To expedite the learning process for the algorithms or for a better understanding of each class defined at the masterlist level, it is advisable for the user to add some keywords which can be a fix of search terms that define the class along with the section or rule numbers.
                        </div>
                        <p className="mt-[1vw]"><b>Masterlist Terminology</b></p>
                        <div className="ml-[2.5vw]">
                            <ul className={"list-outside list-decimal py-[1vw]"}>
                                <li className={"list-item"}>
                                    <b>Node Name:</b> The class/label name that the user wants to define as a building block of masterlist
                                </li>
                                <li><b>Model Node:</b> The node that will be used for the classification of paragraphs.</li>

                                <li><b>Non-Model Node:</b>  The node that will not be used for classification </li>
                                <li><b>Root Node:</b>  The node at the first level of hierarchy. </li>
                                <li><b>Leaf Node:</b> The last node of every branch considering the masterlist as a tree structure.</li>
                                <li>
                                    <b>Node Accuracy:</b>  The accuracy for every node will only be visible after the Training has been completed.
                                    Note: Accuracy is originally computed for model nodes only.
                                    Accuracy at Non-mode parent Nodes is obtained by estimation.
                                </li>
                            </ul>
                        </div>
                        <div className="font-bold text-[1.2vw] mt-[0.5vw]">Masterlist Features</div>
                        <div className="ml-[2.5vw]">
                            <ul className={"list-decimal list-outside py-[1vw]"}>
                                <li className="list-item">
                                    <b> Select as Model Class Button:</b> Button that can be used to select a node as model node/class.
                                </li>
                                <li className="list-item">
                                    <b>Delete Node Button:</b> Any node can be deleted if not needed. Note after deleting a node, all its child nodes will also be deleted.
                                </li>

                                <li className="list-item">
                                    <b>No-Tag Toggle Button:</b>
                                    No Tag can be enabled or disabled. The No-Tag feature also provides the functionality to handle out-of-scope paragraphs. In case the user is sure that their database does not contain out-of-scope paragraphs,
                                    they can disable the No Tag option.
                                </li>
                                <li>
                                    <b>Lock Masterlist:</b> The masterlist can be lock after the user has completed the building of the Masterlist.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p>Users can open the Masterlist Summary by clicking on the Masterlist Summary button. This is divided into two sections:
                            </p>

                            <ul>
                                <li>
                                    <p><b>Masterlist Summary:</b></p>
                                    <p>This is the page where users can view the information about the data they have ingested as well as a summary of the masterlist defined on the platform.
                                        On this screen you can check:</p>
                                    <ul>
                                        <li>
                                            Masterlist and Data Summary

                                        </li>
                                        <li>
                                            Warnings for Seed Para / Pre Labeled dataset

                                        </li>
                                    </ul>

                                    <p><b>Masterlist and Data Summary</b></p>
                                    <ul className="pl-[2vw] list-decimal mb-[1vw]">
                                        <li>Total and Model nodes defined on the Masterlist.</li>
                                        <li>Total number of paragraphs and the number of seed/annotated paragraphs in the uploaded data.</li>

                                    </ul>
                                    <p><b>Warnings for Seed Para / Pre Labeled dataset</b></p>
                                    <p>Provides information about the classes identified by the system in the ingested seed / pre-labeled data file at the Upload stage,
                                        as well as whether the user created appropriate classes to map them with the masterlist.
                                        This table displays all seed classes that cannot be mapped to the masterlist.
                                        For these classes, an appropriate warning is generated, which can be as follows:
                                    </p>
                                    <ul className="pl-[2vw] list-decimal mb-[1vw]">
                                        <li>
                                            If the class does not exist in the Masterlist, the user can either create it or select an existing class in the Masterlist and map it to that class.

                                        </li>
                                        <li>
                                            If the class in the Masterlist is not a Model class, the user can easily make it Model class with the click of a button.
                                        </li>
                                    </ul>
                                    <img alt="select workflow type"
                                        src="/guidelines/images/image1.png"
                                        className="w-[60%] mx-auto mt-[0.5vw]" />
                                </li>
                                <li>
                                    <p><b>Masterlist Coverage: </b></p>
                                    <p>Now that all warnings have been resolved, the user can proceed to the masterlist coverage section,
                                        which will display the following data from the created masterlist:</p>
                                    <ul>
                                        <li>Model Class name</li>
                                        <li>Keyword count: The total number of keywords in the class (name of the class and its parent class is also considered a keyword)</li>
                                        <li>Number of paragraphs: The number of paragraphs that contain the keywords present in the class, as well as the percentage coverage of paragraphs for that class in relation to the total number of paragraphs.</li>
                                        <li>Keywords with no paragraph: When a class contains keywords that do not appear in any paragraph.</li>
                                        <li>The total number of paragraphs that are not covered by the masterlist keywords, implying that no class or keyword is assigned to such paragraphs.</li>

                                    </ul>
                                    <p>Using this data, users can optimise their masterlist to get best results from the platform.</p>

                                    <img alt="select workflow type"
                                        src="/guidelines/images/image3.png"
                                        className="w-[60%] mx-auto mt-[0.5vw]" />

                                </li>
                                <li>
                                    <p><b>Advanced Masterlist</b></p>
                                    <p>While preparing the masterlist, the advanced masterlist feature can help users understand the keywords present in the ingested data.

                                        The Advanced Masterlist keywords are ranked based on their frequency and percentage coverage across the ingested data. As a result, users can better prioritize the keywords they want to use in their masterlist.

                                        Users can access the Advanced Masterlist by clicking the ‘Go to Advanced Masterlist button’ on the Upload or Masterlist page.</p>
                                    <img alt="select workflow type"
                                        src="/guidelines/images/image27.png"
                                        className="w-[60%] mx-auto mt-[0.5vw]" />
                                    <p>
                                        The Advanced Masterlist suggestions are generated automatically when a user comes to the page for the first time, but results can be expanded by selecting the filters from the Advanced Options dropdown.

                                        Depending on the dataset, the Advanced options can be used to generate a more precise list of keywords.
                                    </p>
                                    <img alt="select workflow type"
                                        src="/guidelines/images/image15.png"
                                        className="w-[60%] mx-auto mt-[0.5vw]" />
                                    <p>The generated keywords can be grouped into Folders, which can represent a class or a cluster of similar words; these keywords are displayed as bookmarked on the UI.
                                        Once the keywords have been chosen, they can be added to the masterlist by clicking the the icon in the sidebar.

                                    </p>
                                    <img alt="select workflow type"
                                        src="/guidelines/images/image23.png"
                                        className="w-[60%] mx-auto mt-[0.5vw]" />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            ),
        },
        {
            key: 3,
            title: "ANNOTATION FLOW",
            component: (
                <div className="ml-[2.5vw]">
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">ANNOTATION FLOW</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">
                        <div className="mt-[0.5vw]">
                            When creating a project, the user can select Annotation Workflow. With the List view or the Annotation Workflow, the dataset can be retrained and improved.
                        </div>
                        <img alt="select workflow type" src="/guidelines/images/image31.png" className="w-[40%] mx-auto" />
                        <div className="mt-[0.5vw]">
                            Annotation workflow differs from Complete workflow in its approach. It has a single batch and a larger target to achieve. Unlike in Complete Workflow, the paragraphs are not available in the Class View.
                            If the user believes that a paragraph is relevant to the Class with which it is associated, they can click the 'tick' button and proceed.
                        </div>
                        <img alt="select workflow type" src="/guidelines/images/image30.png" className="w-[20%] mx-auto" />

                        <div className="mt-[0.5vw]">
                            If the end user wants to change the Class associated with a paragraph,
                            they can press the 'cross' button and select the Class from the dropdown below.
                        </div>

                        <img alt="select workflow type" src="/guidelines/images/image25.png" className="w-[30%] mx-auto" />

                        <div className="mt-[0.5vw]">
                            After achieving the 'n' number of paragraph annotations (target),
                            the user can retrain the dataset. If they retrain the dataset,
                            the target will now be '2n' and so on.
                            The 'Train & Predict' button retrains the dataset, while the 'Validation Summary' button displays the validation summary for all project users.

                        </div>
                        <img alt="select workflow type" src="/guidelines/images/image10.png" className="w-[45%] mx-auto" />
                        <div className="mt-[0.5vw]">
                            <p className="">On the validation screen, the user will notice four icons in the upper right corner:

                                <ul className="pl-[4.8vw] list-decimal gap-3 flex flex-col">
                                    <li> The first is the 'Annotated by [current user]' icon, which displays the current user's annotations.</li>
                                    <li>The second is the 'Annotations by all users' icon, which displays the annotations made by all users,
                                        including the current user.</li>
                                    <li> The third icon is the 'Target to Retrain' icon, which depicts the overall goal to achieve in order to retrain.</li>
                                    <li> Finally, the 'Refresh all user's data' icon refreshes the 'Annotations by all users' count.</li>
                                </ul>

                                <div className="mt-[0.5vw]">

                                    <img alt="select workflow type" src="/guidelines/images/image20.png" className="w-[35%] mx-auto" />
                                </div>

                                <div className="mt-[0.5vw]">
                                    Once the target has been met, the user can export the dataset by clicking the 'Export Dataset' button on the Workflow tab.
                                </div>
                                {/* <div className="mt-[0.5vw]">

                            <img alt="select workflow type" src="/guidelines/images/image6.png" className="w-[15%] mx-auto" />
                        </div> */}
                                <div className="mt-[0.5vw]">

                                    <img alt="select workflow type" src="/guidelines/images/image14.png" className="w-[35%] mx-auto" />
                                </div>


                            </p>
                        </div>

                    </div>

                </div>
            )
        },
        {
            key: 4,
            title: "INSTRUCT WORKFLOW",
            component: (
                <div className="ml-[2.5vw]">
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">Validation Flow for Instruct-Based Workflows</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify space-y-[1vw]	">
                        <div>

                        <p><BoldSpan>Managing Prompts and Responses</BoldSpan></p>
                        <p>This section will guide Users through the process of adding prompts, generating responses, and validating them within their workflow.</p>
                        </div>

                        <div className="space-y-[0.2vw]">
                            
                        <p><BoldSpan>Step 1: Adding a New Prompt</BoldSpan></p>
                        <p>
                        To create a new prompt, follow these steps:
                        Users can click on the "Add New Prompt" button, as indicated in the user interface. Users can also access uploaded prompts through pagination, as shown in the provided image.
                        </p>
                        <Image
                        src={"/images/pagination.png"}
                        ></Image>
                        <p>When creating a prompt, users can type the desired prompt in the input field. Optionally, they can add their own answer if they plan to fine-tune the Language Model (LLM) themselves, or they can generate answer/s from the selected LLM model later on.
                                Click on the "Save" button to proceed.
                        </p>
                        <Image src="/images/add-new-response.png" />
                        </div>

                        <div>
                            <p><BoldSpan>Step 2: Generating Responses</BoldSpan></p>
                            <p>After creating the prompts, Users can generate responses by following these steps:<br/>
                                Click on the "Generate" button located next to the prompt they wish to generate responses for.<br/>
                                Depending on the workflow type, they can generate one or multiple responses. Instruct Tune workflow generates a single response for each prompt whereas Instruct Rank and Instruct Select generates multiple responses for each prompt. 
                            </p>
                            <Image src='/images/prompt-01.png' />
                        </div>

                        <div>
                            <p><BoldSpan>Step 3: Response Validation and Fine-Tuning</BoldSpan></p>
                            <p>Once the responses are generated, users can begin the validation process:
                                Users must review the responses carefully to ensure they meet the project's requirements.
                            </p>
                            <Image src="/images/prompt-02.png" />
                        </div>

                        <div>
                            <p>
                            If fine-tuning is necessary, users can make the required adjustments.
                            <br/>
                            By following these steps, users can effectively manage prompts and responses within the project, ensuring that the outcomes meet their specific needs and quality standards.
                            <br/>
                            This documentation provides a clear and concise guide for users on how to work with prompts and responses in DataNeuron. 
                            <br/>
                            </p>
                            <br/>
                            <p><BoldSpan>Step 4: Adding Additional Responses</BoldSpan></p>
                            <p>
                            After the initial response is generated, follow these steps to add more responses and fine-tune their structure:
                            Click on the “Add New Answer” button. This action allows users to add more responses.
                            </p>
                            <Image src="/images/prompt-03.png"/>
                            <p>Users can type the suitable answer or response and click on the “Add” button to add it to the list of responses.</p>
                            <Image src="/images/prompt-05.png"/>
                        </div>

                        <div>
                            <p>In addition to the above flow some workflows can have additional inputs as per the workflow type selected.</p>
                        </div>

                        <div>
                        <p><BoldSpan>Instruct Rank:</BoldSpan></p>
                        <p>
                            In the Instruct Rank workflow, 
                            users can also assign a suitable rank and its relevance to the provided prompt to each response.
                            This will further fine tune the LLM to select the best response type and structure.
                        </p>
                        <Image src="/images/prompt-06.png" />
                        
                        </div>

                        <div>

                        </div>
                    </div>

                </div>
            )
        },
        {
            key: 5,
            title: "STAGE 1 VALIDATION",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">STAGE 1 VALIDATION</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">
                        <div className="mt-[0.5vw]">
                            The user will be presented with a set of paragraphs that must be validated. ALP will identify the paragraphs based on the Masterlist and
                            provide the relevant paragraphs for validation in Stage 1, user needs to validate (~15 paras / class).
                        </div>
                        <div className="mt-[0.5vw]">
                            The user can select the checkmark against the paragraph if the paragraph belongs to the predicted class. In case the prediction is wrong
                            for a paragraph, the user can even suggest the correct class.
                            <i>(It is highly recommended to the user to suggest the correct class at this level to expedite the process and get good results)</i>
                        </div>
                        <div className="mt-[0.5vw]">
                            At this stage, the paragraphs are available for user validation class-wise, i.e., paragraphs are loaded for one class at a time. The
                            user can click on the ‘Load More’ option to get more paragraphs for validation
                        </div>

                        <div className="mt-[0.5vw]">

                            <p className="font-bold">

                                Note: User can use the keyboard shortcuts to annotate the paragraph
                                <ul className="pl-[4.8vw] list-decimal">
                                    <li>Enter: Accept the paragraph</li>
                                    <li>Tab: Reject the paragraph</li>
                                    <li>UP and DOWN arrow key to navigate to next or previous paragraph</li>
                                </ul>

                            </p>
                        </div>

                        <div>


                            <div className="mt-[0.5vw]">

                                When Stage 1 is initiated, the user is redirected to the Stage 1 Validation page, for validating the paragraphs; which are automatically mapped to a specific class or classes depending on the project type. The user must validate these paragraphs or change the predicted classes. These validations will assist us in improving the predictions in subsequent stages.

                                <p>Depending on the project type, the validation methods may differ:</p>


                            </div>

                            <div className="mt-[1vw]">
                                <b>1. Multi Class Annotation</b>
                                <p>
                                    In multiclass annotation, paragraphs can only be mapped to a single class.
                                    To proceed to stage 2, users must complete the minimum target, which is 15 paragraphs per model class.
                                    The paragraphs are categorized based on the predicted classes.
                                </p>
                                <img alt="select workflow type" src="/guidelines/images/image8.png" className="w-[80%] mx-auto" />
                                <img alt="select workflow type" src="/guidelines/images/image9.png" className="w-[80%] mx-auto mt-[0.5vw]" />
                                <p>
                                    By clicking on the check button, users can accept a predicted class.
                                    The cross button allows the user to reject a prediction on a paragraph; once rejected,
                                    the dropdown to select a new class is activated, as shown in the image.

                                </p>
                                <img alt="select workflow type" src="/guidelines/images/image28.png" className="w-[80%] mx-auto mt-[0.5vw]" />

                                <p>
                                    From the dropdown, the user can choose a new class for the paragraph or ignore if the paragraph does not belong to any class.
                                    Such paragraphs will not be included in the training.
                                    Multiple users can assign different classes to a paragraph,
                                    so the final decision is based on a variety of factors,
                                    as explained in the multiuser voting section.

                                </p>
                            </div>

                            <div className="mt-[1vw]">
                                <b>2. Multi Label Annotation</b>
                                <p>
                                    In Multi Label annotation, the paragraphs can be mapped to multiple classes as shown in the image,
                                    so the validation criteria differ from those in multiclass classification.

                                </p>
                                <img alt="select workflow type" src="/guidelines/images/image32.png" className="w-[80%] mx-auto mt-[0.5vw]" />
                                <p>
                                    For initiating Stage 2, 30 paragraphs for at least 2 model classes must be validated.
                                    <br />
                                    Since the paragraphs cannot be grouped by a single class, they are displayed as a list.

                                </p>
                                <img alt="select workflow type" src="/guidelines/images/image19.png" className="w-[80%] mx-auto mt-[0.5vw]" />

                                <p className="mt-[0.5vw]">
                                    User can filter the paragraphs using the topbar dropdown, as shown in the image above.
                                    Aside from filtering, the dropdown also displays the current validation count of classes,
                                    making it simple to track progress.
                                    (Classes with low validation are highlighted in red).
                                    <br />
                                    Once the minimum target has been achieved, the user can initiate Stage 2 by clicking the "MOVE TO STAGE 2" button.

                                </p>
                                <img alt="select workflow type" src="/guidelines/images/image40.png" className="w-[60%] mx-auto mt-[0.5vw]" />

                            </div>

                            <div className="mt-[1vw]">
                                <b>3. Unused paragraphs</b>


                                <ol className="mt-[0.5vw] list-decimal pl-[2vw]"
                                >
                                    <li>
                                        Unused paragraphs in a batch are those that the system predicts and displays to the user,
                                        but the user does not provide feedback on the predictions.
                                    </li>
                                    <li>
                                        Unused para from Stage 1 are annotated by the ensemble models in Stage 2 and provided for user validation in Stage 2 Batch 2.
                                        As a result, such paragraphs are displayed automatically in Stage 2.
                                        This effectively means that the user does not need to return to Stage 1 for validation once they reach Stage 2.

                                    </li>
                                    <li>
                                        The user can validate the unused paragraphs in Stage 2 by returning to previous batches.
                                        For example, if a user is in Batch n, the user can validate the unused paragraphs in all previous batches,
                                        from Batch 1 to Batch n-1.

                                    </li>
                                    <li>
                                        Read-only paragraphs are noneditable,
                                        which means the user cannot provide feedback because they have already been validated and locked for training.

                                    </li>
                                </ol>

                            </div>

                        </div>



                    </div>
                </div>
            ),
        },
        {
            key: 6,
            title: "STAGE 2 VALIDATION",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">STAGE 2 VALIDATION</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">

                        <div className="mt-[0.5vw]">
                            Stage 2 will be generated based on our ensemble model (which is a context-filtering and semi-supervised based model) to provide higher
                            accuracy at Stage 2. The user must validate whether or not the paragraph belongs to the annotated class.
                        </div>
                        <div className="mt-[0.5vw]">
                            At this point, the paragraphs are ready for user validation in batches, i.e., paragraphs are loaded for a batch that will be a mix of
                            multiple classes defined at the Masterlist level. To proceed to the next batch for validation, the user can select the ‘Load More’
                            option
                        </div>
                        <div className="mt-[0.5vw]">
                            In other words, before moving on to the next batch in Stage 2, the user must complete the validation for all of the classes in the
                            current batch.
                        </div>

                        <div className="mt-[0.5vw]">

                            <p className="font-bold">

                                Note: User can use the keyboard shortcuts to annotate the paragraph
                                <ul className="pl-[4.8vw] list-decimal">
                                    <li>Enter: Accept the paragraph</li>
                                    <li>Tab: Reject the paragraph</li>
                                    <li>UP and DOWN arrow key to navigate to next or previous paragraph</li>
                                </ul>

                            </p>

                            <div>


                                <div className="mt-[1vw]">

                                    <p>

                                        When Stage 2 is initiated, users are redirected to the Stage 2 page where they can validate additional paragraphs.
                                        Users must validate these paragraphs or edit the predicted classes.
                                        These validations will aid us in improving AutoML predictions.
                                    </p>


                                    <p>Depending on the project type, the validation methods may differ:</p>


                                </div>

                                <div className="mt-[1vw]">
                                    <b>1. Multi Class Annotation</b>
                                    <p>
                                        In multiclass annotation, the paragraphs can be mapped to 1 class.
                                        <br />
                                        <br />

                                        <img alt="select workflow type" src="/guidelines/images/image4.png" className="w-[80%] mx-auto mt-[0.5vw]" />
                                        <p>

                                            To initiate Model Training, users must complete the minimum target, i.e. 21 paragraphs for at least two classes (Stage 1 + Stage 2).
                                            The paragraphs are classified based on their predicted class.

                                        </p>
                                    </p>
                                    <img alt="select workflow type" src="/guidelines/images/image8.png" className="w-[80%] mx-auto" />
                                    <p>
                                        By clicking on the check button, users can accept a predicted class.
                                        The cross button allows the user to reject a prediction on a paragraph; once rejected,
                                        the dropdown to select a new class is activated, as shown in the image.

                                    </p>
                                    <img alt="select workflow type" src="/guidelines/images/image28.png" className="w-[80%] mx-auto mt-[0.5vw]" />
                                    <p>

                                        The user can select a new class for the paragraph from this dropdown or leave it if the paragraph does not belong to any class at all. Such paragraphs will not be considered for Model Training.

                                        As explained in the multiuser validation section, multiple users can assign different classes to a paragraph, so the final verdict is based on a variety of factors.


                                    </p>

                                </div>

                                <div className="mt-[1vw]">
                                    <b>2. Multi Label Annotation</b>
                                    <p>
                                        In Multi Label annotation, the paragraphs can be mapped to multiple classes as shown in the image,
                                        so the validation criteria differ from those in multiclass classification.

                                    </p>
                                    <img alt="select workflow type" src="/guidelines/images/image32.png" className="w-[80%] mx-auto mt-[0.5vw]" />
                                    <p>
                                        For initiating Model Training, 40 paragraphs for at least 2 model classes must be validated.

                                        <br />

                                        <br />
                                        <p>
                                            Since the paragraphs cannot be grouped by a single class, they are displayed as a list.
                                        </p>

                                    </p>
                                    <img alt="select workflow type" src="/guidelines/images/image29.png" className="w-[80%] mx-auto mt-[0.5vw]" />

                                    <p className="mt-[0.5vw]">
                                        User can filter the paragraphs using the topbar dropdown, as shown in the image above.
                                        Aside from filtering, the dropdown also displays the current validation count of classes,
                                        making it simple to track progress.
                                        (Classes with low validation are highlighted in red).
                                        <br />
                                        <br />
                                        <p>
                                            Users can also switch between batches by using the topbar dropdown, but only paragraphs that have not been valida
                                        </p>
                                        <br />
                                        <img alt="select workflow type" src="/guidelines/images/image13.png" className="w-[80%] mx-auto mt-[0.5vw]" />
                                        <br />
                                        In contrast to Stage 1, Stage 2 does not have a limit of 3 batches, and users can call a new batch by clicking on the "LOAD MORE" button; prediction accuracy on the new batch will improve as the model trains on previously validated data.

                                        Once the target has been achieved, the user can train the model by clicking the "TRAIN PLATFORM" button.


                                    </p>
                                    <img alt="select workflow type" src="/guidelines/images/image17.png" className="w-[60%] mx-auto mt-[0.5vw]" />

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            ),
        },

        {
            key: 7,
            title: "MODEL TRAINING",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">MODEL TRAINING</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify">
                        <div>
                            Our tool trains on dozens of algorithms and automatically chooses the best one. At this stage, the tool will generate a detailed report on the masterlist and data training,
                            the user can analyze this report to improve classes with low accuracy.
                        </div>
                        <div className="mt-[0.5vw]">The report will contain the following details - </div>
                        <div className="mt-[0.5vw] font-bold">1) Class wise detailed metrics:</div>
                        <div className="ml-[2.5vw]">
                            <ul className={"list-decimal list-outside py-[1vw]"}>
                                <li className="list-item">
                                    <b> Precision:</b>  this metric says, of all paragraphs that are labeled as a class A by algorithm, how many actually belonged to the class A.
                                </li>
                                <li className="list-item">
                                    <b>Recall:</b>  this metric says, of all the paragraphs that truly are class A, how many did the algorithm labeled as class A.
                                </li>

                                <li className="list-item">
                                    <b>F1:</b>F1 measure provides a single score that balances both the concerns of precision and recall in one number.

                                </li>
                            </ul>

                        </div>
                        <div className="font-bold mt-[0.5vw]">Training paragraph per class</div>
                        <div className="ml-[2.5vw]">
                            <ul className={"list-decimal list-outside py-[1vw]"}>
                                <li className="list-item">
                                    Training paragraph per class indicates how many paragraphs were used to train a particular class. General trend is,
                                    algorithms will tend to learn better with more number of paragraphs.
                                </li>

                                <li className="list-item">
                                    If a class has less F1 score and that class also has less number of training paragraphs,
                                    then it’s an indication that the user should try to annotate more paragraphs for that particular class.
                                </li>
                            </ul>
                        </div>

                        <div className="mt-[0.5vw]">
                            After this, the user can push the model to the core platform or work on accuracy improvement in the Feedback & Review stage.
                        </div>

                    </div>
                </div>
            ),
        },

        {
            key: 8,
            title: "LLM MODEL TRAINING",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">LLM MODEL TRAINING</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify">
                        <Container>
                            <Div>
                               <p><BoldSpan>Prerequisites</BoldSpan></p>
                               <p>Before users begin the training process, they must ensure that they have the following prerequisites in place:
                                <br/>1. Docker installed on the system or DataNeuron LLM Backend deployed in cloud</p> 
                                
                            </Div>
                            <Div>
                            <p><BoldSpan>Installation</BoldSpan></p>
                               <p>Install Docker: To set up the LLM model for classification, users must have Docker installed on their system or have the DataNeuron LLM backend deployed in cloud. If Docker is not already installed, you can download it from the official Docker website 
                                (<a target="_blank" className="text-primaryBlue border-b" href="https://www.docker.com/get-started">https://www.docker.com/get-started</a>) 
                                and follow the installation instructions specific to your operating system.</p>
                            </Div>

                            <Div>
                                <p><BoldSpan>Getting Started(User can ignore this step if LLM Backend is deployed to cloud)</BoldSpan></p>
                                <p>Pull the LLM Backend Server: To get the LLM backend server, users can use the following Docker command:
                                    <Code>
                                        <CodeLine
                                        text={"docker pull dataneuron/llm-backend"}
                                        ></CodeLine>
                                    </Code>
                                    
                                    Run the LLM Backend: After successfully pulling the backend server,user can start the LLM backend with the following command:
                                    <Code>
                                        <CodeLine text={"docker run -it -p 9000:8080 dataneuron/llm-backend"} />
                                    </Code>
                                    
                                    *Note:Ensure that port 9000 is available, or modify the port number if necessary.
                                    This command launches the LLM backend server at port 9090 and makes it available for use.</p>
                            </Div>

                            <Div>
                            <p><BoldSpan>Authentication</BoldSpan></p>
                            <p>Generate an API Token: To connect to the LLM backend,
                                 users must generate an API token using their password. 
                                 If user already has a token, they can skip this step.
                                 This API token is used to authenticate users with the dataneuron servers.
                            </p>

                            <Image src="/images/authentication-01.png" />
                            


                            </Div>

                            <Div>
                                <p><BoldSpan>Initiate Training:</BoldSpan></p>
                                <p>Once a user has the API token ready,they can access the LLM model training interface.
                                    <br/>Users can click on the "Train" button to start the training process.
                                    <br/>In the API key section,they can paste the API token.
                                    Click on the "Submit" button to trigger the training process.                                    
                                </p>
                                <Image src={"/images/llm-01.png"} />
                                <p><BoldSpan>Training Completion Report:</BoldSpan></p>
                                <p>Upon completion of the training process, users will receive a classification report that contains the following information:
                                    Model Name and Hyperparameters: This section provides details about the trained model, including its name and the hyperparameters used for training.</p>
                            </Div>

                            <Div>
                                <p><BoldSpan>Classification Report Table:</BoldSpan></p>
                                <p>This table presents detailed information about the classes that were trained, including their accuracy, precision, recall, and F1-score. It provides a comprehensive overview of the model's performance for each class.</p>
                             


                            </Div>
                        </Container>

                    </div>
                </div>
            ),
        },

        {
            key: 9,
            title: "WORKFLOW VERSIONING",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">workflow versioning</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">
                        <div>
                            Workflow Versioning is for  creating a new version of the workflow. Every workflow will have four stages: Upload, Masterlist, Validate, and Train.

                        </div>
                        <div className="mt-[0.5vw]" >
                            New versions of the project can be created by using the dropdown component in the workflow dashboard. If the project contains multiple workflow versions, you can switch to any of them by selecting the version from the drop down.
                        </div>
                        <div className="mt-[0.5vw]" >
                            While creating a new version, the user can fork until the stage that must be retained in the new workflow version.
                        </div>


                        <div className="mt-[0.5vw]" >
                            Click the Fork Icon next to the Version that needs to be copied. You can only fork stages that have already been completed in the current workflow.
                        </div>
                        <div className="my-[0.5vw] font-bold"> Forking Workflow Stages:</div>

                        <div className="ml-[2.5vw]">
                            <ul className={"list-decimal list-outside py-[1vw]"}>
                                <li> <b>Upload:</b> All uploaded file data in the form of splitted paragraphs from the database is copied to the newly created workflow.</li>
                                <li><b>Masterlist:</b>When the master list is selected, all classes and keywords in the masterlist are copied to the newly created workflow.</li>
                                <li><b>Validate:</b>When the validate stage is selected, all automatic annotations done by the platform and user validated paragraphs in stages 1 and 2 are copied to the newly created workflow.</li>
                                <li><b>Train:</b> By selecting the train stage, the trained model is copied and deployed to the newly created workflow on the server.</li>
                            </ul>








                        </div>

                    </div>

                </div>
            ),
        },
        {
            key: 10,
            title: "MULTIUSER VALIDATION",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">multiuser validation</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">

                        <div>
                            Multiple users can collaborate on a single project and contribute as per the following rights:
                        </div>

                        <div className="ml-[2.5vw]">
                            <ul className={"list-decimal list-outside py-[1vw]"}>
                                <li><b>UPLOAD ACCESS: </b>Users with Upload access can upload files to the project's database.</li>
                                <li><b>MASTERLIST ACCESS: </b>Users with Masterlist access can define a project's taxonomy and keywords.</li>
                                <li><b>VALIDATION ACCESS: </b>Users with validation access can help validate paragraphs at each stage and batch of the validation process.</li>
                                <li><b>TRAINING/EXPORT ACCESS: </b> a user with train page access can view the training results and download the traning dataset along with masterlist.
                                    <p>
                                        It should be noted that once the current batch has been validated, only the project creator has access to move to the next batch. Viewing the Validation Summary page via the "Validation Summary" button can provide the creator with additional user validation statistics.
                                        The voting weights in the Validation Stage range from 1 (lowest) to 5 (highest), indicating how much weight is given to each user's validation.
                                        For example, when calculating final votes, validation performed by a user with Voting weight 3 will take precedence over validation performed by a user with Voting weight 2.
                                    </p>

                                </li>
                                <li>
                                    <b>TRAINING FOR MULTILABEL:</b> Users who have access to the train page can view the training results and download the training dataset as well as the masterlist. Once the current batch has been validated, only the project creator can initiate the next batch.
                                </li>
                                {/* <li><b>TRAINING/EXPORT ACCESS: </b>Users with access to the train page can view the training results and download the Dataset as well as the Masterlist.</li> */}
                            </ul>
                        </div>
                        <p>
                            By clicking the "Validation Summary" button, you can access user validation statistics. During the Validation Stage, the voting weights are the same for all users.


                            All classes validated correctly by all users will be considered for the outcome of the voting mechanism. When calculating final votes, for example, if a user validated with 'Class 1','Class 2' and another user validated with 'Class 2','Class 3', the final result will be 'Class 1','Class 2','Class 3'.

                        </p>
                        <div className="mt-[0.5vw] font-bold">
                            When should the creator go to the next batch?

                        </div>
                        <div>
                            Only the project creator has the permission to move to the next Stage or Batch.
                            <p className="mt-[0.5vw]">
                                The general rule for project creators is to ensure that all users with Validation Access validate common paragraphs in order to achieve the Stage or Batch target. This ensures that the minimum requirements are met before moving to the model training stage. In the Annotation Workflow users can validate based on their training data requirements.
                            </p>


                        </div>



                    </div>


                </div>





            ),
        },
        {
            key: 11,
            title: "VALIDATION SUMMARY",
            component: (

                <div className="ml-[2.5vw]" >
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">Validation Summary (Project Statistics and User Statistics)</div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify	">

                        <p>
                            Project Creator can track the Overall and Individual progress of the project from the validation summary page.
                        </p>
                        <br />
                        <img alt="select workflow type" src="/guidelines/images/image22.png" className="w-[80%] mx-auto" />

                        <p>
                            NOTE: Accuracy tab is only available in Stage 2.

                            Since the graphs vary depending on the type of project, some screens may display graphs that are specific to the current project type.

                        </p>
                        <br></br>
                        <p><b>The validation summary is divided into 4 sections:</b></p>
                        <br></br>
                        <p>1. <b>Accuracy</b></p>
                        <p>
                            In a tabular format, the Accuracy tab indicates the overall model accuracy and the number of paragraphs used for training individual classes. Other matrices, such as parent node, F1 score, and so on, are also available.

                        </p>
                        <br></br>
                        <p>2. <b>Project Statistics</b></p>
                        <br></br>
                        <ol className="list-decimal pl-[3vw]">
                            <li>
                                <b>Multiuser Overlap Graph:</b>

                                Heatmap graph depicts the overlap of all users' validations with each other, allowing users to see the percentage of overlap between all other users in a given project.
                            </li>
                            <li>
                                <b>Common Paras:</b>


                                Bar graph depicts stage-level information about the common paras among the number of users out of the total paras in the stage. The number of users is represented by the X axis, and the most common paragraph in a given stage is represented by the Y axis.

                            </li>
                            <li>
                                <b>Class Wise Validation Statistics: </b>
                                <ol className=" pl-[2vw]"
                                    style={{
                                        listStyleType: "lower-alpha"
                                    }}
                                >
                                    <li>
                                        <b>Multi Class Classification: </b>
                                        Pie chart depicts the distribution of validation data by class. The slider represents the number of users (which can be changed using the slider below the graph), and the pie chart displays data for the number of users selected with the slider. This data was obtained through multi-user validation and is shared with all classes.

                                    </li>

                                    <li>
                                        <b>Multi-Label Classification: </b>
                                        Bar graph depicts the distribution of validation data by class. Each bar represents data for the number of users chosen with the slider (which can be changed with the slider below the graph). This data was obtained through multi-user validation and is shared with all classes.

                                    </li>

                                </ol>

                            </li>
                        </ol>
                        <br></br>
                        <p>2. <b>User Statistics</b></p>

                        <p>User Statistics page displays a list of active project users as well as individual validation data for each of them.
                            This data is represented in three graphs, each of which is explained below:</p>

                        <ol className="list-decimal pl-[3vw]">
                            <li>
                                <b> Validation Statistics: </b>
                                tabular representation of the validation statistics for a specific user.
                            </li>
                            <li>
                                <b> Class wise Validation Statistics: </b>
                                tabular representation of the validation statistics for a specific user.
                                <ol className="list-decimal pl-[3vw]">
                                    <li>
                                        <b>Multi Class Classification: </b>
                                        Pie chart depicts the user's class-wise validation.

                                    </li>
                                    <li>
                                        <b>Multi Label Classification: </b>
                                        Bar graph shows the number of paragraphs that belong to each class.
                                        (Can include overlapping paragraphs for multiple labels).


                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Overlap Statistics with Other Users: </b>
                                Graph depicts the overlap of a user's common validated paragraphs with all other users having validation access.

                            </li>
                        </ol>

                        <br></br>
                        <p>3. <b>Training Paragraphs</b></p>
                        <p>List of all data points that will be used in the model training stage.
                            This includes the Seed Paragraphs and users' Stage 1 and Stage 2 validation.
                            Users can download all user-level validation information at any stage and in any batch.
                            Users can download the project data from the Training Paragraphs tab in the Validation Summary by clicking on the download icon in the top right corner.
                        </p>
                        <img alt="select workflow type" src="/guidelines/images/image2.png" className="w-[80%] mx-auto" />

                        <br></br>
                        <p>User will get two types of files after downloading:</p>
                        <ol className="list-decimal pl-[2vw]">
                            <li>
                                <b> raw_paragraphs: </b>
                                Paragraphs that did not appear in Stage 1 or Stage 2 and were not validated by any user.

                            </li>
                            <li>
                                <b> validated_paras: </b>
                                Paragraphs validated by users; this file also includes the agreement between multiple users' validations and the results of their validations (only available for previous batches) in the "Class Name" column.

                            </li>
                            <br></br>

                        </ol>
                        <p>Files downloaded will be prefixed with the project name and workflow version.

                        </p>
                        <p>Please note that:</p>
                        <ol className="list-decimal pl-[2vw]">
                            <li>
                                raw_paras can be uploaded as a new dataset on the platform.
                            </li>
                            <li>
                                validated_paras can be re-uploaded as seed paragraphs. (Only paragraphs containing the "Class Name" column will be considered seed paragraphs).

                            </li>
                            <li>
                                validated_paras contains multiple columns in the format listed below that provide information about user validations up to the current batch.

                            </li>
                        </ol>
                        <br></br>
                        <img alt="select workflow type" src="/guidelines/images/image11.png" className="w-[80%] mx-auto" />
                        <br></br>
                        <p>
                            The graphs in the Validation Summary are intended to help users decide when and how to proceed with their project. Users can proceed with current validation data or track the amount of validation performed by team members with validation access at any time.

                        </p>
                    </div>
                </div>
            ),
        },
        {
            key: 12,
            title: "SEED PARAGRAPHS",
            component: (
                <div className="ml-[2.5vw]">
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">
                        Seed Paragraph
                    </div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify">
                        <ol className="pl-[2vw] list-decimal">
                            <li>
                                <p>On the upload page, users can upload two types of datasets:</p>
                                <ol className="pl-[2vw]"
                                    style={{
                                        listStyleType: "lower-alpha"
                                    }}
                                >
                                    <li>
                                        Unannotated: No labels have been assigned to any of the paragraphs.
                                        Similar to  a raw dataset without any annotation present.
                                    </li>
                                    <li>
                                        Partially annotated data: Dataset in which some paragraphs have labels associated to them but the rest are unannotated.
                                    </li>

                                </ol>
                            </li>
                            <li>
                                <p>
                                    The system will use the seed paragraphs to train the model in Stage 2 and Final Model Training.
                                    Partially annotated data can be viewed in the validation summary 'Training Paragraphs' tab.

                                </p>
                            </li>

                            <li>
                                <p>

                                    Download a template for uploading Seed or Partially annotated data for Multiclass workflow
                                    <DownloadTemplates
                                        Seed={false}
                                    />



                                    <p>
                                        The seed paragraphs file must include Mandatory Columns with columns names “Paragraph Text”,
                                        “Class Name”. Class Name must have classes in a list of class names like ['Class 1'] (only one class name per paragraph in multiclass workflow).
                                    </p>
                                </p>
                            </li>

                            <li>
                                Download a template for uploading Seed or Partially annotated data for Multilabel workflow
                                <DownloadTemplates
                                    Seed={false}
                                />
                                <p>
                                    The seed paragraphs file must include Mandatory Columns with columns names “Paragraph Text”, “Class Name”.
                                    Class Name must have classes in a list of class names like ['Class 1','Class 2'].
                                </p>
                                <br></br>
                                <img alt="select workflow type" src="/guidelines/images/image2.png" className="w-[90%] mx-auto" />

                            </li>
                        </ol>
                    </div>
                </div>
            )
        },
        {
            key: 13,
            title: "BILLING MODULE / ACCOUNT SUMMARY",
            component: (
                <div className="ml-[2.5vw]">
                    <div className="text-[1.3vw] font-bold uppercase border-b-[0.1vw] mb-[1.5vw]">
                        BILLING MODULE / ACCOUNT SUMMARY
                    </div>
                    <div className="text-[1.2vw]  pr-[1vw] text-justify">
                        <p>The company/user must have an ACTIVE account plan in order to access the platform.
                            Admin can choose a plan from a list of options on the billing page.</p>
                        <br />
                        <img alt="select workflow type" src="/guidelines/images/image24.png" className="w-[60%] mx-auto" />
                        <br></br>
                        <p>Users can also start with a free trial to get acquainted with the platform.</p>
                        <br />
                        <img alt="select workflow type" src="/guidelines/images/image26.png" className="w-[60%] mx-auto" />
                        <br></br>
                        <p>
                            All company users will have access to the platform after successfully purchasing a plan.
                            <br></br>
                            <br></br>
                            Admin can view their plan usage, billing history, profile, and access management by hovering over the user icon in the top right corner of the screen and selecting "Account Summary."
                        </p>
                        <br />
                        <img alt="select workflow type" src="/guidelines/images/image21.png" className="w-[60%] mx-auto" />
                        <br></br>
                        <p>The account summary page has 4 tabs:. </p>
                        <br />

                        <ol className="pl-[2vw] list-decimal">
                            <li>
                                <p><b>Usage: </b>This tab displays the current data usage as well as information about the number of users,
                                    projects, workflows, prediction calls and classes, and the virtual machine.
                                    Masterlist Annotations are represented by a sunburst chart on the left side.</p>
                            </li>

                            <li>
                                <p> <b>Billing Information: </b> This tab lists the current active plan as well as all previous plans.
                                    To purchase a new plan, click the "Update Subscription" button.</p>
                            </li>
                            <li>
                                <p><b>Profile: </b>
                                    The user can change their First Name, Last Name, and Password on this tab.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Access Management: </b>Only company administrators have access to the Access Management Tab.
                                    This tab allows them to add a user to the organization.</p>
                            </li>
                        </ol>

                    </div>
                </div>
            )
        }
    ];

    let navigator = useNavigate();

    useEffect(() => {
        //contentDivRef.current?.scrollTo({top:0});
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [current]);

    return (
        <AppLayout>
            <div className="">
                <div className={"flex items-center"}>
                    <img className="w-[2vw] h-[2vw]" src="images/leftarrow.svg" alt="left-arrow" onClick={() =>
                        navigator(-1)
                    } />

                    <div className={" mb-[0.7vw] ml-[0.7vw]"}>
                        <div className="text-gray-300 text-[1vw]">ALP/</div>
                        <div className={"text-primarycolor text-[1.5vw]"}>Help & Guidelines</div>
                    </div>
                </div>
            </div>

            <div className={"flex "}>
                <div className=" w-[30%] border-r-[0.1vw] h-[40vw] sticky top-[7vw] custom_scroll overflow-auto">

                    {help_data.map((data: any) => (
                        <div
                            key={data.key}
                            onClick={() => {
                                setCurrent(data.key)
                            }}
                            className={` text-[0.9vw] py-[0.7vw] px-[0.8vw] border-l-[0.4vw] hover:border-primarycolor duration-700 cursor-pointer ${current === data.key ? "border-primarycolor" : 'border-transparent'
                                }`}
                        >
                            {data.title}
                        </div>


                    ))
                    }





                </div>



                <div className={" w-full  px-[0.5vw]  text-[1.2vw]"}>

                    {help_data.find(d => d.key === current)?.component}


                    {/* {current < help_data.length - 1 && (
                        <div
                            className={"border-t-[0.1vw] flex items-center justify-between border-gray-200 ml-[2.5vw] py-[1vw] mt-[2vw]"}
                        >
                            <div>
                                <div className="text-gray-400 ">NEXT</div>
                                <div className={"text-[1.3vw] font-bold"}>{help_data[current + 1]?.title}</div>
                            </div>
                            <div className="cursor-pointer" onClick={() => setCurrent(current < help_data.length ? current + 1 : current)}
                            >
                                <img className="w-[2vw] h-[2vw]" src="images/rightarrow.svg" alt="right-arrow" />

                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        </AppLayout>
    );
};

export default HelpAndGuideline;