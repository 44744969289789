import React from 'react'
import useEntityStore from '../../../store/entityStore'
import { IEntity } from '../types';
import { createEntity } from './_apiCalls';
import useWorkflowStore from '../../../store/useWorkflowStore';
import {v4 as uuid} from 'uuid';

type AddNewEntityProps ={
    isLocked:boolean
}

export default function AddNewEntity({isLocked}:AddNewEntityProps) {
  
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const masterlistLocked:boolean | undefined = useWorkflowStore(state=>state.currentWorkflow)
                                                ?.masterlistLocked;
    const setEntities = useEntityStore(state=>state.setEntities);
    const entities = useEntityStore(state=>state.entities);
    const setCanAddNew = useEntityStore(state=>state.setAddNew);
    const setActive = useEntityStore(state=>state.setActiveEntity);

    const colors:string[]=[
        "#56EEF4",
        "#35FF69",
        "#736B60",
        "#2C6E49",
        "#222E50",
        "#BA3B46",
        "#FF4000",
        "#F72585",
        "#4361EE",
        "#FF0000",
        "#D5A021",
        "#FCFC62",
        "#571F4E",
        "#ECE4B7",
        "#3E5C76",
        "#93B7BE",
        "#24272B",
        "#DDFCAD",
        "#FFC09F",
      

    ]

    const handleAddNewEntity = ()=>{


        let randomIndex:number =0;
        
        //check if the color already exists 
        let isColorUnique = false;
        while(!isColorUnique){
            let tempIndex= Math.floor(Math.random() * colors.length);
            if(!entities.find((entity)=>entity.color === colors[tempIndex] )){
                randomIndex = tempIndex;
                isColorUnique = true;
                
            }
        }
        
        console.log("=== random Index ===",randomIndex);
        const temp = [...entities];
        const newEntity:IEntity ={
            entityId:uuid(),
            entity:"Entity "+entities.length,
            entityDescription:"",
            abbreviation:(( entities.length>=9?"E":"EN")+(entities.length+1)),
            color:colors[randomIndex],
            isEditing:true,
            status:true,
            type:"custom"
        }

        if(workflowId){

            createEntity(workflowId,newEntity);
        }

        temp.push(newEntity);

        setEntities(temp);
        setActive(entities.length)
        //setCanAddNew(false);


    }

    if(masterlistLocked){
        return <div></div>
    }

  return (
    <div className='flex h-[3vw] w-full items-center justify-center
                    border-2 border-dashed my-[1vw] border-[#C4C4C4]
                    bg-[#F0F0F0] mb-[3vw]
                    cursor-pointer
                    '
        onClick={handleAddNewEntity}>
        <p className='text-[#11256D] text-[1vw]'> 
            + Add New Entity
        </p>

    </div>
  )
}
