import React, { useEffect, useState } from 'react'
import {Modal} from 'antd';

interface IMutliUserPieChartModel{
  children:JSX.Element,
  open:boolean,
  setShowModal:(value:boolean)=>void
}

export default function MutliUserPieChartModel({children,open,setShowModal}:IMutliUserPieChartModel) {

  const [showContent,setShowContent] = useState<boolean>(false);
  useEffect(()=>{
    if(open){
      setShowContent(true);
    }else{
      setShowContent(false);
    }

  },[open])


  return (
    <Modal
    open={open}
    footer={null} 
    width={open?'90vw':"80vw"} 
    onCancel={()=>setShowModal(false)}>
      {showContent && children}
    </Modal>
  )
}
