import React from "react";

interface SuccessCompProps {
    successMsg: string;
    show: boolean;
    onClose?: () => void;
}

let SuccessComp: React.FC<SuccessCompProps> = ({ successMsg, show, onClose }) => {
    if (!show) return <></>;

    return (
        <div  className="w-full mb-[0.5vw] rounded-md px-2 py-1 bg-green-50 text-green-500 border-green-500 border flex justify-between">
            <div>Thank You <span className='text-blue-500'>{successMsg}</span> for signing up to DataNeuron ALP. Please log in.</div>
            <div
                className="cursor-pointer hover:font-bold"
                onClick={() => {
                    if (onClose) onClose();
                }}
            >
                X
            </div>
        </div>
    );
};

export default SuccessComp;
