import React, { useEffect, useState } from 'react'

import {ReactComponent as GoogleBertIcon} from '../../assets/google-bert-icon.svg';
import {ReactComponent as MetaLlamaIcon} from '../../assets/meta.svg';

import {ReactComponent as BulletIcon} from '../../assets/bullet-point-icon.svg';
import {ReactComponent as LoadingIcon} from '../../assets/loading_blue.svg';

import { useNavigate } from 'react-router-dom';
import useWorkflowStore from '../../store/useWorkflowStore';
import { getModels, saveModel } from './_apiCalls';
import Button from '../mlc-components/model-comparison/Button';
import { Modal } from 'antd';
import template from '../../template';
import { toast } from 'react-toastify';

const TIIIcon = ({className}:any)=>{
    return <img 
        className={className}
        alt={"Tii falcon icon "}
        src="./images/TII.webp"
    />
}

const LLama27bIcon = ({className}:any)=>{
    return <img 
        className={className}
        alt={"llama2 7b chat hf icon "}
        src="./images/llama.png"
    />
}


const icons:any={
    "google-bert-icon":GoogleBertIcon,
    "llama-2-icon":MetaLlamaIcon,
    "falcon-icon":TIIIcon,
    "meta-llama-icon":LLama27bIcon
}

type Props = {}
const Card = ({model,activeModel,setActiveModel}:any)=>{

    const navigate = useNavigate();
    const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
    const Icon = icons[model?.icon || ""];
    const properties:any = model.properties;
   return  <div className={`h-[65vh] p-[1vw]
                        bg-white drop-shadow-sm hover:drop-shadow-lg
                        flex flex-col gap-[1vw]
                        cursor-pointer
                       ${ activeModel === model.modelName?"border border-primaryBlue":""}
                        `}
                onClick={()=>{
                    // if(!currentWorkflow || !model) return;
                    // saveModel(currentWorkflow?.workflowId,model.modelName)
                    // navigate("/workflow/llm-documentation?workflowId="+currentWorkflow?.workflowId)
                    setActiveModel(model.modelName)
                    }
                    
                }>
            <div>
                {Icon && <Icon className='w-[4vw] h-[4vw] max-w-[80px] max-h-[80px]' />}
            </div>
            <div className='font-semibold text-[1.1vw]'>
                {model.modelName}
            </div>

            <div>
                <ul className="flex flex-col gap-[1vw]">
                    {
                        Object.keys(properties).map(p=>{
                            if(p === "cpu" || p==="gpu" || p === "ram") return null;
                            return <li className=' font-normal text-black text-[1vw] flex items-center gap-[0.5vw]'>
                            <BulletIcon className='w-[1.2vw] h-[1.2vw]' />
                            {properties[p].title } - {properties[p].value}
                        </li>
                        })

                    }
                    <li className=' font-normal text-black text-[1vw] flex items-center gap-[0.5vw]'>
                            <BulletIcon className='w-[1.2vw] h-[1.2vw]' />
                            Minimum System Requirements
                        </li>
                    
                    <li>
                        
                        <ol className='list-disc ml-[15%] text-start space-y-[0.6vw] text-[1vw] font-semibold text-black font-normal'>
                            <li>CPU: {properties?.cpu?.value}</li>
                            <li>GPU: {properties?.gpu?.value}</li>
                            <li>RAM: {properties?.ram?.value}</li>
                        </ol>
                    </li>
                </ul>
            </div>
       

    </div>


}



function LlmModelsComp( ) {
    const [loading,setLoading] = useState<boolean>(true);
    const [modelloading,setModelLoading] = useState<boolean>(false);
    const [models,setModels] = useState<any[]>([]);
    const [activeModel,setActiveModel] = useState<string | null>(null);
    const [isWarnModelOpen,setIsWarnModelOpen] = useState<boolean>(false);

    const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
    const projectType = useWorkflowStore(state=>state.currentProject)?.projectType;
    const navigate = useNavigate();
    const modelType = projectType ?template[projectType].modelType:null;

    useEffect(()=>{
        const fetchModels = async ()=>{
            if(!modelType){
                toast.warn("Could not find LLM models for this project!");
                return;
            }
            setLoading(true)
            let result = await getModels(modelType);
            if(result){
                setModels(result);
            }
            setLoading(false);
        }

        fetchModels();
    },[]);

    if(loading) return <div 
        className='h-[70vh]  flex items-center'>
        <LoadingIcon
        className='w-[8vw] h-[8vw]'
         />

    </div>

  return (
    <div className='p-[1vw]'>

        <Modal
        open={isWarnModelOpen}
        onCancel={()=>setIsWarnModelOpen(false)}
        centered
        title={"Warning"}
        width={"50vw"}
        footer={[
            <Button
            styles={'!w-[5vw] !h-[2.5]'}
            text="Okay"
            loading={loading}
            disabled={loading}
            onClick={() => setIsWarnModelOpen(false)}
        ></Button>
        ]}
        >
         <p>This is an experimental feature, please contact admin for access.</p>   

        </Modal>
        {/* <p className='w-full bg-red-300'>LLM Models</p> */}
        <div className='grid  grid-cols-1 md:grid-cols-3  gap-[3vw]
                        
                        '>
        {
            models.map((model)=>{
                return <Card key={model.name}
                                 model={model}
                                 activeModel ={activeModel}
                                 setActiveModel ={setActiveModel}
                                 />
            })
        }
        </div>
        <div className="flex items-center justify-end">
            <Button
            styles={"!w-[18vw] fixed bottom-[1vw]"} 
            text={"Proceed"}
            loading={modelloading}
            disabled={!activeModel}
            disabledTitle={"Please select a model first"}
            onClick={async()=>{
                  if(!currentWorkflow || !activeModel) return;
                //   if(activeModel !== "Bert-Based-Uncased") {
                //     setIsWarnModelOpen(true);
                //     return;
                //     }
                    setModelLoading(true)
                    await saveModel(currentWorkflow?.workflowId,activeModel)
                    setModelLoading(false)
                    navigate("/workflow/tune-llm-model?workflowId="+currentWorkflow?.workflowId)
            }}
            />
        </div>
    </div>
  )
}

export default LlmModelsComp