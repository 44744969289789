import React from "react";
import { useSearchParams } from "react-router-dom";
import AuthContainer from "../../components/auth/AuthContainer";

let SignUpSuccess: React.FC = () => {
    let [searchParams] = useSearchParams();

    return (
        <AuthContainer>
            <div className="text-[1vw]">
                Thank You <span className="text-blue-500">{searchParams.get("firstName")}</span> for signing up to DataNeuron ALP. <br />
            </div>
            <div className="text-[1vw] mt-[1vw]">
                You will receive an email shortly at <span className="text-blue-500">{searchParams.get("email")}</span> stating whether you have been given
                access to the platform and you will receive the login credentials along with it.

            </div>
            <div className="flex justify-center mt-[2vw]">
                <img className="w-[6vw]" src="/images/success.svg" alt="success" />
            </div>
        </AuthContainer>
    );
};

export default SignUpSuccess;
