import React, { useEffect, useState } from 'react'
import RangeInput from './hyper-parameter-inputs/RangeInput'
import CategoricalInput from './hyper-parameter-inputs/CategoricalInput'
import { ReactComponent as CheckerBoxIcon } from '../../../assets/CheckBoxIcon.svg';
import {ReactComponent as LoadingBlue} from '../../../assets/loading_circle.svg';
import { callAutoMLAPI, callGetSelectedModelAPI, callUpdateSelectedModelAPI } from './_apiCall';
import useWorkflowStore from '../../../store/useWorkflowStore';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import Axios from '../../../utils/Axios';
import ModelCompHeader from './Header';
import { callMLCAutoMLAPI } from '../../mlc-components/model-comparison/_apiCall';
import { toast } from 'react-toastify';
import template from '../../../template';



const ParameterInputField = ({
  parameter,
  onChange,
  addError,
  errors,
  model_info }: any) => {


  const param = model_info?.parameters?.find((p: any) => p.paramName === parameter.paramName);
  switch (parameter.paramType) {
    case "Integer":
      return <RangeInput
        onChange={onChange}
        parameter={parameter}
        min={param.paramStartValue}
        max={param.paramEndValue}
        error={errors.find((err: any) => err.paramName === parameter.paramName)?.error}
        addError={addError}
        isFloat={false}

      />
    case "Float":
      return <RangeInput
        onChange={onChange}
        parameter={parameter}
        min={param.paramStartValue}
        max={param.paramEndValue}
        error={errors.find((err: any) => err.paramName === parameter.paramName)?.error}
        addError={addError}
        isFloat={true}
      />

    case "Categorical":
      return <CategoricalInput
        value={parameter.paramNominalValues}
        onChange={onChange}
        parameter={parameter}
        options={param.paramNominalValues}
        error={errors.find((err: any) => err.paramName === parameter.paramName)?.error}
        addError={addError}
      />

    default:
      return null;
  }
}

const InputBox = ({ trailText,
  setValue, value,
  min, max, disabled }: any) => {
  return <div className='bg-white w-[22vw] h-[2.7vw] 
           rounded-[0.25vw] 
           flex items-center justify-center
           
           '>

    <input
      className='w-full h-full focus:outline-none rounded-[0.25vw]  text-[1vw] 
                border-2  pl-[1vw] pr-[4vw] peer
                invalid:border-red-200 disabled:bg-gray-200
'

      value={value}
      type="number"
      onChange={(e) => setValue(e.target.value)}
      disabled={disabled}
      required
      min={min}
      max={max}
    />
    <label className='  select-none -ml-[4vw] text-[#C4C4C4] peer-invalid:text-red-300 peer-disabled:text-gray-500 text-[0.85vw]'>
      {trailText}
    </label>
  </div>
}


const TimeOutInputs = ({ value, setValue,error,parameters }: any) => {

 

  return (<div>

    <div className='flex gap-x-[4vw] h-[2vw]'>


      {
        parameters && Object.keys(parameters).reverse().map((key)=>{
          return (
            <div className='w-[25%] ' key={key}>
              <div className='flex mb-[1vw] items-center justify-start'>
      
                {/* <div className='w-[10%] flex items-center justify-center'>
      
                  {
                    value?.type === key ?
                      <CheckerBoxIcon className='w-[1.4vw] h-[1.4vw]' />
                      : <div
                        className='w-[1.4vw] h-[1.4vw] border-[0.15vw]
                                   rounded-full inset-md
                                   cursor-pointer'
                        onClick={() => setValue({
                          type: key,
                          value: parameters[key].default || 10
                        })}
                      ></div>
                  }
                </div> */}
                <p
                  className='text-[1vw] px-[0.3vw] font-medium'>
                  {key=== "iteration"?"Number of Iterations":"Number of Minutes"}
                </p>
              </div>
              <InputBox
                disabled={value.type !== key}
                min={parameters[key].min}
                max={parameters[key].max}
                value={value.type === key?value.value:""}
                setValue={(val:any)=>setValue({value:parseInt(val),type:key})}
                trailText={key=== "iteration"?"Iterations":"Minutes"}
              />
              {error && error.paramName === key 
              &&
              <p className='text-red-500 my-[0.2vw] text-[0.9vw]'>{error.error}</p>}
            </div>)
        })
      }


     
    </div>



  </div>)


}


export default function MLCHyperparamerterTuning() {


  const [loading,setLoading] = useState<boolean>(true);
  const [autoMLLoading, setAutoMLLoading] = useState<boolean>(false);
  
  const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId ;
  const project = useWorkflowStore(state=>state.currentProject);
  const navigate = useNavigate();
  
  const [model_info,setModelInfo] = useState<any>();
  const [userInput, setUserInput] = useState<any[]>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const [timeOutInput,setTimeOutInput] = useState<any>({
    type:"iteration",
    value:5
  });


  const handleUserInputChange = (value: any) => {

    const temp = userInput.map((state: any) => {

      if (state.paramName === value.paramName) {
        state = { ...state, ...value }

      }
      return state;
    })
    console.log("=== user input updated ===", temp);
    setUserInput(temp);
  }

  const handleGetModelInfo = async()=>{

    if(!workflowId) return;
    setLoading(true);

    const result = await callGetSelectedModelAPI(workflowId);
  
    console.log("parameters",result.userSelectedModel.parameters);
    setUserInput(result.userSelectedModel.parameters);
    setModelInfo(result.modelInfo);
    setTimeOutInput({
      type:"iteration",
      value:result.modelInfo?.timeOutParams?.iteration?.default
    });

    setLoading(false);
    
  }

  const handleCallAutoML =async()=>{
      
    
      if(!workflowId) return;
      let success = false;
      const projectType = project?.projectType;
      const workflowType = project?.workflowType;
      if(!projectType || !workflowType ) return;
      const autoMLEndpoint = template[projectType][workflowType]
                                                          ?.modelTuning
                                                          ?.automlEndpoint;
      if(!autoMLEndpoint){
        toast.error("Training data not found for this project type");
      }

      setAutoMLLoading(true);


      try{

        await callUpdateSelectedModelAPI(workflowId,userInput,timeOutInput);
       
        if(autoMLEndpoint === "automl"){
           success = await callAutoMLAPI(workflowId);
        }
        if(autoMLEndpoint === "mlc-automl"){
          success =await callMLCAutoMLAPI(workflowId);

        }
  
       
      }catch(err){
        toast.error("Error while calling Model Training Api,please refresh the page and try again");
      }finally{
        if(!success) {
          setAutoMLLoading(false);
          return;}
        setTimeout(()=>{
  
          navigate("/workflow/modeltraining-1?workflowId="+workflowId);
          setAutoMLLoading(false);
        },1000);
      }


  }





  const addError = (error: string | null, paramName: string) => {

    if (error === null) {
      removeError(paramName);
      return;
    }
    console.log("=== adding a new error ===");
    const temp = errors.filter((err: any) => err.paramName !== paramName);
    console.log("=== added error ===", [...temp, { paramName, error }]);
    setErrors([...temp, { paramName, error }]);
  }

  const removeError = (paramName: any) => {
    const temp = errors.filter((err: any) => err.paramName !== paramName);
    setErrors(temp);
  }


  const handleTimeOutParamsChange = (value:any)=>{

   
    let timeOutParams = model_info.timeOutParams; 
    console.log("=== time out value changed ===",value);
    setTimeOutInput(value);
      if(Number.isNaN(value.value)){
        console.log("field is required!");
        addError("Field is required",value.type);
        return;
        
      }
      if(value.value < model_info.timeOutParams?.iteration?.min 
        || value.value > model_info.timeOutParams?.iteration?.max ){
          addError(`${value.type} must be between ${timeOutParams[value.type].min} and ${timeOutParams[value.type].max}`
          ,value.type)
          return;
        }

        addError(null,value.type);

      

    

  




  }


  useEffect(()=>{
       handleGetModelInfo();

  },[])

  useEffect(()=>{
    removeError("durations");
    removeError("iteration");

  },[timeOutInput.type]);

  if(loading){
    return <div 
    className='flex h-[30vw] items-center justify-center'
    >
          <LoadingBlue className='w-[9vw] h-[9vw] animate-spin'  />
    </div>
  }

  return (
    <div>
      <ModelCompHeader
      showLlm={false} />
      <p className='text-[1.1vw] font-bold py-[1vw]'>Hyperparameter Selection</p>

      <form>

        <div className='flex items-center justify-start gap-x-[4vw] gap-y-[3vw] flex-wrap'>
          {
            userInput.map((parameter: any) => {

              return <div className='w-[25%] h-[4vw]'>

                <ParameterInputField
                  onChange={handleUserInputChange}
                  parameter={parameter}
                  model_info={model_info}
                  addError={addError}
                  errors={errors}
                />

              </div>

            })
          }

        </div>
      </form>

      <div className=' h-[10vw]'>

        <p className='text-[1.1vw] font-bold py-[1vw] mt-[3vw]'>
          Hyperparameter Tuning Timeout
        </p>
        <TimeOutInputs
          value={timeOutInput}
          parameters= {model_info.timeOutParams}
          setValue={handleTimeOutParamsChange}
          error={errors.find((e:any)=>e.paramName === "duration" || e.paramName === "iteration")}
          />
      </div>


      <div className='flex justify-end items-center px-[1vw] mt-[1vw]'>
      {<Button
          text={"Reset"}
          styles={"!w-[16vw]"}
          onClick={()=>{
            setUserInput(model_info.parameters);
            setTimeOutInput({
              type:"iteration",
              value:model_info.timeOutParams.iteration.default
            });
            setErrors([]);
          }}

        />}
       {<Button
          text={"Model Training"}
          loading={autoMLLoading}
          disabled={autoMLLoading || errors.length>0 }
          disabledTitle={"Please resolve all errors to train the model"}
          styles={"!w-[16vw]"}
          onClick={handleCallAutoML}

        />}
      </div>
      <div className='flex justify-end items-center px-[1vw] mt-[1vw]'>
      
      </div>
    </div>
  )
}
