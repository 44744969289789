import React, { useState } from 'react'
import {ReactComponent as EditIcon} from '../../../assets/Edit_Icon.svg';
import {ReactComponent as BinIcon} from '../../../assets/Bin_Icon.svg';
import useEntityStore from '../../../store/entityStore';
import {deletEntity as deleteEntityAPI} from "./_apiCalls";
import { Modal } from 'antd';
import useWorkflowStore from '../../../store/useWorkflowStore';


const ConfirmationBox =({deleteEntity,index,close}:any) =>{

    return <div className='text-[1vw] p-[0.7vw] '>
        
        <div className='flex justify-end items-center gap-[1vw] 
                        mt-[3vw] mb-[0.5vw]
                        '>
            <div className='bg-[#11256D] text-white 
                            px-[0.4vw] py-[0.4vw]
                            rounded-[0.2vw]
                            hover:scale-110 duration-200
                            cursor-pointer'
                onClick={()=>deleteEntity()}
                            >Confirm</div>
            <div className='px-[0.4vw] py-[0.4vw]
                            rounded-[0.2vw]
                            border border-transparent
                            hover:scale-110 hover:border-[#11256D] duration-200
                            cursor-pointer'
                 onClick={()=>close()}
                            >Cancel</div>
        </div>
    </div>



}





const EditMenu =({index,disabled,isDefault}:any)=>{
    const entityStore = useEntityStore();
    const [isCBOpen,setIsCBOpen] = useState<boolean>();
    const masterlistLocked:boolean | undefined = useWorkflowStore(state=>state.currentWorkflow)
                                                    ?.masterlistLocked;
    const workflowId:string | undefined = useWorkflowStore(state=>state.currentWorkflow)
                                                    ?.workflowId;

    const deleteEntity = ()=>{
        if(!workflowId) return;
        deleteEntityAPI(entityStore.entities[index].entityId,workflowId);
        const tempEnt = entityStore.entities.filter((ent,i)=>i !== index);

        const canAddNew = !tempEnt.find(ent=>ent.isEditing === true);
        entityStore.setEntities(tempEnt);
        entityStore.setAddNew(canAddNew?true:false);


    }
    const SetAsEdit = ()=>{
        const tempEnt = [...entityStore.entities];
        tempEnt[index].isEditing = true; 
        entityStore.setEntities(tempEnt);


    }
    
    if(masterlistLocked){
        return <div></div>
    }

    return <div
            
    className=' flex gap-[1vw] '
    >
       <Modal 
       open={isCBOpen}
       footer={null}
       onCancel={()=>setIsCBOpen(false)}
       style={{
        top:"30vh",
        
       }}
       width={"30vw"}
       title="Are you sure you want to delete this entity?"
      
       
       >
        <ConfirmationBox
        close={()=>setIsCBOpen(false)}
        deleteEntity={deleteEntity}
        />
       </Modal>

    

      {!disabled && !isDefault && <EditIcon 
       className='hover:scale-125 duration-200 cursor-pointer h-[1.3vw] w-[1.3vw]'
       onClick = {()=>!disabled && SetAsEdit()}
       />}
       {
        isDefault && <div className='w-[1.3vw]'></div>
       }
       <BinIcon
       className='hover:scale-125 duration-200 cursor-pointer h-[1.3vw] w-[1.3vw]'
       onClick = {()=> setIsCBOpen(true)}
       />
        
    </div>

}

export default EditMenu;