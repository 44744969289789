import create from 'zustand';




let advancedMasterlistInfo = {
    nGramValue: 3,      //input from user as drop down
    stopWordStatus: true,  //input from user as toggle button
    intialKeywordCount: 50,
    vocabSize: 20000,
    numPara: 1000000,

    initialKeywordSuggestions: {
        word1: {
            frequency: 234,
            percentPresence: 20,
            sampleParaIds: ["1", "2", "3", "4"],
            rank: 1
        },
        word2: {
            frequency: 200,
            percentPresence: 45,
            sampleParaIds: ["1", "2", "3", "4"],
            rank: 2
        },
        word3: {
            frequency: 456,
            percentPresence: 56,
            sampleParaIds: ["1", "2", "3", "4"],
            rank: 3
        }
    },
}


interface keyword {
    frequency:number,
    percentagePresence:number,
    word:string,
    sampleParaIds:Array<string>,
    rank:number
}
interface selectedWord{
    word:string,
    folder:string
}


interface advanceMasterlistType {
    keywords: Array<keyword>,
    setKeywords:(keywords:Array<any>)=>void,

    selectedWords: Array<selectedWord>,
    setSelectedWords: (selectedWords:Array<any>)=>void,

    folders:any,
    setFolders:(folders:any)=>void,

    paraData: any,
    addParaData:(paras:Array<any>)=>void,

    recentFolders:Array<string>,
    setRecentFolders:(folders:Array<any>)=>void
}


const useAdvancedMasterlist = create<advanceMasterlistType>((set)=>({

    keywords:[],
    setKeywords:(keywords:Array<keyword>)=>set({keywords}),

    selectedWords:[],
    setSelectedWords:(words:Array<selectedWord>)=>{set({selectedWords:words})},

    folders:{},
    setFolders:(folders:Array<string>)=>set({folders}),

    paraData:{},
    addParaData:(paras:Array<any>)=>set({paraData:paras}),

    recentFolders:[],
    setRecentFolders:(folders:Array<string>)=>set({recentFolders:folders})

}))






export const seedInitialSuggestions = (object:any) => {
    
   /// console.log("===amInfo received ===",object);
    let final_object = Object.keys(object).map((key: string,index) => {
        return { ...object[key], word: key,rank:index+1 }
    });
  
    return final_object;
}

export const addWordToSelected = (word: selectedWord,selectedWords:Array<selectedWord>) => {

    let exists=selectedWords.find((sw:any)=>sw===word);

    if(exists) return selectedWords;

    
        selectedWords.push(word);
        return selectedWords;
    



}

export const removeWordFromSelected = (word:selectedWord,selectedWords:Array<selectedWord>) => {

    selectedWords = selectedWords.filter((sw:selectedWord)=>sw.word!==word.word);
    return selectedWords;


}

export const copyWords = (selectedWords:Array<string>) => {

    let text:string=""
    selectedWords.forEach((sw:string,index:number,array:Array<string>)=>{

        if(index<array.length-1)
        {
            text+=`${sw},`
        }
        else{
            text+=sw
        }
       });

    try{

        navigator.clipboard.writeText(text);
    }
    catch{
        alert("Could no copy data");
    }



}

export const updateFolder =(folderName:string,folders:any)=>{

    Object.keys(folders).forEach((folder:any)=>{
            if(folder === folderName)
            {
                folders[folder].updatedOn = new Date();
            }
            
    });

    
    return folders;

}

export const UpdateRecentFolders = (folders:any)=>{
    let keys = Object.keys(folders);

    if(keys.length>2)
    {
        keys.sort((a:string,b:string)=>folders[b]?.updatedOn-folders[a]?.updatedOn);
        console.log(keys);
        return [keys[0],keys[1]];
    }
    else{
        return [];
    }
}






export default useAdvancedMasterlist;