import React, { useState } from "react";
import { toast } from "react-toastify";
import useAuthStore from "../../store/authStore";
import { ReactComponent as LoadingWhite } from '../../assets/loading_white.svg';
import Axios from '../../utils/Axios'

interface ProfileProps { }

const Profile: React.FC<ProfileProps> = () => {
  let [progress, setProgress] = React.useState(false);
  let [progress2, setProgress2] = React.useState(false);
  let [saveDisabled, setSaveDisabled] = React.useState(false);
  let [passwordDisabled, setPasswordDisabled] = React.useState(false);

  let [vis1, setVis1] = useState(false);
  let [vis2, setVis2] = useState(false);
  let [vis3, setVis3] = useState(false);
  let [currentPassword, setCurrentPassword] = useState<string>("");
  let [newPassword, setNewPassword] = useState("");
  let [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const { userDetails } = useAuthStore();

  const { setUserDetails } = useAuthStore();

  let [firstName, setFirstName] = useState<string>(userDetails?.firstName || "");
  let [lastName, setLastName] = useState<string>(userDetails?.lastName || "");

  let handleCurrentPassword = (event: any) => {
    // console.log(event.target.value);
    setCurrentPassword(event.target.value);
  };

  let handleNewPassword = (event: any) => {
    // console.log(event.target.value);
    setNewPassword(event.target.value);
  };

  let handleReEnterNewPassword = (event: any) => {
    // console.log(event.target.value);
    setReEnterNewPassword(event.target.value);
  };

  let validationChecks = (password_new: any, re_enter_password_new: any) => {
    if (password_new.length == 0) {
      toast("New password cannot be empty", {
        type: "warning",
      });
      return false;
    }
    if (re_enter_password_new.length == 0) {
      toast("Re-entered password cannot be empty", {
        type: "warning",
      });
      return false;

    }
    if (password_new != re_enter_password_new) {
      toast("Both password does not match.", {
        type: "warning",
      });
      return false;

    }
    return true;
  };

  let handleSave = async () => {
    if (userDetails?.firstName === firstName.trim() && userDetails?.lastName === lastName.trim())
      return;

    setProgress(true);

    if (firstName.length < 1, lastName.length < 1) {
      toast("Enter valid name.", {
        type: "warning",
      });
      return;
    }

    try {
      const res = await Axios.post("/user/editUser", {
        firstName,
        lastName
      });
      console.log(res);
      let resData = res.data;
      if (resData.result) {
        toast("Details updated successfully.", {
          type: "success",
        });
        setUserDetails({
          firstName: firstName || "",
          lastName: lastName || "",
          email: userDetails?.email || "",
          userId: userDetails?.userId || "",
          company: userDetails?.company || "",
          role: userDetails?.role || "",
          isBillingAccount:userDetails?.isBillingAccount || false,
          associatedBillingAccounts:userDetails?.associatedBillingAccounts || [],
        })
      }
      else {
        toast(resData.message, {
          type: "info",
        });
      }

      setProgress(false);

    }
    catch (err: any) {
      console.log(err)
      toast("Some error occured while updating user details.", { type: "error" });
      setProgress(false);
    }

  }
  let handleChangePassword = async () => {
    setProgress2(true);
    if (!validationChecks(newPassword, reEnterNewPassword)) {
      setProgress2(false);
      return;
    }
    console.log("HERE");

    console.log(
      "Submit info",
      "current password -- " + currentPassword,
      "new password -- " + newPassword,
      "reEnterNewPassword -- " + reEnterNewPassword
    );

    try {
      const res = await Axios.post("/user/changePassword", {
        email: userDetails?.email,
        new_password: newPassword,
        old_password: currentPassword
      });
      console.log(res);
      let resData = res.data;
      if (resData.result) {
        toast("Password changed successfully.", {
          type: "success",
        });
      }
      else {
        toast(resData.message, {
          type: "info",
        });
      }

      setProgress2(false);

    }
    catch (err: any) {
      console.log(err)
      toast("Some error occured while updating password.", { type: "error" });
      setProgress2(false);
    }
  };

  return (
    <div className="flex w-full gap-5 justify-between">
      <div className="flex flex-col space-y-[1.8vw] w-full">
        <div
          className="bg-white p-[1.5vw]"
          style={{ boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)" }}
        >
          <p
            style={{
              fontSize: "1.1vw",
              color: "#1BA94C",
              fontWeight: 500,
            }}
          >
            Your Details
          </p>
          <p
            className="py-[0.2vw]"
            style={{
              fontSize: "1vw",
              color: "#7A7A7A"
            }}
          >
            Your email address is used to sign in and in case we need to contact
            you.
          </p>
          <div className="flex mt-[1vw] space-x-[5vw]">
            <div className="">
              <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                First Name
              </p>
              <input
                className="text-[1vw] px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                type="text"
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value) }}
                style={{
                  width: "22vw",
                  border: "0.1vw solid #C4C4C4"
                }}
              />
            </div>
            <div className="">
              <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                Last Name
              </p>
              <input
                className="text-[1vw] px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                type="text"
                value={lastName}
                onChange={(e) => { setLastName(e.target.value) }}
                style={{
                  width: "22vw",
                  border: "0.1vw solid #C4C4C4"
                }}
              />

            </div>
            <div className="">
              <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                Email Address
              </p>
              <div className="">
                <input
                  className="text-[1vw]  px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                  type="text"
                  value={userDetails?.email}
                  style={{
                    width: "22vw",
                    border: "0.1vw solid #C4C4C4"
                  }}
                  disabled
                />

              </div>
            </div>


          </div>
          <div className="mt-[2.5vw] flex w-full justify-end">
            <button
              className={`py-[0.5vw] w-[10vw] text-white text-[1vw] text-center rounded-[0.3vw] bg-primarycolor ${(userDetails?.firstName === firstName.trim() && userDetails?.lastName === lastName.trim()) || (firstName.length < 2 || lastName.length < 2) ? "cursor-default" : "cursor-pointer duration-300 hover:scale-105"}`}
              onClick={handleSave}
              disabled={(userDetails?.firstName === firstName.trim() && userDetails?.lastName === lastName.trim()) || (firstName.length < 1 || lastName.length < 1)}
            >
              {progress ? <LoadingWhite width={"1.4vw"} height="1.4vw" /> : 'Save Changes'}
            </button>
          </div>
        </div>
        <div
          className="bg-white p-[1.5vw]"
          style={{ boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)" }}
        >
          <p
            style={{
              fontSize: "1.1vw",
              color: "#1BA94C",
              fontWeight: 500,
            }}
          >
            Your Password
          </p>
          <div className="flex mt-[1vw] space-x-[5vw]"

            style={{ fontWeight: 500 }}
          >
            <div className="">
              <p className="mb-[0.5vw] text-[1vw]">Enter Your Current Password</p>
              <div
                className="px-[0.8vw] py-[0.8vw]  bg-gray-100 rounded-[0.35vw] outline-none flex justify-between items-center"
                style={{ width: "22vw", border: "1px solid #C4C4C4" }}
              >
                <input
                  type={`${!vis1 ? "password" : "text"}`}
                  className="text-[1vw] w-[90%] h-full bg-gray-100    outline-none  "
                  onChange={handleCurrentPassword}
                  value={currentPassword}
                />
                <div className="  cursor-pointer" onClick={() => setVis1(!vis1)}>
                  <img src={`${vis1 ? '/images/openeye.svg' : '/images/closedeye.svg'}`} alt="visiblity" className="w-[1.2vw] " />
                </div>

              </div>
            </div>

            <div className="">
              <p className="mb-[0.5vw] text-[1vw]">Enter Your New Password</p>
              <div
                className="px-[0.8vw] py-[0.8vw] bg-gray-100 rounded-[0.35vw] outline-none flex justify-between items-center"
                style={{ width: "22vw", border: "1px solid #C4C4C4" }}
              >
                <input
                  type={`${!vis2 ? "password" : "text"}`}
                  className="text-[1vw]   w-[90%] h-full bg-gray-100  outline-none   "

                  onChange={handleNewPassword}
                  value={newPassword}
                />
                <div className="cursor-pointer" onClick={() => setVis2(!vis2)}>
                  <img src={`${vis2 ? '/images/openeye.svg' : '/images/closedeye.svg'}`} alt="visiblity" className="w-[1.2vw] " />
                </div>
              </div>
            </div>
            <div className="">
              <p className="mb-[0.5vw] text-[1vw]">Re-enter Your New Password</p>
              <div
                className="px-[0.8vw] py-[0.8vw]  bg-gray-100 rounded-[0.35vw] outline-none flex justify-between items-center"
                style={{ width: "22vw", border: "1px solid #C4C4C4" }}
              >
                <input
                  type={`${!vis3 ? "password" : "text"}`}
                  className="text-[1vw] w-[90%] h-full bg-gray-100   outline-none "
                  onChange={handleReEnterNewPassword}
                  value={reEnterNewPassword}
                />
                <div className="cursor-pointer" onClick={() => setVis3(!vis3)}>
                  <img src={`${vis3 ? '/images/openeye.svg' : '/images/closedeye.svg'}`} alt="visiblity" className="w-[1.2vw]" />
                </div>

              </div>
            </div>
          </div>
          <div className="mt-[2.5vw] flex w-full justify-end">
            <button
              className={`py-[0.5vw] w-[12vw] text-white text-[1vw] text-center rounded-[0.3vw]  bg-primarycolor  ${(currentPassword.length <= 0 || reEnterNewPassword.length <= 0 || newPassword.length <= 0) ? "cursor-default" : "cursor-pointer duration-300 hover:scale-105"}`}
              onClick={handleChangePassword}
              disabled={currentPassword.length <= 0 || reEnterNewPassword.length <= 0 || newPassword.length <= 0}
            >
              {progress2 ? <LoadingWhite width={"1.4vw"} height="1.4vw" /> : 'Change Password'}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="w-[30%]">
        <div
          className="bg-white px-5 py-5 w-full"
          style={{
            boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
            fontSize: "15px",
          }}
        >
          <p className="mb-2" style={{ fontWeight: 500, color: "#1BA94C" }}>
            Multi-Factor Authentication
          </p>
          <p className="my-5">
            Add an extra layer of security by setting up email authentication
            when logging in.
          </p>
          <div className="flex">
            <Switch
              onChange={() => setChecked(!checked)}
              style={{ background: `#EAEAEA` }}
            />
            <p className="mx-5" style={{ fontWeight: 500 }}>
              Email Authentication Enabled
            </p>
          </div>
        </div>
      </div> */}
    </div >
  );
};

export default Profile;
