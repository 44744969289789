import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';

interface AddUserCompProps {
  listOfUsers: Array<any>,
  setAddedUsers: any,
  addedUsers: Array<any>,
  disabled:boolean
}

const AddUserComp: React.FC<AddUserCompProps> = ({ listOfUsers, setAddedUsers, addedUsers ,disabled}) => {
  const [inputText, setInputText] = useState<string>("");
  const [matchedResults, setMatchedResults] = useState<Array<any>>([]);

  const [listOpen, setListOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log("list of users",listOfUsers);
    if (inputText === "") {
      setMatchedResults([])
    }

  }, [inputText])

  return (
    <div className={`${(matchedResults.length > 0 || inputText) && 'z-10' } relative m-[0.3vw]  flex justify-center  w-[15vw]  `}>
      <div className=" my-[0.3vw] w-full">

        <input
          disabled={disabled}
          onChange={(e) => {
            setInputText(e.target.value);
            if (listOfUsers.length) {
              if (e.target.value.trim().length === 0) {

                listOfUsers.sort(function compareFn(a: any, b: any) { if (`${a.firstName} ${a.lastName}` <= `${b.firstName} ${b.lastName}`) return -1; else return 1; })
                setMatchedResults(listOfUsers);
              } else {
                let temp: Array<any> = [];
                listOfUsers.forEach((su) => {
                  if (
                    `${su.firstName} ${su.lastName}`
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    su.email
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  ) {
                    temp.push(su);
                  }
                });
                temp.sort(function compareFn(a: any, b: any) { if (`${a.firstName} ${a.lastName}` <= `${b.firstName} ${b.lastName}`) return -1; else return 1; })

                setMatchedResults(temp);
              }
            }
          }}
          className={` ${(matchedResults.length > 0 || inputText) && 'z-10' } 
                    ${disabled?"bg-gray-200 cursor-not-allowed":"bg-gray-50"}
                    relative  focus:outline-none border w-full 
                    rounded-[1vw] text-[1vw] px-[0.8vw] py-[0.2vw]
                     text-center`}
          placeholder="Search User"
          value={inputText}
          type="text"
         
        />
      </div>
      {inputText && <div className=" cursor-pointer fixed inset-0 w-full h-full cursor-default" onClick={() => { setInputText("") }}></div>}
      <div className={`absolute top-[2.2vw] rounded shadow-md bg-white w-full`}>
        {
          matchedResults.length > 0 ? matchedResults.map((elem, index) => {
            return (

              <div key={index} onClick={() => setAddedUsers((prev: any) => {
                if (!addedUsers.find((user: any) => elem.email === user.email)) {
                  setInputText("");
                  return [...prev, elem]
                }
                else {
                  console.log("User already added to the project");
                  toast("The user has already been included in the project.", { type: toast.TYPE.WARNING })
                  return prev;
                }

              })} className="cursor-pointer pl-[0.5vw] hover:bg-gray-100 text-[1vw] my-[0.3vw] bg-white cursor">
                {`${elem.firstName}  ${elem.lastName}`}
              </div>
            )
          }) : inputText && <div className="pl-[0.5vw] text-center my-[0.3vw]  text-[1vw]" >NO MATCH FOUND</div>
        }
      </div>


    </div>
  )
}


export default AddUserComp