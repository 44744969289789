import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectType } from "../../types/store/ProjectStoreTypes";
import useProjectStore from "../../store/projectStore";

import useAuthStore from "../../store/authStore";
import useWorkflowStore from "../../store/useWorkflowStore";

import { FiSearch } from 'react-icons/fi';
import { IconContext } from 'react-icons';

const SearchBarDropDown = () => {
  let projectStore = useProjectStore();
  let [searchResult, setSearchResult] = useState<Array<ProjectType>>([]);
  let [searchTerm, setSearchTerm] = useState<string>("");
  let navigator = useNavigate();

  let authStore = useAuthStore();
  let workflowStore = useWorkflowStore();

  useEffect(() => {
    if (searchTerm !== "") {
      let temp: Array<ProjectType> = [];
      projectStore.projects.forEach((project: any) => {
        if (
         // project.projectInfo.acronym.toUpperCase().startsWith(searchTerm.toUpperCase()) ||
          project.projectInfo.name.toUpperCase().startsWith(searchTerm.toUpperCase()) ||
          project.projectInfo.projectId.toUpperCase().startsWith(searchTerm.toUpperCase())
          // project.createdAt.toUpperCase().startsWith(searchTerm.toUpperCase())
        ) {
          temp.push(project);
        }
      });

      setSearchResult(temp);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  let handleProjectSelection = async (project: any) => {
    //fetching the actve workflow for the current user

    // try {
    //     let userProjectReq = await Axios.post("/userproject/fetchUserProject", {
    //         userId: authStore.userDetails?.userId,
    //     });
    //     let userProject: UserProjectType = userProjectReq.data.data.result;
    //     workflowStore.setUserProject(userProject);
    //     workflowStore.setCurrentProject(project);
    //     navigator(`/workflow/dashboard?workflowId=${userProject.lastAccessedWorkflowId}`);
    // } catch (err) {
    // }
    navigator(`/workflow?workflowId=${project.lastAccessedWorkflowId}`);

  };

  return (
    <>

      {searchTerm.length > 0 && <button className="fixed z-10 inset-0 w-full h-full cursor-default" onClick={() => { setSearchTerm("") }}></button>}
      <div className="relative z-50">
        <div className={"flex"}>
          <input
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            placeholder={"Search for a Project"}
            type="text"
            className={
              "w-full flex-grow py-[0.8vw] px-[1.5vw] text-[1vw] bg-white rounded-l-full outline-none"
            }
          />
          <div
            className={
              "bg-gray-100 w-[7vw] flex justify-center items-center rounded-tr-full cursor-pointer rounded-br-full hover:bg-gray-200 duration-300"
            }
          >
            <IconContext.Provider
              value={{ color: `#2c2c2c`, size: `1.5vw` }}
            >
              <div>
                <FiSearch />
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <div className="absolute w-[99%] ml-[0.4vw] max-h-[45vh] overflow-y-auto shadow-md bg-white transform mt-3 z-50">
          {searchResult.length === 0 && searchTerm.length > 0 && (
            <div className="w-full flex justify-center p-[0.7vw] text-[1.3vw]">
              NO PROJECT FOUND
            </div>
          )}
          {searchResult.map((project: any, idx) => {
            return (
              <div
                onClick={() => { handleProjectSelection(project) }}
                key={project.projectInfo.projectId}
                className={
                  "w-full px-[1vw] py-[0.5vw] flex justify-between hover:bg-gray-100 cursor-pointer"
                }
              >
                <div className="flex space-x-2 items-center">
                  <FiSearch  size={"1vw"}/>
                  <div className={"text-[1.1vw]"}>{project.projectInfo.name}</div>
                  {/* <div className="text-gray-300 text-[1vw]">{project.projectInfo.acronym}</div> */}
                </div>

                {/* <div className="flex items-center">
                {project.activeWorkflow.stages
                  .slice(0, 5)
                  .map((stage, i: number) => {
                    return (
                      <>
                        {i !== 0 && (
                          <div
                            key={i}
                            style={{
                              width: 15,
                              height: 3,
                              backgroundColor: stage.active
                                ? "#11256D"
                                : "lightgray",
                            }}
                          ></div>
                        )}
                        <div
                          key={i + "o"}
                          style={{
                            width: 10,
                            height: 10,
                            backgroundColor: stage.active
                              ? "#11256D"
                              : "lightgray",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </>
                    );
                  })}
              </div> */}
              </div>
            );
          })}
        </div>
      </div>
    </>

  );
};

export default SearchBarDropDown;