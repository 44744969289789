import create from 'zustand';

type numbers ={
    totalParas: number,
    seedClasses: number,
    seedParas: number
}

interface MasterlistSummaryState{
    fetchMasterlistSummary:boolean,
    setFetchSummary:any,
    summary:any[]
    setSummary:any,

    userActions:any,
    setUserActions:any,

    classes:numbers,
    setClasses:any

}

const useMasterlistSummary = create<MasterlistSummaryState>((set:any)=>({

    summary:[],
    fetchMasterlistSummary:true,
    setFetchSummary:(value:boolean)=>set({fetchMasterlistSummary:value}),
    setSummary:(data:any[])=>set({summary:data}),

    userActions:{},
    setUserActions:(userActions:any)=>set({userActions:userActions}),

    classes:{
        totalParas: 0,
        seedClasses: 0,
        seedParas: 0

    },
    setClasses:(data:number)=>set({classes:data})
    
}))


export default useMasterlistSummary;