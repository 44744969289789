import React from "react";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button from "../../components/controls/Button";

interface InvalidWorkflowProps {}
let InvalidWorkflow: React.FC<InvalidWorkflowProps> = () => {
    let navigator = useNavigate();

    let takeUserToHome = () => {
        navigator("/project_dashboard");
    };

    return (
        <div className="w-full flex justify-center items-center h-screen capitalize">
            <div className="text-center">
                <div className="text-3xl">INVALID WORKFLOW</div>
                <br />
                <div className="text-md">
                    <Button text="Go back to home" onClick={takeUserToHome} />
                </div>
            </div>
        </div>
    );
};

export default InvalidWorkflow;
