import React from 'react'
import useWorkflowStore from '../../store/useWorkflowStore'
import template from '../../template';
import AppLayout from '../../components/layouts/AppLayout';
import PageLayout from '../../components/layouts/PageLayout';
import { useNavigate } from 'react-router-dom';
import LlmModelsComp from '../../components/llm-components/LlmModelsComp';

export default function LlmModelsPage() {

    const project = useWorkflowStore(state=>state.currentProject);
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const navigate = useNavigate();

    const projectType = project?.projectType ;
    const workflowType = project?.workflowType;


  return (
    <AppLayout>
        <PageLayout 
        pageIconUrl='/images/active_automl.svg'
        pageTitle={`${project?.name}`}
        pageSubHeading='LLM Model Selection'
        >
            <LlmModelsComp />
        </PageLayout>
    </AppLayout>
  )
}
