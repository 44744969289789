import React, { useEffect, useState } from "react";
import MultiSelectDropDown from './MultiSelectDropdown';
import axios from "../../utils/Axios";
import { toast } from "react-toastify";
import { Select } from "antd";
import { useNavigate } from 'react-router-dom';

//Components
import FolderList from './Folders/FolderList';
import BookMarkMenu from "./Dashboard/BookMarkMenu";
import KeywordCard from "./Dashboard/KeywordCard";


//stores
import useWorkflowStore from "../../store/useWorkflowStore";
import useAuthStore from "../../store/authStore";
import useAdvancedMasterlist,{seedInitialSuggestions,removeWordFromSelected} from "../../store/useAdvancedMasterlist";


//Icons
import {ReactComponent as BookMarkIcon} from '../../assets/bi_bookmark.svg';
import { ReactComponent as LoadingCircle } from '../../assets/loading_circle.svg';
import { ReactComponent as EmptyFolder } from '../../assets/empty_folder.svg';
import {ReactComponent as BookmarkFilled} from "../../assets/bi_bookmark-fill.svg";
import ParaSlider from "./ParaSlider/ParaSlider";
import { AiFillCaretDown } from "react-icons/ai";



interface SortType{
    key:string,
    order:number,
}


let AMDashboard: React.FC = () => {
    const workflowStore = useWorkflowStore();
    let AMStore = useAdvancedMasterlist();
    const navigate = useNavigate();
   
    const {keywords,folders,setKeywords,selectedWords, setFolders, setSelectedWords} = useAdvancedMasterlist(state=>({
        keywords:state.keywords,
        setKeywords:state.setKeywords,
        setRecentFolders:state.setRecentFolders,
        folders:state.folders,
        setFolders:state.setFolders,
        selectedWords:state.selectedWords,
        setSelectedWords:state.setSelectedWords
    }));


    const [loading, setLoading] = useState(true);
    const [filteredList, setFilteredList] = useState<Array<any>>([]);
    const [activeFolder,setActiveFolder] = useState<string>();
    const [sort,setSort] = useState<SortType | undefined>({key:"frequency",order:-1});
    const [activeKeyword, setActiveKeyword] = useState(-1);
    

    //On Active Folder Change
    useEffect(()=>{
        let temp;
        if(activeFolder)
        {

            temp = keywords.filter((fl:any)=>{
                   let word = selectedWords.find(s=>s.folder===activeFolder && s.word === fl.word);
                    return word;
    
                });
        }
        else{
            temp = keywords;
        }
        
        setFilteredList(temp);
        setSort(undefined);
    },[activeFolder]);


    //On Sort Change
    useEffect(()=>{
        if(sort && sort.key === "percentPresence")
        {
            
            let temp = [...filteredList].sort((a:any,b:any)=>
                    a[sort.key].toFixed(2)*100>b[sort.key].toFixed(2)*100?sort.order:-sort.order);
            setFilteredList(temp);
            return;
        }


        if(sort)
        {

            let temp = [...filteredList].sort((a:any,b:any)=> a[sort.key]>b[sort.key]?sort.order:-sort.order);
            setFilteredList(temp);
        }



    },[sort]);

    const [isSetOnce, setIsSetOnce] = useState(false);
    //On mount convert and store initialSuggestion Object
    useEffect(() => {
       // console.log("On mount convert and store initialSuggestion Object");
        setLoading(true);
        let workflow = workflowStore.currentWorkflow;
        let alpdsInfo = workflow?.alpdsInfo;
        let percentage = alpdsInfo?.percentageStatus?.initialMasterlistSuggestionProgress;

        function populateKeywords() {
            //console.log("populateKeywords()");
            
            if (percentage == null && workflow != null) {
                //console.log("Initiate Advanced Masterlist");
                
                axios.post("/advancedmasterlist/intiate_am", { 
                    workflowId: workflow.workflowId,
                    ngram: 1,
                    stopwords: false,
                    number:false,
                    date:false


                 })
                .then((res) => {
                    
                })
                .catch((err) => {
                    console.log(err);
                })
            }
        }

        if (percentage === 100) {
            //console.log("Update Called");
            let object = alpdsInfo?.advancedMasterlistInfo.initialKeywordSuggestions;
            let keys = seedInitialSuggestions(object);
            setKeywords(keys);
            setFilteredList(keys);
            setLoading(false);
        } else {
            populateKeywords();
        }

    }, [workflowStore?.currentWorkflow]);



    const handleSort =(newKey:string)=>{
      
            

            if(sort && sort.key ===newKey)
            {
                let newOrder = sort?.order * -1;
                setSort({key:newKey,order:newOrder});
            }
            else{
                setSort({key:newKey,order:-1});
            }
       
            
            
        
    }

    // if (keywords) {
    //     console.log("keywords : ", filteredList);
    // }

    return (
        <>


            <div className="flex justify-between items-center px-4 border-b py-[0.5vw]  ">
                <p className="text-[1vw] text-[#999999]">Advanced Masterlist</p>
                <div className=" flex items-center space-x-5">

                
                      <MultiSelectDropDown />
               
                <div className="bg-[#11256D]  text-white w-[15vw] text-[0.9vw] py-[0.55vw] my-2 text-center
                                rounded-md cursor-pointer
                                hover:scale-105 duration-500
                                "
                     data-testid="go-to-masterlist-test"
                     onClick={()=>navigate("/workflow/masterlist?workflowId="+workflowStore.currentWorkflow?.workflowId)}>
                    Go to Masterlist
                </div>
                </div>

            </div>

            <div className="">
               <FolderList activeFolder={activeFolder} setActiveFolder = {setActiveFolder} />

            </div>



            {/* Header */}
            <div className='flex w-full   h-[4vw] px-4 sticky top-[4.9vw] z-[90] bg-gray-50 shadow-sm'>

                <div className="flex-[1]  space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                    <span
                    data-testid="sort-keyword-word-test"
                    onClick={()=>handleSort("word")}
                    className="uppercase cursor-pointer">Keyword Name</span>
                    {sort?.key==="word" && <AiFillCaretDown color={"#999999"} className={`duration-200 ${sort.order>0?"rotate-0":"rotate-180"}`} /> }
                </div>

                <div className=" flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                    <span 
                    data-testid="sort-keyword-rank-test"
                    onClick={()=>handleSort("rank")}
                    className="uppercase cursor-pointer ">Keyword Rank</span>
                    {sort?.key==="rank" && <AiFillCaretDown color={"#999999"} className={`duration-200 ${sort.order>0?"rotate-180":"rotate-0"}`} /> }
                </div>

                <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                    <span 
                    data-testid="sort-keyword-frequency-test"
                    onClick={()=>handleSort("frequency")}
                    className="uppercase cursor-pointer">Keyword Frequency</span>
                    {sort?.key==="frequency" && <AiFillCaretDown color={"#999999"} className={`duration-200 ${sort.order>0?"rotate-180":"rotate-0"}`} /> }
                </div>

                <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                    <span className="uppercase cursor-pointer"
                    data-testid="sort-keyword-percentage-test"
                    onClick={()=>handleSort("percentPresence")}
                    >Percentage Coverage</span>
                             {sort?.key==="percentPresence" 
                                 && <AiFillCaretDown color={"#999999"} className={`duration-200 ${sort.order>0?"rotate-180":"rotate-0"}`} /> }
                    
                </div>
                <div className="flex-[0.1]  space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                    <span className="uppercase cursor-pointer"></span>
                </div>





            </div>


            {/* Data */}
            <div className="mt-[1vw] w-full space-y-[0.7vw]">

                {
                    loading && <div className="h-[20vw] flex items-center justify-center">
                        {/* <img src="/images/loading_circle.svg" className="w-[12vw] animate animate-spin" alt="loading" /> */}
                        <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />
                    </div>
                }

                {
                    !loading && !filteredList.length
                    && <div className="h-[20vw] flex flex-col items-center justify-center">
                        <EmptyFolder />
                        <div className="uppercase text-[1.5vw] font-semibold text-[#777777]">No Keywords Found</div>
                      
                    </div>

                }

              
                {!loading && filteredList.length > 0 &&
                   
                    filteredList.map((keyitem:any,index:number)=>
                                <KeywordCard key={"para"+index} keyword={keyitem} activeKeyword={activeKeyword} setActiveKeyword={setActiveKeyword} />)
                  
                }






            </div>

        </>
    );
};

export default AMDashboard;
