import React,{useState,useEffect} from 'react';

import AMPara from './AMPara';

import axios from "../../../utils/Axios";

import { ReactComponent as LoadingCircle } from '../../../assets/loading_circle.svg';

interface ParaSliderProps{
    isOpen:boolean,
    paras:Array<string>,

    loading:boolean,
    setLoading:any

    paraData:Array<string>
    setParaData:any

    keyword:string
}

const ParaSlider:React.FC<ParaSliderProps>=({paras,isOpen,loading,setLoading,paraData,setParaData,keyword})=> {
 
    const [x,setX] = useState<number>(0);
    const [isMounted,setIsMounted] = useState(false);
    const[paragraphs, setParagraphs] = useState<Array<String>>([]);
    
    
    const [width,setWidth] = useState<number>(100);
    
    useEffect(() => {

        function fetchParas() {
            axios.post("/para/getParas", {
                "paraIds": paras
            }).then((res) => {
                
                let paragraphArr: any = [];

                res.data.data.map((item: any) => {
                    paragraphArr.push(item.paraData);
                })

                setParaData(paragraphArr);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
        }

        if (paraData.length > 0) {
            setLoading(false);
        } else {
            fetchParas();
        }

        setIsMounted(true);
        setWidth(100 * paras.length);

    }, [paraData]);

    if(loading) return <div className="bg-white h-[15vw]
                                       flex items-center justify-center
                                       ">
                                <LoadingCircle className="w-[4vw] h-[4vw] animate-spin" />
                                    </div>


    

    if(!loading && paraData.length>0) {
         return (
        
            <div data-testid="paragraph-slider-test" className={`w-full  bg-white flex flex-col justify-center 
                            overflow-x-hidden overflow-y-hidden 
                            ${isMounted?"px-[0.5vw] h-[100%]":"h-0"}
                            duration-300
                            shadow-md `}>
              
                <div 
                style={{
                    transform:`translate(-${x * (100/paraData.length)}%)`,
                    width:`${paraData.length * 100}%`}}
                className={`flex  duration-500 h-[100%] min-h-[15vh]`}>

               { paraData.map((para:string ,index:number)=>{
                    return (
                        <AMPara key={index} para={para} keyword={keyword} ngram={1} />
                    )    
               })}
                </div>
                <div className="flex items-center justify-center space-x-2">
                    {
                        paraData.map((para:string,index:number)=>{
                            return (<div key={index} className={`h-[0.9vw] w-[0.9vw] rounded-full border mt-[5px] mb-[15px]
                            ${x===index?" bg-gray-300 scale-125":"hover:scale-125 duration-500 hover:border-2"}
                            cursor-pointer`} data-testid="paragraph-carousel-nav-test"
                                onClick={()=>setX(index)}></div>)
                        })
                    }
                    
                 
                </div>
              

            </div>
        
    )
    } else {


    return(<div data-testid="no-paragraphs-found-test" className="bg-white h-[15vw] text-[1.5vw] text-[#c9c9c9]
                                                        flex items-center justify-center p-2
                                             ">
                                                <p className="border-t h-full w-full
                                                                flex items-center justify-center">No Para Found</p>
                                             </div>)
    }
}

export default ParaSlider