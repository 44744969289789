import create from "zustand";
import { IEntity } from "../components/ner-components/types";


interface ISelectionData{
    word:string,
    paraId:string,
    startIndex:string,
    endIndex:string
}

interface IEntityStore{
    selection:ISelectionData | null,
    setSelection:(data:ISelectionData | null)=>void

    activeSliceOffset:number | null,
    setActiveSliceOffset:(value:number | null)=>void

    entities:IEntity[],
    setEntities:(entities:IEntity[])=>void,
    canAddNewEntity:boolean,
    setAddNew:(value:boolean)=>void,
    activeEntity:number | null, 
    setActiveEntity:(index:number)=>void

    //para states
    paras:any[],
    setParas:(data:any[])=>void,
    activePara:number,
    setActivePara:(index:number)=>void
    //addEntity:(color:string)=>void

}


const useEntityStore = create<IEntityStore>((set)=>({

    entities:[],
    setEntities:(entities)=>{ set({entities:entities})},

    activeSliceOffset:null,
    setActiveSliceOffset:(value)=>set({activeSliceOffset:value}),

    selection:null,
    setSelection:(word)=>{set({selection:word})},

    activeEntity:null,
    setActiveEntity:(index)=>set({activeEntity:index}),

    canAddNewEntity:true,
    setAddNew:(value)=>set({canAddNewEntity:value}),

    paras:[],
    setParas:(data)=>set({paras:data}),

    activePara:0,
    setActivePara:(index)=>set({activePara:index})
}))

export default useEntityStore;
