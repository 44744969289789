import React from 'react';
import AuthContainerBilling from '../../components/auth/AuthContainerBilling';
import BillingLayout from '../../components/layouts/BillingLayout';

const NoActivePlan:React.FC = () => {

    return (
        <BillingLayout>
            <AuthContainerBilling>
                <div className='flex items-center flex-col justify-center space-y-[2.5vw] text-center'>
                    <img className='w-[7vw]' src="/images/warning.svg" alt="warning" />
                    <h1 className='text-[1.8vw]'>No Active Plan</h1>
                    <p className='text-[1.1vw] text-[#999999]'>Your company does not have an active plan. Please contact your Billing Account Owner to purchase a new plan.</p>

                        <div className="bg-primarycolor cursor-pointer text-center rounded-[0.25vw]" onClick={() => window.location.replace("/")}>
                            <button className="w-[18vw] h-[3vw] cursor-pointer text-white text-[1vw]" type="submit">
                                Try Again
                            </button>
                        </div>
                </div>
            </AuthContainerBilling>
        </BillingLayout>
      )
}

export default NoActivePlan;