import React, { useState } from 'react'
import { ReactComponent as DownChevrolet } from '../../../../assets/down_chevrolet.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg';

interface IMultiClassSelector {
    dropdownOptions: any[],
    disabled?: boolean,
    accepted: String | null,
    node_name: String | null,
    handleTagSelection: (nodeId: string) => void,
    loading: boolean,
    selectionInfo: any;
    keyboardEnabled: boolean
    setKeyboardEnabled: any

}


function MultiClassSelector({
    dropdownOptions,
    accepted,
    node_name,
    disabled,
    handleTagSelection,
    loading,
    selectionInfo,
    keyboardEnabled,
    setKeyboardEnabled
}: IMultiClassSelector) {

    const [open, setOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const filteredList = searchTerm.length > 0 ?
        dropdownOptions.filter((option: any) => option.text.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
        : dropdownOptions
    const getClassName = (name: String) => {
        if (name && name.length > 30) {
            let newName = name.slice(0, 27);
            newName += "...";
            return newName
        }
        else return name;
    }

    return (
        <div>
            {
                open && <div className='h-screen w-screen fixed top-0 left-0 right-0 z-[45]'
                    onClick={() => { setOpen(false); setKeyboardEnabled(true) }}
                ></div>
            }

            <div
                onClick={() => { if (!open && !disabled) { setOpen(true); setKeyboardEnabled(false) } }}
                className={`w-full border-2  py-[0.5vw] px-[0.5vw] rounded-[0.3vw]  
                relative flex items-center justify-between 
                ${open ? "bg-gray-100 border-transparent" : ""}
                ${disabled ? 
                    "bg-[#E0DDDD]  cursor-not-allowed bg-gray-100" 
                    : "cursor-pointer hover:bg-gray-100"}
                `}
            >

                <p className='text-[1vw] select-none text-ellipsis'>
                    {!accepted || accepted === "accepted" ?
                        getClassName(node_name || "")
                        : selectionInfo?.selection ?
                            getClassName(dropdownOptions.find((option) => option.id === selectionInfo.issueId)?.text)
                            : ("No Associated Class")}</p>

                <DownChevrolet className='w-[0.8vw] h-[0.8vw]' />


                {
                    <div className={`absolute  z-[49] left-0 top-[100%]
                        border rounded-md bg-white w-full duration-300 origin-top
                        ${open ? "" : " scale-y-0 opacity-0"}`}>

                        <div className='flex items-center justify-center m-[0.7vw]'>
                            <div className='flex items-center justify-between rounded-full w-full border px-[0.8vw] py-[0.4vw]'>
                                <input className='focus:outline-none w-[85%] text-[0.8vw]'
                                    placeholder='Search class'
                                    value={searchTerm}
                                    onChange={(e: any) => setSearchTerm(e.target.value)}

                                />
                                {searchTerm.length ? <CrossIcon className='w-[0.9vw] h-[0.9vw] cursor-pointer'
                                    onClick={() => setSearchTerm("")} /> : <SearchIcon className='w-[1vw] h-[1vw]' />}
                            </div>

                        </div>
                        <hr />
                        <ul className='bg-white custom_scroll max-h-[10vw]  shadow-lg rounded-b '>
                            {
                                filteredList.length ? filteredList.map((option: any) => {
                                    return (<li key={option.id} className='' onClick={() => { !loading && handleTagSelection(option.id); setOpen(false); setKeyboardEnabled(true) }}      >
                                        <div className='px-[1vw] py-[0.6vw]  cursor-pointer hover:bg-gray-200 focus  :bg-gray-200 text-[0.9vw] ' >
                                            {option.text}</div>
                                        <hr />
                                    </li>)
                                }) : <div className='p-[0.5vw] text-[1vw] text-center '>No Results</div>

                            }

                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}

export default MultiClassSelector