import React, { useEffect, useState } from 'react'
import { ReactComponent as DownChevrolet } from '../../../../assets/down_chevrolet.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg';

// interface ICategoricalProps {
//     dropdownOptions: any[],
//     disabled?: boolean,
//     accepted: String | null,
//     node_name: String | null,
//     handleTagSelection: any,
//     loading: boolean,
//     //selectionInfo: any;
//     keyboardEnabled: boolean
//     setKeyboardEnabled: any,
//     validations:any[]
//     isAnnotated:boolean

//     userValidations:any[]
//     setUserValidation:any,

//     others?:any

// }


interface IParameter {
    paramName: string,
    paramNominalValues:string[]
    paramType: "Numerical" | "Categorical"
}


interface ICategoricalProps{
    parameter:IParameter,
    value:string,
    onChange:any,
    options:any[],
    addError?:(message:string| null,paramName:string)=>void,
    error?:string
}


function CategoricalInput({
   parameter,
   value,
   onChange,
   options,
   addError,
   error
}: ICategoricalProps) {

    const [open, setOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    
    const getClassName = (options: any[]) => {
       // console.log("=== options ===",options);
        if (options && options.length > 0) {
            let name = options.map(n=>{        
                    return  options.find(op=>op===n);
                
            }).join(", ");
            let newName = name.slice(0, 35);
            if(newName.length>=35) newName += "...";
            return newName;
        }
        else return "Please Select A Value";
    }

    const handeStateChange = (value:any)=>{
        console.log("=== dropdown value changed ===",value);
        console.log("=== new value ===",{...parameter,paramNominalValues:value});

        
        onChange({...parameter,paramNominalValues:value});
    }

    useEffect(()=>{

        if(parameter.paramNominalValues.length<=0){
           addError && addError("Please select an option",parameter.paramName);
        }else{
            addError && addError(null,parameter.paramName);
        }


    },[parameter.paramNominalValues])

  

  
    return (
        <div className='w-full'>
            <p className='uppercase text-[0.8vw] font-medium mb-[1vw] '>{parameter.paramName}</p>
            {
                open && <div className='h-screen w-screen fixed top-0 left-0 right-0 z-[45]'
                    onClick={() => { setOpen(false);  }}
                ></div>
            }

            {/*  Container  */}
            <div
                onClick={() => { if (!open ) { setOpen(true); } }}
                className={`w-full border-2  py-[0.5vw] px-[0.5vw] rounded-[0.3vw]  
                relative flex items-center justify-between 
                ${
                     open ?   "bg-gray-100 border-transparent" : "bg-white"
                    }
                ${
                     "cursor-pointer hover:bg-gray-100"}
                `}
            >

              
                              <p className='text-[1vw] select-none'>
                            {getClassName(parameter.paramNominalValues)}</p>

                <DownChevrolet className='w-[0.8vw] h-[0.8vw]' />


                {
                    <div className={`absolute  z-[49] left-0 top-[100%]
                        border rounded-md bg-white w-full duration-300 origin-top
                        ${open ? "" : " scale-y-0 opacity-0"}`}>

                        {/* <div className='flex items-center justify-center m-[0.7vw]'>
                            <div className='flex items-center justify-between rounded-full w-full border px-[0.8vw] py-[0.4vw]'>
                                <input className='focus:outline-none w-[85%] text-[0.8vw]'
                                    placeholder='Search class'
                                    value={searchTerm}
                                    onChange={(e: any) => setSearchTerm(e.target.value)}

                                />
                                {searchTerm.length ? <CrossIcon className='w-[0.9vw] h-[0.9vw] cursor-pointer'
                                    onClick={() => setSearchTerm("")} /> : <SearchIcon className='w-[1vw] h-[1vw]' />}
                            </div>

                        </div>
                        <hr /> */}
                        <ul className='bg-white custom_scroll max-h-[10.5vw]  shadow-lg rounded-b '>
                            {
                                options.length ? options.map((option: any) => {
                                    return (<li 
                                            key={option} 
                                            className='flex '>
                                        <label className='cursor-pointer select-none w-full px-[1vw] flex items-center justify-start hover:bg-gray-200 '>
                                        <input  
                                        type={"checkbox"} 
                                        className="w-[1.2vw] h-[1.2vw] border-2"
                                        checked={parameter.paramNominalValues.find(n=> n === option) ?true: false}
                                        onChange={(e:any) => {
                                            let temp = [...parameter.paramNominalValues];
                                            if(e.target.checked){
                                                temp.push(option)


                                            }else{
                                                temp = temp.filter(t=>t !== option)

                                            }
                                            handeStateChange(temp);
                                        }}
                                        />

                                        <div className=' px-[1vw] w-[90%] py-[0.6vw]  cursor-pointer  focus  :bg-gray-200 text-[0.9vw] ' >
                                        {option}</div>
                                        </label>
                                        
                                    </li>)
                                }) : <div className='p-[0.5vw] text-[1vw] text-center '>No Results</div>

                            }

                        </ul>
                    </div>
                }
            </div>
                {error && <p className='text-[0.9vw] text-red-500 px-[0.4vw]'>{error}</p>}
        </div>
    )
}

export default CategoricalInput

















// import { Select } from 'antd'
// import React from 'react'

// interface IParameter {
//     paramName: string,
//     paramNominalValues:string[]
//     paramType: "Numerical" | "Categorical"
// }

// interface ICategoricalProps{
//     parameter:IParameter,
//     value:string,
//     onChange:any,
//     options:any[]
// }


// export default function CategoricalInput({
//     parameter
//     ,value,
//     options,
// onChange}:ICategoricalProps) {

//     const handeStateChange = (value:any)=>{
//         console.log("=== dropdown value changed ===",value);
//         console.log("=== new value ===",{...parameter,paramNominalValues:value});
//         onChange({...parameter,paramNominalValues:value});
//     }



//   return (
//     <div className='flex flex-col  justify-center'>
//         <p className='uppercase text-[0.8vw] font-medium mb-[1vw] '>{parameter.paramName}</p>
//         <Select
//         mode="multiple"
//         className="w-full !text-[1.1vw] bg-white  rounded border "
//         bordered={false}
//         defaultValue={value}
//         onChange={(value)=>handeStateChange(value)}
        
//         >
//             { options.map(param=>{

//                return  <Select.Option
//                 value={param}
//                 label={param}
//                 >

//                 </Select.Option>
//             })
//             }
//         </Select>
//     </div>
//   )
// }
