export let STAGE2_NODES_REQUEST = "STAGE2_NODES_REQUEST"
export let STAGE2_PRE_PARAS = "STAGE2_PRE_PARAS"
export let STAGE2_ADD_PARAS = "STAGE2_ADD_PARAS"
export let STAGE2_PROGRESS_UPDATE = "STAGE2_PROGRESS_UPDATE"
export let STAGE2_ADD_REJECT_PARAS = "STAGE2_ADD_REJECT_PARAS"
export let STAGE2_UPDATE_REJECT_PARA="STAGE2_UPDATE_REJECT_PARA"
export let INIT_REJECT_PARA = "STAGE2_INIT_REJECT_PARA"
export let REJECT_PARA = "STAGE2_REJECT_PARA"
export let ACCEPT_PARA = "STAGE2_ACCEPT_PARA"
export let UPDATE_PARA = "STAGE2_UPDATE_PARA"
export let SET_STAGE2_BATCH2_INIT="SET_STAGE2_BATCH2_INIT"
export let SET_STAGE2_BATCH3_INIT="SET_STAGE2_BATCH3_INIT"