import React from 'react';
import { NavLink } from 'react-router-dom';
import AuthContainerBilling from '../auth/AuthContainerBilling';
import BillingLayout from '../layouts/BillingLayout';

const BillingFailure:React.FC = () => {

    return (
        <BillingLayout>
            <AuthContainerBilling>
                <div className='flex items-center flex-col justify-center space-y-[2.5vw] text-center'>
                    <img className='w-[8vw]' src="/images/failure.svg" alt="failure" />
                    <h1 className='text-[1.8vw]'>Transaction Failed!</h1>
                    <p className='text-[1.1vw] text-[#999999]'>We weren’t able to process your payment! Please try again.</p>

                    <NavLink to='/billing'>
                        <div className="bg-primarycolor cursor-pointer text-center rounded-[0.25vw]">
                            <button className="w-[18vw] h-[3vw] cursor-pointer text-white text-[1vw]" type="submit">
                                Try Again
                            </button>
                        </div>
                    </NavLink>
                </div>
            </AuthContainerBilling>
        </BillingLayout>
      )
}

export default BillingFailure;