import React,{useEffect} from "react";
import AppLayout from "../components/layouts/AppLayout";
import Stage1Comp from '../components/stage-1';
import PageLayout from '../components/layouts/PageLayout';
import useWorkflowStore from '../store/useWorkflowStore';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import useKeyboard from "../hooks/useKeyboard";
import template from "../template";


interface MasterlistProps {}
let Masterlist: React.FC<MasterlistProps> = () => {
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const workflowStore =useWorkflowStore();
    let workflowId = workflow?.workflowId;
    const project =useWorkflowStore(state=>state.currentProject);
    const navigate = useNavigate();
    const stage1 =project? template[project?.projectType || ""][project?.workflowType || ""]?.stage1 || undefined:undefined;

    useEffect(()=>{

        if(project?.workflowType &&  project?.workflowType!== "annotation_prediction_workflow")
        {
            toast("Stage 1 is not supported in  annotation workflow",{type:toast.TYPE.WARNING});
            navigate("/workflow?workflowId="+workflowId);
        }
       
        if(workflowId && !workflowStore.userProject?.VALIDATION_ACCESS)
        {
            toast("You do not have the authorization to perform validations. Please contact your project creator.",{type:toast.TYPE.WARNING});
            navigate("/workflow?workflowId="+workflowId);
        }

    },[workflowStore.currentWorkflow?.workflowId])
    return (
        <AppLayout>
            <PageLayout pageSubHeading="Validation" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/active_validation.svg">
           {/* <Stage1Comp /> */}
           {stage1!==undefined?React.createElement(stage1.component) :null}
            </PageLayout>
        </AppLayout>
    );
};

export default Masterlist;
