import { Select } from 'antd';
import React, { ReactElement, useState } from 'react'
import { toast } from 'react-toastify';
import { SummaryDataType } from './SummaryComp';
import { getAnnotatedPrompts } from '../../_apiCalls';
import useWorkflowStore from '../../../../store/useWorkflowStore';
import useAuthStore from '../../../../store/authStore';


interface ISummaryTabProps{
   data:SummaryDataType


}

interface ITRowProps{
    
    
}




export default function FtSummaryTab({
    
data
}:ISummaryTabProps) {


    const [loading,setLoading] = useState<boolean>(false);
    let currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
    let currentProject = useWorkflowStore(state=>state.currentProject);
    const user = useAuthStore(state=>state.userDetails);
       //export entities 
       const downloadData=(data:any,filename:string)=>{

        const link = document.createElement<"a">("a");
            const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
            link.href = jsonString;
            link.download = filename;
            link.click();
    
            setTimeout(()=>{
                link.remove();
            },1000);
    
      }

      
  const exportValidatedData = async()=>{
    if(!currentWorkflow) return;

    try{
        const {error,data} = await getAnnotatedPrompts(currentWorkflow?.workflowId);

        if(error) throw new Error("There was an error while fetching the data for export");
        if(!data || !data?.length) throw new Error("There is no data to export, please annotate some data and try again!");

        let final_data:any ={
            project_name:currentProject?.name,
            workflow_version:currentWorkflow?.workflowName,
            annotator:user?.email,
            annotated_data:[],

        }
        data.forEach((prompt:any)=>{
            let validationData = prompt.result;
            
            let result:any[] = [];

            prompt.result.forEach((res:any)=>{
                //console.log("=== prompt ===>",prompt);
               // console.log("=== result ===>",res);
                let temp:any ={};
                let response_data =prompt.prompt_data.data.find((response:any)=>response.responseId === res.responseId);
               // console.log("=== response data ===>",response_data);
                temp.rank = res.rank;
                temp.relevance= res.relevance;
                temp.response = response_data.text;
                temp.source=response_data.source

                result.push(temp);
            })

            final_data.annotated_data.push({
                prompt:prompt.prompt_data.prompt,
                result:result
            })
            
        })
        setLoading(false);
        downloadData(final_data,`${currentProject?.name}_validated_prompt.json`)
        console.log("=== data to export ===>",final_data);


        // if(data){
        //     let validationData = result.data.data;
        //     const cached_ent:any={};
        //     const final_data:any  ={

        //         "project name":currentProject?.name,
        //         "workflow name":currentWorkflow.workflowName,
        //         "ner info":validationData.map((v:any)=>{

        //             let userValidations:any[] = v.validations[user?.userId || ""];
                   
        //             return {
        //                 text:v.para_info.paraData,
        //                 validations:userValidations.map((v2)=>{
        //                 let entity:string =""
        //                 if(cached_ent[v2.entityId]){
        //                     entity=cached_ent[v2.entityId]
        //                 }else{
        //                     const found_ent = entities.find(ent=>ent.entityId === v2.entityId);
        //                     cached_ent[v2.entityId] = found_ent?.entity

        //                 };
        //                     return {
        //                         startIndex:v2.startIndex,
        //                         endIndex:v2.endIndex,
        //                         word:v2.word,
        //                         entity:cached_ent[v2.entityId]
        //                     }



        //                 })
        //             }


        //         })

        //     }
        //     //end of final data

        //     downloadData(final_data,`${currentProject?.name}_validated_paras.json`)



        // }




    }catch(e){
      setLoading(false);
        toast.warn("There was an error while export validated data");
        console.log("=== error while downloading validation data ===",e);
    }




  }


  return (<div className=' w-full h-full bg-[#fAFaFA] flex flex-col gap-[2vw] ' >
                {/* Summary Container */}
                <div className=' flex gap-[1vw] '>
                    {/* Masterlist Summary */}
                    {/* Data Summary */}
                    <div className=' flex-1  p-[0.7vw] h-[11vw] bg-white drop-shadow-md '>
                        <h1 className="text-[1vw] font-bold text-[#7A7A7A]">Prompt Summary</h1>
                        <div className='flex flex-col mt-3 gap-[1vw] '>
                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Total Prompts</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{data.total}</p>
                            </div>

                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Generated Reponses</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{data.generatedRes}</p>
                            </div>
                          
                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>User Added Responses</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{data.userRes}</p>
                            </div>


                        </div>

                    </div>

                </div>
                

               
            </div>
  )
}
