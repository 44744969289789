import React from 'react'
import useEntityStore from '../../../../store/entityStore'
import NERSidebarEntity from './NERSidebarEntity';

interface INerSideBar{
  setCount:any
}


export default function NerSidebar({setCount}:INerSideBar) {

    const entities = useEntityStore(state=>state.entities);


  return (
    <div className='sticky top-[8.5vw]'>

    <div className='flex flex-wrap gap-[1vw] py-[1vw] px-[0.5vw] '>


        {entities.length?entities.map((entity)=>{

             return entity.status? <NERSidebarEntity
                entity={entity}
                setCount={setCount}
            />:null


        }):null}

        


    </div>
    {/* <div
              className=' text-[1.2vw] text-[#7a7a7a]  p-2 border-b border-t mt-[2vw] '
              >Rules</div> */}
    </div>
  )
}
