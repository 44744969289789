import {ReactComponent as LoadingWhite} from '../../../assets/loading_white.svg';

const Button =({text,loading,onClick,disabled,disabledTitle,styles}:any)=>{

    return (<button
      disabled={disabled}
      title={disabled && disabledTitle}
      onClick={!disabled ? onClick:()=>{}}
      className={`uppercase duration-200  w-[13vw]
            text-white text-[0.9vw] 
             h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
             disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
            border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md 
            ${styles}  `}
    >
      {loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                      : text}
    </button>)
  
  }


export default Button;