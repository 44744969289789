import { Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//stores
import useWorkflowStore from '../../store/useWorkflowStore';
import useAdvancedMasterlist from '../../store/useAdvancedMasterlist';

//Components
import ListItem from './ListItem';


//Icons
import {ReactComponent as AMIcon} from '../../assets/AM_Sidebar.svg';
import {ReactComponent as SearchIcon} from '../../assets/search.svg';
import {ReactComponent as CrossIcon} from '../../assets/cross.svg';
import {ReactComponent as ArrowLeftIcon} from '../../assets/arrow-down.svg';







interface SideBarProps {
    isOpen:boolean,
    setIsOpen:any
}



 const Sidebar:React.FC<SideBarProps>=({isOpen,setIsOpen})=>{
   
    const navigate =useNavigate();

    const workflowStore = useWorkflowStore();
    
    const [searchTerm,setSearchTerm] = useState<string>("");
    const [folder,setFolder] = useState<string>("");
    const [currentWords,setCurrentWords] = useState<Array<any>>();


    const {setFolders, setSelectedWords, selectedWords, folders} = useAdvancedMasterlist(state=>({
      setFolders:state.setFolders,
      setSelectedWords:state.setSelectedWords,
      selectedWords:state.selectedWords,
      folders:state.folders,
    }));
  
  
    const goToAM= ()=>
            navigate("/workflow/advancedmasterlist?workflowId="+workflowStore.currentWorkflow?.workflowId);

    useEffect(()=>{
     // console.log("value changed",folder,searchTerm);
      

        let temp =  selectedWords.filter((word:any)=>{
          let isValid:boolean = true;
          if(searchTerm.length > 0) {
            if(!word.word.toLowerCase().includes(searchTerm.toLowerCase())) {
              isValid=false;
            }
          }      
          if(folder.length > 0) {
            if(word.folder.toLowerCase()!==folder.toLowerCase()) {
              isValid=false;
            }
          }       
           
          return isValid;
        });
       // console.log("-- temp --",temp);
        setCurrentWords(temp); 

    },[searchTerm,folder, selectedWords, workflowStore?.currentWorkflow?.workflowId]);


    return <>
         <div className={`flex items-center
          ${isOpen?"px-[0.5vw] justify-between":"justify-center"} 
          w-full border-b 
          mb-[0.5vw] py-[0.6vw] h-[3vw]`}>

          <p className={`text-[1vw] font-bold text-[#11256D]
          overflow-x-hidden
          ${isOpen?"px-4  cursor-pointer  opacity-100  duration-200 delay-300 ":"w-0 opacity-0 "}     `}
          onClick={()=>isOpen && goToAM()} data-testid="go-to-AM-test"
          >
            Go to Advanced Masterlist
            </p>
            {/* <Tooltip
             title={"Go To Advanced Masterlist"}
             color="#777777"
             > */}
            <ArrowLeftIcon className={`w-[1.8vw] h-[1.8vw] -rotate-90 -ml-[2vw] 
                                       ${isOpen?"delay-300 cursor-pointer":"opacity-0"}`}
            onClick={()=>isOpen && goToAM()}
            />

            {/* </Tooltip> */}
            <Tooltip 
            title={`${isOpen?"Close":"Open"}  Advanced Masterlist`} 
            color="#777777"
            
            >

          <AMIcon data-testid="open-masterlist-sidebar-test"
          onClick={()=>setIsOpen(!isOpen)} 
          className={` w-[1.5vw] h-[1.5vw] cursor-pointer hover:scale-110 duration-700`} />
            </Tooltip>
        </div>
      {isOpen &&  <div className={`flex items-center flex-col items-center justify-cener 
                        space-y-[0.6vw] w-full border-b  
                        mb-[0.5vw] 
                        px-[0.2vw] py-[0.6vw]`}>
            <div className='w-full h-[2vw] rounded-full bg-white px-4 
                            flex items-center justify-between '>
            <input className=' py-[0.2vw] text-[0.8vw] w-[90%]
                              border-none focus:border-none focus:outline-none ' 
            onChange={(e:any)=>setSearchTerm(e?.target?.value)}
            value={searchTerm}
            placeholder='Find Keywords' 
            data-testid="keywords-search-test"/>
            {
              searchTerm.length>0?<CrossIcon color={"black"} className='w-[0.8vw] h-[0.8vw] text-black cursor-pointer'
                                   onClick={()=>setSearchTerm("")} 
                                   data-testid="keyword-search-close-test"/>
              : <SearchIcon className='w-[1vw] h-[1vw]'  />
            }
           

            </div>
            <div className="flex-1 w-full">
                        <Select
                            defaultActiveFirstOption={true}
                            placeholder="Select a folder"
                            onChange={(value:string)=>setFolder(value)}
                            className="flex-1 w-full !bg-white border !text-[1vw] !py-[0.4vw]  rounded bg-[#EAEAEA] "
                            bordered={false}
                            data-testid="selected-folders-dropdown-test"
                            >

                                <Select.Option 
                            
                              className="text-[0.8vw] py-3" 
                              value={""}
                              
                              >All</Select.Option>
                            { folders && Object.keys(folders).map(
                              (folder:any,index:number)=><Select.Option 
                              key={index}
                              className="text-[0.8vw] py-3" 
                              value={folder}
                              data-testid="selected-folders-options-test"
                              >{folder}</Select.Option>)
                            }
                            
                            
                        </Select>
                    </div>
           
        </div>}
      
     
       {isOpen && <div className="custom_scroll h-[60%]">
          
              { currentWords && currentWords.map(
                (word:any, index)=>  <ListItem key={index} keyword={word}  />)}
            
          

        </div>}
    
    </>

}

export default Sidebar;