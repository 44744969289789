import React, {  } from 'react';
import moment from 'moment';

interface PlanProps{
    plan: any
}

const PlanCard:React.FC<PlanProps> = ({ plan }) => {

    return (
        <div className='flex w-full hover:shadow-md h-[4vw] bg-white px-4'>
            <div className="flex-[0.4] space-x-1 font-medium text-[1vw] flex justify-start items-center">
                <span>{plan.invoiceName}</span>
            </div>

            <div className="flex-[0.3] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
                <span>{moment(plan.billingDate).format('D MMM YYYY')}</span>
            </div>

            <div className="flex-[0.2] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
                <span>{plan.amount}</span>
            </div>

            <div className="flex-[0.2] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
                <span>{plan.plan}</span>
            </div>
            
            <div className="flex-[0.2] space-x-1 text-[0.9vw] flex justify-start items-center">
                <span className={`block px-[2vh] py-[0.7vh] rounded-[2vh] lowercase first-letter:uppercase ${
                    plan.status === "UPCOMING" ? 'bg-[#11256D1A] text-[#11256D]' : 
                    plan.status === "EXPIRED" ? 'bg-[#a91b1b14] text-[#a91b1b]' : 
                    plan.status === "ACTIVE" ? 'bg-[#1BA94C14] text-[#1BA94C]' : 
                    'bg-[#c2991214] text-[#c29912]'
                }`}>{plan.status}</span>
            </div>

            {/* Download column */}
            {/* <div className="flex-[0.2] space-x-1 text-[#0000FF] text-[1vw] flex justify-start items-center">
                {plan.download}
            </div> */}
        </div>
    )
}

export default PlanCard;