import React, { useEffect, useRef, useState } from 'react';
import Edit from './Edit';

import {ReactComponent as SendIcon} from '../../../../assets/send-icon.svg';
import {ReactComponent as EditIcon} from '../../../../assets/edit-icon.svg';
import {ReactComponent as LoadingWhite} from '../../../../assets/loading_white.svg';

import {RiArrowGoBackFill} from 'react-icons/ri';
import { Modal, Popover } from 'antd';
import Button from '../../../llm-components/model-comparison/Button';
import useWorkflowStore from '../../../../store/useWorkflowStore';
import { toast } from 'react-toastify';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

type Props={
    promptId:string,
    text:string,
    readOnly?:boolean
    
    editable:boolean,
    edit:boolean

    setText:any,
    setEditState:any,

    isNewPrompt?:boolean

    predictOnPrompt?:any
    predictionLoading?:boolean

    savePrompt?:any,

    isPredicted?:boolean
}

const Text =({text,expand}:any)=>{
   
    const textToShow = text.length>500?
                        !expand?text.slice(0,600)+"...":text
                            :text;
    return <p 
    dangerouslySetInnerHTML={{
        __html:textToShow
    }}
    className='border border-[0.15vw] rounded-[0.5vw] 
    whitespace-pre-wrap 
                            min-h-[4vw] 
                            overflow-auto custom_scroll
                            p-[1vw] pt-[1.4vw]
                            text-[0.9vw]
                            border-[#777777] bg-[#FAFAFA]
                            '>
       
    </p>

}

const EditText =({text,onChange}:any)=>{

    const ref = useRef<HTMLTextAreaElement>(null);
    
   
    useEffect(()=>{
        
        if(ref?.current){
            ref?.current?.focus();
        }

    },[])

    return <textarea 
    data-testid="textarea"
    ref={ref}
    value={text}
    onKeyDown={(e:any)=>{
        console.log("key pressed",e);
        if(e.key === "Tab") e.preventDefault();
    }}
    onChange={(e)=>onChange(e.target.value)}
    placeholder='Type your prompt here'
    className='border border-[0.14vw] border-[#777777]
                         rounded-[0.5vw] w-full !h-[15vw]
                         custom_scroll
                            min-h-[7vw]
                            p-[1vw]
                            text-[0.9vw]
                             bg-[#FAFAFA]
                            focus:outline-none
                            ' >

                            </textarea>

}




function Prompt({text,
    edit,editable,setText,
    setEditState,
isNewPrompt,
readOnly,
predictOnPrompt,
predictionLoading,
isPredicted,

savePrompt
}:Props) {
    const [tempText,setTempText] = useState<string>();
    const [isModelOpen,setIsModelOpen] = useState<boolean>(false);

    const [canCallPrediction,setCallPrediction] = useState<boolean>();

    const [expand,setExpand] = useState<boolean>(false);

    const apiCallLimit =100;
    const workflow = useWorkflowStore(state=>state.currentWorkflow); 
    const meta = workflow?.alpdsInfo.meta;

    const TryCanCallPrediction= ():boolean=>{

        if(!meta) return true;
        if(meta.type === "production") return true;

        if(meta.hasOwnProperty("apiCallUsed") && meta.apiCallUsed < apiCallLimit){
            return true
        }
        return false;


    }

    useEffect(()=>{
       // console.log("updating prediction check",TryCanCallPrediction())
        setCallPrediction(TryCanCallPrediction());
    },[workflow,prompt]);
    
    useEffect(()=>{
        setTempText(text);
    },[text]);

  return (
    <div className='flex items-center space-x-[1vw] py-[2vw] '
    data-testid="prompt-component"
    >
        
        {/* Confirmation Modal */}
        <Modal
        data-testid="modal"
        title="Warning"
        open={isModelOpen}
        width={"50vw"}
        centered
        onCancel={()=>setIsModelOpen(false)}
        footer={[
            <Button 
            text="Cancel"
            onClick={()=>setIsModelOpen(false)}
            
            styles={"!bg-white text-primaryBlue border-primaryBlue !w-[10vw]"}/>,
            <Button 
            testId="confirm"
            text="Confirm"
            onClick={()=>{
                setIsModelOpen(false);
                predictOnPrompt();
            }}
            styles={"!w-[10vw]"}/>
        ]}

        >
            <p className='whitespace-prewrap !text-[1.1vw] mb-[2vw]'>Your previous data will be overwritten if you generate a new response.
               <br/> Are you sure you want to go ahead?</p>
        </Modal>


        <div className='flex-1 relative'>
            {text.length>600 &&  
            !edit
            &&<div className='absolute top-0 right-0'>
            <MdOutlineKeyboardArrowDown
            className={`cursor-pointer duration-200 ${expand?"rotate-180":""}`}
            onClick={()=>setExpand(!expand)} 
            size={40}
            />
            </div>}

            {edit?<EditText 
            text={isNewPrompt?text:tempText}
            onChange={isNewPrompt?setText:setTempText}
            
            />:<Text 
            text={text}
            expand={expand}
            />}
        </div>

        {editable && <div className='flex space-x-[1vw]'>
            <div 
            data-testid="Save"
            onClick={()=>{
                if(predictionLoading) return;
                if(!edit){
                    setEditState(true);
                }
                else{
                    setText(tempText);
                    setEditState(false);
                    if(tempText !== text){
                        savePrompt(tempText);
                    }
                    
                }
            }}
            className={`flex items-center justify-center
                            h-[3vw] w-[3vw] 
                            border 
                            rounded-full border
                            ${predictionLoading?"bg-gray-200 cursor-not-allowed":"border-primaryBlue "}
                            hover:scale-110 duration-200
                            cursor-pointer`}>
                {edit?
                <div
                >Save</div>
                :
                    <EditIcon 
                    data-testid="edit-button"
                className='h-[1.5vw] w-[1.5vw]'/>}
            </div>
            {edit && <div 
            onClick={()=>predictionLoading ? ()=>{}:setEditState(false)}
            className={`flex items-center justify-center
                            h-[3vw] w-[3vw] 
                            border-primaryBlue 
                            
                            rounded-full border
                            hover:scale-110 duration-200
                            cursor-pointer
                            '`}>
                <RiArrowGoBackFill 
                
                className='h-[1.5vw] w-[1.5vw]'/>
            </div>}
           
          
        </div>
        }
         {!edit && !readOnly && <div 
         onClick={async()=>{
            if(!canCallPrediction) {
                toast.warn("You have exhausted api calls limit for this project");
                return;
            }
            if(predictionLoading) return;
            if(edit) return;

            if(isPredicted){
                setIsModelOpen(true);
                
            }else{
                await predictOnPrompt();
            }
         }}
         className='flex items-center justify-center
                            h-[3vw] w-[3vw] 
                            bg-primaryBlue 
                            rounded-full border
                            hover:scale-110 duration-200
                            cursor-pointer
                            '>
               {!predictionLoading && <SendIcon 
                data-testid="Send"
                className='h-[1.5vw] w-[1.5vw]'/>}

               {predictionLoading &&
               <Popover 
               open={true}
               content={"Generating answers!"}>
                    <LoadingWhite 
                    data-testid="loading-icon"
                className='h-[1.7vw] w-[1.7vw] animate-spin'/>
               </Popover>
               }
            </div>}
        
    </div>
  )
}

export default Prompt