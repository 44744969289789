import React from "react";
import AppLayout from "../../components/layouts/AppLayout";
import PageLayout from '../../components/layouts/PageLayout';
import useWorkflowStore from '../../store/useWorkflowStore';
import DeployComp from '../../components/workflow/model-training-2/deployment';
import {useLocation} from 'react-router-dom';

interface MasterlistProps { }
let Masterlist: React.FC<MasterlistProps> = () => {
    const workflow = useWorkflowStore(state => state.currentWorkflow);
    const project = useWorkflowStore(state=>state.currentProject);
    const location:any = useLocation();
    console.log("=== Location ===",location?.state?.dep);
    let dep:string = location?.state?.dep as string ||  "deploy1";
    return (
        <AppLayout>
            <PageLayout pageSubHeading="deployment" pageTitle={`${project?.name}`} pageIconUrl="/images/deployWhite.svg">
                <DeployComp dep={dep} />
            </PageLayout>
        </AppLayout>
    );
};


export default Masterlist;
