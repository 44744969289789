import React, { useEffect, useState } from 'react'
import RangeInput from './hyper-parameter-inputs/RangeInput'
import CategoricalInput from './hyper-parameter-inputs/CategoricalInput'
import { ReactComponent as CheckerBoxIcon } from '../../../assets/CheckBoxIcon.svg';
import {ReactComponent as LoadingBlue} from '../../../assets/loading_circle.svg';
import { callGetSelectedModelAPI, callUpdateSelectedModelAPI } from './_apiCall';
import useWorkflowStore from '../../../store/useWorkflowStore';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import Axios from '../../../utils/Axios';
import ModelCompHeader from './Header';
import { callMLCAutoMLAPI } from '../../mlc-components/model-comparison/_apiCall';
import { toast } from 'react-toastify';
import template from '../../../template';



const ParameterInputField = ({
  parameter,
  onChange,
  addError,
  errors,
  model_info }: any) => {


  const param = model_info?.parameters?.find((p: any) => p.paramName === parameter.paramName);
  switch (parameter.paramType) {
    case "Integer":
      return <RangeInput
        onChange={onChange}
        parameter={parameter}
        min={param.paramStartValue}
        max={param.paramEndValue}
        error={errors.find((err: any) => err.paramName === parameter.paramName)?.error}
        addError={addError}
        isFloat={false}

      />
    case "Float":
      return <RangeInput
        onChange={onChange}
        parameter={parameter}
        min={param.paramStartValue}
        max={param.paramEndValue}
        error={errors.find((err: any) => err.paramName === parameter.paramName)?.error}
        addError={addError}
        isFloat={true}
      />

    case "Categorical":
      return <CategoricalInput
        value={parameter.paramNominalValues}
        onChange={onChange}
        parameter={parameter}
        options={param.paramNominalValues}
        error={errors.find((err: any) => err.paramName === parameter.paramName)?.error}
        addError={addError}
      />

    default:
      return null;
  }
}


// const TimeOutInputs = ({ value, setValue,error,parameters }: any) => {

 

//   return (<div>

//     <div className='flex gap-x-[4vw] h-[2vw]'>


//       {
//         parameters && Object.keys(parameters).reverse().map((key)=>{
//           return (
//             <div className='w-[25%] ' key={key}>
//               <div className='flex mb-[1vw] items-center justify-start'>
      
//                 <p
//                   className='text-[1vw] px-[0.3vw] font-medium'>
//                   {key=== "iteration"?"Number of Iterations":"Number of Minutes"}
//                 </p>
//               </div>
//               {/* <InputBox
//                 disabled={value.type !== key}
//                 min={parameters[key].min}
//                 max={parameters[key].max}
//                 value={value.type === key?value.value:""}
//                 setValue={(val:any)=>setValue({value:parseInt(val),type:key})}
//                 trailText={key=== "iteration"?"Iterations":"Minutes"}
//               /> */}
//               {error && error.paramName === key 
//               &&
//               <p className='text-red-500 my-[0.2vw] text-[0.9vw]'>{error.error}</p>}
//             </div>)
//         })
//       }


     
//     </div>



//   </div>)


// }


export default function LLMHyperparamerterTuning() {


  const [loading,setLoading] = useState<boolean>(true);
  const [autoMLLoading, setAutoMLLoading] = useState<boolean>(false);
  
  const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId ;
  const project = useWorkflowStore(state=>state.currentProject);
  const navigate = useNavigate();
  
  const [model_info,setModelInfo] = useState<any>();
  const [userInput, setUserInput] = useState<any[]>([]);
  const [errors, setErrors] = useState<any[]>([]);
  // const [timeOutInput,setTimeOutInput] = useState<any>({
  //   type:"iteration",
  //   value:5
  // });


  const handleUserInputChange = (value: any) => {

    const temp = userInput.map((state: any) => {

      if (state.paramName === value.paramName) {
        state = { ...state, ...value }

      }
      return state;
    })
    console.log("=== user input updated ===", temp);
    setUserInput(temp);
  }

  const handleGetModelInfo = async()=>{

    if(!workflowId) return;
    setLoading(true);

    const result = await callGetSelectedModelAPI(workflowId);
  
    console.log("parameters",result.userSelectedModel.parameters);

    //initialize values
    let temp:any[] =result.userSelectedModel.parameters.map((param:any)=>{
      console.log(param);
      let temp2 = param;
      // if(param.paramType === "Float" || param.paramType === "Integer" ){
      //   temp2.paramValue = param.paramStartValue;
      // }
      // if(param.paramType === "Categorical" ){
      //   temp2.paramValue = param.paramNominalValues[0];
      // }
      return temp2;
    })

    setUserInput(temp);
    setModelInfo(result.modelInfo);

    setLoading(false);
    
  }

  const handleCallAutoML =async()=>{
      
    
      if(!workflowId) return;
      
      const projectType = project?.projectType;
      const workflowType = project?.workflowType;
      if(!projectType || !workflowType ) return;
      const autoMLEndpoint = template[projectType][workflowType]
                                                          ?.modelTuning
                                                          ?.automlEndpoint;
      if(!autoMLEndpoint){
        toast.error("Training data not found for this projec type");
      }

      setAutoMLLoading(true);


      try{

        await callUpdateSelectedModelAPI(workflowId,userInput);
        navigate("/workflow/train-llm?workflowId="+workflowId);
        // if(autoMLEndpoint === "automl"){
        //   await callAutoMLAPI(workflowId);
        // }
        // if(autoMLEndpoint === "mlc-automl"){
        //   await callMLCAutoMLAPI(workflowId);

        // }
  
        // setTimeout(()=>{
  
        //   navigate("/workflow/modeltraining-1?workflowId="+workflowId);
        //   setAutoMLLoading(false);
        // },1000);
      }catch{
        toast.error("Error while saving the changes,please refresh the page and try again");
      }


  }





  const addError = (error: string | null, paramName: string) => {

    if (error === null) {
      removeError(paramName);
      return;
    }
    console.log("=== adding a new error ===");
    const temp = errors.filter((err: any) => err.paramName !== paramName);
    console.log("=== added error ===", [...temp, { paramName, error }]);
    setErrors([...temp, { paramName, error }]);
  }

  const removeError = (paramName: any) => {
    const temp = errors.filter((err: any) => err.paramName !== paramName);
    setErrors(temp);
  }




  useEffect(()=>{
       handleGetModelInfo();

  },[])

  if(loading){
    return <div 
    className='flex h-[30vw] items-center justify-center'
    >
          <LoadingBlue className='w-[9vw] h-[9vw] animate-spin'  />
    </div>
  }

  return (
    <div
    className='min-h-[78vh] flex flex-col'
    >
      <ModelCompHeader
      showLlm={false} />
      <p className='text-[1.1vw] font-bold py-[1vw]'>Hyperparameter Selection</p>

      <form>

        <div className='flex items-center justify-start gap-x-[4vw] gap-y-[3vw] flex-wrap'>
          {
            userInput.map((parameter: any) => {

              return <div className='w-[25%] h-[4vw]'>

                <ParameterInputField
                  onChange={handleUserInputChange}
                  parameter={parameter}
                  model_info={model_info}
                  addError={addError}
                  errors={errors}
                />

              </div>

            })
          }

        </div>
      </form>




      <div className='flex justify-end items-center px-[1vw] place-self-end mt-auto '>
      {<Button
          text={"Reset"}
          styles={"!w-[16vw]"}
          onClick={()=>{
            // let temp = model_info.parameters.map((param:any)=>{
            //   console.log(param);
            //   let temp2 = param;
            //   temp2.paramValue =param.paramValue
            //   return temp2;
            // })
            console.log(model_info.parameters)
            setUserInput(model_info.parameters);
            setErrors([]);
          }}

        />}
       {<Button
          text={"Model Training"}
          loading={autoMLLoading}
          disabled={autoMLLoading || errors.length>0 }
          disabledTitle={"Please resolve all errors to train the model"}
          styles={"!w-[16vw]"}
          onClick={handleCallAutoML}

        />}
      </div>
      
    </div>
  )
}
