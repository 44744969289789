import React from "react";
import { RiAddLine, RiLockLine } from "react-icons/ri";
import Node from "./Node";
import { addNewNode, calculateWires } from "./utils/helper";
import { Layer as LayerType, Wire } from "./utils/interface";
import { useRecoilState, useRecoilValue } from "recoil";
import { layersAtom, masterlistIdAtom, nodesAtom } from "./atoms";
import WireContainer from "./WireContainer";
import { useState } from "react";
import { useEffect } from "react";
//import { NotificationAtom } from "../topbar/notificationAtom";
import { toast } from "react-toastify";
//import usePracticeArea from "../../store/zustand/usePracticeArea";
import useWorkflowStore from '../../store/useWorkflowStore';

import useAuthStore from '../../store/authStore';
import usePermissionStore from "../../store/usePermissionStore";
import AccessControl from "../AccessControl/AccessControl";
interface LayerProps {
    layerInfo: LayerType;
}

let Layer: React.SFC<LayerProps> = ({ layerInfo }) => {
    let [layers, setLayers] = useRecoilState(layersAtom);
    let [nodes, setNodes] = useRecoilState(nodesAtom);
    let masterlistId = useRecoilValue(masterlistIdAtom);
    let [wires, setWires] = useState<Array<Wire>>([]);
    let currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
    let currentProject = useWorkflowStore(state=>state.currentProject);
    let authStore = useAuthStore();
    let userDetails = useAuthStore(state=>state.userDetails);
    let activePlan = useAuthStore(state=>state.activePlan); 
    const { permissions, setPermissions } = usePermissionStore();
    // let [notifications, setNotifications] = useRecoilState(NotificationAtom);

    let userProject = useWorkflowStore(state=>state.userProject);

    // let { currentPracticeArea } = usePracticeArea();

    useEffect(() => {
        if (layerInfo.parent !== undefined) {
            let _wires = calculateWires(layerInfo?.nodes, layerInfo?.parent);
            setWires(_wires);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerInfo?.nodes]);

    let [addAllowed, setAddAllowed] = useState(true);

    let addNode = (e: any) => {
      //  console.log("---workflow Info---",currentWorkflow)
        if (addAllowed) {
            // if (activePlan && nodes.length < activePlan.details.noOfClasses) {
                setAddAllowed(false);
                addNewNode(layers, nodes, "", layerInfo.parent?._id,
                 masterlistId?._id || "", layerInfo.level,
                 currentWorkflow?.workflowId || "")
                    .then((res:any) => {
                        setNodes(res?.nodes);
                        setLayers(res?.layers);
                        setAddAllowed(true);
                    })
                    .catch((err) => {
                        console.error(err);
                        toast("Internal Server Error : Add Node", { type: "error" });
                        setAddAllowed(true);
                        //   setNotifications([
                        //     ...notifications,
                        //     "Internal Server Error : Add Node",
                        //   ]);
                    });
            // } else {
            //     toast("You have added the maximum number of nodes to this project!", { type: "error" });
            // }
            
        }
    };

    useEffect(() => {
        if (authStore.userDetails && authStore.activePlan && authStore.usage) {
            let temp = permissions;

            // Nodes permission
            if (nodes.length < authStore.activePlan.details.noOfClasses) {
                permissions.canCreateNodes = true;
            } else {
                permissions.canCreateNodes = false;
            }

            setPermissions(temp);
        }
    }, [nodes]);

    const DisabledButton = () => {
        return (
            <div
                className="flex items-center justify-center w-[21vw] h-[3vw] rounded-[0.3vw] border-[0.15vw] border-dashed border-gray-400 text-gray-400 flex p-3 items-center cursor-pointer mt-[0.7vw]">
                <div className="svg-center"><RiLockLine className="w-[1vw] h-[1vw]" /></div>
            </div>
        )
    }

    return (
        <div className="min-h-[61vh]">
            <div
                style={{
                    paddingLeft: layerInfo.level === 0 ? 0 : "6.5vw",
                    fontSize: "1vw",
                    fontWeight: 700,
                    color: "#2C2C2C",
                }}
                className=" w-full 
                            border-b mb-[0.5vw] py-[0.5vw]
                            
                            "
            >
                LEVEL {layerInfo.level + 1}
            </div>
            <div className={"flex"}>
                {layerInfo.level !== 0 && <WireContainer wires={wires} />}
                <div className="space-y-[0.7vw] ">
                    {layerInfo?.nodes?.map((node) => {
                        return <Node nodeInfo={node} key={node._id} />;
                    })}


                    {!currentWorkflow?.masterlistLocked 
                    && userProject?.MASTERLIST_ACCESS
                    && currentProject?.createdBy === userDetails?.userId
                
                     && (
                        <AccessControl RestrictedComp={DisabledButton} RequestedPerm="canCreateNodes">
                            <div
                                onClick={addNode}
                                style={{ width: "21vw", height:"3vw" , borderRadius: "0.3vw", fontSize: "1vw" }}
                                className={"flex items-center border-[0.15vw] border-dashed border-gray-400 text-gray-400 flex p-3 items-center cursor-pointer"}
                                data-testid="add-new-node-test"
                            >
                                <div>Add</div> <div className="w-[2vw] svg-center  -mt-[0.15vw] ml-[0.2vw]"><RiAddLine /></div>
                            </div>
                        </AccessControl>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Layer;
