import React, { useEffect } from 'react'
import AddFolderComponent from './AddFolderComponent';
import FolderCapsule from './FolderCapsule';

import useAdvancedMasterlist from '../../../store/useAdvancedMasterlist';


//Custom Components


interface FolderListProps{
  activeFolder:string | undefined,
  setActiveFolder:any
}

const FolderList:React.FC<FolderListProps> =  ({activeFolder,setActiveFolder})=> {

const AMStore= useAdvancedMasterlist();

  let folders = AMStore.folders;
  //console.log("=== Folders in state ===",folders);

  return (
    <div>
         <div className="flex   items-center justify-start space-x-[1vw] py-[1vw]">
                    <p className="text-[1vw] text-[#999999] min-w-[5%] self-start my-[1vw]">Folders</p>
                    <div className=' flex flex-wrap  items-center justify-start '>

                    <AddFolderComponent />
                    {
                      Object.keys(folders).length > 0 ? 
                        Object.keys(folders).map((folderName:string)=>{
                          return <FolderCapsule 
                                  key={folderName}
                                  folderName={folderName}
                                  activeFolder={activeFolder}
                                  setActiveFolder={setActiveFolder} />
                        }) : <div data-testid="empty-folderlist-test" />
                    }
                    
                    </div>
                   
                </div>
    </div>
  )
}

export default FolderList;