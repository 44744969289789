import React,{useState,useEffect} from 'react';
import Chart from 'react-apexcharts';
import {Slider,Modal} from 'antd';
import Axios from '../../utils/Axios';
import useWorkflowStore from '../../store/useWorkflowStore';
import {nodesAtom} from '../../components/masterlist/atoms';
import {useRecoilValue}  from 'recoil';
import {BsArrowsAngleExpand} from 'react-icons/bs';
import {toast } from 'react-toastify';
import MutliUserPieChartModel from './MutliUserPieChartModel';
import IICon from '../IICon';

interface MultiUserPieChartProps{
    stage:number,
    graphData:any,
    max:number,
    requestType:number
  
}

const MultiUserPieChart:React.FC<MultiUserPieChartProps> = ({graphData,
                                                                  max,
                                                                requestType})=> {


  const [users,setUsers] =useState(1);
  const [showModal,setShowModal] = useState<boolean>(false);
  //const [max,setMax] = useState(0);
  const [pieChartOptions,setPieCharOptions] = useState<any>({
    series:[],
    options:{}
  })
  const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
  const nodes = useRecoilValue(nodesAtom);
  let [marks,setMarks] = useState<any>({});



  //Config series and options object on the basis of user numbers
  const getNodeName = (data:any,key:any)=>{
    let noTagClassName = "No Tag";
    let nullName = "No Associated Class";
    if(data)
    {
      return Object.keys(data[key]).map(key=>{
        if(key === "null")
        {
          return nullName;
        }
        else if(key === noTagClassName)
        {
          return noTagClassName;
        }
        else{
          return nodes.find(n=>n.node_id===key)?.node_name
        }
      });
    }
    else {
      return [];
    }

  }



   useEffect(()=>{

    if(!nodes.length)
    {
      toast("Could not find nodes.")
     
    }
   
    if(graphData && nodes.length)
    {
      let key = "num_user_"+users;
      //console.log(key);
     // setSeriesData( graphData[key]);
      //console.log("series data",graphData);
      let options = {}
      let series = {}
      try{

         series =graphData &&  Object.keys(graphData[key])?.map(k=> graphData[key][k])
         options = {
            chart:{
              redrawOnParentResize:true
            },
            expandeOnClick:true,
            labels:getNodeName(graphData,key),
            toolbar: {
                show: false
            },
            legend:{
              position:'bottom'
            },
            noData:{
              text:`No data available for multiuser voting,
              validate more paragraphs to see this graph.`
              ,style:{
                fontSize:"0.9vw"
              }
            }
           
      
        }
      }
      catch(err)
      {
        console.log("Error while parsing data in multiusepie chart");
        toast("There was an error while parsing data in multi-user pie chart.");
      }

      let temp:any ={}
      for(let i =0;i<max;i++)
      {
        temp[`${i+1}`] = `${i+1}`;
      }
      setMarks(temp);
      
      setPieCharOptions({options,series})



    }

  },[users,graphData,nodes])
  ////console.log("graph data",graphData);

  
  return (
    <div className=' h-full'>
      { graphData && 
      <> 
      <div className='flex items-center justify-between p-2'>
      <div className="flex gap-[0.5vw] items-center self-start text-[#1BA94C] font-bold text-[1.1vw] mt-3 mx-5">
        <p>
        Classwise Validation Statistics
        </p>
        
       {requestType ===1 &&  <IICon 
        
            message="This graph shows classwise common validated paras between users for the data validated by user which have currently not undergone multiuser voting.
            If user has not validated any new paragraphs then this graph will be empty"
            />}
        
        </div>
      <BsArrowsAngleExpand className='cursor-pointer hover:scale-110' size={17} color={"#777777"} onClick={()=>setShowModal(true)} />


        <MutliUserPieChartModel
        open={showModal}
        setShowModal={setShowModal}
        >
            <div className=' flex flex-col justify-start items-center h-full w-full '>
          <div className=" w-full text-[#1BA94C] font-bold text-[1.1vw] mt-3 mx-5 ">
            <p>
            Classwise Validation Statistics
            </p>
           
            
            </div>
            {
              
              <>
             
              <Chart 
              options={showModal? pieChartOptions.options:[]}
              
            
              width={700}
              type={"pie"} 
              series={showModal? pieChartOptions.series: []} />
            <div className='p-4 w-1/2 mx-auto'> 
            <Slider min={1} max={max} value={users}  onChange={(num) => setUsers(num)} marks={marks} />
            <div className='text-center'>Number Of Users</div>
        </div>
             </> 
              }
          </div>

        </MutliUserPieChartModel>
        {/* <Modal open={showModal} footer={null} width={'90vw'} onCancel={()=>setShowModal(false)}>
          <div className=' flex flex-col justify-start items-center h-full w-full overflow-auto '>
          <div className=" text-[#1BA94C] font-bold text-[1.1vw] mt-3 mx-5 ">Classwise Validation Statistics</div>
            {
              
            <>
             
             <Chart 
              options={showModal? pieChartOptions.options:[]}
              width={showModal ? 750 : 0} 
              
              type={"pie"} 
              series={showModal? pieChartOptions.series:[]} />
            <div className='p-4 w-1/2 mx-auto'> 
            <Slider min={1} max={max} value={users}  onChange={(num) => setUsers(num)} marks={marks} />
            <div className='text-center'>Number Of Users</div>
        </div>
             </> 
              }
          </div>
        </Modal> */}
      </div>


        <div className='mt-5'>

      <Chart options={pieChartOptions.options} height={500} width={'100%'} type={"pie"} series={pieChartOptions.series} />
        </div>
         
         
        {max>1 && <div data-testid="slider" className='p-4 w-1/2 mx-auto'>
            <Slider min={1} max={max} value={users}  onChange={(num) => setUsers(num)} marks={marks} />
            <div className='text-center'>Number Of Users</div>
        </div>}
        </>
          
      }
    </div>
  )
}

export default MultiUserPieChart;
