import React, { useState } from 'react';



//Icons

import MultiClassSelector from './MultiClassSelector';
import MultiClassValidator from './MultiClassValidator';


 interface IValidationControls{
    handleTagSelection:(value:string)=>void,
    acceptPara:()=>void,
    rejectPara:()=>void,
    accepted:string | null,
    active?:boolean,
    node_name:String,
    nexPredicted?:any[],
    selectionInfo:any,
    dropdownOptions:any[],
    loading:boolean,
    readOnly:boolean,
    keyboardEnabled:boolean,
    setKeyboardEnabled:any

}


function ValidationControls({
    handleTagSelection,
    acceptPara,
    rejectPara,
    accepted,
    active,
    node_name,
    nexPredicted,
    selectionInfo,
    dropdownOptions,
    loading,
    readOnly,
    keyboardEnabled,
    setKeyboardEnabled
    
}:IValidationControls) {

    //local states
    //const [loading,setLoading] = useState<boolean>(false);

    



  return (
    <div className="w-full h-full flex flex-col justify-center py-[1vw] px-[1vw] space-y-[1vw]">

     <MultiClassValidator
     acceptPara={acceptPara}
     rejectPara={rejectPara}
     accepted={accepted}
     loading={loading}
     readOnly={readOnly}

     />

      <div>
        <MultiClassSelector 
        handleTagSelection={handleTagSelection}
        dropdownOptions={dropdownOptions}
        accepted={accepted} 
        node_name={node_name || ""} 
        loading={loading}

        disabled={readOnly? true:(!accepted || accepted ==="accepted")}
        //disabled={readOnly}

        selectionInfo ={selectionInfo}  
        keyboardEnabled={keyboardEnabled}
        setKeyboardEnabled={setKeyboardEnabled}
      
        />
      
      
      </div>

      </div>
  )
}

export default ValidationControls