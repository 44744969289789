import ReactApexChart from "react-apexcharts";
import { InfoCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Button from '../../components/controls/Button';
import Axios from '../../utils/Axios';
import useWorkflowStore from "../../store/useWorkflowStore";
import useAuthStore from "../../store/authStore";
import useCompanyUsersStore from "../../store/useCompanyUsersStore";
import { toast } from 'react-toastify';
import { nodesAtom } from '../../components/masterlist/atoms';
import { useRecoilValue } from 'recoil';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import template from "../../template";



interface UserStatistcsProps {
  userInfo: any,
  stage: number,
  batchNumber:number,
  requestType:number
}
const UserStatisticsComponent: React.FC<UserStatistcsProps> = ({ userInfo, stage ,batchNumber,requestType}) => {


  //Loading States
  const [loadingVStat, setLoadingVStat] = useState(false);
  const [loadingPieChart, setLoadingPieChart] = useState(false);
  const [loadingOverlap, setLoadingOverlap] = useState(false);


  // main states
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [userStats, setUserStats] = useState<Array<any>>([]);
  const [overlapStats, setOverlapStats] = useState<any>();
  const workflowStore = useWorkflowStore();
  const authStore = useAuthStore();
  const { companyUsers } = useCompanyUsersStore();

  const projectType:string = workflowStore.currentProject?.projectType || "";
  const workflowType:string = workflowStore.currentProject?.workflowType || "";

  const [vStats, setVStats] = useState<any>({
    accepted: 0,
    rejected: 0,
    total: 0
  });
  const [pieChartOptions, setPieChartOptions] = useState<any>({
    series: [],
    options: {}
  });
  const nodes: any = useRecoilValue(nodesAtom);
  const getNodeName = (data:any)=>{
    let noTagClassName = "No Tag";
    let nullName = "No Associated Class";
    if(data)
    {
      return Object.keys(data).map(key=>{
        if(key === "null")
        {
          return nullName;
        }
        else if(key === noTagClassName)
        {
          return noTagClassName;
        }
        else{
          return nodes.find((n:any)=>n.node_id===key)?.node_name
        }


      })
    }
    else {
      return [];
    }

  }
  // Lets Fetch Graph Info
  useEffect(() => {

   

    const fetchUserStats = async () => {
      let workflowId = workflowStore.currentWorkflow?.workflowId;

      try {
        setLoadingVStat(true);
        
        let endpoint = template[projectType][workflowType].summary.endpoints.vstats;
        let res_vstats: any = await Axios.post(`/summary/${endpoint}`, {

          workflowId,
          stage: stage,
          userId: userInfo.userId,
          batchNumber:batchNumber || 1,
          requestType

        });

        console.log("=== Got User Stats ===",res_vstats.data);
        let vTotal = 0;
        if (res_vstats.data.result) {
          let accepted = 0;
          let rejected = 0;
          res_vstats.data.result.forEach((value: any) => {
            if (value._id === "accepted") {
              //console.log("accepted",value)
              accepted = value.count;
              vTotal += value.count
            }
            if (value._id === "rejected") {
              //console.log("rejected",value)
              rejected = value.count;
              vTotal += value.count
            }
          })
          setVStats({ accepted, rejected, total: vTotal });
          //console.log("current VStats",vStats);
        }
      }
      catch {
        toast("Could not get user statistics data. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setLoadingVStat(false);
      }
    }

    
    const fetchUserOverlap = async () => {
      let workflowId = workflowStore.currentWorkflow?.workflowId;


      try {
        setLoadingOverlap(true);
        let res = await Axios.post("/summary/overlapstats", {
          workflowId,
          userId: userInfo.userId,
          stage,
          batchNumber,
          requestType
        });
        
        // console.log("=== Response for overlap stats ===",res.data.data);

        //Omit the current user
        let data = res.data.data.filter((d: any) => d.user.userId !== userInfo.userId);

        // Populate the data array with userInfo from companyUsers
        for (let i = 0; i < data.length; i++) {
          companyUsers.forEach((user: any) => {
            if (data[i].user.userId === user.userId) {
              data[i].user.userInfo = {
                firstName: user.firstName,
                lastName: user.lastName,
              }
            }
          })
        }

        data.sort((d1: any, d2: any) => d1.user.userInfo.firstName > d2.user.userInfo.firstName ? 1 : -1);

        let s: Array<any> = [];
        data.forEach((d: any) => {
          let temp: any = {};
          temp.data = d.validations;
          temp.name = d.user.userInfo.firstName + d.user.userInfo.lastName;
          s.push(temp);
        })
        //console.log("Bar graph data",s);
        const barChartData = {
          series: [{
            data: data.map((d: any) => d.overlap.toFixed(2))
          }],
          options: {
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                barHeight:"20%"
              }
            },
            
            xaxis: {
              categories: data.map((d: any) => (d.user.userInfo.firstName + " " + d.user.userInfo.lastName))
            },
            yaxis: {
              min: 0,
              max: 100
            },
            colors: ["#03045E"],
            grid: {
              show: false,
            },
            noData:{
              text:"No Data To Show"
            }
          },

        };
        //console.log("=== Bar Chart Data ===",barChartData);
        setOverlapStats(barChartData);

      } catch (err) {
        //console.log(err);
        toast("Could not get user overlap statistics. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setLoadingOverlap(false);
      }
    }


    fetchUserStats();
    fetchUserOverlap();



  }, [userInfo])

  useEffect(() => {

    const fetchUserPieChart = async () => {
      let workflowId = workflowStore.currentWorkflow?.workflowId;
      let userId = userInfo.userId

      try {
        setLoadingPieChart(true);

        const endpoint = template[projectType][workflowType].summary.endpoints.userpiechart

        let res = await Axios.post(`/summary/${endpoint}`, {
          workflowId,
          stage,
          batchNumber,
          userId,
          requestType

        });
        console.log("=== pie chart data ===", res.data);
        let graphData = res.data.data;

        const series = Object.keys(graphData).map(k => graphData[k])
        const options = {

          expandeOnClick: true,
          labels: getNodeName(graphData),
          toolbar: {
            show: false
          },
          legend: {
            show: false
          },
          noData:{
            text:"No Data To Show"
          }


        }
        // console.log("=== pie chart nodes ===",nodes)
        // console.log("=== pie chart series ===",series)
        // console.log("=== pie chart options ===",options)
        setPieChartOptions({ options, series });

      }
      catch (err) {
        console.log("error in single user pie chart", err);
        toast("Could not get user pie chart data. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setLoadingPieChart(false);
      }

    }
    fetchUserPieChart();

  }, [nodes,userInfo])


  const Loader = () => {
    return (

      <div className='p-3 flex-1  flex justify-center items-center '>
        <AiOutlineLoading3Quarters size={30} color={"gray"} className=" animate-spin" />
      </div>

    )
  }

  return (
    <>
      <div className="flex p-2 bg-white  shadow-md">
        <div className="flex flex-col flex-1 mx-3">
          <div className=" font-bold my-2">Full Name</div>
          <div className="flex border-2 bg-[#f9fafb] border-[#C4C4C4]  max-w-[325px]  rounded-[4px] px-4 py-2">
            <div className='color-[#7A7A7A]'>{`${userInfo.firstName} ${userInfo.lastName}`}</div>
          </div>
        </div>

        <div className="flex flex-col flex-1 ml-14">
          <div className="font-bold my-2">Email</div>
          <div className="flex border-2 bg-[#f9fafb] border-[#C4C4C4] max-w-[325px] rounded-[4px] px-4 py-2">
            <div className='color-[#7A7A7A]'>{userInfo.email}</div>
          </div>
        </div>

        <div className="flex flex-col flex-1 ml-[1.8rem]">
          <div className="flex border-2 bg-white border-[#C4C4C4]  max-w-[250px]  rounded-[4px] mt-[2.35rem] px-4 py-2">
            <div className='color-[#7A7A7A]'>Complete Project</div>
          </div>
        </div>
        <div className="relative" onClick={() => { setIsOpen((prevState: boolean) => !prevState) }}>{isOpen ? < UpOutlined style={{ position: 'relative', fontSize: '20px', top: "3px", right: "3px" }} /> : <DownOutlined style={{ position: 'relative', fontSize: '20px', top: "3px", right: "3px" }} />}</div>

      </div>
      {isOpen &&
        <div >
          <div className="flex   p-2 bg-white">
            {loadingVStat ? (
              <Loader />
            ) : <div className="flex-col my-5 mx-3 flex-1 shadow-[0px_0px_8px_rgba(122,122,122,0.15)] px-5 py-1">
              <div className="text-[#1BA94C] text-base font-bold mt-2 mb-5">Validation Statistics</div>
              <hr
                style={{
                  color: "#EAEAEA",
                  backgroundColor: "#EAEAEA"
                }}
              />
              <div className="flex-col text-[#9A9A9A] py-2">
                <div className="flex justify-between">
                  <div className="flex justify-between  ">
                    <div className="ml-1 ">Validated</div>
                  </div>
                  <div className="mr-3" >{vStats.accepted}</div>
                </div>
              </div>
              <hr
                style={{
                  color: "#EAEAEA",
                  backgroundColor: "#EAEAEA"
                }}
              />
              <div className="flex-col text-[#9A9A9A] py-2">
                <div className="flex justify-between">
                  <div className="flex justify-between ">
                    <div className="ml-1">Rejected</div>
                  </div>
                  <div className="mr-3" >{vStats.rejected}</div>
                </div>
              </div>
              <hr
                style={{
                  color: "#EAEAEA",
                  backgroundColor: "#EAEAEA"
                }}
              />
              <div className="flex-col text-[#9A9A9A] py-2">
                <div className="flex justify-between">
                  <div className="ml-1">Total Validations</div>
                  <div className="mr-3">{vStats.total}</div>
                </div>
              </div>
              <hr
                style={{
                  color: "#EAEAEA",
                  backgroundColor: "#EAEAEA"
                }}
              />
            </div>}

            {loadingPieChart ? <Loader /> : <div className="flex flex-col items-center justify-start my-5 mx-3 flex-1 shadow-[0px_0px_8px_rgba(122,122,122,0.15)] ">


              <div className="self-start text-[#1BA94C] font-bold text-base mt-3 mx-5">Classwise Validation Statistics</div>

              <div id="chart" className="my-2 p-2 w-full h-full flex justify-center items-center">

                <ReactApexChart
                  options={pieChartOptions.options}
                  series={pieChartOptions.series}
                  type="pie"
                  width={250}
                  height={250} />

              </div>


            </div>}
            {loadingOverlap ? <Loader /> :<div className="flex flex-col items-center justify-start my-5 mx-3 flex-1 shadow-[0px_0px_8px_rgba(122,122,122,0.15)] ">
              <div className="self-start text-[#1BA94C] text-base font-bold mt-3 mx-5">Overlap Statistics With other Validators</div>

               <div id="chart" className="my-2 ml-4 mr-8">


                <ReactApexChart options={overlapStats ? overlapStats?.options : {}} series={overlapStats ? overlapStats?.series : []} type="bar" height={240} />

              </div>
            </div>}

          </div>

          {/* <div className="flex justify-end bg-white pb-4 pt-1">
            <button
              className="py-1 px-5 mx-2 text-white rounded-md"
              style={{ background: "#11256D" }}
            >
              Edit User
            </button>
            <button
              className="py-1 px-5 ml-1 mr-5 text-white rounded-md"
              style={{ background: "#E63946" }}
            >
              Delete User
            </button>
          </div> */}

        </div>
      }
    </>);
}


export default UserStatisticsComponent;
