import { toast } from 'react-toastify';
import Axios from '../../utils/Axios';

export const getModels = async(modelType:string)=>{
    try{

        const res = await Axios.post("/llm/get-models",{
            modelType
        });
        if(res.data.data ){
            return res.data.data;
        }

    }catch{
        return false;
    } 
}

export const saveModel = async(workflowId:string,modelName:string)=>{

    try{
        const res = await Axios.post("/model-selection/create-user-selected-model",{
            workflowId,
            modelName
        })
        return res.data.result;

    }catch{
        return false
    }


}

export const uploadModel = async(workflowId:string,file:any,setProgress:any)=>{

    let formData = new FormData();
    formData.append("uploaded_file",file);
    formData.append("workflowId",workflowId);

    try{
        let res = await Axios(
            {
                url : "/llm/upload-model?workflowId="+workflowId,
                method:"POST",
                data:formData,
                onUploadProgress:(e)=>{
                        setProgress(Math.round(
                            (100 * e.loaded) / e.total
                        ))
                }
            }
        )
        if(res.data.result){
            return true;
        }

    }catch{
        return false;
    }




}

export const uploadPredictionModel = async(workflowId:string,file:any,setProgress:any)=>{

    let formData = new FormData();
    formData.append("uploaded_file",file);
    formData.append("workflowId",workflowId);

    try{
        let res = await Axios(
            {
                url : "/llm/upload-prediction-model?workflowId="+workflowId,
                method:"POST",
                data:formData,
                onUploadProgress:(e)=>{
                        setProgress(Math.round(
                            (100 * e.loaded) / e.total
                        ))
                }
            }
        )
        if(res.data.result){
            return true;
        }

    }catch{
        return false;
    }




}


export const removeUploadedModel = async(workflowId:string,type:string)=>{

     try{
        const result = await Axios.post("/llm/remove-uploaded-model",{
            workflowId,
            type
        });
        return true;


     }catch{
        toast.error("There was an error while deleting the selected model");
        return false;
     }


}