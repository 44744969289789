import React from "react";

interface ButtonProps {
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    loading?: boolean;
}

let Button: React.FC<ButtonProps> = ({ text, onClick, disabled, type, loading }) => {
    return (
        <button
            className="min-w-fit px-[4vw] py-[0.5vw] text-[1vw] bg-lightBlack text-white rounded-full"
            disabled={disabled}
            onClick={() => {
                if (onClick) onClick();
            }}
            type={type}
        >
            {text}
        </button>
    );
};

export default Button;
