import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../store/reducers";
import {
  REJECT_PARA,
  STAGE1_PROGRESS_UPDATE,
  UPDATE_PARA,
  ACCEPT_PARA as accept_para,
  UPDATE_NODE_FEEDBACK,
} from "../../store/types/stage1-types";

import { toast } from "react-toastify";

import { useRecoilState, useRecoilValue } from "recoil";
import { nodesAtom } from "../masterlist/atoms";
import NerPara from "../ner-components/NerPara";
import useWorkflowStore from "../../store/useWorkflowStore";
import Axios from '../../utils/Axios';

import {
  STAGE2_NODES_REQUEST,
  STAGE2_UPDATE_REJECT_PARA,
} from "../../store/types/stage-2-types";
import {
  STAGE1_NODES_REQUEST,
  UPDATE_REJECT_PARA,
} from "../../store/types/stage1-types";
import ValidationControls from "./controls/multiclass/ValidationControls";
import useKeyboard from "../../hooks/useKeyboard";
import { getClassesInAlphabeticOrder } from "../stage-2/helper";


export interface StageParaProps {
  node_name: string;
  time_str: string;
  para_data: string;
  paraId: string;
  accepted: null | string;
  active_node_id: string | undefined;
  stage: number;
  predictedIssueId: string;
  nextPredictedIssueId: string;
  batchId: number;
  key:string;
  index:number;
  activePara:number;
  setActivePara:any;
  next:()=>void;
  prev:()=>void;
  readOnly?:boolean;
  keyboardEnabled?:boolean;
  setKeyboardEnabled?:any
}

const StagePara: React.FC<StageParaProps> = (props) => {

  const {
    active_node_id,
    node_name,
    time_str,
    para_data,
    paraId,
    accepted,
    stage,
    predictedIssueId,
    nextPredictedIssueId,
    key,
    index,
    activePara,
    setActivePara,
    next,
    prev,
    readOnly,
    keyboardEnabled,
    setKeyboardEnabled

  } = props;
  if(accepted)
  {
   // console.log("accepted",accepted);
  }


  let dispatch = useDispatch();
  let stage1 = useSelector((state: GlobalState) => state.stage1);
  //let [expanded, setExpanded] = useState(false);
  let [fade, setFade] = useState(false);
  let [accept, setAccept] = useState<boolean | null>();
  let [loading,setLoading] = useState<boolean>(false);
  const {currentWorkflow} = useWorkflowStore();
  const isActive = index === activePara;
  let nodes = useRecoilValue(nodesAtom);

  //sorted in alphabetic order
  const nextClasses:any[] =useMemo(()=>getClassesInAlphabeticOrder(nodes),[nodes] ) ;
  const [isMounted,setIsMounted] = useState<boolean>(false);
  let [selectionInfo, setSelectionInfo] = useState({
    selection: false,
    issueId: "",
    node_id: "",
  });

  //Keyboard Shortcuts
  
  const divRef = useRef<HTMLDivElement>(null);
  const Enter = useKeyboard({keyCode:"Enter"})
  const BackSpace = useKeyboard({keyCode:"Tab"})
  let waitFor = (duration:number)=>{
    return new Promise(resolve=>setTimeout(resolve,duration));
  }

  useEffect(()=>{

    const wait = async()=>{
      await waitFor(100);
      //divRef.current?.scrollIntoView({block:"start"})
      divRef.current?.scrollIntoView({block:"nearest"});
      if(activePara === 0)
      {
        divRef.current?.scrollIntoView({block:"nearest",behavior:"smooth"});
        window.scrollTo({top:0,behavior:"smooth"});
      
      }
    };
    if(isActive)
    {
      wait();
     
    
      
    }
   
  },[isActive]);

  useEffect(()=>{
    const accepteParaAndMove =async()=>{
      if(!keyboardEnabled) return;
      if(Enter && isActive)
      {
        
        if((!accepted || accepted!=="accepted") && !loading && !readOnly) {
          //next();
          await acceptPara();
          
        }
        
       
      }

    }
    accepteParaAndMove();



  },[Enter,isActive])

  useEffect(()=>{
    const rejectParaAndMove = async()=>{
      if(!keyboardEnabled) return;
      if(!BackSpace) return;
      if(!isActive) return;
     
        if((!accepted || accepted!=="rejected") && !loading && !readOnly ){
          console.log("--para id--",paraId);
          await rejectPara();
          //next();
        } 
      

    }
    rejectParaAndMove();
    
    
  },[isActive,BackSpace])
  //Keyboard Shortcuts

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  


  React.useEffect(() => {
    setAccept(accepted === "accepted");
  }, [accepted]);




  //let []
  
  let stageInfo: any = useSelector((state: GlobalState) => {
    if (stage === 1) {
      return state.stage1;
    } else {
      return state.stage2;

    }
  });

  useEffect(() => {
    let paras = stageInfo.paras.find((node:any)=>node.node_id === active_node_id)
    let rejInfo = paras?.data?.find(
      (info: any) => info.para_info.paraId === paraId
      );
   
      
   if(paraId ==="83848bbc-4f62-11ed-b31c-0242ac110002") {
    console.log(paras);
     console.log("=== updating selected info ===",rejInfo);
   }
   
    setSelectionInfo({
      selection: rejInfo?.nextPredicted?.selected,
      issueId: rejInfo?.nextPredicted?.issueId,
      node_id: rejInfo?.nextPredicted?.node_id,
    });
  }, [stageInfo]);

  let handleTagSelection = async (node_id: string) => {
   
    setLoading(true);
    // updating the counting
   

    if (node_id) {
      dispatch({
        type: stage === 1 ? STAGE1_NODES_REQUEST : STAGE2_NODES_REQUEST,
        payload: stageInfo.nodes.map((nd: any) => {
          if (nd.node_id === node_id) {
            return {
              ...nd,
              completed_percentage: nd.completed_percentage + 1,
            };
          }
  
          if (nd.node_id === selectionInfo.issueId) {
            return {
              ...nd,
              completed_percentage: nd.completed_percentage - 1,
            };
          }
  
          return nd;
        }),
      });
      if (stage === 1) {

        dispatch({
          type: UPDATE_REJECT_PARA,
          payload: {
            selected: true,
            issueId: node_id,
            paraId: paraId,
            node_id:active_node_id
          },
        });
      }
      const reject_res = await Axios.post("/annotations/para-action", {
        paraId,
        action: "rejected",
        stage,
        workflowId: currentWorkflow?.workflowId,
        predictedIssueId: node_id

      });
    
    }
    else{
      dispatch({
        type: stage === 1 ? STAGE1_NODES_REQUEST : STAGE2_NODES_REQUEST,
        payload: stageInfo.nodes.map((nd: any) => {
       
  
          if (nd.node_id === selectionInfo.issueId) {
            return {
              ...nd,
              completed_percentage: nd.completed_percentage - 1,
            };
          }
  
          return nd;
        }),
      });
      dispatch({
        type: UPDATE_REJECT_PARA,
        payload: {
          selected:false,
          issueId: "",
          paraId: paraId,
          node_id:active_node_id
        },
      });
      
    }

    setLoading(false);


  };

//   let dropDownOptions = useMemo(
//     () =>
//     {
//       if(currentWorkflow?.useNoTag)
//       {

//         return [{ value: "No Tag", text: "No Tag", id: "No Tag" }].concat(
//           nodes
//             .filter((nd) => nd.is_model_class)
//             .map((node) => ({
//               value: node.node_id,
//               text: node.node_name,
//               id: node.node_id,
//             }))
//         )
//       }
//       else{
//         return nodes
//         .filter((nd) => nd.is_model_class)
//         .map((node) => ({
//           value: node.node_id,
//           text: node.node_name,
//           id: node.node_id,
//         }))
    

//       }


//     }

  

//  ,
//     [nodes]
//   );
  let dropDownOptions = useMemo(() =>
  {
    if(currentWorkflow?.useNoTag)
    {
      //console.log("=== nextPredicted Issue Id ===",nextPredictedIssueId)
      return [{ value: "No Tag", text: "No Tag", id: "No Tag" }].concat(
       nextClasses.map((nd:any)=>{
        let node:any = nodes.find((node)=>node.node_id === nd[0]);
        let value= node.node_id
        let text=node.node_name
        let id= node.node_id
        return {value,text,id};

       })
      )
    }
    else{
      return  nextClasses.map((nd:any)=>{
        let node:any = nodes.find((node)=>node.node_id === nd[0]);
        let value= node.node_id
        let text=node.node_name
        let id= node.node_id
        return {value,text,id};

       })
  

    }


  },[nodes,nextClasses]
);


  let rejectPara = async() => {
    //TODO if accepted first then rejected then update the completed percentage

   // setLoading(true);
    if (accept !== true) {
      dispatch({
        type: UPDATE_NODE_FEEDBACK,
        payload: {
          node_id: props.active_node_id,
          goal: 0,
          feedback: 1,
        },
      });
    }
    try {


      let isAccepted = false;
      let completed = 0;
      for (let i = 0; i < stage1.paras.length; i++) {
        if (stage1.paras[i].node_id === active_node_id) {
        //  console.log("After Reject!!",stage1.paras[i]);
          for (let j = 0; j < stage1.paras[i].data.length; j++) {
            if (
              stage1.paras[i].data[j].para_info.paraId === paraId &&
              stage1.paras[i].data[j].accepted === "accepted"
            ) {
              //console.log("Found Rejected Paras",stage1.paras[i].data[j]);
              isAccepted = true;
              break;
            }
          }
          break;
        }
      }
      for (let i = 0; i < stage1.nodes.length; i++) {
        if (stage1.nodes[i].node_id === active_node_id) {
          completed = stage1.nodes[i].completed_percentage;
        }
      }
      if (isAccepted) {
        dispatch({
          type: STAGE1_PROGRESS_UPDATE,
          payload: {
            node_id: active_node_id,
            data: completed > 0 ? completed - 1 : 0,
          },
        });
      }

      let nodeId = nextPredictedIssueId;

      let node_info = nodes.filter(
        (nodeInfo) => nodeInfo.node_id === nodeId
      )[0];

      if (node_info !== undefined) {
        let node_name = node_info.node_name;
        let parentInfo = nodes.filter(
          (nodeInfo) => nodeInfo.node_id === node_info.parent_id
        )[0];

        let parent_name = "No Parent Name";

        if (parentInfo !== undefined) {
          parent_name = parentInfo.node_name;
        }

        // let nextPredicted = {
        //   node_name,
        //   parent_name,
        //   node_id: nodeId,
        //   selected: null,
        // };
        dispatch({
          type: REJECT_PARA,
          payload: {
            paraId,
            node_id: active_node_id,
            selected:false,
            issueId:null
          },
        });

          // dispatch({
          //   type: REJECT_PARA,
          //   payload: {
          //     data: { paraData: para_data, createdAt: time_str, paraId },
          //     nextPredicted,
          //     curNode: {
          //       node_name: props.node_name,
          //     },
          //   },
          // });
      

        const reject_res = await Axios.post("/annotations/para-action",{
          paraId,
          action:"rejected",
          stage,
          workflowId:currentWorkflow?.workflowId,
          predictedIssueId:null
  
        });
      }
    } catch (err) {
      toast("There was some error while registering your validation. Please reload the page and try again.", { type: "error" });
    }
    finally{
      setLoading(false);
    }
  };
  let acceptPara = async() => {
    if (accept === true) return;


    setLoading(true)
    dispatch({
      type: UPDATE_NODE_FEEDBACK,
      payload: {
        node_id: props.active_node_id,
        goal: 0,
        feedback: 1,
      },
    });

    if (active_node_id !== undefined) {
      try {
        let params = {
          paraId: paraId,
          action: "accepted",
          stage: stage,
           predictedIssueId,
        };
        
       
        dispatch({
          type: accept_para,
          payload: { node_id: active_node_id, paraId: paraId },
        });
        setAccept(true);
        let completed = 0;

        for (let i = 0; i < stage1.nodes.length; i++) {
          if (stage1.nodes[i].node_id === active_node_id) {
            completed = stage1.nodes[i].completed_percentage + 1;
          }
        }
       

        dispatch({
          type: STAGE1_PROGRESS_UPDATE,
          payload: {
            node_id: active_node_id,
            data: completed,
          },
        });
        await Axios.post("/annotations/para-action",{
          paraId:paraId,
          action:"accepted",
          predictedIssueId:predictedIssueId,
          workflowId:currentWorkflow?.workflowId,
          stage:1
        });
      } catch (err) {
        toast("There was some error while registering your validation. Please reload the page and try again.", {
          type: "error",
        });

      }finally{
        setLoading(false);
      }
    }
  };


  return (
    <div
    ref={divRef}
    onClick={()=>setActivePara(index)}
      onMouseDown={(ev) => {
        //nerStore.setCurrentParaId(paraId);
      }}
      key={paraId}
      className={`w-full flex my-5 duration-200 bg-white border min-h-[8vw]
      scroll-mt-[10vw] scroll-mb-[5vw]
      ${  fade ? "opacity-0" : ""}
      ${isMounted?"":"opacity-0"}
      ${isActive?"border-[#11256d] shadow-lg":"border-transparent"}
      `}
    >
      <div
        className={"w-[70%] bg-white rounded-[0.3vw] py-[1vw] px-[1.5vw] "}
      >
       
        <NerPara 
        key={key} 
        paraData={para_data} 
        paraId={paraId} 
        expanded={isActive}
        minlength={270}
        />
      </div>

      <div className=" border my-[1vw] border-dashed border-l-1"></div>
      <div className="w-[30%]">

        <ValidationControls
        acceptPara={acceptPara}
        rejectPara={rejectPara}
        handleTagSelection={handleTagSelection}
        loading={loading}
        readOnly={false}
        selectionInfo={selectionInfo}
        dropdownOptions={dropDownOptions}
        accepted={accepted}
        node_name={node_name}
        keyboardEnabled={keyboardEnabled || false}
        setKeyboardEnabled={setKeyboardEnabled}

        />
      </div>
      
    </div>
  );
};

export default StagePara;
