import ReactApexChart from "react-apexcharts";
import { useState } from "react";

export interface ApexChartProps {
  progress: number;
 
}
const FtChart: React.SFC<ApexChartProps> = (props) => {
  const { progress } = props;

  const [options, setOptions] = useState<any>({
    chart: {
      height: 250,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "50%",
          background: "transparent",
          image: undefined,
          color: "#FFF",
        },
        track: {
          background: "#C4C4C4",
          margin: 5,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: [ "#1BA94C"],
    labels: ["Stage1"],
    legend: {
      show: false,
      floating: false,
      fontSize: "16px",
      position: "left",
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName: any, opts: any) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
  
    responsive: [
      {
      breakpoint: 3000,
      options: {
        chart: {
          height: 350,
        },
      
        
      },
    },
      {
        breakpoint: 2159,
        options: {
          chart: {
            height: 280,
          },
        
          
        },
      },
      {
        breakpoint: 1920,
        options: {
          chart: {
            height: 250,
          },
       
          
        },
      },
      {
        breakpoint: 1720,
        options: {
          chart: {
            height: 200,
          },
       
          
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            height: 120,
          },
       
          
        },
        
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            height: 100,
          },
       
          
        },
        
      },
      
    ],
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={[progress]}
        type="radialBar"
        height={350}
      />
    </div>
  );
};
export default FtChart;
