import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import Button from "../controls/Button";
import InputField from "../controls/InputField";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorComp from "../controls/ErrorComp";
import SuccessComp from "../controls/SuccessComp";
import axios from "../../utils/Axios";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import { values } from "lodash";
import {ReactComponent as LoadingWhite} from '../../assets/loading_white.svg';

interface Values {
    email: string;
    password: string;
}

interface LoginFormProps {
    handleFormSubmit: any,
    loginError: any,
    setLoginError: any,
    signupMessage: any, 
    setSignupMessage: any
}

let LoginForm: React.FC<LoginFormProps> = ({ handleFormSubmit, loginError, setLoginError, signupMessage, setSignupMessage }) => {
    //console.log("=== Recaptcha Key ===",process.env.REACT_APP_RECAPTCH_SITE_KEY);
    let navigator = useNavigate();

    let validate = (values: Values) => {
        let errors: Partial<Values> = {};
        let regEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!values.email) {
            errors.email = "Email Required";
        } else if (!regEmail.test(values.email)) {
            errors.email = "Please enter a valid Email Id";
        }
        if (!values.password) {
            errors.password = "Password Required";
        }

        return errors;
    };



    let formik = useFormik<Values>({
        initialValues: {
            password: "",
            email: "",
        },
        validate,
        onSubmit: values => handleFormSubmit(values),
    });
    
    return (
        <>
            <form data-testid="form" onSubmit={formik.handleSubmit}>
                {/* <ErrorComp data-testid="err-message" errorMsg={loginError.msg} show={loginError.show} onClose={() => setLoginError({ show: false, msg: "" })} /> */}
                <div className="space-y-[2.5vw]">

                <SuccessComp data-testid="success-message" successMsg={signupMessage.msg} show={signupMessage.show} onClose={() => setSignupMessage({ show: false, msg: "" })} />

                    <div className="text-center font-medium text-[1.2vw] font-medium">
                        Sign In to the DataNeuron ALP
                    </div>

                    
                    
                    <div className="space-y-[1.8vw]">
                    <ErrorComp data-testid="err-message" errorMsg={loginError.msg} show={loginError.show} onClose={() => setLoginError({ show: false, msg: "" })} />
                        <div>
                            <InputField
                                placeholder="Email Address"
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                data-testid="email"
                            />

                            <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.email && formik.errors.email}</div>
                        </div>
                        <div className="">
                            <InputField
                                placeholder="Password"
                                type="password"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                data-testid="password"
                            />

                            <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.password && formik.errors.password}</div>
                        </div>
                        <div className="underline cursor-pointer text-right text-[1vw] !mt-[0.5vw]">
                            <span onClick={() => navigator("/reset_password")}>Forgot your password?</span>
                        </div>
                    </div>
                    <div className="bg-primarycolor cursor-pointer text-center rounded-[0.25vw]">
                    <button className="w-full h-[3vw]  cursor-pointer text-white text-[1vw]  "

                            disabled={formik.isSubmitting}
                            type={"submit"}
                        >
                            {formik.isSubmitting?<LoadingWhite width={"1.8vw"} height="2vw" />:"Login Now"}
                        </button>
                    </div>

                    <div className="flex space-x-[0.5vw] text-[1vw]" >
                        <span>
                            Not a registered user?
                        </span>
                        <span className="underline font-semibold cursor-pointer" onClick={() => navigator("/signup")}>Sign Up</span>
                    </div>
                </div>
            </form>
        </>
    );
};

export default LoginForm;
