import create from "zustand";
import { WorkflowStoreType } from "../types/store/WorkflowType";

let useWorkflowStore = create<WorkflowStoreType>(
          (set, _get) => ({
            currentWorkflow: null,
            setCurrentWorkflow: (workflow) => set({ currentWorkflow: workflow }),
            userProject: null,
            setUserProject: (userProject) => set({ userProject }),
            currentProject: null,
            setCurrentProject: (project) => set({ currentProject: project }),
            isConnected:false,
            setIsConnected:(status:boolean)=>set({isConnected:status})
        })
);

export default useWorkflowStore;
