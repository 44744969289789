import create from 'zustand';

interface notificationState{
    notifications:Array<any>,
    isSeen:boolean,
    setNotifications:any,
    addNotifications:any,
    setIsSeen:any
}

const useNotifications = create<notificationState>((set:any)=>({


    notifications:[],
    isSeen:true,
    setNotifications:(notifications:Array<any>)=>set({notifications:notifications}),
    addNotifications:(notification:any)=>set((state:any)=>({notifications:[notification,...state.notifications]})),
    setIsSeen:(seen:boolean)=>set((state:any)=>state.isSeen=seen)


}))


export default useNotifications;