import React from 'react';
import useWorkflowStore from '../../store/useWorkflowStore';

import template from '../../template';

interface ITrainingParasTab{
    hasStages:boolean;
}


export default function TrainingParasTab(
    {hasStages}:ITrainingParasTab) {

    const currentProject = useWorkflowStore(state=>state.currentProject);
    const workflowType:string = currentProject?.workflowType || "";
    const projectType:string = currentProject?.projectType || "";


    const TrainingParasComponent = template[projectType][workflowType].summary.trainingParas


  return React.createElement(TrainingParasComponent,{hasStages:hasStages});
}
