import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContainer from "../../components/auth/AuthContainer";
import ErrorComp from "../../components/controls/ErrorComp";
import InputField from "../../components/controls/InputField";
import Axios from "../../utils/Axios";
import { toast } from 'react-toastify';
import {ReactComponent as LoadingWhite} from '../../assets/loading_white.svg';


interface SetNerPasswordForm {
    password: string;
    confirmPassword: string;
}

let SetNewPassword: React.FC = () => {
    let [searchParams] = useSearchParams();
    let navigator = useNavigate();
    let [isValid, setIsValid] = useState<boolean>();
    let [loading, setLoading] = useState<boolean>(true);

    let [message, setMessage] = useState<string>();

    let [newPassError, setNewPassError] = useState({
        show: false,
        msg: "",
    });
    useEffect(() => {
        if (!searchParams.get("token")) {
            setMessage("No Token Provided");
            setLoading(false)
            return;
        }
        Axios.post(`/user/validateToken`, {
            token: searchParams.get("token")
        }).then((res) => {
            console.log(res);
            if (res.data.result) {
                setIsValid(true)
                setLoading(false)
            }
            else {
                setIsValid(false);
                setMessage(res.data.message);
                setLoading(false)
            }
        }).catch((err) => {
            toast("Some Error Occured", {
                type: "error",
            });
        });
    }, [])
    let validate = (values: SetNerPasswordForm) => {
        let errors: Partial<SetNerPasswordForm> = {};

        if (!values.password) {
            errors.password = "Required";
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = "Passwords must match";
        }

        return errors;
    };

    let onSubmit = async (values: SetNerPasswordForm) => {
        let res = await Axios.post("/user/resetPassword", {
            token: searchParams.get("token"),
            new_password: values.password,
        });
        console.log(res);
        if (res.data.result) {
            navigator("/");
            toast("Password Reset Successful.", {
                type: "info",
            });
        } else {
            setNewPassError({
                show: false,
                msg: res.data.message || "There was some issue",
            });
        }
    };

    let formik = useFormik<SetNerPasswordForm>({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validate,
        onSubmit,
    });

    return (<>
        {
            loading ?
                <>
                </>
                :
                (
                    isValid ?
                        <AuthContainer>

                            <ErrorComp errorMsg={newPassError.msg} show={newPassError.show} onClose={() => setNewPassError({ show: false, msg: "" })} />
                            <form onSubmit={formik.handleSubmit}>

                            <div className="space-y-[2.5vw]">
                                <div className="text-center font-[550] text-[1.1vw]">
                                    Set your new Password
                                </div>
                                <div className="space-y-[1.8vw]">
                                    <div>
                                        <InputField
                                            type="password"
                                            name="password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder="Enter your new password"
                                            value={formik.values.password}
                                        />

                                        <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.password && formik.errors.password}</div>
                                    </div>
                                    <div>
                                        <InputField
                                            type="password"
                                            name="confirmPassword"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder="Re-enter your new password"
                                            value={formik.values.confirmPassword}
                                        />
                                        <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.confirmPassword && formik.errors.confirmPassword}</div>
                                    </div>
                                </div>

                                <div className="bg-primarycolor text-center rounded-[0.25vw]" >
                                    <button className="w-full cursor-pointer text-white text-[1vw] h-[3vw] "
                                        disabled={formik.isSubmitting}
                                        type="submit"
                                    >
                                      {formik.isSubmitting?<LoadingWhite width={"1.8vw"} height="2vw" />:"Reset Password"}  
                                    </button>
                                </div>

                                <div className="flex space-x-[0.5vw] text-[1vw]" >
                                    <span>
                                        Not a registered user?
                                    </span>
                                    <span className="underline text-semibold" onClick={() => navigator("/signup")}>Sign Up</span>
                                </div>
                            </div>
                            </form>
                        </AuthContainer>
                        :
                        <div className="h-screen w-full text-gray-500 flex flex-col justify-center items-center">
                            <div className="text-3xl"> Error 404 | Page Not Found</div>
                            <div className="text-2xl mt-[1vw]">{message}</div>
                        </div>
                )
        }
    </>
    );
};

export default SetNewPassword;
