import React from 'react'
import useWorkflowStore from '../../store/useWorkflowStore'
import template from '../../template';
import AppLayout from '../../components/layouts/AppLayout';
import PageLayout from '../../components/layouts/PageLayout';
import { useNavigate } from 'react-router-dom';
import Ft_ModelTrainingLoading from '../../components/llm-customization/rlhf-fine-tuning/ft-modal-training-1';

import LoadingScreen from '../../components/loading-screen';

export default function LLMFineTuningPage() {

    const project = useWorkflowStore(state=>state.currentProject);
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const navigate = useNavigate();

    const projectType = project?.projectType ;
    const workflowType = project?.workflowType ;

    const Components = workflowType && projectType && template[projectType][workflowType]?.modelTraining?.component;
    const llmProgress = workflow?.alpdsInfo?.percentageStatus?.automlProgress?.llmProgress || 0;
    
  return (
    <AppLayout>
        <PageLayout 
        pageIconUrl='/images/active_automl.svg'
        pageTitle={`${project?.name}`}
        pageSubHeading='LLM Model Selection'
        >
          {/* <HyperParameterTuningComp /> */}
            {(!llmProgress || llmProgress<100) && <Ft_ModelTrainingLoading />}
            {llmProgress && llmProgress===100 &&  Components?.deploy?React.createElement(Components.deploy):null}
        </PageLayout>
    </AppLayout>
  )
}
