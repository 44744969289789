import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWorkflowStore from '../../../../store/useWorkflowStore';
import Axios from '../../../../utils/Axios';
import authStore from '../../../../store/authStore';
import { saveAs } from 'file-saver';

//Icons
import {ReactComponent as Loading} from '../../../../assets/loading_blue.svg';
import useAuthStore from "../../../../store/authStore";
import { getAnnotatedPrompts } from "../../_apiCalls";

interface LLM_ExportCardProps {
  activeStage: number;
  // practiceAreaId: string | undefined;
}

const LLM_ExportCard: React.FC<LLM_ExportCardProps> = ({
  activeStage,

}) => {
  let currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
  let currentProject = useWorkflowStore(state=>state.currentProject);
  const user = useAuthStore(state=>state.userDetails);
  
  const canExport = true;
  let isValidation = currentWorkflow?.stages?.find((stage:any)=>stage.name==="validation")?.active || false;
  const active = isValidation && canExport;
  
  const [loading,setLoading] = useState<boolean>(false)
  
  
  
  const exportValidatedData = async()=>{
    if(!currentWorkflow) return;

    try{
        const {error,data} = await getAnnotatedPrompts(currentWorkflow?.workflowId);

        if(error) throw new Error("There was an error while fetching the data for export");
        if(!data || !data?.length) throw new Error("There is no data to export, please annotate some data and try again!");

        let final_data:any ={
            project_name:currentProject?.name,
            workflow_version:currentWorkflow?.workflowName,
            annotator:user?.email,
            annotated_data:[],

        }
        data.forEach((prompt:any)=>{
            let validationData = prompt.result;
            
            let result:any[] = [];

            prompt.result.forEach((res:any)=>{
                //console.log("=== prompt ===>",prompt);
               // console.log("=== result ===>",res);
                let temp:any ={};
                let response_data =prompt.prompt_data.data.find((response:any)=>response.responseId === res.responseId);
               // console.log("=== response data ===>",response_data);
                temp.rank = res.rank;
                temp.relevance= res.relevance;
                temp.response = response_data.text;
                temp.source=response_data.source

                result.push(temp);
            })

            final_data.annotated_data.push({
                prompt:prompt.prompt_data.prompt,
                result:result
            })
            
        })
        setLoading(false);
        downloadData(final_data,`${currentProject?.name}_validated_prompt.json`)
        console.log("=== data to export ===>",final_data);


        // if(data){
        //     let validationData = result.data.data;
        //     const cached_ent:any={};
        //     const final_data:any  ={

        //         "project name":currentProject?.name,
        //         "workflow name":currentWorkflow.workflowName,
        //         "ner info":validationData.map((v:any)=>{

        //             let userValidations:any[] = v.validations[user?.userId || ""];
                   
        //             return {
        //                 text:v.para_info.paraData,
        //                 validations:userValidations.map((v2)=>{
        //                 let entity:string =""
        //                 if(cached_ent[v2.entityId]){
        //                     entity=cached_ent[v2.entityId]
        //                 }else{
        //                     const found_ent = entities.find(ent=>ent.entityId === v2.entityId);
        //                     cached_ent[v2.entityId] = found_ent?.entity

        //                 };
        //                     return {
        //                         startIndex:v2.startIndex,
        //                         endIndex:v2.endIndex,
        //                         word:v2.word,
        //                         entity:cached_ent[v2.entityId]
        //                     }



        //                 })
        //             }


        //         })

        //     }
        //     //end of final data

        //     downloadData(final_data,`${currentProject?.name}_validated_paras.json`)



        // }




    }catch(e){
        toast.warn("There was an error while export validated data");
        console.log("=== error while downloading validation data ===",e);
    }




  }

    //export entities 
    const downloadData=(data:any,filename:string)=>{

        const link = document.createElement<"a">("a");
            const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
            link.href = jsonString;
            link.download = filename;
            link.click();
    
            setTimeout(()=>{
                link.remove();
            },1000);
    
      }



  return (
    <div
      className="p-3  bg-white w-full shadow-lg relative"
      style={{
        width: "20vw",
        height: "15vw",
      }}

    >
      <div className="flex space-x-2 absolute top-[0.5vw] left-[0.5vw]  ">
        <img
          width={17}
          height={22}
          src="/images/deployBlue.svg"
          alt="validate"
        ></img>
        <p className="text-[1.1vw] font-medium ">Export</p>
      </div>
      <div className="flex w-full h-full justify-center  items-center " >
        <div
          className={`text-[1vw] flex justify-center items-center text-center
          cursor-${active ? "pointer" : "not-allowed"
            } ${active
              ? !loading?"hover:bg-primarycolor hover:text-white text-primarycolor":""
              : "bg-gray-300 text-white"
            }`}
          onClick={() =>
            // trained
          {  
            if(loading) return;
            if(!active){
              toast.warn("Retrain once to export dataset with result");
              return;
            }
            setLoading(true);
            active 
              ? exportValidatedData()
              : toast("Initiate validation to export data", {
                type: "warning",
              })
            }
          }
          style={{
            border: `1px solid ${active? "#2C2C2C" : "white"}`,

            width: "15vw",
            height: "2.5vw",
          }}
        >
          {loading?
           <Loading className="w-[2vw] h-[2vw] hover:stroke-white" />:"Export Dataset "}
        </div>
      </div>
    </div>
  );
};

export default LLM_ExportCard;
