import React from 'react';
import AuthContainerBilling from '../../components/auth/AuthContainerBilling';
import BillingLayout from '../../components/layouts/BillingLayout';

const UsageLimitExceeded: React.FC = () => {

    return (
        <BillingLayout>
            <AuthContainerBilling>
                <div className='flex items-center flex-col justify-center space-y-[2.5vw] text-center'>
                    <img className='w-[7vw]' src="/images/warning.svg" alt="warning" />
                    <h1 className='text-[1.8vw]'>Usage Limit Exhausted</h1>
                    <p className='text-[1.1vw] text-[#999999]'>You have exhausted the usage limit. Please contact a DataNeuron admin.</p>

                    <a href='mailto:support@dataneuron.ai'>
                        <div className="bg-primarycolor cursor-pointer text-center rounded-[0.25vw]">
                            <button className="w-[18vw] h-[3vw] cursor-pointer text-white text-[1vw]" type="submit">
                                Contact Admin
                            </button>
                        </div>
                    </a>
                </div>
            </AuthContainerBilling>
        </BillingLayout>
    )
}

export default UsageLimitExceeded;