import React, { useState } from 'react'
import axios from "../../../utils/Axios";
import { toast } from "react-toastify";

//stores
import useAdvancedMasterlist,{removeWordFromSelected} from '../../../store/useAdvancedMasterlist';
import useWorkflowStore from "../../../store/useWorkflowStore";

//components
import ParaSlider from '../ParaSlider/ParaSlider';
import BookMarkMenu from './BookMarkMenu';


//icons
import {ReactComponent as BookMarkIcon} from '../../../assets/bi_bookmark.svg';
import {ReactComponent as BookmarkFilled} from "../../../assets/bi_bookmark-fill.svg";

interface KeywordProps{
    keyword:any,
    activeKeyword: number,
    setActiveKeyword: any
}

const KeywordCard:React.FC<KeywordProps>=({keyword,activeKeyword,setActiveKeyword})=> {
    const {selectedWords,setSelectedWords} = useAdvancedMasterlist();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(true);
    const [paraData,setParaData] = useState<Array<string>>([]);
    
    const workflowStore = useWorkflowStore();

    const keywords = useAdvancedMasterlist(state=>state.keywords);

    const handleRemoveWord = async()=>{
        

            let word: any = selectedWords.find((w)=>w.word === keyword.word);
            
            if(word)
            {
                // API to delete keyword
                let userProject: any = workflowStore.userProject;
                let currWorkflowId = workflowStore.currentWorkflow?.workflowId; 
                axios.post("/wishlist/deleteKeyword", {
                    userId: userProject?.userId,
                    projectId: userProject?.projectId,
                    workflowId: currWorkflowId, 
                    word: word.word
                })

                toast("Keyword removed from bookmarks", { type: toast.TYPE.SUCCESS });
            
                // Update State
                let newState = removeWordFromSelected(word,selectedWords);
                setSelectedWords(newState);
            } else {
                toast("Keyword does not exist!", { type: toast.TYPE.ERROR });
            }
    }


    return (
        <div>
        <div
            className='flex w-full hover:shadow-md h-[4vw] bg-white px-4  cursor-pointer'
            
            >
            

            <div data-testid="keyword-active-word-test"
            onClick={()=>{ activeKeyword == keyword.rank ? setActiveKeyword(-1) : setActiveKeyword(keyword.rank)}}
            className=" flex-[1] font-medium  text-[1vw] flex justify-start items-center">
                <p className='text-ellipsis overflow-hidden  h-[2vw]  '>
                    {keyword.word}
                </p>

            </div>

            <div data-testid="keyword-active-rank-test"
            onClick={()=>{ activeKeyword == keyword.rank ? setActiveKeyword(-1) : setActiveKeyword(keyword.rank)}}
            className="flex-[1] text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                <span> {keyword.rank}</span>

            </div>

            <div data-testid="keyword-active-frequency-test"
            onClick={()=>{ activeKeyword == keyword.rank ? setActiveKeyword(-1) : setActiveKeyword(keyword.rank)}}
            className="flex-[1] space-x-1 text-[#999999] text-center  text-[1vw] uppercase flex justify-start items-center">
                <span>{keyword.frequency}</span>

            </div>



            <div data-testid="keyword-active-percentage-test"
            onClick={()=>{ activeKeyword == keyword.rank ? setActiveKeyword(-1) : setActiveKeyword(keyword.rank)}}
            className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                

                    <span className="flex space-x-1 w-full">
                        {keyword?.percentPresence.toFixed(2)}%
                    </span>
                

            </div>

            <div data-testid="keyword-bookmark-test" className="relative flex-[0.1]  space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
               
                {selectedWords.find((w)=>w.word === keyword.word)
                ?
                <BookmarkFilled className="w-[1vw] h-[1vw] cursor-pointer
                                            hover:scale-110 duration-500"
                                data-testid="keyword-delete-bookmark-test"
                                onClick={()=>handleRemoveWord()}
                                            />
                :<BookMarkIcon onClick={() => setIsMenuOpen(true)}
                data-testid="keyword-open-bookmark-test"
                className="w-[1vw] h-[1vw] cursor-pointer
                            hover:scale-110 duration-500" />    
                }
                {<BookMarkMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} keyword={keyword.word} />}


            </div>




        </div>
        <div className="">
            {keyword.rank == activeKeyword && 
                <ParaSlider 
                    paras={keyword.sampleParaIds} 
                    isOpen={keyword.rank == activeKeyword} 
                    loading={loading} setLoading={setLoading} 
                    paraData={paraData}
                    setParaData={setParaData}
                    keyword={keyword.word}
                /> 
            }
                                    
        </div>
        </div>


    )
}

export default KeywordCard