import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContainer from "../../components/auth/AuthContainer";
import SignUpForm from "../../components/auth/SignUpForm";
import axios from "../../utils/Axios";

interface Values {
    email: string;
    password: string;
    recaptchaToken: string;
    firstName: string;
    company: string;
    lastName: string;
    rePassword: string;
}


let SignUp: React.FC = () => {
    let navigate = useNavigate();
    let [signUpError, setSignUpError] = useState({ show: false, msg: "" });

    let handleFormSubmit = async (values: Values) => {

        try {
            //     console.log("=== Values in state ===",values);
            // console.log("===User Agent ===",window.navigator.userAgent);
            let serverRes = await axios.post("/user/signup", {
                ...values,
                userAgent: window.navigator.userAgent,
                ipAddress: "1.2.3.4.5.6",
                role: "ADMIN",
                status: "WAITING",
                isBillingAccount: true,
                associatedBillingAccount: ""
            });
            //console.log("=== Response from server after sign in  ===",serverRes.data);
            if (serverRes.data.result) {
                navigate(`/signin?firstName=${values.firstName}`);
            } else {
                setSignUpError({
                    show: true,
                    msg: "There was some error during signing you up",
                });
            }
        } catch (err: any) {
            // console.error("=== Error while sign up ===",err.response);
            setSignUpError({
                show: true,
                msg: err.response.data.data.message || "Some error occurred while signing you up ,please check the credentials",
            });
        }
    };

    return (
        <AuthContainer>
            <SignUpForm
                handleFormSubmit={handleFormSubmit}
                signUpError={signUpError}
                setSignUpError={setSignUpError}
            />
        </AuthContainer>
    );
};

export default SignUp;
