import React,{useEffect, useState} from 'react'
import {Select} from 'antd';

//stores
import useAdvancedMasterlist,{addWordToSelected,UpdateRecentFolders,updateFolder} from '../../../store/useAdvancedMasterlist';
import useWorkflowStore from "../../../store/useWorkflowStore";

import {ReactComponent as CrossIcon} from '../../../assets/cross.svg';
import axios from "../../../utils/Axios";
import { toast } from "react-toastify";

interface BookMarkMenuProps{
    isOpen:boolean,
    setIsOpen:any,
    keyword:string
}

const BookMarkMenu:React.FC<BookMarkMenuProps>= ({isOpen,setIsOpen,keyword})=> {

    const folders = useAdvancedMasterlist(state=>state.folders);
    const setFolders = useAdvancedMasterlist(state=>state.setFolders);
    const {selectedWords,setSelectedWords,recentFolders,setRecentFolders} = useAdvancedMasterlist();
    const [folderSelected,setFolderSelected]  = useState("none");

    const workflowStore = useWorkflowStore();

    useEffect(()=>{
        setFolderSelected("none")
    },[isOpen]);


    const handleDone = async () => {

        // API to add keyword with its adjecent folder
        try {
            let userProject: any = workflowStore.userProject;
            let currWorkflowId = workflowStore.currentWorkflow?.workflowId; 
            axios.post("/wishlist/addKeyword", {
                userId: userProject?.userId,
                projectId: userProject?.projectId,
                workflowId: currWorkflowId, 
                folder: folderSelected == "none" ? "" : folderSelected,
                word: keyword
            })

            toast("Keyword added to bookmarks", { type: toast.TYPE.SUCCESS });

            // if (folderSelected == "none") {
            //     toast("Keyword bookmarked", { type: toast.TYPE.SUCCESS });
            // } else {
            //     toast("Keyword added for '" + folderSelected + "'", { type: toast.TYPE.SUCCESS });
            // }

            // Update State
            let  newState = addWordToSelected({word:keyword,folder:folderSelected},selectedWords);
            
            setSelectedWords(newState);
            setFolders(updateFolder(folderSelected,folders));
            setRecentFolders(UpdateRecentFolders(folders));
            
            setIsOpen(false);
        } catch {
            toast("Couldn't add keyword right now", { type: toast.TYPE.ERROR });
        }
    }

    return (
        <>
      
     {isOpen &&   <div onClick={()=>setIsOpen(false)}
        className={`fixed top-0 left-0  h-screen w-screen z-[98]
                    cursor-default `}></div>}
    
    
    <div data-testid="bookmark-is-open-test" className={` cursor-default rounded-md bg-white shadow-md 
                      absolute z-[99] top-[1vw] right-[1vw]
                    border overflow-hidden duration-300 origin-top-right
                    w-[20vw]  p-[1vw]
                    ${isOpen?"scale-100 opacity-100":"scale-0 opacity-0"}
                    space-y-[1vw] normal-case`}>


        <div className="flex justify-between items-center">
            <p className="text-[1vw] text-black font-semibold">Add To Folder</p>
            <div data-testid="bookmarks-close-button-test" className="text-[1vw] cursor-pointer" onClick={()=>setIsOpen(false)}>
                <CrossIcon className="w-[1vw] h-[1vw]"/>
                </div>
        </div>


      {recentFolders.length>0 &&  <div>

            <div className="text-[1vw] text-black">Recent Folders</div>
            <div className="flex items-center p-2 space-x-4">
              
              {
                recentFolders.map((key:string, index: number)=>
                (
                                <div key={index} className={`w-1/2 py-[0.5vw] text-center px-[0.2vw] text-[0.9vw]  
                                border rounded-full
                                cursor-pointer
                                ${folderSelected===key?"bg-[#11256D] text-white"
                                                        :" hover:bg-[#11256D] hover:text-white hover:scale-105"} 
                                duration-500
                                ` }
                                data-testid="recent-folders-test"
                                onClick={()=>setFolderSelected(key)}>{key}</div>
              
                ))

              }
              
                
            </div>



        </div>}



            <div className=" space-x-4 flex items-center">
                <div className="text-[1vw]">Name:</div>
                <div className="flex-1">
                    
                    <Select
                        onChange={(value) => setFolderSelected(value)}
                        value={folderSelected}
                        listItemHeight={20}
                        data-testid="bookmark-folder-select-test"
                        // @ts-ignore
                        // inputProps={{ "data-testid": "bookmark-folder-select-test" }}
                        className="flex-1 w-full !bg-white border !text-[1vw] !py-[0.4vw]  rounded bg-[#EAEAEA] "
                        bordered={false}>
                        <Select.Option className="text-[0.8vw] py-3" value={"none"}>none</Select.Option>
                        {
                            Object.keys(folders).map((folderName, index)=>{
                               return (<Select.Option key={index} className="text-[0.8vw] py-3" value={folderName} data-testid="bookmark-folder-option-test">
                                    {folderName}
                                    </Select.Option>)

                            })
                        }  
                        
                      
                    </Select>
                </div>

            </div>

        <div className="flex items-center justify-end">
            <button
                data-testid="bookmark-menu-done-test"
                className="px-4 py-2 bg-[#11256D] rounded-md text-white
                            hover:scale-110 duration-500 cursor-pointer "
                onClick={() => handleDone()}
            >Done</button>
        </div>
    </div>
        </>

    )
}

export default BookMarkMenu