
import { Select, Checkbox } from 'antd';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AddUserComp from './AddUserComp';
import Axios from '../../utils/Axios';
import useAuthStore from '../../store/authStore';
import useCompanyUsersStore from "../../store/useCompanyUsersStore";
import { useNavigate } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { a } from 'msw/lib/glossary-297d38ba';
import template from '../../template';
import Button from '../mlc-components/model-comparison/Button';
import Header from './Header';
import React from 'react';

type Props = {
    projectType: string,
    setStep: any,
    step: number,

}

export default function AddWorkflowData({ projectType, setStep, step }: Props) {

    const userDetails = useAuthStore(state => state.userDetails);
    const isAuthenticated = useAuthStore(state => state.isAuthenticated);
    let { companyUsers } = useCompanyUsersStore();
    let { usage, setUsage } = useAuthStore();
    const navigate = useNavigate();

    const [listOfUsers, setListOfUser] = useState<Array<any>>([]);
    const [workflowTypeOptions, setWorkflowTypeOption] = useState<Array<any>>([]);

    const [billingAccount, setBillingAccount] = useState<any>({});

    const [name, setName] = useState("");
    //  const [acronym, setAcronym] = useState("");
    const [workflowType, setWorkflowType] = useState<null | string>(null);

    const [allowMultiUser, setAllowMultiUser] = useState<boolean>(false);
    const [addedUsers, setAddedUsers] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [apiKey, setApiKey] = useState<string>("");
    const [llmType, setLlmType] = useState<string | null>(null);

    const apiKeyMinLength = 20;

    const controls = template[projectType]?.createProjectControls || null;
    const hasLLMInputs = template[projectType]?.hasLLMInputs || false;
    const llmTypeOptions = template[projectType]?.llmModels || [];

    // Setting associated billing account
    useEffect(() => {
        if (!userDetails?.isBillingAccount) {
            let temp = companyUsers.filter((user: any) => { return user.userId === userDetails?.associatedBillingAccounts[0] })
            if (temp) {
                setBillingAccount(temp[0]);
            }
        } else {
            setBillingAccount(userDetails);
        }

    }, []);

    //Fetch Users, WorkflowTypes And Project Types
    useEffect(() => {
        let currentProject = template[projectType];
        setWorkflowTypeOption(() => currentProject.workflows);
        setWorkflowType(null);
        setAllowMultiUser(currentProject.allowMultiUser);
        if (!currentProject.allowMultiUser) {
            setAddedUsers((prev: any[]) => [prev[0]])
        }

        const fetchUsers = async () => {
            try {
                let res = companyUsers;

                if (res) {
                    console.log("=== Got Users From Same Company ===", res);

                    setListOfUser(res.map((user: any) => {
                        if (user.email === userDetails?.email) {
                            user.UPLOAD_ACCESS = true
                            user.MASTERLIST_ACCESS = true
                            user.VALIDATION_ACCESS = true
                            user.TRAINING_ACCESS = true
                            user.DEPLOYMENT_ACCESS = true

                            setAddedUsers([...addedUsers, user]);
                            return user;
                        }
                        else {
                            user.UPLOAD_ACCESS = false
                            user.MASTERLIST_ACCESS = false
                            user.VALIDATION_ACCESS = false
                            user.TRAINING_ACCESS = false
                            user.DEPLOYMENT_ACCESS = false

                            return user;
                        }
                    }));
                }

            } catch {
                toast("Could not fetch users from same company. Please reload the page.", { type: toast.TYPE.ERROR });
            }
        }


        if (isAuthenticated) {

            //fetchWorkflowTypes();
            //fetchProjectTypes();
            fetchUsers();
        }


    }, [isAuthenticated]);


    const handleSubmit = async () => {

        console.log({

            workflowType,
            projectType,
            name,
            //acronym,
            userProject:
                [...addedUsers]
        });


        // if (name === "" || acronym === "" || projectType === "" || workflowType === "") {
        if (name === "" || projectType === "" || workflowType === "") {
            toast("All the fields are required.", { type: toast.TYPE.ERROR });
            return;
        }


        try {
            setLoading(true);

            let res = await Axios.post("/project/create", {


                workflowType,
                projectType,
                name,
                // acronym,
                userProject:
                    [...addedUsers]



            });
            console.log("=== After Project Created ===", res.data);

            // Updating state
            let temp = usage
            if (temp) {
                temp.details.noOfProjects = temp.details.noOfProjects + 1;
                temp.details.noOfWorkflows = temp.details.noOfWorkflows + 1;
                setUsage(temp);
            }

            toast("The project was created successfully.", { type: toast.TYPE.SUCCESS });
            navigate("/workflow?workflowId=" + res.data.data.project.lastAccessedWorkflowId)
        } catch (err) {
            toast("An error occurred while creating a new project.", { type: toast.TYPE.ERROR });


        }
        finally {
            setLoading(false);
        }

    }


    return (
        <>
            <Header
                title={`Creating a ${template[projectType].name} project`}
                canGoBack={true}
                onClick={setStep}
            />
            <div className="flex bg-white space-y-[2vw] flex-col
         min-h-[72vh]  p-[2vw] relative
         shadow-md ">
                {/* First Row */}

                <div className="flex  space-x-[2vw]  w-[90%]">
                    <div className="flex-1">


                        <div className="font-semibold text-[1vw]">Project Name <span className='text-red-400'>*</span></div>
                        <input className="focus:outline-none text-[1vw] mt-2 border-[#c4c4c4] bg-gray-50   rounded-[0.2vw] border-[0.1vw] px-[0.5vw] py-[0.5vw] w-full"
                            type="text"
                            placeholder="Enter Project Name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </div>


                    <div className="flex-1">
                        <div className="font-semibold text-[1vw]">Billing Account Associated With The Project <span className='text-red-400'>*</span></div>


                        <Select className='mt-2 w-full flex items-center text-[1vw]
                    h-[2.5vw] rounded-[0.2vw] 
                    border-[0.1vw] border-[#c4c4c4]
                    bg-[#FAFAFA]
                    '
                            suffixIcon={<BsChevronDown className="text-[1vw] -ml-[0.5vw]" />}
                            placeholder="Select Billing Account"
                            bordered={false}
                        // suffixIcon={<BsChevronDown style={{width:"1.3vw", height:"1.3vw"}}/>}

                        >

                            <Select.Option className="text-[1vw] py-[0.4vw]" value={billingAccount.userId}>{billingAccount.email}</Select.Option>

                        </Select>
                    </div>

                    <div className="flex-1">


                    </div>



                </div>
                {/* Second Row */}

                <div className="flex  space-x-[2vw]   w-[90%]">


                    <div className="flex-1 ">

                        <div className="font-semibold  text-[1vw]">Workflow Type <span className='text-red-400'>*</span></div>
                        <Select className='mt-2 w-full 
                    flex items-center text-[1vw] 
                    h-[2.5vw] rounded-[0.2vw] 
                    border-[0.1vw] border-[#c4c4c4]
                    bg-[#FAFAFA]'
                            disabled={!projectType || !projectType.length}
                            suffixIcon={<BsChevronDown className="text-[1vw] -ml-[0.5vw]" />}
                            placeholder="Select Workflow Type"
                            value={workflowType}
                            bordered={false}
                            onChange={(value: string) => setWorkflowType(value)}
                        >
                            {
                                workflowTypeOptions?.map((w: any) => {


                                    return <Select.Option className="text-[1vw] py-[0.4vw]" value={w.id} >
                                        {w.text}

                                    </Select.Option>

                                }
                                )
                            }
                        </Select>
                    </div>

                    {/* Prompt Related Inputs */}
                    <div className="flex-1">
                        {hasLLMInputs ? <div>
                            <div className="font-semibold  text-[1vw]">
                                Select LLM Model
                                <span className='text-red-400'>*</span></div>
                            <Select className='mt-2 w-full 
                        flex items-center text-[1vw] 
                        h-[2.5vw] rounded-[0.2vw] 
                        border-[0.1vw] border-[#c4c4c4]
                        bg-[#FAFAFA]'
                                disabled={!projectType || !projectType.length}
                                suffixIcon={<BsChevronDown className="text-[1vw] -ml-[0.5vw]" />}
                                placeholder="Select Model Type"
                                value={llmType}
                                bordered={false}
                                onChange={(value: string) => setLlmType(value)}
                            >
                                {
                                    llmTypeOptions?.map((w: any) => {


                                        return <Select.Option
                                            className="text-[1vw] py-[0.4vw]"
                                            value={w.id} >
                                            {w.name}

                                        </Select.Option>

                                    }
                                    )
                                }
                            </Select>

                        </div> : null}

                    </div>
                    <div className="flex-1">


                    </div>



                </div>

                {/* API  Key */}
           {hasLLMInputs &&
                <div className='flex w-[59.5%] space-x-[2vw]'>

                    <div className='flex-1'>
                        <div className="font-semibold  text-[1vw]">
                            API Key
                            {/* <span className='text-red-400'>*</span> */}
                        </div>
                        <div className=''>
                            <input className="flex-1 focus:outline-none text-[1vw] mt-2 border-[#c4c4c4] bg-gray-50   rounded-[0.2vw] border-[0.1vw] px-[0.5vw] py-[0.5vw] w-full"
                                type="text"
                                placeholder={`Enter your${llmType?" "+llmType:""} api key`}
                                onChange={(e) => setApiKey(e.target.value)}
                                value={apiKey}
                            />
                            {
                                apiKey===null || apiKey.length<20?
                                <span className='text-red-500 text-[0.9vw] mt-[0.5vw] block'>
                                   *You have not provided a valid API Key, You can only create a trial project with a limit of 100 API calls for selected LLM.
                                </span>:null
                            }
                            {/* <div className='flex-1'>

                            </div> */}
                            {/* <div className='flex-1'>

                            </div> */}
                        </div>
                    </div>

                </div>
}
                {/* Third Row */}
                {allowMultiUser && <div className="  w-full ">
                    <div className='text-[1vw] font-semibold mb-[0.5vw]'>Add Users To ({name})</div>

                    <table className='w-full border border-disableColor'>
                        <thead>
                            <tr className='bg-grayColor'>
                                <th className='p-[0.8vw] text-[1vw] border border-collapse  border-disableColor'>User Name</th>
                                <th className='p-[0.8vw] text-[1vw] border border-collapse  border-disableColor'>Upload</th>
                                <th className='p-[0.8vw] text-[1vw] border border-collapse  border-disableColor'>Masterlist</th>
                                <th className='p-[0.8vw] text-[1vw] border border-collapse  border-disableColor'>Validate</th>
                                <th className='p-[0.8vw] text-[1vw] border border-collapse  border-disableColor'>Train/Export</th>
                            </tr>
                        </thead>
                        <tbody>

                            {addedUsers.length > 0 &&
                                addedUsers.map((user: any) =>


                                    <tr key={user.email} className="">
                                        <td className='text-[1vw] text-center border-collapse border border-disableColor !pr-0'>{`${user.firstName} ${user.lastName}`}</td>
                                        <td className=' text-center border-collapse p-[0.8vw] border border-disableColor'>
                                            <div className='flex items-center justify-center'>

                                                <input
                                                    type="checkbox"
                                                    disabled={!allowMultiUser}
                                                    checked={user.UPLOAD_ACCESS}
                                                    onChange={(e: any) => {
                                                        let state = addedUsers.find((ele: any) => ele.email === user.email);
                                                        state.UPLOAD_ACCESS = true;
                                                        setAddedUsers(() => addedUsers.map((ele: any) => {
                                                            if (ele.email === user.email) {

                                                                ele.UPLOAD_ACCESS = e.target.checked;
                                                                return ele;
                                                            }
                                                            return ele;
                                                        }));
                                                    }} className="hover:cursor-pointer disabled:cursor-not-allowed w-[1.3vw] h-[1.3vw]"
                                                />
                                            </div>

                                        </td>
                                        <td className=' text-center border-collapse p-2 border border-disableColor'>
                                            <div className='flex items-center justify-center'>

                                                <input
                                                    type="checkbox"
                                                    disabled={!allowMultiUser}
                                                    checked={user.MASTERLIST_ACCESS}
                                                    onChange={(e: any) => {
                                                        let state = addedUsers.find((ele: any) => ele.email === user.email);
                                                        state.MASTERLIST_ACCESS = true;
                                                        setAddedUsers(() => addedUsers.map((ele: any) => {
                                                            if (ele.email === user.email) {

                                                                ele.MASTERLIST_ACCESS = e.target.checked;
                                                                return ele;
                                                            }
                                                            return ele;
                                                        }));
                                                    }}

                                                    className="hover:cursor-pointer disabled:cursor-not-allowed w-[1.3vw] h-[1.3vw]"
                                                />
                                            </div>

                                        </td>
                                        <td className=' text-center border-collapse p-2 border border-disableColor'>
                                            <div className='flex items-center justify-center'>
                                                <input
                                                    type="checkbox"
                                                    disabled={!allowMultiUser}
                                                    onChange={(e: any) => {
                                                        let state = addedUsers.find((ele: any) => ele.email === user.email);
                                                        state.VALIDATION_ACCESS = true;
                                                        setAddedUsers(() => addedUsers.map((ele: any) => {
                                                            if (ele.email === user.email) {

                                                                ele.VALIDATION_ACCESS = e.target.checked;
                                                                return ele;
                                                            }
                                                            return ele;
                                                        }));
                                                    }} checked={user.VALIDATION_ACCESS}
                                                    className="hover:cursor-pointer disabled:cursor-not-allowed w-[1.3vw] h-[1.3vw]"
                                                />
                                                <Select
                                                    bordered={false} className='text-[0.8vw] w-[8vw]  rounded-[0.2vw] border-[0.05vw] border-[#c4c4c4] focus:outline-none rounded ml-3 p-0'
                                                    disabled={!addedUsers.find(u => u.email === user.email).VALIDATION_ACCESS || !allowMultiUser}
                                                    placeholder="Add Weight"
                                                    value={user.voting_weight}
                                                    onChange={(value) => {
                                                        setAddedUsers(() => addedUsers.map((u: any) => {
                                                            if (u.email === user.email) {
                                                                u.voting_weight = value;
                                                                return u;
                                                            }
                                                            return u;

                                                        })

                                                        )
                                                    }}
                                                >

                                                    <Select.Option className="text-[1vw] py-[0.4vw]" value={1} >1 (LOW)</Select.Option>
                                                    <Select.Option className="text-[1vw] py-[0.4vw]" value={2} >2</Select.Option>
                                                    <Select.Option className="text-[1vw] py-[0.4vw]" value={3} >3</Select.Option>
                                                    <Select.Option className="text-[1vw] py-[0.4vw]" value={4}>4</Select.Option>
                                                    <Select.Option className="text-[1vw] py-[0.4vw]" value={5}>5 (HIGH)</Select.Option>
                                                </Select>

                                            </div>
                                        </td>
                                        <td className=' text-center border-collapse p-[0.8vw] border border-t-0 border-disableColor'>

                                            <div className='flex items-center justify-center'>


                                                <input
                                                    type="checkbox"
                                                    disabled={true}
                                                    onChange={(e: any) => {
                                                        let state = addedUsers.find((ele: any) => ele.email === user.email);
                                                        state.TRAINING_ACCESS = true;
                                                        setAddedUsers(() => addedUsers.map((ele: any) => {
                                                            if (ele.email === user.email) {

                                                                ele.TRAINING_ACCESS = e.target.checked;
                                                                return ele;
                                                            }
                                                            return ele;
                                                        }));
                                                    }} checked={user.TRAINING_ACCESS}
                                                    className="hover:cursor-pointer disabled:cursor-not-allowed w-[1.3vw] h-[1.3vw]"

                                                />
                                            </div>

                                        </td>
                                    </tr>
                                )}

                            <tr>
                                <td className=' border border-collapse border-disableColor !pr-0 '>
                                    <div className='flex justify-center w-full'>

                                        <AddUserComp
                                            listOfUsers={listOfUsers}
                                            setAddedUsers={setAddedUsers}
                                            addedUsers={addedUsers}
                                            disabled={!allowMultiUser}
                                        />
                                    </div>
                                </td>
                                <td className=' border-collapse text-center p-[0.8vw] border border-disableColor'>

                                </td>
                                <td className='border-collapse text-center p-[0.8vw] border border-disableColor'>

                                </td>
                                <td className='border-collapse text-center p-[0.8vw] border border-disableColor'>

                                </td>
                                <td className='border-collapse text-center p-[0.8vw] border border-disableColor'>

                                </td>

                            </tr>

                        </tbody>
                    </table>


                </div>}
                <div className=' flex-1'>

                </div>

                {/* Final Row */}
                <div className='flex justify-end self-end
                            '>
                    {controls ?
                        React.createElement(controls, {
                            inputs: {
                                projectType,
                                workflowType,
                                billingAccount,
                                userProject:addedUsers,
                                name
                            },
                            meta: {
                                apiKey,
                                llmType,

                            },
                            apiKeyMinLength
                        })
                        : <Button
                            text={"Create Project"}
                            styles={"!w-[18vw] "}
                            disabled={false}
                            onClick={() => step === 1 && handleSubmit()}
                            loading={loading}
                        />}

                </div>

            </div >
        </>
    )
}
