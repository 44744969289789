import { boolean } from 'mathjs';
import React, { useEffect, useState } from 'react'

type Props = {
    workflow:any,
    promptId:string
}

function useIsPromptInProgress({
    workflow,
    promptId
}: Props):[boolean,any] {
    
    

    const [isPromptInProgress,setIsPromptIsInProgress] = useState<boolean>(false);

    useEffect(()=>{
        if(!promptId) setIsPromptIsInProgress(false);
        //console.log("=== change in workflow ===");
        const promptsInProgress:string[] = workflow?.alpdsInfo?.promptInProgress || [];
       // console.log("=== promptInProgress ===",promptsInProgress,promptId);
        if(!promptId || !workflow) return;
        let temp = promptsInProgress.find(p=>p === promptId);
        if(temp) console.log("=== prompt found in db ===");
        if(temp && !isPromptInProgress) setIsPromptIsInProgress(true);
        if(!temp && isPromptInProgress) setIsPromptIsInProgress(false);

    },[workflow,promptId]);

    return [isPromptInProgress,setIsPromptIsInProgress]

}

export default useIsPromptInProgress