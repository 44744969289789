import React from 'react';
import {ReactComponent as GreenTick} from '../../../../assets/Green_Tick.svg';
import {ReactComponent as GrayCross} from '../../../../assets/Gray_Cross.svg';

import {ReactComponent as CrossIcon} from '../../../../assets/CrossX.svg';
import {ReactComponent as TickIcon} from '../../../../assets/Tick.svg';

interface IMultiClassValidator{
    accepted:String | null,
    loading:boolean,
    acceptPara:()=>void,
    rejectPara:()=>void,
    readOnly?:boolean
}



function MultiClassValidator({
    acceptPara,
    rejectPara,
    loading,
    accepted,
    readOnly
}:IMultiClassValidator) {

  const isAccepted = accepted && accepted === "accepted";
  const isRejected =  accepted && accepted === "rejected";
  


  return (
    <div className={" flex justify-start items-center  space-x-[1vw] cursor-pointer "}>

    <div className={`relative border-[0.14vw] flex justify-center items-center group  w-[4.2vw] h-[2.8vw] rounded-md
                      ${ isAccepted?
                          "border-[#1BA94C] bg-[#1BA94C] ":""}
                      ${!isAccepted ? 
                          readOnly ? "bg-[#C5C3C3] cursor-not-allowed":"border-[#c7c7c7] hover:border-[#1BA94C]"
                                                                     :""}
                     
                    `}
    
    onClick={() => {!readOnly && !loading && accepted!=="accepted" && acceptPara() }}
    >
       

      <TickIcon
      className={`  duration-200  transition-transform w-[1.3vw] h-[1.3vw] 
      ${isAccepted?"stroke-white " :" "}
      ${!isAccepted ?  readOnly ? "stroke-white ":"stroke-[#c7c7c7] group-hover:scale-125 group-hover:stroke-[#1BA94C]"
                                                 :""}
       `}  
      
      
      />
    </div>

   

    <div className={`border-[0.14vw] group flex justify-center items-center w-[4.2vw] h-[2.8vw] rounded-md duration-500
                      relative
                      ${isRejected?"bg-[#E63946] border-[#E63946] ":""}
                      ${!isRejected ?  readOnly ? "bg-[#C5C3C3] cursor-not-allowed":"border-[#c7c7c7] hover:border-[#E63946]"
                                                                   :""}
                    `}
    onClick={() =>!readOnly && !loading && accepted!=="rejected" && rejectPara()}
    
    >
     
      <CrossIcon 
        className={`duration-200 w-[1vw] h-[1vw] 
        
        ${isRejected?" stroke-white " :""}
        ${!isRejected ?  readOnly ? "stroke-white ":"stroke-[#c7c7c7] group-hover:scale-125  group-hover:stroke-[#E63946]"
                                                 :""}
          `}
      

      />

    </div>
   
  </div>
  )
}

export default MultiClassValidator