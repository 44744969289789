import Axios from '../../../utils/Axios';



export const RevertToStage2 = async(workflowId:string)=>{
    //alert("Reverting this workflow to stage2");
    try{
        await Axios.post("/workflow/revertToStage2",{
            workflowId:workflowId
        });
        

    }catch{

    }
}

export const callNextBatch = async()=>{
    alert("Calling next batch before redirection");
}

