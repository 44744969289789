import * as React from "react";
import {useState} from 'react';
import { Wire } from "./utils/interface";

export interface WireContainerProps {
    wires: Array<Wire>;
    
}

let calculateHeight = (wires: Array<Wire>) => {
    let biggest = 0;
    wires.forEach((wire) => {
        if (wire.y1 > biggest) {
            biggest = wire.y1;
        }
        if (wire.y2 > biggest) {
            biggest = wire.y2;
        }
    });
    return biggest + 100;
};

const WireContainer: React.SFC<WireContainerProps> = ({ wires }) => {
    let containerWidth = 100;
    const [offset,setOffset] =useState<number>();
    const divRef = React.useRef<SVGSVGElement>(null);

    
    
    React.useEffect(()=>{
        const updateOffset = (event:any)=>{
           // alert(divRef.current?.scrollTop)
            setOffset(divRef.current?.scrollTop);
            
    
        }
        window.addEventListener("scroll",(e:any)=> updateOffset(e));

        return ()=> window.removeEventListener("scroll",()=>updateOffset);



    },[]);


    return (
        <svg 
        ref={divRef}
        style={{ 
            width: containerWidth,
            height: calculateHeight(wires),
            marginTop:`${offset}px`
            }}
        
        >
            {wires.map((wire, i) => {
                return (
                    <path
                        stroke={wire.stroke}
                        strokeWidth={1}
                        key={i * Math.random()}
                        fill={"transparent"}
                        d={`M 0 ${wire.y1} L ${containerWidth / 2 } ${wire.y1} L ${containerWidth / 2 } ${wire.y2} L ${containerWidth} ${
                            wire.y2
                        }`}
                    />
                );
            })}
        </svg>
    );
};

export default WireContainer;
