import React ,{useState}from 'react';
import { toast } from 'react-toastify';


import {ReactComponent as CopyIcon} from '../../assets/copy_squares.svg';

interface Keyword{
    frequency: number,
    percentPresence: number,
    samplePara:Array<string>,
    rank: number
}

interface ListItemProps{
    keyword:Keyword,
}

const unsecuredCopyToClipboard = (text: any) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

const copyToClipboard = (content: any) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content);
  } else {
    unsecuredCopyToClipboard(content);
  }
};


 const ListItem:React.FC<any>=({keyword})=>{

    const [isOpen,setIsOpen] = useState(false);

    return <>
        <div className='bg-white p-4 m-2 hover:shadow-md duration-300 flex items-center justify-between '>
        <p 
        data-testid="keyword-isopen-test"
        onClick={()=>setIsOpen(!isOpen)}
        className={`cursor-pointer text-[0.9vw] font-medium 
                    ${isOpen && "border-b2"}
        `}
        >{keyword.word}</p>
        <CopyIcon 
        data-testid="keyword-copy-test"
        onClick={()=>{
            copyToClipboard(keyword.word)
            toast("Word copied to clipboard",{type:toast.TYPE.SUCCESS});
        }}
        className='w-[1vw] h-[1vw]
                             cursor-pointer duration-700
                             hover:scale-125'/>
        {/* <div className={`bg-white  ${isOpen?"p-2":"h-[0]"} duration-500 overflow-hidden space-y-2`}>
            <div className='space-y-2'>
                <p className='text-[#c4c4c4]'>Keyword Rank</p>
                <p>5</p>
            </div>
            <div className='space-y-2'>
                <p className='text-[#c4c4c4]'>Keyword Frequency</p>
                <p>200</p>
            </div>
            <div className='space-y-2'>
                <p className='text-[#c4c4c4]'>Sample Paragraph</p>
                <p>This is some sample paragraph for the selected keyword</p>
            </div>
           
        </div> */}
        </div>
       

    </>

}

export default ListItem;