import React, { useState, useEffect } from 'react'
import useWorkflowStore from '../../../store/useWorkflowStore';
import { useRecoilValue } from "recoil";
import { nodesAtom } from '../../masterlist/atoms';
import Axios from '../../../utils/Axios';
import { toast } from 'react-toastify';
import useAuthStore from '../../../store/authStore';
import useKeyboard from '../../../hooks/useKeyboard';
import MLCStagePara from './validation/MLCStage2Para';
import { Pagination, Popover, Select, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import ClassFilterSelector from '../_shared/ClassFilterDropdown';


//Icons
import { ReactComponent as Loading } from '../../../assets/loading_blue.svg';
import { ReactComponent as Refresh } from '../../../assets/reload.svg';
import { ReactComponent as MultiUserIcon } from '../../../assets/mutiuser.svg';
import { ReactComponent as AchievedTarget } from '../../../assets/achieved_target.svg';
import { ReactComponent as TotalTarget } from '../../../assets/total_target.svg';
import { ReactComponent as LoadingWhite } from '../../../assets/loading_white.svg';

import template from '../../../template';
import LoadingScreen from '../../loading-screen';
import BatchFilter from './BatchFilter';
import checkForCriticalProgress from '../../../lib/checkForProgress';
import Switch from '../../data-validation/Switch';
import { BulkValidateParas, MLCBulkValidateParas } from '../../stage-2/helper';
import { callModelComparisonAPI } from '../model-comparison/_apiCall';
import {IterateToTrain, callAutoML} from '../_apiCalls';
import { TrainStatus, checkCurrentTrainStatus } from '../../../utils/navigationHelper';


const HoverMenu = ({ children, text }: any) => {
  return (

    <Popover
      className='!text-[2vw]'
      placement="top"
      content={text} color="white"

    >
      {children}
    </Popover>
  )
}


function Index() {

  //global states
  const projectType: string = useWorkflowStore(state => state.currentProject)?.projectType || "";
  const workflowType: string = useWorkflowStore(state => state.currentProject)?.workflowType || "";

  const stage2Endpoint: string = template[projectType][workflowType]?.stage2.endpoints.initBatch;
  const autoMLEndpoint: string = template[projectType][workflowType]?.stage2.endpoints.autoML;
  const GetParasEndpoint: string = template[projectType][workflowType]?.stage2.endpoints.getParas;


  const navigate = useNavigate();
  const nodes = useRecoilValue(nodesAtom);
  const currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
  const userId: string = useAuthStore(state => state.userDetails?.userId) || "";
  const firstName: string = useAuthStore(state => state.userDetails?.firstName) || "";
  const lastName: string = useAuthStore(state => state.userDetails?.lastName) || "";
  const createdBy: string = useWorkflowStore(state => state.currentProject?.createdBy) || "";
  const isCreater = userId === createdBy;
  // const stage1Progress =currentWorkflow?.alpdsInfo?.percentageStatus?.stage1Progress || 0;
  const stage2Progress = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress;
  const classificationReports: any = currentWorkflow?.alpdsInfo.stage2ClassificationReports;
  const currentBatchKey: string = classificationReports?.lastUpdatedKey;
  const currentBatch: number = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchNumber;
  // console.log("=== current batch key ===",typeof currentBatchKey?.slice(5) || 0);
  // console.log("=== current batch ===",currentBatch);




  //data store
  const [paras, setParas] = useState<Array<any>>([]);
  const [fileInfo, setFileInfo] = useState<any>({});

  const [dropdownOptions, setDropDownOptions] = useState<any>({});

  //count states
  const [count, setCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalParas, setTotalParas] = useState<number>(0);
  const [nodeCount, setNodeCount] = useState<any>({});
  const [multiUserNodeCount, setMultiUserNodeCount] = useState<any>({});
  const targetPara = 40;
  const MIN_USER_THRESHOLD = 1;

  //Loading States
  const [loading, setLoading] = useState<boolean>(false);
  const [batchLoading, setBatchLoading] = useState<boolean>(false);
  const [pieLoading, setPieLoading] = useState<boolean>(false);
  const [autoMLLoading, setAutoMLLoading] = useState<boolean>(false);

  //control states
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [canMoveToAutoML, setCanMoveToAutoML] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [batch, setBatch] = useState<number>(currentBatch);
  const [isReadOnly, setIsReadOnly] = useState<boolean>();

  const noTagName: string = "No Tag"




  const currentBatchInfo = currentBatchKey ? classificationReports[currentBatchKey] : {};


  //Listview information
  const trainingOffset = currentWorkflow?.listviewInfo.retrainTarget || 45; //200;
  const listviewInfo: any = currentWorkflow?.listviewInfo;
  // const [page, setPage] = useState<number>(listviewInfo?.userInfo?listviewInfo?.userInfo[userId]?.lastAccessedPage || 1:1);

  const pageSize = 20;
  const target = (listviewInfo?.lastTrainingCount | 0) + trainingOffset;
  const [isMinThreshold, setIsMinThreshold] = useState<boolean>(false);
  const [allUsersData, setAllUsersData] = useState<any>({});
  const canCallRetrain = totalCount >= target || count >= target;
  const [initNodeDelta, setInitNodeDelta] = useState<any>({});



  //keyboard navigation
  const [activePara, setActivePara] = useState<number>(0);
  const [keyboardEnabled, setKeyboardEnabled] = useState<boolean>(true);
  const ArrowDown = useKeyboard({ keyCode: "ArrowDown" });
  const ArrowUp = useKeyboard({ keyCode: "ArrowUp" });

  //Pagination states
  const offset = 20;
  const start = (page - 1) * pageSize;
  const end = start + offset;
  const current_paras = paras
    ?.filter((p: any) => filter.length > 0
      ? p.validations.indexOf(filter) !== -1
      : true);
  useEffect(()=>{
    
    


  },[currentWorkflow?.alpdsInfo,batch]);

  //Check if paragraph is read only
  const CheckParaReadOnly=(p:any):boolean=>{

    const currentStatus = checkCurrentTrainStatus(currentWorkflow);
    const isPrevBatch = currentBatch !== batch; 
    //console.log("current batch && batch",currentBatch,batch,p);

    if(currentStatus ===  TrainStatus.NotTrained 
      || currentStatus === TrainStatus.InIterate)
    {
      console.log("workflow is in iterate or not trained");
      return isPrevBatch && p.result.length>0;
    }

    return true;
  }



  const PrevPara = () => {
    if (!keyboardEnabled) return;
    if (ArrowUp && activePara > 0) {
      setActivePara(prev => prev - 1);
    }

  }


  const NextPara = () => {
    if (!keyboardEnabled) return;
    if (paras && activePara < 19) {

      setActivePara(prev => prev + 1);
    }
  }


  // ------ get total annotated paragraphs on all pages from multiuser pie chart -----
  const fetchCountDetails = async (isMounted: boolean) => {
    //console.log("multi user called")

    if (currentWorkflow) {
      try {

        //Set Loading True
        setPieLoading(true)

        let res = await Axios.post("/summary/multiuserpiechart", {
          workflowId: currentWorkflow.workflowId,
          stage: "stage2",
          batchNumber: currentBatch,
          requestType: 1
        })
        //console.log("graph data =>", res.data.data);
        let result = res.data.data;
        setAllUsersData(result);
        //let users = Object.keys(result)[0];
        let users: string = "num_user_" + MIN_USER_THRESHOLD;

        let classes = Object.keys(result[users]).filter((key: string) => key !== "null");

        let total = 0;
        let tempCount: any = {};
        classes.forEach((key: string) => {
          total += result[users][key] || 0;

          // tempCount[key] ={};
          // tempCount[key].count =result[users][key];

        });
        let node_ids: string[] = Object.keys(currentBatchInfo);

        node_ids.forEach((key) => {
          total += currentBatchInfo[key]?.validatedParas || 0;
          tempCount[key] = {}
          tempCount[key].count = result[users][key] || 0;
          tempCount[key].count += currentBatchInfo[key]?.validatedParas || 0;
        })
        // console.log("=== multi user data ===",tempCount);
        setTotalCount(total);
        setMultiUserNodeCount(tempCount);



      } catch (err) {
        toast("Could not get user pie chart data. Please reload the page.", { type: toast.TYPE.ERROR });
        console.log("error in multi user pie chart", err);
      }
      finally {
        setPieLoading(false);
      }

    }

  }


  // ------ Notify user of autovalidated paragraphs ------
  const notifyAutoValidatedParas = (avPara: any, batch: number) => {

    if (batch < currentBatch) return false;
    if (!currentWorkflow?.autoValidate[userId]) return false;
    const lastUpdatedTimeStamp: string | undefined = classificationReports?.lastUpdatedTimestamp;
    //const lastUpdatedTimeStamp:string | undefined =avPara.createdAt;
    let isAutoValidated = true;
    //console.log("classification report",classificationReports);

    if (lastUpdatedTimeStamp) {

      const predictions: string[] = avPara.predictedIssueId;
      const validations: string[] = avPara.validations[userId].map((v: any) => v.issueId);
      //console.log(predictions, validations);

      if (predictions.length !== validations.length) return false;

      // validations.forEach((issueId: any) => {

      //   if (!predictions.find(prediction => prediction === issueId)) return false;
      //   // let batchDate = new Date(lastUpdatedTimeStamp);
      //   // let validationDate = new Date(validation.timeStamp);
      //   // let dateDiff = validationDate.getTime() - batchDate.getTime(); 
      //   // //console.log("=== dates ===",batchDate.getTime(),validationDate.getTime())
      //   // console.log("=== notify autovalidate diff ===",avPara,dateDiff/1000);
      //   // if(dateDiff>20000) isAutoValidated= false;            

      // })

    }
    //console.log("=== notify autovalidate result ===",isAutoValidated);
    return isAutoValidated;

  }

  // ------ fetch paragraphs ------
  // ------ get total paragraphs ----
  const fetchParas = async (batch: number) => {


    //if (loading || !currentWorkflow) return;
    if (loading) return
    if (!currentWorkflow) return;
    if (!currentBatchKey) return;
    if (!nodes.length) return;
    setLoading(true);
    // console.log("=== fetching paras ===",batch);
    try {

      let res: any = await Axios.post("/annotations/" + GetParasEndpoint, {
        workflowId: currentWorkflow?.workflowId,
        page: page,
        pageSize: pageSize,
        stage: 2,
        batchNumber: batch
      });

      let result: Array<any> = res.data.data.result.paras;
      let total_paras = res.data.data.result.total_paras;
      let count = res.data.data.result.total_count || 0;


      let class_count: any = {};

      nodes.filter((node: any) => node.is_model_class).forEach((node: any) => {

        class_count[node.node_id] = {};
        class_count[node.node_id].node_name = node.node_name;
        class_count[node.node_id].count = currentBatchInfo[node.node_id]?.validatedParas || 0;


      })

      //Add No Tag
      const noTagName = "No Tag"
      if (currentWorkflow.useNoTag) {
        class_count[noTagName] = {};
        class_count[noTagName].node_name = noTagName;
        class_count[noTagName].count = currentBatchInfo[noTagName]?.validatedParas || 0;
        ;

      }

      let annotated: number = 0;
      let autoValidatedParas = 0;
      //result.sort((para1:any,para2:any)=>para1.paraSeqId >para2.paraSeqId?1:-1);
      result.sort((a: any, b: any) => {

        if (a.predictedIssueId[0] === noTagName) return 1;
        if (b.predictedIssueId[0] === noTagName) return -1;
        return a.predictedIssueId.length > b.predictedIssueId.length ? -1 : 1

      });
      result.forEach((para: any, index: number) => {

        //console.log("=== fetched paras ===",para);
        let validations: any = para?.validations;
        let userValidation: Array<any> | undefined = validations[userId];
        para.index = index;
        if (userValidation && userValidation?.length) {
          if (para.autoValidated) {

            if (notifyAutoValidatedParas(para, batch)) autoValidatedParas++;
          }
          para.isSaved = true;
          if (userValidation[0]?.issueId !== null) {
            para.action = "accepted";
            para.validations = userValidation.map(u => u.issueId);
            delete para.para_info.validations;

            delete para.workflows;
            annotated++;

            para.validations.forEach((v: any) => {
              class_count[v] && class_count[v].count++;
            })


          } else {
            para.action = "rejected";
            para.validations = [];
            delete para.para_info.validations;
            delete para.workflows;


          }


        }


        else {
          para.isSaved = false;
          para.action = null;
          para.validations = para.predictedIssueId;
        }



      });

      // console.log("=== class count ===", class_count);


      //console.log(result);
      if (autoValidatedParas > 0) {
        toast.success(`${autoValidatedParas} 
          ${autoValidatedParas > 1 ? "paragraphs were" : "paragraph was"}
          autovalidated`);
      }
      setCount(count);
      !isReadOnly && setNodeCount(class_count);
      setParas(result);
      setTotalParas(total_paras);
      setActivePara(0);
    } catch (err) {
      toast("Could not fetch paras", { type: toast.TYPE.ERROR });
      console.log("Error while fetching paragraphs", err);

    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {

    if (autoMLLoading) {
      return;
    }


    try {

      const currentStatus = checkCurrentTrainStatus(currentWorkflow);

      if (currentStatus === TrainStatus.NotTrained || currentStatus === TrainStatus.InIterate) {
        return;
      }

      if (currentStatus === TrainStatus.InModelSelection || currentStatus === TrainStatus.InModelTuning) {

        toast.warn("Please complete model training or trigger iterate");
        navigate("/workflow?workflowId=" + currentWorkflow?.workflowId);
      }



    } catch {

    }

  }, [currentWorkflow?.alpdsInfo.percentageStatus]);


  // ------ call AutoML ------
  const triggerAutoML = async () => {
    if (!currentWorkflow || autoMLLoading) return;

    let shouldNavigate: boolean = true;
    let navigatePoint: string = "";
    setAutoMLLoading(true);
    switch (checkCurrentTrainStatus(currentWorkflow)) {



      case TrainStatus.NotTrained:
       // alert("You have not trained the model");
       await callModelComparisonAPI(currentWorkflow.workflowId);
        
        navigatePoint = "model-comparision"
        break;
        case TrainStatus.IsInLLMModelTrainig:
          navigatePoint = "train-llm";
       
          break;
          case TrainStatus.IsInLLMModelTuning:
            navigatePoint = "tune-llm-model"
          
            break;
      case TrainStatus.InModelTuning:
        navigatePoint = "model-tuning"
       
        break;
      case TrainStatus.InModelSelection:
        navigatePoint = "model-comparision"
        
        break;

      case TrainStatus.InTraining:
        navigatePoint = "modeltraining-1"
       // alert("You are in training right now");
       
        break;
      case TrainStatus.Trained:
        navigatePoint = "modeltraining-2"
        
        break;
      case TrainStatus.InIterate:
        if(currentWorkflow.alpdsInfo.selectedModelInfo.type === "llm"){
          await IterateToTrain(currentWorkflow.workflowId);
          navigatePoint = "train-llm"
        }else{
          await callAutoML(currentWorkflow.workflowId);
          navigatePoint = "modeltraining-1";
        }
       
        break;
      default:
      //alert("could not find anything")


    }
    setTimeout(() => {
      setAutoMLLoading(false);
      console.log(navigatePoint);
      shouldNavigate && navigatePoint.length && navigate(`/workflow/${navigatePoint}?workflowId=` + currentWorkflow?.workflowId);
    }, 1000);
    return;








  }
  // ------ Load Next Batch ------
  const LoadNextBatch = async () => {
    if (!currentWorkflow || batchLoading) return;

    const currentStatus = checkCurrentTrainStatus(currentWorkflow);
    if(currentStatus !== TrainStatus.NotTrained
      && currentStatus !== TrainStatus.InIterate
      ){
        toast.warn("Cannot call a new batch after training!");
        return;
      }
    if (checkForCriticalProgress(currentWorkflow)) {
      toast.warn("A critical process is in progress, please wait while other processes are complete");
      return;
    }

    setBatchLoading(true);
    try {
      await Axios.post(`/integration/${stage2Endpoint}`, {
        workflowId: currentWorkflow?.workflowId,
        batchNumber: currentBatch + 1
      });


    } catch {

    } finally {
      setBatchLoading(false)
    }


  }



  // ----- -----
  //AutoValidate paragraphs
  const updateAutoValidate = async (value: boolean) => {
    //if value is false show message that the changes will take place in the next batch
    if (!value) {
      toast("Auto Validation will get disabled from next batch onwards.", { type: toast.TYPE.INFO })
    } else {
      toast("Auto Validation will get enabled from next batch onwards.", { type: toast.TYPE.INFO })
    }

    //initiate delta count for stageParas

    let delta: any = initNodeDelta;

    // if (value) {
    //   // find all paras which are not accepted and have autovalidated true
    //   // 
    //   let paraIds: Array<string> = [];
    //   let paraDatas: Array<any> = [];
    //   console.log("paras",paras,currentBatch);
    //   paras?.forEach((para: any) => {


    //       if (!para.action
    //         && para.autoValidated   
    //         && para.batchId === currentBatch
    //        ) {
    //         console.log("para found")
    //         paraIds.push(para?.para_info?.paraId);
    //         paraDatas.push({
    //           paraId: para?.para_info?.paraId,
    //           nodeId:para.predictedIssueId,
    //           index:para.index
    //         });
    //       }


    //   });



    //   if (paraDatas.length) {
    //     console.log("paras in state",paras);
    //     console.log("paras to autovalidate",paraDatas);
    //     let tempParas = [...paras];
    //     let tempCount = {...nodeCount}
    //     paraDatas.forEach((paraData: any) => {

    //       //update the paras in state

    //      // tempParas
    //      tempParas[paraData.index].isSaved = true;
    //      tempParas[paraData.index].action = "accepted";
    //      tempParas[paraData.index].validations = tempParas[paraData.index].predictedIssueId;

    //      //update delta

    //      paraData.nodeId.forEach((node:string)=>{
    //         delta[node]++;
    //      });

    //     });

    //     Object.keys(tempCount).forEach((nodeId:string)=>{
    //       tempCount[nodeId].count += delta[nodeId]; 
    //     })

    //     console.log("=== node count ===",tempCount);
    //     console.log("=== delta ===",delta);
    //     setParas(tempParas);
    //     setNodeCount(tempCount);
    //     // End of O(n^3) loop



    //     // call bulk validate api to make all those paras validate
    //    await MLCBulkValidateParas(paraDatas,currentWorkflow?.workflowId || "");
    //     paraIds = [];
    //     paraDatas = [];

    //   }

    //   else {
    //     toast("There were no paragraphs eligible for autovalidation in this batch", { type: toast.TYPE.WARNING });
    //   }



    // }


    //finally update workflow for autovalidate change if everything goes right
    try {
      await Axios.post("/workflow/updateAutovalidate", {
        workflowId: currentWorkflow?.workflowId,
        userId: userId,
        autoValidation: value
      });
    }
    catch {
      toast.error("Could Not Enable AutoValidation!!");

    }
    return;

  }




  useEffect(() => {

    if (!isMounted) setIsMounted(true);

  }, [])


  //Move to Next Paragraph on Arrow Down
  useEffect(() => {
    ArrowDown && NextPara();
  }, [ArrowDown]);

  //Move to previous paragraphs on Arrow Up
  useEffect(() => {
    ArrowUp && PrevPara();

  }, [ArrowUp]);



  //Update Class Selector when nodecounts changes
  useEffect(() => {
    // alert("count changed");
    let threshold: number = 0;
    let noTagThreshold: number = 0;
    // console.log("--- before checking can move to automl ---",targetPara,nodeCount);
    const temp = Object.keys(nodeCount).map((key) => {

      //check for minimum target
      if (key !== noTagName) {

        if (nodeCount[key].count >= targetPara
          || (multiUserNodeCount[key]?.count || 0) >= targetPara
        ) {
          // console.log("=== key_pair ===",nodeCount[key],multiUserNodeCount[key]);
          threshold++
        };
      } else {
        if (nodeCount[key].count >= targetPara
          || (multiUserNodeCount[key]?.count || 0) >= targetPara) noTagThreshold++;
      }





      return {
        text: nodeCount[key].node_name,
        value: key,
        count: nodeCount[key].count,
        color: nodeCount[key].count < targetPara ? "#E63946" : null
      }
    })


    if (threshold >= 2) {

      if (currentWorkflow?.useNoTag) {
        //alert("You can move to autom"+threshold+"_"+noTagThreshold);
        setCanMoveToAutoML(noTagThreshold > 0 ? true : false);
      } else {
        // alert("You can move to autom"+threshold+"_"+noTagThreshold);
        setCanMoveToAutoML(true);
      }

    } else {
      setCanMoveToAutoML(false);
    }

    setDropDownOptions(temp);


  }, [nodeCount, multiUserNodeCount]);



  // ----- Calculate Initial Value of NodeDelta when page mounts -----
  useEffect(() => {
    if (nodes.length) {
      const temp: any = {};
      nodes.filter((node: any) => node.is_model_class).forEach((node: any) => {
        temp[node.node_id] = 0;
      });

      //if No Tag is ON
      if (currentWorkflow?.useNoTag) {
        temp[noTagName] = 0;
      }

      setInitNodeDelta(temp);


    }


  }, [nodes]);




  //----------------------------------------------------
  //Fetch paragraphs on component load or refresh
  //----------------------------------------------------
  useEffect(() => {

    if (stage2Progress === 100 && !loading) {
      // console.log("=== fetching paras on progress change ===");
      setBatch(currentBatch);
      fetchParas(currentBatch);
      isCreater && fetchCountDetails(true);

      //   let p1 = fetchParas();
      //  let p2 = fetchCountDetails(true);
    }





  }, [stage2Progress])

  //----------------------------------------------------
  //Fetch paragraphs on page number change
  //----------------------------------------------------
  useEffect(() => {
    if (!loading) {
      setActivePara(0);
      window.scrollTo({ top: 0, behavior: "smooth" });
      // fetchParas();
    }
  }, [page]);

  //----------------------------------------------------
  //Fetch paragraphs on batch number change
  //----------------------------------------------------
  useEffect(() => {
    if (!isMounted) return;
    if (stage2Progress === 100 && !loading) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      // console.log("=== fetching paras on batch change ===");

      fetchParas(batch);
      isCreater && fetchCountDetails(true);
      setActivePara(0);
      setPage(1);
    }
  }, [batch]);

  useEffect(() => {
    if (!loading) {
      setActivePara(0);
      setPage(1);
      window.scrollTo({ top: 0, behavior: "smooth" });
      // fetchParas();
    }
  }, [filter]);


  //----------------------------------------------------
  //notify user that they can mvoe to autoML
  //----------------------------------------------------
  useEffect(() => {
    if (createdBy === userId && canMoveToAutoML) {

      toast("Target achieved, you can now train the model.", { type: toast.TYPE.SUCCESS });
    }



  }, [canMoveToAutoML, createdBy, userId]);


  if (stage2Progress !== 100) {
    return <LoadingScreen
      msg='Please wait while we annotate paragraphs for you'
      compleated={stage2Progress || 0}
    />
  }


  if (stage2Progress !== undefined) {
    //"/workflow/stage2?workflowId="+currentWorkflow?.workflowId)
  }




  if (!loading && (totalParas === 0)) {
    return (
      <div className="uppercase w-full min-h-[25vw] flex justify-center px-[1.5vw] py-[0.6vw] text-[1.3vw] flex-col items-center text-center">
        No Paragraphs found in this batch <br />

      </div>
    )
  }


  return (
    <div>
      <div className={`z-[50]  w-full  bg-[#fafafa] sticky top-[4.9vw]
                      text-[1.2vw] text-[#7a7a7a]  p-2 
                      flex justify-between items-center  border-b flex-1`}>
        <p>Suggested Paragraphs</p>
        <div className=' flex space-x-[1vw] items-center'>

          <div className='w-[50vw]'>

            <div className='flex gap-[1vw] items-center justify-center'>




              <BatchFilter

                onChange={(e: number) => setBatch(e)}
                value={batch}

              />

              <ClassFilterSelector
                defaultOption={{
                  text: "All Classes",
                  count: count,
                  color: "",
                  value: ""
                }}
                options={dropdownOptions}
                onChange={(e: any) => setFilter(e)}
                value={filter}
              />

              <div className='w-[40vw] flex gap-2 items-center'>
                <p className="text-[1.1vw] text-[#777777]">Auto Validation</p>
                <Switch
                  disable={currentBatch < 2 || batch !== currentBatch}
                  disableMessage={currentBatch < 2
                    ? "You can only activate autovalidation during or after batch 2"
                    : "Autovalidation is only available in latest batch and onwards"}
                  state={currentWorkflow?.autoValidate[userId] || false}
                  onClick={updateAutoValidate}
                  width={5}
                  innerWidth={1.6}
                  title=""
                >

                </Switch>
              </div>
            </div>

          </div>



          <div className='flex-1 flex items-center space-x-[0.2vw]'>
            <HoverMenu text={`Annotated by ${firstName} ${lastName}`}>

              <div><AchievedTarget className=' w-[1.8vw] h-[1.8vw]' /></div>
            </HoverMenu>
            <p className='text-[#1BA94C] select-none'>{count}</p>
          </div>
          {isCreater && <div className='flex-1 flex items-center space-x-[0.3vw]'>
            <div>
              <HoverMenu text="Annotated by all users">

                <MultiUserIcon className=' w-[2vw] h-[2vw]' />
              </HoverMenu>


            </div>


            <p className='text-[#1BA94C] select-none'>
              {
                filter === "" ? totalCount
                  : multiUserNodeCount[filter]?.count || 0
              }

            </p>

          </div>}
          <div className='flex-1 flex items-center space-x-[0.3vw]'>
            <div>
              <HoverMenu text="Target for Model Training">

                <TotalTarget className=' w-[3.5vw] h-[2vw]' />

              </HoverMenu>


            </div>


            <p className='text-[#1BA94C] select-none'>{targetPara}</p>

          </div>
          <div>

          </div>

          {isCreater && <div className="flex-1 flex justify-center">
            {pieLoading ? (
              <Loading className=' w-[2.5vw] h-[2.5vw] ' />
            ) : (<HoverMenu text="Refresh all user's data">

              <Refresh className=' w-[2.5vw] h-[2.5vw] cursor-pointer hover:scale-110 duration-200'

                onClick={() => !pieLoading && fetchCountDetails(true)} />
            </HoverMenu>)}
          </div>}


        </div>
      </div>

      {
        loading ? (
          <div className='h-[65vh] w-full flex items-center justify-center'>
            <Loading className='w-[6vw] h-[6vw]' />
          </div>
        ) :
          current_paras?.length > 0 ? (
            <div className='mt-[1vw] '>



              <div className={`flex flex-col space-y-[1vw] `}>
                {
                  current_paras
                    .slice(start, end)
                    .map((para: any, index: number) => {

                      return <MLCStagePara
                        index={index}
                        para={para}
                        activePara={activePara}
                        next={NextPara}
                        prev={PrevPara}
                        setActivePara={setActivePara}
                        key={para._id}

                        accepted={para.action}


                        stage={2}
                        readOnly={CheckParaReadOnly(para)}


                        setNodeCount={setNodeCount}

                        //keyboard toggle
                        keyboardEnabled={keyboardEnabled}
                        setKeyboardEnabled={setKeyboardEnabled}

                        //update state
                        setParas={setParas}
                        setCount={setCount}


                        //fileInfo
                        fileInfo={fileInfo}

                        others={{
                          initNodeDelta,
                          nodeCount,
                          isActiveBatch: !isReadOnly
                        }}
                      />
                    })
                }
              </div>
              <div className='py-[1vw] flex justify-end items-center'>
                <Pagination className='bg-white p-[0.6vw] text-[1vw] self-end'
                  total={current_paras.length}
                  showQuickJumper
                  showSizeChanger={false}
                  current={page}
                  pageSize={pageSize}
                  onChange={(page) => {
                    !loading && setPage(page)

                  }} />
                {isCreater && <button
                  onClick={() => !loading && LoadNextBatch()}
                  className={`uppercase duration-200  w-[13vw]  text-[0.9vw]  h-[3vw] ml-[0.7vw] 
                  text-white text-center rounded-md border-2 hover:scale-105 
                  disabled:opacity-75 disabled:cursor-not-allowed
                  border-transparent  bg-primaryBlue hover:drop-shadow-md   `}
                >{batchLoading ? <LoadingWhite className='w-[2vw] h-[2vw]' /> : "Load More"}
                </button>}


              </div>

            </div>
          ) : <div className='flex h-[28vw] text-gray-400 text-[1.5vw] items-center justify-center'>
            No more paragraphs available for this class

          </div>
      }

      <div className='flex justify-end
      w-full  border-t py-[1vw]
      '>




        {isCreater ? <div className='flex items-center'>
          <button
            disabled={!canMoveToAutoML}
            title={canMoveToAutoML ? "" : "Complete the target to enable model training"}
            onClick={() => !autoMLLoading && canMoveToAutoML && triggerAutoML()}
            className={`uppercase duration-200  w-[13vw]
                text-white text-[0.9vw] 
                 h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
                 disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
                border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md   `}
          >
            {autoMLLoading ? <LoadingWhite className='w-[2vw] h-[2vw]' />
              : "Train Model"}
          </button>
          <button
            onClick={() => navigate(`/workflow/stage2-summary?workflowId=${currentWorkflow?.workflowId}`)}
            className={`uppercase duration-200  w-[13vw]
                text-white text-[0.9vw] 
                 h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
                 disabled:opacity-75 disabled:cursor-not-allowed
                border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md   `}
          >Validation Summary
          </button>

        </div>
          : <div></div>}



      </div>

    </div>
  )
}

export default Index