import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import useKeyboard from '../../../../hooks/useKeyboard';
import MLCParaTags from '../../_shared/MLCParaTags';





//Icons

import MultiClassSelector from '../../_shared/MultiLabelClassSelector';
//import MultiClassValidator from './MultiClassValidator';


 interface IValidationControls{
    handleTagSelection:(value:any[],delta:any)=>void,

    accepted:string | null,
    active?:boolean,
    node_name:String,
   
    validations:any[]
    dropdownOptions:any[],
    loading:boolean,
    readOnly:boolean,
    keyboardEnabled:boolean,
    setKeyboardEnabled:any,

    others:any

}


function ValidationControls({
    handleTagSelection,
    
    accepted,
    active,
    node_name,
    
    validations,
    dropdownOptions,
    loading,
    readOnly,
    keyboardEnabled,
    setKeyboardEnabled,

    others
    
}:IValidationControls) {

    //local states
    const [isSaved,setIsSaved] = useState<boolean>(false);
    const [nodeChanges,setNodeChanges] = useState<any>();
    const {tempValidations,setTempValidations,initNodeDelta,delta,setDelta} = others;
    const Enter = useKeyboard({ keyCode: "Enter" })
   // console.log(delta,initNodeDelta);


    useEffect(() => {
      const accepteParaAndMove = async () => {
        
        if (!keyboardEnabled) return;
        if(!Enter) return;
        if(!active) return;
        if(isSaved) return;
        if(readOnly) return;
       // alert("Saving changes...");
        setIsSaved(true);
        setTimeout(()=>{

          handleTagSelection(tempValidations,delta);
        },400);
  
      }
      accepteParaAndMove();
  
  
  
    }, [Enter]);

    const handleClassChange = (newState:any[]) =>{

      // update temperory state
      // set isSaved to false


      //check if state is changed
      if(newState)

      setTempValidations(newState);
      setIsSaved(false);


    }

    useEffect(()=>{
        if(accepted)
        {
          setIsSaved(true);
        }
    },[])

    



  return (
    <div className="w-full h-full  flex flex-col items-start justify-start py-[1vw] px-[1vw] space-y-[1vw]">

    
      <div className={` ${active? "":"h-[5vw] overflow-hidden"}  `}>
        <MLCParaTags
        active={active || false}
        handleRemoveTag ={(node_id:string)=>{
         // alert(node_id);
          let newState = tempValidations.filter((v:string)=>v!==node_id);
          
          //update delta
          const temp = {...others.delta}
          temp[node_id]--;
          others.setDelta(temp);


          setTempValidations(newState);
          setIsSaved(false);

        }}
        data={tempValidations}

        //tagData = {para}
        />
      </div>
      <Divider></Divider>
      {/* <div className=" border  h-full my-[1vw] mx-[1vw] border-dashed border-l-1"></div> */}
      <div className='w-full flex gap-[1vw] justify-center'>
        <MultiClassSelector 
        handleTagSelection={handleTagSelection}
        dropdownOptions={dropdownOptions}
        accepted={accepted} 
        node_name={node_name || ""} 
        loading={loading}

        disabled={false}
        //disabled={readOnly}
        validations={validations}
        //selectionInfo ={selectionInfo}  
        keyboardEnabled={keyboardEnabled}
        setKeyboardEnabled={setKeyboardEnabled}
        isAnnotated={accepted?true:false}

        userValidations={tempValidations}
        setUserValidation ={handleClassChange}

        others={others}
      
        />

        <div className={` w-full  text-center flex items-center justify-center  h-[2.5vw] 
                         rounded-[0.2vw] border-[#11256D] border
                         cursor-pointer text-[0.9vw] selector-none
                         ${!isSaved?"hover:bg-[#11256D] hover:text-white hover:text-[0.95vw] text-[#11256D]"
                         :"bg-[#11256D] text-white  "}
                         duration-500 `}
                         
              onClick={()=>{
                if(isSaved) return;
                setIsSaved(true);
                handleTagSelection(tempValidations,others.delta)
              }} 
                        >{isSaved?"Saved":"Save"}</div>
      
      
      </div>

      </div>
  )
}

export default ValidationControls