import React,{useContext, useEffect, useRef, useState} from "react";
import { BrowserRouter as Router, Route, Routes, Navigate,useNavigate} from "react-router-dom";
import ResetPassword from "../pages/auth/resetPassword";
import SetNewPassword from "../pages/auth/setNewPass";
import SignIn from "../pages/auth/signin";
import SignUp from "../pages/auth/signup";
import SignUpSuccess from "../pages/auth/signupSuccess";
import ProjectDashboard from "../pages/dashboards/projectDashboad";
import ProjectDashboardV2 from "../pages/dashboards/ProjectDashboardV2";
import NotificationDashboard from "../pages/dashboards/NotificationDashboard";
import WorkflowDashboard from "../pages/dashboards/workflowDashboard";
import HelpAndGuideline from "../pages/help-and-guideline";
import Masterlist from "../pages/Masterlist";
import Page404 from "../pages/Page404";
import UploadPage from "../pages/UploadPage";
import InvalidWorkflow from "../pages/workflow/InvalidWorkflow";
import useAuthStore from "../store/authStore";
import WorkflowRoutingComp from "./Workflow";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Pages
import Stage1Page from '../pages/Stage1';
import Stage2Page from '../pages/Stage2Page';
import Stage1Summary from '../pages/summary/Stage1SummaryPage';
import Stage2Summary from '../pages/summary/Stage2SummaryPage';
import ValidationSummary from '../pages/summary/ValidationSummaryPage';
import CreateProjectPage from '../pages/CreateProjectPage';
import ModelTraining1 from '../pages/workflow/ModelTraining1';
import ModelTraining2 from '../pages/workflow/ModelTraining2';
import Deployment from '../pages/workflow/Deployment';
import AM from "../pages/AdvancedMasterlist";
import ListViewPage from '../pages/ListViewPage';

//import BillingTest from "../pages/BillingTest";
//import SuccessPage from "../components/BillingTest/SuccessPage";

import BillingComp from "./Billing/BillingComp";
import BillingSuccess from './Billing/BillingSuccess';
import BillingFailure from './Billing/BillingFailure';
import NoActivePlan from '../pages/admin/NoActivePlan';
import UsageLimitExceeded from '../pages/admin/UsageLimitExceeded';


import Admin from "../pages/admin";

import {socket, SocketContext} from '../contexts/socket';
import useNotifications from "../store/useNotifications";
import Axios from '../utils/Axios';

import useWorkflowStore from '../store/useWorkflowStore';
import { seedInitialSuggestions ,copyWords, addWordToSelected, removeWordFromSelected} from "../store/useAdvancedMasterlist";
import useCompanyUsersStore from "../store/useCompanyUsersStore";
import usePermissionStore from "../store/usePermissionStore";
import {ReactComponent as LoadingCircle} from '../assets/loading_circle.svg';
import ModelComparisonPage from "../pages/ModelComparisonPage";
import ModelTuning from "../pages/ModelTuning";
import LlmModelsPage from "../pages/llm/LlmModels";
import LlmApiDocumentationPage from "../pages/llm/LlmAPIDocumentationPage";
import LlmUploadModelComp from "./llm-components/LlmUploadModelComp";
import LlmUploadModelPage from "../pages/llm/LlmUploadModelPage";
import LLMTuneModelPage from "../pages/llm/LLMTuneModelPage";
import LLMDeploy from "./llm-customization/rlhf-fine-tuning/training/LLMDeploy";
import LLMFineTuningPage from "../pages/llm-customization/LLMFineTuningPage";

let RoutingComp: React.FC = () => {
    let authState = useAuthStore();
    const { setCompanyUsers } = useCompanyUsersStore();
    const { permissions, setPermissions } = usePermissionStore();
    let socket = useContext(SocketContext);
    const errorEvent  ="notification";
    const {notifications,addNotifications,setNotifications,setIsSeen} = useNotifications();
    const isConnected = useWorkflowStore(state=>state.isConnected);
    const setIsConnected = useWorkflowStore(state=>state.setIsConnected);
    const disconnectId = useRef<any>(null);
    const connectId = useRef<any>(null);
    const [loading,setLoading] = useState<boolean>(true);
    

    useEffect(()=>{
        const verifyUser = async()=>{
            try{
                let result:any = await Axios.get("/user/me");

                const user = result.data.data;
                // console.log(user);
                authState.setIsAuthenticated(true);
                authState.setUserDetails({
                    userId:user.userId,
                    firstName:user.firstName,
                    lastName:user.lastName,
                    company:user.company,
                    email:user.email,
                    role:user.role,
                    isBillingAccount:user.isBillingAccount,
                    associatedBillingAccounts:user.associatedBillingAccounts,
                });
                authState.setToken(user.activeToken);
                localStorage.setItem("token",user.activeToken);
                
                let accountInfo = await Axios.post("/billing/getAccountDetails", {
                    userId: user.userId,
                    isBillingAccount: user.isBillingAccount,
                    associatedBillingAccounts: user.associatedBillingAccounts
                });
                //console.log("account", accountInfo)
                if (accountInfo.data.result) {

                    if (accountInfo.data.data.activePlan === "The company does not have an active plan") {
                        // window.location.replace("/");
                        authState.setIsProcessComplete(true);
                    } else {
                        
                        authState.setBillingDetails(accountInfo.data.data?.billingDetails);
                        authState.setActivePlan(accountInfo.data.data?.activePlan);
                        authState.setUsage(accountInfo.data.data?.usage);
                        authState.setIsProcessComplete(true);

                        let usersData = await Axios.get(`/user/companyuser`);
                        if (usersData && usersData.data.result) {
                            // console.log("setting");
                            // console.log(usersData.data.data);
                            setCompanyUsers(usersData.data.data);
                        } else {
                            console.log("Could not fetch company users");
                        }
                    }
                    
                } else {
                    authState.setIsProcessComplete(true);
                    console.log("Could not fetch account details");
                }

            }catch(err){
                authState.setIsProcessComplete(true);
                console.log("could not load user details",err);
            }
            finally{
                
                setLoading(false);
            }

        }
        verifyUser();

    },[]);

    
    const fetchNotifications = async()=>{

        
        let res = await Axios.post("/notification/getall",{
            limit:10,
            page:1
        });
        let notifications = res?.data?.data && res?.data?.data.map((n:any)=>{
            n.action = true;
            return n;
        })
       
        setNotifications(notifications);
        
      


    }

        //Change Connected State Whenever Socket Connectes Or Disconnects
        useEffect(()=>{
            if(loading) return;
           
            socket?.on("connect",()=>{
                disconnectId.current && toast.dismiss(disconnectId.current);
                
                setIsConnected(true); 
                socket?.emit("adduser",authState?.userDetails?.userId);
                connectId.current && toast.dismiss(connectId.current); 
                //connectId.current= toast("Status: Connected",{type:toast.TYPE.SUCCESS,position:"top-center",});
            });
    
            socket?.on("disconnect",()=>{
                setIsConnected(false);
                disconnectId.current=  toast("Status: Disconnected",{type:toast.TYPE.ERROR,position:"top-center",autoClose:false});
                
            });
    
    
            //Clear All Subscriptions
            return ()=>{
                socket?.off("connect");
                socket?.off("disconnect");
            }
    
        },[loading]);
    
    useEffect(()=>{
        if(loading) return;
       if(authState.userDetails?.userId)
       {
        
       // console.log("Connected To Server From Routing Component",authState.userDetails?.userId);
         fetchNotifications();
        
             
 
             socket?.emit("adduser",authState?.userDetails?.userId);
 
 
             //console.log("Subscribing to Error Event");
         
       
 
 
         //Subscribing to Error Event
         socket?.on(errorEvent,(obj:any)=>{
                 //console.log("Error Received From Server",obj);
                 addNotifications({...obj,action:true});
                 setIsSeen(false);
     
             });
       }

      
            return ()=>{
                socket?.off(errorEvent);
            }

               
    },[loading, authState.userDetails?.userId])

    
    useEffect(() => {
        if (authState.userDetails && authState.activePlan && authState.usage) {
            let temp = permissions;

            // Users permission
            if (authState.userDetails.isBillingAccount && 
                authState.usage.details.noOfUsers < authState.activePlan.details.noOfUsers) {
                permissions.canCreateUsers = true;
            } else {
                permissions.canCreateUsers = false;
            }

            // Projects permission
            if (authState.usage.details.noOfProjects < authState.activePlan.details.noOfProjects) {
                permissions.canCreateProjects = true;
            } else {
                permissions.canCreateProjects = false;
            }

            // Workflows permission
            if (authState.usage.details.noOfWorkflows < authState.activePlan.details.noOfWorkflows) {
                permissions.canCreateWorkflows = true;
            } else {
                permissions.canCreateWorkflows = false;
            }

            // Usage permission
            if (authState.usage.details.secondaryMemory < authState.activePlan.details.secondaryMemory) {
                permissions.canUpload = true;
            } else {
                permissions.canUpload = false;
            }

            setPermissions(temp);
        }
    }, [authState]);


    let Loader = () => {
        return (
            <div className="h-screen w-screen text-center flex items-center justify-center  ">
                <div className=" fixed bg-gray-300 opacity-10  w-full text-center items-center justify-center"></div>
                <div className="h-full w-full flex justify-center items-center">
                    <h1 className="fixed mt-[1vw] text-[1.5vw] text-gray-500 mb-[1.5vw]">Authorizing User...</h1>
                    {/* <img className="w-[20vw] animate animate-spin" src="/images/loading_circle.svg" alt="some loading screen" /> */}
                    <LoadingCircle className="w-[20vw] h-[20vw] animate animate-spin" />
                </div>
            </div>
        )
    }
    //Create a socket event listner here only
    if(loading) 
        return <Loader />;

    

    return (
        
        <Router basename="/">

            <ToastContainer
            position="bottom-right"
            />
            <Routes>
                
                {/* <Route index element={<Navigate replace to={authState.isAuthenticated ? "/project_dashboard" : "/signin"} />} /> */}
                <Route index element={
                    (authState.isProcessComplete ?
                        (authState.isAuthenticated ? 
                            (authState.activePlan !== undefined ?
                                <ProjectDashboardV2 /> : 
                                (authState.userDetails?.isBillingAccount ? 
                                    <Navigate replace to="/billing" /> : 
                                    <NoActivePlan />)) 
                            : 
                            <Navigate replace to="/signin" />) :
                        <Loader />
                        ) 
                    } 
                />
                {/* <Route index element={
                    authState.isAuthenticated ? 
                        (authState.isProcessComplete && authState.activePlan !== undefined && permissions.canUpload ?
                            <ProjectDashboardV2 /> : 
                            (authState.activePlan === undefined ? 
                                (authState.userDetails?.isBillingAccount ? 
                                    <Navigate replace to="/billing" /> : 
                                    <NoActivePlan />) :
                                <UsageLimitExceeded />
                            )) : 
                        <Navigate replace to="/signin" />
                    } 
                /> */}

                <Route path={"project_dashboard"} element={authState.isAuthenticated && authState.isProcessComplete && authState.activePlan !== undefined?<ProjectDashboardV2 />:<Navigate replace to="/signin" />} />

    
                <Route path={"notification_dashboard"} element={authState.isAuthenticated && authState.isProcessComplete && authState.activePlan !== undefined?<NotificationDashboard />:<Navigate replace to="/signin" />} />
                <Route path={"project/create"} element={authState.isAuthenticated && authState.isProcessComplete && authState.activePlan !== undefined?<CreateProjectPage />:<Navigate replace to="/signin" />} />

                {/* <Route path={"signin"} element={<SignIn />} />
                <Route path={"signup"} element={<SignUp />} /> */}
                <Route path={"signin"} element={!authState.isAuthenticated?<SignIn />:<Navigate replace to="/" />} />
                <Route path={"signup"} element={!authState.isAuthenticated?<SignUp />:<Navigate replace to="/" />} />
                <Route path={"reset_password"} element={!authState.isAuthenticated?<ResetPassword />:<Navigate replace to="/" />} />
                <Route path={"create_new_password"} element={!authState.isAuthenticated?<SetNewPassword />:<Navigate replace to="/" />} />
                <Route path={"signup_success"} element={!authState.isAuthenticated?<SignUpSuccess />:<Navigate replace to="/" />} />
                
                <Route path={"help_and_guideline"} element={authState.isAuthenticated?<HelpAndGuideline />:<Navigate replace to="/signin" />} />


                <Route path={"billing"} element={authState.isAuthenticated && authState.userDetails?.isBillingAccount?<BillingComp />:<Navigate replace to="/signin" />} />
                <Route path={"billing/success"} element={authState.isAuthenticated && authState.userDetails?.isBillingAccount?<BillingSuccess />:<BillingSuccess />} />
                <Route path={"billing/failure"} element={authState.isAuthenticated && authState.userDetails?.isBillingAccount?<BillingFailure />:<Navigate replace to="/signin" />} />

                <Route path={"usage_limit_exceeded"} element={authState.isAuthenticated?<UsageLimitExceeded />:<Navigate replace to="/signin" />} /> 

                <Route path={"workflow"} element={authState.isAuthenticated && authState.isProcessComplete && authState.activePlan !== undefined?<WorkflowRoutingComp />:<Navigate replace to="/signin" />}>
                    <Route index  element={<WorkflowDashboard />} />
                    <Route path={"upload"} element={<UploadPage />} />
                    <Route path={"masterlist"} element={<Masterlist />} />
                    <Route path={"stage1"} element={<Stage1Page />} />
                    <Route path={"stage2"} element={<Stage2Page />} />
                    <Route path ={"validation"} element={<ListViewPage />} />

                    <Route path={"validation-summary"} element={<ValidationSummary />} />
                    <Route path={"stage1-summary"} element={<Stage1Summary />} />
                    <Route path={"stage2-summary"} element={<Stage2Summary />} />
                    <Route path={"invalid_workflow"} element={<InvalidWorkflow />} />
                    <Route path={"model-comparision"} element={<ModelComparisonPage />} />
                    <Route path={"explore-llm-models"} element={<LlmModelsPage />} />
                    
                    {/* Instruct Based Workflows Training Screens */}
                    <Route path={"tune-llm-model"} element={<LLMTuneModelPage />} />
                    <Route path={"llm-model-fine-tune"} element={<LLMFineTuningPage />} />

                    <Route path ={"train-llm"} element={<LlmApiDocumentationPage />} />
                    
                    <Route path ={"integrate-model-artifact"} element={<LlmUploadModelPage />} />
                    <Route path={"model-tuning"} element={<ModelTuning />} />
                    <Route path={"modeltraining-1"} element={<ModelTraining1 />} />
                    <Route path={"modeltraining-2"} element={<ModelTraining2/>} />
                    <Route path={"modeltraining-2/dep"} element={<Deployment/>} />
                    <Route path={"advancedmasterlist"} element={<AM />} />

                </Route>
                <Route path={"admin"} element={authState.isAuthenticated && authState.isProcessComplete && authState.activePlan !== undefined?<Admin/>:<Navigate replace to="/signin" />}/>

                <Route path="*" element={<Page404 />} />
       
            </Routes>
        </Router>
    );
};

export default RoutingComp;
