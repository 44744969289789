import React from "react";
import useWorkflowStore from "../../../../store/useWorkflowStore";
import { useLocation } from "react-router-dom";
import AppLayout from "../../../layouts/AppLayout";
import DeployComp from '../../shared/llm-deployment';


const LLMDeploy = ()=>{
    const workflow = useWorkflowStore(state => state.currentWorkflow);
    const project = useWorkflowStore(state=>state.currentProject);
    const location:any = useLocation();
    console.log("=== Location ===",location?.state?.dep);
    let dep:string = location?.state?.dep as string ||  "deploy2";
    return (
                <DeployComp dep={dep} />
    );
}

export default LLMDeploy;