import React from "react";
import AppLayout from "../../components/layouts/AppLayout";
import PageLayout from '../../components/layouts/PageLayout';
import useWorkflowStore from '../../store/useWorkflowStore';
import ModelTraining2Comp from '../../components/workflow/model-training-2/model_training2';
import template from "../../template";

interface MasterlistProps { }
let Masterlist: React.FC<MasterlistProps> = () => {
    const workflow = useWorkflowStore(state => state.currentWorkflow);
    const project = useWorkflowStore(state => state.currentProject);

    const projectType = project?.projectType;
    const workflowType = project?.workflowType;
    const model_training2:any  =projectType && workflowType
                                                            && template[projectType][workflowType]
                                                            ?.training
                                                            .modelTraining2;
                                                               


    return (
        <AppLayout>
            <PageLayout pageSubHeading="model training" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/model-training_white.svg">
                {/* <ModelTraining2Comp /> */}
                {
                   model_training2 &&  React.createElement(model_training2?model_training2.component:<></>)
                }
            </PageLayout>
        </AppLayout>
    );
};


export default Masterlist;
