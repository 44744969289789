
import { Divider,Popover } from "antd";
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useRecoilState, useRecoilValue } from "recoil";

import RejectedPara from "../data-validation/RejectedPara";
import SeedPara from "../data-validation/SeedPara";
import StageNode from "../data-validation/StageNodeV2";
import StagePara from "../data-validation/StageParaV2";
import LoadingScreen from "../loading-screen";
import { nodesAtom } from "../masterlist/atoms";

import { GlobalState } from '../../store/reducers';
import { ParaDataType } from "../../store/reducers/stage1Reducer";
import useAuthStore from "../../store/authStore";
import { useNavigate } from 'react-router-dom';
import {
  // ADD_SEED_PARA,
  INIT_REJECT_PARA,
  REJECT_PARA,
  STAGE1_ADD_PARAS,
  STAGE1_NODES_REQUEST,
  STAGE1_PRE_PARAS,
  STAGE1_PROGRESS_UPDATE,
  UPDATE_NODE_FEEDBACK,
} from "../../store/types/stage1-types";

//will have to implement Api calls separately


import {ReactComponent as SeedIcon} from '../../assets/seed_icon.svg';


import style from "./stage1.module.css";
import { toast } from "react-toastify";
import { getWithoutNan } from "../../utils/helper";
import useWorkflowStore from "../../store/useWorkflowStore";
import { getNodeInfo } from "../masterlist/utils/helper";

// ----- Will add google analytics later -----
//import ReactGA from "react-ga";

// ----- Will add ner support later -----
//import useNerStore from "../../../../../store/zustand/useNerStore";
//import NerSidebar from "../../../../../components/ner/NerSidebar";

import { Pagination } from 'antd';


import Axios from '../../utils/Axios';
import useKeyboard from "../../hooks/useKeyboard";
import template from "../../template";

let Stage1: React.FC = (): any => {
  //Info from global store
  let currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
  let currentProject = useWorkflowStore(state => state.currentProject);
  let { userDetails } = useAuthStore();
  //let nerStore = useNerStore();

  //get links from template
  const projectType:string = currentProject?.projectType || "";
  const workflowType:string = currentProject?.workflowType || "";

  const stage2Endpoint = template[projectType][workflowType]?.stage1.endpoints.initStage2;



  let dispatch = useDispatch();
  let stage1 = useSelector((state: GlobalState) => state.stage1);
  let [batch, setBatch] = useState<number>(1);
  let [active_node, setActiveNode] = useState<any>();
  let [loading, setLoading] = useState(true);
  let [nodeLoading, setNodeLoading] = useState(true);
  let [seedPara, setSeedPara] = useState<boolean>(false);
  let [integrated, setIntegrated] = useState(true);
  let [stage1percentage, setStage1Percentage] = useState("0%");
  let [state2started, setStage2Started] = useState(false);
  let [stage2percentage, setStage2percentage] = useState("0%");
  //let { stages, setStages } = useContext(StageContext);
  let [current_paras, setCurrent_paras] = useState<Array<ParaDataType> | null>(
    null
  );
  let [disable, setDisable] = useState<boolean>(true);
  let comp: any = [];
  let nodesComplete: any = [];
  const stageParasRef = React.useRef<HTMLDivElement>(null);
  const [activePara,setActivePara] = useState<number>(2);


  const navigate = useNavigate();

  //keyboard navigation
  const [keyboardEnabled,setKeyboardEnabled] = useState<boolean>(true);
  const ArrowDown = useKeyboard({keyCode:"ArrowDown"});
  const ArrowUp = useKeyboard({keyCode:"ArrowUp"});
  const PrevPara = ()=>{
    if(!keyboardEnabled) return;
    if(ArrowUp && activePara>0){
      setActivePara(prev=>prev-1);
    }

   }
 
   const NextPara = ()=>{
    if(!keyboardEnabled) return;
    if(current_paras && activePara<current_paras.length-1){
      
      setActivePara(prev=>prev+1);
    }
   }


 
   useEffect(() => {
    ArrowDown && NextPara();
  }, [ArrowDown]);
  useEffect(() => {
    ArrowUp && PrevPara();
   
  }, [ArrowUp]);

  useEffect(() => {
    if (
      active_node &&
      active_node?.goal <= active_node?.feedback &&
      active_node.goal !== 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [active_node, stage1.nodes]);


  //---------------------------------------------
  //Function To Sort Paras By Their Para sequence
  //---------------------------------------------
  let compare = (a: any, b: any) => {
    let paraSecId1 = a.para_info.paraSeqId;
    let paraSecId2 = b.para_info.paraSeqId;
    if (paraSecId1 < paraSecId2) return -1;
    else if (paraSecId1 > paraSecId2) return 1;
    return 0;
  };



  let nodes = useRecoilValue(nodesAtom);

  //-------------------------------------------------------------
  //Function To Calc Nearest class and dipatch reject para action
  //-------------------------------------------------------------
  let fetchRejectedParaTag = (
    para: any,
    curNodeName: string,
    issueId: any,
    node_id: string
  ) => {
    
    //console.log("=== Fetch Rejected para was called ===",issueId)
    let nodeId = para.workflows[currentWorkflow?.workflowId || ""].alpdsInfo?.rankMetric.stage1.value[1][0];

    let node_info = nodes.filter((nodeInfo) => nodeInfo.node_id === nodeId)[0];
    
    //  //console.log("=== Fetch Rejected para was called ===",node_info)
    if (node_info) {
      let node_name = node_info.node_name;
      let parentInfo = nodes.filter(
        (nodeInfo) => nodeInfo.node_id === node_info.parent_id
      )[0];

      let parent_name = "No Parent Name";

      if (parentInfo) {
        parent_name = parentInfo.node_name;
      }
      let selected = null;
      if (!issueId) {
        selected = false;
      } else{
        selected = true;

        for (let i = 0; i < nodesComplete.length; i++) {
          if (nodesComplete[i].node_id === issueId) {
            //
            //console.log("=== Updating count for node ===",nodesComplete[i].node_id);
            nodesComplete[i].completed_percentage += 1;
          }
        }
      }
      let nextPredicted = {
        node_name,
        parent_name,
        node_id: nodeId,
        issueId,
        selected,
      };
      dispatch({
        type: REJECT_PARA,
        payload: {
          paraId:para.paraId,
          node_id:node_id,
          selected:selected,
          issueId:issueId
        },
      });
  
    }
  };



  let [fetching, setFetching] = useState(true);

  useEffect(() => {
    // nerStore.setStage(1);

  }, [fetching]);



  //-------------------------------------------------------------
  //Function To Fetch All Paras And Setup state for annotation
  //-------------------------------------------------------------
  let fetchParas = async (node_id: string, node_name: string) => {
    //console.log("=== fetch paras node_id ===",node_id,node_name);
    let completed: number = 0;

    try {

      let res: any = await Axios.post("/annotations/node-paras", {
        workflowId: currentWorkflow?.workflowId,
        predictedIssueId: node_id,
        stage: 1
      });


     
      let result = res.data.data.result.paras;
      let total = 0,finished = 0;
      
      result.sort(compare);
     
      result.forEach((para: any) => {

     
        if (para.validations && para.validations[userDetails?.userId || ""] && para.validations[userDetails?.userId || ""][0].Action === "accepted") {
         
          para.accepted = "accepted";
         
          completed += 1;
        }

        else if (para.validations && para.validations[userDetails?.userId || ""] 
                 && para.validations[userDetails?.userId || ""][0].Action === "rejected") {
          para.accepted = "rejected";
        }

        else {
          para.accepted = null;
        }


        if (para.accepted  && para.batchId < 3) {
          total += 1;
          finished += 1;
        } else if (para.batchId < 3) {
          total += 1;
        }
      });

      let rejected_p = result.filter(
        (para: any) => para.accepted === "rejected"
      );

      comp.push({
        node_id,
        completed,
      });
      dispatch({
        type: STAGE1_ADD_PARAS,
        payload: {
          node_id,
          data: result,
        },
      });
      let c = 0;
  
      for (let i = 0; i < stage1.nodes.length; i++) {
        if (stage1.nodes[i].node_id === node_id) {
          c = stage1.nodes[i].completed_percentage;
          break;
        }
      }

      dispatch({
        type: STAGE1_PROGRESS_UPDATE,
        payload: {
          node_id,
          data: completed,
        },
      });
      for (let i = 0; i < rejected_p.length; i++) {
        fetchRejectedParaTag(
          rejected_p[i].para_info,
          node_name,
          rejected_p[i]?.validations[`${userDetails?.userId}`][0].issueId,
          node_id
        );
      }
      //     //

      dispatch({
        type: UPDATE_NODE_FEEDBACK,
        payload: {
          node_id: node_id,
          goal: total,
          feedback: finished,
        },
      });



    } catch (error) {
      toast("Could Get Paras For Nodes", { type: toast.TYPE.ERROR });

    }
    finally {
      // setFetching(false);
    }




  };


  //----------------------------
  //Sroll To Top Function
  //----------------------------
  let backToTop = () => {
   // let ele = document.getElementById("stage_1_paras");
    stageParasRef.current?.scrollTo({top:0,behavior:"smooth"});
    window.scrollTo({top:0,behavior:"smooth"});
    //ele?.scrollTo({ top: 0 });
  };


  //-----------------------------------------------------------
  //Sroll To Top Whenever Active Class Changes or Batch Changes
  //-----------------------------------------------------------  
  useEffect(() => {
    backToTop();
    setActivePara(0);
  }, [active_node, batch]);


  let updateComplete = () => {
    let completed = 0;

    for (let i = 0; i < nodesComplete.length; i++) {
      let id = nodesComplete[i].node_id;
      completed = nodesComplete[i].completed_percentage;

      for (let j = 0; j < comp.length; j++) {
        if (comp[j].node_id === id) {
          let c = comp[j].completed;

          comp[j].completed = completed + c;
          break;
        }
      }
    }
    for (let i = 0; i < comp.length; i++) {
      dispatch({
        type: STAGE1_PROGRESS_UPDATE,
        payload: {
          node_id: comp[i].node_id,
          data: comp[i].completed,
        },
      });
    }
    
  };

  let fetchStage = async () => {
    dispatch({
      type: INIT_REJECT_PARA,
      payload: {},
    });
    try {
   

      if (!currentWorkflow?.workflowId || !integrated) return;
      console.log("=== Stage 1 Fetching data ===");
      
        let nodes_res: Array<any> = [...nodes];
      nodes_res= nodes_res
      .filter(node=>node.is_model_class === true)
      .sort((a:any,b:any)=>a.node_name.toLowerCase().trim()>b.node_name.toLowerCase().trim()?1:-1)
        //------------------------------------------------------------------------------------
        //If Workflow.useNoTag is true then add a class{node_id:"No Tag",node_name:""No Tag }
        //------------------------------------------------------------------------------------
        if(currentWorkflow.useNoTag)
        {

          const noTagObject = {
            _id:"No Tag",
              child_node_ids:[],
              createdOn:Date.now(),
              node_name:"No Tag",
              node_id:"No Tag",
              parent_id:null,
              is_leaf_node:true,
              is_model_class:true,

           
          }
          nodes_res
          .push(noTagObject);
        }
        
       
     
        let final_nodes:Array<any> = nodes_res;
        

      
        final_nodes = final_nodes
        
          .map((fn: any) => {
          let obj ={...fn};
          Object.assign(obj,{
           completed_percentage : 0,
            goal : 0,
            feedback : 0,

          })
       
          
          return obj;
        });
        
        console.log("=== final nodes ===",final_nodes); 
        dispatch({
          type: STAGE1_NODES_REQUEST,
          payload: final_nodes,
        });
        setActiveNode(final_nodes[0]);
        // setCurParas();
        let node_populate = [];

        try {
          for (let i = 0; i < final_nodes.length; i++) {
            nodesComplete.push({
              node_id: final_nodes[i].node_id,
              completed_percentage: 0,
            });
            node_populate.push({
              node_id: final_nodes[i].node_id,
              data: [],
            });
          }
        } catch (err) {
          //console.error(err);
        }

        dispatch({
          type: STAGE1_PRE_PARAS,
          payload: node_populate,
        });
       
        setFetching(true);
        await Promise.all(final_nodes.map(async (t) => {
          return await fetchParas(
            t.node_id,
            t.node_name
          );

        }));
        setFetching(false);
      
        setTimeout(() => {
          updateComplete();
        }, 500 * nodesComplete.length);
      // } else {
      //   toast("Internal Server Error : Fetching the node", {
      //     type: "error",
      //   });
       
      // }
    } catch (err) {
      console.log(err);
      
    }
    setNodeLoading(false);
  };

  let [isAllowed, setIsAllowed] = useState<boolean>(false);

  let checkConstrainsts = (): boolean => {
    let allowed = true;

    stage1.nodes?.forEach((node) => {
      // console.log("=== checking node ===",node.completed_percentage)
      if (node && node.completed_percentage < 15 && node.feedback < node.goal) {
        // console.log("=== Inside If Condition ===")
        allowed = false;
      }
    });

    return allowed;
  };
  useEffect(() => {
    let check = checkConstrainsts();
    setIsAllowed(check);
    
  }, [stage1.nodes]);

  useEffect(() => {
    //fetching all the node issues
    if (integrated === false
      || currentWorkflow?.stages.find(s => s.name === "validation-1" )?.active===false) {
      return
    }
    if ( nodes) {
      fetchStage();
      
    }
  }, [integrated, nodes]);



  //----------------------------------------------------------------------------
  // Function To Set Current Paragraphs Of Active Class And Active Batch In View
  //----------------------------------------------------------------------------
  let setCurParas = () => {
    let temp = [];
    if (stage1 && stage1.paras) {
      //@ts-ignore
      for (let i = 0; i < stage1.paras?.length; i++) {
        //@ts-ignore
        if (stage1.paras[i].node_id === active_node?.node_id) {
          //@ts-ignore
          temp = stage1.paras[i].data;
          break;
        }
      }
      temp = temp.filter((para: any) => para.batchId === batch);
      //
      setCurrent_paras(temp);
    }
  };



  //-----------------------------------------------------
  // Check Stage1Progress And Show/Hide Loading Screen 
  //-----------------------------------------------------
  useEffect(() => {
    //check if stage1 is in progress
    let val = currentWorkflow?.alpdsInfo?.percentageStatus
      ?.stage1Progress as number;

    if (val) {
      setStage1Percentage(`${val}%`);

      

      if (val === 100 || val === 0) {
        setIntegrated(true);
      } else {
        setIntegrated(false);
      }
    } else {
      setIntegrated(false);
    }
  }, [currentWorkflow?.alpdsInfo?.percentageStatus?.stage1Progress]);


  //------------------------------------------------
  //Set Current Paras In Accordance To Active Class 
  //------------------------------------------------
  useEffect(() => {
    setCurParas();
  }, [active_node, batch, stage1.paras]);

  let [allowStage2, setAllowStage2] = useState(true);

  
 //-----------------------------------------------
 // Call Stage2 API and Redirect User To Stage2
 //-----------------------------------------------
  let moveToStage2 = async () => {
    // console.log("going to stage 2")
    //return;
    if (allowStage2 === true) {
      setAllowStage2(false);

      try {

        await Axios.post("/integration/"+stage2Endpoint, {
          workflowId: currentWorkflow?.workflowId,
          batchNumber: 1
        });

        toast("Stage 2 Auto Annotation started successfully.", { type: toast.TYPE.SUCCESS });
        setStage2Started(true);
        setIntegrated(false);
      }
      catch (err) {
        toast("There was an error while initializing Stage 2. Please reload the page.",
          { type: toast.TYPE.ERROR })
      }
      finally {
        setAllowStage2(true);
      }
    
    }
  };



 //------------------------------------------------------------
 // Check Stage2Progress Percentage And Redirect User To Stage2 
 //------------------------------------------------------------
  useEffect(() => {
    //check if stage 2 is in progress
    if (

      currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress
        ?.currentBatchProgress === 100
    ) {
      navigate("/workflow/stage2?workflowId=" + currentWorkflow.workflowId);
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.currentBatchProgress
  ]);


 //------------------------------------------------------------
 // Check If Stage2 Progress Started  
 //------------------------------------------------------------
  useEffect(() => {
    let val = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.currentBatchProgress as number;

    if (val) {
      setStage2percentage(`${val}%`);

      if (val !== 100 && val !== 0) {
        setStage2Started(true);
        setIntegrated(false);
      }
    } else {
    }
  }, [
    currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress
      ?.currentBatchProgress
  ]);


 //------------------------------------------------------------
 // Function To Handle Add New Para Request  
 //------------------------------------------------------------
  let handleAddNewPara = async (data: any) => {
    const selectedIssueId = active_node?.node_id; // from store
    if (!selectedIssueId) return //console.error("Selected Issue id null");

    if (data.newParaTitle.length !== 0 && data.newParaData.length !== 0) {
      const newParaParams = {
        workflowId: currentWorkflow?.workflowId,
        paraTitle: data.newParaTitle,
        paraData: data.newParaData,
        issueId: selectedIssueId,
      };
      setSeedPara(false);

      try {

        //let res = apiNetworkCall(SEED_PARA, "post", newParaParams);

        let res = await Axios.post("/annotations/seed-para", {
          workflowId: currentWorkflow?.workflowId,
          paraData: newParaParams.paraData,
          issueId: newParaParams.issueId

        })
        //console.log("para was seeded successfully",res.data);

        // --- Make a seed para request here ---
        toast("New paragraph data was successfully seeded.", { type: "success" });
        
      } catch (err) {
        toast("Internal Server Error : Seed paragraph", { type: "success" });
      
      }
    }
  };


 //------------------------------------------------------------
 // Function To Update Node Progress Count  
 //------------------------------------------------------------
  let handleCalculate = (nodes: any) => {
    let completed_percentage = 0;
    for (let i = 0; i < stage1.nodes.length; i++) {
      if (nodes[i].node_id === active_node?.node_id) {
        completed_percentage = nodes[i].completed_percentage;
      }
    }
    return completed_percentage;
  };


  //----------------------------------------------------
  //Unlock Masterlist & Redirect User To Masterlist Page
  //----------------------------------------------------
  let resetStage1 = async () => {

    //--- Make a request to reset Stage 1 here ---

    Axios.post("/masterlist/markLocked", {
      workflowId: currentWorkflow?.workflowId,
      markAs: false,
      user: userDetails?.userId
    });

    navigate("/workflow/masterlist?workflowId=" + currentWorkflow?.workflowId);
  };

  let [stage1Valid, setStage1Valid] = useState(true);

  
  //---------------------------------------------------------
  // Check If Stage1 Classed Have Enough Paras For Validation
  //---------------------------------------------------------
  useEffect(() => {
    // console.log("Inside stage 1 check",stage1percentage,fetching);
    if (stage1percentage === "100%" && fetching === false) {
      let isStage1Valid = true;
      if (stage1.paras.length >= 2) {
        let count = 0;
        stage1.paras.forEach((data) => {
          if (data.data.length > 15) {
            count += 1;
          }
        });
        if (count < 2) {
          isStage1Valid = false;
        }
      } else {
        isStage1Valid = false;
      }

    
      setStage1Valid(isStage1Valid);
    }
  }, [stage1.paras, fetching]);




  //---------------------------------------------------------
  // Show Loading Screen If Stage Is Not Ready
  //---------------------------------------------------------
  if (integrated === false || currentWorkflow?.stages.find(s => s.name === "validation-1")?.active === false) {
    return (
      <>
        {/* <Prompt
          when={shouldBlockNavigation}
          message={
            "You will have to restart validation process to get back to this page"
          }
        /> */}
        <LoadingScreen
          msg={"Please wait while we annotate the paragraphs for you!"}
          compleated={
            state2started
              ? getWithoutNan(parseInt(stage2percentage.trim().slice(0, -1)))
              : getWithoutNan(parseInt(stage1percentage.trim().slice(0, -1)))
          }
        />
      </>
    );
  }

  return (
    <>
      {/* <Prompt
        when={shouldBlockNavigation}
        message={
          "You will have to restart validation process to get back to this page"
        }
      /> */}
      <div className={"w-full -mt-[2vw] "}>
        <div className="flex">
          <div
            className="w-[21vw] mr-[1vw] mt-[2vw] border-r border-gray-200"
          >
            <div
              className={"text-[1.2vw] text-[#7A7A7A]  p-2"}
            >
              Tags
            </div>
            <Divider className="p-0 m-0"
            />
            <br />
            <div
              className={`${style.custom_scroll} max-h-[100vw] !min-h-[50vh] p-[0.7vw] `}
            >
              {true &&
                stage1.nodes?.map((node) => {
                  return (
                    <StageNode
                      onSelect={() => {
                        setActiveNode(node);
                        setBatch(1);
                      }}
                      key={node._id}
                      active={node.node_id === active_node?.node_id}
                      parent_name={
                        getNodeInfo(node.parent_id, nodes)?.node_name || "root"
                      }
                      node_name={node.node_name}
                      node_id={node.node_id}
                      completed_percentage={node.completed_percentage}
                    />
                  );
                })}
            </div>
            <br />
            {/* <div
              onClick={() => {
                // history.push("/app/help_and_guideline");
                navigate("/help_and_guideline");
              }}
              className="flex cursor-pointer items-center space-x-[0.9vw] bg-white m-[0.3vw] p-[0.7vw] rounded-md"
            >
              <img
                className="w-[1.4vw]"
                src="/images/help_and_guideline.svg"
                alt="help and guideline pic"
              />
              <div className="text-[#7a7a7a] text-[1vw]">
                Help & Guidelines
              </div>
            </div> */}
            {/* <NerSidebar /> */}
          </div>

          <div className={"w-4/5"}>
            <div

              className={"text-[1.2vw] text-[#7a7a7a] p-2 flex justify-between mt-[2vw]"
            }
            >
              <p>Suggested Paragraphs</p>
              {/* <p>
                <span style={{ color: "#11256D" }}>
                  {handleCalculate(stage1.nodes)}/15
                </span>{" "}
                Completed
              </p> */}
              <div className="flex space-x-3">
                <Popover content="Achieved Target">

                <div className="flex space-x-1">
                  <img src="/images/achieved_target.svg"  className="w-[1.8vw]" alt="" />
                  <span className="cursor-default text-[#1BA94C]">{handleCalculate(stage1.nodes)}</span>
                </div>
                </Popover>
                <Popover content="Target For Current Batch">

                <div className="flex space-x-1">
                  <img src="/images/current_target.svg"  className="w-[1.8vw]" alt="" />
                  <span className="cursor-default text-[#1BA94C]">{15 - handleCalculate(stage1.nodes)>0?(15 - handleCalculate(stage1.nodes)):"0"}</span>
                </div>
                </Popover>
                <Popover content="Total Target">

                <div className="flex space-x-1">
                  <img src="/images/total_target.svg"  className="w-[3vw]" alt="" />
                  <span className="cursor-default text-[#1BA94C]">15</span>
                </div>
                </Popover>

              </div>
            </div>
            <Divider style={{ padding: 0, margin: 0 }} />

          

            {stage1Valid === false && !fetching ? (
              <div className="uppercase w-full min-h-[25vw] flex justify-center px-[1.5vw] py-[0.6vw] text-[1.3vw] flex-col items-center text-center">
                You don't have enough data for annotation. Please provide
                relevant keywords for the nodes. <br />
                <button
                  onClick={() => {
                    resetStage1();
                  }}
                  className="uppercase bg-primaryBlue text-white px-[1vw] py-[0.7vw] mt-[1.5vw] text-[1.1vw] rounded-[0.5vw] shadow-[0.2vw]"
                >
                  Go to masterlist
                </button>
              </div>
            ) : (
              <div className={` space-y-3 p-3 border-b  `}>
                <div id={"stage_1_paras "}
                ref={stageParasRef}
                  className={`${style.para_scroll} px-[1vw] space-y-[1vw] pb-[2vw]`}
                  
                >
                  {current_paras && current_paras?.length>0? (
                    current_paras?.map((paraMain: any, index:number) => {
                      //@ts-ignore
                      let para = paraMain.para_info;
                      
                      return (
                        <StagePara
                          // nerInfo={paraMain.alpdsInfo[0].nerInfo}
                          index={index}
                          activePara={activePara}
                          next={NextPara}
                          prev={PrevPara}
                          setActivePara={setActivePara}
                          key={para.paraId}
                          active_node_id={active_node?.node_id}
                          accepted={paraMain.accepted}
                          node_name={
                            active_node === undefined
                              ? "loading ..."
                              : active_node?.node_name
                          }
                          para_data={para.paraData}
                          time_str={para.workflows[currentWorkflow?.workflowId || ""]?.createdAt || ""}
                          // time_str=""
                          paraId={para.paraId}
                          stage={1}
                          predictedIssueId={
                            para.workflows[currentWorkflow?.workflowId || ""]?.alpdsInfo.predictedIssueId.stage1[0]

                          }
                          nextPredictedIssueId={
                            para.workflows[currentWorkflow?.workflowId || ""]?.alpdsInfo?.rankMetric.stage1.value[1][0]
                          }
                          batchId={paraMain.batchId}

                          //keyboard toggle
                          keyboardEnabled={keyboardEnabled}
                          setKeyboardEnabled={setKeyboardEnabled}
                        />
                      );
                    })
                  ) : (
                    <div className={"w-full flex justify-center h-[20vw] items-center"}>
                      {fetching === true ? (
                        <img
                          src="/images/loading_blue.svg"
                          alt="loading"
                          className="w-[3vw] mt-[2.5vw]"
                        />
                      ) : (
                        <div
                          className={"text-[1.2vw] mt-[0.8vw]  text-gray-400"}
                        >
                          No more paragraphs for the selected class 
                        </div>
                      )}
                    </div>
                  )}




                </div>
                {/* {seedPara ? (
                  <SeedPara
                    title={active_node.node_name}
                    handleAddNewPara={handleAddNewPara}
                  />
                ) : null} */}
                <div className={"px-[1vw] flex justify-between items-center"}>
                  <div></div>
                  {/* <button
                    className={`uppercase w-[15vw] border-2 flex justify-center  items-center   rounded-md transform hover:scale-105 duration-200
                      ${!seedPara?"bg-white text-[#11256D] border-[#11256D] border-2 text-[0.9vw] px-[1vw] py-[0.8vw] hover:bg-[#11256D] hover:text-white"
                      :"bg-[#E63946] text-white text-[1vw] py-2"}
                      `}
                 
                    onClick={() => setSeedPara(!seedPara)}
                  >
                    {!seedPara && (
                    <>  <SeedIcon color="" stroke=""  className=" mr-2" />
                     
                      </>
                    )}
                    {!seedPara ? "SEED NEW PARAGRAPH" : "CANCEL"}
                  </button> */}


                  {/* Commented By Mudit 22-06-2022 */}
                  {/* <div className="flex space-x-3 items-center justify-center ">

                    {batch !== 1 && active_node.completed_percentage < 15 && (
                      <button
                        className={`uppercase duration-200  text-white px-3 py-2 ml-3  rounded-md border-2 border-transparent   ${disable
                          ? "bg-gray-400"
                          : "bg-primaryBlue hover:text-primaryBlue hover:border-primaryBlue hover:bg-white"
                          }`}
                        disabled={disable}
                        onClick={() => handleSkipNode()}
                      >
                        SKIP NODE
                      </button>
                    )}
                  </div> */}
                  <div className="flex items-center justify-center">
                    <Pagination responsive current={batch} defaultCurrent={1} total={30} onChange={(page) => setBatch(page)} />
                  </div>
                </div>
                <Divider />
                {/* <div
                  className="py-[0.5vw] text-[#7A7A7A] text-[1.2vw] pl-3"

                >
                  Suggest Tags
                </div>
                <Divider />
                <div className="overflow-y-auto  custom_scroll max-h-[50vw]"
                  id={"stage_2_paras"}
                  
                >                  {stage1.rejectedParas &&
                  stage1.rejectedParas.length > 0 &&
                  stage1.rejectedParas.map((para: any, index) => {
                    if (para.curNode) {
                      return (
                        <RejectedPara
                          suggestedNodeInfo={para.nextPredicted}
                          paraData={para.data.paraData}
                          nodeName={para.curNode.node_name}
                          paraId={para.data.paraId}
                          stage={1}
                          timeStr={para.data.createdAt}
                        />
                      );
                    }
                  })}
                </div> */}
                {currentProject?.createdBy === userDetails?.userId &&
                  <div className="w-full flex mt-[1vw] justify-end items-center">


                    <button
                      disabled={!isAllowed}
                      title={
                        isAllowed
                          ? ""
                          : "Either complete target of 15 paras per class or validate at least 2 batches for every class"
                      }
                      className={`uppercase duration-200 text-center text-[0.9vw] 
                      w-[13vw]
                      text-white
                      px-[1vw] py-[0.8vw] 
                      ml-[0.7vw]
                      my-[0.3vw]  text-white rounded-md border-2  border-transparent 
                    ${!isAllowed
                          ? "bg-gray-400 cursor-not-allowed"
                          : " border-transparent  bg-primaryBlue  hover:border-primaryBlue hover:scale-105"
                        }`}
                      onClick={() => {
                        moveToStage2();
                      }}

                    >

                      {allowStage2 === false ? (
                        <img
                          className="mx-auto w-[1.3vw] h-[1.3vw]"
                          src={"/images/loading_white.svg"}
                          
                          alt="loading"
                        />
                      ) : (
                        "Move To Stage 2"
                      )}
                    </button>


                    <button
                      className="uppercase duration-200  w-[13vw]
                      text-white text-[0.9vw] 
                      px-[1vw] py-[0.8vw] ml-[0.7vw]
                      my-[0.3vw] text-center rounded-md border-2 
                      border-transparent bg-primaryBlue  hover:border-primaryBlue hover:scale-105 "

                      onClick={() => navigate("/workflow/stage1-summary?workflowId=" + currentWorkflow?.workflowId)}
                    >

                      Validation Summary
                    </button>
                  </div>}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stage1;