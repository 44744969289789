import ReactApexChart from "react-apexcharts";
import { InfoCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Button from '../../../components/controls/Button';
import Axios from '../../../utils/Axios';
import useWorkflowStore from "../../../store/useWorkflowStore";
import useAuthStore from "../../../store/authStore";
import useCompanyUsersStore from "../../../store/useCompanyUsersStore";
import { toast } from 'react-toastify';
import { nodesAtom } from '../../../components/masterlist/atoms';
import { useRecoilValue } from 'recoil';



import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsArrowsAngleExpand } from 'react-icons/bs'

import template from "../../../template";
import { Modal, Popover } from "antd";
import ExpandedEntitySummary from "./NERExpandedEntitySummary";




const NERUserStatisticsComponent= ({ tabData }:any) => {

  const loading = tabData.loading;
  const vStats = tabData?.data?.vStats;
  const barGraphDataOptions = tabData?.data?.barGraphDataOptions;
  const smallWidth = tabData?.data?.smallWidth;
  const expandedWidth = tabData?.data?.expandedWidth;
 

  const userInfo = useAuthStore(state=>state.userDetails);


  // main states
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isClasswiseOpen, setIsClasswiseOpen] = useState<boolean>(false);
  const [showExpandMesssage, setShowExpandMessage] = useState<boolean>(false);


  const Loader = () => {
    return (

      <div className='p-3 h-[25vw] flex-1  flex justify-center items-center '>
        <AiOutlineLoading3Quarters size={50} color={"gray"} className=" animate-spin" />
      </div>

    )
  }



  return (
    <>
      <div className="flex p-2 bg-white  shadow-md">
        <div className="flex flex-col flex-1 mx-3">
          <div className=" font-bold my-2">Full Name</div>
          <div className="flex border-2 bg-[#f9fafb] border-[#C4C4C4]  max-w-[325px]  rounded-[4px] px-4 py-2">
            <div className='color-[#7A7A7A]'>{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
          </div>
        </div>

        <div className="flex flex-col flex-1 ml-14">
          <div className="font-bold my-2">Email</div>
          <div className="flex border-2 bg-[#f9fafb] border-[#C4C4C4] max-w-[325px] rounded-[4px] px-4 py-2">
            <div className='color-[#7A7A7A]'>{userInfo?.email}</div>
          </div>
        </div>

        <div className="flex flex-col flex-1 ml-[1.8rem]">
          <div className="flex border-2 bg-white border-[#C4C4C4]  max-w-[250px]  rounded-[4px] mt-[2.35rem] px-4 py-2">
            <div className='color-[#7A7A7A]'>Complete Project</div>
          </div>
        </div>
        {/* <div className="relative" onClick={() => { setIsOpen((prevState: boolean) => !prevState) }}>
          {isOpen ? < UpOutlined style={{ position: 'relative', fontSize: '20px', top: "3px", right: "3px" }} /> : <DownOutlined style={{ position: 'relative', fontSize: '20px', top: "3px", right: "3px" }} />}
          </div> */}

      </div>
      {isOpen &&
        <div  >
          <div className="flex    p-2 bg-white">
            {loading ? (
              <Loader />
            )
              : <div className="flex-col w-[40%] 
                               shadow-[0px_0px_8px_rgba(122,122,122,0.15)]
                                px-5 py-1 my-5 mx-3 text-[0.9vw]">
                <div className="text-[#1BA94C] text-[0.9vw] p-[0.1vw] font-bold mt-2 mb-5">Validation Statistics</div>
                {/* <hr
                style={{
                  color: "#EAEAEA",
                  backgroundColor: "#EAEAEA"
                }}
              /> */}

                <div className="flex-col text-[#9A9A9A] py-2 ">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[65%]">Total Paragraphs</div>
                    <div className="mr-3">{vStats?.totalParas}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

                <div className="flex-col text-[#9A9A9A] py-2 ">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[65%]">Paragraphs Validated by User</div>
                    <div className="mr-3">{vStats?.userValidated}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[70%]">Cumulative Entities Predicted by Algorithm</div>
                    <div className="mr-3">{vStats?.totalPredicted}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[70%]">Cumulative Entities Added by User</div>
                    <div className="mr-3 w-[50%] text-end">{vStats?.addedByUser}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />


                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between  ">

                    <div className="ml-1 w-[65%]">Predicted Entities Accepted by User</div>

                    <div className="mr-3" >{vStats?.accepted}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />
                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between">

                    <div className="ml-1  w-[60%] ">Predicted Entities Rejected by User</div>

                    <div className="mr-3" >{vStats?.rejected}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

              </div>}

            {loading ? <Loader /> : <div className="flex flex-col items-center justify-start my-5 mx-3 flex-1 shadow-[0px_0px_8px_rgba(122,122,122,0.15)] ">

              <div className="flex items-center justify-between w-full p-[0.5vw]">
                <div className="self-start text-[#1BA94C] font-bold text-[0.9vw] p-[0.1vw]">Classwise Validation Statistics</div>

                <div className='flex items-center gap-[1vw]'>
                  <p className="text-[0.7vw]">
                    {showExpandMesssage && "(Showing only 10 Entities)"}</p>
                  <Popover
                    content="Expand">

                    <BsArrowsAngleExpand className='cursor-pointer hover:scale-110 duration-100'
                      size={17}
                      color={"#777777"}
                      onClick={() => setIsClasswiseOpen(!isClasswiseOpen)}
                    />
                  </Popover>
                </div>
              </div>

              <div id="chart" className="my-2 p-2 w-full h-full flex justify-center items-center">

                <ReactApexChart
                  options={barGraphDataOptions}
                  series={barGraphDataOptions.series}
                  type="bar"
                  width={smallWidth}
                  height={350} />

                <Modal
                  open={isClasswiseOpen}
                  onCancel={() => setIsClasswiseOpen(false)}
                  footer={null}
                  width={"90vw"}

                >
                  {!loading && <ExpandedEntitySummary
                    isOpen={isClasswiseOpen}
                    setIsOpen={setIsClasswiseOpen}
                    width={expandedWidth}
                    
                    pieChartOptions={barGraphDataOptions}
                    loading={loading}

                  />}
                </Modal>
              </div>



            </div>}
            {/* {loadingOverlap ? <Loader /> : <div className="flex flex-col items-center justify-start my-5 mx-3 flex-1 shadow-[0px_0px_8px_rgba(122,122,122,0.15)] ">
              <div className="self-start text-[#1BA94C] text-[0.9vw] p-[0.1vw] font-bold mt-3 mx-5">Overlap Statistics With other Validators</div>

              <div id="chart" className="flex justify-center items-center">


                <ReactApexChart
                  options={overlapStats ? overlapStats?.options : {}}
                  series={overlapStats ? overlapStats?.series : []}
                  type="bar"
                  width={300}
                  height={300} />

              </div>
            </div>} */}

          </div>



        </div>
      }
    </>);
}


export default NERUserStatisticsComponent;
