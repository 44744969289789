import * as React from "react";

export interface StageNodeProps {
  parent_name: string;
  node_name: string;
  active: boolean;
  node_id: string;
  completed_percentage: number;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
}
  
const StageNode: React.SFC<StageNodeProps> = (props) => {
 
  return (
    <div
      onClick={props.onSelect}
      className={`cursor-pointer px-[1vw]  py-[0.8vw] mb-[0.8vw] rounded-[0.3vw] 
      ${props.active?" bg-[#11256D] text-white":"bg-white  hover:bg-[#E5E5E5] "}
      `}
      
    >
      <div className="font-semibold text-[0.9vw] text-[#c4c4c4]">{props.parent_name} </div>
      <div className="font-semibold text-[1vw]">
        {props.node_name}
      </div>
     
    </div>
  );
};

export default StageNode;
