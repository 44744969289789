import * as React from "react";
import { useState } from "react";
import { /*Upload,*/ Modal, Progress } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "../utils/Axios";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import AppLayout from "../components/layouts/AppLayout";
import PageLayout from "../components/layouts/PageLayout";
import useAuthStore from "../store/authStore";
import useWorkflowStore from "../store/useWorkflowStore";
import './index.css';
import AccessControl from "../components/AccessControl/AccessControl";
import useMasterlistSummary from "../store/useMasterlistSummary";

import { AiOutlineCloudDownload } from 'react-icons/ai';
import DownloadTemplates from "../components/DownlaodTemplates";
import template from "../template";

export interface UloadedFile {
    extension: string;
    fileId: string;
    fileName: string;
    filePath: string;
    practiceAreaId: string;
    size: number;
    status: string;
    uploadedBy: string;
    _id: string;
}

const UploadPage: React.SFC = () => {
    let [uploadedFiles, setUploadedFiles] = useState<Array<UloadedFile>>([]);
    let navigate = useNavigate();
    let [uploadBtnActive, setUploadBtnActive] = useState(true);
    let [uploadPercent, setUploadPercent] = useState(0);

    let workflowStore = useWorkflowStore();
    let workflowId = workflowStore.currentWorkflow?.workflowId;
    const summaryStore = useMasterlistSummary();
    let [fileId, setFileId] = useState<Array<string>>([]);
    let [showProgress, setShowProgress] = useState(false);
    let authStore = useAuthStore();
    let currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
    let project = useWorkflowStore(state => state.currentProject);
    let [showButtons, setShowButtons] = useState(false);
    let { usage, setUsage, activePlan } = useAuthStore();
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const projectType = workflowStore.currentProject?.projectType;

    const hasAM = projectType &&  template[projectType].hasAMasterlist;
    const hasMasterlist = projectType &&  template[projectType].hasMasterlist;

    useEffect(() => {

        if (workflowId && !workflowStore.userProject?.UPLOAD_ACCESS) {
            toast("You do not have the authorization to perform validations. Please contact your project creator.", { type: toast.TYPE.WARNING });
            navigate("/workflow?workflowId=" + workflowId);
        }

    }, [workflowStore.currentWorkflow?.workflowId])

    useEffect(() => {

        // make window scroll top
        window.scrollTo(0, 0);
    }, []);

    let getUploadedFiles = async () => {
        try {
            let res = await axios.post("/file/getAllUploadedFile", {
                workflowId: workflowId as string,
            });

            let data = res.data.data;

            setFileId([data.map((file: any) => file.fileId)]);
            setUploadedFiles(data);
        } catch (err) {
            toast("Internal Server Error : Fetching the files", {
                type: "error",
            });
        }
    };

    useEffect(() => {
        if (workflowId)
            getUploadedFiles();
        return () => {
            Modal.destroyAll();
        };
    }, [workflowStore.currentWorkflow]);

    const uploadFile = async (selectedFile: Array<File>) => {
        console.log(selectedFile);
        setUploadPercent(0);
        setUploadBtnActive(false);
        setShowButtons(false);

        // Setting billingAccountId
        let billingAccountId = "";
        if (authStore.userDetails) {
            if (authStore.userDetails.isBillingAccount) {
                billingAccountId = authStore.userDetails.userId;
            } else {
                billingAccountId = authStore.userDetails.associatedBillingAccounts[0];
            }
        }

        for (let i = 0; i < selectedFile.length; i++) {
            let currentFile = selectedFile[i];

            let formData = await new FormData();
            formData.append("uploaded_file", currentFile);
            formData.append("workflowId", workflowId as string);
            formData.append("type", "upload");
            formData.append("userId", authStore.userDetails?.userId as string);
            formData.append("billingAccountId", billingAccountId as string);

            try {

                if (usage && activePlan) {
                    let currFileSize = (currentFile.size / (1000000 * 1024));
                    let availableSpace = activePlan.details.secondaryMemory - usage.details.secondaryMemory;

                    if (currFileSize > availableSpace) {
                        setShowPopup(true);
                        setUploadBtnActive(true);
                        setShowProgress(false);

                        let isTrue = uploadedFiles.length > 0 ? true : false;
                        for (let i = 0; i < uploadedFiles.length; i++) {
                            let file = uploadedFiles[i];
                            if (workflowStore?.currentWorkflow?.alpdsInfo?.percentageStatus?.dataIngestionProgress[file.fileId] !== 100) {
                                isTrue = false;
                            }
                        }
                        if (isTrue) {
                            setShowButtons(true);
                        } else {
                            setShowButtons(false);
                        }

                    } else {
                        let res = await axios({
                            method: "post",
                            url: "/file/upload",
                            data: formData,
                            onUploadProgress: (e) => {
                                setUploadPercent(Math.round((100 * e.loaded) / e.total));
                            },
                        });

                        if (res.status === 200) {

                            // Updating state
                            let temp = usage;
                            if (temp) {
                                let fileSize = (res.data.data.size / (1000000 * 1024));
                                temp.details.secondaryMemory = temp.details.secondaryMemory + fileSize;
                                setUsage(temp);
                            }

                            setFileId([...fileId, res.data.data.fileId]);
                            setUploadedFiles((files) => [res.data.data, ...files]);
                            setShowButtons(true);
                        }
                    }
                }

            } catch (err) {
                console.error(err);
            }
        }
        //After the upload is done let's trigger the split para
        summaryStore.setFetchSummary(true);
        await axios
            .post("/integration/triggerFileSpitting", {
                workflowId,
                projectType
            })
            .then((res) => {
                setUploadBtnActive(true);
            })
            .catch((err) => {
                console.error(err);
            });

        // once para splitting is done update the stage
    };

    let [creatingMasterlist, setCreatingMasterlist] = useState(false);
    let [creatingAMasterlist, setCreatingAMasterlist] = useState(false);
    let { search } = useLocation()
    let createMasterlistHandler = async () => {
        setCreatingMasterlist(true)
        try {
            

                let response = await axios.post("/masterlist/createMasterlist", {
                    createdBy: authStore.userDetails?.userId,
                    workflowId: workflowId,
                });
            
            // console.log(response.data);

            // Updating state
            // let temp = usage;
            // if (temp) {
            //     temp.details.noOfMasterlist = temp.details.noOfMasterlist + 1;
            //     setUsage(temp);
            // }

            let wId = new URLSearchParams(search)
            console.log("navigate to masterlist with workflowID", wId.get("workflowId"));
            setCreatingMasterlist(false)
            navigate("/workflow/masterlist?workflowId=" + wId.get("workflowId"));

            // navigate("/masterlist")
        } catch (err) {
            setCreatingMasterlist(false)
            toast("There was an issue while creating the Masterlist. Please reload the page and try again.", {
                type: "error",
            });
        }
    };

    useEffect(() => {
        let isTrue = uploadedFiles.length > 0 ? true : false;
        for (let i = 0; i < uploadedFiles.length; i++) {
            let file = uploadedFiles[i];
            if (workflowStore?.currentWorkflow?.alpdsInfo?.percentageStatus?.dataIngestionProgress[file.fileId] !== 100) {
                isTrue = false;
            }
        }
        if (isTrue) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [uploadedFiles])


    const DisabledUpload = () => {
        return (
            <div className="flex justify-center items-center mx-1 h-full flex-col">
                <div className=" relative flex flex-col justify-center items-center  w-full h-full cursor-no-drop grayscale">
                    <img
                        className="z-50 w-[17vw]"
                        style={{
                            position: "absolute",
                            transform: "translateX(-10%)",
                            pointerEvents: "none",
                            opacity: uploadBtnActive ? 1 : 0.5,
                        }}
                        src="/images/upload_button.svg"
                        alt="upload"
                    />

                    {/* <input
                        id="upload"
                        disabled={!uploadBtnActive}
                        style={{ opacity: 0 }}
                        className={"absolute z-50 top-0  left-0 border-dashed border-2 border-green-500 w-full h-full "}
                        accept={".csv,.zip,.txt,text/plain,text/csv,application/zip,.pdf"}
                        type="file"
                        multiple
                        placeholder={"Select file"}
                        onChange={(e) => {
                            //let's reset every state

                            if (e.target.files?.length !== 0) {
                                setShowProgress(true);
                                //@ts-ignore
                                uploadFile(e.target.files);
                            }
                        }}
                    />
                    <div className="w-[21vw] mt-[12vw]">

                        {showProgress && <Progress strokeColor={"#11256D"} percent={uploadPercent} style={{ width: '19.5vw' }} />}
                    </div> */}
                </div>
            </div>
        )
    }

    return (
      
                <div>
                    <div className="flex justify-around my-[1.2vw] w-full bg-white" style={{ height: "30vw" }}
                    >
                        <div
                            style={{
                                width: "49%",
                                minWidth: "250px",
                                color: "#11256D",
                                borderRight: "1px solid #E0E0E0",
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            {
                                showPopup &&
                                <Modal
                                    title="Can't upload"
                                    centered
                                    width="45vw"
                                    open={showPopup}
                                    onCancel={() => setShowPopup(false)}
                                    footer={[
                                        <button onClick={() => setShowPopup(false)} className="text-[#fff] text-[1vw] bg-[#aaa] px-[0.9vw] py-[0.5vw] rounded-[0.4vw]">
                                            Close
                                        </button>,
                                        authStore.userDetails && authStore.userDetails.isBillingAccount &&
                                        <button onClick={() => navigate("/billing")} className="text-[#fff] text-[1vw] bg-primaryBlue px-[1.3vw] py-[0.5vw] rounded-[0.4vw] ml-[0.5vw]">
                                            Upgrade
                                        </button>
                                    ]}
                                >
                                    <p>The file exceeds your available usage.</p>
                                </Modal>
                            }

                            <AccessControl RestrictedComp={DisabledUpload} RequestedPerm="canUpload">

                                <div className="flex justify-center items-center mx-1 h-full flex-col">
                                    <div className=" relative flex flex-col justify-center items-center  w-full h-full">
                                        <img
                                            className="z-50 w-[17vw]"
                                            style={{
                                                position: "absolute",
                                                transform: "translateX(-10%)",
                                                pointerEvents: "none",
                                                opacity: uploadBtnActive ? 1 : 0.5,
                                            }}
                                            src="/images/upload_button.svg"
                                            alt="upload"
                                        />

                                        <input
                                            id="upload"
                                            disabled={!uploadBtnActive}
                                            style={{ opacity: 0 }}
                                            className={"absolute z-50 top-0  left-0 border-dashed border-2 border-green-500 w-full h-full "}
                                            accept={".csv,.zip,.txt,text/plain,text/csv,application/zip,.pdf,.xlsx"}
                                            type="file"
                                            multiple
                                            placeholder={"Select file"}
                                            onChange={(e) => {
                                                //let's reset every state

                                                if (e.target.files?.length !== 0) {
                                                    setShowProgress(true);
                                                    //@ts-ignore
                                                    uploadFile(e.target.files);
                                                }
                                            }}
                                        />
                                        <div className="w-[21vw] mt-[12vw]">

                                            {showProgress && <Progress strokeColor={"#11256D"} percent={uploadPercent} style={{ width: '19.5vw' }} />}
                                        </div>
                                    </div>
                                </div>

                            </AccessControl>
                        </div>

                        <div className="flex flex-col py-[1.5vw] px-[1vw] !overflow-y-auto custom_scroll w-1/2">
                            {uploadedFiles.length !== 0 ? (
                                <div>
                                    {uploadedFiles.map((file: UloadedFile, i: number) => {
                                        return (
                                            <div key={file._id}>
                                                <div className={"w-full rounded"}>
                                                    <div
                                                        style={{
                                                            // borderRadius: "0.2vw 0.2vw 0px 0px",
                                                            width: "100%",
                                                            height: "0.3vw",
                                                            backgroundColor: "#ddd",
                                                        }}
                                                    >
                                                        <div
                                                            className="rounded"
                                                            style={{
                                                                //borderRadius: "0.1vw 0.1vw 0px 0px",
                                                                width: `${workflowStore.currentWorkflow?.alpdsInfo?.percentageStatus?.dataIngestionProgress[file.fileId] || 0}%`,
                                                                height: "0.3vw",
                                                                backgroundColor:
                                                                    workflowStore.currentWorkflow?.alpdsInfo?.percentageStatus?.dataIngestionProgress[file.fileId] === 100
                                                                        ? "#1BA94C"
                                                                        : "#11256D",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div className={"flex p-[0.5vw] items-end justify-between"}>
                                                        <div className="flex items-center">
                                                            <img
                                                                src={
                                                                    file.fileName.endsWith(".txt") || file.fileName.endsWith(".csv")
                                                                        ? "/images/file_text.svg"
                                                                        : "/images/file_zip.svg"
                                                                }


                                                                className='w-[1.3vw]'
                                                                alt=""
                                                            />
                                                            <div className="pl-[0.5vw] text-[1vw]">{file.fileName}</div>
                                                        </div>
                                                        {/* <div>{`${currentPracticeArea?.alpdsInfo?.percentageStatus?.dataIngestionProgress[file.fileId] || 0}%`}</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div>
                                    {uploadedFiles.length === 0 && (
                                        <div className={"w-full h-[15vw] flex justify-center items-center text-4xl text-gray-300"}>NO UPLOADS IN PROGRESS</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={"flex justify-end"}>

                        <div>
                            {showButtons &&
                                <div className="">
                                    {hasAM && <button
                                        onClick={() => {
                                            // let's create a masterlist for the user
                                            setCreatingAMasterlist(true);
                                            navigate("/workflow/advancedmasterlist?workflowId=" + currentWorkflow?.workflowId);
                                        }}
                                        disabled={creatingMasterlist || creatingAMasterlist}
                                        className={`cursor-pointer uppercase bg-primaryBlue text-white border-transparent mr-[0.5vw] duration-300 py-[0.8vw] border-blue-800 w-[15vw] text-[1vw] text-center rounded-[0.5vw] hover:scale-105`}
                                    >
                                        {creatingAMasterlist ? <img src={"/images/loading_white.svg"}
                                            width="20vw"
                                            height="20vw"
                                            alt="loading"
                                            className="text-center mx-auto" />

                                            : "Advanced Masterlist"}
                                    </button>}

                                    <button
                                        onClick={() => {
                                            // let's create a masterlist for the user

                                            createMasterlistHandler();
                                        }}
                                        disabled={creatingMasterlist || creatingAMasterlist}
                                        className={`cursor-pointer uppercase bg-primaryBlue text-white border-transparent mr-[0.5vw] duration-300 py-[0.8vw] border-blue-800 w-[15vw] text-[1vw] text-center rounded-[0.5vw] hover:scale-105`}
                                    >
                                        {creatingMasterlist ? <img src={"/images/loading_white.svg"}
                                            width="20vw"
                                            height="20vw"
                                            alt="loading"
                                            className="text-center mx-auto" />

                                            : "Create Masterlist"}
                                    </button>
                                </div>
                            }
                        </div>

                    </div>


                    <div className={"p-[0.5vw]"}>
                        <div className="flex items-center">
                            <img src="/images/tip.svg" className="w-[2vw] h-[2vw]" alt="tip image" />
                            <div className="ml-[0.6vw] text-gray-500 border-b-[0.15vw] border-gray-300 text-[1.5vw]">Tip</div>
                        </div>
                        <br />
                        <div className={"text-gray-500 text-[1.2vw] w-3/5"}>
                            The accepted file formats are .csv, .txt , .pdf and .zip. In order to get a better understanding of how the file should be
                            structured, download our templates.
                        </div>
                        <br />
                        {/* <div className="flex">

                            <div
                                onClick={() => {

                                    saveAs("/doc/Template_csv_file.csv", "Template_csv_file");
                                    saveAs("/doc/Template_For_Zip_File.zip", "Template_For_Zip_File");
                                    saveAs("/doc/Template_Text_File.txt", "Template_Text_File");
                                    // saveAs("/doc/MultiClass Seed Paragraphs Template.xlsx", "MultiClass Seed Paragraphs Template");
                                    // saveAs("/doc/MultiLabel Seed Paragraphs Template.xlsx", "MultiLabel Seed Paragraphs Template")
                                }}
                                className={
                                    ` cursor-pointer 
                                duration-300 
                                group
                                flex items-center justify-center gap-[0.5vw] 
                                 px-[0.5vw] text-[1vw] py-[0.5vw]
                                text-center rounded-md text-primarycolor
                                h-[3vw] overflow-hidden
                                `
                                }
                            >
                                <div className="border-[0.15vw] rounded-full 
                                            group-hover:scale-110 group-hover:border-primaryBlue 
                                            p-[0.5vw] group-hover:scale-110 duration-200">

                                    <AiOutlineCloudDownload className="w-[1.2vw] h-[1.2vw]" />
                                </div>
                                <p className="
                                          
                                          duration-300">Download Raw Paragraph Templates</p>
                            </div>
                            <div
                                onClick={() => {

                                    // saveAs("/doc/Template_csv_file.csv", "Template_csv_file");
                                    // saveAs("/doc/Template_For_Zip_File.zip", "Template_For_Zip_File");
                                    // saveAs("/doc/Template_Text_File.txt", "Template_Text_File");
                                    saveAs("/doc/MultiClass Seed Paragraphs Template.xlsx", "MultiClass Seed Paragraphs Template");
                                    saveAs("/doc/MultiLabel Seed Paragraphs Template.xlsx", "MultiLabel Seed Paragraphs Template")
                                }}
                                className={
                                    ` cursor-pointer 
                                duration-300 
                                group
                                flex items-center justify-center gap-[0.5vw] 
                                 px-[0.5vw] text-[1vw] py-[0.5vw]
                                text-center rounded-md text-primarycolor
                                h-[3vw] overflow-hidden
                                `
                                }
                            >
                                <div className="border-[0.15vw] rounded-full 
                                            p-[0.5vw] 
                                            group-hover:scale-110 group-hover:border-primaryBlue 
                                            duration-200 ">

                                    <AiOutlineCloudDownload className="w-[1.2vw] h-[1.2vw]" />
                                </div>
                                <p className="
                                          
                                          duration-300">Download Seed Paragraph Templates</p>
                            </div>
                        </div> */}
                        <DownloadTemplates />
                    </div>
                </div>
    );
};

export default UploadPage;
