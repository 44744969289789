

import { Divider } from "antd";
import * as React from "react";
import { useRecoilState } from "recoil";
//import { PREDICT_DS } from "../../../../../../utils/server-urls";
import Axios from '../../../../utils/Axios';
import { Link,useNavigate } from "react-router-dom";
import useWorkflowStore from "../../../../store/useWorkflowStore";
import { toast } from "react-toastify";


export interface Deploy1Props {
  setActive:any,
  setCurrentPage:any
}

const Deploy1: React.FC<Deploy1Props> = ({setCurrentPage,setActive}) => {

  let [loading, setLoading] = React.useState<boolean>(false);
  let [predictionStarted,setPredictionStarted] = React.useState(false);
  let navigate =useNavigate();
  
  console.log("props",typeof setCurrentPage,typeof setActive);

  let { currentWorkflow } = useWorkflowStore();

  let hasLLM = currentWorkflow?.alpdsInfo?.selectedModelInfo?.type === "llm" || false;

  const predictionProgress =currentWorkflow?.alpdsInfo?.percentageStatus?.predictionOnDatabaseProgress;
  const isDisabled = predictionStarted 
  || (predictionProgress !== undefined &&  predictionProgress <100)

  let performPrediction = async() => {
  if(hasLLM){
    toast.warn("Prediction on ingested data is not available for LLM Models");
    return;
  }
 if(loading)
 {
   toast("Prediction on dataset has already started.",{type:toast.TYPE.WARNING});
   return;
 }
    setPredictionStarted(true);
   await  Axios.post("/integration/predict_dataset",{
        workflowId:currentWorkflow?.workflowId
    });
    // apiNetworkCall(PREDICT_DS, "post", {
    //   workflowId: currentWorkflow?.workflowId,
    // });
  };

  React.useEffect(() => {
    let val =
      currentWorkflow?.alpdsInfo?.percentageStatus
        ?.predictionOnDatabaseProgress;

    if (val === 0) {
      setLoading(true);
    } else {
      if (loading) {
        toast("Prediction on ingested data completed successfully.", {
          type: "success",
        });
      }
      setLoading(false);
      setPredictionStarted(false);
    }
  }, [currentWorkflow?.alpdsInfo?.percentageStatus
    ?.predictionOnDatabaseProgress]);

  return (
    <div className="w-full">
      <div className="bg-white rounded-md w-full p-[1vw]">
        <p className="font-sans text-[1.3vw]" style={{ color: "#2C2C2C" }}>
          Information about Prediction on Ingested Data
        </p>
        <Divider />
        <div className="font-thin text-[1.1vw] pb-[1vw]">
          Perform Prediction on Database paragraphs with trained model.
          Predictions will not be performed on the paragraphs which have been
          validated by user and will remain a part of training dataset. User can
          download the predictions performed on the database with the trained
          algorithm from the{" "}
          <span
            className={"font-bold cursor-pointer text-blue-700 hover:text-blue-400 duration-200 underline"}
            onClick={()=>{
              setCurrentPage("deploy2");
              setActive(2)
            }}
          >
            Export Dataset
          </span>{" "}
          page. In order to get predictions on new data use the Prediction API
          service.
        </div>
      </div>
      <div className="flex justify-end w-full my-[1.5vw]">
        <button
          disabled={isDisabled || hasLLM}
          onClick={() => performPrediction()}
          onMouseEnter={(e) => {
            e.currentTarget.setAttribute("src", "/images/loading_white.svg");
          }}
          onMouseOut={(e) => {
            e.currentTarget.setAttribute("src", "/images/loading.svg");
          }}
          className={`disabled:cursor-not-allowed px-[1vw] duration-300 py-[0.5vw] 
          disabled:hover:bg-white disabled:hover:text-primaryBlue
          
          flex justify-center 
          text-primarycolor  hover:text-white 
          text-[1.1vw] rounded-[0.3vw] 
          ${  !loading && "hover:bg-primarycolor"}
          `}
          style={{ border: "1px solid #11256D" }}
        >
          {loading ? (
            <img width={23} src="/images/loading_blue.svg" alt="loading white" />
          ) : (
            "PERFORM PREDICTION"
          )}
        </button>
      </div>
    </div>
  );
};

export default Deploy1;
