import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWorkflowStore from '../../store/useWorkflowStore';
import Axios from '../../utils/Axios';
import authStore from '../../store/authStore';
import { saveAs } from 'file-saver';

//Icons
import {ReactComponent as Loading} from '../../assets/loading_blue.svg';

interface DeployCardProps {
  activeStage: number;
  // practiceAreaId: string | undefined;
}

const DeployCard: React.FC<DeployCardProps> = ({
  activeStage,

}) => {
  const navigator = useNavigate();
  let [trained, setTrained] = useState(false);
  let currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
  let [isMasterlistComplete, setIsMasterListComplete] = useState(false);
  const [loading,setLoading] = useState<boolean>(false);
  const listviewInfo = currentWorkflow?.listviewInfo;
  const canExport = listviewInfo?.lastTrainingCount>45;


  let token = authStore(state => state.token);
  let isValidation = currentWorkflow?.stages?.find((stage:any)=>stage.name==="validation")?.active || false;

  const active = isValidation && canExport;
  let [masterlistId, setMasterlistId] = useState<string>("");
  let [trainingId, setTrainingId] = useState<string>("");

  const trainDataProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.trainDataExportProgress;
  const masterlistProgress =currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.masterlistExportProgress;
  // const loading = (trainDataProgress!==undefined && trainDataProgress<100) &&
  //                 (masterlistProgress!==undefined && masterlistProgress<100);
  // console.log("loading",loading);

  //For Training Progress
  useEffect(() => {
    let trainingProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.trainDataExportProgress;

   // console.log("==Progrss T ==", trainingProgress);
    if (trainingId !== "" && trainingProgress === 100) {
    //  console.log("=== Init Train Data ===")
    setLoading(false);
      initDownload(trainingId, token || "", setTrainingId);
    }

  }, [trainDataProgress]);


  //For MasterList Progress
  useEffect(() => {
    let masterListProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.masterlistExportProgress;



    //("==Progrss M ==", masterListProgress);
    if (masterlistId !== "" && masterListProgress === 100) {
    //  console.log("=== Init MasterList Data ===")
    setLoading(false);
      initDownload(masterlistId, token || "", setMasterlistId);
      setIsMasterListComplete(true);
    }

  }, 
  [masterlistProgress]);


  //console.log("=== Public URL ===", process.env.PUBLIC_URL);
  //console.log("=== Base URL ===", process.env.REACT_APP_BASE_URL);


  const generateUrls = async () => {


    toast("Your Datasets will start downloading shortly.", { type: toast.TYPE.SUCCESS });


    const trainingTaskId = await Axios.post("/integration/export_training_data", {
      workflowId: currentWorkflow?.workflowId,
      activeToken: token
    });



    setTrainingId(trainingTaskId.data.data);
    console.log("=== training task id ===", trainingId);

    const masterlistTaskId = await Axios.post("/integration/export_masterlist_data", {
      workflowId: currentWorkflow?.workflowId,
      activeToken: token
    });


    setMasterlistId(masterlistTaskId.data.data);
   // console.log("=== masterlist task id ===", masterlistId);

  }


  const initDownload = async (taskId: any, token: string, setState: any) => {

    const tData = await Axios.post("/integration/download_data", {
      taskId: taskId,
      activeToken: token,
      workflowId: currentWorkflow?.workflowId

    });

    //console.log("===Task Data ===", tData.data.data.url);
    // download the file

    saveAs(tData.data.data.url);
    //  saveFile(tData.data.data.url);

    return;

  }
  const saveFile = (url: string) => {
    //create an anchor element
    //initiate click event
    //delete anchor element
    if (!url) {
      toast("File could not be downloaded. Please reload the page.");
      return;
    }
   // console.log("Downloading file", url);
    const link = document.createElement("a");
    link.href = url;
    link.download = `file`;
    link.click();
    //link.remove();

  }

  return (
    <div
      className="p-3  bg-white w-full shadow-lg relative"
      style={{
        width: "20vw",
        height: "15vw",
      }}

    >
      <div className="flex space-x-2 absolute top-[0.5vw] left-[0.5vw]  ">
        <img
          width={17}
          height={22}
          src="/images/deployBlue.svg"
          alt="validate"
        ></img>
        <p className="text-[1.1vw] font-medium ">Export</p>
      </div>
      <div className="flex w-full h-full justify-center  items-center " >
        <div
          className={`text-[1vw] flex justify-center items-center text-center
          cursor-${active ? "pointer" : "not-allowed"
            } ${active
              ? !loading?"hover:bg-primarycolor hover:text-white text-primarycolor":""
              : "bg-gray-300 text-white"
            }`}
          onClick={() =>
            // trained
          {  
            if(loading) return;
            if(!active){
              toast.warn("Retrain once to export dataset with result");
              return;
            }
            setLoading(true);
            active 
              ? generateUrls()
              : toast("Initiate validation to export data", {
                type: "warning",
              })
            }
          }
          style={{
            border: `1px solid ${active? "#2C2C2C" : "white"}`,

            width: "15vw",
            height: "2.5vw",
          }}
        >
          {loading?
           <Loading className="w-[2vw] h-[2vw] hover:stroke-white" />:"Export Dataset "}
        </div>
      </div>
    </div>
  );
};

export default DeployCard;
