import { useEffect } from 'react';
import useEntityStore from '../../../store/entityStore'
import MoveToAnnotationButton from '../../controls/MoveToAnnotationButton';
import TriggerAnnotation from '../../controls/TriggerAnnotationButton';
import { headerItem } from '../types';
import AddNewEntity from './AddNewEntity';
import Entity from './Entity';
import NERTriggerAnnotation from './NERTriggerAnnotation';
import { getEntity } from './_apiCalls';
import useWorkflowStore from '../../../store/useWorkflowStore';


const headers:headerItem[] =[
    {
        text:"Entity Name",
        classes:"w-[20%]"
    },
    {
        text:"Entity Description",
        classes:"w-[40%]"
    },
    {
        text:"Entity Abbreviation",
        classes:"w-[20%]"
    },
    {
        text:"",
        classes:"w-[20%]"
    }
]

export default function EntityComp() {

    const entites = useEntityStore(state=>state.entities);
    const setEntities = useEntityStore(state=>state.setEntities);
    const canAddNew = useEntityStore(state=>state.canAddNewEntity);
    const workflowId:string | undefined = useWorkflowStore().currentWorkflow?.workflowId;
    //const setEntities = useEntityStore(state=>state.setEntities);
   // console.log("=== entities ===",entites);
   useEffect(()=>{

    const fetchEntities = async()=>{
    
        if(workflowId){
       
            const entitiyList = await getEntity(workflowId);
            setEntities(entitiyList);            
        }
    }
    fetchEntities();

   
   },[])

  return (
    <div className=''>

        {/* Header */}
        <div className='w-full  border-b h-[3vw] px-2'>
            <div className='flex items-center justify-center h-full'>

                {
                    headers.map(header=><div
                    className={` h-full
                            text-[1vw] font-medium
                            flex items-center justify-start ${header.classes}`}
                    >{header.text}</div>)
                }



            </div>
        </div>

        {/* body */}

        <div>
            {
                entites?.map((entity,i)=>{
                    return <Entity 
                            key={entity.entityId}
                            entity={entity} 
                            index={i}
                            />
                })
            }
        </div>

        <div>
           {entites.length<=20 && <AddNewEntity
            isLocked={false}
            />}
        </div>


        {/* Start Annotation Button */}

      <NERTriggerAnnotation 
      lockMasterlist={()=>alert("started annotation")}
      startAnnoEnabled={true}
      />
        
        {/* <MoveToAnnotationButton 
        startAnnoEnabled ={entites.length>=1}
        /> */}


    </div>
  )
}
