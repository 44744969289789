import axios from "axios";
import authStore from '../store/authStore';
//const token = JSON.parse(localStorage.getItem("auth-store") as string)?.state?.token;

//console.log("=== BASE_URL ===",process.env.REACT_APP_BASE_URL);
let instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"/api/v1" ,
  
    headers: {
      //  Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        accept: "application/json",
    },
    withCredentials:true
   
});

instance.interceptors.request.use(function (config:any) {
    const token =localStorage.getItem('token') as string;
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

  instance.interceptors.response.use((response:any)=>response,(err)=>{
    //Do Something if request fails with 401 error
    if(err.response.status === 401)
    {
      localStorage.clear();
      console.log("Token was rejected need to redirect user to login page");

      authStore.setState({isAuthenticated:false,token:undefined,userDetails:undefined,isProcessComplete:true});
      //window.location.replace("/signin");

    }
   return Promise.reject(err);
  
  });

export default instance;
