import React from 'react'

type Props = {
    autoValidationScore:number | null
}


function ConfidenceScore ({autoValidationScore}: Props) {
  if(autoValidationScore === null) return null;
    return (
    <div className='text-[1vw] user-selection-none'>
              Confidence Score 
              <span className='ml-[0.5vw] text-[1.1vw] text-[#1BA94C] font-strong'>{autoValidationScore * 100}%</span>
            </div>
  )
}

export default ConfidenceScore 
