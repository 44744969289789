import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';


interface IExpandedClasswiseSummary{
    isOpen:boolean,
    setIsOpen:(value:boolean)=>void,
    pieChartOptions:any,
    nodes:any[],
    loading:boolean

}




function ExpandedClasswiseSummary({
    isOpen,
    setIsOpen,
    pieChartOptions,
    nodes,
    loading
}:IExpandedClasswiseSummary) {

    const [series,setSeries] = useState<any>({});
    const [options,setOptions] = useState<any>({});

    useEffect(()=>{
        setSeries(pieChartOptions.series);
        setOptions(pieChartOptions.options);
    },[pieChartOptions])

    const getChartHeight =()=>{

        let model_nodes = nodes.filter((node:any)=>node.is_modal_class);
        if(model_nodes.length<10){
          return 500
    
    
        }
        if(model_nodes.length<25){
          return 600
    
    
        }
        if(model_nodes.length<50){
          return 700
    
        }
        if(model_nodes.length<75){
          return 800
    
    
        }
    
        return 500;
    
    
      }
      //console.log("=== series data ===",pieChartOptions)


  return (
    <>
       <div className="self-start text-[#1BA94C] font-bold text-base p-[1vw]">Classwise Validation Statistics</div>
      <div className="flex items-center justify-center p-[2vw]">

        <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width={550}
        height={getChartHeight()} />
      </div>
    </>
   
  )
}

export default ExpandedClasswiseSummary