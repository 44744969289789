import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../store/reducers";


import { toast } from "react-toastify";

import { useRecoilValue } from "recoil";
import { nodesAtom } from "../masterlist/atoms";
import NerPara from "../ner-components/NerPara";
import useWorkflowStore from "../../store/useWorkflowStore";
import Axios from '../../utils/Axios';

import ValidationControls from "./controls/multiclass/ValidationControls";
import useKeyboard from "../../hooks/useKeyboard";
import { getClassesInAlphabeticOrder } from "../stage-2/helper";


export interface StageParaProps {
 
  para:any;
  time_str: string;
  para_data: string;
  paraId: string;
  accepted: null | string;
  stage: number;
  predictedIssueId: string;
  nextPredictedIssueId: string;
  batchId: number;
  index:number;
  activePara:number;
  setActivePara:any;
  next:()=>void;
  prev:()=>void;
  readOnly?:boolean;
  keyboardEnabled?:boolean;
  setKeyboardEnabled?:any;

  setParas:any;
  setCount:any;

  fileInfo:any;
}

const ListViewPara: React.FC<StageParaProps> = (props) => {

  const {
    
    
    para,
    para_data,
    paraId,
    accepted,
    stage,
    predictedIssueId,
    nextPredictedIssueId,
    index,
    activePara,
    setActivePara,
    next,
    prev,
    readOnly,
    keyboardEnabled,
    setKeyboardEnabled,
    setParas,
    setCount,
    fileInfo

  } = props;

  //let [expanded, setExpanded] = useState(false);
  let [fade, setFade] = useState(false);
  let [accept, setAccept] = useState<boolean | null>();
  let [loading,setLoading] = useState<boolean>(false);

  
  const {currentWorkflow} = useWorkflowStore();
  const isActive = index === activePara;
  let nodes = useRecoilValue(nodesAtom);

  //sorted in alphabetic order
  const nextClasses:any[] =useMemo(()=>getClassesInAlphabeticOrder(nodes),[nodes] ) ;
  const [isMounted,setIsMounted] = useState<boolean>(false);

  //Keyboard Shortcuts
  
  const divRef = useRef<HTMLDivElement>(null);
  const Enter = useKeyboard({keyCode:"Enter"})
  const BackSpace = useKeyboard({keyCode:"Tab"})
  let waitFor = (duration:number)=>{
    return new Promise(resolve=>setTimeout(resolve,duration));
  }


  useEffect(()=>{

    const wait = async()=>{
      await waitFor(100);
      //divRef.current?.scrollIntoView({block:"start"})
      divRef.current?.scrollIntoView({block:"nearest"});
      if(activePara === 0)
      {
        divRef.current?.scrollIntoView({block:"nearest",behavior:"smooth"});
        window.scrollTo({top:0,behavior:"smooth"});
      
      }
    };
    if(isActive)
    {
      wait();
     
    
      
    }
   
  },[isActive]);

  useEffect(()=>{
    const accepteParaAndMove =async()=>{
      if(!keyboardEnabled) return;
      if(Enter && isActive)
      {
        
        if((!accepted || accepted!=="accepted") && !loading && !readOnly) {
          //next();
          await acceptPara();
          
        }
        
       
      }

    }
    accepteParaAndMove();



  },[Enter,isActive])

  useEffect(()=>{
    const rejectParaAndMove = async()=>{
      if(!keyboardEnabled) return;
      if(!BackSpace) return;
      if(!isActive) return;
     
        if((!accepted || accepted!=="rejected") && !loading && !readOnly ){
          console.log("--para id--",paraId);
          await rejectPara();
          //next();
        } 
      

    }
    rejectParaAndMove();
    
    
  },[isActive,BackSpace])
  //Keyboard Shortcuts

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  


  React.useEffect(() => {
    setAccept(accepted === "accepted");
  }, [accepted]);

  let handleTagSelection = async (node_id: string) => {
   
    setLoading(true);
    // updating the counting
    console.log("=== rejecting para ===",node_id);

    if (node_id) {
    //==== Update Count ======
    let isAnnotated=accept;
      if (!isAnnotated) {
          //==== Update Count ======
          setCount((prev:number)=>prev+1);
       
      }
    
    //==== Update Para as rejected =====

    setParas((prev:any)=>{
        let paras = [...prev];
        paras[index].nextPredicted={
            selection:true,
            issueId:node_id,
        } 
        return paras;
    })
      
      const reject_res = await Axios.post("/annotations/para-action", {
        paraId,
        action: "rejected",
        stage,
        workflowId: currentWorkflow?.workflowId,
        predictedIssueId: node_id

      });
    
    }
    else{
        //==== Update Count ======


    //==== Update Paragraph ======
    setParas((prev:any)=>{
        let paras = [...prev];
        paras[index].nextPredicted={
            selection:false,
            issueId:"",
        } 
        return paras;
    })
    
      
    }

    setLoading(false);


  };

  let dropDownOptions = useMemo(() =>
  {
    if(currentWorkflow?.useNoTag)
    {
      //console.log("=== nextPredicted Issue Id ===",nextPredictedIssueId)
      return [{ value: "No Tag", text: "No Tag", id: "No Tag" }].concat(
       nextClasses.map((nd:any)=>{
        let node:any = nodes.find((node)=>node.node_id === nd[0]);
        let value= node.node_id
        let text=node.node_name
        let id= node.node_id
        return {value,text,id};

       })
      )
    }
    else{
      return  nextClasses.map((nd:any)=>{
        let node:any = nodes.find((node)=>node.node_id === nd[0]);
        let value= node.node_id
        let text=node.node_name
        let id= node.node_id
        return {value,text,id};

       })
  

    }


  },[nodes,nextClasses,currentWorkflow?.useNoTag]
);


  let rejectPara = async() => {
    //TODO if accepted first then rejected then update the completed percentage
    //if(!isActive) return;
   // setLoading(true);
    try {


     

      let nodeId = nextPredictedIssueId;

      //==== Update Count ======
    let isAnnotated=accepted && accepted === "accepted";
    if (isAnnotated) {
        //==== Update Count ======
        setCount((prev:number)=>prev-1);
     
    }


      let node_info = nodes.filter(
        (nodeInfo) => nodeInfo.node_id === nodeId
      )[0];
      
      console.log("updating state");
              setParas((prev:any)=>{
                  let paras = [...prev];
                 
                  paras[index].accepted = "rejected";
                  paras[index].nextPredicted={
                      selected:false,
                      issueId:null
                  }
                  return paras;
              });
      
              const reject_res = await Axios.post("/annotations/para-action",{
                paraId,
                action:"rejected",
                stage,
                workflowId:currentWorkflow?.workflowId,
                predictedIssueId:null
        
              });

//       if (node_info !== undefined) {
//         let node_name = node_info.node_name;
//         let parentInfo = nodes.filter(
//           (nodeInfo) => nodeInfo.node_id === node_info.parent_id
//         )[0];

//         let parent_name = "No Parent Name";

//         // if (parentInfo !== undefined) {
//         //   parent_name = parentInfo.node_name;
//         // }
// //==== Update Para for rejection with no selected info ======
//       }
    } catch (err) {
      toast("There was some error while registering your validation. Please reload the page and try again.", { type: "error" });
      console.log(err)
    }
    finally{
      setLoading(false);
    }
  };
  let acceptPara = async() => {
   // if(!isActive) return;
    if (accept) return;


    setLoading(true)
    
      try {
        
      //==== Accept Para ====== 
      setParas((prev:any)=>{
        let paras = [...prev];
        paras[index].accepted="accepted";
        return paras;
    })
     //==== Update Count ======
     //==== Update Count ======
     let isAnnotated=!accepted || (accepted === "rejected" && !para.nextPredicted.issueId) ;
     if (isAnnotated) {
         //==== Update Count ======
         setCount((prev:number)=>prev+1);
      
     }
 
        

       
       
        await Axios.post("/annotations/para-action",{
          paraId:paraId,
          action:"accepted",
          predictedIssueId:predictedIssueId,
          workflowId:currentWorkflow?.workflowId,
          stage:1
        });
      } catch (err) {
        toast("There was some error while registering your validation. Please reload the page and try again.", {
          type: "error",
        });

      }finally{
        setLoading(false);
      }
    
  };

  const getClassName =(nodeId:string)=>{

    if(currentWorkflow?.useNoTag)
    {
      if(nodeId ==="No Tag") return "No Tag";
    }
 
    return nodes.find((node:any)=>node.node_id === nodeId)?.node_name ||""


  }

  return (
    <div
    ref={divRef}
    onClick={()=>setActivePara(index)}
      onMouseDown={(ev) => {
        //nerStore.setCurrentParaId(paraId);
      }}
      key={paraId}
      className={`w-full flex  duration-200 bg-white border min-h-[8vw]
      scroll-mt-[10vw] scroll-mb-[5vw]
      ${  fade ? "opacity-0" : ""}
      ${isMounted?"":"opacity-0"}
      ${isActive?"border-[#11256d] shadow-lg":"border-transparent"}
      `}
    >
      <div
        className={"w-[78%] bg-white rounded-[0.3vw] py-[1vw] px-[1.5vw] "}
      >
        <div className="flex border-b pb-[0.2vw] justify-between w-full">
      <p className="text-[0.8vw]   pb-1">{para?.paraSeqId} </p>
       <p className="text-[0.8vw]  pb-1">{fileInfo[para?.fileId || ""]?.fileName }</p>
        </div>
        <NerPara 
        key={paraId}
         paraData={para_data} 
         paraId={paraId} 
         expanded={isActive}
         minlength={270}
         />
      </div>

      <div className=" border my-[1vw] border-dashed border-l-1"></div>

      <div className="w-[22%]">

        <ValidationControls
        acceptPara={acceptPara}
        rejectPara={rejectPara}
        handleTagSelection={handleTagSelection}
        loading={loading}
        readOnly={false}
        selectionInfo={para.nextPredicted}
        dropdownOptions={dropDownOptions}
        accepted={accepted}
        node_name={getClassName(para?.stage_info?.predictedIssueId[0])}
        keyboardEnabled={keyboardEnabled || false}
        setKeyboardEnabled={setKeyboardEnabled}
        


        />
      </div>
      
    </div>
  );
};

export default ListViewPara;
