import React, { useState, useEffect } from 'react'
import useWorkflowStore from '../../store/useWorkflowStore';
import { useRecoilValue } from "recoil";
import { nodesAtom } from '../masterlist/atoms';
import Axios from '../../utils/Axios';
import { toast } from 'react-toastify';
import useAuthStore from '../../store/authStore';
import useKeyboard from '../../hooks/useKeyboard';
import ListviewPara from '../data-validation/ListViewPara';
import { Pagination, Popover, Tooltip } from 'antd';
import {useNavigate} from 'react-router-dom';


//Icons
import { ReactComponent as Loading } from '../../assets/loading_blue.svg';
import {ReactComponent as LoadingGreen} from '../../assets/loading_circle.svg';
import { ReactComponent as Refresh } from '../../assets/reload.svg';
import {ReactComponent as MultiUserIcon} from '../../assets/mutiuser.svg';
import {ReactComponent as AchievedTarget} from '../../assets/achieved_target.svg';
import {ReactComponent as TotalTarget} from '../../assets/total_target.svg';
import {ReactComponent as LoadingWhite}  from '../../assets/loading_white.svg';
import NoPara from '../stage-2/NoPara';
import template from '../../template';


const HoverMenu = ({children,text}:any)=>{
  return (

  <Popover
  className='!text-[2vw]'
  placement="top"
  content={text} color="white"
  
  >
      {children}
  </Popover>
  )
}


function Index() {


  const [paras, setParas] = useState<Array<any>>([]);
  const [fileInfo,setFileInfo] = useState<any>({});

  //count states
  const [count, setCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalParas, setTotalParas] = useState<number>(0);

  //Loading States
  const [loading, setLoading] = useState<boolean>(true);
  const [pieLoading, setPieLoading] = useState<boolean>(false);
  const [retrainLoading, setretrainLoading] = useState<boolean>(false);

  //global states
  const projectType:string =useWorkflowStore(state=>state.currentProject)?.projectType || "";
  const workflowType:string =useWorkflowStore(state=>state.currentProject)?.workflowType || "";
  const retrainEndpoint:string = template[projectType][workflowType]?.validation?.endpoints?.initStage2;
  const validationEndpoint:string = template[projectType][workflowType]?.validation.endpoints.validation;


  const navigate = useNavigate();
  const currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
  const userId: string = useAuthStore(state => state.userDetails?.userId) || "";
  const firstName: string = useAuthStore(state => state.userDetails?.firstName) || "";
  const lastName: string = useAuthStore(state => state.userDetails?.lastName) || "";
  const createdBy:string = useWorkflowStore(state=>state.currentProject?.createdBy) || "";
  const isCreater = userId === createdBy;


  //Listview information
  const trainingOffset =currentWorkflow?.listviewInfo.retrainTarget || 45; //200;
  const listviewInfo:any = currentWorkflow?.listviewInfo;
  const [page, setPage] = useState<number>(listviewInfo?.userInfo?listviewInfo?.userInfo[userId]?.lastAccessedPage || 1:1);
  const pageSize = 20;
  const target = (listviewInfo?.lastTrainingCount | 0) +trainingOffset;
  const [isMinThreshold,setIsMinThreshold] = useState<boolean>(false);
  const [allUsersData,setAllUsersData] = useState<any>({});
  const canCallRetrain = totalCount >=target || count>=target;



  //keyboard navigation
  const [activePara, setActivePara] = useState<number>(0);
  const [keyboardEnabled, setKeyboardEnabled] = useState<boolean>(true);
  const ArrowDown = useKeyboard({ keyCode: "ArrowDown" });
  const ArrowUp = useKeyboard({ keyCode: "ArrowUp" });


  const PrevPara = () => {
    if (!keyboardEnabled) return;
    if (ArrowUp && activePara > 0) {
      setActivePara(prev => prev - 1);
    }

  }


  const NextPara = () => {
    if (!keyboardEnabled) return;
    if (paras && activePara < paras.length - 1) {

      setActivePara(prev => prev + 1);
    }
  }
  //Move to Next Paragraph on Arrow Down
  useEffect(() => {
    ArrowDown && NextPara();
  }, [ArrowDown]);

  //Move to previous paragraphs on Arrow Up
  useEffect(() => {
    ArrowUp && PrevPara();

  }, [ArrowUp]);

  // ------ get total annotated paragraphs on all pages from multiuser pie chart -----
  const fetchCountDetails = async (isMounted: boolean) => {


    if (currentWorkflow) {
      try {

        //Set Loading True
        setPieLoading(true)

        let res = await Axios.post("/summary/multiuserpiechart", {
          workflowId: currentWorkflow.workflowId,
          stage: "stage1",
          batchNumber: 1
        })
        //console.log("graph data =>", res.data.data);
        let result = res.data.data;
        setAllUsersData(result);
        let users = Object.keys(result)[0];

        let classes = Object.keys(result[users]).filter((key: string) => key !== "null");

        let total = 0;

        classes.forEach((key: string) => {
          total += result[users][key] || 0;

        })
        
        setTotalCount(total);
        


      } catch {
        toast("Could not get user pie chart data. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setPieLoading(false);
      }

    }

  }

  // ------ fetch paragraphs ------
  // ------ get total paragraphs ----
  const fetchParas = async () => {

    //if (loading || !currentWorkflow) return;
    if(!currentWorkflow) return;
    setLoading(true);
    try {

      let res: any = await Axios.post("/annotations/aw-paras", {
        workflowId: currentWorkflow?.workflowId,
        page: page,
        pageSize: pageSize
      });

      let result: Array<any> = res.data.data.result.paras;
      let total_paras = res.data.data.result.total_paras;
      let count = res.data.data.result.total_count || 0;

      let annotated: number = 0;
      //result.sort((para1:any,para2:any)=>para1.paraSeqId >para2.paraSeqId?1:-1);
      result.forEach((para: any) => {

        let validations: any = para?.stage_info?.validations;
        let userValidation: Array<any> | undefined = validations[userId];
        if (userValidation && userValidation?.length && userValidation[0]?.Action === "accepted") {
          para.accepted = "accepted";
          annotated++;
        }
        else if (userValidation && userValidation[0]?.Action === "rejected") {

          para.accepted = "rejected";
          para.nextPredicted = {}
          para.nextPredicted.selection = false;
          para.nextPredicted.issueId = "";


          //Fill in selected classes Info
          if (userValidation[0] && userValidation[0]?.issueId) {
            annotated++;
            para.nextPredicted.selection = true;
            para.nextPredicted.issueId = validations[userId][0]?.issueId;
          }
        }
        else {
          para.accepted = null;
        }



      });

      //console.log(result);
      setParas(result);
      setTotalParas(total_paras);
      setCount(count);
      setActivePara(0);
    } catch (err) {
      toast("Could not fetch paras", { type: toast.TYPE.ERROR });
     // console.log("Error while fetching paragraphs",err);

    } finally {
      setLoading(false);
    }
  }

  // ------ fetch files ------
  const fetchFiles = async()=>{

    try{

      let files:any = await Axios.post("/file/getAllUploadedFile",{
        workflowId:currentWorkflow?.workflowId
      });

      const file_info = files.data.data;
      

      let file_state:any = {}
      file_info.forEach((file:any)=>{
        let object:any ={}
        object.fileId = file.fileId;
        let fileName:string = file.fileName || "";
        fileName = fileName.slice(13);
        object.fileName = fileName;
        
        file_state[file.fileId] = object;
      });
      setFileInfo(file_state);
      console.log(file_state);


    }catch(err){
      toast("There was an error while fetching file information.Please reload the page and try again",
      {
        type:toast.TYPE.ERROR
      })
    }

  }

  // -------- check if atleast 2 classes have paras annotated -------

  const checkConstraints = ()=>{
    let classThreshold:any ={};
    paras?.forEach((para:any)=>{

      if(para.accepted === "accepted" && para?.stage_info?.predictedIssueId[0]!=="No Tag"){
        let node_id:string = para?.stage_info?.predictedIssueId[0];
        
        if(classThreshold[node_id]){
          classThreshold[node_id]++;
        }
        else{
          classThreshold[node_id] =1;
        }

      }else if(para.accepted === "rejected"){
        let node_id = para.nextPredicted?.issueId;
          if(para.nextPredicted.selection && node_id!=="No Tag")
          {
            let node_id = para.nextPredicted.issueId;
            if(classThreshold[node_id]){
              classThreshold[node_id]++;
            }
            else{
              classThreshold[node_id] =1;
            }

          }
      }
    });
   // console.log("classes info",classThreshold);

    let result = {...allUsersData};
    let users = Object.keys(result)[0];

    let classes = Object.keys(result[users]).filter((key: string) => key !== "null" && key!=="No Tag");

    //console.log("classes from all users data",classes);

    if(Object.keys(classThreshold).length<2 && classes.length<2)
    {
      return false;
    }

    return true;


  }

  // ------ call retrain api ------
  const triggerRetrainApi =async()=>{
    if(!currentWorkflow || retrainLoading) return;
   // toast("calling")

    setretrainLoading(true);
    const canRetrain =  checkConstraints();

    if(!canRetrain)
    {
      
      setretrainLoading(false);
      toast("Please annotate some paragraphs for atleast 2 model classes and try again!",
      {type:toast.TYPE.WARNING});
      return;
    }
   
   
    
  
    try{
     await Axios.post(`/integration/${retrainEndpoint}`,{
        workflowId:currentWorkflow.workflowId,
      });

    }catch{

    }finally{
      setretrainLoading(false);
    }


  }

  //----------------------------------------------------
  //Unlock Masterlist & Redirect User To Masterlist Page
  //----------------------------------------------------
  let resetStage1 = async () => {

    //--- Make a request to reset Stage 1 here ---

    Axios.post("/masterlist/markLocked", {
      workflowId: currentWorkflow?.workflowId,
      markAs: false,
      user: userId
    });

    navigate("/workflow/masterlist?workflowId=" + currentWorkflow?.workflowId);
  };

  
  //----------------------------------------------------
  //Fetch paragraphs on component load or refresh
  //----------------------------------------------------
  useEffect(() => {

    let p1 = fetchParas();
    let p2 = fetchCountDetails(true);
    fetchFiles();
    Promise.all([p1, p2]);
    //window.addEventListener("scroll", onScroll);

    // return () => window.removeEventListener("scroll", onScroll);




  }, [])

  //----------------------------------------------------
  //Fetch paragraphs on page number change
  //----------------------------------------------------
  useEffect(() => {
    if(!loading)
    {

      fetchParas();
    }
  }, [page]);

  
  //----------------------------------------------------
  //notify user that they can call retrain api
  //----------------------------------------------------
  useEffect(()=>{
    if(createdBy === userId && canCallRetrain)
    {
        
        toast("You can now retrain the model",{type:toast.TYPE.SUCCESS});
    }



  },[canCallRetrain]);





  if(!loading && (totalParas===0 ))
  {
    return (
      <div className="uppercase w-full min-h-[25vw] flex justify-center px-[1.5vw] py-[0.6vw] text-[1.3vw] flex-col items-center text-center">
      You don't have enough data for annotation. Please provide
      relevant keywords for the nodes. <br />
      <button
        onClick={() => {
          resetStage1();
        }}
        className="uppercase bg-primaryBlue text-white px-[1vw] py-[0.7vw] mt-[1.5vw] text-[1.1vw] rounded-[0.5vw] shadow-[0.2vw]"
      >
        Go to masterlist
      </button>
    </div>
    )
  }


  return (
    <div>
      <div className={`z-[50]  w-full  bg-[#fafafa] sticky top-[10vh]
                      text-[1.2vw] text-[#7a7a7a]  p-2 
                      flex justify-between items-center  border-b flex-1`}>
        <p>Suggested Paragraphs</p>
        <div className=' flex space-x-[1vw] items-center'>
          <div className='flex-1 flex items-center space-x-[0.2vw]'>
            <HoverMenu text={`Annotated by ${firstName} ${lastName}`}>

           <div><AchievedTarget className=' w-[1.8vw] h-[1.8vw]'/></div>
            </HoverMenu>
            <p className='text-[#1BA94C] select-none'>{count}</p>
          </div>
          <div className='flex-1 flex items-center space-x-[0.3vw]'>
            <div>
             <HoverMenu text="Annotated by all users">

              <MultiUserIcon className=' w-[2vw] h-[2vw]' />
             </HoverMenu>

             
            </div>
            

            <p className='text-[#1BA94C] select-none'>{totalCount}</p>
           
            </div>
            <div className='flex-1 flex items-center space-x-[0.3vw]'>
            <div>
             <HoverMenu text="Target for retrain">

              <TotalTarget className=' w-[3.5vw] h-[2vw]' />
              
             </HoverMenu>

             
            </div>
            

            <p className='text-[#1BA94C] select-none'>{target}</p>
           
            </div>
            <div>
              
              </div>

            <div className="flex-1 flex justify-center">
          {pieLoading ? (
            <Loading className=' w-[1.8vw] h-[1.8vw] ' />
          ) :( <HoverMenu text="Refresh all user's data">

            <Refresh className=' w-[1.8vw] h-[1.8vw] cursor-pointer hover:scale-110 duration-200'  
            
            onClick={() => !pieLoading && fetchCountDetails(false)} />
          </HoverMenu>)}
            </div>
           
          
        </div>
      </div>

      {
        loading ? (
          <div className='h-[65vh] w-full flex items-center justify-center'>
            <Loading className='w-[6vw] h-[6vw]' />
          </div>
        ) :
        paras?.length>0?(
          <div className='mt-[1vw] '>



            <div className={`flex flex-col space-y-[1vw] `}>
              {
                paras?.map((para: any, index: number) => {
                  return <ListviewPara

                    index={index}
                    para={para}
                    activePara={activePara}
                    next={NextPara}
                    prev={PrevPara}
                    setActivePara={setActivePara}
                    key={para.paraId}

                    accepted={para.accepted}

                    para_data={para.paraData}
                    time_str={""}
                    paraId={para.paraId}
                    stage={1}
                    predictedIssueId={
                      para?.stage_info?.predictedIssueId[0]

                    }
                    nextPredictedIssueId={
                      para?.stage_info?.predictedIssueId[0]
                    }
                    batchId={para.batchId}

                    //keyboard toggle
                    keyboardEnabled={keyboardEnabled}
                    setKeyboardEnabled={setKeyboardEnabled}

                    //update state
                    setParas={setParas}
                    setCount={setCount}
                    

                    //fileInfo
                    fileInfo={fileInfo}
                  />
                })
              }
            </div>
          </div>
        ):<div className='flex items-center justify-center
        h-[28vw] text-gray-400 text-[1.5vw] 
        
        '>
          No more paragraphs available for this class

        </div>
      }

      <div className='flex p-2 justify-between items-center 
      w-full h-[4vw] border-t pt-[2vw] my-[1vw] 
      '>
      {isCreater ?  <div className='flex items-center'>
        <button
          disabled={!canCallRetrain}
          title="Complete the target to enable retraining"
          onClick={()=>!retrainLoading && canCallRetrain && triggerRetrainApi()}
          className={`uppercase duration-200  w-[13vw]
                text-white text-[0.9vw] 
                 h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
                 disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
                border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md   `}
        >
          {retrainLoading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                          : "Train & Predict"}
        </button>
        <button
          onClick={()=>navigate(`/workflow/validation-summary?workflowId=${currentWorkflow?.workflowId}`)}
          className={`uppercase duration-200  w-[13vw]
                text-white text-[0.9vw] 
                 h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
                 disabled:opacity-75 disabled:cursor-not-allowed
                border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md   `}
        >Validation Summary
        </button>
        
        </div>
        :<div></div>}


        <Pagination className='bg-white p-[0.6vw] text-[1vw]'
          total={totalParas}
          showQuickJumper
          showSizeChanger={false}
          current={page}
          pageSize={pageSize}
          onChange={(page) => {
          !loading && setPage(page)

          }} />
      </div>

    </div>
  )
}

export default Index