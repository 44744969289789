import React from 'react';
import Table from './Table';

const AM:React.FC = ()=>{
    return (
        <div>
            <Table />
        </div>
      )
}


export default AM;