import { Popover } from 'antd'
import React from 'react'
import {IoMdInformationCircleOutline} from 'react-icons/io'

const Message = ({message}:{message:string})=>{
    return (<p className='w-[35vw] text-[1vw] p-[0.2vw]' >
        {message}.
    </p>)
}

function IICon({message}:{message:string}) {
  return (
    <div>
        <Popover
        
        content={<Message message={message} />}
        >
        <IoMdInformationCircleOutline size={"1.7vw"} cursor={"pointer"} color={"#11256D"}/>
        </Popover>
    </div>
  )
}

export default IICon