import create from 'zustand';


interface PermissionsType {
    canCreateUsers: boolean,
    canCreateProjects: boolean,
    canCreateWorkflows: boolean,
    canCreateNodes: boolean,
    canUpload: boolean
}

interface PermissionsStoreType {
    permissions: PermissionsType,
    setPermissions: (permissions: PermissionsType) => void
}

let usePermissionStore = create<PermissionsStoreType>(
    (set) => ({
        permissions: {
            canCreateUsers: false,
            canCreateProjects: false,
            canCreateWorkflows: false,
            canCreateNodes: false,
            canUpload: false
        },
        setPermissions: (permissions: PermissionsType) => set({permissions})
    }),
);

export default usePermissionStore;