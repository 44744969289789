import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import useWorkflowStore from "../store/useWorkflowStore";
import Axios from "../utils/Axios";
import useMasterlist from "./masterlist/hooks/useMasterlist";
import { useSetRecoilState, useRecoilState } from 'recoil';
import { layersAtom } from "./masterlist/atoms";
import { masterlistIdAtom } from "./masterlist/atoms";
import { nodesAtom } from "./masterlist/atoms";
import { calculateFirstLayer, fetchMasterlist, injestProperties } from "./masterlist/utils/helper";
import useAuthStore from "../store/authStore";
import { SocketContext } from '../contexts/socket';
import VersionProcess from "./VersionProcess";
import AppLayout from "./layouts/AppLayout";
import PageLayout from './layouts/PageLayout';
import useNotifications from '../store/useNotifications';
import { toast } from "react-toastify";

import useAdvancedMasterlist,{seedInitialSuggestions,removeWordFromSelected} from "../store/useAdvancedMasterlist";
import useMasterlistSummary from "../store/useMasterlistSummary";
import template from "../template";
import { getEntity } from "./ner-components/masterlist/_apiCalls";
import useEntityStore from "../store/entityStore";
/**
 * A small note on how realtime data streaming from mongodb to client works
 *
 * 1. First users comes on the website that sends a "connection" event to the backend (check backend for refrence)
 * 2. That get's saved a connections array
 * 3. Then user sends a subscription request that get's stored in subscriptions array along with socket id
 * 4. Then we detect changes in the database through mongodb change stream and sends that data to all the
 *    people who have subscribed for that event.
 * 5. We recied the event in the frontend and do whatever we want with the data
 */

const WorkflowChangeEventName = "workflow_change";
const roomEventTest = "room_event_test";
const joinRoomEventName = "join_room";
const leaveRoomEventName = "leave_room";
const errorEvent  ="error";



let WorkflowRoutingComp: React.FC = () => {
    let [searchParams] = useSearchParams();
    let workflowStore = useWorkflowStore();
    let navigator = useNavigate();
    let workflowId = searchParams.get("workflowId");
    let { loading, data, error } = useMasterlist();
    let [layers, setLayers] = useRecoilState(layersAtom);
    let setMasterlist = useSetRecoilState(masterlistIdAtom);
    let [nodes, setNodes] = useRecoilState(nodesAtom);
    let [loadingWorkflow, setLoadingWorkflow] = useState(false);
    let socket = useContext(SocketContext);
    let useDetails = useAuthStore(state => state.userDetails);
    let setFetchSummary = useMasterlistSummary(state=>state.setFetchSummary);
    const setEntities = useEntityStore(state=>state.setEntities);
   
    const workflow = workflowStore.currentWorkflow;

    
    const project = workflowStore.currentProject;

    const isCreater = project?.createdBy === useDetails?.userId; 

    const {keywords,folders,setKeywords,selectedWords, setFolders, setSelectedWords} = useAdvancedMasterlist(state=>({
        keywords:state.keywords,
        setKeywords:state.setKeywords,
        setRecentFolders:state.setRecentFolders,
        folders:state.folders,
        setFolders:state.setFolders,
        selectedWords:state.selectedWords,
        setSelectedWords:state.setSelectedWords
    }));


    let fetchWorkflow = async () => {
        setLoadingWorkflow(true)
        //leave the room before fetching new one
        if (workflowStore.currentWorkflow) {
            let roomId = workflowStore.currentWorkflow?._id;

            //console.log.log("=== Leaving the room ===",roomId);
            socket?.emit(leaveRoomEventName, roomId);
        }
        if (workflowId) {
            try{
                let workflow_res = await Axios.get(`/workflow/getUserWorkflow/${workflowId}`);
                //console.log.log("workflow Info on render",workflow_res.data.data)
    
                // let workflow = workflow_res.data.data;
    
                let workflow = workflow_res.data.data.workflow;
                let project = workflow_res.data.data.project;
                let data = workflow_res.data.data;
                delete data.workflow;
                delete data.project;
                let userProjectInfo = data;
    
    
    
                if (workflow ) {
                    socket?.emit(WorkflowChangeEventName, {
                        workflowDocId: workflow._id,
                    });
    
                    workflowStore.setCurrentWorkflow(workflow);
                    workflowStore.setCurrentProject({ ...project, workflowDetails: data.workflowDetails });
                    workflowStore.setUserProject(userProjectInfo.userProject);
                    
                    //set Entities if present
                    if(template[project?.projectType]?.hasEntity){
                        //fetch entities
                      const entities = await getEntity(workflow.workflowId);
                      setEntities(entities);
                    }
    
                    // Setting selected words
                    let userProject: any = userProjectInfo.userProject;
                    if (userProject?.wishListInfo) {
                        if (userProject?.wishListInfo[`${workflow.workflowId}`]) {
                            setFolders(userProject?.wishListInfo[`${workflow.workflowId}`].folders);
                
                            let arrSelected:any = [];
                            let objSelected = userProject?.wishListInfo[`${workflow.workflowId}`].keywords;
                            
                            Object.entries(objSelected).forEach((item: any) => {
                                let sel = {
                                    word: item[0],
                                    folder: item[1].folder
                                };
                                arrSelected.push(sel);
                            })
                            console.log("Setting words from user project");
                            
                            setSelectedWords(arrSelected);
                        }
                    } else {
                        setFolders({});
                        setKeywords([]);
                        setSelectedWords([]);
                    }

                    
                    setLoadingWorkflow(false)
                } else {
                    setLoadingWorkflow(false)
                    navigator("/workflow/invalid_workflow");
                }
                
            }catch{
                setLoadingWorkflow(false)
                    navigator("/workflow/invalid_workflow");
                
            }
          
        } else {
            setLoadingWorkflow(false)
            navigator("/workflow/invalid_workflow");
        }

    };
    



    //---------------------------------------------------------------------
    // Fetch Workflow On First Render
    //---------------------------------------------------------------------
    useEffect(() => { 
        
        fetchWorkflow();


        let workflow: any;
        socket?.on(roomEventTest, async () => {

            let workflow_res: any = await Axios.get(`/workflow/getWorkflow/${workflowId}`);
            workflow = workflow_res.data.data;
            workflowStore.setCurrentWorkflow(workflow);            
        });

     
       

        return () => {
            let roomId = workflowStore.currentWorkflow?._id;
            socket?.emit(leaveRoomEventName, roomId);
            socket?.off(roomEventTest);
            socket?.off(errorEvent);
        
            //Reset Masterlist On unmount
            setMasterlist(null);
            setLayers([calculateFirstLayer([])]);
            setNodes([]);
            setFetchSummary(true);
        };
    }, [workflowId]);


    //---------------------------------------------------------------------
    // On First Mount:subscribe to workflow change event and fetch workflow
    //---------------------------------------------------------------------
    useEffect(()=>{

        const getWorkflow= async()=>{
           // console.log("=== Subscribing To Current Workflow ===");
            if(workflow)
            {
    
                let workflow_res: any = await Axios.get(`/workflow/getWorkflow/${workflowId}`);
                let workflow = workflow_res.data.data;
                workflowStore.setCurrentWorkflow(workflow);
                socket?.emit(WorkflowChangeEventName, {
                    workflowDocId: workflow?._id,
                });
            }

        }

       getWorkflow();
    },[]);


    //---------------------------------------------------------------------
    // On Reconnect: subscribe to workflow change event and fetch workflow
    //---------------------------------------------------------------------
    useEffect(()=>{

        const getWorkflow= async()=>{
          
            if(workflow && workflowStore.isConnected)
            {
                console.log("=== Fetch Workflow On Connection  ===");
                let workflow_res: any = await Axios.get(`/workflow/getWorkflow/${workflowId}`);
                let workflow = workflow_res.data.data;
                workflowStore.setCurrentWorkflow(workflow);

                socket?.emit(WorkflowChangeEventName, {
                    workflowDocId: workflow?._id,
                });
              
            }

        }

       getWorkflow();
    },[workflowStore.isConnected]);

    
    //---------------------------------------------------------------------
    // Fetch And Configure MasterList On Load
    //---------------------------------------------------------------------
    useEffect(() => {

       // console.log("=== check masterlist useffect ===", loading, error, data)
       
        
        if (!loading && !error && data) {
           // console.log("=== setting masterlist ===",data);
            
            let injestedNodes = injestProperties(data?.nodes);
            setMasterlist(data);
            setLayers([calculateFirstLayer(injestedNodes)]);
            setNodes(injestedNodes);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data,loading,error]);


     //---------------------------------------------------------------------
    // Update Masterlist when masterlist is locked
    //---------------------------------------------------------------------
    useEffect(()=>{
      

        const updateMasterlist = async()=>{
            const projectType = project?.projectType ;
            if(!projectType) return;
            if(!template[projectType]?.hasMasterlist) return ;
           
            if(!nodes.length) return;
    
            try{
                const data:any =await Axios.post("/masterlist/getMasterlist",{
                    workflowId:workflowId
                });
                let injestedNodes = injestProperties(data?.data?.data?.nodes);
                setMasterlist(data?.data?.data);
                setLayers([calculateFirstLayer(injestedNodes)]);
                setNodes(injestedNodes);

                console.log("=== got masterlist ===",data?.data);



            }catch{
                if(!template[projectType]?.hasMasterlist) return ;
                console.log("=== inside the error ===")
                toast.error("Could not fetch masterlist, please reload the page and try again!");
            }
        }


        if(workflow?.masterlistLocked && !isCreater)
        {
            updateMasterlist();
        }

    },[workflow?.masterlistLocked])

    
    
    
    return (
        <div>
            {
                loadingWorkflow ?
                    <div className="h-screen w-screen text-center flex items-center justify-center  ">
                        <div className=" fixed bg-gray-300 opacity-10  w-full text-center items-center justify-center"></div>
                        <div>
                            <h1 className="text-[1.5vw] text-gray-500 mb-[1.5vw]">Workflow Loading...</h1>
                            <img className="w-[20vw] animate animate-spin" src="/images/loading_circle.svg" alt="some loading screen" />
                        </div>
                    </div>
                    :

                    (workflow?.forkInfo?.forkInProcess || false) ?
                        <AppLayout>
                            <PageLayout
                                pageTitle={`${project?.name} (${workflow?.workflowName})`}
                                pageSubHeading="Dashboard"
                                pageIconUrl="/images/active_dashboard.svg"
                            >
                                <VersionProcess
                                    selectedStages={workflow?.forkInfo?.selectedStages || {}}
                                    forkInProcess={workflow?.forkInfo?.forkInProcess || false}
                                    uploadCompleted={workflow?.forkInfo?.upload || false}
                                    masterlistCompleted={workflow?.forkInfo?.masterlist || false}
                                    validateCompleted={workflow?.forkInfo?.validate || false}
                                    trainCompleted={workflow?.forkInfo?.train || false}
                                    inProgress={workflow?.forkInfo?.inProgress || "NONE"}
                                />
                            </PageLayout>
                        </AppLayout>
                        :

                        <Outlet />


            }

        </div >
    );
};

export default WorkflowRoutingComp;
