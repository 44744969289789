import { combineReducers } from "redux";
import Stage1Reducer, { Stage1ReducerType } from "./stage1Reducer";
import Stage2Reducer, { Stage2ReducerType } from "./stage2Reducer";
import FeedbackReducer, { feedbackReducerType } from "./feedback";

export interface GlobalState {
  //practiceArea: PracticeAreaState;
  stage1: Stage1ReducerType;
  stage2: Stage2ReducerType;
  feedback: feedbackReducerType;
}

let all_reducers = combineReducers<GlobalState>({
  //practiceArea: PracticeAreaReducer,
  stage1: Stage1Reducer,
  stage2: Stage2Reducer,
  feedback: FeedbackReducer,
});

export default all_reducers;
