

import { Divider, Popover, Pagination } from "antd";
import Switch from '../../components/data-validation/Switch';
import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import Stage2Node from "../../components/data-validation/stage2/Stage2NodeV2";
import StagePara from "../../components/data-validation/stage2/Stage2ParaV2";
import { BulkValidateParas, NavigateToTrainStage, getNextPredictedIssueId } from './helper';


import { useNavigate, Link } from 'react-router-dom';
import {
  ParaDataType,
  StageNodeType,
} from "../../store/reducers/stage1Reducer";
import { saveAs } from 'file-saver'

import LoadingScreen from "../../components/loading-screen";
import SeedPara from "../../components/data-validation/SeedPara";
import Axios from '../../utils/Axios';

import { toast } from "react-toastify";
import { getNodeInfo } from "../../components/masterlist/utils/helper";
import NoPara from "./NoPara";

//Stores
import useWorkflowStore from '../../store/useWorkflowStore';
import useAuthStore from '../../store/authStore';
import authStore from '../../store/authStore';
import { useRecoilState, useRecoilValue } from "recoil";
import { nodesAtom } from "../../components/masterlist/atoms";

//Icons
import { ReactComponent as SeedIcon } from '../../assets/seed_icon.svg';
import { ReactComponent as Loading } from '../../assets/loading_blue.svg';
import { ReactComponent as Refresh } from '../../assets/reload.svg';

import useKeyboard from "../../hooks/useKeyboard";

import checkForCriticalProgress from "../../lib/checkForProgress";
import Counter from "./Counter";
import template from "../../template";
import { TrainStatus, checkCurrentTrainStatus } from "../../utils/navigationHelper";


export interface AnnotationCount {
  node_id: string;
  count: number;
}

export interface NodeReport {
  node_name: string;
  validatedParas: number;
  color: string;
  targetParas: number;
  nodeId: string;
  _id: string;
}

export interface ModelClasses {
  modeClassName: string;
  modelClassId: string;
  nodes: Array<NodeReport>;
}

export interface Stage2Props { }

const Stage2: React.FC = () => {

  let navigate = useNavigate()
  let [active_node, setActiveNode] = React.useState<StageNodeType | undefined>();
  let [loading, setLoading] = useState(false);
  let [seedPara, setSeedPara] = useState<boolean>(false);
  let [batch, setBatch] = useState<number>();
  let [current_paras, setCurrent_paras] = useState<Array<ParaDataType> | null>(
    null
  );
  //const [nodeDelta,setNodeDelta] = useState<any>([]);

 // const [loadingCount, setLoadingCount] = useState<boolean>(false);

  let currentWorkflow = useWorkflowStore(store => store.currentWorkflow);
  let currentBatchNumber = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchNumber;
  let currentBatchProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress || 0;
  let currentProject = useWorkflowStore(store => store.currentProject);
  let userDetails = authStore(state => state.userDetails);
  const currentUser = useAuthStore(state => state.userDetails);

  const lastUpdatedKey = currentWorkflow?.alpdsInfo?.stage2ClassificationReports?.lastUpdatedKey;
  const stage2AccuracyProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2AccuracyProgress || null;
  // const stage2AccuracyReport =lastUpdatedKey ?( currentWorkflow?.alpdsInfo?.stage2ClassificationReports?.stage2AccuracyReport ||
  //                                 currentWorkflow?.alpdsInfo?.stage2ClassificationReports[lastUpdatedKey]):null;
  const stage2AccuracyReport =lastUpdatedKey ?currentWorkflow?.alpdsInfo?.stage2ClassificationReports[lastUpdatedKey]:null;
                              


  // === In Component State ===
  let [stageParas, setStageParas] = useState<Array<any>>();
  let [stageNodes, setStageNodes] = useState<Array<any>>();
  let [stageRejectedParas, setStageRejectedParas] = useState<Array<any>>([]);

  const [stageNodeProgress,setStageNodeProgress] = useState<any>({});


  let [popOpen, setPopOpen] = useState<boolean>(false);
  //let [canClickAutoML, setCanClickAutoML] = useState<boolean>(false);
  let [page, setPage] = useState<number>(currentBatchNumber);
  let [paraEmpty, setParaEmpty] = useState<boolean>(false);
  let [fetching, setFetching] = useState(false);
  //let [achievedTarget, setAchievedTarget] = useState();
  const stageParaRef: any = useRef(null);
  const [activePara, setActivePara] = useState<number>(0);
  const [counts, setCounts] = useState<AnnotationCount[]>([]);
  const [canTriggerAutoML,setCanTriggerAutoML] = useState<boolean>(false);
  const [loadMoreLoading,setLoadMoreLoading] = useState<boolean>(false);

  // ===END OF In Component State ===



  //Keyboard shortcut
  const [keyboardEnabled, setKeyboardEnabled] = useState<boolean>(true);
  const ArrowDown = useKeyboard({ keyCode: "ArrowDown" });
  const ArrowUp = useKeyboard({ keyCode: "ArrowUp" });

  //get links from template data
  const projectType:string = currentProject?.projectType || "";
  const workflowType:string = currentProject?.workflowType || "";
  const autmlEndpoint:string = template[projectType][workflowType]?.stage2?.endpoints?.automl; 

  //keyboard navigation
  const PrevPara = () => {
    if (!keyboardEnabled) return;
    if (ArrowUp && activePara > 0) {
      setActivePara(prev => prev - 1);
    }

  }

  const NextPara = () => {
    if (!keyboardEnabled) return;
    if (current_paras && activePara < current_paras.length - 1) {

      setActivePara(prev => prev + 1);
    }
  }

  useEffect(() => {
    ArrowDown && NextPara();
  }, [ArrowDown]);

  useEffect(() => {
    ArrowUp && PrevPara();

  }, [ArrowUp]);


  //Check if paragraph is read only
  const CheckParaReadOnly=(p:any):boolean=>{

    const currentStatus = checkCurrentTrainStatus(currentWorkflow);
    const isPrevBatch = page !== getBatchNumber(); 
    //console.log("current batch && batch",currentBatch,batch,p);

    if(currentStatus ===  TrainStatus.NotTrained
       || currentStatus === TrainStatus.InIterate)
    {
      console.log("workflow is in iterate or not trained");
      return isPrevBatch && p?.result && p?.result?.length>0;
    }

    return true;
  }


  //Update AutoValidate
  const updateAutoValidate = async (value: boolean) => {

   
    //if value is fals  e show message that the changes will take place in the next batch
    if (!value) {
      toast("Auto Validation will get disabled from next batch onwards.", { type: toast.TYPE.INFO })
    }else{
      toast("Auto Validation will get enabled from next batch onwards.", { type: toast.TYPE.INFO })
    }
    



    //initiate delta count for stageParas
    // let delta:any = {};  
    // if (value) {
    //   // find all paras which are not accepted and have autovalidated true

    //   let paraIds: Array<string> = [];
    //   let paraDatas: Array<any> = [];
    //   stageParas?.forEach((node: any) => {
    //     // console.log("Stage Node",node);
    //     node.data.forEach((para: any) => {
    //       delta[node.node_id] =0;
    //       if (!para.accepted && para.autoValidated === true && para.batchId === getBatchNumber()) {

    //         paraIds.push(para?.para_info?.paraId);
    //         paraDatas.push({
    //           paraId: para?.para_info?.paraId,
    //           nodeId: node.node_id
    //         });
    //       }
    //     });

    //   });

    //   // console.log("=== to be autoValidated Paras ===",paraIds);



    //   // update local state for those paras
    //   // start of O(n^3) loop
    //   //console.log("delta",delta);

    //   if (paraDatas.length) {

    //     let tempState: any;
    //     paraDatas.forEach((paraData: any) => {


    //       let paras = stageParas && stageParas.map((para: any) => {
    //         if (para.node_id === paraData.nodeId) {
    //           // console.log("=== Paragraph to autoAnnotate ===",para.data[0])
    //           let data = para.data.map((p: any) => {
    //             if (p.para_info.paraId === paraData.paraId) {
    //               //console.log("=== autovalidated para ===",p,para)
    //               p.accepted = "accepted";
    //               delta[para.node_id]++;

    //             }
    //             return p;
    //           });
    //           para.data = data;


    //         }

    //         return para;
    //       });
          



    //       tempState = paras;


    //     });

    //     //console.timeEnd("timer");

    //     // update stage count for every class

    //     let temp:any = {...stageNodeProgress}
    //     Object.keys(delta).forEach((key:string)=>{

    //       //console.log("class id",temp[key],delta[key]);

    //       temp[key].validatedParas = (temp[key]?.validatedParas || 0) + delta[key];


    //     })



    //     setStageParas(tempState);
    //     setStageNodeProgress(temp);
    //     // End of O(n^3) loop



    //     // call bulk validate api to make all those paras validate
    //     await BulkValidateParas(paraIds,currentWorkflow?.workflowId || "");
    //     paraIds = [];
    //     paraDatas = [];
    //     tempState = [];

    //   }

    //   else {
    //     toast("There are no paragraphs eligible for Auto Validation in this batch!", { type: toast.TYPE.WARNING });
    //   }



    // }


    //finally update workflow for autovalidate change if everything goes right
    try {
      await Axios.post("/workflow/updateAutovalidate", {
        workflowId: currentWorkflow?.workflowId,
        userId: userDetails?.userId,
        autoValidation: value
      });
    }
    catch {
      toast("Could Not Enable AutoValidation!!");

    }
    return;

  }


  // let canTriggerAutoML = () => {

  //   if (currentWorkflow?.alpdsInfo.stage2ClassificationReports[`batch${currentBatchNumber}`]) {
  //     let batchInfo = currentWorkflow?.alpdsInfo.stage2ClassificationReports[`batch${currentBatchNumber}`];
  //     let count = 0;



  //     stageParas?.forEach((node) => {
  //       //console.log("=== node in stage paras ===",node);
  //       //console.log("=== length of validated paras ===",node.data?.filter((para:any)=>para.accepted).length);
  //     });


  //     if (count >= 2) setCanClickAutoML(true);
  //     else setCanClickAutoML(false);
  //     //console.log("=== Can Trigger AutoML set to ===",canClickAutoML);

  //   }

  // }




  //function to count total paras for a class
  function ValidationCounter() {
    let acceptedParas = 0;
    let rejectedParas = 0;

    stageParas?.forEach((node: any) => node?.data?.forEach((para: any) => {

      if (para.batchId === getBatchNumber()) {
        if (para.accepted === "accepted" && para.predictedIssueId[0] === active_node?.node_id) acceptedParas++;
        if (para.accepted === "rejected" && para.nextPredicted?.issueId === active_node?.node_id) rejectedParas++;
      }

    }))

    //console.log("===accepted paras ===",acceptedParas);
    // console.log("===rejected paras ===",rejectedParas);


    const dsInfoParas = currentWorkflow?.alpdsInfo?.stage2ClassificationReports["batch" + (currentBatchNumber - 1 >= 0 ? currentBatchNumber - 1 : 0)][active_node?.node_id || ""]?.validatedParas || 0
    let activeNodeCount = acceptedParas + rejectedParas + dsInfoParas;
    // console.log("====active node count",activeNodeCount);
    return activeNodeCount;
  }

  useEffect(()=>{
    let temp:boolean = true;
    let count =0;
    let noTagCount=0;
   // console.log(stageNodeProgress);

    if(!currentWorkflow?.useNoTag){
      noTagCount =1;
    }

    Object.keys(stageNodeProgress).forEach((key)=>{

        if(key!=="No Tag" && stageNodeProgress[key].validatedParas>=21){
          count++;
        }

        if(key === "No Tag" && stageNodeProgress[key].validatedParas>=21 ){
          noTagCount =1;
        }

    });
    if(count<2 || noTagCount <1 ){
      //toast.warn("target not completed"+count+noTagCount);
      temp = false;
    };

    if(count>=2 && noTagCount >=1 ){
      toast.success("You can now train the model");
    };

    

    setCanTriggerAutoML(temp);

  },[stageNodeProgress]);


  //Update Annotation Counter
  useEffect(() => {
    try {


    // let activeNodeCount = ValidationCounter();

    //  setBatchAnnoProgress(activeNodeCount);

    } catch (err) {
     // console.log("=== Error While Counting Paras  ===")
    }



  }, [active_node, stageParas, stageRejectedParas, stageNodes]);

 



  const getBatchNumber = (): number => {
    return currentBatchNumber;
  }


  let nodesComplete: any = [];
  // Show Mesage that Creator has moved to next batch
  useEffect(() => {

    if (userDetails?.userId !== currentProject?.createdBy && currentBatchNumber > 1 && currentBatchProgress < 100) {


      setPopOpen(true);
    }
    else {
      setPopOpen(false);
    }


  }, [currentBatchNumber, currentBatchProgress]);


  // === Calcuate Completed Percentage ===
  const updateStageNodeProgress = (data: any) => {


    setStageNodes((prevNodes: any) => {

      let newNodes = prevNodes.map((node: any) => {
        if (node.node_id === data.node_id) {
          node.completed_percentage = data.completed;
          node.feedback = data.feedback;
          if (data.goal) {
            node.goal = data.goal;
          }
        }

        return node;
      })

      // console.log("Progress Updated",newNodes);
      return newNodes;
    });
  }

  // let [summaryReport, setSummaryReport] = useState<Array<ModelClasses>>([]);
  // let nodesInfo = useRecoilValue(nodesAtom);

  const updatePara = (i: number) => {
    //console.log(activePara);
    setActivePara((prev) => prev + i);
  }

  let inputManager = (e: any) => {
    //console.log(e.key)
    switch (e.key) {
      case "ArrowDown":
        // console.log(activePara);
        updatePara(1);
        break;
      case "ArrowUp":
        //console.log(activePara);
        if (activePara > 0) updatePara(-1);
        break;
    }

  }
  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });



    return () => window.removeEventListener("keydown", inputManager, false);



  }, []);

  let backToTop = () => {
    let ele = document.getElementById("stage_2_paras");
    ele?.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setActivePara(0);
    backToTop();
  }, [active_node, batch, page]);



  let [isAllowed, setIsAllowed] = useState<boolean>(false);

  useEffect(() => {
    let b: boolean = checkConstarinsts();
    // console.log("=== After Check Constrains ===",b)
    setIsAllowed(b);
  }, [stageParas, stageRejectedParas, stageNodes]);


  let nodes = useRecoilValue(nodesAtom);






  let [isAutoMlClicked, setAutoMlClicked] = useState(false);

  let checkConstarinsts = (): boolean => {

    let canMoveToNextBatch = false;

    stageParas?.forEach((node: any) => {
      let batchParas = node.data.filter((p: any) => p.batchId === getBatchNumber());
      // console.log("--- checking for batch paras length ---",batchParas);
      if (batchParas.length > 0) {
        canMoveToNextBatch = true;
      }

    });

    return canMoveToNextBatch;


  };


  //---------------------------------------------
  // Function to sort paras by para sequence
  //---------------------------------------------
  let compare = (a: any, b: any) => {
    let paraSecId1 = a.para_info.paraSeqId;
    let paraSecId2 = b.para_info.paraSeqId;
    if (paraSecId1 < paraSecId2) return -1;
    else if (paraSecId1 > paraSecId2) return 1;
    return 0;
  };


  //-------------------------------------
  // If there are no paras in the class 
  //-------------------------------------

  useEffect(() => {
    stageParas?.forEach((para: any) => {
      let { node_id, data } = para;
      if (active_node?.node_id === node_id) {
        let paraNo = data.filter((para: any) => para.batchId === batch);

        if (paraNo.length === 0) {
          setParaEmpty(true);
        } else {
          setParaEmpty(false);
        }
      }
    });
  }, [currentBatchNumber, active_node]);

  //-------------------------------------
  // If model training is triggered
  //-------------------------------------
  useEffect(()=>{
    if(!currentWorkflow) return;
    if(currentProject?.createdBy === userDetails?.userId) return;

    const currentStatus = checkCurrentTrainStatus(currentWorkflow);


    if(currentStatus === TrainStatus.NotTrained
        || currentStatus === TrainStatus.InIterate
        ){
          return;
        }

        toast.warn("Cannot access validation after training is triggered");
        navigate("/workflow?workflowId="+currentWorkflow.workflowId);



  },[currentWorkflow]);



  // let nerStore = useNerStore();

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    // nerStore.setStage(2);
  }, []);

 // ------ Notify user of autovalidated paragraphs ------
 // ------ Notify user of autovalidated paragraphs ------
 const notifyAutoValidatedParas = (avPara:any,batch:number)=>{

  if(page !== batch) return false;
  const userId:string = currentUser?.userId || ""
  if(!currentWorkflow?.autoValidate[userId]) return false;
   //const lastUpdatedTimeStamp:string | undefined = classificationReports?.lastUpdatedTimestamp;
  //const lastUpdatedTimeStamp:string | undefined =avPara.createdAt;
  let isAutoValidated =true;
  //console.log("classification report",classificationReports);

  

    const predictions:string[] = avPara.predictedIssueId;
    const validations:string[] = avPara.validations[userId].map((v:any)=>v.issueId);
    //console.log(predictions,validations);

    if(predictions.length !== validations.length) return false;

    validations.forEach((issueId:any)=>{

      if(!predictions.find(prediction =>prediction === issueId)) return false;
        // let batchDate = new Date(lastUpdatedTimeStamp);
        // let validationDate = new Date(validation.timeStamp);
        // let dateDiff = validationDate.getTime() - batchDate.getTime(); 
        // //console.log("=== dates ===",batchDate.getTime(),validationDate.getTime())
        // console.log("=== notify autovalidate diff ===",avPara,dateDiff/1000);
        // if(dateDiff>20000) isAutoValidated= false;            

    })

  
  //console.log("=== notify autovalidate result ===",isAutoValidated);
  return isAutoValidated;

}



  //-----------------------------------------------
  // Find Paras That are accepted by autovalidated
  //----------------------------------------------
  useEffect(() => {
    if (!fetching && stageParas && stageParas.length
      && currentWorkflow?.autoValidate
      && (currentWorkflow?.autoValidate[userDetails?.userId || ""] || false)
      && page === getBatchNumber()
      && getBatchNumber() > 1
      && nodes
    ) {
      //console.log("called from useEffect");      
      let paraIds: Array<string> = [];

      stageParas?.forEach((node: any) => {
        node.data.forEach((para: any) => {
          if (para.accepted === "accepted" && para.autoValidated === true && para.batchId === getBatchNumber()) {

            if(notifyAutoValidatedParas(para,getBatchNumber()))
            paraIds.push(para?.para_info?.paraId);

          }
        });


      });


      paraIds.length && toast(`${paraIds.length} paras were autovalidated`, { type: toast.TYPE.SUCCESS });
      //!paraIds.length && toast("There are no paragraphs eligible for Auto Validation in this batch!", { type: toast.TYPE.WARNING });
    }



  }, [fetching])


  const UpdateCount = (result:any[])=>{
    if(page === getBatchNumber()){

      const temp:any ={}

      Object.keys(stage2AccuracyReport).forEach((key)=>{
        temp[key] ={
          validatedParas:stage2AccuracyReport[key].validatedParas
        }
      })




      result && result.forEach((r:any)=>{

        Object.keys(r?.delta).forEach((key)=>{
          //console.log(r.delta[key],temp[key]?.validatedParas,stage2AccuracyReport[key]?.validatedParas);
              temp[key]={
                validatedParas:(temp[key]?.validatedParas || 0)
                                 + (r.delta[key] || 0)
              }
        }); 
       

      });
      //console.log("after calculation",temp);
      setStageNodeProgress(temp);
    }
  }


  let fetchParas = async (node_id: string, node_name: string, batchId: number) => {

    
    //console.log("=== Inside FetchParas ===")
    let completed = 0;
    let feedback = 0;
    let paraExists = false;
    let delta:any = {};
    let shouldUpdateCount = batchId === getBatchNumber();
    nodes.forEach((node)=>{
      delta[node.node_id] =0;
    });

    if(currentWorkflow?.useNoTag)
    {
      delta["No Tag"] = 0;
    }

    


    // console.log("Before fetch stageparas data",stageParas);
    if (stageParas?.length) {
      // console.time("if paras already exists");
      stageParas.forEach((paras: any) => {
        //console.log("=== Node ===", paras);

        let temp = paras.data.find((para: any) => para.batchId === batchId)

        if (temp) {
          paraExists = true;

        }

      });
      //console.timeEnd("if paras already exists")
    }
    if (paraExists) {
      //console.log("Paras Exist")
      setCurParas();
      return
    }


    try {
      let res: any = await Axios.post("/annotations/node-paras", {
        workflowId: currentWorkflow?.workflowId,
        predictedIssueId: node_id,
        stage: 2,
        batchId: batchId
      });
      // console.log("received para info for stage2",res.data);
      let result = res.data.data.result.paras;

      //console.log("received para info for stage2", result);

      result.sort(compare);

      result.forEach((para: any) => {

        
     

        if (para.validations[currentUser?.userId || ""]
          && para.validations[currentUser?.userId || ""][0]?.Action === "accepted") {
          // completed += 1;
          para.accepted = "accepted";
          shouldUpdateCount &&  delta[node_id]++;

        } else if (para.validations[currentUser?.userId || ""]
          && para.validations[currentUser?.userId || ""][0]?.Action === "rejected") {

         
            const accuracyDate =new Date(currentWorkflow?.alpdsInfo?.stage2ClassificationReports.stage2AccuracyReport?.timeStamp);
             const validationDate = new Date(para.validations[currentUser?.userId || ""][0]?.timeStamp);

          para.accepted = "rejected";
          // para.accepted= "rejected"
          para.nextPredicted = {}
          para.nextPredicted.selected = false;
          para.nextPredicted.issueId = "";


          if (para.validations[currentUser?.userId || ""][0]?.issueId) {
            para.nextPredicted.selected = true;
            para.nextPredicted.issueId = para.validations[currentUser?.userId || ""][0]?.issueId;
            
            if(shouldUpdateCount)
            {
                delta[para.nextPredicted.issueId]++;
            }
          }
        }
        else {
          para.accepted = null
        }
      });


      // if(shouldUpdateCount)
      // {
        
      //   setStageNodeProgress(()=>{
          
      //     let temp = {...stageNodeProgress};
      //     Object.keys(delta).forEach((key:string)=>{
      //         temp = {...temp,[key]:{
      //           validatedParas:(stage2AccuracyReport[key]?.validatedParas || 0) + delta[key]
      //         }}

       
          
  
  
      //     })
          
      //     return temp;
  
      //   });
      // }
     

      setStageParas((prev: any) => {

        let newParas = prev && prev.map((para: any) => {
          if (para?.node_id === node_id) {
            return {
              node_id,
              data: [...para?.data, ...result]
            };
          }
          return para;
        })
        return newParas;
      })


      return {
        node_id:node_id,
        delta:delta
      }
      
      backToTop();

    }
    catch (err: any) {
      //console.log("Error while getting paras for stage 2", err);
    }
    finally {
      // Commenting From Here ===> Mudit
      // setFetching(false);
    }


  };

  let fetchStage = async () => {
    //console.log("fetch paras was called");
    if (fetching) return;

    if (!currentWorkflow) return;

    try {
      //Fetch all nodes
      if (!stageNodes?.length) {

        //------------------------------------------------------------------------------------
        //If Workflow.useNoTag is true then add a class{node_id:"No Tag",node_name:""No Tag }
        //------------------------------------------------------------------------------------
        let nodes_1: Array<any> = [...nodes];
        nodes_1 = nodes_1.sort((a: any, b: any) => a.node_name.toLowerCase().trim() > b.node_name.toLowerCase().trim() ? 1 : -1)
        if (currentWorkflow.useNoTag && nodes_1.length) {

          const noTagObject = {
            _id: "No Tag",

            child_node_ids: [],

            createdOn: Date.now(),

            node_name: "No Tag",
            node_id: "No Tag",
            parent_id: null,
            is_leaf_node: true,
            is_model_class: true,



          }
          nodes_1

            .push(noTagObject);
        }




        let final_nodes = nodes_1
          .filter((node) => node.is_model_class)

          .map((fn: any) => {
            // fn.feedback=0;
            return {
              ...fn,
              completed_percentage: 0,
              goal: 0,
            };
          });



        setStageNodes(final_nodes);
        //console.log("=== nodes set in stage ===", final_nodes);
        setActiveNode(final_nodes[0]);
        // setCurParas();
        let node_populate: Array<any> = [];

        final_nodes.forEach((nd) => {
          nodesComplete.push({
            node_id: nd.node_id,
            completed_percentage: 0,
          });
          node_populate.push({
            node_id: nd.node_id,
            data: [],
          });
        });
        //console.log("=== node_populate in stage ===", node_populate);

        setStageParas(node_populate);


        if (!fetching) {
          setFetching(true);
          // console.log("=== fetchParas was called from fetchStage===")

          const result =  await Promise.all(final_nodes.map(
            async (node: any) => await fetchParas(node.node_id, node.node_name, getBatchNumber()))
          )
          
          UpdateCount(result);

         
          setFetching(false);
          //canTriggerAutoML();
         // ValidationCounter();
        }


      }
      //  console.log("=== Got Issues ===",data.data);




    }
    catch (err) {
      console.log("===Error while configuring stage nodes",err);

      toast("Internal Server Error : Fetching the node", {
        type: "error",
      });

    }
  };


  let validateLoadMore = async () => {

    //setBatch((b) => b + 1);
    //setLoading(true);
    setLoadMoreLoading(true);
    const currentStatus = checkCurrentTrainStatus(currentWorkflow);

    if(currentStatus === TrainStatus.NotTrained 
      || currentStatus === TrainStatus.InIterate){
        await callBatchPopulate(currentBatchNumber + 1);
      
    }else{
      toast.warn("Cannot call next batch once training is initiated");
    }

    
   // setLoading(false);
    setLoadMoreLoading(false);



  };

  let setCurParas = () => {
    let temp = [];
    if (stageParas) {
      //@ts-ignore
      for (let i = 0; i < stageParas?.length; i++) {
        //@ts-ignore
        if (stageParas[i].node_id === active_node?.node_id) {
          //@ts-ignore
          temp = stageParas[i].data;
          break;
        }
      }
      temp = temp.filter((para: any) => para.batchId === page);

      //console.log("=== Setting Paras in State to curParas ===",temp)
      setCurrent_paras(temp);

    }
  };

  



  // change or fetch data when page changes
  useEffect(() => {


    const changePageData = async () => {
      if (!fetching) {
        //console.log("=== Page Was Changed ===")
        if (stageNodes && !fetching) {

          setFetching(true);
        const result =   await Promise.all(stageNodes.map(async (node: any) => {
            await fetchParas(node.node_id, node.node_name, page)
          }))

          UpdateCount(result);
          setFetching(false);
          //canTriggerAutoML();

        }
      }

    }

    changePageData();
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    },);
    stageParaRef.current && stageParaRef.current.scrollTo({
      top: 0,
      // behavior:"smooth"
    },);
    //backToTop();


  }, [page])

  useEffect(() => {
    setCurParas();
  }, [active_node, batch, stageParas, page]);

  let callBatchPopulate = async (b: number) => {
    let canCallDSApi: boolean | undefined = checkForCriticalProgress(currentWorkflow);
    //console.log("canCallDSAPI",canCallDSApi);
    if (canCallDSApi) {
      toast("A critical process is in progress, please wait for its completion and try again", { type: toast.TYPE.WARNING });
      return
    }
   

    toast(`Initialization Batch ${b}.`, { type: "success" });

    try {
      await Axios.post("/integration/stage2_init", {
        workflowId: currentWorkflow?.workflowId,
        batchNumber: b
      });

      setStageParas((prev:any)=>{
        let temp = prev.map((nodePara:any)=>{
          return{
            node_id:nodePara.node_id,
          data:[]

          } 
        })
        return temp;
      });


    }
    catch (err) {
      toast(`There was an error with Batch ${b} paragraph population.`, {
        type: toast.TYPE.ERROR,
      });
    }

  };



// useEffect(()=>{

//   if(stage2AccuracyProgress === 100)
//   {
//     setStageNodeProgress(stage2AccuracyReport);
//   }

// },[stage2AccuracyProgress])


  useEffect(() => {

    // setStageParas([]);
    // setStageRejectedParas([]);

    const loadData = async () => {
      await fetchStage();
      setPage(getBatchNumber());




    }

    if (currentWorkflow && nodes && currentBatchProgress === 100 && !fetching) {

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      },)
      loadData();

    }

  }, [nodes, currentBatchProgress]);


  if (
    ((currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress < 100 ||
      loading))
    && (currentBatchNumber === 1 || currentProject?.createdBy === userDetails?.userId)
  ) {
    return (
      <LoadingScreen
        msg={"Please wait while we generate the next batch for you."}
        compleated={currentWorkflow?.alpdsInfo?.percentageStatus.stage2Progress.currentBatchProgress}
      />
    );
  }




  return (
    <div className="pb-[3vw]">
      {popOpen && <>

        <div
          className="fixed top-0 left-0 w-screen h-screen bg-gray-600 opacity-50 z-40 flex justify-center items-center cursor-pointer"

        >
        </div>
        <div className=" fixed top-0 left-0 w-screen h-screen z-50   flex justify-center items-center"
        >
          <div className="bg-white h-[22vh] min-h-[180px] w-1/2 rounded px-3 py-3 z-50"
            style={{ borderLeft: "5px solid #11256D " }}>
            <div
              className="mt-5 h-3/4 w-full flex justify-start items-center"

            >
              <div className="mx-3" ><img src="/images/info.svg" /></div>
              <div >
                <p className="font-semibold">
                  The Creator has initiated a new batch, so you won't be able to validate in this batch anymore.
                  Wait for the next batch to load to start annotating.
                </p>
              </div>




            </div>
            {/* <div className=" h-1/4 flex justify-end -mt-6 mx-3" >
              
            </div> */}


          </div>
        </div>
      </>}



      <div className={"flex  border-gray-200"}>
        <div

          className="border-r w-[20vw]"

        >
          <div className="text-[#7A7A7A] text-[1.1vw] pb-[0.25vw] border-b border-r border-r-white border-gray-200 py-2">
            Tags
          </div>

          <div className=" h-[100vh] py-5 w-full    ">
            <div className=" custom_scroll  p-5 py-2 max-w-[21vw] max-h-[55vh] ">

              {stageNodes?.map((node: any) => {
                return (
                  <Stage2Node
                    active={node._id === active_node?._id}
                    completedPercentage={node.completed_percentage}
                    key={node._id}
                    goal={node.goal}
                    node_name={node.node_name}
                    parent_info={
                      getNodeInfo(node.parent_id, nodes)?.node_name || "root"
                    }
                    onSelect={() => {
                      setActiveNode(node);
                      //setBatch(1);
                    }}
                  />
                );
              })}


            </div>
            <div className="flex justify-between py-[1vw] items-center border-t  mx-[1vw] mt-[1vw]">
              <p className="text-[1.1vw] text-[#777777]">Auto Validation</p>

              <Switch
                innerWidth={1.6}
                title=""
                width={5}
                onClick={updateAutoValidate}
                disable={getBatchNumber()<2 || page !== getBatchNumber()}
            disableMessage={ getBatchNumber()<2
              ?"You can only activate autovalidation during or after batch 2"
            :"Autovalidation is only available in latest batch and onwards"}
                state={(currentWorkflow?.autoValidate && currentWorkflow?.autoValidate[(userDetails && userDetails.userId) || ""]) || false}
              />

            </div>
          </div>

        </div>

        <div className={"flex-grow"} style={{ width: "70%", }}>
          <div

            className="text-[1.1vw] flex justify-between py-1 sticky top-0"
            style={{
              color: "#7A7A7A",
              marginLeft: 10,

            }}

          >
            Suggested Paragraphs
         
            <Counter
            currentWorkflow={currentWorkflow}
            currentBatchNumber={currentBatchNumber}
            active_node={active_node}
            stage2AccuracyProgress={stage2AccuracyProgress}
            stageNodeProgress={stageNodeProgress}
            />

          </div>
          <div>
            <Divider className="p-0 m-0" />
          </div>
          <div
            className="overflow-y-scroll custom_scroll px-[1vw] min-h-[50vw] pb-[1vw]"
            id={"stage_2_paras"}
            style={{ maxHeight: "100vw" }}
            ref={stageParaRef}
          >
            <div className="">
              {stageParas && current_paras?.map((paraMain: any, index) => {

                //@ts-ignore
                let para: any = paraMain.para_info;
                //console.log("== Para main  ==",paraMain);
                // if (paraMain.accepted === "rejected") return <div key={paraMain.paraId}></div>;
                return (

                  <div key={paraMain._id}>

                    <StagePara
                      index={index}
                      activePara={activePara}
                      setActivePara={setActivePara}
                      batchId={currentBatchNumber}
                      setStageRejectedParas={setStageRejectedParas}
                      stageNodes={stageNodes || []}
                      updateStageNodeProgress={setStageNodeProgress}
                      readOnly={CheckParaReadOnly(para)}
                      stageParas={stageParas || []}
                      setStageParas={setStageParas}

                      nerInfos={paraMain.alpdsInfo && paraMain?.alpdsInfo[0]?.nerInfo}


                      active_node_id={active_node?.node_id}
                      accepted={paraMain.accepted}
                      node_name={
                        active_node === undefined
                          ? "loading ..."
                          : active_node?.node_name
                      }
                      para_data={para.paraData}
                      time_str={para?.workflows[currentWorkflow?.workflowId || ""]?.createdAt || "randome date"}
                      paraId={para.paraId}
                      stage={2}
                      //predictedIssueId={para.workflows[currentWorkflow?.workflowId]?.alpdsInfo.predictedIssueId.stage2[0]}
                      predictedIssueId={paraMain.predictedIssueId[0]}
                      next={NextPara}
                      prev={PrevPara}
                      para={para}
                      keyboardEnabled={keyboardEnabled}
                      setKeyboardEnabled={setKeyboardEnabled}

                      isActiveBatch={page === getBatchNumber()}
                    />
                  </div>

                );
              })}
              {current_paras?.length === 0 && (
                <div className={"h-[400px]"}>
                  {fetching ? (
                    <div className={"flex h-full justify-center items-center h-[400px]"}>
                      <img
                        src="/images/loading_blue.svg"
                        alt="loading"
                        className="w-[3vw] "

                      />
                    </div>
                  ) : (
                    <div className={"flex justify-center items-center h-[400px]"}>

                      <NoPara
                        isFeedback={false}
                        isAllowed={isAllowed && isAutoMlClicked === false}
                        paraEmpty={paraEmpty || !fetching}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* {seedPara && (
            <SeedPara
              title={active_node?.node_name || ""}
              handleAddNewPara={handleAddNewPara}
            />
          )} */}
          <div className={" flex justify-between items-center mt-[0.5vw] py-[1vw] px-[2vw] ml-[1vw] border-b"}>
            <div></div>
            {/* <button
              className={` uppercase w-[13vw] border-2 flex justify-center  items-center   rounded-md transform hover:scale-105 duration-200
              ${!seedPara ? "bg-white text-[#11256D] border-[#11256D] border-2 text-[0.8vw]  py-[0.7vw] hover:bg-[#11256D] hover:text-white"
                  : "bg-[#E63946] text-white text-[1vw] py-2 px-4"}
              `}

              onClick={() => setSeedPara(!seedPara)}
            >
              {!seedPara && (
                <SeedIcon className=" w-[2vw]" />
              )}
              {!seedPara ? "SEED NEW PARAGRAPH" : "CANCEL"}
            </button> */}
            <div className="px-4">
              <Pagination current={page}
                className="text-[1vw] "
                showSizeChanger={false}
                responsive
                total={getBatchNumber() * 10}
                defaultCurrent={getBatchNumber()}
                onChange={(value: number) => setPage(value)} />
            </div>

            {
              currentProject?.createdBy === userDetails?.userId && <button
                onClick={() => validateLoadMore()
                }
                className={` duration-200 text-white  text-[0.9vw] 
           
              text-white
              px-[2vw] py-[0.8vw] 
              ml-[0.7vw]
              my-[0.3vw] text-center rounded-md border-2 border-transparent   
              ${!isAllowed || loadMoreLoading
                    ? "bg-gray-400"
                    : "bg-primaryBlue  hover:border-primaryBlue hover:scale-105"
                  }`}

                disabled={!isAllowed || loadMoreLoading}
              >
                  {loadMoreLoading ? <img width={25} 
                                  height={25}
                                   className="mx-auto"
                                    src={"/images/loading_white.svg"} alt="" />
                                 : "LOAD MORE"}
                
              </button>
            }
          </div>


        </div>
      </div>
      <div className="flex justify-end w-full">


        {userDetails?.userId === currentProject?.createdBy &&
          <div className="flex justify-end items-center w-[76%] border-b pb-[1vw] ">


            {currentWorkflow && currentWorkflow.stages.find(s => s.name === "train")
              && <button
                className={`uppercase duration-200  w-[13vw]
                text-white text-[0.9vw] 
                px-[1vw] py-[0.8vw] ml-[0.7vw] text-center rounded-md border-2 
                border-transparent my-[0.3vw]  
                ${canTriggerAutoML?"bg-primaryBlue cursor-pointer hover:border-primaryBlue hover:scale-105"
                      :"bg-gray-400 cursor-not-allowed"}
                   `}
                disabled={!canTriggerAutoML}
                onClick={async () => {
                  if(!canTriggerAutoML){
                    toast.warn("Please complete the target and try again")
                    return;
                  }

                  if (!isAutoMlClicked) {
                    setAutoMlClicked(true);
                    await NavigateToTrainStage(currentWorkflow,navigate);
                    setTimeout(()=>{
                      setAutoMlClicked(false);
                    },1000);

                  }
                }}>

                {isAutoMlClicked ? <img width={25} 
                                  height={25}
                                   className="mx-auto"
                                    src={"/images/loading_white.svg"} alt="" />
                                 : "Train Platform"}
              </button>}





            <button
              className="uppercase duration-200  w-[13vw]
                      text-white text-[0.9vw] 
                      px-[1vw] py-[0.8vw] ml-[0.7vw]
                      my-[0.3vw] text-center rounded-md border-2 
                      border-transparent bg-primaryBlue  hover:border-primaryBlue hover:scale-105 "


              onClick={() => navigate("/workflow/stage2-summary?workflowId=" + currentWorkflow?.workflowId)}
            >
              Validation Summary
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default Stage2;
