import React, { useEffect, useRef, useState } from 'react'
import Para from '../shared/Para'
import useWorkflowStore from '../../../store/useWorkflowStore';
import { Popover } from 'antd';


//Icons
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as LoadingWhite } from '../../../assets/loading_white.svg';
import {ReactComponent as UserAddedIcon} from '../../../assets/userAddIcon.svg';


const EditText = ({ text, onChange }: any) => {

  const ref = useRef<HTMLTextAreaElement>(null);


  useEffect(() => {

    if (ref?.current) {
      ref?.current?.focus();
    }

  }, [])

  return  <div className='p-[0.3vw] border rounded-[0.5vw] border-[#777777] bg-[#FAFAFA]'>

  <textarea
    data-testid="answer-textarea"
    ref={ref}
    value={text}
    onChange={(e) => onChange(e.target.value)}
    placeholder='Type your answer here...'
    className=' w-full h-[20vw]
                          p-[1vw]
                          custom_scroll
                          text-[0.9vw]
                           
                          focus:outline-none
                          ' >

  </textarea>
  </div>

}
const AddNewAnswerButton = ({ text, loading,
  onClick, disabled,
  data,
  disabledTitle, styles }: any) => {




  return (<button
    data-testid="update-answer-button"
    disabled={disabled}
    title={disabled && disabledTitle}
    onClick={!disabled ? onClick : () => { }}
    className={`uppercase duration-200  w-full
                text-primaryBlue text-[0.9vw] hover:text-white
                h-[2.5vw]  text-center rounded-md border-[0.1vw] hover:scale-105 
                disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:border-transparent
                border-[#1BA94C]  hover:drop-shadow-md 
${styles}  `}
  >
    {loading ? <LoadingWhite className='w-[2vw] h-[2vw]' />
      : <div className='flex items-center justify-center space-x-[0.5vw]'>
        <span className={` ${disabled?"text-white":"text-[#1BA94C]"}`}>Save</span>
      </div>}
  </button>)

}
const OutlineButton = ({ text, loading,
  onClick, disabled,
  data,
  disabledTitle, styles }: any) => {




  return (<button
    disabled={disabled}
    title={disabled && disabledTitle}
    onClick={!disabled ? onClick : () => { }}
    className={`uppercase duration-200  w-full
                text-primaryBlue text-[0.9vw] hover:text-white
                h-[2.5vw]  text-center rounded-md border-[0.1vw] hover:scale-105 
                disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
                border-primaryBlue  hover:drop-shadow-md
${styles}  `}
  >
    {loading ? <LoadingWhite className='w-[2vw] h-[2vw]' />
      : <div className='flex items-center justify-center space-x-[0.5vw]'>
        <span className='text-primaryBlue'>{text}</span>
      </div>}
  </button>)

}


function EditableValidation({ active, setActive, index,
  answers, answer, readOnly,
  validation,
  canDelete,
  rankSet,
  updateAnswer,
  setValidation }: any) {

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [rankOptions, setRankOptions] = useState<any[]>([]);

  const workflow = useWorkflowStore(state => state.currentWorkflow);
  const inputs = workflow?.listviewInfo?.inputConfigs;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [tempText, setTempText] = useState<string>("");
  //console.log("=== validation ===",validation);

  useEffect(() => {
    console.log("=== answer's source ===>",answer.source);
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    }
  }, [])

  useEffect(() => {
    setTempText(answer?.text || "");
  }, [editMode]);

  useEffect(() => {
    const temp = [
      {
        text: 1,
        id: 1
      }
    ]

    answers?.forEach((ans: any, index: number) => {
      index !== 0 && temp.push({
        text: index + 1,
        id: index + 1
      })
    });

    setRankOptions(temp);


  }, [answers]);

  return (
    <div className={`relative ${active?"border-primaryBlue border":""}`}>
      {/* {answer.source==="user" &&  <div className='absolute top-[-2.2vw] left-[-1.5vw] z-[10]'>
                     <UserAddedIcon 
                     className='w-[6vw] h-[6vw]'
                     />
          </div>} */}
    <div
      onClick={() => setActive(index)}
      className={`min-h-[20vw]
                      relative
                      border rounded-[0.2vw]
                      bg-white hover:shadow-md
                      p-[1vw] space-x-[1vw]
                      duration-200 border
                      ${isMounted ? "" : "-translate-y-[2vw]"}
                     }
                      `}>

        
      <div className={`w-full ${answer.source==="user"?"pt-[1vw]":"pt-[1vw]"} ${editMode?"pr-[2vw]":"pr-[2vw]"} `}>
        <div className='w-full flex justify-end relative '>
          

          {!editMode && !readOnly && <Popover
            content={"Edit this answer"}
          >
            <EditIcon
              data-testid="edit-button"
              onClick={() => setEditMode(true)}
              className={`w-[1.4vw] h-[1.4vw] duration-200
                      absolute ${answer.source==="user"?"top-[-1vw]":"top-[-1vw]"}
                       right-[-2vw] hover:scale-125
                      hover:scale-120 cursor-pointer`} />

          </Popover>}
        </div>
        {editMode ? <EditText
          text={tempText}
          onChange={setTempText}
        />

          : <Para
            active={active}
            text={answer?.text} />}
      </div>

      {editMode && <div className=' flex justify-end'>

        <div className='flex space-x-[1vw] w-[30%] mt-[1vw] pr-[1vw]'>
          <OutlineButton 
          text={"Cancel"}
          onClick={()=>setEditMode(false)}
          />
        <AddNewAnswerButton
          text="Save"
          styles={""}
          disabled={tempText === answer.text}
          onClick={()=>{
            setEditMode(false);
            updateAnswer(tempText)}}
        />
          </div>
      </div>}


    </div>
    </div>
  )
}

export default EditableValidation;