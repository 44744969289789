import React from "react";
import AppLayout from "../../components/layouts/AppLayout";
import PageLayout from '../../components/layouts/PageLayout';
import useWorkflowStore from '../../store/useWorkflowStore';
import ModelTraining1Comp from '../../components/workflow/modal-training-1';
import template from "../../template";

interface MasterlistProps { }
let Masterlist: React.FC<MasterlistProps> = () => {
    const workflow = useWorkflowStore(state => state.currentWorkflow);
    const project = useWorkflowStore(state => state.currentProject);

    const projectType=project?.projectType;
    const workflowType = project?.workflowType;
    const component = projectType && workflowType && template[projectType][workflowType]
                                                            .training
                                                            .modelTraining1.component;
    return (
        <AppLayout>
            <PageLayout pageSubHeading="model training 1" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/model-training_white.svg">
                {component && React.createElement(component)}
            </PageLayout>
        </AppLayout>
    );
};


export default Masterlist;
