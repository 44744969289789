import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

/**
 * This is the laoding component which shows progress between two stages
 */

interface LoadingScreenProps {
  msg: string;
  compleated: number;
}

let LoadingScreen: React.SFC<LoadingScreenProps> = ({ msg, compleated }) => {
  return (
    <div className={"w-full h-full"}>
      <div className={"text-center p-12 text-[2vw] text-gray-400"}>{msg}</div>
      <div className="flex justify-center">
        <div className="p-12 flex text-center  relative w-[22vw] h-[22vw]">
          <CircularProgressbar
            styles={buildStyles({
              pathColor: "#11256D",
              textColor: "#11256D",
              textSize: 12,
            })}
            value={compleated}
            text={`${compleated}%`}
          />
          <img
           
            className="w-[10vw] h-[10vw] absolute transform left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] rounded-full"
            src="/images/progress.svg"
            alt="progress"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
