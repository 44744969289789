import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import instance from "../utils/Axios";
import useWorkflowStore from "../store/useWorkflowStore";
import useAuthStore from '../store/authStore';
import useCompanyUsersStore from "../store/useCompanyUsersStore";
import CollapsibleComponent2 from "./CollapsibleComponent2";
import { DownOutlined } from '@ant-design/icons';

interface PermissionComponentProps{
  setPopupOpen:any;
  isPopupOpen:boolean
}
const PermissionComponent  : React.FC<PermissionComponentProps> = ({setPopupOpen, isPopupOpen}) => {
  const [inputText, setInputText] = useState<string>("");
  const [listOfUsers, setListOfUsers] = useState<Array<any>>([]);
  const [matchedResults, setMatchedResults] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  
  const currentProject = useWorkflowStore(state => state.currentProject);
  const workflow = useWorkflowStore(state => state.currentWorkflow);
  const userDetails = useAuthStore(state => state.userDetails);
  const { companyUsers } = useCompanyUsersStore();
  
  const dropDownClickHandler = () => {
    setIsOpen(prevState => !prevState);
    setActiveIndex(-1);
  }
  
  const collapseClickHandler = (index: number) => {
    console.log(index)
    if (activeIndex == index)
      setActiveIndex(-1);
    else
      setActiveIndex(index);
  }

  const workflowId = workflow?.workflowId;

  useEffect(() => {
    instance.post(`/userproject/user`, {
      projectId: currentProject?.projectId,
      companyUsers: companyUsers
    })
      .then((res) => {
        let result = res.data.data;
        // result = result.filter((u:any)=>u.status==="APPROVED");
        // console.log("Got User Project Info", result );
        result.sort(function compareFn(a:any, b:any) {  if(`${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}`) return -1;})
        // console.log(result)
        setListOfUsers(result);
        setMatchedResults(result);
      })
      .catch((err) => {
        toast("Unable to fetch users to provide permissions.", {
          type: "error",
        });
      });
  }, [companyUsers]);

  const createClickHandler = (permissions: any, userInfo: any) => {
    //@ts-ignore
    if (userInfo.user_project_info === undefined) {

      console.log("---State---", permissions);
      instance.post('/userproject/createUserProject', {
        projectId: currentProject?.projectId,
        userId:userInfo.userId,
        lastAccessedWorkflowId: workflowId,
        permissions
      })
        .then((res) => {
          const result = res.data.data.result;
          console.log("----update user project res---", result);         

          setListOfUsers((prevState: Array<any>) => {
            prevState.forEach(element => {
              if (element.userId === userInfo.userId) {
                element.user_project_info = result;
              }
            });
            return prevState;
          })

          toast("Access has been granted successfully.", {
            type:"success"
          });
          collapseClickHandler(-1);
        })
        .catch((err) => {
          toast("Some error occurred while adding permissions.", {
            type: "error",
          });
        });
    }
    else {
      instance.post('/userproject/updatePermissionsUserProject', {
        projectId: currentProject?.projectId,
        userId:userInfo.userId,
        lastAccessedWorkflowId: workflowId,
        permissions
      }).then((res) => {
        const result = res.data.data.result;
        console.log("----update user project res---", result);         

        setListOfUsers((prevState: Array<any>) => {
          prevState.forEach(element => {
            if (element.userId == userInfo.userId) {
              element.user_project_info = result;
            }
          });
          return prevState;
        })


        toast("Access has been updated successfully.", {
          type:"success"
        });
        collapseClickHandler(-1);

      }).catch((err) => {
        toast("Some error occurred while adding permissions.", {
          type: "error",
        });
      })
    }
  }

  return (
    <>
    {isOpen &&<button className="fixed z-10 inset-0 w-full h-full cursor-default" onClick={()=>{setPopupOpen(false)}}></button> }
    <div className="relative z-[51] ">
      <div className="flex justify-end">
        <div className="flex justify-between rounded-[0.35vw] w-[15vw] bg-[#EAEAEA]  py-[0.3vw] px-[0.8vw]">
          <div className="font-normal text-[1.2vw] pl-[0.2vw]">
            Manage Users
          </div>
          <button onClick={dropDownClickHandler}>
            <img src="/images/down_outline.svg" style={{
              width: "1.1vw",
              height: "1.5vw",
            }} alt="down_outline" />
          </button>
        </div>
      </div>

      {
        isOpen &&
        <div className="bg-white absolute border-[#EAEAEA] border-solid  border-[1.2px] shadow-lg shadow-slate-300 pt-[0.2vw] rounded-[0.35vw]  w-[15vw] ">
          <div className="flex mt-2 mb-3 justify-center items-center px-2">
            <input
              onChange={(e) => {
                setActiveIndex(-1)
                setInputText(e.target.value);
                if (listOfUsers.length) {
                  if (e.target.value.trim().length === 0) {

                    listOfUsers.sort(function compareFn(a:any, b:any) {  if(`${a.firstName} ${a.lastName}` <= `${b.firstName} ${b.lastName}`) return -1; else return 1;})
                    setMatchedResults(listOfUsers);
                  } else {
                    let temp: Array<any> = [];
                    listOfUsers.forEach((su) => {
                      if (
                        `${su.firstName} ${su.lastName}`
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase()) ||
                        su.email
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      ) {
                        temp.push(su);
                      }
                    });
                    temp.sort(function compareFn(a:any, b:any) {  if(`${a.firstName} ${a.lastName}` <= `${b.firstName} ${b.lastName}`) return -1; else return 1;})

                    setMatchedResults(temp);
                  }
                }
              }}
              className="bg-gray-50 border w-full rounded-2xl text-[1vw] px-3 py-1"
              placeholder="Search User"
              value={inputText}
              type="text"
            />
          </div>
          {
            matchedResults && matchedResults.map((elem, index) => {
              return (
                <div key={index} className={`hover:bg-[#F4F4F4] ${activeIndex===index && `bg-[#F4F4F4]`}`}>    
                <CollapsibleComponent2  index={index} userInfo={elem} activeIndex={activeIndex} collapseClickHandler={collapseClickHandler} createClickHandler={createClickHandler} />
                </div>
                )
            })
          }

        </div>
      }
    </div>
    </>

  );
}
export default PermissionComponent;