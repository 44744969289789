import React, { useEffect } from 'react'
import useWorkflowStore from '../store/useWorkflowStore'
import template from '../template';
import AppLayout from '../components/layouts/AppLayout';
import PageLayout from '../components/layouts/PageLayout';
import { useNavigate } from 'react-router-dom';

export default function ModelComparisonPage() {

    const project = useWorkflowStore(state=>state.currentProject);
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const navigate = useNavigate();

    const projectType = project?.projectType ;
    const workflowType = project?.workflowType;

const modelComparison =projectType 
                        && workflowType
                        &&  template[projectType][workflowType]?.modelComparison;

const inModelSelection = workflow?.stages.find((stage:any)=>stage.inModelSelection);
const inModelTuning = workflow?.stages.find((stage:any)=>stage.inModelTuning);

useEffect(()=>{
  if(workflow?.alpdsInfo?.selectedModelInfo?.modelName){
   // navigate("/workflow/llm-documentation?workflowId="+workflowId);
  }

},[])


if(  !modelComparison){
      navigate("/workflow?workflowId="+workflowId);
      
}


  return (
    <AppLayout>
        <PageLayout 
        pageIconUrl='/images/active_automl.svg'
        pageTitle={`${project?.name}`}
        pageSubHeading='Model Selection'
        >
            <div>{modelComparison && 
                React.createElement(modelComparison?.component)}</div>
        </PageLayout>
    </AppLayout>
  )
}
