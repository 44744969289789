import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as DownChevrolet } from '../../../assets/down_chevrolet.svg'
import { ReactComponent as SearchIcon } from '../../../assets/search.svg'
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import useWorkflowStore from '../../../store/useWorkflowStore';

interface IBatchFilter {
    onChange: (e: number) => any,
    value: number


}


export const FilterOption = ({ setOpen, onChange, option }: any) => {




    return (<li
        onClick={() => {
            //setCurrent(option);
            //alert("setting batch "+option);
            setOpen(false);
            onChange(option)
        }}
        key={option}
        className='cursor-pointer select-none w-full px-[1vw] flex items-center justify-between hover:bg-[#E6F7FF] '>

        <div className=' py-[0.5vw]  cursor-pointer  focus  :bg-gray-200 text-[0.9vw] ' >
            Batch {option}</div>



    </li>)


}


function BatchFilter({
   
    onChange, 
    value

}: IBatchFilter) {

    //local states
    const current:number=value;
    const [open, setOpen] = useState<boolean>(false);
    const [options,setOptions] = useState<number[]>([]);
    const optionsRef = useRef<HTMLUListElement>(null);


    //global states
    const currentWorkflow = useWorkflowStore((state)=>state.currentWorkflow);
    const stage2Progress:any = currentWorkflow?.alpdsInfo?.percentageStatus.stage2Progress;
    const currentBatchNumber:number = stage2Progress?.currentBatchNumber || 0;

    useEffect(()=>{
    let temp:any[] =[];

    // Object.keys(batches).forEach((key:string)=>{

    //     let batch = parseInt(key.split("").slice(5).join(""));
    //     temp.push(batch+1);

    // });
    for(let i=1;i<=currentBatchNumber;i++){
        temp.push(i);

    }

    //console.log("=== option values ===",temp);
    temp.sort((a:number,b:number)=>a>b?-1:1)


    setOptions(temp);
 

    },[currentBatchNumber]);



    useEffect(() => {
        if (open) {
            optionsRef.current && optionsRef?.current?.scrollTo({ top: 0 });
        }


    }, [open]);


    return (
        <div className='w-full   '>
            {
                open && <div className='h-screen w-screen fixed top-0 left-0 right-0 z-[45]'
                    onClick={() => {
                        setOpen(false);
                        //setKeyboardEnabled(true); 
                    }}
                ></div>
            }

            {/*  Container  */}
            <div
                onClick={() => { if (!open) { setOpen(true); } }}
                className={`w-full border-2  py-[0.6vw] px-[0.6vw] rounded-[0.3vw]  
                relative flex items-center justify-between  cursor-pointer
                ${true ? "bg-gray-100 border-transparent" : ""}`}

            >
                <div className='flex justify-between w-full'>
                    <p className='text-[1vw] select-none'>
                        Batch {current}</p>
                    

                </div>


                <DownChevrolet className='w-[0.8vw] h-[0.8vw]' />


                {
                    <div className={`absolute  z-[49] left-0 top-[100%]
                        border rounded-md bg-white w-full duration-300 origin-top
                      
                        ${open ? "" : " scale-y-0 opacity-0"}`}>

                        {/* <div className='flex items-center justify-center m-[0.7vw]'>
                            <div className='flex items-center justify-between rounded-full w-full border px-[0.8vw] py-[0.4vw]'>
                                <input className='focus:outline-none w-[85%] text-[0.8vw]'
                                    placeholder='Search class'
                                    value={searchTerm}
                                    onChange={(e: any) => setSearchTerm(e.target.value)}

                                />
                                {searchTerm.length ? <CrossIcon className='w-[0.9vw] h-[0.9vw] cursor-pointer'
                                    onClick={() => setSearchTerm("")} /> : <SearchIcon className='w-[1vw] h-[1vw]' />}
                            </div>

                        </div>
                        <hr /> */}
                        <ul className='bg-white custom_scroll max-h-[12.8vw]  shadow-lg rounded-b '
                            ref={optionsRef}
                        >
                         
                            {
                                options.map((op:number)=><FilterOption 
                                     onChange={onChange}
                                     option={op}
                                     setOpen={setOpen}

                                    
                                    />
                                )
                            }
                            


                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}

export default BatchFilter