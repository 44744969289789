import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApexChart from "./ApexChart";
import Chart from "./Chart";
import useWorkflowStore from '../../../store/useWorkflowStore';
import { current } from "immer";
//import './trainCard.css'
interface TrainCardProps {
  // practiceAreaId: string | undefined;
  // activeStage: number;
}
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
const NERTrainCard: React.FC<TrainCardProps> = () => {
  let navigate = useNavigate();
  let {currentWorkflow} = useWorkflowStore()
  let handleClick = () => {
    
    if(currentWorkflow?.stages.find(s=>s.name==="train")?.active || currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress)
    {
      navigate("/workflow/modeltraining-1?workflowId="+currentWorkflow.workflowId);
    }
  };

  let autoMlProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress || 0;

  const [details, setDetails] = useState({
    trainingProgress: 0,
    stage1: autoMlProgress || 0,
  });

  let [counter, setCounter] = useState<number>(0);
 

   let [trained, setTrained] = useState(false);

  useEffect(() => {
    setTrained(currentWorkflow?.stages.find(s=>s.name==="train")?.active || false);
    
    let data =
      currentWorkflow?.alpdsInfo?.percentageStatus
        ?.automlProgress || 0 ;

    if (data) {
      let s1 = data;
     

      setDetails({
        ...details,
        stage1: s1,
       
      });
    }
    
    
    return () => {};


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkflow?.stages]);

  return (
    <div
      className={`p-3 bg-white w-full shadow-lg ${trained?"cursor-pointer":""}`}
      style={{ height: "15vw", width: "20vw" }}
      onClick={() => handleClick()}
    >
      <div className="flex space-x-2">
        <img
          style={{
            width: "2.5vw",
            height: "2.5vh",
          }}
          src="/images/trainCardIcon.svg"
          alt="validate"
        ></img>
        <p style={{ fontWeight: 500, fontSize: "1.1vw" }}>Train</p>
      </div>
      {details.stage1 === 0 ? (
        <div className="flex h-[90%] justify-center items-center">
          <Chart stage1={0} />
          {/* <div className="flex items-center" style={{ height: "120px" }}>
            <p
              style={{
                fontWeight: 300,
                color: "#9A9A9A",
                lineHeight: "20px",
                fontSize: "14px",
              }}
            >
              Model Training has not yet been initiated.
            </p>
          </div> */}
        </div>
      ) : details.stage1 === 100 ? (
        <div className="my-[0.5vw] mx-2">
          <p
          className="text-[#2C2C2C] text-[1.3vw] mb-[0.3vw]"
            
          >
            Accuracy Score
          </p>
          <p            className="text-[1.4vw]"
>
            {Math.floor(currentWorkflow?.alpdsInfo?.trainInfo?.MicroF1* 100)}%
          </p>
          <div className="flex w-full bg-white h-12 border-2 mt-2">
            <div
              style={{
                backgroundColor:
                  currentWorkflow?.alpdsInfo?.trainInfo?.MicroF1<
                  0.75
                    ? "#F59F00"
                    : "#1BA94C",
                width: `${
                  currentWorkflow?.alpdsInfo?.trainInfo?.MicroF1 *
                  100
                }%`,
              }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center ">
          

          <ApexChart
            
            stage1={details.stage1}
            
            height={250}
          />
          
          {/* <div className="flex text-[0.9vw] text-left py-1  w-[30%]">
           
           
          </div> */}
        </div>
      )}
    </div>
  );
};

export default NERTrainCard;

//export default {};