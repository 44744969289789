import { createStore } from "redux";
import AllReducers from './reducers';
import {persistStore,persistReducer} from 'redux-persist';
import storage from "redux-persist/lib/storage";
//@ts-ignore
 //export default createStore(AllReducers , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
 export default createStore(AllReducers)


const persistConfig ={
    key:"stage-data",
    storage
    
}
const persistedReducer = persistReducer(persistConfig,AllReducers);
//export default createStore(persistedReducer );