
import { Divider } from "antd";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import useWorkflowStore from "../../../../../store/useWorkflowStore";
import Axios from '../../../../../utils/Axios';
import { saveAs } from 'file-saver'
import useAuthStore from "../../../../../store/authStore";
import useEntityStore from "../../../../../store/entityStore";



export interface Deploy2Props { }

const NERDeploy2: React.FC<Deploy2Props> = () => {
  const [stat, setStat] = useState({
    count: 0,
    download: 0,
  });
  let { currentWorkflow ,currentProject} = useWorkflowStore();
  let token = useAuthStore(state => state.token);
  let user = useAuthStore(state=>state.userDetails);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  const entities = useEntityStore(state=>state.entities);


  let [requiredExports, setRequiredExports] = React.useState({
    masterlist: false,
    training: false,
    prediction: false,
  });

  // let io = React.useContext(SocketContext);



  //Save Files To Local Storage
  const initDownload = async () => {

    if(
        requiredExports.masterlist === false &&
        requiredExports.training === false &&
        requiredExports.prediction === false
      ) return;

        requiredExports.masterlist &&   exportEntities();
        requiredExports.training &&  await exportValidatedData();
        requiredExports.prediction &&  await exportPredictionData();
      return;

    
  }

  //export entities 
  const downloadData=(data:any,filename:string)=>{
    const link = document.createElement<"a">("a");
        const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
        link.href = jsonString;
        link.download = filename;
        link.click();

        setTimeout(()=>{
            link.remove();
        },1000);

  }

  const exportEntities = async() =>{

    try{
        let data = {
            "project name":currentProject?.name,
            "workflow name":currentWorkflow?.workflowName,
            "entities":entities.map((ent)=> {
                return {
                    name:ent.entity,
                    description:ent.entityDescription,
                    abbreviation:ent.abbreviation
                }
            })
        }

        
        downloadData(data,`${currentProject?.name}_masterlist.json`);


    }catch{
        toast("There was an error while exporting masterlist");
    }

  }

  const exportValidatedData = async()=>{
    if(!currentWorkflow) return;

    try{
        const result = await Axios.post("/ner/export-validated-data",{
            workflowId:currentWorkflow?.workflowId
        });

        if(result.data){
            let validationData = result.data.data;
            const cached_ent:any={};
            const final_data:any  ={

                "project name":currentProject?.name,
                "workflow name":currentWorkflow.workflowName,
                "ner info":validationData.map((v:any)=>{

                    let userValidations:any[] = v.validations[user?.userId || ""];
                   
                    return {
                        text:v.para_info.paraData,
                        validations:userValidations.map((v2)=>{
                        let entity:string =""
                        if(cached_ent[v2.entityId]){
                            entity=cached_ent[v2.entityId]
                        }else{
                            const found_ent = entities.find(ent=>ent.entityId === v2.entityId);
                            cached_ent[v2.entityId] = found_ent?.entity

                        };
                            return {
                                startIndex:v2.startIndex,
                                endIndex:v2.endIndex,
                                word:v2.word,
                                entity:cached_ent[v2.entityId]
                            }



                        })
                    }


                })

            }
            //end of final data

            downloadData(final_data,`${currentProject?.name}_validated_paras.json`)



        }




    }catch(e){
        toast.warn("There was an error while export validated data");
        console.log("=== error while downloading validation data ===",e);
    }




  }

  const exportPredictionData = async()=>{
    if(!currentWorkflow) return;

    try{
        const result = await Axios.post("/ner/export-prediction-data",{
            workflowId:currentWorkflow?.workflowId
        });

        if(result.data){
            let validationData = result.data.data;
            const workflowId =  currentWorkflow.workflowId || ""
            const cached_ent:any={};
            const final_data:any  ={

                "project name":currentProject?.name,
                "workflow name":currentWorkflow.workflowName,
                "ner info":validationData.map((v:any)=>{

                    let predictions:any[] | null = v.workflows[workflowId]
                    .alpdsInfo
                    .predictedIssueId.AutoML;
                   
                    return {
                        text:v.paraData,
                        validations:predictions ?  predictions?.map((v2)=>{
                        let entity:string =""
                        if(cached_ent[v2.entityId]){
                            entity=cached_ent[v2.entityId]
                        }else{
                            const found_ent = entities.find(ent=>ent.entityId === v2.entityId);
                            cached_ent[v2.entityId] = found_ent?.entity

                        };
                            return {
                                startIndex:v2.startIndex,
                                endIndex:v2.endIndex,
                                word:v2.word,
                                entity:cached_ent[v2.entityId]
                            }



                        }):null
                    }


                })

            }
            //end of final data

            downloadData(final_data,`${currentProject?.name}_prediction_paras.json`)



        }




    }catch(e){
        toast.warn("There was an error while export validated data");
        console.log("=== error while downloading validation data ===",e);
    }




  }








  return (
    <div className="my-[0.2vw] mx-[0.5vw] w-full">
      <div className="bg-white rounded-md w-full p-[1vw]">
        <p className="font-sans text-[1.3vw]" style={{ color: "#2C2C2C" }}>
          Information about exporting the Dataset
        </p>
        <Divider />
        <p className={"font-thin mb-[0.5vw] text-[1.1vw] w-11/12"}>
          Download the data by selecting the checkboxes below and then click on
          "EXPORT DATASET" button. The Download process will initiate
          automatically. Data will be downloaded in .xlsx format.
        </p>
        <p className="font-sans text-[1.1vw] ">
          Select which dataset you wish to export
        </p>
        <div className="my-[0.7vw]">
          <div className="flex items-center mt-[0.8vw]">
            <input
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  masterlist: !requiredExports.masterlist,
                })
              }
              type="checkbox"
              className="rounded-[0.3vw] mr-[1vw] w-[1vw] h-[1vw]"

            />
            <p className="font-sans text-[1.1vw] mt-[0.2vw]">
              Masterlist</p>
          </div>
          <div className="flex items-center mt-3">
            <input
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  training: !requiredExports.training,
                })
              }
              type="checkbox"
              className="rounded-[0.3vw] mr-[1vw] w-[1vw] h-[1vw]"

            />
            <p className="font-sans text-[1.1vw] mt-[0.2vw]">

              Training Dataset</p>
          </div>
          <div className="flex items-center mt-[0.8vw]">
            <input
              disabled={
                currentWorkflow?.alpdsInfo?.percentageStatus
                  ?.predictionOnDatabaseProgress != 100
              }
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  prediction: !requiredExports.prediction,
                })
              }
              type="checkbox"
              className="rounded-[0.3vw] mr-[1vw] w-[1vw] h-[1vw]"
            />

            <p className="font-sans text-[1.1vw] mt-[0.2vw]">
              Prediction Results on Ingested Data
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end my-10">
        {isLoading ? (
          <div className="px-4 rounded-md py-3 border-primarycolor bg-white border-2">
            <img className="w-24 h-6" src="/images/loading_blue.svg" alt="loader" />
          </div>
        ) : (
          <button
          disabled={isLoading}
            onClick={async() => {
                initDownload();
            }}
            title={
              requiredExports.masterlist === true ||
                requiredExports.training === true ||
                requiredExports.prediction === true
                ? ""
                : "Please select any one export option."
            }
            className={`${requiredExports.masterlist === true ||
              requiredExports.training === true ||
              requiredExports.prediction === true
              ? "bg-primarycolor hover:scale-105 duration-200"
              : "bg-gray-400 cursor-not-allowed"
              } px-[1.5vw] py-[0.5vw] text-white font-sans text-[1.1vw] rounded-[0.3vw]`}
          >
            EXPORT DATASET
          </button>
        )}
      </div>
      <div className={"mt-[2vw] w-1/2"}>
        <div className="flex">
          <img src="/images/tip_green.svg" alt="tip" className="mr-[0.5vw] w-[1.5vw]"></img>
          <p className="font-sans text-[1.1vw] text-skin-darkGrey">Tip</p>
        </div>
        <Divider style={{ margin: "10px 0" }} />
        <p
          className="text-gray-500 font-sans text-[1.1vw]"
        >
          In order to download data please enable "Pop Up" through your browser.
          <br />
          Please perform "Prediction on Ingested Data" before exporting
          "Prediction Results on Ingested Data".
        </p>
      </div>
    </div>
  );
};

export default NERDeploy2;
