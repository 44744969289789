import React, { useState, useEffect } from 'react'
import useWorkflowStore from '../../../store/useWorkflowStore';


import Axios from '../../../utils/Axios';
import { toast } from 'react-toastify';
import useAuthStore from '../../../store/authStore';
import useKeyboard from '../../../hooks/useKeyboard';
import NERPara from './NERPara';
import { Pagination, Popover, Tooltip } from 'antd';
import {useNavigate} from 'react-router-dom';


//Icons
import { ReactComponent as Loading } from '../../../assets/loading_blue.svg';
import {ReactComponent as LoadingGreen} from '../../../assets/loading_circle.svg';
import { ReactComponent as Refresh } from '../../../assets/reload.svg';
import {ReactComponent as MultiUserIcon} from '../../../assets/mutiuser.svg';
import {ReactComponent as AchievedTarget} from '../../../assets/achieved_target.svg';
import {ReactComponent as TotalTarget} from '../../../assets/total_target.svg';
import {ReactComponent as LoadingWhite}  from '../../../assets/loading_white.svg';
import template from '../../../template';
import NerSidebar from './Sidebar/NerSidebar';
import useEntityStore from '../../../store/entityStore';
import Footer from './Footer';
import Counter from './Counter';


const HoverMenu = ({children,text}:any)=>{
  return (<Popover
  className='!text-[2vw]'
  placement="top"
  content={text} color="white"
  
  >
      {children}
  </Popover>
  )
}


function Index() {


  const paras = useEntityStore(state=>state.paras);
  const setParas = useEntityStore(state=>state.setParas);
  //count states
  const [count, setCount] = useState<number>(0);
  const [totalParas, setTotalParas] = useState<number>(0);

  //Loading States
  const [loading, setLoading] = useState<boolean>(true);

  //global states
  const projectType:string =useWorkflowStore(state=>state.currentProject)?.projectType || "";
  const workflowType:string =useWorkflowStore(state=>state.currentProject)?.workflowType || "";
  const retrainEndpoint:string = template[projectType][workflowType]?.validation?.endpoints?.initStage2;
  const validationEndpoint:string = template[projectType][workflowType]?.validation.endpoints.validation;


  const navigate = useNavigate();
  const currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
  const userId: string = useAuthStore(state => state.userDetails?.userId) || "";


  //Listview information
  const RetrainTarget= 20;
  const AutoMLTarget = 15;
  const pageSize = 20;
  const listviewInfo:any = currentWorkflow?.listviewInfo;
  const [page, setPage] = useState<number>(listviewInfo?.userInfo?listviewInfo?.userInfo[userId]?.lastAccessedPage || 1:1);
  const [isMinThreshold,setIsMinThreshold] = useState<boolean>(false);
  const [allUsersData,setAllUsersData] = useState<any>({});




  //keyboard navigation
  const activePara = useEntityStore(state=>state.activePara);
  const setActivePara = useEntityStore(state=>state.setActivePara);
  const [keyboardEnabled, setKeyboardEnabled] = useState<boolean>(true);
  const ArrowDown = useKeyboard({ keyCode: "ArrowDown" });
  const ArrowUp = useKeyboard({ keyCode: "ArrowUp" });


  const PrevPara = () => {
    if (!keyboardEnabled) return;
    if (ArrowUp && activePara > 0) {
      
      setActivePara(activePara - 1);
    }

  }


  const NextPara = () => {
    if (!keyboardEnabled) return;
    if (paras && activePara < paras.length - 1) {

      setActivePara(activePara + 1);
    }
  }
  //Move to Next Paragraph on Arrow Down
  useEffect(() => {
    ArrowDown && NextPara();
  }, [ArrowDown]);

  //Move to previous paragraphs on Arrow Up
  useEffect(() => {
    ArrowUp && PrevPara();

  }, [ArrowUp]);



  // ------ fetch paragraphs ------
  // ------ get total paragraphs ----
  const fetchParas = async () => {


    //if (loading || !currentWorkflow) return;
    if(!currentWorkflow) return;
    setLoading(true);
    try {
     // alert("inside ner view")
      let res: any = await Axios.post("/annotations/ner-paras", {
        workflowId: currentWorkflow?.workflowId,
        page: page,
        pageSize: pageSize
      });

      let result: Array<any> = res.data.data.result.paras;
      let total_paras = res.data.data.result.total_paras;
      let count = res.data.data.result.total_count || 0;

      let annotated: number = 0;
      //result.sort((para1:any,para2:any)=>para1.paraSeqId >para2.paraSeqId?1:-1);
      result.forEach((para: any) => {

        let validations: any = para?.ner_info[0]?.validations;
        let userValidation: Array<any> | undefined =validations? validations[userId]:undefined;
        if (userValidation ) {
          para.saved = true;
          para.validation =userValidation;
          annotated++;
        }
        else {
          para.saved=false;
          para.validation = para.ner_info[0].predictedEntities          ;
        }



      });

      //console.log(result);
      setParas(result);
      setTotalParas(total_paras);
      setCount(count);
      setActivePara(0);
    } catch (err) {
      toast("Could not fetch paras", { type: toast.TYPE.ERROR });
     console.log("Error while fetching paragraphs",err);

    } finally {
      setLoading(false);
    }
  }

  //----------------------------------------------------
  //Unlock Masterlist & Redirect User To Masterlist Page
  //----------------------------------------------------
  let resetStage1 = async () => {

    //--- Make a request to reset Stage 1 here ---

    Axios.post("/masterlist/markLocked", {
      workflowId: currentWorkflow?.workflowId,
      markAs: false,
      user: userId
    });

    navigate("/workflow/masterlist?workflowId=" + currentWorkflow?.workflowId);
  };

  
  //----------------------------------------------------
  //Fetch paragraphs on component load or refresh
  //----------------------------------------------------
  useEffect(() => {

    let p1 = fetchParas();
    //let p2 = fetchCountDetails(true);
    //fetchFiles();
    Promise.all([p1]);
    //window.addEventListener("scroll", onScroll);

    // return () => window.removeEventListener("scroll", onScroll);




  }, [])

  //----------------------------------------------------
  //Fetch paragraphs on page number change
  //----------------------------------------------------
  useEffect(() => {
    if(!loading)
    {

      fetchParas();
    }
  }, [page]);

  
  //----------------------------------------------------
  //notify user that they can call retrain api
  //----------------------------------------------------
  // useEffect(()=>{
  //   if(createdBy === userId && canCallRetrain)
  //   {
        
  //       toast("You can now retrain the model",{type:toast.TYPE.SUCCESS});
  //   }



  // },[canCallRetrain]);





  if(!loading && (totalParas===0 ))
  {
    return (
      <div className="uppercase w-full min-h-[25vw] flex justify-center px-[1.5vw] py-[0.6vw] text-[1.3vw] flex-col items-center text-center">
      You don't have enough data for annotation. Please provide
      relevant keywords for the nodes. <br />
      <button
        onClick={() => {
          resetStage1();
        }}
        className="uppercase bg-primaryBlue text-white px-[1vw] py-[0.7vw] mt-[1.5vw] text-[1.1vw] rounded-[0.5vw] shadow-[0.2vw]"
      >
        Go to masterlist
      </button>
    </div>
    )
  }


  return (
    <div className=''>
     
      <div className='flex items-center border-b shadow-sm sticky top-[5vw] bg-[#fafafa]  z-[50]'>
      <div className='w-[20%]
                    text-[1.2vw] text-[#7a7a7a]  p-2 
                    '>Entities
      </div>
      <div className={` w-full  bg-[#fafafa]
                      text-[1.2vw] text-[#7a7a7a]  p-2  
                      flex justify-between items-center   flex-1`}>

                        
        <p>Suggested Paragraphs</p>
        
      </div>
      <div className='w-[20%]'>
        <Counter
        count={count}
        RetrainTarget={RetrainTarget}
        />
      </div>

      </div>

      {
        loading ? (
          <div className='h-[65vh] w-full flex items-center justify-center'>
            <Loading className='w-[6vw] h-[6vw]' />
          </div>
        ) :
        paras?.length>0?(
          <div className=' flex
           '>
            <div className='w-[25%] border-r mr-[1vw]   '>
              <NerSidebar
              setCount={setCount}
              />
             
            </div>




              
            <div className={`w-full flex flex-col space-y-[1vw] py-[1vw] `}>
              {
                paras?.map((para: any, index: number) => {
                  return <NERPara
                  para={para}
                  stage={1}
                  index={index}
                 
                  readOnly={false}
                  keyboardEnabled={true}
                  setKeyboardEnabled={()=>{}}
                
                 
                  setCount={setCount}
                
                  fileInfo={{}}



                        />
                })
              }
            </div>
          </div>
        ):<div className='flex h-[28vw] text-gray-400 text-[1.5vw] items-center justify-center'>
          No more paragraphs available for this class

        </div>
      }

    <Footer
    page={page}
    setPage={setPage}
    pageSize={pageSize}
    totalParas={totalParas}
    parasLoading={loading}
    count={count}
    RetrainTarget={RetrainTarget}
    AutoMLTarget={AutoMLTarget}
    />


    </div>
  )
}

export default Index