
import * as React from "react";

export interface Stage2NodeProps {
  parent_info: string;
  node_name: string;
  completedPercentage: number;
  active: boolean;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
  goal: number;
}

const Stage2Node: React.FC<Stage2NodeProps> = ({
  active,
  onSelect,
  parent_info,
  node_name,
  completedPercentage,
  goal,
}) => {
  goal = goal === 0 ? 1 : goal;
  return (
    <div
    className={`cursor-pointer px-[1vw]  py-[0.8vw] mb-[0.8vw] rounded-[0.3vw]
    ${active?" bg-[#11256D] text-white":"bg-white  hover:bg-[#E5E5E5] "}
    `}
    
    onClick={onSelect}
    >
      <div className="font-semibold text-[0.9vw] text-[#9A9A9A]">{parent_info} </div>
      <div className=" font-semibold text-[1vw] text-gray " >
        {node_name} 
      </div>
      {/* <div style={{ width: "100%", backgroundColor: "#ffffff", height: 3 }}>
        <div
          style={{
            width: `${(completedPercentage * 100) / goal}%`,
            backgroundColor: "#11256D",
            height: 2,
          }}
        ></div>
      </div> */}
    </div>
  );
};

export default Stage2Node;