import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down.svg';
import axios from "../../utils/Axios";

import useWorkflowStore from "../../store/useWorkflowStore";
import useAdvancedMasterlist,{seedInitialSuggestions,removeWordFromSelected} from "../../store/useAdvancedMasterlist";
import useAuthStore from '../../store/authStore';

export default function MultiSelectDropdown() {

    const workflowStore = useWorkflowStore();
    const {userDetails} = useAuthStore();
   
    const {keywords,folders,setKeywords,selectedWords, setFolders, setSelectedWords} = useAdvancedMasterlist(state=>({
        keywords:state.keywords,
        setKeywords:state.setKeywords,
        setRecentFolders:state.setRecentFolders,
        folders:state.folders,
        setFolders:state.setFolders,
        selectedWords:state.selectedWords,
        setSelectedWords:state.setSelectedWords
    }));


    const [isOpen, setIsOpen] = useState<boolean>();
    const ref = useRef<any>();

    const [nGram,setNGram] = useState<number>(1);
    const [stopWords,setStopWords] = useState<boolean>(false);
    const [date,setDate] =useState<boolean>(false);
    const [number,setNumber] = useState<boolean>(false);
    const [canEdit,setCanEdit] = useState<boolean>(false);

    useEffect(() => {
        setCanEdit(workflowStore?.currentProject?.createdBy === userDetails?.userId);
        let nGramValue = workflowStore?.currentWorkflow?.alpdsInfo?.nGramValue ? workflowStore?.currentWorkflow?.alpdsInfo?.nGramValue : 1;
        let stopWords = workflowStore?.currentWorkflow?.alpdsInfo?.stopWords || false;
        let dates= workflowStore?.currentWorkflow?.alpdsInfo?.date || false;
        let numbers = workflowStore?.currentWorkflow?.alpdsInfo?.number || false;
        setNGram(nGramValue);
        setStopWords(stopWords);
        setNumber(numbers);
        setDate(dates);
    }, []);

    useEffect(()=>{

        setNGram(workflowStore.currentWorkflow?.alpdsInfo.nGramValue);
        setStopWords(workflowStore.currentWorkflow?.alpdsInfo.stopWords)

    },[workflowStore.currentWorkflow?.alpdsInfo.nGramValue,
        workflowStore.currentWorkflow?.alpdsInfo.stopWords,
        workflowStore?.currentWorkflow?.alpdsInfo?.date,
        workflowStore?.currentWorkflow?.alpdsInfo?.number
    
    ]);

    const onCheck =(checked:boolean,ngram:number)=>{

        if(checked)
        {
            setNGram(ngram);
        }
        else if(ngram>1 && ngram===nGram)
        {
            setNGram(ngram-1);
        }
        else if(ngram>1 && ngram<nGram)
        {
            setNGram(ngram);
        }
        else{
            setNGram(1);
        }
    }

    function updateKeywords() {

        let workflow = workflowStore.currentWorkflow;

        axios.post("/advancedmasterlist/intiate_am", { 
            workflowId: workflow?.workflowId,
            ngram: nGram,
            stopwords: stopWords,
            date:date,
            number:number
         })
        .then((res) => {
            setIsOpen(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    return (
        <div className='relative'  >
            {isOpen && <div data-testid="close-multi-select-test" className='fixed top-0 left-0 h-screen w-screen z-[80]' onClick={() => setIsOpen(false)}></div>}
            <div className={`bg-white ${isOpen ? "rounded-t-lg " : "rounded-lg delay-200 "} 
                        border-2 r h-[2.5vw] w-[15vw] 
                        cursor-pointer
                         `}
            >
                <div className='flex items-center justify-between h-full  px-2'
                    data-testid="open-multi-select-test"
                    onClick={() => setIsOpen((prev: boolean | undefined) => !prev)}>
                    <div className=' text-[0.9vw]'  >{`Advanced Options`}</div>
                    <div><ArrowDownIcon className='w-[1vw] h-[1vw]' /></div>

                </div>
                {<div
                    ref={ref}
                    className={`absolute bg-white  w-[15vw]
                            top-[100%] right-0 border-t-0 z-[91]
                            rounded-b-lg 
                            duration-300
                            shadow-md
                            ${isOpen ? ` border-2  ` : "scale-y-0"} origin-top overflow-hidden`}>
                    <div className={`space-y-[0.7vw] ${isOpen ? "opacity-1" : "opacity-0"}
                duration-200  cursor-default p-2`} >

                        <div className='flex items-center justify-between md:py-0 sm:py-0.5'>
                            <p className='text-[0.9vw]'>Include Stopwords</p>
                            <input type="checkbox" className='w-[1.1vw] h-[1.1vw]'
                             checked={stopWords}
                             disabled={!canEdit}
                             onChange={(checked)=>setStopWords(!stopWords)}
                             value={workflowStore?.currentWorkflow?.alpdsInfo?.stopWords}
                             data-testid="stopwords-checkbox-test"
                        />
                        </div>
                        {/* date */}
                        <div className='flex items-center justify-between md:py-0 sm:py-0.5'>
                            <p className='text-[0.9vw]'>Include Date</p>
                            <input type="checkbox" className='w-[1.1vw] h-[1.1vw]'
                             checked={date}
                             disabled={!canEdit}
                             onChange={(checked)=>setDate(!date)}
                             value={workflowStore?.currentWorkflow?.alpdsInfo?.date}
                             data-testid="stopwords-checkbox-test"
                        />
                        </div>

                        {/* numbers */}
                        <div className='flex items-center justify-between md:py-0 sm:py-0.5'>
                            <p className='text-[0.9vw]'>Include Numbers</p>
                            <input type="checkbox" className='w-[1.1vw] h-[1.1vw]'
                             checked={number}
                             disabled={!canEdit}
                             onChange={(checked)=>setNumber(!number)}
                             value={workflowStore?.currentWorkflow?.alpdsInfo?.numbers}
                             data-testid="stopwords-checkbox-test"
                        />
                        </div>

                        <div className='flex items-center justify-between md:py-0 sm:py-0.5'>
                            <p className='text-[0.9vw]'>1 Gram</p>
                            <input type="checkbox" className='w-[1.1vw] h-[1.1vw]'
                            disabled={!canEdit}
                            checked={nGram!==undefined&& nGram>=1}
                            onChange={(checked)=>onCheck(checked.target.checked,1)} 
                            data-testid="1gram-checkbox-test"
                        />
                        </div>
                        <div className='flex items-center justify-between md:py-0 sm:py-0.5'>
                            <p className='text-[0.9vw]'>2 Gram</p>
                            <input type="checkbox" className='w-[1.1vw] h-[1.1vw]'
                            disabled={!canEdit}
                            checked={nGram!==undefined&& nGram>1}
                            onChange={(checked)=>onCheck(checked.target.checked,2)}
                            data-testid="2gram-checkbox-test"
                        />
                        </div>
                        <div className='flex items-center justify-between md:py-0 sm:py-0.5'>
                            <p className='text-[0.9vw]'>3 Gram</p>
                            <input type="checkbox" 
                            disabled={!canEdit}
                            checked={nGram!==undefined&& nGram===3}
                            onChange={(checked)=>onCheck(checked.target.checked,3)}

                            className='w-[1.1vw] h-[1.1vw]' 
                            data-testid="3gram-checkbox-test"
                        />
                        </div>
                        {/* <div className='flex items-center justify-between md:py-0 sm:py-0.5'>
                            <p className='text-[0.8vw]'>Test</p>
                            <input type="checkbox" 
                            className='scale-150' />
                        </div> */}
                      {canEdit &&  <div className='flex items-center justify-end'>
                            <div
                                className='rounded-md text-center p-[0.5vw] text-[0.7vw] bg-[#11256D] text-white 
                                           w-1/2 hover:scale-105 cursor-pointer duration-500'
                                onClick={updateKeywords}
                                data-testid="multi-select-done-test"
                            >Done</div> {/* func */}
                        </div>}
                    </div>
                </div>}
            </div>

        </div>
    )
}
