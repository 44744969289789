import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";


import { toast } from "react-toastify";

import { useRecoilValue } from "recoil";
import { nodesAtom } from "../../../masterlist/atoms";
import NerPara from "../../../ner-components/NerPara";
import useWorkflowStore from "../../../../store/useWorkflowStore";
import Axios from '../../../../utils/Axios';

import ValidationControls from "./MLCValidationControls";
import useKeyboard from "../../../../hooks/useKeyboard";
import { getClassesInAlphabeticOrder } from "../../../stage-2/helper";


export interface StageParaProps {

  para: any;

  accepted: null | string;
  stage: number;

  index: number;
  activePara: number;
  setActivePara: any;
  next: () => void;
  prev: () => void;
  readOnly?: boolean;
  keyboardEnabled?: boolean;
  setKeyboardEnabled?: any;

  setParas: any;
  setCount: any;

  fileInfo: any;
}

const ListViewPara: React.FC<StageParaProps> = (props) => {

  const {


    para,
    //paraData,
    //paraId,
    accepted,
    stage,
    //predictedIssueId,
    // nextPredictedIssueId,
    index,
    activePara,
    setActivePara,
    next,
    prev,
    readOnly,
    keyboardEnabled,
    setKeyboardEnabled,
    setParas,
    setCount,
    fileInfo

  } = props;

  // console.log("=== para ===",para)
  //let [expanded, setExpanded] = useState(false);
  let [fade, setFade] = useState(false);
  let [accept, setAccept] = useState<boolean | null>();
  let [loading, setLoading] = useState<boolean>(false);


  const { currentWorkflow } = useWorkflowStore();
  const isActive = index === activePara;
  let nodes = useRecoilValue(nodesAtom);

  const [delta, setDelta] = useState<any>({});
  const [tempValidations,setTempValidations] = useState<any[]>(para.validations || []);

  //sorted in alphabetic order
  const nextClasses: any[] = useMemo(() => getClassesInAlphabeticOrder(nodes), [nodes]);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  //Keyboard Shortcuts

  const divRef = useRef<HTMLDivElement>(null);
  const Enter = useKeyboard({ keyCode: "Enter" })
  const BackSpace = useKeyboard({ keyCode: "Tab" })
  let waitFor = (duration: number) => {
    return new Promise(resolve => setTimeout(resolve, duration));
  }



  useEffect(() => {

    const wait = async () => {
      await waitFor(100);
      //divRef.current?.scrollIntoView({block:"start"})
      divRef.current?.scrollIntoView({ block: "nearest" });
      if (activePara === 0) {
        divRef.current?.scrollIntoView({ block: "nearest", behavior: "smooth" });
        window.scrollTo({ top: 0, behavior: "smooth" });

      }
    };
    if (isActive) {
      wait();



    }

  }, [isActive]);



  useEffect(() => {
    const rejectParaAndMove = async () => {
      if (!keyboardEnabled) return;
      if (!BackSpace) return;
      if (!isActive) return;

      if ((!accepted || accepted !== "rejected") && !loading && !readOnly) {
        console.log("--para id--", para.paraId);
        // await rejectPara();
        //next();
      }


    }
    rejectParaAndMove();


  }, [isActive, BackSpace])
  //Keyboard Shortcuts

  React.useEffect(() => {
    setIsMounted(true);

    let temp: any = {};
    nodes.forEach((node: any) => {
      if (node.is_model_class)
        temp[node.node_id] = 0;
    });

    setDelta(temp);



  }, []);




  React.useEffect(() => {
    setAccept(accepted === "accepted");
  }, [accepted]);

  let handleTagSelection = async (validations: Array<any>,delta:any) => {

    setLoading(true);
    // updating the counting


    //check if validations array is empty or not
    //if validaions is empty
    //if already annotated 
    // if action is rejected then do not decrease count

    // if actions is accepted then decrease count

    //else
    // if action is accepted then do not increase count
    // else increase the count

    //intersection =>
    // let a = new Set([1, 2, 3, 4]);
    // let b = new Set([5, 4, 3, 2]);

    // let a_minus_b = new Set([...a].filter(x => !b.has(x)));
    // let b_minus_a = new Set([...b].filter(x => !a.has(x)));





    let isAnnotated = para.action;
    if (!validations.length) {
      // alert("validation is empty")
      if (isAnnotated) {
        if (para.action === "rejected") {
          // alert("already rejected")
          //do nothing
        } else {
          setCount((prev: number) => prev - 1);
        }
      }


      setParas((prev: any) => {
        let paras = [...prev];
        paras[index].validations = validations;
        paras[index].action = "rejected";
        return paras;
      })



    } else {
      if (isAnnotated) {
        if (para.action === "accepted") {
          //do nothing
        } else {
          setCount((prev: number) => prev + 1);
        }
      }else{
        setCount((prev: number) => prev + 1);
      }




      setParas((prev: any) => {
        let paras = [...prev];
        paras[index].validations = validations;
        paras[index].action = "accepted";
        return paras;
      })


      
    }
    setLoading(false);
    
    //reset the delta
    const temp = { ...delta };
    Object.keys(temp).forEach((key: any) => {
      temp[key] = 0
    })
    setDelta(temp);
    const reject_res = await Axios.post("/annotations/mlc-para-action", {
      paraId: para.paraId,
      //action: "accepted",
      stage,
      workflowId: currentWorkflow?.workflowId,
      validations: validations

    });

  };


  let dropDownOptions = useMemo(() => {
    if (currentWorkflow?.useNoTag) {

      return [{ value: "No Tag", text: "No Tag", id: "No Tag" }].concat(
        nextClasses.map((nd: any) => {
          let node: any = nodes.find((node) => node.node_id === nd[0]);
          let value = node.node_id
          let text = node.node_name
          let id = node.node_id
          return { value, text, id };

        })
      )
    }
    else {
      return nextClasses.map((nd: any) => {
        let node: any = nodes.find((node) => node.node_id === nd[0]);
        let value = node.node_id
        let text = node.node_name
        let id = node.node_id
        return { value, text, id };

      })


    }


  }, [nodes, nextClasses, currentWorkflow?.useNoTag]
  );



  const getClassName = (nodeId: string) => {

    if (currentWorkflow?.useNoTag) {
      if (nodeId === "No Tag") return "No Tag";
    }

    return nodes.find((node: any) => node.node_id === nodeId)?.node_name || ""


  }


  return (
    <div
      ref={divRef}
      onClick={() => setActivePara(index)}
      onMouseDown={(ev) => {
        //nerStore.setCurrentParaId(paraId);
      }}
      key={para._id}
      className={`w-full flex  duration-200  border min-h-[8vw]
      scroll-mt-[10vw] scroll-mb-[5vw]
      ${fade ? "opacity-0" : ""}
      ${isMounted ? "" : "opacity-0"}
      ${isActive ? "border-[#11256d] shadow-lg" : "border-transparent"}
      ${para.action ? "bg-gray-100" : "bg-white"}
      `}
    >
      <div
        className={"w-[60%]  rounded-[0.3vw] py-[1vw] px-[1.5vw] "}
      >
        <div className="flex border-b pb-[0.2vw] justify-between w-full">
          <p className="text-[0.8vw]   pb-1">{para?.paraSeqId} </p>
          <p className="text-[0.8vw]  pb-1">{fileInfo[para?.fileId || ""]?.fileName}</p>
        </div>
        <NerPara 
        key={para._id} 
        paraData={para.paraData} 
        paraId={para.paraId} 
        expanded={isActive}
        minlength={650}
        />
      </div>

      <div className=" border my-[1vw] border-dashed border-l-1"></div>

      <div className="w-[40%]">

        <ValidationControls

          handleTagSelection={handleTagSelection}
          loading={loading}
          readOnly={false}

          validations={para.validations}
          dropdownOptions={dropDownOptions}
          accepted={accepted}
          node_name={getClassName(para?.stage_info?.predictedIssueId[0])}
          keyboardEnabled={keyboardEnabled || false}
          setKeyboardEnabled={setKeyboardEnabled}
          active={isActive}

          others={{
            delta,
            setDelta,
            tempValidations,
            setTempValidations
          }}



        />
      </div>

    </div>
  );
};

export default ListViewPara;
