import { toast } from 'react-toastify';
import Axios from '../../../utils/Axios';
import { IEntity } from '../types';
//fetch nerlist
export const getEntity = async (workflowId:string)=>{

    try{
        const res = await Axios.post("ner/get-entity-list",{
            workflowId:workflowId
        });

        let entityList = res.data.data.entities;
        console.log(entityList);
        return entityList;
        



    }catch(e){
        console.log(e);
        toast.warn("There was an error while fetching entity list");
        return null;
    }


}

//create entity
export const createEntity = async (workflowId:string,entity:IEntity)=>{


   
    try{
        const res = await Axios.post("ner/create-entity",{
            workflowId:workflowId,
            ...entity
        });

        let result = res.data?.data?.result;
        console.log(result);
        return result;
        



    }catch(e){
        console.log(e);
        toast.warn("There was an error while creating entity");
        return null;
    }




}
//update entity
export const updateEntity = async (workflowId:string,entity:IEntity)=>{


   
    try{
        const res = await Axios.post("ner/update-entity",{
            workflowId:workflowId,
            entity
        });

        let result = res.data?.data?.result;
        console.log(result);
        return result;
        



    }catch(e){
        console.log(e);
        toast.warn("There was an error while creating entity");
        return null;
    }




}
//delete entity
export const deletEntity = async(entityId:string,workflowId:string)=>{

    try{
        const res = await Axios.post("ner/delete-entity",{
            entityId,
            workflowId
        });

        let result = res.data?.data?.result;
        console.log(result);
        return result;
        



    }catch(e){
        console.log(e);
        toast.warn("There was an error while deleting entity");
        return null;
    }

}

//start annotation
export const triggerNERAnnotation =async (workflowId:string,batchNumber:number)
                                                            :Promise<boolean> => {

    try{
        await Axios.post("/masterlist/markLocked", {
            workflowId:workflowId,
            markAs: true
          });
        await Axios.post("/integration/ner-stage-1",{
            workflowId,
            batchNumber
        });
        return true;

        
    }catch(e){
        toast.error("There was an error while intiating validation, please refresh and try again");
        return true;
    }


}


//remove from annotation annotation
export const removeFromAnnotation =async (workflowId:string,entities:string[]) :Promise<boolean> => {

    try{
        await Axios.post("/ner/remove-from-automl",{
            workflowId,
            entities
        });
        return true;

        
    }catch(e){
        toast.error("There was an error while removing the entities from annotation");
        return true;
    }


}