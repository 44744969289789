import React, { useEffect, useState } from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Select, Checkbox } from 'antd';
import { useFormik } from "formik";
import axios from "../../utils/Axios";
import useAuthStore from "../../store/authStore";
import useProjectStore from "../../store/projectStore";
import useWorkflowStore from "../../store/useWorkflowStore";
import useCompanyUsersStore from "../../store/useCompanyUsersStore";
import UserList from "./userList";
import { ReactComponent as LoadingWhite } from '../../assets/loading_white.svg';
import { toast } from "react-toastify";
import { ReactComponent as LoadingCircle } from '../../assets/loading_circle.svg';
import { ReactComponent as NoUsersFound } from '../../assets/users_not_found.svg';
import AccessControl from "../../components/AccessControl/AccessControl";

interface AccessManagementProps { }
interface Values {
  email: string;
  firstName: string;
  lastName: string;
}


const AccessManagement: React.FC<AccessManagementProps> = () => {

  let [progress, setProgress] = React.useState(false);
  let [userList, setList] = useState<Array<any>>([]);
  let [show, setShow] = React.useState(false);
  let [loading, setLoading] = useState(true);

  const auth = useAuthStore();
  const { companyUsers, setCompanyUsers } = useCompanyUsersStore();
  let { usage, setUsage } = useAuthStore();

  let validate = (values: Values) => {
    let regEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let errors: Partial<Values> = {};
    if (!values.email) {
      errors.email = "Required";
    }
    else if (!regEmail.test(values.email)) {
      errors.email = "Please enter a valid email Id";
    }
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }

    return errors;
  };

  useEffect(() => {

    const fetchUserList = async () => {
      try {
        // let serverRes = await axios.get("/user/companyuser")
        // console.log(serverRes);

        // if (companyUsers) {
          companyUsers.sort(function compareFn(a: any, b: any) { if (`${a.firstName} ${a.lastName}` <= `${b.firstName} ${b.lastName}`) return -1; else return 1; })
          setList(companyUsers)

          setLoading(false);
        // }
        // else {
        //   toast(serverRes.data?.message || "Some error occured while fetching users list.", {
        //     type: "info",
        //   });
        // }

      }
      catch (err: any) {
        toast("Some error occured while fetching users list.", {
          type: "warning",
        });
      }
    }
    
    if (companyUsers.length > 0) {
      fetchUserList();
    }

    console.log(companyUsers)

  }, [companyUsers]);



  let fetchCompanyUsers = async () => {
    try {
      let res = await axios.get(`/user/companyuser`);

      if (res && res.data.result) {
        console.log("setting");
        setCompanyUsers(res.data.data);

        let companyList = res.data.data;
        companyList.sort(function compareFn(a: any, b: any) { if (`${a.firstName} ${a.lastName}` <= `${b.firstName} ${b.lastName}`) return -1; else return 1; });

        setList(companyList);
      }
    } catch {
      toast("Could Not fetch Company Users. Please reload the page.", { type: toast.TYPE.ERROR });
    }
  };


  let handleFormSubmit = async (values: Values) => {
    try {
      setLoading(true);
      setProgress(true);

      if (values.firstName.length <= 1 || values.lastName.length <= 1) {
        toast("First Name and Last Name should have a minimum length of 2 characters", {
          type: "info",
        });

      } else {

        let serverRes = await axios.post("/user/adduser", {
          ...values,
          company: auth.userDetails?.company,
          userAgent: window.navigator.userAgent,
          ipAddress: "1.2.3.4.5.6",
          associatedBillingAccount: auth.userDetails?.userId,
        });
        if (serverRes.data.result) {

          // Updating state
          let temp = usage
          if (temp) {
              temp.details.noOfUsers = temp.details.noOfUsers + 1;
              setUsage(temp);
          }

          toast("User added successfully.", {
            type: "success",
          });
          
          fetchCompanyUsers();
          console.log("serverRes", serverRes)
          
          formik.resetForm();
        }
        else {
          console.log(serverRes)
          toast(serverRes.data?.message || "Some error occured, user was not added.", {
            type: "info",
          });
        }
      }

      setLoading(false);
      setProgress(false);
    }
    catch (err: any) {
      toast("Some error occured, user was not added.", {
        type: "warning",
      });

      setLoading(false);
      setProgress(false);
      formik.resetForm();
    }
  };
  let formik = useFormik<Values>({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    validate,
    onSubmit: handleFormSubmit,
  });

  let DisabledButton = () => {
    return (
      <div className="border mb-[2vw] rounded-[0.3vw] text-[1vw] border-black py-[0.5vw] w-[10vw] cursor-pointer hover:bg-gray-500 hover:text-white duration-300 text-center">
        Add New User
      </div>
    )
  }

  let DisabledButton2 = () => {
    return (
      <div className="border mb-[2vw] rounded-[0.3vw] text-[1vw] border-black py-[0.5vw] w-[10vw] cursor-pointer hover:bg-gray-500 hover:text-white duration-300 text-center mt-[1.5rem]">
        Add New User
      </div>
    )
  }
  
  return (
    <div className="w-full flex flex-col">
      <AccessControl RestrictedComp={DisabledButton} RequestedPerm="canCreateUsers">
        <div className="border mb-[2vw] rounded-[0.3vw] text-[1vw] border-black py-[0.5vw] w-[10vw] cursor-pointer hover:bg-primarycolor hover:text-white duration-300 text-center" onClick={() => { setShow(prevState => !prevState) }}>
          Add New User
        </div>
      </AccessControl>
      
      {show &&
        <div
          style={{
            boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
          }}
          className="bg-white mb-[1vw] p-[1.5vw]"

        >
          <p
            style={{
              fontSize: "1.1vw",
              color: "#1BA94C",
              fontWeight: 500,
            }}
          >
            New User
          </p>
          <form onSubmit={formik.handleSubmit}>

            <div className="flex mt-[1vw] space-x-[5vw]">
              <div>
                <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                  First Name
                </p>
                <input
                  className="text-[1vw] px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                  type="text"
                  name="firstName"
                  style={{
                    width: "22vw",
                    border: "0.1vw solid #C4C4C4"
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                <div className="absolute text-red-500 ml-[0.2vw] text-[0.85vw]">{formik.touched.firstName && formik.errors.firstName}</div>

              </div>

              <div>
                <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                  Last Name
                </p>
                <input
                  type="text"
                  name="lastName"
                  className="text-[1vw] px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                  style={{
                    width: "22vw",
                    border: "0.1vw solid #C4C4C4"
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />

                <div className="absolute text-red-500 ml-[0.2vw] text-[0.85vw]">{formik.touched.lastName && formik.errors.lastName}</div>

              </div>
              <div>
                <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                  Email Address
                </p>
                <div>
                  <input
                    name="email"
                    className="text-[1vw]  px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                    type="text"
                    style={{
                      width: "22vw",
                      border: "0.1vw solid #C4C4C4"
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}

                  />
                  <div className="absolute text-red-500 ml-[0.2vw] text-[0.85vw]">{formik.touched.email && formik.errors.email}</div>

                </div>
              </div>

            </div>
            <div className="mt-[2.5vw] flex w-full justify-end">
              <button
                className={`py-[0.5vw] w-[10vw] text-white text-[1vw] text-center rounded-[0.3vw] bg-[#11256D] ${formik.values.firstName.length>0 &&  formik.values.lastName.length>0 &&formik.values.email.length>0? "cursor-pointer duration-300 hover:scale-105" : "cursor-default"}`}
                style={{ background: "#11256D" }}
                disabled={formik.isSubmitting}
                type={"submit"}
                onClick={() => {
                  formik.submitForm();
                }} >
                {formik.isSubmitting ? <LoadingWhite width={"1.8vw"} height="1.8vw" /> : 'Add User'} 
              </button>
            </div>

          </form>
        </div>
      }

      {
        // length = 1 because the current user is getting returned
        userList.length > 1 ?
          userList.filter((user: any) => user.email != auth.userDetails?.email).map((user: any) => {
            return <UserList user={user} setList={setList} />
          }) : 
          <div className="h-[20vw] flex flex-col items-center justify-center mt-[1rem]">
            <NoUsersFound />
            <div className="uppercase text-[1.5vw] font-semibold text-[#777777] mt-[1rem]">No Users Found</div>
            <p className="text-center mt-4 text-[#777777] text-[1vw]">
              Looks like you have not added users to the company yet. The user information will<br />
              appear here as soon as you add your first user.
            </p>

            <AccessControl RestrictedComp={DisabledButton2} RequestedPerm="canCreateUsers">
              <div className="border mb-[2vw] rounded-[0.3vw] text-[1vw] border-black py-[0.5vw] w-[10vw] cursor-pointer hover:bg-primarycolor hover:text-white duration-300 text-center mt-[1.5rem]" onClick={() => { setShow(prevState => !prevState) }}>
                Add New User
              </div>
            </AccessControl>
          </div>
      }

      {
        loading &&
        (<div className="flex items-center justify-center mt-[2vw]">
            <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />
        </div>)
      }

    </div>
  );
};

export default AccessManagement;