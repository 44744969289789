import React from 'react'
import useWorkflowStore from '../store/useWorkflowStore'
import template from '../template';
import AppLayout from '../components/layouts/AppLayout';
import PageLayout from '../components/layouts/PageLayout';
import { useNavigate } from 'react-router-dom';

export default function ModelComparisonPage() {

    const project = useWorkflowStore(state=>state.currentProject);
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const navigate = useNavigate();

    const projectType = project?.projectType ;
    const workflowType = project?.workflowType;

const modelTuning =projectType 
                        && workflowType
                        &&  template[projectType][workflowType]?.modelTuning;




if(  !modelTuning){
      navigate("/workflow?workflowId="+workflowId);
}


  return (
    <AppLayout>
        <PageLayout 
        pageIconUrl='/images/active_automl.svg'
        pageTitle={`${project?.name}`}
        pageSubHeading='Hyperparameter Tuning'
        >
            <div>{modelTuning && 
                React.createElement(modelTuning?.component)}</div>
        </PageLayout>
    </AppLayout>
  )
}

