
export let BASE_URL = "https://localhost:8000";

export let SIGN_IN_URL = BASE_URL + "/user/login";
export let SIGN_UP_URL = BASE_URL + "/user/signup";
export let CREATE_PRACTICE_AREA_URL = BASE_URL + "/pa/create";
export let GET_ALL_PA = BASE_URL + "/pa/get";
export let UPLOAD_FILE_URL = BASE_URL + "/upload";
export let MODE_TRAINING_2 = BASE_URL + "/api/modelTraining2";
export let GET_ISSUES = BASE_URL + "/api//annotations/issues";
export let ISSUE_PARAS = BASE_URL + "/api/annotations/issue-paras";
export let SEED_PARA = BASE_URL + "/annotations/seed-para";
export let ACCEPT_PARA = BASE_URL + "/annotations/accept-paras";
export let COUNT_PARAS = BASE_URL + "/annotations/countParaDatas";
export let GET_NEW_TAG = BASE_URL + "/annotations/getNewTag";
export let GET_NEXT_TAG = BASE_URL + "/getNextNode";

//billing urls
export let VERIFY_BILLING_ACCOUNT = BASE_URL + "/billingAccount/verify";
export let CREATE_STRIPE_SESSION =
  BASE_URL + "/billingAccount/create-new-session";
export let GET_NOTIFICATIONS = BASE_URL + "/getNotifications";
export let VERIFY_PAYMENT = BASE_URL + "/payment/create_customer";
export let VERIFY_AND_UPGRADE_PLAN =
  BASE_URL + "/verify_payment_and_upgrade_plan";
export let UPDATE_PLAN = BASE_URL + "/billingAccount/update_plan";
export let PAY_DUE_BILL = BASE_URL + "/create_payment_link";
export let UPGRADE_PLAN = BASE_URL + "/billingAccount/upgrade_plan";

//usage urls
export let POPULATE_NODES = BASE_URL + "/masterlist/getNodes";
export let GET_ALL_MASTERLISTS = BASE_URL + "/masterlist/count";
export let CALCULATE_USAGE = BASE_URL + "/get_usage";
export let GET_USAGE_DATA = BASE_URL + "/get_usage_data";

//all mastelist releated urls
export let CREATE_MASTERLIST = BASE_URL + "/createMasterlist";
export let GET_MASTERLIST = BASE_URL + "/getMasterlist";
export let ADD_NODE = BASE_URL + "/createNode";
export let DELETE_NODE = BASE_URL + "/deleteNode";
export let UPDATE_NODE = BASE_URL + "/updateNode";
export let NODE_UPDATE_BULK = BASE_URL + "/updateNodeBulk";
export let USE_NO_TAG = BASE_URL + "/pa/useNoTag";
export let MARK_MASTERLIST_LOCKED = BASE_URL + "/markLocked";

//data validation urls
export let GET_NODES = BASE_URL + "/annotations/issues";

export let COUNT_STAGE1_VALIDATED = BASE_URL + "/annotations/countStage1";
export let COUNT_STAGE2_VALIDATED = BASE_URL + "/annotations/countStage2";
export let GET_NODE_PARAS = BASE_URL + "/annotations/node-paras";
export let STAGE_1_INTEGRATE = BASE_URL + "/stage1-integrate";
export let STAGE_2_INTEGRATE = BASE_URL + "/stage2-integrate";
export let STAGE_2_NEXT_BATCH = BASE_URL + "/stage2-batch";
export let AUTOML_INTEGRATION = BASE_URL + "/integrate-automl";
//get model training 1 data
export let MODEL_TRAINING_DATA = BASE_URL + "/modelTrainingData1";
export let GET_MODEL_TRAINING_2_DATA = BASE_URL + "/modelTrainingData2";
export let GENERATE_TOKEN = BASE_URL + "/integrate-generate-token";
export let PREDICT_DS = BASE_URL + "/predict_dataset";
export let EXPORT_DATASET = BASE_URL + "/export_dataset";
export let DOWNLOAD_DATA = BASE_URL + "/downloadData";
export let GET_ALL_UPLOADED_FILES = BASE_URL + "/getUploadedFiles";
export let DELETE_FILE = BASE_URL + "/deleteFile";
export let PRACTICE_AREA_DETAILS = BASE_URL + "/pa/getSinglePracDetails";
export let DELETE_BULK_NODES = BASE_URL + "/deleteBulkNodes";
export let VERIFY_EMAIL_ID = BASE_URL + "/user/verifyEmail";
export let VERIFY_OTP = BASE_URL + "/user/verifyOtp";
export let UDPATE_STAGE = BASE_URL + "/pa/stages";
export let STAGE_INFO = BASE_URL + "/stageParaInfo";
//for para information
export let PARA_COUNT_URL = BASE_URL + "/getParaCount";
export let TRIGGER_FILE_SPLIT = BASE_URL + "/triggerUploadSplit";
export let RESET_STAGE_1 = BASE_URL + "/resetStage1";
export let GET_ALL_NER_WRDS = BASE_URL + "/getAllNerWrds";
export let CHECK_MASTERLIST = BASE_URL + "/validateMasterlist";
export let ADD_NER_WORD = BASE_URL + "/addNerNerInfo";
export let REMOVE_NER_WORD = BASE_URL + "/removeNerInfo";
export let ASSIGN_NER_TO_PARA = BASE_URL + "/assignNerWrd";

export let GET_ALL_SUB_USERS = BASE_URL + "/assignNerWrd";
export let CHECK_PASSWORD_URL = BASE_URL + "/assignNerWrd";
export let GET_ALL_PA_LINKED_TO_BILLING = BASE_URL + "/assignNerWrd";
export let UPDATE_PASSWORD_URL = BASE_URL + "/assignNerWrd";

