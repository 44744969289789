import { useEffect, useState } from "react";
import useWorkflowStore from "../../../store/useWorkflowStore";
//import usePracticeArea from "../../../store/zustand/usePracticeArea";
import axios from "../../../utils/Axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Masterlist } from "../utils/interface";
import {useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import { layersAtom, masterlistIdAtom, nodesAtom } from "../atoms";
import {useSearchParams} from 'react-router-dom';

export interface MasterlistFetchType {
    loading: boolean;
    data: Masterlist | undefined;
    error: Error | undefined;
    setData:any
}

let useMasterlist = (): MasterlistFetchType => {
    let [loading, setLoading] = useState<boolean>(true);
    let [searchParams] = useSearchParams();
    let workflowId = searchParams.get("workflowId");
    let [data, setData] = useState<Masterlist>();
    let [error, setError] = useState<any>();
    const workflowStore = useWorkflowStore();
    const navigator = useNavigate();
    let [layers, setLayers] = useRecoilState(layersAtom);
    let setMasterlist = useSetRecoilState(masterlistIdAtom);
    // let { currentWorkflow } = usePracticeArea();
    let [nodes, setNodes] = useRecoilState(nodesAtom);

    
    let fetchMasterlist = async () => {
        //if (currentPracticeArea !== null) {
        setLoading(true);
        try {
            let masterlist: any = await axios.post("/masterlist/getMasterlist", {
               // workflowId: workflowStore.currentWorkflow?.workflowId,
               workflowId
            });
            if(!masterlist.data.data)
            {
                //console.log("Could not get masterlist")
                setData(undefined);
               
                setMasterlist(null);
                setLayers([]);
                setNodes([]);

            //    navigator("/workflow/upload?workflowId="+workflowStore.currentWorkflow?.workflowId);
            }
            setData(masterlist.data.data);
        } catch (err) {
            setError(err);
        }
        setLoading(false);
        // }
    };

    useEffect(() => {
       // console.log("=== Fetching New MasterList ===")
        if(workflowStore.currentWorkflow )
        fetchMasterlist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowId,workflowStore.currentWorkflow?.workflowId]);

    
    useEffect(() => {
       // console.log("=== Fetching New MasterList ===")
        if(workflowId)
        {

            fetchMasterlist();
        }
        else{
            toast("no workflowID")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { loading, data, error,setData };
};

export default useMasterlist;
