import React, { useEffect, useState } from "react";


//global stores
import useWorkflowStore from "../../store/useWorkflowStore";

//3rd Party modules
import { toast } from "react-toastify";

import { Divider } from 'antd';

//3rd Party Icons
import { FiSearch } from 'react-icons/fi';
import { MdClear } from 'react-icons/md'
import { IconContext } from 'react-icons';
import UserStatisticsComponent from "./UserStatisticsComponent";

import { Select } from 'antd';
import template from "../../template";
import { TrainStatus, checkCurrentTrainStatus } from "../../utils/navigationHelper";

interface UserStatsProps {
  stage: number,
  batchNumber: number,
  users: Array<any>,
  requestType: number,
  setRequestType: any
}

const UserStats: React.FC<UserStatsProps> = ({ stage, batchNumber, users, requestType, setRequestType }) => {


  let [searchTerm, setSearchTerm] = useState<string>("");
  let [selectedUser, setSelectedUser] = useState<any>();
  let [filteredList, setFilteredList] = useState<Array<any>>([]);
  let [displayDropdown, setDisplayDropdown] = useState<boolean>(true);

  const projectType:string | null = useWorkflowStore(state=>state.currentProject)?.projectType || "";
  const workflowType:string | null = useWorkflowStore(state=>state.currentProject)?.workflowType || "";

  const UserStatisticsComp:any = template[projectType][workflowType].summary.userStatisticsComp; 


  const workflow = useWorkflowStore(state=>state.currentWorkflow);
  const currentStatus = checkCurrentTrainStatus(workflow);


  //Search SideEffect
  useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredList([]);
      setSelectedUser(null);
    } else {

      setFilteredList(
        users.filter(
          u => u.firstName.toLowerCase().startsWith(searchTerm.toLowerCase())
            || u.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
            || u.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())

        )
      )

      //setFilteredList((prev:Array<any>)=>prev.sort())

    }


  }, [searchTerm, users])

  return (
    <>
    <div className="flex   items-center justify-between mb-[1vw]">
    {stage === 2 && <div className="w-[200px] mx-3">
          <Select
            style={{ width: "15vw"}}
            defaultValue={1} onChange={setRequestType} value={requestType} 
            className="w-[200px] !text-[1.1vw] !py-[0.4vw] !rounded bg-[#EAEAEA]" bordered={false}>

            {currentStatus === TrainStatus.NotTrained && 
              <Select.Option className="text-[1vw] py-1" value={1}>Current Batch</Select.Option>}
            <Select.Option className="text-[1vw] py-1" value={2}>All Stage 2 Batches</Select.Option>
            <Select.Option className="text-[1vw] py-1" value={3}>All Annotated Data</Select.Option>
          </Select>
        </div>}
      <div className={"flex  items-center w-auto   relative  "}>


        <input
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          placeholder={"Search User"}
          type="search"
          className={" w-[35vw] max-w-[400px] h-[50px]  text-base flex-grow p-3 bg-white rounded-l-full outline-none z-[20]"}
        />


        <div className="flex  bg-white rounded-r-full h-[50px] items-center h-full">

            <div className={`flex items-center ${searchTerm.length>0?"":"opacity-0"}`}>

            <MdClear size={20} className="ml-1 cursor-pointer" onClick={() => {
              setSearchTerm("")
              setSelectedUser(null)
            }} />
            <Divider type="vertical" />
            </div>
          {searchTerm.length > 0 && <>
          </>}

          <IconContext.Provider
            value={{ color: `#2c2c2c`, size: `30px` }}
          >
            <div className=" mr-5">
              <FiSearch />
            </div>
          </IconContext.Provider>
          {/* </div> */}
        </div>

        {searchTerm && searchTerm.length > 0 && !selectedUser && <div onClick={() => {
          setSearchTerm("");
        }} className="fixed z-40 top-0  left-0 fixed  w-[100vw] h-[100vh]"></div>}

        <div className={` top-[105%] custom_scroll absolute w-full  max-h-96 overflow-y-scroll shadow-md bg-white  z-50`}>
          {/* {filteredList && filteredList.length === 0 && searchTerm.length > 0 && (
          <div className="w-[500px] flex justify-center p-4 text-xl">
            NO USER FOUND
          </div>
        )}  */}

          {displayDropdown && filteredList.length > 0 && filteredList.map((user: any, idx) => {
            return (
              <div
                onClick={() => {
                  setSelectedUser(user)
                  setSearchTerm(user.firstName + " " + user.lastName)
                }}
                key={user.firstName}
                className={
                  " px-3 py-2 flex justify-between hover:bg-gray-100 cursor-pointer"
                }
              >
                <div>
                  <div className={"text-lg"}>{user.firstName} {user.lastName}</div>
                </div>

              </div>
            );
          })}
        </div>

      </div>
     
      </div>

      <div className="custom_scroll" 
      // style={{ height: "70vh", overflowY: "scroll" }}
      >
        {
          selectedUser ?
          <UserStatisticsComp requestType={requestType} batchNumber={batchNumber} stage={stage} userInfo={selectedUser} /> :

            users.length > 0 && <div>
              {
                users.map(userInfo => <UserStatisticsComp requestType={requestType} batchNumber={batchNumber} stage={stage} userInfo={userInfo} />)
              }

            </div>
        }
      </div>
    </>

  );
};
export default UserStats;
