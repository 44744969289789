import React, { useEffect, useState } from 'react'
import {ReactComponent as LoadingCircle} from '../../../assets/loading_circle.svg';
import {AiFillCaretDown} from 'react-icons/ai'
import {ReactComponent as CheckerBoxIcon} from '../../assets/CheckBoxIcon.svg';
import useWorkflowStore from '../../store/useWorkflowStore';
import { useNavigate } from 'react-router-dom';
import Button from '../mlc-components/model-comparison/Button';
import { callModelComparisonAPI, callSaveSelectedModelAPI } from '../mlc-components/model-comparison/_apiCall';
import { toast } from 'react-toastify';
import ModelCompHeader from '../mlc-components/model-comparison/Header';
import Loader from '../loader';
import LoadingScreen from '../loading-screen';
import template from '../../template';



// const Loading =()=>{
//     return <div className=' flex flex-col gap-[1.5vw] items-center justify-center
//                              w-full h-[35vw]'>
//         <p className='text-[2.2vw] text-[#9A9A9A]'>Loading Models...</p>
//         <LoadingCircle className='w-[20vw] h-[20vw] animate-spin' />
//     </div>
// }


const ModelSelectionHeader = ()=>{

    return <div className='flex justify-between items-center
                         w-full h-[6vw] px-[2vw]
                         text-[1.1vw]
                         border-b'>


    <div className='flex flex-col'>
        <p className='text-[#9A9A9A] '>Total Paragaphs</p>
        <p className='font-medium '>{5000}</p>

    </div>

    <div className='flex flex-col'>
        <p className='text-[#9A9A9A] '>NO. OF PARAGRAPHS VALIDATED</p>
        <p className='font-medium '>{500}</p>

    </div>
    <div className='flex flex-col'>
        <p className='text-[#9A9A9A] '>NO. OF NODES</p>
        <p className='font-medium '>{5}</p>

    </div>
    <div className='flex flex-col'>
        <p className='text-[#9A9A9A]'>TOTAL NO. OF DOCUMENTS</p>
        <p className='font-medium'>{1}</p>

    </div>




    </div>


}


const ModelRow = ({title,data,isRecommended,selected,setSelected}:any)=>{

    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    return <div className='w-full h-[4vw] bg-white
                            flex items-center justify-start
                            text-[1vw] px-[0.2vw]
                            hover:shadow-md
                            duration-200 cursor-pointer
                            select-none'
            onClick={()=>selected!==title && setSelected(title)}
            >
        
        <div className='w-[10%] flex items-center justify-center'>
            
            {
                <>
                {selected !== title 
                ? <div 
                className='w-[1.4vw] h-[1.4vw] border-[0.15vw]
                             rounded-full inset-md
                             cursor-pointer'
                    //onClick={()=>setSelected(title)}
                ></div>
                :<CheckerBoxIcon className='w-[1.4vw] h-[1.4vw]' />
                }
                </>
            }
        </div>
        <div className='w-[45%]'>
           <p className='font-medium cursor-pointer'
           
           >{title}
           
            {isRecommended && <span className='ml-[1.5vw] p-[0.4vw]
                             text-primaryBlue bg-[#EAEAEA]
                             rounded-[0.2vw] font-normal text-[1vw]'>
                Recommended
            </span>}
           </p> 
        </div>
        {/* <div className='w-[15%]'>
            {data?.precision?.toFixed(2)}
        </div>
        */}
        
        <div className='w-[15%] pl-[3.5vw]  '>
            {data?.f1_score?.toFixed(2)}
        </div>
        <div className='w-[15%] pl-[3.5vw]'>
            {workflow?.alpdsInfo?.trainInfo?.trainingParas - data.support}
        </div>
        <div className='w-[15%] pl-[1vw]'>
            {data?.support?.toFixed(2)}
        </div>




    </div>

}

const ModelsTable = ({data,selected,setSelected}:any)=>{

    const workflow = useWorkflowStore(state=>state.currentWorkflow);


    type SortStateType={
        key:string,
        type:"asc" | "desc"
    }
    const [sortState,setSortState] = useState<SortStateType>({
        key:"f1_score",
        type:"asc"
    });

    const selectedModel = workflow?.alpdsInfo?.selectedModel || "Logistic Regression";
    const modelComparision = workflow?.alpdsInfo?.modelComparision;
    const [keys,setKeys] = useState<string[]>([]);    
    const [recommended,setRecommended] = useState<string>();

    const modelComparision1:any= {
        "Logistic Regression": {
            "TP": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 7,
                "9": 0,
                "10": 0,
                "11": 1
            },
            "TN": {
                "0": 131,
                "1": 128,
                "2": 126,
                "3": 135,
                "4": 119,
                "5": 128,
                "6": 123,
                "7": 133,
                "8": 98,
                "9": 127,
                "10": 134,
                "11": 118
            },
            "FP": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
            },
            "FN": {
                "0": 11,
                "1": 14,
                "2": 16,
                "3": 7,
                "4": 23,
                "5": 14,
                "6": 19,
                "7": 9,
                "8": 37,
                "9": 15,
                "10": 8,
                "11": 23
            },
            "Precision": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 1,
                "9": 0,
                "10": 0,
                "11": 1
            },
            "Recall": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1590909090909091,
                "9": 0,
                "10": 0,
                "11": 0.041666666666666664
            },
            "F1 Score": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.2745098039215686,
                "9": 0,
                "10": 0,
                "11": 0.07999999999999999
            },
            "Support": {
                "0": 11,
                "1": 14,
                "2": 16,
                "3": 7,
                "4": 23,
                "5": 14,
                "6": 19,
                "7": 9,
                "8": 44,
                "9": 15,
                "10": 8,
                "11": 24
            },
            "Average_precision": 0.16666666666666666,
            "Average_recall": 0.01672979797979798,
            "Average_f1_score": 0.02954248366013072,
            "Average_support": 17
        },
        "Random Forest": {
            "TP": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 7,
                "9": 0,
                "10": 0,
                "11": 1
            },
            "TN": {
                "0": 131,
                "1": 128,
                "2": 126,
                "3": 135,
                "4": 119,
                "5": 128,
                "6": 123,
                "7": 133,
                "8": 98,
                "9": 127,
                "10": 134,
                "11": 118
            },
            "FP": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0
            },
            "FN": {
                "0": 11,
                "1": 14,
                "2": 16,
                "3": 7,
                "4": 23,
                "5": 14,
                "6": 19,
                "7": 9,
                "8": 37,
                "9": 15,
                "10": 8,
                "11": 23
            },
            "Precision": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 1,
                "9": 0,
                "10": 0,
                "11": 1
            },
            "Recall": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1590909090909091,
                "9": 0,
                "10": 0,
                "11": 0.041666666666666664
            },
            "F1 Score": {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.2745098039215686,
                "9": 0,
                "10": 0,
                "11": 0.07999999999999999
            },
            "Support": {
                "0": 11,
                "1": 14,
                "2": 16,
                "3": 7,
                "4": 23,
                "5": 14,
                "6": 19,
                "7": 9,
                "8": 44,
                "9": 15,
                "10": 8,
                "11": 24
            },
            "Average_precision": 0.15666666666666665,
            "Average_recall": 0.0672979797979798,
            "Average_f1_score": 0.01054248366013072,
            "Average_support": 15
        }
    };

    

    const headers =[
        {
            styles:"w-[10%]"
        },
        {
            title:"Model Name",
            key:"key",
            styles:"w-[45%]",
           
        },
       
        {
            title:"Micro F1 Score",
            styles:"w-[15%] ",
            key:"f1_score"
            
        },
        {
            title:"Training Paras",
            styles:"w-[15%] ",
            key:""
        },
        {
            title:"Support",
            styles:"w-[15%]",
            key:"support"
        },
        
    ]

    useEffect(()=>{
        console.log("=== sorting on mount ===",sortState);
        const temp =Object.keys(modelComparision);
        if(!temp.length) return;
        
        
            temp.sort((a,b)=>{
                return modelComparision[a]["f1_score"]>
                            modelComparision[b]["f1_score"]?
                            -1
                            :1});
        
        setKeys(temp);
        setSelected(temp[0]);
        setRecommended(temp[0]);
    },[]);
    useEffect(()=>{
        console.log("=== sort was changed ===",sortState);
        const temp =Object.keys(modelComparision);
        
        if(sortState.key === "key"){
            temp.sort((a,b)=>a>b?
            sortState.type==="asc"?1:-1
            :sortState.type==="asc"?-11:1)
          }else{

            temp.sort((a,b)=>{
                return modelComparision[a][sortState.key]>
                            modelComparision[b][sortState.key]?
                            sortState.type==="asc"?-1:1
                            :sortState.type==="asc"?1:-1});
        }
        setKeys(temp);
    },[sortState])




    return <div className='py-[1vw]'>
        {/* Header */}
        <div className='flex items-center justify-start w-full text-[1vw]'>


            {
                headers.map((header)=>{
                    return <div className={`flex items-center
                                    ${header.styles}`}>
                    {header.title? <div
                    className='flex items-center gap-[0.4vw]
                                cursor-pointer select-none'
                    onClick={()=>{
                        let key=header.key
                        setSortState({
                            key:key,
                            type:sortState.key===key?
                            sortState.type==="asc"?"desc":"asc":"asc"
                        })
                    }}
                    >
                    <p className='text-[#999999] uppercase'>{header.title}</p>
                    <AiFillCaretDown 
                    color='#999999'
                    className={`w-[1.1vw] h-[1.1vw] duration-200
                            ${sortState.key === header.key?"":"hidden"}
                            ${sortState.key ===header.key
                                        ?sortState.type==="desc"?"rotate-180":""
                                        :""}`} />
                    </div>:null
                    }
    
                </div>

                })
            }
           




        </div>


        {/* Body */}
        <div className='mt-[1vw] flex flex-col gap-[1vw]'>
            {
                  keys.map((key,index)=>{
                    return <ModelRow
                            key={key}
                            title={key}
                            data={modelComparision[key]}
                            isRecommended ={key === recommended} 
                            selected={selected}
                            setSelected={setSelected}
                            />
                  })             
            }
            
        </div>
    </div>

}






export default function ModelComparison() {

    const navigate = useNavigate();
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const workflowId = workflow?.workflowId;
    const [isLoading,setIsLoading] = useState<boolean>(true);
    const [loading,setLoading] = useState<boolean>(false);
    const [selected,setSelected] = useState<string>();

    const trainStage:any = workflow?.stages.find((stage)=>stage.name === "train");

    const isModelSelectionInProgress = workflow?.alpdsInfo?.percentageStatus?.automlProgress?.modelComparisionProgress; 
    const projectType:string = useWorkflowStore(state=>state.currentProject)?.projectType || "";
    const isModelTuning = trainStage?.inModelTuning;
    const isModelSelection = trainStage?.inModelSelection;
    const isTrain = trainStage?.active;







    const handleModelSelection =async()=>{

        if(!workflowId ) return;
        if(!selected) {
            toast.warn("Please select a model and try again!");
            return;
        }

        setLoading(true)

        await callSaveSelectedModelAPI(workflowId,selected);

        setLoading(false);

        navigate("/workflow/model-tuning?workflowId="+workflowId);





    }

   

    useEffect(()=>{
        if(!workflowId) return;
        if(isModelTuning) return;
        if(!isTrain) return;

        if(isModelSelectionInProgress !== undefined 
            && isModelSelectionInProgress <100 ) {
                setIsLoading(true);
                return
            }

            //alert(isModelSelectionInProgress);
        if(isModelSelectionInProgress === 100){
           setIsLoading(false);
        }



    },[isModelSelectionInProgress])


  return (
    <div className=''>

        <div>

           {!isLoading ?<div>

            <ModelCompHeader 
            showLlm={template[projectType]?.hasLLM}/>
            <ModelsTable 
            selected={selected}
            setSelected={setSelected}
            />

            <div className='flex items-center justify-end py-[1vw]'>


            <Button
            text={"Hyperparameter Tuning"}
            styles={"!w-[20vw]"}
            loading={loading}
            disabled={loading}
            onClick={handleModelSelection}

            />
            </div>


           </div>:<LoadingScreen 
           msg='Loading Models...'
           compleated={isModelSelectionInProgress || 0} />}
        </div>
    </div>
  )
}
