
import * as React from "react";
import useWorkflowStore from "../../store/useWorkflowStore";
import { saveAs } from 'file-saver';
import Button from "../mlc-components/model-comparison/Button";
import { useNavigate } from "react-router-dom";

//Icons
import { BiCopy } from 'react-icons/bi'
import { toast } from "react-toastify";
import { Input, Modal } from "antd";
import Axios from "../../utils/Axios";
import { Header } from "antd/lib/layout/layout";
import axios from "axios";
import template from "../../template";

export interface APIDocumentationProps { }

const CopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
}


const Heading = ({ text, styles }: any) => {
    return <p className={`inline font-medium 
                        text-[1.4vw] 
                        pb-[0.3vw] 
                        border-b border-black
                        ${styles}`}>{text}</p>
}

const Paragraph = ({ text, styles, subheading }: any) => {

    const subheadingStyles = "font-medium text-[1.2vw]";
    return <p
        className={`text-[1vw] 
                ${subheading ? subheadingStyles : ""}
                ${styles}`}
    >
        {text}
    </p>
}


const Code = ({ children, styles }: any) => {
    return <code
        className={`block w-[40vw] font-[Inter]

                        rounded-[0.2vw] text-[1vw]
                        px-[1vw] my-[1vw] py-[0.8vw] 
                        bg-[#EFEFEF]
                        border border-[0.1vw] border-[#C4C4C4] ${styles}`}
    >
        {children}
    </code>
}

const scrollToDiv = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
        behavior:"smooth",
        block:"start"   
    });
}

const CodeLine = ({ text, styles }: any) => {
    return <div
        className={`flex justify-between items-center ${styles}`}
    >
        <p>$ {text}</p>
        <div><BiCopy
            onClick={() => CopyToClipboard(text)}
            color="#7A7A7A"

            className="w-[1.5vw] h-[1.5vw] cursor-pointer rotate-270"
        /></div>
    </div>
}

const InputField = ({ name,
         value, 
         setValue,
          label,
          placeholder,
          disabled,
          error,
          styles }: any) => {

    return <div className="space-y-[0.2vw] mt-[1vw]">
        <p className="!text-[0.9vw] text-[#2C2C2C]">{label}</p>
        <input
            name={name}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
            placeholder={placeholder}
            className={`w-full 
            outline-none p-[0.4vw] text-[1vw]
            rounded-[0.2vw] 
            border ${error?"border-red-400":"border-[#C4C4C4]"}
            disabled:bg-[#DDDDDD]`}
            
        />
        {error && <span className="text-red-400">
            {error}
        </span>}

    </div>

}


const LlmAPIDocumentation: React.SFC<APIDocumentationProps> = () => {

    const currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
    const projectType = useWorkflowStore(state=>state.currentProject)?.projectType;
    const endpoint = projectType && template[projectType]?.dockerEndpoint;
    const predictionUrl = process.env.REACT_APP_PREDICTION_URL;

    const step1Ref = React.useRef<HTMLDivElement>(null);
    const step2Ref = React.useRef<HTMLDivElement>(null);
    //const ref = React.useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [passConVisible, setPassVisible] = React.useState<boolean>(false);
    const [token, setToken] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [isConnectModelOpen, setIsConnectModelOpen] = React.useState<boolean>(false);
    
    const [url,setUrl] = React.useState<string>("http://localhost:9000");
    const [apiKey,setApiKey] = React.useState<string>(token);
    const [ canCallLLM,setCanCallLLM] = React.useState<boolean>(true);

    const [errors,setErrors] = React.useState<any>([]);

    React.useEffect(()=>{
        console.log("Checking Inputs",url,apiKey)
        try{
            const validLink = new URL(url);
            setErrors({...errors,url:undefined})

            if(apiKey==="" || apiKey.length<40){
                setCanCallLLM(false);
                setErrors({...errors,apiKey:"Invalid API Key"})
                return;
            }
            setErrors({});
            setCanCallLLM(true);



        }catch(err){
            console.log("Invalid Url");
            setErrors({url:"Invalid URL"})
            setCanCallLLM(false);
        }
        

    },[url,apiKey,token])


    let generateToken = async () => {
        if (password !== "") {
            setLoading(true);

            try {

                let res = await Axios.post("/integration/generate-token", {
                    password
                });
                if (res.data.result) {
                    setToken(res.data.data.token);
                    setApiKey(res.data.data.token)
                    toast("Token was successfully generated.", { type: toast.TYPE.SUCCESS });
                }

            } catch (err) {
                console.log("=== Error while generating token. Please reload the page. ===");
                toast(" Error while generating token. Please reload the page.", { type: toast.TYPE.ERROR });
            }
            setLoading(false);
            setPassVisible(false);
        } else {
            alert("Please enter a password");
        }
    };

    return (
        <div>
            <div className="h-[4vw] w-full  border-b
                            flex items-center justify-between">
                <p className="text-[1vw] text-[#7A7A7A]">LLM DOCKER API DOCUMENTATION</p>
                {/* <Button
                    text={"Integrate Model Artifacts"}
                    styles={"!w-[15vw]"}
                    onClick={() => navigate("/workflow/integrate-model-artifact?workflowId=" + currentWorkflow?.workflowId)}
                /> */}
            </div>
            <div style={{ width: "95%" }}>

                <div>
                    <div className="flex items-center justify-between">
                        {/* <h1 className="font-medium text-[1.8vw] mb-[1vw]">
                            DataNeuron Prediction API
                            </h1> */}

                    </div>

                </div>
                <div className=" mt-[2vw]  space-y-[1vw]">
                    <Heading text={"Overview"} />
                    <Paragraph
                    styles={"pl-[1vw]"}
                        text={"Integrate DataNeuron LLM Docker into your workflow."}
                    />

                    <div className="mt-[2vw]">

                    <Heading
                        text={"Step By Step Procedure:"}
                        styles={"!mt-[2vw]"}
                    />
                    </div>

                    <ul className="text-[1vw] list-[alpha] pl-[2vw]">
                        <li onClick={()=>scrollToDiv(step1Ref)}>Setup LLM Backend on Local Machine</li>
                        <li onClick={()=>scrollToDiv(step2Ref)}>Connect to DataNeuron Workflow</li>
                    </ul>
                </div>
                <div
                    ref={step1Ref}
                    className="mt-[2vw]">
                    <Heading
                        text={"1. Setup LLM Backend on Local Machine"}
                    />

                    <ul className="text-[1vw] pl-[1vw] list-style-decimal mt-[1vw]">
                        <li>
                            <Paragraph
                                text={"1. Pulling"}
                            />
                            <Code>
                                <CodeLine
                                    text={"docker pull dataneuron/llm-backend"}
                                />
                            </Code>
                        </li>

                        <li>
                            <Paragraph
                                text={"2. Running"}
                            />

                            <Code>
                                <CodeLine
                                    text={"docker run -it -p 9000:8080 dataneuron/llm-backend"}
                                />
                            </Code>
                        </li>
                    </ul>

                    <div className="mt-[1vw] pl-[1vw]">
                        <Paragraph
                            text={"Now the llm docker image is running at 9000 port"} />
                    </div>
                </div>



                <div
                    ref={step2Ref}
                    className="mt-[2vw] space-y-[1vw]">
                    <Heading
                        text={"2. Connect to DataNeuron workflow"}
                    />

                    <Paragraph
                        text={"Docker Backend Connection"}
                        styles={"pl-[1vw]"}

                    />

                    <ul className="pl-[2vw] text-[1vw] list-[lower-alpha] space-y-[1vw]">
                        <li>
                            <Paragraph
                                text={" Generate API token "}

                            />

                            <div className="flex w-full mt-[20px]">
                                <input

                                    disabled
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                    type="text"
                                    className="px-[0.5vw] cursor-pointer py-[0.1vw] 
                                                border border-[#7A7A7A]
                                                bg-transparent rounded-l-[0.5vw] 
                                                w-7/12"
                                ></input>
                                <div
                                    onClick={() => {
                                        navigator.clipboard.writeText(token);
                                        toast("Copied successfully", { type: "success" });
                                    }}
                                    className='flex items-center 
                                    border-b border-t border-[#7a7a7a]
                                    px-[0.5vw] py-[0.2vw]  
                                    cursor-pointer'
                                >
                                    <img className="w-[1.5vw]" src="images/copy_squares.svg" alt="copy" />
                                </div>
                                <button
                                    onClick={() => setPassVisible(true)}
                                    className="px-[0.5vw] w-[15vw] py-[0.7vw] duration-200
                                             border border-primaryBlue  hover:border-transparent
                                             text-primaryBlue hover:bg-primaryBlue hover:text-white
                                             text-[1.1vw] rounded-r-[0.5vw]"
                                >
                                    REGENERATE KEY
                                </button>
                            </div>
                            <Modal
                                centered
                                title="Please enter your password"
                                width={"30vw"}
                                open={passConVisible}
                                onOk={() => generateToken()}
                                onCancel={() => setPassVisible(false)}
                                footer={[
                                    // <button className="py-[0.2vw] px-[0.5vw] text-[1.1vw] text-white bg-primaryBlue rounded mr-[0.5vw]" onClick={()=>generateToken()} >Submit</button>,
                                    <Button
                                        styles={'!w-[5vw] !bg-white !text-primaryBlue !h-[2.5]'}
                                        text="Cancel"
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => setPassVisible(false)}
                                    ></Button>,
                                    <Button
                                        styles={"!w-[5vw] !h-[2.6vw]"}
                                        text="Submit"
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => generateToken()}
                                    ></Button>,

                                ]}
                                className="w-[25vw]"
                            >
                                {/* <div className="text-[1.1vw]">Please enter your password </div> <br /> */}
                                <Input
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={"password"}
                                    placeholder={"Password"}
                                    className="text-[1.1vw]"
                                />
                            </Modal>

                        </li>
                        <li>
                            <Paragraph
                                text={" Click on Train LLM button"}

                            />
                        </li>
                        <li>
                            <Paragraph
                                text={" Input field:"}

                            />
                            <ul className="pl-[2vw] list-[lower-alpha] text-[1vw]">
                                <li>
                                    <Paragraph
                                        text={"LLM server url : By default this will be localhost:9000"} />
                                </li>
                                <li>
                                    <Paragraph
                                        text={"API key generated in previous step"} />
                                </li>
                                <li>
                                    <Paragraph
                                        text={"WorkflowId will be automatically filled"} />
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Paragraph
                                text={'To start the LLM fine tuning click on “Submit” '}

                            />
                        </li>
                    </ul>
                </div>

                <div className="flex items-center justify-end">
                    <Button
                        text={"Train LLM"}
                        styles={"!w-[16vw]"}
                        loading={loading}
                        onClick={() => {
                            setIsConnectModelOpen(true);
                        }}
                    />
                    <Modal
                        centered
                        title="Connect to LLM Backend"
                        width={"40vw"}
                        open={isConnectModelOpen}
                        onOk={() => generateToken()}
                        onCancel={() => setIsConnectModelOpen(false)}
                        footer={[
                            // <button className="py-[0.2vw] px-[0.5vw] text-[1.1vw] text-white bg-primaryBlue rounded mr-[0.5vw]" onClick={()=>generateToken()} >Submit</button>,
                            <Button
                                styles={'!w-[5vw] !bg-white !text-primaryBlue !h-[2.5]'}
                                text="Cancel"
                                loading={loading}
                                disabled={loading}
                                onClick={() => setIsConnectModelOpen(false)}
                            ></Button>,
                            <Button
                                styles={"!w-[5vw] !h-[2.6vw]"}
                                text="Submit"
                                loading={loading}
                                disabled={loading || !canCallLLM}
                                onClick={async() =>{

                                    try{
                                        let docker_url = url;
                                        setLoading(true);
                                        if(docker_url[url.length-1] === "/"){
                                            docker_url = docker_url.slice(0,docker_url.length);
                                        } 
                                        
                                        await axios.post(docker_url+"/"+endpoint,{
                                            workflowId:currentWorkflow?.workflowId,
                                            api_token:apiKey,
                                            DATANEURON_SERVER_URL:process.env.REACT_APP_DATANEURON_URL
                                        })
    
                                        setLoading(false);
                                    }catch(error){
                                        console.log("Error in llm backend",error);
                                        toast.error("There was an error while connecting to llm backend!");
                                        setLoading(false);
                                    }


                                }}
                            ></Button>,

                        ]}
                        className=""
                    >
                        {/* <div className="text-[1.1vw]">Please enter your password </div> <br /> */}
                        <div className="">
                            <InputField
                                name="url"
                                label="LLM Server URL"
                                value={url}
                                setValue={setUrl}
                                error={errors.url}

                            />

                            <div className="space-y-[0.2vw] mt-[1vw]">
                            <p className="!text-[0.9vw]">API Key</p>
                            <textarea
                            value={apiKey}
                            onChange={(e)=>setApiKey(e.target.value)}
                            className={`border ${errors.apiKey?"border-red-400":"border-[#C4C4C4]"}
                                        w-full min-h-[6vw] max-h-[8vw]
                                        custom_scroll
                                        outline-none p-[0.4vw] 
                                        text-[1vw] tracking-tighter
                                        rounded-[0.2vw]`}
                                        >

                            </textarea>
                            {
                               errors.apiKey &&<span className="text-red-400">{
                                    errors.apiKey
                                }
                                </span>
                            }
                            </div>
                            <InputField
                                name="workflowId"
                                label="Workflow Id"
                                disabled
                                setValue={() => { }}
                                value={currentWorkflow?.workflowId}
                                error={errors.workflowId}

                            />



                        </div>
                    </Modal>

                </div>
            </div>
        </div>
    );
};

export default LlmAPIDocumentation;
