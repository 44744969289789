import React from 'react'
import useWorkflowStore from '../../store/useWorkflowStore'
import template from '../../template';
import AppLayout from '../../components/layouts/AppLayout';
import PageLayout from '../../components/layouts/PageLayout';
import { useNavigate } from 'react-router-dom';
import LlmModelsComp from '../../components/llm-components/LlmModelsComp';
import LLMHyperparamerterTuning from '../../components/llm-components/model-comparison/LLMHyperparamerterTuning';

export default function LLMTuneModelPage() {

    const project = useWorkflowStore(state=>state.currentProject);
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const navigate = useNavigate();

    const projectType = project?.projectType ;
    const workflowType = project?.workflowType ;
    const componentName = workflow?.alpdsInfo.selectedModelInfo?.type ==="llm"?"llmModelTuning":"modelTuning";

    const HyperParameterTuningComp = workflowType && projectType && template[projectType][workflowType][componentName]?.component;


  return (
    <AppLayout>
        <PageLayout 
        pageIconUrl='/images/active_automl.svg'
        pageTitle={`${project?.name}`}
        pageSubHeading='Fine Tune LLM Model'
        >
          {/* <LLMHyperparamerterTuning /> */}
          {/* <HyperParameterTuningComp />*/}
            {HyperParameterTuningComp?React.createElement(HyperParameterTuningComp):null} 
        </PageLayout>
    </AppLayout>
  )
}
