import { Pagination, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import useKeyboard from '../../hooks/useKeyboard';
import TParagraph from './TParagraph';
import DownloadData from '../DownloadValidations';
import DownUnvalidated from '../DownloadUnvalidatedData';

import Axios from '../../utils/Axios';
import useWorkflowStore from '../../store/useWorkflowStore';

import { nodesAtom } from '../../components/masterlist/atoms';
import { useRecoilValue } from 'recoil'
import Loader from '../loader';
import { toast } from 'react-toastify';

interface ITPProps {
    hasStages: boolean;
}



export default function TrainingParagraphs({ hasStages }: ITPProps) {


    const [page, setPage] = useState<number>(1);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const currentProject = useWorkflowStore(state => state.currentProject);
    const currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
    const currentBatch = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchNumber || 0;
    const isTrain: boolean = currentWorkflow?.stages.find((s: any) => s.name === "train")?.active || false;
    const [paras, setParas] = useState<Array<any>>([]);
    const [totalParas, setTotalParas] = useState<number>(0);
    const [batches, setBatches] = useState<Array<number | string>>([]);

    const [filters, setFilters] = useState<any>({
        stage: "All",
        batch: "All",
        class: "All"
    });

    const nodes = useRecoilValue(nodesAtom);


    const fetchInactiveSeedParas = async()=>{
        if(!currentWorkflow?.workflowId) return;

        try{

            const res = await Axios.post("para/getInactiveSeedParas",{
                workflowId:currentWorkflow.workflowId
            });

            if(res.data.data)
            {

                toast.warning(`${res.data.data}  seed ${res.data.data>1?"paragraphs":"paragraph"} remain untagged `,{
                    autoClose:false
                })
            }

            
            
        }catch{
            toast.warning("Error while fetching inactive seed para count",{
                
            })

        }


    }

    useEffect(() => {

        let temp = [];
        const targetBatch = isTrain ? currentBatch + 1 : currentBatch
        temp.push("All");
        for (let i = 1; i < targetBatch; i++) {
            temp.push(i);
        }
        setBatches(temp);
        fetchInactiveSeedParas();

        return ()=>toast.dismiss();


    }, [])






    const isDownKeyPressed = useKeyboard({ keyCode: "ArrowDown" });
    const isUpKeyPressed = useKeyboard({ keyCode: "ArrowUp" });
    const fetchData = async () => {
        setLoading(true);
        let res: any = await Axios.post("/summary/getTrainingParas", {
            workflowId: currentWorkflow?.workflowId,
            page: page,
            filters: filters
        });

        console.log("training paras", res.data.data.paras);
        setParas(res.data.data.paras);
        setTotalParas(res.data.data.totalParas);


        setLoading(false);
    }

    //fetchData
    useEffect(() => {

        fetchData();


    }, [filters, page]);

    useEffect(() => {
        if (isDownKeyPressed) {
            if (activeIndex < paras.length - 1) {
                console.log("down", activeIndex);
                setActiveIndex(prev => prev + 1)
            };
        }

    }, [isDownKeyPressed])

    useEffect(() => {
        if (isUpKeyPressed) {
            if (activeIndex > 0) setActiveIndex(prev => prev - 1);

        }

    }, [isUpKeyPressed])


    return (
        <div className=''>

            {/* Filters */}
            <div className='flex justify-between items-center w-full 
                            sticky top-[4.9vw] bg-gray-50 py-[0.5vw] 
                            border-b shadow-sm'>

                <div className={`flex ${hasStages ? "w-1/2" : "w-[40%]"} gap-[1vw] h-[2.5vw]`}>
                    {hasStages && <div className='flex-1 w-full '>

                        <Select
                            placeholder="Select Type"
                            className='w-[14vw] h-full flex items-center  !text-[1.1vw]  rounded bg-[#EAEAEA]'
                            bordered={false}
                            onChange={(e) => setFilters({ ...filters, stage: e })}>
                            <Select.Option className="text-[0.8vw]" value={"All"}>All</Select.Option>
                            <Select.Option className="text-[0.8vw]" value={1}>Stage 1</Select.Option>
                            <Select.Option className="text-[0.8vw]" value={2}>Stage 2</Select.Option>
                            <Select.Option className="text-[0.8vw]" value={"seed"}>Seed Paragraphs</Select.Option>

                        </Select>
                    </div>}

                    {hasStages && <div className='flex-1'>

                        <Select
                            placeholder="Select Batch"
                            className='w-[14vw] h-full flex items-center  !text-[1.1vw]  rounded bg-[#EAEAEA]'
                            bordered={false}
                            disabled={filters.stage !== 2}
                            onChange={(e) => setFilters({ ...filters, batch: e })}>

                            {
                                batches.map(batch => <Select.Option
                                    className="text-[0.8vw]"
                                    value={batch}
                                >{batch}</Select.Option>)
                            }


                        </Select>
                    </div>}

                    {!hasStages && <div className='flex-1 '>

                        <Select
                            placeholder="Select Stage"
                            className='w-[14vw] h-full flex items-center  !text-[1.1vw]  rounded bg-[#EAEAEA]'
                            bordered={false}
                            onChange={(e) => setFilters({ ...filters, stage: e })}>
                            <Select.Option className="text-[0.8vw]" value={"All"}>All</Select.Option>
                            <Select.Option className="text-[0.8vw]" value={1}>Annotated</Select.Option>
                            <Select.Option className="text-[0.8vw]" value={"seed"}>Seed Paragraphs</Select.Option>

                        </Select>
                    </div>

                    }
                    <div className='flex-1'>

                        <Select

                            placeholder="Select Class"
                            className='w-[14vw] h-full flex items-center  !text-[1.1vw]  rounded bg-[#EAEAEA]'
                            bordered={false}
                            onChange={(e) => setFilters({ ...filters, class: e })}>
                            <Select.Option className="text-[0.8vw]" value={"All"}>All</Select.Option>
                            {currentWorkflow?.useNoTag &&
                                <Select.Option className="text-[0.8vw]" value={"No Tag"}>No Tag</Select.Option>
                            }
                            {
                                nodes.filter(node => node.is_model_class).map((node) => {

                                    return <Select.Option className="text-[0.8vw]"
                                        value={node.node_id}
                                    >{node.node_name}
                                    </Select.Option>
                                })
                            }


                        </Select>
                    </div>


                </div>
                <div className=' flex gap-4'>
                    <DownloadData />
                    {/* <DownUnvalidated /> */}
                </div>


            </div>

            {/* Loading */}
            {loading && <div className='h-[20vh]'>
                <Loader />
            </div>}

            {/* Training Paragraphs */}

            <div >
                {
                    //!data?.length 
                    !loading && !paras.length && <div className='h-[25vw] w-full flex justify-center items-center text-[1.2vw]'>
                        No Paragraphs Found
                    </div>

                }
                {
                    //data.length
                    !loading && paras.length > 0 && paras.map((tpara: any, index) => {

                        return <TParagraph
                            key={tpara.paraId}
                            para={tpara}
                            index={index}
                            setActivePara={setActiveIndex}
                            activePara={activeIndex}

                        />

                    })
                }




            </div>

            {/* Pagination */}
            {paras.length > 0
                && <div className='flex justify-end'>

                    <Pagination
                        disabled={loading}
                        onChange={(e) => setPage(e)}
                        current={page}
                        total={totalParas}
                        pageSize={20}
                        showSizeChanger={false}
                        showQuickJumper
                    />
                </div>}
        </div>
    )
}
