import { toast } from 'react-toastify';
import Axios from '../../../utils/Axios';

export const getParas =async()=>{

}

export const saveAllValidations =async(validations:any[],paraId:string,workflowId:string)=>{

    try{
        await Axios.post("/ner/paraAction",{
            validations,
            paraId,
            workflowId
        })

    }catch{
        toast.warn("Could not save changes, please refresh the page and try again!");
    }



}


export const callNextBatch = async()=>{

}

export const callAutoML = async(workflowId:string)=>{

    
    try{
        await Axios.post("/ner/automl",{workflowId});

        return true;

    }catch{
        toast.warn("Could not save changes, please refresh the page and try again!");
        return false
    }





}




export const callPrediction = async(workflowId:string)=>{

    
    try{
        await Axios.post("/ner/prediction",{workflowId});

        return true;

    }catch{
        toast.warn("Could not save changes, please refresh the page and try again!");
        return false
    }





}