import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import BillingCol from './BillingCol';
import useAuthStore from "../../store/authStore";
import Axios from '../../utils/Axios';
import BillingLayout from '../layouts/BillingLayout';
import Loader from "../loader";
import { toast } from 'react-toastify';
import useCompanyUsersStore from "../../store/useCompanyUsersStore";

let BillingComp: React.FC = () => {

  const [masterPlans, setMasterPlans] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [freeTrial, setFreeTrial] = useState<any>({});
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [hasDoneFreeTrial, setHasDoneFreeTrial] = useState<boolean>(false);
  const authStore = useAuthStore();
  const { setCompanyUsers } = useCompanyUsersStore();
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    let fetchMasterPlans = async () => {
      let res = await Axios.get("/billing/getMasterPlans");

      if (res.data.result) {
        let plans = res.data.data;
        let arr: any = [];

        plans.forEach((plan: any) => {
          
          if (plan.planName === "SME") {
            let obj = {
              title: {
                h1: plan.planName,
                p: "Best suited for smaller projects with the flexibility to pay as you go."
              },
              details1: {
                title: "$5,000/month",
                points: [{
                  img: "/images/billing3.svg",
                  desc: "100 Masterlist Classes"
                }, {
                  img: "/images/billing2.svg",
                  desc: "1M Paras / Upto 5 Users"
                }, {
                  img: "/images/billing6.svg",
                  desc: "30 Days Data Retention"
                }]
              },
              details2: {
                title: "Includes",
                points: ["Heirarchical Taxonomy", "Active Learning", "Model Training", "Model Versioning", "Customer Success Manager Included (Email, Phone, Slack", "Dedicated Cloud", "Model Retraining", "Custom APIs", "Integrations"]
              },
              button: {
                title: "Purchase Plan",
                disabled: false
              },
              planId: plan.planId
            }
            arr.push(obj);

          } else if (plan.planName === "Enterprise") {
            let obj = {
              title: {
                h1: plan.planName,
                p: "Best suited for enterprise level projects with scalability and performance in mind."
              },
              details1: {
                title: "$10,000/month",
                points: [{
                  img: "/images/billing3.svg",
                  desc: "150 Masterlist Classes"
                }, {
                  img: "/images/billing2.svg",
                  desc: "10M Paras / Upto 50 Users"
                }, {
                  img: "/images/billing6.svg",
                  desc: "60 Days Data Retention"
                }, {
                  img: "/images/billing5.svg",
                  desc: "Hybrid / On-Premise with Support SLAs"
                }]
              },
              details2: {
                title: "Includes",
                points: ["Heirarchical Taxonomy", "Active Learning", "Model Training", "Model Versioning", "Customer Success Manager Included (Email, Phone, Slack", "Dedicated Cloud", "Model Retraining", "Custom APIs", "Integrations"]
              },
              button: {
                title: "Purchase Plan",
                disabled: false
              }, 
              planId: plan.planId
            }
            arr.push(obj);
          } else if (plan.planName === "Free Trial") {
            setFreeTrial(plan);
          }

        })

        let obj = {
          title: {
            h1: "Natural Language Prediction",
            p: "Best suited for large projects with NLP as a main offering to users."
          },
          details1: {
            title: "Custom Plan",
            points: [{
              img: "/images/billing2.svg",
              desc: "Create a custom plan of your choice"
            }]
          },
          details2: {
            title: "Customize the following parameters",
            points: ["Size of data", "Number of Masterlist", "Number of Users", "Number of Projects", "Number of Prediction API Calls"]
          },
          button: {
            title: "support@dataneuron.ai",
            disabled: true
          },
          planId: ""
        }

        console.log(arr);

        arr.push(obj);

        setMasterPlans(arr);
      } else {
        console.log("Could not fetch master plans");
      }

      setLoading(false);
    }

    fetchMasterPlans();
  }, []);


  useEffect(() => {
    setLoading2(true);
    let checkFreeTrial = async () => {
      let res = await Axios.get("/billing/hasFreeTrial");

      console.log(res.data);

      if (!res.data.result && res.data.data === "No free trial found") {
        setHasDoneFreeTrial(false);

      } else {
        setHasDoneFreeTrial(true);
      }

      setLoading2(false);
    }

    checkFreeTrial();
  }, []);


  const handleClick = async () => {
    setButtonLoading(true);
    if (authStore.isAuthenticated) {
      await Axios.post("/billing/createFreeTrial", {
          planId: freeTrial.planId,
          // engBackend: process.env.REACT_APP_BASE_URL
      })
      .then(async (res) => {
        toast("Your free trial has started", { type: toast.TYPE.SUCCESS });
        setHasDoneFreeTrial(true);

        let accountInfo = await Axios.post("/billing/getAccountDetails", {
          userId: authStore.userDetails?.userId,
          isBillingAccount: authStore.userDetails?.isBillingAccount,
          associatedBillingAccounts: authStore.userDetails?.associatedBillingAccounts
        });

        if (accountInfo.data.result) {

            // === Setting billing account details ===
            authStore.setIsProcessComplete(true);
            authStore.setBillingDetails(accountInfo.data.data?.billingDetails);
            authStore.setActivePlan(accountInfo.data.data?.activePlan);
            authStore.setUsage(accountInfo.data.data?.usage);

            // === Setting company users ===
            let usersData = await Axios.get(`/user/companyuser`);
            if (usersData && usersData.data.result) {
                // console.log("setting");
                navigate("/");
                setCompanyUsers(usersData.data.data);
            } else {
                console.log("Could not fetch account details");
            }

        } else {
            console.log("Could not fetch account details");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setButtonLoading(false);
      })
    } else {
      setButtonLoading(false);
      navigate("/signin");
    }
  }
   
  return (
    <BillingLayout>
     {/* <div className="fixed top-0 left-0 h-screen w-full px-[2.5vw] py-[5vh] bg-[#11111177] z-[100]"> */}
      <div className="w-full h-full bg-white rounded-[9px] py-[2vw] flex items-center justify-center flex-col relative">
        <div className='text-center'>
          <h1 className='text-[1.5vw] font-semibold mb-[2vh]'>{authStore.activePlan === undefined ? "You don't have an Active Plan!" : ""} Choose the plan that suits you best.</h1>
          {/* <NavLink to='' className='text-[0.9vw] mb-[2vh] inline-block font-light text-[#777777] underline hover:underline'>Compare the plans in more detail</NavLink> */}
        </div>

        {
          loading ? 
          <Loader /> :
          <>
            <div className='grid grid-cols-3 gap-[2vw]'>
              {
                masterPlans && masterPlans.map((plan: any, index: any) => {
                  return <BillingCol plan={plan} key={index} />
                })
              }
            </div>

            {
              loading2 ? 
              <img src="/images/loading_blue.svg" alt="loading" className='mt-[5vh] w-[1.6vw] mx-auto ' /> :
              !hasDoneFreeTrial ?
              <div className="mt-[5vh] flex flex-col justify-center items-center">
                <p className='text-[1.2vw] font-medium'>Want to try out our product first?</p>
                <button 
                  className={`mt-[2vh] w-[14vw] h-[2.5vw] border-[#11256D] border-[1px] font-medium text-[#11256D] text-[1vw] transition duration-150 ease-in-out hover:bg-[#11256D] hover:text-[#fff]
                  ${buttonLoading ? 'bg-primarycolor' : 'bg-white'}`}
                  onClick={handleClick}
                >
                  {
                    buttonLoading ?
                    <img src="/images/loading_white.svg" alt="loading" className='w-[1.6vw] mx-auto ' /> :
                    "Free Trial (7 Days)"
                  }
                </button>
              </div>
              : ""
            }
          </>
        }
        
      </div>
     {/* </div> */}
    </BillingLayout>
  )
}

export default BillingComp;