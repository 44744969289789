import React,{useEffect, useState} from 'react'

interface IUseKeyboard{
    keyCode:string
}


function useKeyboard({keyCode}:IUseKeyboard) {

    const [isKeyboardEvent,setIsKeyboardEvent] = useState<boolean>();
    const handleKeyDown = (e:KeyboardEvent)=>{
       
        if(e.key===keyCode)
        {
            e.preventDefault();
            setIsKeyboardEvent(true);
        }
        

    }
    const handleKeyUp= (e:KeyboardEvent)=>{
        
        if(e.key===keyCode)
        {
            e.preventDefault();
            setIsKeyboardEvent(false);
        }
        

    }

    useEffect(()=>{
        window.addEventListener("keydown",handleKeyDown);
        window.addEventListener("keyup",handleKeyUp);


        return ()=>{
            window.removeEventListener("keydown",handleKeyDown);
            window.removeEventListener("keyup",handleKeyUp);

        }
    },[]);

    return isKeyboardEvent
}

export default useKeyboard