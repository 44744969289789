import React, { useEffect, useState } from 'react';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser'; 

interface AMParaProps{
    para:string,
    keyword:string,
    ngram:number
}

const AMPara:React.FC<AMParaProps> = ({para,keyword,ngram})=>{

        const [paraData,setParaData] = useState(""); 
        const [bigPara, setBigPara] = useState("");
        const [isBigPara, setIsBigPara] = useState(false); 
        const [readMoreText, setReadMoreText] = useState("read more");     

        useEffect(() => {
            let text = para;
  	        let regex = new RegExp("\\s" + keyword, "gi"); 
		    let newText = text.replace(regex, `<span class="text-red-400 font-medium"> ${keyword} </span>`);

            if (newText.length > 1100) {
                setParaData(newText.slice(0, 1100))
                setIsBigPara(true);
                setBigPara(newText);
            } else {
                setParaData(newText);
            }
        },[]);

        function expandPara() {
            if (paraData.length > 1100) {
                setParaData(bigPara.slice(0, 1100));
                setReadMoreText("read more");
            } else {
                setParaData(bigPara);
                setReadMoreText("read less");
            }
        }

        return (
             <p className="w-[100%] p-2 text-[1vw]  h-[100%] mx-[0.7vw] border-t select-none">
                <span> { ReactHtmlParser (paraData) } </span>
                {
                    isBigPara ? <span data-testid="read-more-para-test" className='text-[#11256D] font-medium cursor-pointer' onClick={expandPara}> ...{readMoreText}</span> : ""
                }            
            </p>
        )
}


export default AMPara;