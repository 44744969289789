import React from 'react'
import Switch from '../../data-validation/Switch'
import { toggleAutoValidation } from '../_apiCalls'
import { toast } from 'react-toastify'
import useWorkflowStore from '../../../store/useWorkflowStore'

type Props = {
    predictionLoading:boolean,
    readOnly?:boolean
}

function AutoValidationSwitch({predictionLoading,readOnly = false}: Props) {
    

    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const workflowId = workflow?workflow.workflowId:"";
    const autoValidate = workflow?.autoValidate?.isAutoValidate || false;

    const handleToggleAutoValidation =async(value:boolean)=>{
    
        const {data,error} = await toggleAutoValidation(workflowId,value);
        //if(error) toast.error("There was an error while updating autovalidation please try again!")
    }
    return (
    <div className='flex items-center text-[1vw] space-x-[1vw]'>
    <span>Auto Validation</span>
    <Switch
        innerWidth={1.6}
        title=""
        width={5}
        onClick={()=>handleToggleAutoValidation(!autoValidate)}
        disable={predictionLoading || readOnly}
        disableMessage={readOnly?"Cannot change autovalidation after model training": "Please wait while answers are generated"}
        state={autoValidate}
      />
    </div>
  )
}

export default AutoValidationSwitch