import { Dropdown, Menu, Popover } from "antd";
import React, { useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Node as NodeType } from "./utils/interface";
import { useRecoilState } from "recoil";
import { activeNodeAtom, layersAtom, nodesAtom } from "./atoms";
import { calculateNextLayer, updateNode, updateNodeProp } from "./utils/helper";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import { NotificationAtom } from "../topbar/notificationAtom";
//import { Report } from "../../pages/app/practice-area/pa/model-training-2/model_training2";
import { toast } from "react-toastify";
import useWorkflowStore from "../../store/useWorkflowStore";
import useAuthStore from "../../store/authStore";

export interface Report {
    f1_score: number;
    precision: number;
    recall: number;
    support: number;
    training_paras: number;
    info: string;
}

export interface NodeProps {
    nodeInfo: NodeType;
}

const PopContent = ({classname,keywords}:any) =>{

    // if(!keywords.length) return null;

    return (<div className="max-w-[30vw] text-center break-all">
       <p className="font-medium text-[0.9vw]">Keywords:</p>
        <p className=" text-[0.9vw]">{keywords}</p>

    </div>)

}

const Node: React.SFC<NodeProps> = ({ nodeInfo }) => {
    let [layers, setLayers] = useRecoilState(layersAtom);
    let [nodes, setNodes] = useRecoilState(nodesAtom);
    let [activeNodeState, setActiveNodeState] = useRecoilState(activeNodeAtom);
    let { currentWorkflow: currentPracticeArea, userProject, currentProject } = useWorkflowStore();
    let currentUser = useAuthStore(state => state.userDetails);
    let isCreator = currentProject?.createdBy === currentUser?.userId
    let canEdit = userProject?.MASTERLIST_ACCESS;
    // let getNodeInfo = (nodeId: string): Report | undefined => {
    //     //@ts-ignore
    //     if (allNodes !== undefined && allNodes[nodeId] !== undefined) {
    //         //@ts-ignore
    //         return allNodes[nodeId];
    //     } else {
    //         return undefined;
    //     }
    // };

    //let [notifications, setNotifications] = useRecoilState(NotificationAtom);
    // let [allNodes, setAllNodes] = useState();
    let [node_name, setNodeName] = useState("");
    let [keywords, setKeywords] = useState("");
    let [editable, setEditable] = useState(false);

    // console.log(nodeInfo);
    let navigate = useNavigate();

    //const dispatch = useDispatch();
    //const { currentPracticeArea } = useProjectStore();

    let inputRef = useRef(null);

    useEffect(() => {
        // if (currentPracticeArea?.stages[4]?.active === true) {
        //   if (
        //     currentPracticeArea.alpdsInfo &&
        //     currentPracticeArea.alpdsInfo.classificationReports
        //   ) {
        //     console.log(
        //       currentPracticeArea.alpdsInfo.classificationReports.nodeReport
        //     );
        //     setAllNodes(
        //       //@ts-ignore
        //       currentPracticeArea.alpdsInfo.classificationReports.nodeReport
        //     );
        //   }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNodeName(nodeInfo.node_name);
        setKeywords(nodeInfo.keywords.join(","));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let addNewLayer = (e: any) => {
        let new_layer = calculateNextLayer(nodeInfo, nodes);
        setLayers([...layers.slice(0, nodeInfo.node_level + 1), new_layer]);
    };

    let update = () => {

        if (node_name.length < 1) {
            setNodeName("default Class name")
            toast("Class name must contain at least 2 characters. ", {
                type: "error",
            });
            return
        }
        //console.log("---Updating Node---")
        updateNode(nodeInfo._id, node_name, keywords)
            .then((res) => { })
            .catch((err) => {
               // console.log(err);
                toast("Internal server error: Node update.", {
                    type: "error",
                });
                // setNotifications([
                //   ...notifications,
                //   "Internal server error: Node update.",
                // ]);
            });
        let new_state = updateNodeProp(
            nodeInfo,
            {
                node_name: node_name?.trim(),
                keywords: keywords.split(",").filter((n) => n.trim() !== ""),
            },
            nodes,
            layers
        );
        //console.log("=== Updating nodes ===",new_state);
        setNodes(new_state?.nodes);
        setLayers(new_state.layers);
    };

    useEffect(() => {
        if (editable) {
            inputRef && inputRef.current && (inputRef.current as HTMLInputElement).focus();
        }

    }, [editable, setEditable])

    let pencilClick = (e: any) => {
        //@ts-ignore
        setEditable(true);

    };

    // let handleUpdate = () => {
    //     //dispatch({ type: SET_UPDATE_ACTIVE, payload: { action: true } });
    //     // history.push("/app/pa/update");
    // };
    // let handleFeedbackClick = (tab: number) => {
    //     // dispatch({
    //     //   type: SET_FEEDBACK_DETAILS,
    //     //   payload: {
    //     //     tab: tab,
    //     //     node_id: nodeInfo.node_id,
    //     //     node_name: nodeInfo.node_name,
    //     //     //@ts-ignore
    //     //     accuracy: getNodeInfo(nodeInfo.node_id)["f1-score"] * 100,
    //     //   },
    //     // });
    //     handleUpdate();
    // };

    // let MasterlistMenu = () => {
    //     return (
    //         <Menu
    //             style={{
    //                 borderRadius: 6,
    //                 overflow: "hidden",
    //                 boxShadow: "0px 0px 12px rgba(122, 122, 122, 0.15);",
    //             }}
    //         >
    //             <Menu.Item style={{ width: 240 }}>
    //                 <div
    //                     className={"flex space-x-3 items-center p-3 "}
    //                     onClick={() => {
    //                         handleFeedbackClick(0);
    //                     }}
    //                 >
    //                     <img src="/img/seed_para_ml.svg" alt="seed para icon" width={16} />
    //                     <div className={"flex-grow"} style={{ color: "#7A7A7A", fontSize: 15 }}>
    //                         Seed Paragraph
    //                     </div>
    //                 </div>
    //             </Menu.Item>
    //             <Menu.Item style={{ width: 240 }} onClick={() => handleFeedbackClick(1)}>
    //                 <div className={"flex space-x-3 items-center p-3 "}>
    //                     <img src="/img/upload_icon.svg" alt="seed para icon" width={16} />
    //                     <div className={"flex-grow"} style={{ color: "#7A7A7A", fontSize: 15 }}>
    //                         Upload Source
    //                     </div>
    //                 </div>
    //             </Menu.Item>
    //             <Menu.Item style={{ width: 240 }} onClick={() => handleFeedbackClick(2)}>
    //                 <div className={"flex space-x-3 items-center p-3 "}>
    //                     <img src="/img/cube.svg" alt="seed para icon" width={16} />
    //                     <div className={"flex-grow"} style={{ color: "#7A7A7A", fontSize: 15 }}>
    //                         Review More
    //                     </div>
    //                 </div>
    //             </Menu.Item>
    //         </Menu>
    //     );
    // };



    return (
        <>
        <Popover 
        className=""
        content ={currentPracticeArea?.masterlistLocked 
            // && nodeInfo._id=== activeNodeState?._id 
           &&  !editable && keywords.length>0
            && <PopContent 
                            classname={node_name}
                            keywords={keywords}
                            />}
        showArrow={currentPracticeArea?.masterlistLocked 
            // &&  nodeInfo._id=== activeNodeState?._id 
            &&  !editable && keywords.length>0}
        
        >

            <div
                id={nodeInfo._id}
                className={"bg-white flex overflow-hidden hover:shadow-md duration-100 cursor-pointer"}
                style={{
                    width: "21vw",
                    borderRadius: "0.3vw",
                    border: nodeInfo._id === activeNodeState?._id ? "0.05vw solid #555" : "0.05vw solid transparent",
                    userSelect: "none",
                }}
            >
                {nodeInfo.is_model_class && <div style={{ backgroundColor: "#11256D", width: 4 }} />}
                <div
                    data-testid="node-click-test"
                    onClick={(e) => {
                       // console.log(nodeInfo._id)
                       if(nodeInfo._id === activeNodeState?._id) return;
                        setActiveNodeState(nodeInfo);
                        addNewLayer(e);
                    }}
                    className={"flex-grow relative flex"}
                >
                    { !editable && <button className="absolute z-10 inset-0 w-full h-full" ></button>}
                    <div className={"flex-grow p-[0.5vw]"}>
                        <input
                            ref={inputRef}
                            id={nodeInfo._id + "1"}
                            data-testid="node-name-input-test"
                            disabled={!editable}
                            type="text"
                            value={node_name}
                            className="w-full text-[1vw] font-semibold bg-white  outline-none cursor-pointer"
                            onChange={(e) => setNodeName(e.target.value)}
                            placeholder={"node name"}
                            onBlur={() => {
                                setNodeName(node_name.trim());
                                update();
                            }}
                        />
                        

                        <input
                            type="text"
                            disabled={!editable}
                            value={keywords}
                            style={{ color: "#555" }}
                            data-testid="node-keywords-input-test"
                            className="w-full text-[1vw] bg-white  outline-none cursor-pointer"
                            onChange={(e) => setKeywords(e.target.value)}
                            placeholder={"Please enter keywords"}
                            onBlur={() => {
                                setEditable(false);
                                update();
                            }}
                        />
                        


                    </div>
                    {


                        //checks active node state && currentWorkflowArea && if masterlist is locked
                        nodeInfo._id === activeNodeState?._id &&
                        currentPracticeArea &&
                        currentPracticeArea.masterlistLocked === false && (
                            canEdit && isCreator && <div className={"p-2 z-20"}>
                                <img
                                    data-testid="edit-node-test"
                                    onClick={pencilClick}
                                    src="/images/pencil.svg"
                                    alt="pencil icon"
                                    width={25}
                                    className=""
                                />
                            </div>)}

                </div>
                {/* {nodeInfo.is_model_class === true && getNodeInfo(nodeInfo.node_id) !== undefined && (
                    <Dropdown trigger={["click"]} overlay={MasterlistMenu}>
                        <div
                            className={"cursor-pointer flex p-3 justify-center items-center"}
                            style={{
                                backgroundColor: "#EAEAEA",
                                width: 76,
                                color: "#11256D",
                            }}
                        >
                            {
                                //@ts-ignore
                                (getNodeInfo(nodeInfo.node_id)["f1-score"] * 100).toFixed(2)
                            }
                            %
                            <FiChevronDown />
                        </div>
                    </Dropdown>
                )} */}
            </div>
        </Popover>
        </>
    );
};

export default Node;
