import * as React from "react";
import Loader from "../loader";
import useMasterlist from "./hooks/useMasterlist";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useEffect ,useState} from "react";
import { layersAtom, masterlistIdAtom, nodesAtom ,activeNodeAtom} from "./atoms";
import Layer from "./Layer";
import { calculateFirstLayer, injestProperties, fetchMasterlist } from "./utils/helper";
import Toolbar from './toolBar';
import { toast } from 'react-toastify';
import useWorkflowStore from '../../store/useWorkflowStore';
import useAdvancedMasterlist from '../../store/useAdvancedMasterlist';
import Axios from '../../utils/Axios'
import { useNavigate } from 'react-router-dom';


import useAuthStore from "../../store/authStore";

import Sidebar from "../AdvancedMasterlist/SideBar";

import TriggerAnnotation from "../controls/TriggerAnnotationButton";
import MoveToAnnotationButton from "../controls/MoveToAnnotationButton";
import template from "../../template";
import useMasterlistSummary from "../../store/useMasterlistSummary";


interface Check {
  allowed: boolean;
  messages: Array<string>;
}




const Masterlist: React.FC = () => {

  const navigate = useNavigate();

  let [layers, setLayers] = useRecoilState(layersAtom);
  let [nodes, setNodes] = useRecoilState(nodesAtom);
  let [activeNode,setActiveNode] = useRecoilState(activeNodeAtom);


  let { currentWorkflow } = useWorkflowStore();
  const workflowStore = useWorkflowStore();
  const userActions = useMasterlistSummary(state=>state.userActions);
  
  let currentProject = useWorkflowStore(state => state.currentProject);
  let userDetails = useAuthStore(state => state.userDetails);
  
  const stage1 = currentWorkflow?.stages.find(stage=>stage.name==="validation-1")?.active;
  const stage2 = currentWorkflow?.stages.find(stage=>stage.name==="validation-2")?.active;
  const projectType =currentProject?.projectType || "";
  const workflowType = currentProject?.workflowType || "";
  //console.log("=== stage1 ===",stage1);

  // let [notifications, setNotifications] = useRecoilState(NotificationAtom);
  //  let history = useHistory();
  let [startAnnoEnabled, setStartAnnoEnabled] = React.useState(true);


  let [isSideBarOpen,setIsSideBarOpen] = useState(false);
  let [isTableOpen,setIsTableOpen] = useState(false);

  const {folders,selectedWords, setFolders, setSelectedWords} = useAdvancedMasterlist(state=>({
    folders:state.folders,
    setFolders:state.setFolders,
    selectedWords:state.selectedWords,
    setSelectedWords:state.setSelectedWords
  }));


  //create masterlist if not exist already

  const updateWorkflowForMasterlist = async()=>{
    if(!workflowStore?.currentWorkflow?.stages[1]?.active && workflowStore.currentWorkflow?.workflowId)
    {
      try{
        let response =await Axios.post("/masterlist/createMasterlist", {
          createdBy: userDetails?.userId,
          workflowId: workflowStore?.currentWorkflow?.workflowId });
          
          navigate("/workflow/masterlist?workflowId="+workflowStore?.currentWorkflow?.workflowId);
          toast("Masterlist was created successfully",{type:toast.TYPE.SUCCESS});
       }catch{
          toast("Could not create masterlist",{type:toast.TYPE.ERROR});
       }
    }

  }

  //Reset Active Node
  useEffect(()=>{
   // console.log(nodes);
    setActiveNode(null)
    setLayers([layers[0]]);
    updateWorkflowForMasterlist();
 




  },[]);
  




  let allowToStartStage1 = (): Check => {
    let allowed = true;
    let messages:any = [];

    let modelNodes = nodes.filter(
      (nodeInfo) => nodeInfo.is_model_class === true
    );
    let leafNodes = nodes.filter((nodeInfo) => nodeInfo.is_leaf_node === true);

    if (modelNodes.length > 100 || modelNodes.length < 2) {
      allowed = false;
      messages.push(
        "The number of model nodes should be more than 1 and should be less than 101"
      );
    }

    if (leafNodes.length > 100) {
      allowed = false;
      messages.push("The number of leaf nodes should not be greater than 100");
    }

    //TODO : The number of paras should ne n ^ 2 times the number of modal nodes (n)

    return { allowed, messages };
  };
 let lockMasterlist = async () => {
 
    if(!startAnnoEnabled){
      return;
    }
        setStartAnnoEnabled(false);
        
        try {
    
          let check: Check = allowToStartStage1();
    
          if (check.allowed) {
    
            let res: any = await Axios.post("/masterlist/markLocked", {
              workflowId: currentWorkflow?.workflowId,
              markAs: check.allowed
            });
            
            if (res.data.result) {
              //Show user success message
              toast("The Masterlist is locked.", {
                type: toast.TYPE.SUCCESS
              });
        
              //Lets call the stage 1 integration api
              console.log(template[projectType][workflowType])
              let endpoint = template[projectType][workflowType]?.masterlist?.endpoints?.initStage1;

        
              //let res =
              
             Axios.post("/integration/"+endpoint, {
                workflowId: currentWorkflow?.workflowId,
                userActions,
                isNoTag:currentWorkflow?.useNoTag
              });
              const stageLink = stage1 === undefined?"validation":"stage1"
              navigate(`/workflow/${stageLink}?workflowId=${currentWorkflow?.workflowId}`)
            }
            else {
            //  console.log("MasterList Was Not Locked", res);
            }
          }
          else {
            //console.log(check.messages);
            check.messages.forEach(msg => {
              toast(msg, {
                type: toast.TYPE.WARNING
              })
            })
          }
    
        } catch (error: any) {
         // console.log("Error In Initializing Stage 1", error.response);
          toast(error.response?.data?.message, {
            type: toast.TYPE.ERROR
          })
        }
        finally {
          setStartAnnoEnabled(true);
        }
      
    
      };
 

  return (
    <div className="">
      <Toolbar />
      <div className="flex ">

      <div
        id={"masterlist_holder"}
        className="flex
        transform  -translate-y-8 p-[0.7vw] 
         overflow-x-auto custom_scroll flex-1
         
         "
      >
        {layers.map((layer) => {
          return <Layer layerInfo={layer} key={layer?.id} />;
        })}
      </div>

      {/* ==========Sidebar Starts here */}
    {currentProject?.createdBy === userDetails?.userId 
    &&  <div className={`${isSideBarOpen?"md:w-[20%] sm:w-[22%]":"w-[4%]"}
                         overflow-hidden sticky top-[9vw]
                         border border-t-0 border-r-0 border-b-0 
                         -mt-6 h-[63vh] duration-500`}>
   
      <Sidebar isOpen ={isSideBarOpen} setIsOpen={setIsSideBarOpen}/>
      
      </div>}
      
     {/* <div className={`absolute bg-white z-[10] -mt-[12vh]  h-[80%]   duration-500 ${isSideBarOpen?"opacity-100 w-[87.7%] ":"opacity-0 w-[0%]"} ${isTableOpen?"left-[8%]":"left-[98.5%]"} `}>

      <div className={`${isTableOpen?"opacity-100 ":"opacity-0 "} h-full`}>
       {isTableOpen && <Table />}
      </div>

      <div>
      <div className="relative bg-red">
        <ArrowLeft 
        className={`absolute -top-[45vh]  -left-[1.7%]  z-[60] w-[3vw] h-[3vw] delay-500 duration-500 ${isTableOpen?"rotate-180":""} ${isSideBarOpen?"cursor-pointer":""} `} 
        onClick={()=>isSideBarOpen && setIsTableOpen(!isTableOpen)}
        />
      </div>
      </div>

      </div> */}
      {/* ==========End Of Sidebar */}



      </div>

      {!currentWorkflow?.masterlistLocked
        ?currentProject?.createdBy === userDetails?.userId ? 
        
                                                          <TriggerAnnotation
                                                          lockMasterlist={lockMasterlist}
                                                          startAnnoEnabled={startAnnoEnabled}
                                                          isSideBarOpen={isSideBarOpen}
                                                          />:null

        :<MoveToAnnotationButton 
        isSideBarOpen={isSideBarOpen}
          OnClick={() => {
            toast("Moved To Validation", { type: toast.TYPE.SUCCESS })
            const stageLink = stage1===undefined?"validation":stage2?"stage2":"stage1"
            navigate(`/workflow/${stageLink}?workflowId=`+ currentWorkflow?.workflowId);
          }}
        />
      }
     
    </div>
  );
};

export default Masterlist;