import React, { useEffect, useState } from "react";
import * as d3 from "d3";
// import  from "./data.json";

const SIZE = window.innerWidth / 3;
const RADIUS = SIZE / 2;
console.log(window.innerWidth)



interface Data {
  name: string;
  value?: number;
}

export const SunburstChart = ({ data }: any) => {
  // console.log(data)
  // console.log(data.name)
  const [hovered, setHovered] = useState(false);
  const [cordX, setCordX] = useState<number>();

  const [cordY, setCordY] = useState<number>();
  const [dataHover, setDataHover] = useState<any>();
  // console.log(data.children)
  const svgRef = React.useRef<SVGSVGElement>(null);
  const [viewBox, setViewBox] = React.useState("0,0,0,0");

  const partition = (data: Data) =>
    d3.partition<Data>().size([2 * Math.PI, RADIUS])(
      d3
        .hierarchy(data)
        // @ts-ignore
        .sum((d) => d.value)
        // @ts-ignore
        .sort((a, b) => b.value - a.value)
    );

  const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));
  console.log(d3.interpolateRainbow);
  console.log(data.children.length + 1);
  console.log(d3.quantize(d3.interpolateRainbow, data.children.length + 1));
  console.log(d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1)))
  
  const format = d3.format(",d");

  const arc = d3
    .arc<d3.HierarchyRectangularNode<Data>>()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(RADIUS / 2)
    .innerRadius((d) => d.y0)
    .outerRadius((d) => d.y1 - 1);

  const getAutoBox = () => {
    if (!svgRef.current) {
      return "";
    }

    const { x, y, width, height } = svgRef.current.getBBox();

    return [x, y, width, height].toString();
  };

  React.useEffect(() => {
    setViewBox(getAutoBox());
  }, []);

  const getColor = (d: d3.HierarchyRectangularNode<Data>) => {
    //@ts-ignore
    while (d.depth > 1) d = d.parent;
    return color(d.data.name);
  };

  const getTextTransform = (d: d3.HierarchyRectangularNode<Data>) => {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = (d.y0 + d.y1) / 2;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  };

  const root = partition(data);

  if (data.children.length <= 0)
    return (<div>
      No Data to Show
    </div>)

  return (
    <>
      {
        hovered &&
        <div className={`absolute bg-white  ml-[1.5vw]  px-[0.8vw] py-[0.5vw] rounded-[0.2vw] border-[0.1vw] border-black w-min-[10vw]`}
          style={{
            top: cordY,
            left: cordX,
            marginTop: dataHover.depth == 3 ? "-4vw" : (dataHover.depth == 2 ? "-3vw" : "-2vw")
          }}
        >

          {
            dataHover.depth == 3 &&
            <div className="">
              <b>Number of Model Nodes:</b> {dataHover.data.value}
              <br />
              <b>Workflow:</b> {dataHover.parent.data.name}
              <br />
              <b>Project:</b> {dataHover.parent.parent.data.name}
              <br />
              <b>Percentage:</b> {(100 * dataHover.value / (root.descendants()[0].value || 1)).toPrecision(3)}%
            </div>
          }
          {
            dataHover.depth == 2 &&
            <div className="">
              <b>Workflow:</b> {dataHover.data.name}
              <br />
              <b>Project:</b> {dataHover.parent.data.name}
              <br />
              <b>Percentage:</b> {(100 * dataHover.value / (root.descendants()[0].value || 1)).toPrecision(3)}%
            </div>
          }
          {
            dataHover.depth == 1 &&
            <div className="">
              <b>Project:</b> {dataHover.data.name}
              <br />
              <b>Percentage:</b> {(100 * dataHover.value / (root.descendants()[0].value || 1)).toPrecision(3)}%
            </div>
          }

        </div>
      }

      <svg width={SIZE} height={SIZE} viewBox={viewBox} ref={svgRef}>

        <g fillOpacity={0.6}

        >
          {root
            .descendants()
            .filter((d) => d.depth)
            .map((d, i) => (
              <path key={`${d.data.name}-${i}`} fill={getColor(d)} d={arc(d) || ""}
                onMouseOver={(e) => {
                  // console.log("Over")
                  // console.log(d.data.name);
                  // console.log(d)
                  setHovered(prevState => !prevState);
                  setDataHover(d)
                }}
                onMouseMove={(e) => {
                  setCordX(e.pageX)
                  setCordY(e.pageY)
                }}
                onMouseLeave={() => {
                  // console.log("Leave")
                  setHovered(prevState => !prevState);
                  setDataHover(null)
                }}
              >
                <text>
                  {d
                    .ancestors()
                    .map((d) => d.data.name)
                    .reverse()
                    .join("/")}
                  \n${format(d.value || 0)}
                </text>
              </path>
            ))}
        </g>
        <g
          pointerEvents="none"
          textAnchor="middle"
          fontSize={7}
          fontFamily="sans-serif"
          className="text-justify"
        >
          {root
            .descendants()
            .filter((d) => d.depth && ((d.y0 + d.y1) / 2) * (d.x1 - d.x0) > 10)
            .map((d, i) => (
              <text
                key={`${d.data.name}-${i}`}
                transform={getTextTransform(d)}
                dy="0.35em"
                fontSize={"0.7vw"}
                onMouseMove={() => {
                  console.log((100 * (d.value || 0) / (root.descendants()[0].value || 1)).toPrecision(3))
                }}
              >
                {
                  parseFloat((100 * (d.value || 0) / (root.descendants()[0].value || 1)).toPrecision(3)) <= 2.5 ? "" : ((100 * (d.value || 0) / (root.descendants()[0].value || 1)).toPrecision(3)) + "%"
                }
              </text>
            ))}
        </g>
      </svg>
    </>

  );
};
