import React, { useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import MasterlistComp from '../../components/masterlist'
import PageLayout from '../../components/layouts/PageLayout';
import useWorkflowStore from '../../store/useWorkflowStore';

//import template 
import template from "../../template";


interface MasterlistProps {}
let Masterlist: React.FC<MasterlistProps> = () => {
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const project =useWorkflowStore(state=>state.currentProject);

    const component =project? 
                          template[project?.projectType || ""][project?.workflowType || ""]?.summary || undefined
                          :undefined;
    
   
    return (


        <AppLayout>
            <PageLayout pageSubHeading="Stage1 Summary" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/active_masterlist.svg">
          
            {component!== undefined?React.createElement(component.stage1Summary):null}
            </PageLayout>
        </AppLayout>
    );
};

export default Masterlist;
