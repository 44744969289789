import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthStore from '../../../store/authStore';
import Axios from '../../../utils/Axios';
import useEntityStore from "../../../store/entityStore";

interface MasterListCardProps {
  workflowStore:any
}

const NERMasterListCard: React.FC<MasterListCardProps> = ({workflowStore}) => {

 const userDetails = useAuthStore(state=>state.userDetails);
 const entities = useEntityStore(state=>state.entities);
 const uploadComplete = workflowStore.currentWorkflow.stages[0].active;
  const navigate = useNavigate();
  const [entityStats, setEntityStats] = useState({
    total: 0,
    defaultEnt: 0,
    userDefined: 0,
    //totalNodes: 0,
  });
  let { usage, setUsage } = useAuthStore();


  //calculation of the stats of masterlist card
  useEffect(() => {
    let total = 0;
    let defaultEnt = 0;
    let userDefined = 0;
    //let noOfKey = 0;
    entities.forEach((entity, i) => {
      if(entity.type ==="default"){
        defaultEnt++;
      }else{
        userDefined++;
      }
    })
    
    setEntityStats({
      total:entities.length,
      defaultEnt,
      userDefined
    });

    return () => {};
  }, [entities]);

  return (
    <div
      
      className="  bg-white w-full cursor-pointer shadow-lg 
      min-w-[20vw] max-w-[20vw] h-[15vw] relative"
     
    >
      <div className="flex space-x-2 absolute top-[0.5vw] left-[0.5vw] ">
        <img
          className="w-[1.3vw] h-[1.3vw]"
          src="/images/masterListBlue.svg"
          alt="validate"
        ></img>
        <p className="text-[1.1vw] font-medium ">MasterList</p>
      </div>
      { ( entities.length<= 0 || !uploadComplete) ? (
        <div className="w-full h-full flex justify-center items-center cursor-pointer"
     
        >
          <div
            className={`h-full  flex justify-center items-center text-center text-[1vw] cursor-${
              // currentPracticeArea?.stages[0].active === true
              workflowStore?.currentWorkflow?.stages[0].active===true  
              ? "pointer"
                : "not-allowed"
            } ${
              // currentPracticeArea?.stages[0].active === true
              workflowStore?.currentWorkflow?.stages[0].active=== true 
              ? "text-[1vw] font-medium  hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              // currentPracticeArea?.stages[0].active === 
              workflowStore?.currentWorkflow?.stages.find((s:any)=>s.name==="upload")?.active===true? navigate("/workflow/masterlist?workflowId="+workflowStore?.currentWorkflow?.workflowId)
                : toast("Upload some files before creating a masterlist!", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${
                // currentPracticeArea?.stages[0].active === 
                workflowStore?.currentWorkflow?.stages[0].active===true   
              
                  ? "#2C2C2C"
                  : "white"
              }`,
            
              width: "15vw",
              height: "2.5vw",
            }}
          >
            Create Masterlist
          </div>
        </div>
      ) : (
        <div className=" text-[#7A7A7A] p-[0.2vw]  h-full pt-[3vw] px-[1vw]" 
        onClick={async() => {
          if(!workflowStore?.currentWorkflow?.stages[1]?.active)
          {
            try{
              let response =await Axios.post("/masterlist/createMasterlist", {
                createdBy: userDetails?.userId,
                workflowId: workflowStore?.currentWorkflow?.workflowId });
                
                // Updating state
                // let temp = usage;
                // if (temp) {
                //     temp.details.noOfMasterlist = temp.details.noOfMasterlist + 1;
                //     setUsage(temp);
                //     // console.log("masterlist: ", temp.details.noOfMasterlist , usage?.details.noOfMasterlist);
                // }
  
                navigate("/workflow/masterlist?workflowId="+workflowStore?.currentWorkflow?.workflowId);
                toast("Masterlist was created successfully",{type:toast.TYPE.SUCCESS});
             }catch{
                toast("Could not create masterlist",{type:toast.TYPE.ERROR});
             }
          }
          // currentPracticeArea?.stages[0].active === true
          workflowStore?.currentWorkflow?.stages[0].active===true     
            ? navigate("/workflow/masterlist?workflowId="+workflowStore?.currentWorkflow?.workflowId)
            : toast("No uploaded files found!", { type: "warning" });
        }}
        style={{ color: "#7A7A7A", fontSize: "13px" }}>
          <div className="flex text-[0.9vw] justify-between">
            <p>Total Entities </p>
            <p>{entityStats.total}</p>
          </div>
          <Divider className="my-[0.5vw]" />
          <div className="text-[0.8vw]  flex justify-between">
            <p>Default Entities </p>
            <p>{entityStats.defaultEnt}</p>
          </div>
          <Divider className="my-[0.5vw]" />
          <div className="text-[0.8vw] flex justify-between">
            <p>User Defined Entities </p>
            <p>{entityStats.userDefined}</p>
          </div>
          <Divider className="my-[0.5vw]" />
          
        </div>
      )}
    </div>
  );
};

export default NERMasterListCard;
