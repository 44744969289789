import * as React from "react";

const Loader: React.FC = () => {
    return (
        <div className={"w-full h-96 flex justify-center items-center"}>
            <img src="/images/loading_blue.svg" className="w-[6vw]" alt="loading icon" width={30} />
        </div>
    );
};

export default Loader;
