import { Action } from "../../utils/global-interfaces"
import { FEEDBACK_ACCEPT_PARA, FEEDBACK_ADD_PARAS, FEEDBACK_ADD_REJECT_PARA, FEEDBACK_REJECT_PARA_INIT, SET_FEEDBACK_DETAILS, SET_UPDATE_ACTIVE } from "../types/feedback.types";

export interface feedbackReducerType{
    current_paras:Array<any>,
    rejected_paras:Array<any>,
    isActive:boolean,
    tab:number,
    node_id:string|null,
    node_name:string|null,
    accuracy : number
}
let defaultState:feedbackReducerType={
    current_paras:[],
    rejected_paras:[],
    isActive:false,
    tab:0,
    node_id:null,
    node_name:null,
    accuracy : 0
}
let FeedbackReducer=(state=defaultState, action:Action)=>{
    switch(action.type){
        case FEEDBACK_ADD_PARAS:{
            return {
				...state,
				current_paras : action.payload
			}
        }
        case FEEDBACK_ADD_REJECT_PARA:{
            return {
				...state,
				rejected_paras: action.payload.rejected_paras,
				current_paras: action.payload.current_paras,
			}
        }
        case SET_UPDATE_ACTIVE:{
            return{
                ...state,
                isActive:action.payload.action
            }
        }
        case SET_FEEDBACK_DETAILS:{
            return {
                ...state,
                tab:action.payload.tab,
                node_id:action.payload.node_id,
                node_name:action.payload.node_name,
                accuracy : action.payload.accuracy
            }
        }
        case FEEDBACK_REJECT_PARA_INIT:{
            return {
                ...state,
                rejected_paras:[]
            }
        }
        case FEEDBACK_ACCEPT_PARA:{
            return {
                ...state,
                current_paras: state.current_paras.map((para:any)=>{
                    if(para.paraId===action.payload.paraId){
                        para.accepted="accepted"
                    }
                    return para
                })
            }
        }
        default : {
			return state
		}
    }
}
export default FeedbackReducer;