import React from 'react';
import AdvancedMasterlist from '../components/AdvancedMasterlist';
import PageLayout from '../components/layouts/PageLayout';
import AppLayout from '../components/layouts/AppLayout';
import useWorkflowStore from '../store/useWorkflowStore';
import {} from '../assets/am_icon.svg'

const AM:React.FC = ()=>{
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const project =useWorkflowStore(state=>state.currentProject);
    return (
       <AppLayout>
        <PageLayout pageSubHeading='Advanced Masterlist' pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl='./images/am_icon.svg'>
            <AdvancedMasterlist />
        </PageLayout>
       </AppLayout>
      )
}


export default AM;