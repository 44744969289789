import { toast } from 'react-toastify';
import Axios from '../../utils/Axios';
export const callAutoML = async(workflowId:string)=>{

    
    try{
        await Axios.post("/integration/mlc-automl",{workflowId});

        return true;

    }catch{
        toast.warn("Could not save changes, please refresh the page and try again!");
        return false
    }





}

export const IterateToTrain = async(workflowId:string)=>{

    
    try{
        await Axios.post("/workflow/iterateToTrain",{workflowId});
        return true;

    }catch{
        toast.warn("There was an error while updating the workflow, please refresh the page and try again.");
        return false
    }





}