import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import Axios from '../../../../utils/Axios';
import useWorkflowStore from '../../../../store/useWorkflowStore';
import MultiUserBarGraph from '../MultiUserBarGraph';
import Heatmap from '../../../validation-summary/Heatmap';
import StackedBar from '../../../validation-summary/StackedBar';
import MLCStackedBar from '../MLCStackedGraph';
import { toast } from 'react-toastify';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import {Select} from 'antd';
import template from '../../../../template';
import { TrainStatus, checkCurrentTrainStatus } from '../../../../utils/navigationHelper';


interface ProjectSummaryPropsType {
  stage: number,
  batchNumber:number,
  heatmapData:any,
  pieData:any,
  max:number,
  requestType:number,
  setRequestType:any
}

let MLCProjectSummary: React.FC<ProjectSummaryPropsType> = ({ stage,batchNumber,heatmapData,pieData,max,requestType,setRequestType}) => {

  const [data, setData] = useState([]);
  const workflow = useWorkflowStore(state => state.currentWorkflow);
  console.log("=== bar graph ===",pieData);
  const currentStatus = checkCurrentTrainStatus(workflow);

  



  return (
    <>    
   {stage===2 && <div className="w-[200px] mx-3 my-[px]  self-start">
        <Select
        style={{width:"15vw"}}
         defaultValue={1}
        value={requestType}
           onChange={setRequestType}
            className="w-[200px] !text-[1.1vw] !py-[0.4vw]  rounded bg-[#EAEAEA] "
             bordered={false}>


          {currentStatus === TrainStatus.NotTrained && <Select.Option className="text-[1vw] py-1" value={1}>Current Batch</Select.Option>}
          <Select.Option className="text-[1vw] py-1" value={2}>All Stage 2 Batches</Select.Option>
          <Select.Option className="text-[1vw] py-1" value={3}>All Annotated Data</Select.Option>
        </Select>
      </div>}
    <div className='bg-white m-2 p-2 h-full w-full mt-7   flex flex-col items-center justify-center  p-6 space-y-5' >
    
   



         
      <div className='w-full  bg-white  flex items-center justify-center items-stretch  space-x-4' >



       {<> <div className='flex-1 border-2 w-full ' >
          {

            !pieData.loading 
            ? <MLCStackedBar
              graphData={pieData?.seriesData?.para_count}
              batchNumber={batchNumber}
              workflowId={workflow?.workflowId}
              stage={stage}
              requestType={requestType}
              canShowCommonParas={true}
              /> :
              (
                <div className='p-3 h-full flex justify-center items-center '>
                  <AiOutlineLoading3Quarters size={40} color={"gray"} className=" animate-spin" />
                </div>
              )
          }

        </div>
        <div className=' flex-1 border-2'>
          {

            !pieData.loading ? <MultiUserBarGraph  
            stage={stage} 
            graphData={pieData.seriesData} 
            max={max}
            requestType={requestType} /> :
              (
                <div className='p-3 h-full flex justify-center items-center '>
                  <AiOutlineLoading3Quarters size={40} color={"gray"} className=" animate-spin" />
                </div>
              )
          }


        </div>
        </>}
      
      </div>


      <div className=' w-full border border-2 flex justify-center'>
        {

           !heatmapData.loading && <Heatmap seriesData={heatmapData.seriesData} options={heatmapData.options} loading={ heatmapData.loading} stage={stage} batchNumber={batchNumber}  />
           
        }


      </div>
    </div>
    </>






  )
}


export default MLCProjectSummary;
