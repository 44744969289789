import React, { useEffect, useState } from "react";
import NotificationSearchComp from "../../components/controls/NotficationSearchComp";
import AppLayout from "../../components/layouts/AppLayout";
import useAuthStore from "../../store/authStore";
import Axios from "../../utils/Axios";
import { Divider, notification, Pagination } from "antd";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useNotifications from "../../store/useNotifications";
import { getMessage } from "../../components/controls/util";
import { toast } from "react-toastify";


//Import Icons Here
import { ReactComponent as Success } from '../../assets/success.svg';
import { ReactComponent as Warning } from '../../assets/warning.svg';
import { ReactComponent as Error } from '../../assets/error.svg';
import { ReactComponent as LoadingCircle } from '../../assets/loading_circle.svg';
import { ReactComponent as EmptyFolder } from '../../assets/empty_folder.svg';
import { ReactComponent as ElStar } from '../../assets/elStar.svg';
import { ReactComponent as ElFilledStar } from '../../assets/elFilledStar.svg';
import { ReactComponent as Bookmark } from '../../assets/bi_bookmark.svg';
import { ReactComponent as BookmarkFilled } from '../../assets/bi_bookmark-fill.svg';
import {ReactComponent as EmptyFolderGray} from '../../assets/empty_folder_gray.svg';
import { M } from "msw/lib/glossary-297d38ba";



let ProjectDashboard: React.FC = () => {
    const pageSize = 8;
    let { userDetails } = useAuthStore();
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState<Array<any>>([]);
    const { setIsSeen } = useNotifications();
    let [searchTerm, setSearchTerm] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [currentNotes, setCurrentNotes] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [filters, setFilters] = useState<any>({
        warning: false,
        success: false,
        failure: false,
        isStarred: false,
        message: ""
    });
    const [filteredList, setFilteredList] = useState<Array<any>>([]);







    const fetchNotifications = async () => {
        setLoading(true);

    try{
        let res = await Axios.post("/notification/getall", {
            limit: 400,
            page: 1
        });
       
        setNotifications(res.data.data);
        setFilteredList(res.data.data);
        

        }
        catch(err:any){
            toast("There was an error while fetching notifications, reload the page and try again",{type:toast.TYPE.ERROR});
        }
        finally{
            setLoading(false)
        }
    }

    //Call Toggle Bookmark Function Here
    const ToggleBookmark = async (nId: String, value: boolean) => {
        if (!nId) return
        setNotifications(notifications.map((n: any) => {
            if (n.notificationId && n.notificationId === nId) {
                if (!n.userInfo) n.userInfo = {}
                n.userInfo[userDetails?.userId || ""] = {
                    isStarred: value
                };

            }
            return n;
        }));

        try {
            let res = await Axios.post("/notification/markas", {
                notificationIds: [nId],
                key: "isStarred",
                value: value
            });
        }
        catch (err) {
            setNotifications(notifications.map((n: any) => {
                if (n.notificationId === nId) {
                    n.userInfo[userDetails?.userId || ""].isStarred = !value;
                    return n;
                }
                else {
                    return n;
                }
            }))
            console.log("=== Error while bookmarking notifications ===")
        }



    }

    //Divide List Of Notifications into Datewise Structure
    const calculateData = (data: any) => {
        let final_data: any = {};

        data.forEach((n: any) => {
            if (!final_data[moment(n.createdAt).format("Do MMM YYYY")]) {
                final_data[moment(n.createdAt).format("Do MMM YYYY")] = [];
            }

            final_data[moment(n.createdAt).format("Do MMM YYYY")].push(n);
        });

        console.log("=== Data ===", final_data);
        return final_data;
    }

    //Mark All As Read Function Here
    const MarkAllAsRead = async (notifications: any) => {
        if (!notifications.length) return;

        let nId:Array<string> =[]
         notifications.forEach((n: any) => {
            if (!n.userInfo || !n.userInfo[userDetails?.userId || ""]?.isSeen) {
                nId.push(n.notificationId);
            }
        });

        if (!nId.length) {
            console.log("=== No Notifications To Update ===")
            return;
        }

        try {
            let res = await Axios.post("/notification/markas", {
                notificationIds: nId,
                key: "isSeen",
                value: true
            });
            setIsSeen(true);
        }
        catch (err) {

            console.log("=== Error while bookmarking notifications ===")
        }



    }

    //Change Current Notifications
    const updateView = async()=>{
        let size = pageSize>filteredList.length?filteredList.length : pageSize;
        let notes:Array<any> = filteredList.slice(((page - 1) * size), (page * size));
        setCurrentNotes(calculateData(notes));

        //Mark Notifications as read if they are unread
        await MarkAllAsRead(notes);

    }



    //let's fetch all the notifications
    useEffect(() => {
        //add loading state here
       
        fetchNotifications();
       

        //add loading state here
    }, []);


    //filter projects when filters are clicked
    useEffect(() => {
        console.log("=== Filters were changed ===", filters);
        // setFilteredList(notifications) 
        // return;

        if (filters.error || filters.warning || filters.success || filters.starred) {
            //setPage(1);
        }

        if (!notifications.length) return;

        let list: Array<any> = notifications.filter((n: any) => {

            if (filters.error || filters.warning || filters.success || filters.starred) {

                return (filters.warning && n.type === "warning")
                    || (filters.error && n.type === "error")
                    || (filters.success && (n.type === "success" || n.type === "info"))

            }
            else {
                return true;
            }
        })
            .filter((n: any) => {
                if (filters.isStarred) {

                    return (filters.isStarred && n.userInfo && n?.userInfo[userDetails?.userId || ""]?.isStarred)
                }
                else {
                    return true;
                }
            })
            .filter((n: any) => {
               if(searchTerm === "")
               {
                return n;
               } 
                return getMessage(n.errorCode,n.message,n.type).toLowerCase().includes(searchTerm.toLowerCase())
            });



       // setTotal(list.length);
        let size = pageSize

        // setFilteredList(calculateData(list.slice(  ((page-1)*size) , (page*size) ) ));
        setFilteredList(list);
        //let d =filteredList
        //setCurrentNotes(calculateData( d ));

        
        //setFilteredList(calculateData(list));
        if (pageSize > filteredList.length) {
            size = filteredList.length;
            setPage(1);
        }



    }, [filters]);



    //Listens to page change 
    useEffect(() => {
      updateView();
    }, [page]);


    //Listens to filter changes
    useEffect(() => { 
       updateView();
       setPage(1);
       console.log("=== List Data ===", filteredList, currentNotes);
   }, [filteredList])



   //Notification ListItem Component
    const NotificationCard = (key: string, notification: any) => {

        if(notification.type==="success")
        {

            console.log("=== Notification Received ===",notification);
        }

        let fromNowText = moment(notification?.createdAt).fromNow().split(" ");

        if ((fromNowText[0] === "a" || fromNowText[0] === "an") && fromNowText[1] !== "few") {
            fromNowText[0] = "1"
        }



        return (
            <div key={key} className='flex w-full hover:shadow-md h-[6vw] bg-white px-[2vw] mb-2 '>

                <div className=" w-[80%]  font-medium  text-[0.9vw]  flex justify-start items-center space-x-[1vw]">

                    <span className="flex h-full items-center">

                        {notification.type === "warning" ? 
                                            <Warning width="1.5vw" height="1.5vw" /> 
                                            : notification.type === "error" 
                                            ? <Error width="1.5vw" height="1.5vw"   /> 
                                            : <Success width="1.5vw" height="1.5vw" />}
                    </span>
                    <p className='text-ellipsis text-[#777777] '>
                        {getMessage(notification?.errorCode,notification.message,notification.type)} ({notification?.projectInfo?.name} - {notification?.workflowInfo?.workflowName})
                    </p>

                </div>

                <div className="w-[20%] space-x-1 text-[#999999]  text-[0.8vw] uppercase flex justify-end items-center  space-x-[1vw]">
                    <span>{moment(notification?.createdAt).format("DD/MM/YYYY LT")} ({fromNowText.join(" ")})</span>
                    <span className="cursor-pointer">
                        {
                            notification?.userInfo &&
                                notification?.userInfo[userDetails?.userId || ""]?.isStarred
                                ? <BookmarkFilled width={"1.2vw"} height={"1.2vw"} onClick={() => ToggleBookmark(notification.notificationId, false)} />
                                : <Bookmark width={"1.2vw"} height={"1.2vw"} onClick={() => ToggleBookmark(notification.notificationId, true)} />

                        }
                    </span>

                </div>


            </div>


        )
    }



    return (
        <AppLayout>

            <div className="py-[1.5vw] px-4 -mt-[2vw]">
                {/* <div className="text-[1.5vw] font-semibold text-gray-400">Welcome back,</div> */}
                <div className="text-[1.5vw] font-medium text-[#c4c4c4]">Notifications</div>
            </div>
            <NotificationSearchComp
                data={notifications}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setFilters={setFilters}
                filters={filters} />
            <Divider />


            <div className="flex justify-between items-center px-[1vw]">

                <div className="flex items-center justify-start w-[65%] h-[5vw] space-x-[2vw]">
                    <div className="text-[1vw] text-[#999999]">Filter By</div>

                    <div className="flex space-x-2">
                        <div onClick={() => setFilters({ ...filters, warning: !filters.warning })}

                            className={`rounded-full text-[0.8vw]
                                    ${filters.warning ? " text-white bg-[#11256D] border-2 border-[#11256D]" : "text-[#777777] border-2"}
                                    hover1:bg-[#11256D] hover1:text-white duration-700 
                                    hover:border-[#11256D] 
                                    border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                            Warning
                        </div>

                        <div onClick={() => setFilters({ ...filters, error: !filters.error })}

                            className={`rounded-full text-[0.8vw]
                                ${filters.error ? " text-white bg-[#11256D] border-2 border-[#11256D]" : "text-[#777777] border-2"}
                                hover1:bg-[#11256D] hover1:text-white duration-700 
                                hover:border-[#11256D] 
                                border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                            Errors
                        </div>

                        <div onClick={() => setFilters({ ...filters, success: !filters.success })}

                            className={`rounded-full text-[0.8vw]
                        ${filters.success ? " text-white bg-[#11256D] border-2 border-[#11256D]" : "text-[#777777] border-2"}
                        hover1:bg-[#11256D] hover1:text-white duration-700 
                        hover:border-[#11256D] 
                        border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                            Information
                        </div>


                        <div className="border-l-2" ></div>

                        <div onClick={() => setFilters({ ...filters, isStarred: !filters.isStarred })}

                            className={`rounded-full text-[0.8vw]
                        ${filters.isStarred ? " text-white bg-[#11256D] border-2 border-[#11256D]" : "text-[#777777] border-2"}
                        hover1:bg-[#11256D] hover1:text-white duration-700 
                        hover:border-[#11256D] 
                        border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                            Bookmarked
                        </div>
                    </div>

                    <div className="flex space-x-2">

                        <div className="border-l-2" ></div>

                    </div>



                    <div onClick={() => {
                        setFilters({})
                        setSearchTerm("")
                    }
                    }
                        className={`rounded-full text-[0.8vw] 
                       hover:bg-red-400 hover:text-white duration-700
                      border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                        Clear All</div>

                    {/* Will Add Project Type Filters Later */}
                    {/* <div className="rounded-full text-[0.8vw] text-white border px-[0.8vw] py-[0.3vw] bg-[#11256D] cursor-pointer">MultiClass</div> */}
                </div>


            </div>


            {/* Header */}
            {/* <div className='flex w-full  h-[3vw] px-4'>

                <div className="flex-[1.5] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                    <span className="uppercase">Name</span>
                   
                </div>

               

              

                <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                    <span className="uppercase">Last Updated</span>
                   
                </div>


            </div> */}


            {/* Data */}
            <div className="mt-[1vw] w-full">

                {
                    loading && <div className="h-[20vw] flex items-center justify-center">
                        {/* <img src="/images/loading_circle.svg" className="w-[12vw] animate animate-spin" alt="loading" /> */}
                        <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />
                    </div>
                }

                {
                    !loading && Object.keys(filteredList).length === 0
                    && <div className="h-[20vw] flex flex-col items-center justify-center">
                        <EmptyFolderGray />
                        <div className="uppercase text-[1.5vw] font-semibold text-[#777777]">No Notifications Found</div>
                        {notifications.length === 0 && <p className="text-center mt-4 text-[#777777] text-[1vw]">

                            Stay Tuned! Important notifications about your project will show up here.
                        </p>}
                    </div>

                }


                {/* {!loading && Object.keys(filteredList).length>0 && */}
                {!loading && Object.keys(currentNotes).length > 0 &&

                    <div className="space-y-2">
                        {currentNotes && Object.keys(currentNotes).map((n: any) => {
                            return (
                                <div className="space-y-2">
                                    {currentNotes[n] && <p className=" mt-[2vw] bg-[#f1f1f1] text-[1vw] p-[0.5vw] text-center rounded-lg max-w-[10vw]">
                                        {n === moment(n.createdAt).format("Do MMM YYYY") ? "Today" : n}
                                    </p>}
                                    <div>
                                        {
                                            currentNotes[n] && currentNotes[n].map((note: any) => NotificationCard(note?._id, note))
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }


                    </div>
                    //  filteredList.map((notification: any) => ProjectCard(notification?._id, notification))
                }

                <div className="flex items-center justify-end ">
                    {!loading && <div className={`bg-white px-[0.5vw] py-[0.4vw]`} >

                        <Pagination
                            responsive ={true}
                            current={page}
                            pageSize={pageSize}
                            className="!text-[0.9vw]"
                            onChange={p => setPage(p)}
                            total={filteredList.length} />
                    </div>}
                </div>

            </div>

        </AppLayout>
    );
};

export default ProjectDashboard;
