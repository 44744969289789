import React, { useState } from 'react'
import Validations from './validation/Validations';
import EditableValidation from './EditableValidation';
import Prompt from '../shared/prompt/Prompt'
import AddNewAnswer from './AddNewAnswer'

import {ReactComponent as LoadingWhite} from '../../../assets/loading_white.svg';
import {AiFillPlusCircle} from 'react-icons/ai';
import { toast } from 'react-toastify';
import { createPrompt } from '../_apiCalls';
import useWorkflowStore from '../../../store/useWorkflowStore';



const PredictionButton =({text,loading,onClick,disabled,disabledTitle,styles,testId}:any)=>{

  return (<button
  data-testid={testId}
    disabled={disabled}
    title={disabled && disabledTitle}
    onClick={!disabled ? onClick:()=>{}}
    className={`uppercase duration-200  w-[16vw]
          text-white text-[0.9vw] 
           h-[3vw]  text-center rounded-md border-2 hover:scale-105 
           disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
          border-transparent bg-primaryBlue hover:drop-shadow-md 
          ${styles}  `}
  >
    {loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                    :  <div className='flex items-center justify-center space-x-[0.5vw]'>
                    {/* <AiFillPlusCircle
                    className='h-[1.5vw] w-[1.5vw]' />  */}
                    <span>{text}</span>
                    </div>}
  </button>)

}





function AddNewPrompt({setEditState,callback,canGoBack}:any) {

  const [prompt,setPrompt] = useState<string>("");
  const [answers,setAnswers] = useState<any[]>([]);

  const [rankSet,setRankSet] = useState<Set<number>>(new Set<number>([]));

  const [loading,setLoading] = useState<boolean>(false);

  
  const workflow = useWorkflowStore(state=>state.currentWorkflow);


  const resetStates = ()=>{
    setPrompt("");
    setAnswers([]);
   
  }

  return (
    <div className='space-y-[1vw] bg-white px-[1vw] pb-[2vw]'>
    {/* Prompt */}
    <div>
       <Prompt
       
       promptId={""}
       text={prompt}
       editable={false}
       edit={true}

       isNewPrompt={true}

       //  Functions here
       setText={setPrompt}
       setEditState={()=>{}}
       />
    </div>

    {/* Back Button */}
    <div className='border-t border-b py-[0.5vw] space-x-[1vw]'>
            {canGoBack &&   <PredictionButton 
            testId="back-button"
            text={"Back"}
            styles={`!bg-white !text-primaryBlue
                         border border-primaryBlue
                         `}
            onClick={()=>setEditState(false)}/>}
            <PredictionButton 
            testId="save-button"
            loading={loading}
            disabled={loading}
            onClick={async()=>{
              if(!workflow) return;

              setLoading(true);
              const {error,data}=await createPrompt(workflow.workflowId,{prompt,answers});
              setLoading(false);
              if(!error){
                  resetStates();
                  callback && callback();
                }
                
            }}
            text={"Save"}/>
        </div>

 

    {/*Add Answer */}
   {answers.length===0 && <div className='space-y-[1vw]'>
      <AddNewAnswer
      rankSet={rankSet} 
      setActive={()=>{}}
      answers={answers}
      saveAnswer={async(ans:any,callback:any)=>{
        if(ans.text?.length<3){
          toast.warn("Answer must be 3 words long");
          return;
        }
        console.log("Answers to be added",ans);
        let temp = [ans];
        console.log("New Answers",temp);
       
        setAnswers(temp);
        if(callback) callback();
        
      }}
      />
    </div>}

    {/* List of All Answers (are now editable) */}
    {answers.length>0 && 
      <EditableValidation 
      key={answers[0]?.responseId}
        active={false}
        setActive={()=>{}}
        index={0}
        answer={answers[0]}
        answers={answers}
        validation={answers[0]}
        canDelete ={true}
        rankSet={rankSet}
       

        updateAnswer={(text:string)=>{

          const temp = [{
            ...answers[0],
            text
          }]
          setAnswers(temp);
        }}
      
      
      />
    }
  

</div>
  )
}

export default AddNewPrompt