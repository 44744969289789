import { filter } from 'lodash';
import { toast } from 'react-toastify';
import Axios from '../../utils/Axios';
import { callModelComparisonAPI } from '../mlc-components/model-comparison/_apiCall';
import { TrainStatus, checkCurrentTrainStatus } from '../../utils/navigationHelper';
import { IterateToTrain } from '../mlc-components/_apiCalls';


export const getNextPredictedIssueId = (para:any,workflowId:string)=>{
    console.log("== getNextPredicted was called ==");

    let rankMetric = para.workflows[workflowId]?.alpdsInfo?.rankMetric.stage2;
    let predictedByClassifier = rankMetric?.predictionMadeByClassifier

    if(!rankMetric.value)
    {
        return "No Class Found";
    }

    if( !predictedByClassifier || predictedByClassifier === 1)
    {
        return rankMetric?.value
    }
    else if(predictedByClassifier === 2)
    {
        let classes:any = [...rankMetric.value]
        classes.sort((a:Array<any>,b:Array<any>)=> a[2]<b[2]?1:-1 );
        //console.log("=== classes ===",classes,para.paraId);
        return classes;
    }

}

export const getClassesInAlphabeticOrder = (nodes:Array<any>)=>{


    if(!nodes || ! nodes.length)
    {
        return [];
    }


        let nextNodes = nodes
                        .filter((node:any)=>node.is_model_class===true)
                        .map((node:any)=> {return {node_name:node.node_name,node_id:node.node_id}})

        nextNodes = nextNodes.sort((a:any,b:any)=> a.node_name.toLowerCase().trim()>b.node_name.toLowerCase().trim()?1:-1 );
        //console.log("== getClassesInAlphabeticOrder was called ==",nextNodes);
        //console.log("=== classes ===",classes,para.paraId);
        return nextNodes.map(node=>{return [node.node_id]});


}

export const BulkValidateParas = async(paraIds:Array<string>,workflowId:string)=>{


    try{
        await Axios.post("/annotations/bulkParaAction",{
            paraIds,workflowId
        });
        toast(`Auto Validated ${paraIds.length} paragraphs`,{type:toast.TYPE.SUCCESS});
    }
    catch{
        toast(`Could not auto update paras, Please refresh the page and try again!`,{type:toast.TYPE.ERROR});

    }


};

export const MLCBulkValidateParas = async(paraData:Array<any>,workflowId:string)=>{


    try{
        await Axios.post("/annotations/mlc-bulkParaAction",{
            paraData,workflowId
        });
        toast(`Auto Validated ${paraData.length} paragraphs`,{type:toast.TYPE.SUCCESS});
    }
    catch{
        toast(`Could not autovalidate paras, Please refresh the page and try again!`,{type:toast.TYPE.ERROR});

    }


};

export const CallAutoML =async (currentWorkflow:any,navigate:any)=>{
    try {

        await Axios.post("/integration/automl", {
          workflowId: currentWorkflow?.workflowId
        });

        setTimeout(() => navigate("/workflow/modeltraining-1?workflowId=" + currentWorkflow?.workflowId)
          , 1000);

      } catch (err: any) {
        toast(err.response.data.message || "Error while initiating model training",
          { type: toast.TYPE.ERROR });


      }
}

export const NavigateToTrainStage =async(workflow:any,navigate:any)=>{


    let shouldNavigate:boolean = true;
    let navigatePoint:string ="";
      switch(checkCurrentTrainStatus(workflow)){



        case TrainStatus.NotTrained:
              //alert("You have not trained the model");
              await callModelComparisonAPI(workflow.workflowId);
              shouldNavigate=true;
              navigatePoint ="model-comparision"
              break;
        case TrainStatus.IsInLLMModelTuning:
                navigatePoint="tune-llm-model";
                break;
        case TrainStatus.IsInLLMModelTrainig:
                navigatePoint="train-llm";
                break;
        case TrainStatus.InModelTuning:
              navigatePoint ="model-tuning"
              //alert("you are in model tuning");
              shouldNavigate=false;
              break;
        case TrainStatus.InModelSelection:
              navigatePoint = "model-comparision"
              //alert("you are in model selection");
              shouldNavigate =false;
              break;

        case TrainStatus.InTraining:
              navigatePoint = "modeltraining-1"
              //alert("You are in training right now");
              shouldNavigate=false;
              break;
        case TrainStatus.Trained:
              navigatePoint = "modeltraining-2"
              shouldNavigate=false;
             // alert("You want to start iterate");
              break;
        case TrainStatus.InIterate:
             // alert("You are in iterate mode");
             if(workflow?.alpdsInfo?.selectedModelInfo?.type === "llm"){
                await IterateToTrain(workflow?.workflowId);
          navigatePoint = "train-llm"

             }else{
                 await CallAutoML(workflow,navigate);
             }
              //navigatePoint = "modeltraining-1"
              shouldNavigate=false;
              break;
        default:
          //  alert("could not find anything")


      }
      navigatePoint.length && navigate(`/workflow/${navigatePoint}?workflowId=`+workflow?.workflowId);
      return;

   
}

export const CallNextBatch = async(workflow:any)=>{

    const workflowId = workflow.workflowId;
    const currentBath = workflow?.alpdsInfo
                                ?.percentageStatus
                                ?.stage2Progress
                                ?.currentBatchNumber

    try{

        const res = await Axios.post("/integration/stage2_init",{
            workflowId,
            batchNumber:currentBath+1
            
        })


    }catch{
        toast.error("there was an error while calling a new batch")
    }



}



