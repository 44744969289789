import { Divider, Select } from "antd";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
//import { useDispatch, useSelector } from "react-redux";





import { toast } from "react-toastify";


import useWorkflowStore from "../../../store/useWorkflowStore";




import useKeyboard from "../../../hooks/useKeyboard";
import NerPara from "../../ner-components/NerPara";
import MLCParaTags from "../_shared/MLCParaTags";
import { useRecoilValue } from "recoil";
import { nodesAtom } from "../../masterlist/atoms";

export interface StageParaProps {
  
  para:any;
  index:number;
  activePara:number,
  setActivePara:(index:number)=>void
  
  
}

const StagePara: React.FC<StageParaProps> = (props) => {
  const {
    para,
    index,
    activePara,
    setActivePara,
   
    
  } = props;

  const [fade, setFade] = useState(false);
  const [loading,setLoading] = useState(false);
  const isActive = index === activePara;
  const currentDiv = useRef<HTMLDivElement>(null);
  
  
  //console.log("readOnly",readOnly);
  //Keyboard Shortcuts
  const divRef = useRef<HTMLDivElement>(null);
  const Enter = useKeyboard({keyCode:"Enter"})
  const BackSpace = useKeyboard({keyCode:"Tab"})
  useEffect(()=>{

    const wait = async()=>{
      await waitFor(100);
      
      divRef.current?.scrollIntoView({block:"nearest"});
      if(activePara === 0)
      {
        window.scrollTo({top:0,behavior:"smooth"});
      
      }
    };
    if(isActive)
    {
      wait();
     
    
      
    }
   
  },[isActive]);

 

  //Keyboard Shortcuts


  let waitFor = (duration:number)=>{
    return new Promise(resolve=>setTimeout(resolve,duration));
  }



  let nodes = useRecoilValue(nodesAtom);

  const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
  //sorted on their best match with paragraphs
  //const nextClasses:any[] = useMemo(()=>getNextPredictedIssueId(para,currentWorkflow?.workflowId || ""),[para.paraId,currentWorkflow?.workflowId])
  
  const [isMounted,setIsMounted] = useState<boolean>(false);

  const trainingInfo =para.workflows[currentWorkflow?.workflowId || ""].trainingInfo;
  const uploadType = para.workflows[currentWorkflow?.workflowId || ""].uploadType;

  const getClassName =(node_id:string):string=>{

    if(node_id === "No Tag") return "No Tag";

    if(node_id === null) return "No Associated Class";

    let node_name:string = nodes.find((node)=>node.node_id === node_id)?.node_name || "";

    return node_name;

  }

  //console.log("para",para,trainingInfo);
  const isSeed = !trainingInfo?true:false;
  const node_data:any=!isSeed ? trainingInfo?.result.map(((n:any)=>n.issueId))
                              :uploadType["0"].issueID;
  
  
  useEffect(() => {
    setIsMounted(true);
    

  }, [])


  













  return (
    <div
      ref={divRef}
      onClick={()=>setActivePara(index)}
      key={para.paraId}
      className={`w-full flex my-5 duration-200 bg-white border min-h-[8vw] 
      scroll-mt-[5vw] scroll-mb-[4vw]
      ${  fade ? "opacity-0" : ""}
      ${isMounted?"":"opacity-0"}
      ${isActive?"border-[#11256d] shadow-lg":"border-transparent"}
      `}
    >
      <div
        className={" bg-white w-[70%] rounded-[0.3vw] py-[1vw] px-[1.5vw] "}
      >
     
        <div  key={para.paraId} className="flex   ">
          <NerPara
           // nerInfo={nerInfos}
          
            paraData={para.paraData}
            paraId={para.paraId}
            expanded={isActive}
            minlength={670}
          />
        </div>
      </div>
      <div className="border my-[1vw] border-dashed border-l-1"></div>
    <div className="w-[30%]">
        <div className="flex flex-col gap-[1vw] px-[1vw] h-full justify-center p-2 ">
            {/* <div className="bg-red-100 text-[#E63946] h-[2vw] 
                            flex items-center justify-center 
                            rounded-[0.2vw] text-[1.2vw]">{
                                node_name?.length>27?node_name.slice(0,27)+"...":node_name
                            }</div> */}
            <MLCParaTags 
            readOnly={true}
            data={node_data}
            active={isActive}
            handleRemoveTag={()=>{}}

            
            />
            <div className="flex gap-[1vw]">
                <div className="bg-[#EAEAEA] text-[#11256D] h-[2vw] w-1/2  text-[1vw]
                                flex items-center justify-center rounded-[0.3vw]
                                rounded-[0.2vw]">{isSeed?"Seed":"Stage "+trainingInfo?.stage}</div>
               {!isSeed &&  trainingInfo?.stage===2 && <div className="bg-[#EAEAEA] text-[#11256D] h-[2vw] w-1/2  text-[1vw]
                                flex items-center justify-center rounded-[0.3vw]
                                rounded-[0.2vw]">{trainingInfo.batch}</div>}
            </div>
        </div>
      
    </div>
    
    </div>
  );
};

export default StagePara;
