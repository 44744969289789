import { toast } from "react-toastify";
import Axios from '../../../utils/Axios';

const endpoint ="/model-selection/";

export const callGetSelectedModelAPI = async (workflowId: string) => {

    try {
        const res = await Axios.post(endpoint+"get-user-selected-model", {
            workflowId,

        })
        console.log("==response received===",res.data.data);
        return res.data.data;
    } catch {
        toast.error("There was an error while saving the parameters");
        return false;
    }
}

export const callUpdateSelectedModelAPI = async (workflowId: string,
    parameters: any[],
    ) => {

    try {
        const res = await Axios.post(endpoint+"update-user-selected-model", {
            workflowId,
            parameters,

        })

        // const res2 = await Axios.post("/integration.automl", {
        //     workflowId,

        // })
        return res.data;
    } catch {
        toast.error("There was an error while saving the parameters");
        return false;
    }
}


export const callSaveSelectedModelAPI = async (workflowId: string, modelName: string) => {

    try {
        const res = await Axios.post(endpoint+"create-user-selected-model", {
            workflowId,
            modelName
        })
        return res.data;
    } catch {
        toast.error("There was an error while saving the selected model");
        return false;
    }
}

export const callMLCAutoMLAPI = async (workflowId: string) => {

    try {

        const res = await Axios.post("/integration/mlc-automl", {
            workflowId,
        })
        return res.data;
    } catch {
        toast.error("There was an error calling Model Training API");
        return false;
    }
}
