import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Popover } from 'antd';
import PermissionComponent from '../PermissionComponent';
import useWorkflowStore from '../../store/useWorkflowStore';
import authStore from '../../store/authStore';
import { useMatch } from 'react-router-dom';
import { Steps } from 'antd';
import VersionComponent from '../VersionComponent'
import template from "../../template";

interface CreateProjectPageLayoutProps {
    children:JSX.Element
}

let CreateProjectPageLayout: React.FC<CreateProjectPageLayoutProps> = ({ children }:CreateProjectPageLayoutProps) => {
    const workflowStore = useWorkflowStore();
 
  
    
    const projectType:string = workflowStore?.currentProject?.projectType || "";
    const workflowType:string = workflowStore?.currentProject?.workflowType || "";

    useEffect(()=>{
       
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })

  
},[])


    return (
        <>
            {children}
        </>
    );
};

export default CreateProjectPageLayout;
