import React, { useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import MasterlistComp from '../../components/masterlist'
import PageLayout from '../../components/layouts/PageLayout';
import useWorkflowStore from '../../store/useWorkflowStore';

//import template 
import template from "../../template";


interface MasterlistProps {}
let Stage2Summary: React.FC<MasterlistProps> = () => {
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const project =useWorkflowStore(state=>state.currentProject);

    const component =project? 
                          template[project?.projectType || ""][project?.workflowType || ""]?.summary || undefined
                          :undefined;
    console.log("===component===",component);
   
    return (


        <AppLayout>
            <PageLayout pageSubHeading="Stage2 Summmary" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/active_masterlist.svg">
          
            {component!== undefined?React.createElement(component.validationSummary):null}
            </PageLayout>
        </AppLayout>
    );
};

export default Stage2Summary;
