type Answer={
    text:string,
    autoValidationScore:number,
    source:string,
    responseId:string
}


export const getSortedAnswers= (answers:Answer[])=>{
    if(answers.length <2) return answers;

    return answers.sort((a1,a2)=>{
        if(a1.source === "user") return -1;
        if(a2.source === "user") return 1;

        if(a1.autoValidationScore === null) return 1;
        if(a2.autoValidationScore === null) return -1;

        return a1.autoValidationScore>a2.autoValidationScore?-1:1;
    });
}