
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useWorkflowStore from "../../store/useWorkflowStore";
import {useNavigate} from 'react-router-dom';
import { current } from "immer";
import useAuthStore from "../../store/authStore";
import { TrainStatus, checkCurrentTrainStatus } from "../../utils/navigationHelper";




const DeployCard:React.FC = () => {
  let  currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
  const userId = useAuthStore(state=>state.userDetails)?.userId;
  const createdBy = useWorkflowStore(state=>state.currentProject)?.createdBy;
  let isLLM =currentWorkflow && currentWorkflow?.alpdsInfo?.selectedModelInfo?.type==="llm"?true:false;

  //let history = useHistory();
  let [trained, setTrained] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if(currentWorkflow)
    {
      const trainStage:any = currentWorkflow?.stages?.find(s=>s.name==="train");
      const automlProgress = currentWorkflow?.alpdsInfo?.percentageStatus.automlProgress;
   
      const isCreator = userId === createdBy
      if(checkCurrentTrainStatus(currentWorkflow) === TrainStatus.Trained 
      && isCreator
      && !isLLM
      
      ){
          setTrained(true);
        }

       
    }
    return () => {};
  }, [currentWorkflow?.stages]);
  return (
    <div
      className="p-3  bg-white  w-full shadow-lg"
      style={{ height: "15vw", width: "20vw" }}
      onClick={() => {
        //trained && navigate("/workflow/modeltraining-2/dep?workflowId="+currentWorkflow?.workflowId)
      }}
    >
      <div className="  flex  space-x-2 py-2 items-center justify-start">
        <img
           style={{
            width: "2.5vw",
            height: "2.5vh",
          }}
          src="/images/deployBlue.svg"
          alt="validate"
        ></img>
        <p style={{ fontWeight: 500, fontSize: "1.1vw" }}>Deploy</p>
      </div>
      <div className=" flex w-full justify-center my-1  h-[80%]">
        <div className="w-11/12 flex flex-col gap-2 justify-center items-center">
          <div
            className={`flex justify-center items-center text-center cursor-${
              trained ? "pointer" : "not-allowed"
            } ${
              trained
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              trained?navigate("/workflow/modeltraining-2/dep?workflowId="+currentWorkflow?.workflowId,{state:{dep:"deploy1"}})
                : toast("Complete the model training first.", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${trained ? "#2C2C2C" : "white"}`,
              fontSize: "1vw",
              width: "15vw",
              height: "5vh",
            }}
          >
            Prediction on Ingested Data
          </div>
          <div
            className={`flex justify-center items-center text-center cursor-${
              trained ? "pointer" : "not-allowed"
            } ${
              trained
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              trained
                ? navigate("/workflow/modeltraining-2/dep?workflowId="+currentWorkflow?.workflowId,{state:{dep:"deploy2"}})
                : toast("Complete the model training first.", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${trained ? "#2C2C2C" : "white"}`,
              fontSize: "1vw",
              width: "15vw",
              height: "5vh",
            }}
          >
            Export Dataset
          </div>
          <div
            className={`flex justify-center items-center text-center cursor-${
              trained ? "pointer" : "not-allowed"
            } ${
              trained
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() =>
              trained
                ? navigate("/workflow/modeltraining-2/dep?workflowId="+currentWorkflow?.workflowId,{state:{dep:"deploy3"}})
                : toast("Complete the model training first.", {
                    type: "warning",
                  })
            }
            style={{
              border: `1px solid ${trained ? "#2C2C2C" : "white"}`,
              fontSize: "1vw",
              width: "15vw",
              height: "5vh",
            }}
          >
            Model API
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployCard;
