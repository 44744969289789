import React from "react";
import AppLayout from "../../components/layouts/AppLayout";
import PageLayout from "../../components/layouts/PageLayout";

import useWorkflowStore from "../../store/useWorkflowStore";
import useAuthStore from "../../store/authStore";

import UploadCard from "../../components/workflow-cards/uploadCard";
import StageCard from "../../components/workflow-cards/stagecard/index";

import ExportCard from "../../components/workflow-cards/exportCard";
import DeployCard from '../../components/workflow-cards/deployCard'

import VersionComponent from "../../components/VersionComponent";
import { useEffect, useState } from "react";
import Axios from '../../utils/Axios';
import { toast } from "react-toastify";
import checkForCriticalProgress from "../../lib/checkForProgress";
import Template  from "../../template";
import useVersionStore from "../../store/versionStore";
import { useNavigate } from "react-router-dom";

interface ListofVersion {
  _id: string;
  workflowId: string,
  workflowName: string
}


let WorkflowDashboard = () => {
  let workflowStore = useWorkflowStore();
  const isAuthenticated = useAuthStore(state=>state.isAuthenticated);

  // const [forkInProcess, setForkProcess] = useState<boolean>(false);
  //const [listOfVersion, setListOfVersion] = useState<Array<ListofVersion>>([]);
  const listOfVersion = useVersionStore(state=>state.listOfVersion);
  const setListOfVersion = useVersionStore(state=>state.setListOfVersion);
  let [selectedStages, setSelectedStages] = useState<any>({});
  const [isForkInProcess,setIsForkInProcess] = useState<boolean>(false);
  let { usage, setUsage } = useAuthStore();


  //const projectId:string=useWorkflowStore(state=>state.currentProject?.projectId) || "";
  const workflow = useWorkflowStore(state=>state.currentWorkflow);
  const project =useWorkflowStore(state=>state.currentProject);
  const projectId = project?.projectId;
  const navigate = useNavigate();
  
  const projectType = project?.projectType ;
  const workflowType = project?.workflowType ;

  const cards =projectType &&
   workflowType && Template[projectType][workflowType]?.workflowDashboard;
  const canFork = projectType && Template[projectType]?.hasForking;

  const template:any={
    "multiclass_classification":{
      "annotation_workflow":{
        forkLink:"aw_fork"
      },
      "annotation_prediction_workflow":{
        forkLink:"fork"
      }
    },
    "multilabel_classification":{
      "annotation_workflow":{
        forkLink:"aw_fork"
      },
      "annotation_prediction_workflow":{
        forkLink:"fork"
      }
    }
  }





    
    


  useEffect(() => {
    //console.log("======= workflow project changed =============",project?.projectId);
    
    let fetchProjectWorkflow = async () => {
      if(!projectId) return
      try{
        const res = await Axios.post("/project/getprojectworkflow", {
          projectId:projectId
        });
        let listofVersions: Array<any> = res.data?.data?.workflowDetails;
        //console.log("=== List of versions ===", listofVersions);
        listofVersions?.sort((v1, v2) => v1.createdAt > v2.createdAt ? 1 : -1);
        setListOfVersion(listofVersions);
      }catch(e){

      }
      
    }

    if(isAuthenticated)
    {
      fetchProjectWorkflow();
    }

  }, [projectId,isAuthenticated]);



  const createClickHandler = async (version: any, upload: boolean, masterlist: boolean, validate: boolean, train: boolean) => {
    let canCallDSApi:boolean | undefined = checkForCriticalProgress(workflowStore.currentWorkflow);

    if(canCallDSApi)
    {
      toast("A critical process is in progress, please wait for its completion and try again",{type:toast.TYPE.WARNING});
      return
    }
    
    
    
    toast("Workflow fork process initiated.", {
      type: "info",
    });

    if (!upload && !masterlist && !validate && !train) {
      //console.log("Fork is not Possible without selection of Stages");
      toast("It is not possible to fork without selecting the stages.", { type: 'error' })
      return;
    }
    const stageObject: any = {
      upload, masterlist, validate, train
    }
    setSelectedStages(stageObject);
    const projectId = workflowStore.currentProject?.projectId;
    const newWorkflowName: string = `Version ${listOfVersion.length + 1}`;

    //Initiate the workflow forking process
    const url = projectType && workflowType &&`/workflow/${template[projectType][workflowType].forkLink}`
    try{

      const versioningResponse: any = await Axios.post(url || "", {
        oldWorkflowId: version.workflowId,
        stageObject,
        projectId,
        newWorkflowName
      })
      navigate("/workflow?workflowId="+versioningResponse?.data?.data?.workflowId);

    
   
    // Updating state
    let temp = usage;
    if (temp) {
        temp.details.noOfWorkflows = temp.details.noOfWorkflows + 1;
        setUsage(temp);
    }

    setListOfVersion([...listOfVersion, { workflowId: versioningResponse.data.data.workflowId, 
      workflowName: versioningResponse.data.data.workflowName,
       stages: versioningResponse.data.data.stages }])

      }
      catch(err){
        toast.warn("There was an error while forking the workflow");
  
      }
      
  }
  return (
    <AppLayout>
      <PageLayout
        pageTitle={`${project?.name} (${workflow?.workflowName})`}
        pageSubHeading="Workflow"
        pageIconUrl="/images/active_dashboard.svg"
      >
        {!(workflow?.forkInfo?.forkInProcess || false) 
        && canFork
        &&<VersionComponent listOfVersion={listOfVersion} 
        createClickHandler={createClickHandler} />}

        <div className="grid justify-center h-[45vw] ">
          <div
            className={
              "w-full flex items-center overflow-x-scroll  custom_scroll -mt-[20vw]"
            }
            style={{
              overflowY: "hidden"
            }}
          >
            <div className={"h-full flex flex-col justify-center space-y-4"}>
              <UploadCard workflowStore={workflowStore} />
              {/* <MasterListCard currentWorkflow={workflow} /> */}
            </div>

            <div className={"flex flex-col justify-center"}>
              <svg width={25} height={20}>
                <path
                  d={"M 0 10 H 25"}
                  stroke={
                    //   currentPracticeArea?.stages[5].active === 
                    workflowStore.currentWorkflow?.stages[1].active
                      ? "#1BA94C"
                      : "#C4C4C4"
                  }
                  strokeWidth={2}
                  fill={"transparent"}
                />
              </svg>
            </div>


            <div className={"h-full flex flex-col justify-center space-y-4"}>
              {/* <UploadCard currentWorkflow={workflow}/> */}
              {
                cards?.masterlistCard?
                  React.createElement(cards.masterlistCard,{
                    workflowStore
                  })
                :null
              }
              
            </div>

         
            {cards?.masterlistCard?<div className={"flex flex-col justify-center"}>
              <svg width={25} height={20}>
                <path
                  d={"M 0 10 H 25"}
                  stroke={
                    //   currentPracticeArea?.stages[5].active === 
                    workflowStore.currentWorkflow?.stages[2].active
                      ? "#1BA94C"
                      : "#C4C4C4"
                  }
                  strokeWidth={2}
                  fill={"transparent"}
                />
              </svg>
            </div>:null}
            <div className={"h-full flex flex-col justify-center"}>
              <StageCard

              />
            </div>
           
            <div className={"flex flex-col justify-center"}>
              <svg width={25} height={20}>
                <path
                  d={"M 0 10 H 25"}
                  stroke={
                    //   currentPracticeArea?.stages[5].active === 
                    workflowStore.currentWorkflow?.stages[3]?.active
                      ? "#1BA94C"
                      : "#C4C4C4"
                  }
                  strokeWidth={2}
                  fill={"transparent"}
                />
              </svg>
            </div>

            {/* Complete Workflow Cards */}



            {
              workflowStore.currentWorkflow && workflowStore.currentWorkflow?.stages.find(s => s.name === "train")
              &&
              <div className={`flex flex-col justify-start ${cards.deployCard ?"mt-[16.5vw]":""}  items-center  `}
              >
                {
                 cards.trainCard? React.createElement(cards.trainCard):null
                }
                {/* <TrainCard
                /> */}
               {cards.deployCard && <svg width={50} height={20}>
                  <path
                    d={"M 10 0 V 50"}
                    strokeWidth={2}
                    fill={"transparent"}
                    stroke={
                      //   currentPracticeArea?.stages[5].active === 
                      workflowStore.currentWorkflow?.stages.find((stage)=>stage.name=== "Train")?.active
                        ? "#1BA94C"
                        : "#C4C4C4"
                    }
                  />
                </svg>}
                {/* <IterateCard /> */}
                {
                  cards.deployCard && React.createElement(cards.deployCard)
                }
              </div>
            }
            {
              workflowStore.currentWorkflow && workflowStore.currentWorkflow.stages?.filter(s => s.name === "export").length > 0
              &&
              <div className={"flex flex-col justify-center"}>
               {cards?.exportCard?React.createElement(cards?.exportCard)
               :<ExportCard
                  activeStage={3}
                />}
              </div>
            }
          </div>
        </div>

      </PageLayout>
    </AppLayout>
  );
};

export default WorkflowDashboard;
