import React, { useState } from "react";
import axios from "../../utils/Axios";
import { toast } from "react-toastify";
import { ReactComponent as LoadingWhite } from '../../assets/loading_white.svg';
import { Slider, Modal } from 'antd';
import useCompanyUsersStore from "../../store/useCompanyUsersStore";
import useAuthStore from '../../store/authStore';

interface UserListProps {
    user: any;
    setList: any;
}
let UserList: React.FC<UserListProps> = ({ user, setList }) => {
    // console.log(user);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { companyUsers, setCompanyUsers } = useCompanyUsersStore();
    let { usage, setUsage } = useAuthStore();


    let fetchCompanyUsers = async () => {
        try {
          let res = await axios.get(`/user/companyuser`);
    
          if (res && res.data.result) {
            console.log("setting");
            setCompanyUsers(res.data.data);
    
            let companyList = res.data.data;
            companyList.sort(function compareFn(a: any, b: any) { if (`${a.firstName} ${a.lastName}` <= `${b.firstName} ${b.lastName}`) return -1; else return 1; });
    
            setList(companyList);
          }
        } catch {
          toast("Could Not fetch Company Users. Please reload the page.", { type: toast.TYPE.ERROR });
        }
    };


    const deleteHandler = async () => {
        try {
            setLoading(true);

            let serverRes = await axios.post("/user/deleteuser",
                {
                    email: user.email
                }
            )

            // console.log(serverRes);

            if (serverRes.data.result) {
                setShowModal(false)

                // Updating state
                let temp = usage
                if (temp) {
                    temp.details.noOfUsers = temp.details.noOfUsers - 1;
                    setUsage(temp);
                }

                toast("User deleted successfully.", {
                    type: "success",
                });

                fetchCompanyUsers();

                setLoading(false);


                // setList((prevState: any) => {
                //     console.log("Email", user.email);
                //     const mylist = [...prevState];
                //     console.log("Before", mylist);

                //     mylist.forEach((element: any, index: number) => {
                //         if (element.email === user.email) {
                //             console.log("Matched");
                //             mylist.splice(index, 1); // Removes the index element
                //         }
                //     })
                //     console.log("After", mylist);

                //     return mylist;
                // })

            }
            else {
                setShowModal(false)

                toast(serverRes.data?.message || "Some error occured.", {
                    type: "info",
                });

                setLoading(false);
            }
        }
        catch (err: any) {
            toast("Some error occured.", {
                type: "warning",
            });

            setLoading(false);
        }

    }
    return (<div>
        {showModal &&
            <>
                <button className="fixed z-[100] inset-0 w-full bg-black opacity-10 h-full cursor-default"></button>
                <div className=" fixed top-[15vw]  z-[101]  left-0 w-screen flex justify-center items-center"
                >
                    <div className="relative bg-white flex flex-col justify-center h-[12vw] w-[45vw] rounded px-[1vw] py-[1.5vw] "
                        style={{ borderLeft: "5px solid #11256D " }}>
                        <div
                            className="w-full  flex justify-start items-center"
                        >
                            <div className="mx-[1vw]" ><img src="/images/info.svg" className="w-[4vw]" /></div>
                            <div className="text-[1vw]">
                                <div>
                                    Are you sure to delete the user <b>{user.firstName + " " + user.lastName}</b>?
                                </div>
                                <div>
                                    Email Id: <b>{(user.email).toLowerCase()}</b>
                                </div>
                            </div>
                        </div>
                        <div className="flex absolute bottom-[1vw] right-[1vw] mt-[1.5vw] space-x-[1vw] justify-end" >
                        {/* <button className='text-[1vw] w-[18vw] h-[3.5vw]  border-1  duration-500 disabled:bg-primaryBlue uppercase bg-primaryBlue text-white rounded  shadow-lg  hover:shadow-md hover:scale-105'
                            disabled={loading}
                            onClick={() => handleSubmit()}
                            >
                            {
                                loading ?
                                <img src="/images/loading_white.svg" alt="loading" className='w-[2vw] mx-auto ' /> : 
                                "Create Project"
                            }
                            </button> */}
                            <button
                                className={`flex items-center justify-center py-[0.5vw] w-[10vw] h-[2.7vw] text-primarycolor text-[1.1vw] rounded-[0.3vw] hover:text-white hover:bg-primarycolor duration-300 
                                ${loading ? 'bg-primarycolor' : 'bg-white'}`
                            }
                                style={{ border: "0.15vw solid #11256D" }}
                                type={"submit"}
                                disabled={loading}
                                onClick={() => {
                                    deleteHandler();
                                }} >
                                {
                                    loading ?
                                    <img src="/images/loading_white.svg" alt="loading" className='w-[2vw] mx-auto ' /> : 
                                    "Proceed"
                                }
                            </button>
                            <button
                                className={`flex items-center justify-center py-[0.5vw] w-[10vw] h-[2.7vw] text-[#E63946] text-[1.1vw] rounded-[0.3vw] hover:text-white hover:bg-[#E63946] duration-300`}
                                style={{ border: "0.15vw solid #E63946" }}
                                type={"submit"}
                                disabled={loading}
                                onClick={() => {
                                    setShowModal(false)
                                }} >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </>
        }

        <div
            style={{
                boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
            }}
            className="bg-white flex flex-col my-[1vw] p-[1.5vw]"

        >
            <div className="flex my-[1vw] space-x-[5vw]">
                <div>
                    <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                        First Name
                    </p>
                    <input
                        className="text-[1vw] px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                        type="text"
                        name="firstName"
                        style={{
                            width: "22vw",
                            border: "0.1vw solid #C4C4C4"
                        }}
                        value={user.firstName}
                        disabled
                    />
                </div>
                <div>
                    <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                        Last Name
                    </p>
                    <input
                        type="text"
                        name="lastName"
                        className="text-[1vw] px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"

                        style={{
                            width: "22vw",
                            border: "0.1vw solid #C4C4C4"
                        }}
                        value={user.lastName}
                        disabled
                    />
                </div>
                <div>
                    <p className="mb-[0.5vw] text-[1vw]" style={{ fontWeight: 500 }}>
                        Email Address
                    </p>
                    <input
                        name="email"
                        className="text-[1vw]  px-[0.8vw] py-[0.6vw] bg-gray-100 rounded-[0.35vw] outline-none"
                        type="text"
                        style={{
                            width: "22vw",
                            border: "0.1vw solid #C4C4C4"
                        }}
                        value={user.email}
                        disabled
                    ></input>
                </div>

            </div>
            <div className="mt-[1.5vw] flex w-full justify-end items-center">
                <button
                    className={`py-[0.5vw] w-[10vw] text-white text-[1vw] text-center rounded-[0.3vw] cursor-pointer bg-[#11256D] duration-300 hover:scale-105`}
                    style={{ background: "#E63946" }}
                    type={"submit"}
                    onClick={() => { setShowModal(true) }}
                >
                    Delete User
                </button>
            </div>

        </div>
    </div>
    );
};

export default UserList;