import React, { ReactNode, useEffect, useState } from "react";
import { NerEntity } from "../../store/interface";
import useNerStore from "../../store/useNerStore";

interface NerParaProps {
  paraData: string;
  expanded: boolean;
  paraId: string;
  minlength: number;
  
}

let NerPara: React.SFC<NerParaProps> = ({ paraData, paraId, expanded,minlength }) => {
  //
  //let [letters, setLetters] = useState<Array<ReactNode>>([]);
  let [letters, setLetters] = useState<string>("");
  let nerStore = useNerStore();

  useEffect(() => {
    // let text = expanded ? paraData : paraData.slice(0, 250);
    // let words = nerStore.words.filter((wrd:any) => wrd.paraId === paraId);

    // let letterComps: Array<ReactNode> = [];
    // let currentEnt: NerEntity | null = null;
    // let tempKeyHold: Array<ReactNode> = [];

    // for (let i = 0; i < text.length; i++) {
    //   let srch = words.find(
    //     (wrd:any) => wrd.startIndex === i || wrd.endIndex === i
    //   );
    //   if (srch && nerStore.enabled) {
    //     if (currentEnt !== null) {
    //       currentEnt = null;
    //     } else {
    //       let entity = nerStore.entities.find(
    //         (ent:any) => ent.entity === srch?.entity.entity
    //       );
    //       if (entity) {
    //         currentEnt = entity;
    //       } else {
    //         console.log("The entity does not exists. Please check your code");
    //       }
    //     }
    //   }

    //   if (currentEnt) {
    //     tempKeyHold.push(
    //       <span
    //         style={{
    //           color: currentEnt.color,
    //         }}
    //       >
    //         {text[i]}
    //       </span>
    //     );
    //   } else {
    //     if (tempKeyHold.length > 0) {
    //       letterComps.push(
    //         <span className="hover:bg-gray-100 cursor-pointer rounded-md noselect">
    //           {tempKeyHold}
    //         </span>
    //       );
    //       tempKeyHold = [];
    //     }

    //     letterComps.push(<span>{text[i]}</span>);
    //   }
    // }
    let letterComp =expanded? paraData:paraData.slice(0,minlength);
    setLetters(letterComp);
  //}, [expanded, nerStore.words, nerStore.enabled, paraData]);
}, [expanded, paraData]);

  return <div key={paraId} className={`text-[1vw] break-words text-left  w-full   ${expanded?"":" select-none"} `}>
    {/* {letters.map((letter:ReactNode,i)=><span key={i} className="">{letter}</span>)} */}
    <p>{letters} {paraData.length>=minlength && !expanded && <span>...</span>}</p>
    
    </div>;
};

export default NerPara;
