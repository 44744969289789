import create from "zustand";
import { AuthStoreType } from "../types/store/AuthStoreType";

let useAuthStore = create<AuthStoreType>(
    //persist(
        (set, _get) => ({
            isAuthenticated: false,
            isProcessComplete: false,
            token: undefined,
            userDetails: undefined,
            setIsAuthenticated: (auth: boolean) => set({ isAuthenticated: auth }),
            setIsProcessComplete: (done: boolean) => set({ isProcessComplete: done }),
            setToken: (token: string) => set({ token }),
            setUserDetails: (userDetails) => set({ userDetails }),
            billingDetails: undefined,
            setBillingDetails: (billingDetails) => set({ billingDetails }),
            activePlan: undefined,
            setActivePlan: (activePlan) => set({ activePlan }),
            usage: undefined,
            setUsage: (usage) => set({ usage })
        }),
//         {
//             name: "auth-store",
//         }
//     )
 );

export default useAuthStore;
