import React from 'react'

//icons
import {ReactComponent as LoadingWhite} from '../../../assets/loading_blue.svg';

type Props = {
  styles?:string
}

function Loading({styles}: Props) {
  return (
    <div 
    data-testid="prompt-loading"
    className={` h-[600px] w-full flex items-center justify-center ${styles} `}>
        <LoadingWhite
        className='w-[7vw] h-[7vw]'
        />
    </div>
  )
}

export default Loading