import { Pagination, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import useKeyboard from '../../../hooks/useKeyboard';

import DownloadData from '../../DownloadValidations';
import DownloadUnvalidatedData from '../../DownloadUnvalidatedData';

import Axios from '../../../utils/Axios';
import useWorkflowStore from '../../../store/useWorkflowStore';

import { nodesAtom } from '../../../components/masterlist/atoms';
import { useRecoilValue } from 'recoil'
import Loader from '../../loader';
import { toast } from 'react-toastify';
import NERPara from '../validation/NERPara';
import { userInfo } from 'os';
import useAuthStore from '../../../store/authStore';
import TNERPara from './TNERPara';

interface ITPProps {
    hasStages: boolean;
}

const TrainingParaComp = ()=>{
    return <>
    </>
}





export default function NERTrainingParagraphs({ hasStages }: ITPProps) {


    const [page, setPage] = useState<number>(1);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const userId = useAuthStore(state=>state.userDetails)?.userId || "";
    const currentProject = useWorkflowStore(state => state.currentProject);
    const currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
    const currentBatch = currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchNumber || 0;
    const isTrain: boolean = currentWorkflow?.stages.find((s: any) => s.name === "train")?.active || false;
    const [paras, setParas] = useState<Array<any>>([]);
    const [totalParas, setTotalParas] = useState<number>(0);
    //const [batches, setBatches] = useState<Array<number | string>>([])



   






    const isDownKeyPressed = useKeyboard({ keyCode: "ArrowDown" });
    const isUpKeyPressed = useKeyboard({ keyCode: "ArrowUp" });
    const fetchData = async () => {
        setLoading(true);
        let res: any = await Axios.post("/ner/training-paras", {
            workflowId: currentWorkflow?.workflowId,
            page: page,
        });

        console.log("training paras", res.data.data.paras);

        const tparas:any[] =[]
         res.data.data.paras.forEach((p:any)=>{

            p.paraData = p.para_info[0].paraData;
            p.validation =p.validations[userId];
            p.saved= true;
            p.para_info = undefined;

            tparas.push(p);
        })

        setParas(tparas);
        setTotalParas(res.data.data.totalParas);



        setLoading(false);
    }

    //fetchData
    useEffect(() => {

        fetchData();
        setActiveIndex(0);


    }, [ page]);

    useEffect(() => {
        if (isDownKeyPressed) {
            if (activeIndex < paras.length - 1) {
                console.log("down", activeIndex);
                setActiveIndex(prev => prev + 1)
            };
        }

    }, [isDownKeyPressed])

    useEffect(() => {
        if (isUpKeyPressed) {
            if (activeIndex > 0) setActiveIndex(prev => prev - 1);

        }

    }, [isUpKeyPressed])


    return (
        <div>

            {/* Filters */}
           

            {/* Loading */}
            {loading && <div className='h-[20vh]'>
                <Loader />
            </div>}

            {/* Training Paragraphs */}

            <div  className='flex flex-col gap-[1.4vw]'>
                {
                    //!data?.length 
                    !loading && !paras.length && <div className='h-[25vw] w-full flex justify-center items-center text-[1.2vw]'>
                        No Paragraphs Found
                    </div>

                }
                {
                    //data.length
                    !loading && paras.length > 0 && paras.map((tpara: any, index) => {

                        return <TNERPara
                            activePara={activeIndex}
                            setActivePara={setActiveIndex}
                            para={tpara}
                            stage={1}
                            index={index}
                            setCount={()=>{}}
                            fileInfo={{}}
                        />
                        // return <TParagraph
                        //     key={tpara.paraId}
                        //     para={tpara}
                        //     index={index}
                        //     setActivePara={setActiveIndex}
                        //     activePara={activeIndex}

                        // />

                    })
                }




            </div>

            {/* Pagination */}
            {paras.length > 0
                && <div className='flex justify-end mt-[2vw] '>

                    <Pagination
                    className='text-[1vw]'
                        disabled={loading}
                        onChange={(e) => setPage(e)}
                        current={page}
                        total={totalParas}
                        pageSize={20}
                        showSizeChanger={false}
                        showQuickJumper
                    />
                </div>}
        </div>
    )
}
