import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {ReactComponent as CrossIcon} from '../../assets/gray_cross (2).svg';
import {ReactComponent as LoadingCircle} from '../../assets/loading_circle.svg';

import useWorkflowStore from '../../store/useWorkflowStore';
import { Progress } from 'antd';

import axios from '../../utils/Axios';
import Button from '../mlc-components/model-comparison/Button';
import { removeUploadedModel, uploadModel, uploadPredictionModel } from './_apiCalls';
import { toast } from 'react-toastify';


type Props = {}
export interface UloadedFile {
    extension: string;
    fileId: string;
    fileName: string;
    filePath: string;
    practiceAreaId: string;
    size: number;
    status: string;
    uploadedBy: string;
    _id: string;
}
const Card = ({header,content,modelUrl,uploadFn,name,fileType}:any)=>{

    const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
    let [uploadBtnActive, setUploadBtnActive] = useState(true);
    let [uploadPercent, setUploadPercent] = useState(0);
    let [showProgress, setShowProgress] = useState(false);
    const [loadingDelete,setLoadingDelete] = useState<boolean>(false);

    let inputRef = useRef<HTMLInputElement>(null);
    let sliceLength  = name === "model"
                                ?(currentWorkflow?.workflowId.length || 0)
                                :(currentWorkflow?.workflowId.length || 0) + 11

    const [] = useState<boolean>(false);
    const resetUploadStates = ()=>{
        setUploadPercent(0);
        setShowProgress(false);
        setUploadBtnActive(true);
        if(inputRef.current){
            inputRef.current.value="";
        }
    }
    
    const uploadFile = async (selectedFile: Array<File>) => {
        console.log(selectedFile);
        setUploadPercent(0);
        setUploadBtnActive(false);
        const workflowId = currentWorkflow?.workflowId;
        if(!workflowId){
            alert("workflowId is invalid")
            return;
        }
        
    
       

       
            let currentFile = selectedFile[0];
            try {
                   const result = await uploadFn(workflowId,currentFile,setUploadPercent);
                   if(!result){
                        toast.error("There was an error while uploading the file, please check the file format and try again");
                        resetUploadStates();
                        
                    }    

            } catch (err) {
                console.error(err);
            }
    };


   return  <div className='h-[28vw] p-[1vw]
                        bg-white drop-shadow-sm hover:drop-shadow-lg
                        flex flex-col gap-[1vw]
                        
                        '
                >
            <div>
                
            </div>
            <div className='font-semibold text-[1.1vw]'>
                {header}
            </div>

            <div>
                <p className='text-[1vw] text-black'>
                    {content}
                </p>
            </div>
            <div>
            <div className=" relative flex flex-col justify-center items-center   w-full h-[15vw]">
                                        <img
                                            className="z-50 w-[15vw]"
                                            style={{
                                                position: "absolute",
                                                transform: "translateX(-10%)",
                                                pointerEvents: "none",
                                                opacity: uploadBtnActive ? 1 : 0.5,
                                            }}
                                            src="/images/upload_button.svg"
                                            alt="upload"
                                        />

                                        <input
                                            id="upload"
                                            disabled={!uploadBtnActive}
                                            style={{ opacity: 0 }}
                                            className={"absolute z-50 top-0  left-0 border-dashed border-2 border-green-500 w-full h-full "}
                                            //accept={".pkl"}
                                            accept={fileType}
                                            type="file"
                                            ref={inputRef}
                                            placeholder={"Select file"}
                                            onChange={(e) => {
                                                //let's reset every state

                                                if (e.target.files?.length !== 0) {
                                                    setShowProgress(true);
                                                    //@ts-ignore
                                                    uploadFile(e.target.files);
                                                }
                                            }}
                                        />
                                        <div className="w-[21vw] mt-[12vw]">

                                            {showProgress && <Progress strokeColor={"#11256D"} percent={uploadPercent} style={{ width: '19.5vw' }} />}
                                        </div>
                                     
                                    </div>
                                    {modelUrl!=="" &&<div className='flex items-center justify-start
                                                                        gap-[0.6vw] 
                                                                        text-[1vw] text-black'>
                                            {!loadingDelete?<CrossIcon 
                                            className='w-[0.9vw] h-[0.9vw] cursor-pointer'
                                            onClick={async()=>{
                                                if(!currentWorkflow) return;
                                                setLoadingDelete(true);
                                                await removeUploadedModel(currentWorkflow?.workflowId,
                                                      name==="model"?"model":"prediction model"              
                                                    )
                                                    setLoadingDelete(false);
                                                    resetUploadStates();
                                                }
                                               
                                                }
                                            />:<LoadingCircle
                                            className='w-[0.9vw] h-[0.9vw] cursor-pointer'
                                            />}
                                            {modelUrl
                                            ?.split("/")
                                            ?.reverse()[0]
                                            ?.slice(sliceLength + 1)
                                            }
                                            </div>}
            </div>
       

    </div>


}


function LlmUploadModelComp({}: Props) {
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const navigate = useNavigate();

    const [modelUrl,setModelUrl] = useState<string>();
    const [predictionModelUrl,setPredictionModelUrl] = useState<string>();



    useEffect(()=>{
        setModelUrl(workflow?.alpdsInfo?.selectedModelInfo?.modelUrl);
        setPredictionModelUrl(workflow?.alpdsInfo?.selectedModelInfo?.predictionModelUrl)

    },[workflow?.alpdsInfo])

  return (
    <div className='p-[1vw]'>
    {/* <p className='w-full bg-red-300'>LLM Models</p> */}
    <div className='grid  grid-cols-1 md:grid-cols-2  gap-[3vw]
                    
                    '>
    <Card
    key={"upload model"}
    fileType={".pkl"}
    header={"Model Object*"}
    content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed odio lectus, vehicula nec urna sit amet, maximus placerat risus. Etiam dictum eu turpis vel facilisis."}
    name={"model"} 
    modelUrl={modelUrl}
    uploadFn={uploadModel}
    />
    <Card 
     key={"upload prediction model"}
     fileType={".csv,.zip,.txt,text/plain,text/csv,application/zip,.pdf,.xlsx"}
     header={"Prediction On Ingested Data"}
     content={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed odio lectus, vehicula nec urna sit amet, maximus placerat risus. Etiam dictum eu turpis vel facilisis."}
     name={"prediction model"}
     modelUrl={predictionModelUrl}
     uploadFn={uploadPredictionModel}
    
    />

    
    </div>
    <div className='flex items-center justify-end mt-[5vw]'>
        <Button 
        disabled={workflow?.alpdsInfo?.selectedModelInfo?.modelUrl === ""}
        text={"Training Results"}
        styles={"!w-[18vw]"}
        onClick={()=>navigate("/workflow/modeltraining-2/dep?workflowId="+workflow?.workflowId)}

        />
    </div>
</div>
  )
}

export default LlmUploadModelComp