import { Divider, Select } from "antd";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
//import { useDispatch, useSelector } from "react-redux";
import { useRecoilState, useRecoilValue } from "recoil";
import { GlobalState } from "../../../sidebarStore../../store/reducers";



import { nodesAtom } from "../../masterlist/atoms";

import { toast } from "react-toastify";
import NerPara from "../../ner-components/NerPara";

import useWorkflowStore from "../../../store/useWorkflowStore";
import Axios from '../../../utils/Axios';

import ValidationControls from "../controls/multiclass/ValidationControls";
import { getClassesInAlphabeticOrder, getNextPredictedIssueId } from "../../stage-2/helper";
import useKeyboard from "../../../hooks/useKeyboard";

export interface StageParaProps {
  batchId:number,
  node_name: string;
  time_str: string;
  para_data: string;
  paraId: string;
  accepted: null | string;
  active_node_id: string | undefined;
  stage: number;
  predictedIssueId: string;
  nextPredictedIssueId?: Array<any>,
  nerInfos: Array<any>;
  stageParas:Array<any>,
  setStageParas:any,
  readOnly:boolean | (()=>boolean),
  
  isActiveBatch:boolean;

  updateStageNodeProgress: any,
  setStageRejectedParas:any,
  stageNodes:Array<any>,
  index:number
  activePara:number;
  setActivePara:any;
  para:any,
  next:()=>void,
  prev:()=>void,
  keyboardEnabled?:boolean;
  setKeyboardEnabled?:any;
  
  
}

const StagePara: React.FC<StageParaProps> = (props) => {
  const {
    updateStageNodeProgress,
    active_node_id,
    node_name,
    para_data,
    paraId,
    accepted,
    stage,
    predictedIssueId,
    stageParas,
    setStageParas,
    readOnly,
    index,
    activePara,
    setActivePara,
    para,
    keyboardEnabled,
    setKeyboardEnabled,

    isActiveBatch

    
  } = props;
  //let dispatch = useDispatch();
  //let stage2 = useSelector((state: GlobalState) => state.stage2);
 


  const [fade, setFade] = useState(false);
  const [loading,setLoading] = useState(false);
  const isActive = index === activePara;
  let [selectionInfo, setSelectionInfo] = useState({
    selection: false,
    issueId: "",
    node_id: "",
  });




  const currentDiv = useRef<HTMLDivElement>(null);
  
  const canValidate:any =readOnly;
  //console.log("readOnly",readOnly);
  //Keyboard Shortcuts
  const divRef = useRef<HTMLDivElement>(null);
  const Enter = useKeyboard({keyCode:"Enter"})
  const BackSpace = useKeyboard({keyCode:"Tab"})
  useEffect(()=>{

    const wait = async()=>{
      await waitFor(100);
      
      divRef.current?.scrollIntoView({block:"nearest"});
      if(activePara === 0)
      {
        window.scrollTo({top:0,behavior:"smooth"});
      
      }
    };
    if(isActive)
    {
      wait();
     
    
      
    }
   
  },[isActive]);

  useEffect(()=>{
    if(!keyboardEnabled) return;
    const accepteParaAndMove =async()=>{
      if(Enter && isActive)
      {
        
        if((!accepted || accepted!=="accepted") && !loading && !readOnly) {
          //next();
          await acceptPara();
          
        }
        
       
      }

    }
    accepteParaAndMove();



  },[Enter,isActive])

  useEffect(()=>{

    const rejectParaAndMove = async()=>{
      if(!keyboardEnabled) return;
      if(!BackSpace) return;
      if(!isActive) return;
     
        if((!accepted || accepted!=="rejected") && !loading && !readOnly ){
          await rejectPara();
          //next(); // keep user on same para on rejection
        } 
      

    }
    rejectParaAndMove();
    
    
  },[isActive,BackSpace])
  //Keyboard Shortcuts


  let waitFor = (duration:number)=>{
    return new Promise(resolve=>setTimeout(resolve,duration));
  }



  let nodes = useRecoilValue(nodesAtom);

  const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
  //sorted on their best match with paragraphs
  //const nextClasses:any[] = useMemo(()=>getNextPredictedIssueId(para,currentWorkflow?.workflowId || ""),[para.paraId,currentWorkflow?.workflowId])
  
  //sorted in alphabetic order
  const nextClasses:any[] =useMemo(()=>getClassesInAlphabeticOrder(nodes),[nodes] ) ;
  const [isRejected,setIsRejected] = useState<boolean>(false);
  const [isMounted,setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
    

  }, [])

  useEffect(() => {
   
     
     let paras= stageParas.find((node:any)=>node.node_id === active_node_id)?.data;
     let rejInfo = paras.find((para:any)=>para.para_info.paraId === paraId)
     //console.log("=== rejInfo ===",rejInfo);

    setSelectionInfo({
      selection: rejInfo?.nextPredicted?.selected,
      issueId: rejInfo?.nextPredicted?.issueId,
      node_id: rejInfo?.nextPredicted?.node_id,
    });
  }, [stageParas]);
  
  

  let dropDownOptions = useMemo(() =>
    {
      if(currentWorkflow?.useNoTag)
      {
        //console.log("=== nextPredicted Issue Id ===",nextPredictedIssueId)
        return [{ value: "No Tag", text: "No Tag", id: "No Tag" }].concat(
         nextClasses.map((nd:any)=>{
          let node:any = nodes.find((node)=>node.node_id === nd[0]);
          let value= node.node_id
          let text=node.node_name
          let id= node.node_id
          return {value,text,id};

         })
        )
      }
      else{
        return  nextClasses.map((nd:any)=>{
          let node:any = nodes.find((node)=>node.node_id === nd[0]);
          let value= node.node_id
          let text=node.node_name
          let id= node.node_id
          return {value,text,id};

         })
    

      }


    },[nodes,nextClasses]
  );


  let rejectPara =async () => {
    //TODO if accepted first then rejected then update the completed percentage
   // console.log("==Reject Para Was Called")
    try {
   
    
     
      let isAccepted = false;
      let completed = 0;
      let feedback = 0;

      
      for (let i = 0; i < stageParas?.length; i++) {
        if (stageParas[i]?.node_id === active_node_id) {
          for (let j = 0; j < stageParas[i]?.data?.length; j++) {
            if (
              stageParas[i]?.data[j].para_info.paraId === paraId &&
              stageParas[i]?.data[j].accepted === "accepted"
            ) {
             
              isAccepted = true;
              break;
            }
          }
          break;
        }
      }
     
      //updating counts

      if(isActiveBatch)
      {

        if(isAccepted)
        {
  
          console.log("--- updating count ---");
  
          updateStageNodeProgress((prev:any)=>{
            let temp = {...prev,[active_node_id || ""]:{
              validatedParas:prev[active_node_id || ""]?.validatedParas-1
            }};
            console.log("after update",temp);
            
            return temp;
  
          });
        }
      }
      


        //console.log("--- Before Para Rejected ---",stageParas)
        setStageParas((paras:any)=>{
         let data= paras.map((para:any)=>{
            if(para.node_id === active_node_id)
            {
              let temp = para.data.map((p:any)=>{

                //return p.para_info.paraId !== paraId
                if(p.para_info.paraId===paraId) 
                {
                  p.accepted= "rejected"
                  p.nextPredicted={}
                  p.nextPredicted.selected = false;
                  p.nextPredicted.issueId = "";
                }
                return p
                
              })
              
              
              para.data= temp;
              //console.log("--- After Para Rejected ---",para);
            }
            return para

          })
          return data;
        });
       



     
      
          await Axios.post("/annotations/para-action",{
          workflowId:currentWorkflow?.workflowId,
          paraId,
          action:"rejected",
          predictedIssueId:null,
          stage:2
        });
        
     // }
    } catch (err) {
      //console.log("Error while rejecting the para",err);
      
      toast("There was some error while registering your validation. Please reload the page and try again.", {
        type: "error",
      });
      
    }
  };

  let acceptPara = async() => {
    //if (accept === true) return;

    if (active_node_id !== undefined) {
      try {
        let params = {
          paraId: paraId,
          action: "accepted",
          stage: 2,
          predictedIssueId: predictedIssueId,
          workflowId:currentWorkflow?.workflowId
        };
       
        
        console.log("--- updating count ---");
        

        if(isActiveBatch)
        {

          updateStageNodeProgress((prev:any)=>{
            let temp = {...prev,[active_node_id]:{
              validatedParas:(prev[active_node_id]?.validatedParas || 0) + 1
            }};
  
            if(selectionInfo.selection && selectionInfo.issueId)
            {
              temp[selectionInfo.issueId].validatedParas=temp[selectionInfo.issueId]?.validatedParas || 0-1;
            }
            
            return temp;
  
          });
        }


        setStageParas((prev:any)=>{

          let paras =prev.map((para:any)=>{
            if(para.node_id === active_node_id)
            {
             let data = para.data.map((p:any)=>{
                    if(p.para_info.paraId === paraId)
                    {
                      p.accepted= "accepted";
                    }
                    return p;
              });
              para.data = data;

            }
            return para;
          })
         // console.log("=== After Accepting para ===",paras);
          return paras

        })
       

     
        await Axios.post("/annotations/para-action",params);
        
      } catch (err) {
      

        toast("There was some error while registering your validation. Please reload the page and try again.", {
          type: toast.TYPE.ERROR,
        });
      }
    }
  };


  let handleTagSelection =async (node_id: string) => {
    // updating the counting
    
    
    
    if (node_id) {
      setLoading(true);
      //update the state
      
       let isIssueId:string | null=null;
      setStageParas((paras:any)=>{
        let data= paras.map((para:any)=>{
           if(para.node_id === active_node_id)
           {
             let temp = para.data.map((p:any)=>{
  
               //return p.para_info.paraId !== paraId
               if(p.para_info.paraId===paraId) 
               {
               
                if(p.nextPredicted.slected)
                {
                  isIssueId=p.nextPredicted.issueId;
                }
                p.nextPredicted.selected = true;
                p.nextPredicted.issueId = node_id;
               }
               return p
               
             })
             
             
             para.data= temp;
           }
           return para
  
         })
         return data;
       });

       
       //Updating counts
       if(isActiveBatch)
       {

         updateStageNodeProgress((prev:any)=>{
          
           let temp:any= {...prev,[node_id]:{
             validatedParas:prev[node_id]?.validatedParas || 0+1
           }
         };
         
         if(selectionInfo.selection && selectionInfo.issueId)
         {
           temp={...temp,[selectionInfo.issueId]:{
             validatedParas:temp[selectionInfo.issueId]?.validatedParas || 0 -1
           }};
         }
           return temp;
 
         });
       }

       
      //call the api
      const reject_res = await Axios.post("/annotations/para-action",{
        paraId,
        action:"rejected",
        stage,
        workflowId:currentWorkflow?.workflowId,
        predictedIssueId:node_id
  
      });
      setLoading(false);
     
      

     
     


      
    }
   

   

 
  };



  let handleWrdClick = async (wrd: string) => {
    if (wrd === "") {
      toast("No word selected", { type: "info" });
      return;
    }

    let newParas = stageParas.map((paraInfo) => {
      let data = paraInfo.data.map((data: any) => {
        if (!data.alpdsInfo[0].nerInfo) return data;

        let newNerInfo = data.alpdsInfo[0].nerInfo.filter(
          (info: any) =>
            info.word.toLocaleLowerCase() !== wrd.toLocaleLowerCase()
        );

        return {
          ...data,
          alpdsInfo: [
            {
              ...data.alpdsInfo[0],
              nerInfo: newNerInfo,
            },
          ],
        };
      });
      return {
        ...paraInfo,
        data,
      };
    });

    // dispatch({
    //   type: nerStore.stage === 1 ? STAGE1_PRE_PARAS : STAGE2_PRE_PARAS,
    //   payload: newParas,
    // });
  };

  return (
    <div
      ref={divRef}
      onClick={()=>setActivePara(index)}
      key={paraId}
      className={`w-full flex my-5 duration-200 bg-white border min-h-[8vw] 
      scroll-mt-[5vw] scroll-mb-[4vw]
      ${  fade ? "opacity-0" : ""}
      ${isMounted?"":"opacity-0"}
      ${isActive?"border-[#11256d] shadow-lg":"border-transparent"}
      `}
    >
      <div
        className={" bg-white w-[70%] rounded-[0.3vw] py-[1vw] px-[1.5vw] "}
      >
     
        <div  key={paraId} className="flex   ">
          <NerPara
           // nerInfo={nerInfos}
            minlength={270}
            paraData={para_data}
            paraId={paraId}
            expanded={isActive}
          />
        </div>
      </div>
      <div className="border my-[1vw] border-dashed border-l-1"></div>
    <div className="w-[30%]">

      <ValidationControls
        accepted={accepted}
        rejectPara={rejectPara}
        acceptPara={acceptPara}
        handleTagSelection={handleTagSelection}

        readOnly={canValidate}

        loading={loading}
        dropdownOptions={dropDownOptions}
        selectionInfo={selectionInfo}
        node_name={node_name}
        keyboardEnabled={keyboardEnabled || false}
        setKeyboardEnabled={setKeyboardEnabled}
         />
    </div>
    
    </div>
  );
};

export default StagePara;
