import React from 'react'

function Para({active,text}:any) {
  const limit = 500;
  return (
    <p
    className='text-[0.92vw] text-left whitespace-pre-wrap'
    >{!active?text?.slice(0,limit):text} {!active && text.length>limit?"...":""}</p>
  )
}

export default Para