export const getMessage = (key: string, text:string ,type:string,isShort=false) => {


    
    if ( text && text!=="") {
        if (isShort) {
            text = text.split(",")[0] + "..."
        }
        return text;
    }

    const upload_message: string = process.env.React_APP_DataIngestion_Message as string;
    const masterlist_message: string = process.env.React_APP_MasterlistAnalysis_Message as string;
    const stage1_message: string = process.env.React_APP_Stage1_Message as string;
    const stage2_message: string = process.env.React_APP_Stage2_Message as string;
    const automl_message: string = process.env.React_APP_AutoML_Message as string;
    const export_message: string = process.env.React_APP_Export_Message as string;
    const prediction_message: string = process.env.React_APP_Prediction_Message as string;

    let message: string = ""
    switch (key) {
        case "DataIngestion_Message":
            message = upload_message;
            break;

        case "MasterlistAnalysis_Message":
            message = masterlist_message;
            break;
        case "Stage1_Message":
            message = stage1_message;
            break;

        case "Stage2_Message":
            message = stage2_message;
            break;

        case "AutoML_Message":
            message = automl_message;
            break;

        case "Prediction_Message":
            message = prediction_message;
            break;

        case "Export_Message":
            message = export_message;
            break;
        
        default:
            message="There was some unknown error in the system"
    }
    let final_message = message;
    if(isShort)
    {
        final_message = message.split(",")[0] + "..."
    }
    return final_message;


}