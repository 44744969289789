
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';


interface IExpandedEntitySummary{
    isOpen:boolean,
    setIsOpen:(value:boolean)=>void,
    pieChartOptions:any,
    loading:boolean,
    width:number

}




function ExpandedEntitySummary({
    isOpen,
    setIsOpen,
    pieChartOptions,
    width
  
}:IExpandedEntitySummary) {

    const [series,setSeries] = useState<any>({});
    const [options,setOptions] = useState<any>({});

 

    useEffect(()=>{
        setSeries(pieChartOptions.series);
        const temp:any = {...pieChartOptions}
        temp.plotOptions.bar.columnWidth="30%"
        console.log("== options ==",temp);
        setOptions(temp);
    },[pieChartOptions])


      //console.log("=== series data ===",pieChartOptions)


  return (
    <>
       <div className="self-start text-[#1BA94C] font-bold text-base p-[1vw]">Classwise Validation Statistics</div>
      <div className="flex items-center justify-center p-[2vw]">

        <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width={width}
        height={500} />
      </div>
    </>
   
  )
}

export default ExpandedEntitySummary