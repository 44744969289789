import { Popover, Tooltip, Badge } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { useNavigate } from 'react-router-dom';
import Notifications from "./NotificationsComp";
import useNotifications from "../../store/useNotifications";


//Import All Icons Here
import { ReactComponent as QuestionMark } from '../../assets/question_mark.svg';
import { ReactComponent as Avatar } from '../../assets/avatar.svg';
import { ReactComponent as Bell } from '../../assets/notification_bell.svg';
import { ReactComponent as ToggleBell } from '../../assets/toggle_notifications.svg';
import { ReactComponent as EmptyFolder } from '../../assets/empty_folder.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { IoMdNotificationsOutline } from "react-icons/io";
import { VscSignOut } from "react-icons/vsc";
import {RiSettings2Line} from 'react-icons/ri';
import Axios from '../../utils/Axios';





let AvatarPopout = () => {
    //let history = useHistory();
    const navigate = useNavigate();
    const authStore = useAuthStore();
   
    let signOutUser = async() => {
        localStorage.clear();
        authStore.setIsAuthenticated(false);
        // authStore.setIsProcessComplete(false);
        authStore.setToken("");
        authStore.setUserDetails(undefined);
        authStore.setBillingDetails(undefined);
        authStore.setActivePlan(undefined);
        authStore.setUsage(undefined);
                    
        Axios.post("/user/signOut");
    
        navigate("/signin");
    };
    /**
     *
     *
     * Will take the user to the admin page
     */
    let accountClick = () => {
        // if(window.location.pathname=='/admin') return;
        navigate("/admin");
    };

    return (
        <div>
            <div
                className="flex justify-start items-center px-[0.7vw] py-[0.5vw] space-x-[1vw] cursor-pointer hover:bg-gray-200"
                onClick={accountClick}
            >
                <RiSettings2Line size={"1.2vw"} />
                <span className="text-[1vw]">Account Summary</span>
            </div>
            <div
                onClick={signOutUser}
                className="flex justify-start items-center px-[0.7vw] py-[0.5vw] space-x-[1vw] cursor-pointer hover:bg-gray-200"

            >
                <VscSignOut size={"1.2vw"} />
                <span className="text-[1vw]">Log out</span>
            </div>
            {/* <div className="flex justify-between px-3 py-2 space-x-3 cursor-pointer hover:bg-gray-200">
                  <RiSettings2Line size={26} />
                  <span>Account Settings</span>
              </div> */}
        </div>
    );
};


let Topbar: React.FC = () => {
    let { userDetails } = useAuthStore();

    const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    // const [isSeen,setIsSeen] = useState<boolean>(false);
    let setIsSeen = useNotifications(state => state.setIsSeen);
    let isSeen = useNotifications(state => state.isSeen);;

    return (
        <div className="w-full bg-white z-[98] fixed b h-[5vw] top-0 left-0 right-0 flex justify-between bg-white items-center ">


            {/* DataNeuron logo  */}
            <Link to={"/"}>
                <div className="px-[1.5vw] py-[0.5vw]">
                    <div className={"flex items-center  h-full"}>
                        {/* <img src="/images/gray_logo.svg" className="w-[13vw] " alt="logo" /> */}
                        {/* <span style={{fontFamily:"Barlow"}} className="font-bold text-[#777777] text-[1.7vw]">DataNeuron</span> */}
                        <Logo width={"10vw"} height={"2vw"} />
                    </div>
                </div>
            </Link>



            <div className={"flex space-x-6 mr-[2vw] items-center"}>

                <div>

                    <Tooltip
                        placement="bottom"
                        title="Notifications" color="#777777"

                        visible={isVisible}>

                        <div
                            onMouseEnter={() => !isNotificationsOpen ? setIsVisible(true) : setIsVisible(false)}
                            onMouseLeave={() => setIsVisible(false)}
                            className="relative flex items-center justify-center">

                            {!isSeen ?
                                <ToggleBell width={"1.8vw"} height="1.7vw"
                                    className="cursor-pointer z-[91]"
                                    onClick={() => {
                                        //setIsSeen(true);
                                        setIsVisible(false);
                                        setIsNotificationsOpen((prev) => !prev)
                                    }}
                                />:<Bell width={"1.8vw"} height="1.8vw"
                                className="cursor-pointer z-[91]"
                                onClick={() => {
                                   // setIsSeen(true);
                                    setIsVisible(false);
                                    setIsNotificationsOpen((prev) => !prev)
                                }}
                            />

                        }


                            {/* {!isSeen && <div className=" absolute p-[0.35vw] bg-red-500 -top-1 right-0 rounded-full z-[95]">

                        </div>} */}

                            <div className="absolute top-[2.4vw] z-[91] ">
                                <Notifications isOpen={isNotificationsOpen} setIsOpen={setIsNotificationsOpen} />

                            </div>
                        </div>
                        {isNotificationsOpen && <div className="absolute opacity-0 top-0  left-0 bg-[#777777] h-[100vh] w-[100vw] z-[90] "
                            onClick={() => setIsNotificationsOpen(false)}
                        >
                        </div>}
                    </Tooltip>
                </div>



                {/* Help and guidelines  */}
                <Link to={"/help_and_guideline"}>
                    <Tooltip title="Help and Guidelines" placement="bottom" color="#7a7a7a">

                        <div>
                            {/* <img src="/images/help_black.svg" className="w-[1.4vw] " alt="help and guidelines" /> */}
                            <QuestionMark width={"2vw"} height="2vw" />
                        </div>
                    </Tooltip>
                </Link>

                <Popover placement={"bottom"} content={<div><AvatarPopout /></div>}>
                    <div className="flex items-center justify-center space-x-3 cursor-pointer">
                        <div className={" text-gray-500 ml-3 text-[1vw]"}>
                            <p>{userDetails?.email}</p>
                            <p className="text-right">{userDetails?.company}</p>

                        </div>
                        {/* <img src="/images/user.png" className="w-[2vw] " alt="avatar temp" /> */}
                        <Avatar width={"1.7vw"} height={"1.7vw"} />
                    </div>
                </Popover>

            </div>
        </div>
    );
};

export default Topbar;
