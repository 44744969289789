import React, { useState } from 'react';
import {ReactComponent as Cross} from '../../../assets/cross.svg';

import {useRecoilValue} from 'recoil';
import {nodesAtom} from '../../masterlist/atoms'
import { Divider, Popover } from 'antd';

interface ITag {
    text: string,
    readOnly:boolean
    active:boolean
    handleRemoveTag: (node_name: string) => void
}

interface IParaTag{
    data:any[],
    active:boolean,
    handleRemoveTag:any;
    readOnly?:boolean
}


const classCharacterLimit:number =14;

const Tag: React.FC<ITag> = ({active,text,handleRemoveTag,readOnly }) => {
    const [open,setOpen ] = useState<boolean>(false);
    



    const handleOpenChange =(e:boolean)=>{
        if( text.length>classCharacterLimit)
        setOpen(e);
    }

    return (
    <Popover
    open={open}
    onOpenChange={handleOpenChange}
    content={<div className="text-[1vw]">{text}</div>}
    
    >

    <div className=' flex hover:shadow-md duration-200  items-center gap-1 bg-[#EAEAEA]  rounded-full text-[0.9vw]  px-[1vw] py-[0.5vw]'>
        <p className='text-[#11256D] text-start select-none'>
        {text?.length>classCharacterLimit?text.slice(0,classCharacterLimit)+"...":text}
        </p>
       {!readOnly && active && <Cross className="cursor-pointer w-[0.9vw] h-[0.9vw] hover:scale-125 duration-200"
         stroke='#11256D'
            onClick={()=>active && handleRemoveTag(text)}
         />}
        </div>
    </Popover>
        )

}



function MLCParaTags({active,data,handleRemoveTag,readOnly}:IParaTag) {

    const nodes:any[] = useRecoilValue(nodesAtom);
    const currentTags:string[] = active?
                                        data
                                        :data.length>3?data.slice(0,2):data.slice(0,3);
    
    const getClassName = (name:string)=>{

        if(name === "No Tag") return "No Tag";

        if(name === null) return "No Associated Class";



        return nodes.find((n:any)=>n.node_id === name)?.node_name;
    }




    const removeTag=(name:string)=>{
        handleRemoveTag(name);
    }

    //const sortedList:string[] = data.sort((a:string,b:string)=>getClassName(a)<getClassName(b))


    return (
        <div className="h-full w-full flex flex-col">
            <div className='font-medium  p-[0.2vw] w-full  text-[0.85vw] text-start '>
           Classes
            </div>
            
            
            
            <div className={`flex  gap-[1vw] flex-wrap
                                items-start 
                                ${active?"custom_scroll":"" } 
                                px-[0.2vw] py-[0.8vw]`}>
                {
                  data?.length?    currentTags.map((tag: any) => {
                        return <Tag
                                key={tag}
                                text={getClassName(tag)}
                                handleRemoveTag={()=>removeTag(tag)}
                                active={active}
                                readOnly={readOnly || false}
                                />
                        
                    }
                   
                    ):<div className='text-center text-[0.9vw] w-full flex items-center justify-center'>
                        No Associated Class</div>
                }
            {!active && data.length>3?
             <Tag text={`&${data.length-2} more`} handleRemoveTag={()=>{}} active={active} readOnly={true} />
            :null
            }
            </div>
        </div>

    )
}

export default MLCParaTags