import create from 'zustand';

interface companyUsersStoreType {
    companyUsers: Array<any>,
    setCompanyUsers: (companyUsers: Array<any>) => void
}

let useCompanyUsersStore = create<companyUsersStoreType>(
    (set) => ({
        companyUsers: [],
        setCompanyUsers: (companyUsers: Array<any>) => set({companyUsers})
    }),
);

export default useCompanyUsersStore;