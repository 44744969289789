import { Slider } from 'antd'
import { parse } from 'path'
import React, { useEffect, useState } from 'react'


interface IParameter {
      paramName: string,
      paramStartValue: number,
      paramEndValue: number,
      paramType: "Numerical" | "Categorical"
}

interface IRangeProps {
    parameter:IParameter,
    onChange:any,
    min:number,
    max:number,
    error?:string,
    isFloat?:boolean
    addError?:any,
    removeError?:any

}

const InputBox = ({trailText,
                    setValue,value,
                min,max,isFloat,onBlur}:any)=>{
    return <div className='bg-white w-[9.7vw] h-[2.7vw] 
                             rounded-[0.25vw] 
                             flex items-center justify-center
                             
                             '>
       
   <input 
   className='w-full h-full focus:outline-none rounded-[0.25vw]  text-[1vw] 
                border-2  pl-[1vw] pr-[2vw] peer
                invalid:border-red-200
                '
   onBlur={onBlur}
   value={value}
   type="number"
   onChange={(e)=>{
    console.log("before onchange",e.target.value,typeof e.target.value)
    setValue(e.target.value)
}}
    step={isFloat?0.01:1}
    
   required
   min={min}
   max={max}
   />
 <label className=' select-none -ml-[2vw] text-[#C4C4C4] peer-invalid:text-red-400 text-[0.85vw]'>
    {trailText}
    </label>
    </div>
}


export default function RangeInput({
    parameter,
    onChange,
    error,
    addError,
    min,
    max,
    isFloat}:IRangeProps) {

    const [minMax,setMinMax] = useState<string[]>([
        parameter.paramStartValue +"",
        parameter.paramEndValue + ""

    ]);

    useEffect(()=>{

        if((parameter.paramStartValue +"")!== minMax[0]
        || (parameter.paramEndValue +"")!== minMax[1]
        ){

            setMinMax([ parameter.paramStartValue+"",
                parameter.paramEndValue+""])
        }
    },[ parameter]);

    const checkInputs = (minVal:number,maxVal:number,paramName:string)=>{
        console.log("=== checking for error ===");
        //removeError(paramName);
        if(Number.isNaN(minVal) || Number.isNaN(maxVal)){
            addError("All fields are required",paramName);
            return;

        }
        if(minVal>=maxVal){
            addError("Min must be less than Max",paramName);
            return;
        }
        if(minVal<min || minVal>=max){
            addError(`Min value should between ${min} and ${max}`,paramName );
            return;

        }
        if(maxVal<min || maxVal>max){
            addError(`Max value should between ${min} and ${max}`,paramName );
            return;

        }

        addError(null,paramName)
       

    }
   
    
    const handleInputChange = ()=>{

        let minValue=0;
        let maxValue =0;
        
        
        if(isFloat){
           
            minValue = parseFloat(parseFloat(minMax[0]+"").toFixed(2));
            maxValue = parseFloat(parseFloat(minMax[1]+"").toFixed(2));
           
        }else{
            minValue = parseInt(minMax[0]+"");
            maxValue = parseInt(minMax[1]+"");
        }
        const temp:any[] = [minValue,maxValue];
        console.log("=== values ===",temp)
        setMinMax(temp)
        onChange({
            ...parameter,
            paramStartValue:temp[0],
            paramEndValue:temp[1]
            
        })
        
    }

    useEffect(()=>{
        console.log("=== calling add error ===");
        
       addError &&  checkInputs(+minMax[0],+minMax[1],parameter.paramName);
    },[minMax[0],minMax[1]]);

  return (
    <div className=''>
        <p className='uppercase text-[0.8vw] font-medium mb-[1vw] '>{parameter.paramName}</p>
            
            <div className='flex items-center justify-start  w-full'>
            <InputBox
            trailText="Min"
            value={minMax[0]}
            setValue={(value:string)=>setMinMax(prev=>[value,prev[1]])}
            min={min}
            max={max}
            isFloat={isFloat}
            onBlur={()=>handleInputChange()}

            />
            <div className='bg-[#C4C4C4] ml-[1vw] mr-[0.5vw] w-[0.7vw] h-[0.15vw] '></div>
            <InputBox
            trailText="Max"
            value={minMax[1]}
            setValue={(value:string)=>setMinMax(prev=>[prev[0],value])}
            min={min}
            max={max}
            isFloat={isFloat}
            onBlur={()=>handleInputChange()}
            />

        </div>
        {error && <p className='text-[0.9vw] text-red-500 px-[0.4vw]'>{error}</p>}
    </div>
  )
}
