import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from '../../utils/Axios';
import useAuthStore from "../../store/authStore";
import useCompanyUsersStore from "../../store/useCompanyUsersStore";
import AuthContainerBilling from '../auth/AuthContainerBilling';
import BillingLayout from '../layouts/BillingLayout';

const BillingSuccess: React.FC = () => {

    const [planName, setPlanName] = useState<any>();
    const authStore = useAuthStore();
    const { setCompanyUsers } = useCompanyUsersStore();

    useEffect(() => {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let sessionId = urlParams.get('sessionId');

        axios.post("/billing/successPayment", {
            sessionId,
            // engBackend: process.env.REACT_APP_BASE_URL
        })
            .then(async (session: any) => {
                setPlanName(session.data.data.accountPlan.planName + " Plan");

                let accountInfo = await axios.post("/billing/getAccountDetails", {
                    userId: authStore.userDetails?.userId,
                    isBillingAccount: authStore.userDetails?.isBillingAccount,
                    associatedBillingAccounts: authStore.userDetails?.associatedBillingAccounts
                });
                // console.log("accountss", accountInfo)

                if (accountInfo.data.result) {

                    // === Setting billing account details ===
                    authStore.setIsProcessComplete(true);
                    authStore.setBillingDetails(accountInfo.data.data?.billingDetails);
                    authStore.setActivePlan(accountInfo.data.data?.activePlan);
                    authStore.setUsage(accountInfo.data.data?.usage);

                    // === Setting company users ===
                    let usersData = await axios.get(`/user/companyuser`);
                    if (usersData && usersData.data.result) {
                        // console.log("setting");
                        setCompanyUsers(usersData.data.data);
                    } else {
                        console.log("Could not fetch account details");
                    }

                } else {
                    console.log("Could not fetch account details");
                }
            })
            .catch((err) => {
                setPlanName("Plan");
                console.log(err);
            })

    }, []);

    return (
        <BillingLayout>
            <AuthContainerBilling>
                <div className='flex items-center flex-col justify-center space-y-[2.5vw] text-center'>
                    <img className='w-[8vw]' src="/images/success.svg" alt="success" />
                    <h1 className='text-[1.8vw]'>Payment Successful!</h1>
                    <p className='text-[1.1vw] text-[#999999]'>Your payment for <strong>{planName}</strong> was successful! You can now continue using DataNeuron.</p>

                    <NavLink to='/'>
                        <div className="bg-primarycolor cursor-pointer text-center rounded-[0.25vw]">
                            <button className="w-[18vw] h-[3vw] cursor-pointer text-white text-[1vw]" type="submit">
                                Go to Dashboard
                            </button>
                        </div>
                    </NavLink>
                </div>
            </AuthContainerBilling>
        </BillingLayout>
    )
}

export default BillingSuccess;