import React, { useState } from 'react'
import Button from '../../llm-components/model-comparison/Button';

import Axios from '../../../utils/Axios';
import { toast } from 'react-toastify';
import { boolean } from 'mathjs';
import { useNavigate } from 'react-router-dom';

type Props = {}

function index({inputs,meta,apiKeyMinLength}:any) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading,setLoading] = useState<boolean>(false);

   

    const isValidApiKey = (apiKey:string)=>{

        if(apiKey === null || apiKey.length<apiKeyMinLength ){
            return false

        }
        return true;


    }

  const handleCreateProject = async(type:string)=>{

    
      setLoading(true);
      try{
        

        Object.keys(inputs).forEach((key:string)=>{
            if(inputs[key] === null || inputs[key] === undefined || inputs[key] === ""){
                throw new Error(`All fields are required(${key})`);
            }
        })

        Object.keys(meta).forEach((key:string)=>{
            if(key === "apiKey" && type === "trial") {
                console.log("skipping apiKey")
                return;
            }
            if(meta[key] === null || meta[key] === undefined || meta[key] === ""){
                throw new Error(`All fields are required(${key})`);
            }
        })

        const projectMeta = {...meta,type:type}
        console.log("values",{...inputs,projectMeta});

        const result = await Axios.post("/project/create-llm-project",{
            ...inputs,
            meta:projectMeta
        });
        console.log("=== project response ===>",result);
        navigate("/workflow?workflowId="+result?.data?.data?.workflowId);
        

    }catch(err:any){
        console.log("=== error while creating llm project ===>",err);
        toast.error(err.message);
    }

    setLoading(false);


  }


  return (
    <div className='flex'>
         <Button 
         loading={loading}
        text="Create Trial Project"
        onClick={()=>{handleCreateProject("trial")}}
        />
        <Button 
        loading={loading}
        onClick={()=>{handleCreateProject("production")}}
        disabled={!meta.apiKey || meta?.apiKey?.length<apiKeyMinLength }
        text="Create Project"
        />
    </div>
  )
}

export default index