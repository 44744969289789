import {ReactComponent as Logo} from '../assets/logo.svg';
interface AuthLogoProps {
    onClick?: () => void;
}

export let AuthLogo: React.FC<AuthLogoProps> = (props) => {
    return (
        <div
            className="flex space-x-[0.5vw] items-center cursor-pointer"
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
        >
            {/* <img className="w-[5vw]" src="/images/logo.svg" alt="logo" /> */}
            <Logo height="15vw" width="15vw"  />
            {/* <div style={{ width: 3 }} className="bg-black h-[6vh]"></div>
            <div className="text-[2.5vw] font-bold">DataNeuron</div> */}
        </div>
    );
};
