import React, { HTMLInputTypeAttribute, useEffect, useState } from "react";

let InputField: React.FC<React.AllHTMLAttributes<HTMLInputElement>> = (props) => {
    let [fieldType, setFieldType] = useState<HTMLInputTypeAttribute>();

    useEffect(() => {
        setFieldType(props.type);
    }, [props.type]);

    return (
        <div className="flex border border-gray-300 rounded-[0.25vw]">
            <input {...props} type={fieldType} className={" text-[1vw] px-[0.7vw] w-full py-[0.7vw] focus:outline-none "} />
            {props.type === "password" && (
                <img
                    onClick={() => setFieldType(fieldType !== "password" ? "password" : "text")}
                    className="px-[0.5vw] my-auto  w-[2.1vw] h-[2.1vw]"
                    src={fieldType !== "password" ? "/images/hidePassword.svg" : "/images/showPassword.svg"}
                    alt=""
                />
            )}
        </div>
    );
};

export default InputField;
