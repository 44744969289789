import React from "react";
import { useNavigate } from "react-router-dom";

import { useMatch } from 'react-router-dom';
import useWorkflowStore from '../../store/useWorkflowStore';
import { toast } from "react-toastify";
import './index.css';
import {Tooltip} from 'antd';

//Icons
import {ReactComponent as DashboardMain} from '../../assets/dashboard_main.svg';

import {ReactComponent as WorkflowDashboard} from '../../assets/dashboard.svg';
import {ReactComponent as Upload} from '../../assets/upload.svg';
import {ReactComponent as Masterlist} from '../../assets/masterlist.svg';
import {ReactComponent as Validation} from '../../assets/validation.svg';
import {ReactComponent as Iterate} from '../../assets/iterate.svg';
import {ReactComponent as AutoML} from '../../assets/automl.svg';
import {ReactComponent as Deploy} from '../../assets/deploy.svg';

import {ReactComponent as ActiveWorkflowDashboard} from '../../assets/active_dashboard.svg';
import {ReactComponent as ActiveUpload} from '../../assets/active_upload.svg';
import {ReactComponent as ActiveMasterlist} from '../../assets/active_masterlist.svg';
import {ReactComponent as ActiveValidation} from '../../assets/active_validation.svg';
import {ReactComponent as ActiveIterate} from '../../assets/active_iterate.svg';
import {ReactComponent as ActiveAutoML} from '../../assets/active_automl.svg';
import {ReactComponent as ActiveDeploy} from '../../assets/active_deploy.svg';
import { TrainStatus, checkCurrentTrainStatus } from "../../utils/navigationHelper";
import { callModelComparisonAPI } from "../mlc-components/model-comparison/_apiCall";
import useAuthStore from "../../store/authStore";
import template from "../../template";



let Sidebar: React.FC = () => {
    let navigate = useNavigate();
    
    const currentUser = useAuthStore(state=>state.userDetails);
    const currentProject = useWorkflowStore(state=>state.currentProject);
    let currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
    let workflowType = useWorkflowStore(state=>state.currentProject)?.workflowType || "";
    let userProject = useWorkflowStore(state => state.userProject);

    const projectType = useWorkflowStore(state=>state.currentProject)?.projectType || "";

    let isWorkflow = useMatch("/workflow/*");
    let isDashboard = useMatch("/workflow");
    let isUpload = useMatch("/workflow/upload");
    let isMasterlist = useMatch("/workflow/masterlist");
    let isStage1 = useMatch("/workflow/stage1")
    let isValidation =useMatch("/workflow/validation");
    let isStage2 = useMatch("/workflow/stage2");
    let isModelTrain1 = useMatch("/workflow/modeltraining-1");
    let isModelTrain2 = useMatch("/workflow/modeltraining-2");
    let isDeploy = useMatch("/workflow/modeltraining-2/dep");
    //console.log("== Upload Access  ==", userProject?.UPLOAD_ACCESS);
    let hasStages = currentWorkflow?.stages.find((stage)=>stage.name === "validation-1");
    const isCreator:boolean = currentProject?.createdBy === currentUser?.userId; 
    const links:any = {
        "annotation_workflow":{
            link:"validation"
            
        },
        "annotation_prediction_workflow":{
            link:currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress?"stage2":"stage1"
        }
    }

    const HoverMenu = ({children,text}:any)=>{
        return (

        <Tooltip
        placement="right"
        title={text} color="#777777">
            {children}
        </Tooltip>
        )
    }


    const handleNavigationToDeploy=()=>{
        let shouldNavigate = false;


        switch(checkCurrentTrainStatus(currentWorkflow)){
            case TrainStatus.Trained:
                shouldNavigate=true;
                break;

        }

        if(shouldNavigate && isCreator){
            navigate("/workflow/modeltraining-2/dep?workflowId="+currentWorkflow?.workflowId);
        }


    }
    const handleNavigateToTrain = async()=>{

        if(!isCreator){
            toast.warn("Only project admin can access training results");
            return;
        }

        let shouldNavigate:boolean = true;
        let navigatePoint:string ="";
      
        const trainingUrl = template[projectType][workflowType]?.trainingPageUrl;
        console.log("=== training URl ===>",trainingUrl)
          switch(checkCurrentTrainStatus(currentWorkflow)){
    
    
    
            case TrainStatus.NotTrained:
                  //alert("You have not trained the model");
                  toast.warn("Please tigger model training and try again");
                  shouldNavigate =false;
                  break;
            case TrainStatus.IsInLLMModelTrainig:
                    navigatePoint = "train-llm";
                    break;
            case TrainStatus.IsInLLMModelTuning:
                    navigatePoint = trainingUrl?trainingUrl:"tune-llm-model";
                    break;
            case TrainStatus.InModelTuning:
                navigatePoint = "model-tuning"
                  break;
            case TrainStatus.InModelSelection:
                  navigatePoint = "model-comparision"
                  //alert("you are in model selection");
                  break;
    
            case TrainStatus.InTraining:
                  navigatePoint = "modeltraining-1"
                  //alert("You are in training right now");
                  //shouldNavigate=false;
                  break;
            case TrainStatus.Trained:
                  navigatePoint = "modeltraining-2"
                  
                  //alert("You want to start iterate");
                  break;
            case TrainStatus.InIterate:
                  //alert("You are in iterate mode");
                  
                  break;
            default:
                //alert("could not find anything")
    
    
          }
          shouldNavigate && navigatePoint.length && navigate(`/workflow/${navigatePoint}?workflowId=`+currentWorkflow?.workflowId);
          return;





    }
    const handleNavigationToValidation =()=>{
        const currentStatus = checkCurrentTrainStatus(currentWorkflow);
    if(!currentWorkflow?.stages.find((s)=>s.name==="validation-1"
                                            || s.name==="validation")?.active){

                                                toast.warn("Please initiate stage1 to go to validation")
                                                return;
                                            }

                                            const stage2 = currentWorkflow?.stages.find((s)=>s.name==="validation-2")?.active;
                                            const stage1 = currentWorkflow?.stages.find((s)=>s.name==="validation-1"
                                            || s.name==="validation")?.active;
                                            navigate(`/workflow/${stage2?"stage2"
                                                                    :hasStages?"stage1"
                                                                    :"validation"}?workflowId=`+currentWorkflow?.workflowId);
                                            return;
      if(currentStatus === TrainStatus.NotTrained 
        || currentStatus === TrainStatus.InIterate){
                                        
      }

      if(currentStatus === TrainStatus.InModelSelection 
        || currentStatus === TrainStatus.InModelTuning
        || currentStatus=== TrainStatus.InTraining
         ){
           
        toast.warn("Please complete model training first");
        return;
        //navigate("/workflow?workflowId="+currentWorkflow?.workflowId);
      }
      toast.warn("Please trigger iterate before validating more paragraphs");
    }
    
    return (
        <div className={`fixed left-0 
        
        ${ currentWorkflow && currentWorkflow?.stages?.length>4
        ?"top-[15vh]":"top-[20vh]"}
        `}
        
        >
                {/* <img
                    src="/images/dashboard_main.svg"
                    className="cursor-pointer w-[2.5vw] fixed left-[1.2vw] top-[16vh] h-[2.5vw]"
                    alt="dashboard"
                    onClick={() => navigate("/")}
                /> */}
                <DashboardMain
                className="cursor-pointer w-[2.5vw] fixed left-[1.2vw] top-[16vh] h-[2.5vw]" 
                onClick={() => navigate("/")}
                />
                
                {
                    isWorkflow &&
                    <>
                        <div
                        className={`
                            
                            ${currentWorkflow!==null && 
                                currentWorkflow!.stages!.length>=4
                            ?"myimage"
                            :"myimage2"} `}
                        >
                        </div>
                       
                


                            <div className={`  ${currentWorkflow?.stages.find(s=>s.name==="train")?"icons":"icons2"}   flex-col justify-center  py-[1.5vw] space-y-3 pr-4 border-r-[0.08vw]`}>


                            <HoverMenu text="Workflow">

                                <div
                                    style={{
                                        background: isDashboard ? "#11256D" : "#EAEAEA",
                                    }}
                                    className="w-[3vw] h-[3vw] rounded-full bg-gray-200 flex justify-center items-center cursor-pointer transform hover:scale-110 duration-200"
                                    onClick={() => navigate("/workflow?workflowId=" + currentWorkflow?.workflowId)}
                                >
                                    {/* <img
                                        className="w-[1.5vw] h-[1.5vw]"
                                        src={`/images/${isDashboard ? "active_" : ""}dashboard.svg`}
                                        alt="dashboard"

                                    /> */}
                                    {isDashboard?<ActiveWorkflowDashboard
                                    className="w-[1.5vw] h-[1.5vw]"
                                    />:<WorkflowDashboard
                                    className="w-[1.5vw] h-[1.5vw]"
                                    />}
                                </div>
                            </HoverMenu>

                               

                                <HoverMenu text="Upload">

                                <div
                                    style={{
                                        background: isUpload ? "#11256D" : "#EAEAEA",
                                    }}
                                    className={`${currentWorkflow?.masterlistLocked || !userProject?.UPLOAD_ACCESS ? "cursor-not-allowed" : "cursor-pointer"} w-[3vw] h-[3vw] rounded-full bg-gray-200 flex justify-center items-center  transform hover:scale-110 duration-200`}
                                    onClick={() => {
                                        if (!currentWorkflow?.masterlistLocked && userProject?.UPLOAD_ACCESS) {
                                            navigate("/workflow/upload?workflowId=" + currentWorkflow?.workflowId)

                                        }
                                        else {
                                            if (!userProject?.UPLOAD_ACCESS) {
                                                toast("You do not have the access to upload files. Please contact your project creator.", { type: toast.TYPE.WARNING })
                                            }
                                            else {

                                                toast("The Upload stage is locked.", { type: toast.TYPE.INFO })
                                            }

                                        }
                                    }
                                    }
                                >
                                    {/* <img className="w-[1.5vw] h-[1.5vw]"
                                        src={`/images/${isUpload ? "active_" : ""}upload.svg`}
                                        alt="upload"

                                    /> */}
                                    {isUpload?<ActiveUpload
                                    className="w-[1.5vw] h-[1.5vw]"
                                    />
                                    :<Upload
                                    className="w-[1.5vw] h-[1.5vw]"
                                    />}
                                </div>
                                </HoverMenu>

                               {currentWorkflow?.stages.find(s=>s.name==="masterlist") && <HoverMenu text="Masterlist">

                                <div
                                    style={{
                                        background: isMasterlist ? "#11256D" : "#EAEAEA",
                                    }}
                                    className={`w-[3vw] h-[3vw] rounded-full bg-gray-200 flex justify-center items-center ${currentWorkflow?.stages.find(s=>s.name==="masterlist")?.active?"cursor-pointer":"cursor-not-allowed"} transform hover:scale-110 duration-200`}
                                    onClick={() =>{
                                        if(!currentWorkflow?.stages.find(s=>s.name==="masterlist")?.active) 
                                        {
                                            toast("Create a masterlist first",{type:toast.TYPE.WARNING})
                                            return;
                                        }
                                          navigate("/workflow/masterlist?workflowId=" + currentWorkflow?.workflowId)}}
                                >
                                    {/* <img
                                        className="w-[1.5vw] h-[1.5vw]"
                                        src={`/images/${isMasterlist ? "active_" : ""}masterlist.svg`}
                                        alt="masterlist"

                                    /> */}
                                    {isMasterlist?<ActiveMasterlist  className="w-[1.5vw] h-[1.5vw]"/>
                                        :<Masterlist className="w-[1.5vw] h-[1.5vw]" />
                                    }
                                </div>
                                </HoverMenu>}

                                <HoverMenu text="Validation">

                                <div
                                    style={{
                                        background: isStage1 || isStage2 || isValidation ? "#11256D" : "#EAEAEA",
                                    }}
                                    className={`${!currentWorkflow?.masterlistLocked ? "cursor-not-allowed" : "cursor-pointer"} w-[3vw] h-[3vw] rounded-full bg-gray-200 flex justify-center items-center transform hover:scale-110 duration-200`}
                                    onClick={() => {
                                        handleNavigationToValidation();

                                    }}
                                >
                                    {/* <img
                                        className="w-[1.5vw] h-[1.5vw]"
                                        src={`/images/${isStage1 || isStage2 || isValidation ? "active_" : ""}validation.svg`}
                                        alt="validation"

                                    /> */}
                                    {isStage1 || isStage2 || isValidation?<ActiveValidation className="w-[1.5vw] h-[1.5vw]" />
                                    :<Validation className="w-[1.5vw] h-[1.5vw]" />

                                    }
                                </div>
                                </HoverMenu>

                                {currentWorkflow?.stages.find(s => s.name === "train") && <>

                                    <HoverMenu text="Train">

                                    <div
                                        onClick={() => currentWorkflow?.stages.find(s => s.name === "train")?.active || currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress 
                                        ? handleNavigateToTrain()
                                         :toast("Train the model first.", { type: toast.TYPE.WARNING })}
                                        style={{
                                            background: isModelTrain1 || isModelTrain2 ? "#11256D" : "#EAEAEA",
                                        }}
                                        className="w-[3vw] h-[3vw] rounded-full bg-gray-200 flex justify-center items-center cursor-pointer transform hover:scale-110 duration-200"
                                    >
                                        {/* <img className="w-[1.5vw] h-[1.5vw]" 
                                        src={`/images/${isModelTrain1 || isModelTrain2 ? "active_" : ""}automl.svg`}
                                         alt="automl" /> */}
                                         {isModelTrain1 || isModelTrain2?<ActiveAutoML className="w-[1.5vw] h-[1.5vw]" />
                                         :<AutoML className="w-[1.5vw] h-[1.5vw]"  />

                                         }
                                    </div>
                                    </HoverMenu>

                                   {currentWorkflow?.stages.find(s => s.name === "deploy") && <HoverMenu text="Deploy">

                                    <div
                                        style={{
                                            background: isDeploy ? "#11256D" : "#EAEAEA",
                                        }}
                                        onClick={() => currentWorkflow?.stages.find(s => s.name === "train")?.active ? handleNavigationToDeploy() :
                                            toast("Train the model first", { type: toast.TYPE.WARNING })}
                                        className="w-[3vw] h-[3vw] rounded-full bg-gray-200 flex justify-center items-center cursor-pointer transform hover:scale-110 duration-200"
                                    >
                                        {/* <img className="w-[1.5vw] h-[1.5vw]" src={`/images/${isDeploy ? "active_" : ""}deploy.svg`} alt="deploy" /> */}
                                        {isDeploy?<ActiveDeploy className="w-[1.5vw] h-[1.5vw]" />
                                        :<Deploy  className="w-[1.5vw] h-[1.5vw]" />

                                        }
                                    </div>
                                    </HoverMenu>}
                                </>}
                            </div>

                     
                    </>
                }
                




            </div>

    );
};

export default Sidebar;
