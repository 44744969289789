import Sidebar from "../controls/Sidebar";
import Topbar from "../controls/Topbar";

let AppLayout: React.FC = ({ children }) => {

    return (

        <div className="mt-[5vw] ml-[5vw] mr-[1.5vw] bg-gray-50 px-[3vw] py-[1vw] rounded-md min-h-screen">

            <Topbar />
            {/* {location.pathname !== "/project_dashboard" && <Sidebar />} */}
            {true && <Sidebar />}
            {children}
        </div>
    );
};

export default AppLayout;
