

import React, { useState, useEffect } from "react";
import ApexChart from "./ApexChart";
import styles from "./modelTraining1.module.css";
import { useContext } from "react";
//import useWorkflow from "../../../../../store/zustand/useWorkflow";
import useWorkflowStore from "../../../../store/useWorkflowStore";
// import { sendWorkflowInfo } from "../../../../../realtime";
//import useCurrentUser from "../../../../../store/zustand/useCurrentUser";
import useAuthStore from "../../../../store/authStore";
import {useNavigate} from 'react-router-dom';
//import ReactGA from "react-ga";

export interface ModelTraining1Props {}

interface detailsState {
  trainingProgress: number;
 
}

const ModelTraining1: React.FC<ModelTraining1Props> = () => {
  let workflowStore = useWorkflowStore();
  let navigate = useNavigate();

  let initial = workflowStore.currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress;
  let llmProgress = initial?.llmProgress || 0;

 

  //let { stages, setStages } = useContext(StageContext);





  return (
    <div className="w-full mt-4">
      <section className={styles.training_section}>
        <img src="/images/training_icon.svg" width={40} alt="Training Icon" />
        <div>Training in progress ...</div>
        <div className={styles.training_percentage}>
          {llmProgress}%
        </div>
      </section>
      {/* Training Section */}
      {/* Data Section
      <section className={styles.data_section}>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>DATA POINT</div>
          <div className={styles.data_value}>{details.dataPoint}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>DATA POINT</div>
          <div className={styles.data_value}>{details.dataPoint}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>TOTAL UPLOADS</div>
          <div className={styles.data_value}>{details.totalUpload}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>ESTIMATED TIME</div>
          <div className={styles.data_value}>{details.estimatedTime}</div>
        </div>
      </section>  */}
      {/* Data Section */}
      {/* Graph Section */}
      <div className=" justify-center">

      <section className={` flex p-[1vw] justify-between  h-[60vh] `}>
        <div className="w-[15%]">

        </div>
       
        <div className={`w-[40vw] h-[35vw] flex items-center justify-center   relative  `}>
          <div className="w-full h-full mt-[3vw] ">

          <ApexChart
            stage1={llmProgress}
           
            height={600}
          />
          </div>

          <div className="w-full h-full absolute flex items-center justify-center">
            <p className="text-[3.5vw] text-[#1BA94C]">
                {llmProgress}%

            </p>
          </div>

        </div>

        <div className={`flex flex-col p-[0.4vw] justify-end`}>
          <div className={styles.tip_container}>
            <img src="/images/tip_green.svg" alt="Tip Icon" />
            <div>Tip</div>
          </div>
          <div className={styles.tip_description}>
            You can choose to minimize this process and come back to it later.
            You can access it by peeking into the dashboard.
          </div>
        </div>

      </section>
      </div>
    </div>
  );
};

export default ModelTraining1;
