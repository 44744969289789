import React, { useEffect, useState } from 'react';
//import styles from "./accuracy.module.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { nodesAtom,layersAtom } from "../../../../components/masterlist/atoms";
import useWorkflow from "../../../../store/useWorkflowStore";
import { Popover, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import { markModelClassAsFalse } from '../../../masterlist/utils/helper';
import axios from '../../../../utils/Axios';
import {AiFillCaretDown} from 'react-icons/ai';
import produce from 'immer';
import { Layer } from '../../../masterlist/utils/interface';

import LoadingScreen from '../../../loading-screen';


export default function MLCAccuracy() {

    let [selected, setSelected] = useState<Array<any>>([]);
    let [canDeselect, setCanDeselect] = useState<boolean>();
    let [loading,setLoading] = useState<boolean>(false);

    const [loadingAccuracy,setLoadingAccuracy] = useState<boolean>(false);
   

    let [nodesInState, setNodes] = useRecoilState(nodesAtom);
    let [layers,setLayers] = useRecoilState(layersAtom);
    let detailedReport: any = {};
    let workflow = useWorkflow(state => state.currentWorkflow);

    let key = workflow?.alpdsInfo?.stage2ClassificationReports?.lastUpdatedKey || null;
    let currentBatch = workflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchNumber || 0;
    let classificationReport = workflow && workflow?.alpdsInfo.stage2ClassificationReports;

    //detailedReport =classificationReport &&  (key ? (classificationReport[key] || {})  :  ( classificationReport[`batch${currentBatch}`] || {}));
    detailedReport=classificationReport[key];
    let nodes = useRecoilValue(nodesAtom); 
    const stage2AccuracyProgress= workflow?.alpdsInfo?.percentageStatus?.stage2AccuracyProgress || null;

    let DeselectForClassfication = async () => {
        if (!selected.length) return;

        if(!canDeselect)
        {
            toast("You Need Atleast 2 Model Classes For Model Training",{type:"warning"});
            return;

        } 

        let new_nodes = markModelClassAsFalse(selected, nodes);
        setNodes(new_nodes);

        

        let temp: Array<any> = [];
        selected.forEach((node_id) => {
            let node = nodes.find((n) => n.node_id === node_id);

            if (node) {
                temp.push(node._id);
            }
        });


        setLoading(true)

       // await new Promise(resolve=>setTimeout(resolve,1000))
        try{

            await axios.post("/masterlist/updateNodeBulk",{
                nodes:temp,
                props:{
                    is_model_class:false
                }
            })
        }
        catch(err)
        {
            toast("Could not update nodes",{type:toast.TYPE.ERROR});

        }
        finally{
            setSelected([]);
            setLoading(false);
        }




    }

    let canDeselectForClassification = () => {
        if (!selected.length) return false;

        let model_classes = nodes.filter(n => n.is_model_class);

        if (model_classes.length - selected.length >= 2) {
            return true;
        }
        else {
         
            return false;
        }
    }

    useEffect(() => {

        setCanDeselect(canDeselectForClassification());


    }, [selected]);

    // useEffect(()=>{

    //     if(stage2AccuracyProgress === null || stage2AccuracyProgress === undefined )
    //     {
    //         RefreshAccuracyReports();
    //     }

    // },[]);

    let getNodeParent = (node_id: string) => {

        let node = nodes.find(n => n.node_id === node_id);

        if (!node?.parent_id) return "N/A";

        return nodes.find(n => n._id === node?.parent_id)?.node_name;
    }

    let getNodeColor = (color: string) => {

        if (color === "red") return "!text-[#E63946]";

        if (color === "yellow") return "!text-[#F59F00]";

        if (color === "green") return "!text-[#1BA94C]";

    }

    let getAncestors = (node_id: string): Array<string> => {
        // console.log("Inside Ancestors");
        let nodeInfo = nodes.find(n => n.node_id === node_id);
        let ancestors: Array<string> = [];

        if (nodeInfo?.node_level === 0) return [nodeInfo.node_name];

       // let temp_parent:string;
        let current_node = nodeInfo;
        for (let i: number = nodeInfo?.node_level || 0; i >= 0; i--) {

            ancestors.push(current_node?.node_name || "");
            let temp_parent = current_node?.parent_id || "";
            let parentInfo = nodes.find(n => n._id === temp_parent);
            //  ancestors.push(parentInfo?.node_name);
            current_node = parentInfo;
        }
        ancestors.reverse();
        // console.log("=== ancestors ===",ancestors);
        return ancestors;




    }

    // const RefreshAccuracyReports =async()=>{
    //     setLoading(true);

    //     const currentBatchNumber:number = workflow?.alpdsInfo.percentageStatus.stage2Progress.currentBatchNumber || 0;

    //     if(!currentBatchNumber)
    //     {
    //         toast("Batch number information is not present in current workflow",{type:"warning"});
    //         setLoading(false)
    //         return;
    //     }
       
    //     let response =await axios.post("/integration/refreshAccuractReport",{
    //         workflowId:workflow?.workflowId,
    //         batchNumber:currentBatch
    //     });

    //     setLoading(false);


    // }

    const Content = (ancestors: Array<string>) => {

        if(!nodes) return;
        return (
            <div className='max-w-[30vw] text-center overflow-x-'>
                {
                    ancestors.map((a, i) => {
                        return <span key={i} className='!w-[5vw] text-ellipsis text-[#7a7a7a] text-[1vw]'>{a}{i !== ancestors.length - 1 && " > "}</span>
                    })
                }
            </div>
        )

    }

    const TData =(node_id:string)=>{
       
        if(node_id === "No Tag") 
        {
            return node_id && ( <td className={`   ${getNodeColor(detailedReport[node_id].color)}`}>
            {
         
                        <Popover content="No Tag">
                            <span className={`text-[1.1vw]  text-ellipsis`}>
                                {node_id}
    
                            </span>
                        </Popover>
                
                
            }
        </td>);
        }
        if(!nodes) return ;
        let nodeData:any= nodes.filter((node) => node.node_id === node_id)[0];


        if(!nodeData) return (
            <span>
                <p className='text-center'>Node not found</p>
            </span>
        )
   

        return node_id && ( <td className={`text-center1   ${nodeData.is_model_class?getNodeColor(detailedReport[node_id].color) :"text-[#777777]"}`}>
        {
     
                    <Popover content={Content(getAncestors(node_id))}>
                        <span className={`text-[1.1vw]  text-ellipsis ${!nodes.find(n => n.node_id === node_id)?.is_model_class ? "opacity-50" : ""}`}>
                            {`${nodeData &&  nodeData.node_name.length>25?nodeData.node_name.substring(0,22)+"...":nodeData.node_name}`}

                        </span>
                    </Popover>
            
            
        }
    </td>);
    }

    const Table = ()=>{
        return (
            <table className={`styles.details_table text-[1vw] table-auto w-full`}>
            <thead>

            <tr className=' bg-[#f3f3f3] h-[5vw]'>

                <th className={"text-center  text-[#999999]  "}></th>
                <th className={"text-left text-[#999999]"}>Class Node</th>
                <th className={"text-left text-[#999999] "}>Parent Node</th>
                <th className={"text-left text-[#999999] "}>Validated Paragraphs</th>
                <th className={"text-left text-[#999999] mr-[3vw] "}>Expected F1 Score</th>

            </tr>
            </thead>
            <tbody>

            {
               detailedReport && Object.keys(detailedReport)
               .sort((k1:string,k2:string)=>detailedReport[k1]?.accuracy>detailedReport[k2].accuracy?-1:1)
               .map((node_id: string) => {

                    //getAncestors(node_id) 
                    return (node_id !== "timestamp" && node_id!== "lastUpdatedTimestamp") &&
                        (<tr key={node_id} className="!border-b !border-b-[#c4c4c4] h-[5vw] hover:bg-gray-100 ">
                        <td className='text-center  !pr-0 !w-[3vw]  '>
                            {/* <input type="checkbox"
                            
                                checked={selected.find(n => n === node_id)!== undefined}
                                 disabled = {!nodes.find(n=>n.node_id === node_id)?.is_model_class || loading}
                               
                                onChange={(e) => {
                                    //console.log("=== event  ===",e.target.checked);
                                    if (e.target.checked) {
                                        setSelected([...selected, node_id]);
                                    } else {
                                        setSelected(
                                            selected.filter(
                                                (data) => data !== node_id
                                            )
                                        );
                                    }

                                }}
                                className='!w-[1.3vw] h-[1.3vw] hover:cursor-pointer disabled:cursor-not-allowed'
                                 />              */}
                            </td>
                            {TData(node_id)}
                            {/* <td className={`text-center1    ${getNodeColor(detailedReport[node_id].color)}`}>
                                {
                             
                                            <Popover content={Content(getAncestors(node_id))}>
                                                <span className={`text-[1.1vw]  text-ellipsis ${!nodes.find(n => n.node_id === node_id)?.is_model_class ? "opacity-50" : ""}`}>
                                                    {nodes.filter((node) => node.node_id === node_id)[0]?.node_name}

                                                </span>
                                            </Popover>
                                    
                                    
                                }
                            </td> */}
                            <td className={"text-center1 "}>{
                                getNodeParent(node_id)
                            }</td>
                            <td className={"text-left "}>{detailedReport[node_id].validatedParas || 0}</td>
                            <td className={"text-left mr-[3vw] "}>{

                                detailedReport[node_id].accuracy?.toFixed(2) || 0}</td>
                        </tr>)

                })
            }
            
            </tbody>




        </table>

        )
    }




    return (
        <div className=''>
         {/* { stage2AccuracyProgress===100?   <div className=' flex justify-end'>
                <Popover content="Refresh Accuracy">

                    <div
                        style={{
                            alignSelf: "center",
                            justifySelf: "flex-end"
                        }}
                        className="duration-700 rounded  p-2 cursor-pointer flex items-center -mr-2 border-1 hover:scale-105"
                        onClick={() => RefreshAccuracyReports()}
                    >
                        <img src="/images/reload.svg" className="w-[3vw]" alt="" />
                    </div>
                </Popover>
            </div>:null} */}
              
            {
            false? <LoadingScreen  msg='Recalculating Accuracy Report' 
            compleated={stage2AccuracyProgress || 0}/>: <Table />
            }
            


            


            { false ? <div className='flex justify-end items-center h-[5vw] '>


                <button
                    title={!canDeselect && selected.length ? "You Need Atleast 2 Model Classes For Model Training":""}
                    disabled={!selected.length}
                    onClick={DeselectForClassfication}
                    className={`${!canDeselect|| loading?"bg-[#777777] text-white opacity-60":""} w-[20vw] text-center h-[3vw] bg-[#E63946] rounded font-semibold text-white hover:scale-[105%] text-[1vw] duration-700`}>
                    {loading? <img src="/images/loading_white.svg" alt="" className='w-[2vw] mx-auto'  />: "Deselect for Classification"}
                </button>


            </div>:null}
        </div>
    )
}
