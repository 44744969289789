import React,{useEffect,useState} from 'react';

interface FolderCapsuleProps{
    folderName:string,
    activeFolder:string | undefined,
    setActiveFolder:any
}

const FolderCapsule:React.FC<FolderCapsuleProps>=({folderName,activeFolder,setActiveFolder})=> {

    const [isMounted,setIsMounted] = useState<boolean>(false);



    useEffect(()=>{
        setIsMounted(true);
    },[]);

    return (
        // Adding testid to check functionality
        <div data-testid="folder-active-test" className={`border border-black rounded-full 
        flex items-center justify-center text-[0.8vw]
        duration-300 cursor-pointer overflow-hidden
        h-[2vw] w-[8vw] m-[0.5vw] select-none
        ${folderName === activeFolder?"bg-[#11256D] text-white scale-110":"hover:scale-110 hover:border-[#11256D] hover:text-[#11256D] hover:border-2"}
        ${isMounted?"scale-100":"scale-0"}
        `}
        onClick={()=>folderName === activeFolder?setActiveFolder(undefined): setActiveFolder(folderName)}
       
        >
            {folderName}</div>
    )
}

export default FolderCapsule