import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";


import UserStatistics from "../../../components/validation-summary/UserStatistics";
import ProjectSummary from "../../../components/validation-summary/ProjectSummary";
import TrainingParasTab from "../../../components/validation-summary/TrainingParasTab";
//import Accuracy from "../../../components/validation-summary/Accuracy";
//import Accuracy from "../../validation-summary/TempAccuracy";
import MLCAccuracy from "./tabs/MLCAccuracy";
import useWorkflowStore from '../../../store/useWorkflowStore';
import Axios from '../../../utils/Axios';
import {Popover} from 'antd';
import useAuthStore from "../../../store/authStore";
import useCompanyUsersStore from "../../../store/useCompanyUsersStore";
import template from "../../../template";
import { TrainStatus, checkCurrentTrainStatus } from "../../../utils/navigationHelper";

const ValidationStats: React.FC = () => {
  let [active, setActive] = useState(0);

  const workflow:any = useWorkflowStore(state => state.currentWorkflow);
  const project:any = useWorkflowStore(state=>state.currentProject);
  const workflowStore = useWorkflowStore();
  let authStore = useAuthStore();
  let {companyUsers} = useCompanyUsersStore();
  const currentStatus = checkCurrentTrainStatus(workflow);
  const  [requestType,setRequestType] = useState<number>(currentStatus === TrainStatus.NotTrained?1:2);
  const [pieloading, setPieLoading] = useState(true);
  const [options,setOptions ] = useState<any>();
  const [seriesData,setSeriesData]= useState<Array<any>>();
  const [heatmapLoading, setHeatmapLoading] = useState(true);
  const [max, setMax] = useState(0);
  const [pieData, setPieData] = useState<any>(null);
  const [data, setData] = useState([]);
  let [users,setUsers] = useState<Array<any>>([]);
//   const mockData:any={"num_user_1":{
//     "19b586cf-7f7b-43ed-a728-67057d966ea8":18
//     ,"292b3b0f-03b5-4e37-84ce-4a0908736e55":18
//     ,"3347cd35-e66a-4911-8e0a-55c52d186839":18
//     ,"84062a09-b16e-44e3-a755-9cf85c5c8c8a":18
//     ,"981b1ae4-44a3-407e-be92-d6d6c0fc42d8":18
//     ,"No Tag":8}
//     ,"num_user_2":{}
//     ,"para_count":{
//       "num_user_1":26,
//     "num_user_2":13,
//     "num_user_3":13
//   , "num_user_4":26,
//   "num_user_5":13,
//   "num_user_6":13,
//   "num_user_7":13
//   , "num_user_8":26,
//   "num_user_9":13,
//   "num_user_10":13}
// }

  let batchNumber =workflow?.alpdsInfo.percentageStatus.stage2Progress.currentBatchNumber;
  let stage = 2;

  //get component
  let SummaryComp = template[project?.projectType || ""][project?.workflowType || ""]?.summary

//Call At Initial Render
useEffect(() => {

  if(workflow) {
    fetchPieGraphData();
  }

  if (workflow && companyUsers.length > 0) {
    fetchUserProject();
    fetchData();
  }

}, [workflow?.workflowId, companyUsers]);

//fetch all users
useEffect(()=>{

  //Config Graph Options
  const options = {
  colors: ['#11256D'],
  xaxis: {
      //type:"category",
      
      position: 'top',
      // offsetY:'20px',
      // offsetX:'50px'
  },
  yaxis:{
      reversed:true
  },
  toolbar: {
      show: false
  }
  }
  setOptions(options);
  
  
      //Config Graph values
   
      let series:Array<any> =[]
     
      data.forEach((d:any)=>{
          let name=d && d.user?.firstName;
          let overlap:Array<any>=[]
         // d.result.sort((u1:any,u2:any)=>u1.user._Id-u2.user._Id);
           d.result?.forEach((r:any)=>{
               overlap.push({y:r.overlap.toFixed(2),x:r.user.userInfo.firstName});
              
           })
           series.push({name,data:overlap});
  
       })
       //console.log("=== Series ===",series);   
       setSeriesData(series);
  
  },[data]);

const fetchUserProject = async()=>{
  //let workflowId = workflowStore.currentWorkflow?.workflowId;
  let projectId = workflowStore.currentProject?.projectId;
  //let userId =authStore.userDetails?.userId;
  let company = authStore.userDetails?.company;

  //console.log("=== Project Id ===",projectId);


  try{
    //console.log("=== Fetching users ===")
      let res = await Axios.post("/userproject/user",{
          projectId,
          companyUsers
      });
     // console.log("=== Response for Users===",res.data);
      if(res.data.result)
      {
          setUsers(res.data.data.filter((user:any)=> user.user_project_info?.VALIDATION_ACCESS ));
      }
      else{
        toast("Could not get user pie chart data. Please reload the page.")
      }
      

  }
  catch(err){
     // console.log("=== Error in code ===",err);
      toast("Could not get users. Please reload the page.",{type:toast.TYPE.ERROR});
  }

};


  //fetch multiuservoerlap Graph Data Here
  const fetchData = async () => {
    if(!workflow && !batchNumber) return;
    try { 

        setHeatmapLoading(true);
        let res: any = await Axios.post('/summary/multiuseroverlapstats', {
            stage: stage,
            workflowId: workflow?.workflowId,
            batchNumber:batchNumber,
            requestType
        })
        
        let companyMap = new Map();

          companyUsers.forEach((user: any) => {
            companyMap.set(user.userId, {
              userId: user.userId,
              firstName: user.firstName,
              lastName: user.lastName,
            })
          })
          
          let overlap = res.data.data;
          let merged: any = [];

          overlap.forEach((item: any) => {
            
            let resultArr: any = [];
            let result = item.result;
            result.forEach((inner: any) => {

              let obj2 = {
                user: {
                  userId: inner.user.userId,
                  userInfo: companyMap.get(inner.user.userId)
                },
                validations: inner.validations,
                overlap: inner.overlap
              };

              resultArr.push(obj2);
            })

            let obj = {
              user: companyMap.get(item.user.userId),
              result: resultArr,
            };

            merged.push(obj)
          })

          // console.log("Merged", merged)
          
          if (merged.length > 0) {
            merged.sort((d1:any,d2:any)=>d1.user._Id-d2.user._Id);
            merged.forEach((d:any)=>{
                d.result.sort((r1:any,r2:any)=>{
                  return r1.user.userInfo._id > r2.user.userInfo._id?1:-1 })
            })
          
            setData(merged);

           // console.log("=== Res after sort ===",merged);
          }
          
        setHeatmapLoading(false);
        

        
       // console.log("=== Res after sort ===",res.data.data);
        

    } catch (err: any) {
      //  console.log("=== res ===", err.response.data);
        setHeatmapLoading(false);
    }

}

// fetch multiuserpiechart data here
let fetchPieGraphData = async () => {
 // if(requestType===3) return;
  if (workflow && batchNumber >0) 
{
    try {

      //Set Loading True
      setPieLoading(true)

      let res = await Axios.post("/summary/multiuserpiechart", {
        workflowId: workflow?.workflowId,
        stage: stage === 1 ? "stage1" : "stage2",
        //batchNumber:batchCounter
        batchNumber,
        requestType:requestType
      })
      setPieData(res.data.data);
      //setPieData(mockData);
      //console.log("=== pie data ===",res.data.data);
      
      setMax(Object.keys(res.data.data).length-1);

    } catch {
      toast("Could not get user pie chart data. Please reload the page.", { type: toast.TYPE.ERROR });
    }
    finally {
      setPieLoading(false);
    }

  }
  


}


//Call At RequestType Change in select
useEffect(() => {

  if(workflow) {
    fetchPieGraphData();
  }

  if (workflow && companyUsers.length > 0) {
    fetchUserProject();
    fetchData();
  }

}, [requestType, companyUsers]);


  return (
   
      <div className="w-full">
      
        <div className="w-full">
          <div className="w-full mb-[1vw]">
            <div
              className="flex justify-between"
              style={{ width: "100%", fontSize: "17px" }}
            >
              <div className="flex mx-5 ">
              <p
                className="cursor-pointer p-3 mx-3 text-[1.1vw] mt-2"
                style={{
                  color: `${active === 0 ? "#1BA94C" : "#000"}`,
                  borderBottom: active === 0 ? "3px solid #1BA94C" : "none",
                  fontWeight: 500

                }}
                onClick={() => setActive(0)}
              >
                Accuracy
              </p>
             
              <p
                className="cursor-pointer p-3 mx-3 text-[1.1vw] mt-2"
                style={{
                  color: `${active === 1 ? "#1BA94C" : "#000"}`,
                  borderBottom: active === 1 ? "3px solid #1BA94C" : "none",
                  fontWeight: 500

                }}
                onClick={() => setActive(1)}
              >
                Project Statistics
              </p>
              <p
                className="cursor-pointer p-3 mx-3 text-[1.1vw] mt-2"

                style={{
                  color: `${active === 2 ? "#1BA94C" : "#000"}`,
                  borderBottom: active === 2 ? "3px solid #1BA94C" : "none",
                  fontWeight: 500
                }}
                onClick={() => setActive(2)}
              >
                User Statistics
                
              </p>

              <p
                className="cursor-pointer p-3 mx-3 text-[1.1vw] mt-2"

                style={{
                  color: `${active === 3 ? "#1BA94C" : "#000"}`,
                  borderBottom: active === 3 ? "3px solid #1BA94C" : "none",
                  fontWeight: 500
                }}
                onClick={() => setActive(3)}
              >
                Training Paragraphs
                
              </p>


        
              </div>
            
            {active!==0 &&  <Popover content="Refresh graphs">

              <div
                style={{
                  alignSelf:"center",
                  justifySelf:"flex-end"
                }}
                className="duration-700 rounded  p-2 cursor-pointer flex items-center -mr-2 border-1 hover:scale-105"
                onClick={()=>{
                  if(!pieloading && !heatmapLoading)
                  {
                    fetchUserProject()
                    fetchData()
                    fetchPieGraphData()
                  }
                }}
              >
                <img src="/images/reload.svg" className={`w-[3vw] 
                ${pieloading || heatmapLoading?"animate-spin":""}`} alt="" />
               </div>
              </Popover>}

            </div>
          </div>
          {/* <div
            style={{
              width: "100%",
              height: "1px",
              background: "#C4C4C4",
              marginBottom: 20,
              transform: "translateY(-1.5px)",
            }}
          ></div> */}
          <div style={{ margin: "0 25px 0 40px" }}>
            {/* {active === 0 && < ProjectStatistics/>} */}
          </div>

          <div style={{ margin: "0 25px 0 40px" }}>

         {
           active === 2 && <UserStatistics 
           setRequestType={setRequestType}
          requestType={requestType}
           stage={stage}
            batchNumber={batchNumber}
             users={users} /> 
         }

         {
           active===1 && React.createElement(SummaryComp?.projectSummary,{
            requestType:requestType,
            setRequestType:setRequestType,
            heatmapData:{loading:heatmapLoading,seriesData:seriesData,options}, 
            stage:stage, 
            batchNumber:batchNumber,
            pieData:{loading:pieloading,seriesData:pieData,},
            max :max
          })
          
           
       
         }

         {
           active ===0 && <MLCAccuracy />
         }
          {
            active===3?<TrainingParasTab hasStages={true} />:null
           }
          </div>
        </div>
      </div>
    
  );
};
export default ValidationStats;
