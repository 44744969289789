import { toast } from "react-toastify";
import Axios from "../../../utils/Axios";

export const callCoverageAPI = async(workflowId:string)=>{

    try{

        let data = await Axios.post("/integration/getDataCoverage",{
            workflowId:workflowId
        });
        return data?.data;


    }catch(err){
        toast.error("There was an error while generating coverage data, please refresh the page and try again!");
    }


};