import React, { useEffect, useState } from 'react'
import { ReactComponent as DownChevrolet } from '../../../assets/down_chevrolet.svg'
import { ReactComponent as SearchIcon } from '../../../assets/search.svg'
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';

interface IMultiClassSelector {
    dropdownOptions: any[],
    disabled?: boolean,
    accepted: String | null,
    node_name: String | null,
    handleTagSelection: any,
    loading: boolean,
    //selectionInfo: any;
    keyboardEnabled: boolean
    setKeyboardEnabled: any,
    validations:any[]
    isAnnotated:boolean

    userValidations:any[]
    setUserValidation:any,

    others?:any

}


function MultiClassSelector({
    dropdownOptions,
    accepted,
    node_name,
    disabled,
    handleTagSelection,
    loading,
    //selectionInfo,
    validations,
    keyboardEnabled,
    setKeyboardEnabled,
    isAnnotated,

    userValidations,
    setUserValidation,

    others
}: IMultiClassSelector) {

    const [open, setOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    

    const filteredList = searchTerm.length > 0 ?
    dropdownOptions.filter((option: any) => option.text.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
    : dropdownOptions


    const getClassName = (validations: any[]) => {
        if (validations && validations.length > 0 && validations[0]!==null) {
            let name = validations.map(n=>{
                    if(n === "No Tag") return "No Tag"
                    return  dropdownOptions.find(op=>op.value===n)?.text;
                
            }).join(", ");
            let newName = name.slice(0, 25);
            if(newName.length>=25) newName += "...";
            return newName;
        }
        else return "No Associated Class";
    }

    const handleDropDownChange = (e:any,option:any)=>{
        let newState:String[]=[];
        const temp = {...others.delta};
       // alert("Inside handleChange")
        //console.log(temp);
        //console.log("=== prev delta ===",temp);

        if(e.target.checked)
        {   

              //update the delta value 
              if(others.setDelta && others.delta){
                

                temp[option.value] = temp[option.value] +1;

                



            }

            //check if value is no tag
            if(option.value === "No Tag")
            {

                if(isAnnotated){

                    userValidations.forEach((node:string)=>{
    
                        temp[node] = -1;
    
                    })
                    temp["No Tag"] = 1;
                }
                
                newState = [option.value];
            }else{
                
                //if value is not No Tag then remove No Tag from the user validations array

                //reduce No Tag count if No Tag was there
                let isNoTag:boolean = userValidations[0] === "No Tag";

               // alert("no tag was removed"+isAnnotated+"_"+isNoTag);
                if(isAnnotated)
                {

                    if(isNoTag){
                        temp["No Tag"] = -1
                    }
                }



                let tempState = userValidations.filter((v:string)=>v!=="No Tag");


                
                if(tempState[0] === null){
                    newState =[option.value];
                }else{

                    newState= [...tempState,option.value];
                }
            }

          

            

        }else{
        //  alert("Inside Unchecked")

            //update the delta value 
            if(others.setDelta && others.delta){
               
               temp[option.value] = temp[option.value] - 1;
              // alert(option.value);

               



           }
            //console.log("== new delta ===",temp);
            newState = userValidations.filter((v:string)=>v!==option.value);

        }

        
       
        //console.log("=== updating delta ===",temp);
        others.setDelta(temp);
        setUserValidation(newState);
        
    }

    return (
        <div className='w-full'>
            {
                open && <div className='h-screen w-screen fixed top-0 left-0 right-0 z-[45]'
                    onClick={() => { setOpen(false); setKeyboardEnabled(true);  }}
                ></div>
            }

            {/*  Container  */}
            <div
                onClick={() => { if (!open && !disabled) { setOpen(true); setKeyboardEnabled(false) } }}
                className={`w-full border-2  py-[0.5vw] px-[0.5vw] rounded-[0.3vw]  
                relative flex items-center justify-between 
                ${isAnnotated?
                     open ?   "bg-gray-100 border-transparent" : ""
                    :"bg-gray-100"}
                ${disabled ? 
                    "bg-[#E0DDDD]  cursor-not-allowed bg-gray-100" 
                    : "cursor-pointer hover:bg-gray-100"}
                `}
            >

              
                              <p className='text-[1vw] select-none'>
                            {getClassName(userValidations)}</p>

                <DownChevrolet className='w-[0.8vw] h-[0.8vw]' />


                {
                    <div className={`absolute  z-[49] left-0 top-[100%]
                        border rounded-md bg-white w-full duration-300 origin-top
                        ${open ? "" : " scale-y-0 opacity-0"}`}>

                        <div className='flex items-center justify-center m-[0.7vw]'>
                            <div className='flex items-center justify-between rounded-full w-full border px-[0.8vw] py-[0.4vw]'>
                                <input className='focus:outline-none w-[85%] text-[0.8vw]'
                                    placeholder='Search class'
                                    value={searchTerm}
                                    onChange={(e: any) => setSearchTerm(e.target.value)}

                                />
                                {searchTerm.length ? <CrossIcon className='w-[0.9vw] h-[0.9vw] cursor-pointer'
                                    onClick={() => setSearchTerm("")} /> : <SearchIcon className='w-[1vw] h-[1vw]' />}
                            </div>

                        </div>
                        <hr />
                        <ul className='bg-white custom_scroll max-h-[10.5vw]  shadow-lg rounded-b '>
                            {
                                filteredList.length ? filteredList.map((option: any) => {
                                    return (<li 
                                            key={option.id} 
                                            className='flex '>
                                        <label className='cursor-pointer select-none w-full px-[1vw] flex items-center justify-start hover:bg-gray-200 '>
                                        <input  
                                        type={"checkbox"} 
                                        className="w-[1.2vw] h-[1.2vw] border-2"
                                        checked={userValidations.find(n=> n=== option.value) ?true: false}
                                        onChange={(e:any) => handleDropDownChange(e,option)}
                                        />

                                        <div className=' px-[1vw] w-[90%] py-[0.6vw]  cursor-pointer  focus  :bg-gray-200 text-[0.9vw] ' >
                                        {option.text}</div>
                                        </label>
                                        
                                    </li>)
                                }) : <div className='p-[0.5vw] text-[1vw] text-center '>No Results</div>

                            }

                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}

export default MultiClassSelector