import { Action } from "../../utils/global-interfaces";
import {
  STAGE2_ADD_PARAS,
  STAGE2_NODES_REQUEST,
  STAGE2_PRE_PARAS,
  STAGE2_PROGRESS_UPDATE,
  ACCEPT_PARA,
  SET_STAGE2_BATCH2_INIT,
  SET_STAGE2_BATCH3_INIT,
  STAGE2_UPDATE_REJECT_PARA,
} from "../types/stage-2-types";
import {
  INIT_REJECT_PARA,
  REJECT_PARA,
  UPDATE_PARA,
} from "../types/stage-2-types";
import { ParaDataType, StageNodeType } from "./stage1Reducer";

export interface Stage2ReducerType {
  nodes: Array<any>;
  paras: Array<any>;
  rejectedParas: Array<any>;
  nodes_progress: Array<any>;
  batch2_init: boolean;
  batch3_init: boolean;
}

let default_state: Stage2ReducerType = {
  nodes: [],
  paras: [],
  rejectedParas: [],
  nodes_progress: [],
  batch2_init: false,
  batch3_init: false,
};

let Stage2Reducer = (
  state: Stage2ReducerType = default_state,
  action: Action
) => {
  switch (action.type) {
    case STAGE2_NODES_REQUEST: {
      return {
        ...state,
        nodes: action.payload,
      };
    }
    case STAGE2_PRE_PARAS: {
      return {
        ...state,
        paras: action.payload,
      };
    }
    case STAGE2_ADD_PARAS: {
      return {
        ...state,
        paras: state.paras?.map((para: any) => {
          if (para?.node_id === action.payload.node_id) {
            return action.payload;
          }
          return para;
        }),
      };
    }
    case STAGE2_PROGRESS_UPDATE: {
      return {
        ...state,
        nodes: state.nodes.map((node: any) => {
          if (node.node_id === action.payload.node_id) {
            node.completed_percentage = action.payload.data;
            node.feedback = action.payload.feedback;
            if (action.payload.goal) {
              node.goal = action.payload.goal;
            }
          }
          return node;
        }),
      };
    }
    case ACCEPT_PARA: {
      return {
        ...state,
        paras: state.paras.map((para: any) => {
          if (para.node_id === action.payload.node_id) {
            let data = para.data.map((p: any) => {
              if (p.para_info.paraId === action.payload.paraId) {
                p.accepted = "accepted";
              }
              return p;
            });
            para.data = data;
          }
          return para;
        }),
      };
    }
    case UPDATE_PARA: {
      return {
        ...state,
        paras: state.paras.map((para: any) => {
          if (para.node_id === action.payload.node_id) {
            //
            //
            let temp = para.data.filter(
              (p: any) => p.para_info.paraId !== action.payload.paraId
            );
            //
            para.data = temp;
          }
          return para;
        }),
      };
    }
    case REJECT_PARA: {
      return {
        ...state,
        rejectedParas: [...state.rejectedParas, action.payload],
      };
    }
    case INIT_REJECT_PARA: {
      return {
        ...state,
        rejectedParas: [],
      };
    }
    case STAGE2_UPDATE_REJECT_PARA: {
      return {
        ...state,
        rejected_paras: state.rejectedParas.map((para: any) => {
          if (para.data.paraId === action.payload.paraId) {
            para.nextPredicted.selected = action.payload.selected;
            para.nextPredicted.issueId = action.payload.issueId;
          }
          return para;
        }),
      };
    }
    case SET_STAGE2_BATCH2_INIT: {
      return {
        ...state,
        batch2_init: action.payload.action,
      };
    }
    case SET_STAGE2_BATCH3_INIT: {
      return {
        ...state,
        batch3_init: action.payload.action,
      };
    }
    default: {
      return state;
    }
  }
};

export default Stage2Reducer;
