import React,{useEffect} from "react";
import AppLayout from "../components/layouts/AppLayout";

import ListView from '../components/listview';

import PageLayout from '../components/layouts/PageLayout';
import useWorkflowStore from '../store/useWorkflowStore';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import useKeyboard from "../hooks/useKeyboard";
import LoadingScreen from "../components/loading-screen";
import template from "../template";


interface MasterlistProps {}
let Masterlist: React.FC<MasterlistProps> = () => {


    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const workflowStore =useWorkflowStore();
    let workflowId = workflow?.workflowId;
    const project =useWorkflowStore(state=>state.currentProject);
   // console.log("Workflow type",project?.workflowType);
    const navigate = useNavigate();

    //Loading variables
    const stage1Progress = workflow?.alpdsInfo?.percentageStatus?.stage1Progress || 0;
    const stage2Progress = workflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress;
    const nerStage1Progress =  workflow?.alpdsInfo?.percentageStatus?.nerStage1Progress || 0;
    const validation =project? template[project?.projectType || ""][project?.workflowType || ""]?.validation || undefined:undefined;
    const currentBatchProgress = stage1Progress?stage1Progress
                                    :nerStage1Progress?nerStage1Progress.currentBatchProgress
                                    :0;

   // console.log("=== got percentage stage1 ===",currentBatchProgress);
    //--------------------------------------------------------------------------------------------
    //when user does not have validation permission or this page is not supported in this workflow
    //--------------------------------------------------------------------------------------------
    useEffect(()=>{
       
        // if(project?.workflowType && project?.workflowType!== "annotation_workflow")
        // {
        //     toast("Listview is not supported in complete workflow",{type:toast.TYPE.WARNING});
        //     navigate("/workflow?workflowId="+workflowId);

        // }
        if(workflowId && !workflowStore.userProject?.VALIDATION_ACCESS)
        {
            toast("You do not have the authorization to perform validations. Please contact your project creator.",{type:toast.TYPE.WARNING});
            navigate("/workflow?workflowId="+workflowId);
        }

    },[workflowStore.currentWorkflow?.workflowId])


    //--------------------------------------------------------------------------------------------
    //check for stage1Progress
    //--------------------------------------------------------------------------------------------
    if( currentBatchProgress <100 )
    {
        return (
            <AppLayout>
                <PageLayout pageSubHeading="Validation" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/active_validation.svg">
                <LoadingScreen compleated={stage1Progress || nerStage1Progress.currentBatchProgress || 0} msg={"Please wait while we annotate the paragraphs for you!"} />
                </PageLayout>
            </AppLayout>
        );
        
    }

    // //--------------------------------------------------------------------------------------------
    // //check for stage2Progress
    // //--------------------------------------------------------------------------------------------
    // if(stage2Progress && stage2Progress <100)
    // {
    //     return (
    //         <AppLayout>
    //             <PageLayout pageSubHeading="Validation" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/active_validation.svg">
    //             <LoadingScreen compleated={stage2Progress} msg={"Please wait while we annotate the paragraphs for you!"} />
    //             </PageLayout>
    //         </AppLayout>
    //     );
    // }


    return (
        <AppLayout>
            <PageLayout pageSubHeading="Validation" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/active_validation.svg">
           {/* <ListView /> */}
            {validation!== undefined?React.createElement(validation.component) :null    }
            </PageLayout>
        </AppLayout>
    );
};

export default Masterlist;
