export let STAGE1_NODES_REQUEST = "STAGE1_NODES_REQUEST"
export let STAGE1_PRE_PARAS = "STAGE1_PRE_PARAS"
export let STAGE1_ADD_PARAS = "STAGE1_ADD_PARAS"
export let STAGE1_PROGRESS_UPDATE = "STAGE1_PROGRESS_UPDATE"
export let STAGE1_ADD_REJECT_PARAS = "STAGE1_ADD_REJECT_PARAS"
export let INIT_REJECT_PARA = "INIT_REJECT_PARA"
export let REJECT_PARA = "REJECT_PARA"
export let UPDATE_REJECT_PARA = "UPDATE_REJECT_PARA"
export let ACCEPT_PARA = "ACCEPT_PARA"
export let UPDATE_PARA = "UPDATE_PARA"
export let UPDATE_NODE_FEEDBACK="UPDATE_NODE_FEEDBACK"