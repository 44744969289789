import React, { useEffect, useRef, useState } from 'react'
import Para from '../shared/Para'
// import MultiSelector from './MultiSelector'

import {ReactComponent as LoadingWhite} from '../../../assets/loading_white.svg';
import useWorkflowStore from '../../../store/useWorkflowStore';

import {v4 as uuid} from 'uuid';

const AddNewAnswerButton =({text,loading,
                      onClick,disabled,
                      data,
                      disabledTitle,styles}:any)=>{

    


    return (<button
      disabled={disabled}
      title={disabled && disabledTitle}
      onClick={!disabled ? onClick:()=>{}}
      className={`uppercase duration-200  w-full
            text-primaryBlue text-[0.9vw] 
             h-[2.5vw]  text-center rounded-md border-[0.1vw] hover:scale-105 
             disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
            border-[#1BA94C]  hover:drop-shadow-md 
            ${styles}  `}
    >
      {loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                      :  <div className='flex items-center justify-center space-x-[0.5vw]'>
                      <span className='text-[#1BA94C]'>Add</span>
                      </div>}
    </button>)
  
  }


function AddNewAnswer({active,index,answers,saveAnswer,rankSet}:any) {

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const workflow = useWorkflowStore(state=>state.currentWorkflow);

 

  //place inputs here
  const [text,setText] = useState<string>("");
  const [values,setValues] = useState<any>({});



  useEffect(()=>{
      textAreaRef.current?.focus();
  },[]);




  useEffect(()=>{
    console.log("Values changed",values);
  },[values])

  const resetStates = ()=>{
      setValues({});
      setText("");
  }



  return (
    <div 
    className={`min-h-[10vw]
                      border rounded-[0.2vw]
                      bg-white shadow-md
                      p-[1vw] space-x-[1vw]
                      ${active?"border border-primaryBlue":""}
                      `}>
        
        <div className='w-full'>
        <textarea 
        data-testid="new-answer-textarea"
        ref={textAreaRef}
        placeholder='Type your answer here...'
        value={text}
        onChange={(e)=>setText(e.target.value)}
        className='w-full 
                    text-[0.9vw] min-h-[12vw]
                    max-h-[20vw] 
                    focus:outline-none'
        />
        </div>

        <div className=' space-y-[1vw] flex flex-col items-end my-auto'>
         
          <AddNewAnswerButton 
          styles={"w-[20vw]"}
          onClick={()=>{
            saveAnswer({
              text:text,
              responseId:uuid(),
              ...values
            },resetStates);
            
            }}/>
        </div>


    </div>
  )
}

export default AddNewAnswer