import React, { useEffect, useState ,useMemo} from "react";
import ProjectSearchComp from "../../components/controls/ProjectSearchCom";
import AppLayout from "../../components/layouts/AppLayout";
import useAuthStore from "../../store/authStore";
import useProjectStore from "../../store/projectStore";
import useCompanyUsersStore from "../../store/useCompanyUsersStore";
import axios from "../../utils/Axios";
import { toast } from "react-toastify";
import { Divider } from "antd";
import { useNavigate } from 'react-router-dom';
import { AiFillCaretDown } from 'react-icons/ai';
import moment from 'moment';
import AccessControl from '../../components/AccessControl/AccessControl';


import { ReactComponent as LoadingCircle } from '../../assets/loading_circle.svg';
import { ReactComponent as EmptyFolder } from '../../assets/empty_folder.svg';

interface filter {
    completed: boolean,
    inProgress: boolean,
    annotation: boolean,
    prediction: boolean,
    createdByMe: boolean,
    multiclass: boolean,
    multilabel:boolean,
    ner:boolean
}


const ProjectCard = ( {project, projectType, workflowType,navigate}:any) => {

    
    let stages:any[] = project?.workflow?.stages;
    let[ activeStage,setActiveStage]= useState<string>();
    // let activeStage= useMemo(()=>{
    useEffect(()=>{
        let active:any= stages.length && [...stages].reverse().find((stage:any)=>stage.active === true)

    
        
        switch(active?.name){
            case "upload":
                setActiveStage("Upload")
                break;
                
            case "masterlist":
                setActiveStage("Masterlist")
                break;
            case "validation-1":
                setActiveStage("Stage 1")
                break;
            case "validation-2":
                setActiveStage("Stage 2")
                break;
            case "validation":
                setActiveStage("Validation")
                break;
            case "train":

                
                if(active?.inModelTuning){
                    setActiveStage("Model Tuning")
                    

                }else if(active?.inModelSelection){
                    setActiveStage("Model Selection")
                    
                }else{
                    setActiveStage("Train")
                }
                break;
                
                
            case "export":
                setActiveStage("Export")
                break;
    
            default:
                setActiveStage("upload")
        }
    },[project.projectInfo.projectId])    
   
    const getProjectName = (name:string | undefined)=>{
        if(!name) return;
        if(name?.includes("(")) return name?.split("(")[1].split(")")[0];
        if(name?.split(" ")[0]!=="") return name?.split(" ")[0];



        return name;
    }
    //     }
    // },[project.projectInfo.projectId]);
    if (!stages) {
        return <></>;
    }
   

    

   
    return (
        <div  onClick={() => navigate(`/workflow?workflowId=${project.lastAccessedWorkflowId}`)} className='flex w-full hover:shadow-md h-[6vw] bg-white px-4 mb-2 cursor-pointer'>

            <div className=" flex-[1.5] font-medium  text-[1vw] uppercase flex justify-start items-center">
                <p className='text-ellipsis overflow-hidden  max-h-[2.6vw]  '>
                    {project?.projectInfo?.name}
                </p>

            </div>

            <div className="flex-[1] text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                <span> {project?.workflow?.workflowName}  </span>

            </div>

            <div className="flex-[1] space-x-1 text-[#999999] text-center  text-[0.9vw] uppercase flex justify-center items-center">
                <span>{workflowType?.name}</span>

            </div>

            <div className="flex-[1] space-x-1 text-[#999999] text-center text-[1vw] uppercase flex justify-center items-center">
                <span>  {getProjectName(projectType?.name)}</span>

            </div>

            <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                <div className="flex flex-col w-[70%] text-center">
                    <p className="text-[0.9vw]">{activeStage}</p>
                    <div className="flex space-x-1 w-full">
                        {
                            project?.workflow?.stages && project?.workflow?.stages.map((stage: any) => (
                                <div 
                                key={stage?.name}
                                className={`flex-1 ${stage.active ? "bg-[#1BA94C]" : "bg-[#F59F00]"} h-1`}></div>

                            ))
                        }
                    </div>
                </div>

            </div>

            <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                <span>{moment(project?.projectInfo?.updatedAt).fromNow()}</span>

            </div>


        </div>


    )
}


let ProjectDashboard: React.FC = () => {
    let projectStore = useProjectStore();
    let { setCompanyUsers } = useCompanyUsersStore();
    let { userDetails,isAuthenticated } = useAuthStore();
    const navigate = useNavigate();


    const [addProjectVisisble, setAddProjectVisible] = useState(false);
    const [workflowTypes, setWorkflowTypes] = useState<any[]>([]);
    const [projectTypes, setProjectTypes] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const INITIAL_STATE: filter = {
        completed: false,
        inProgress: false,
        annotation: false,
        prediction: false,
        createdByMe: false,
        multiclass:false,
        multilabel:false,
        ner:false

    }

    const [filters, setFilters] = useState<filter>(INITIAL_STATE);

    const [filteredList, setFilteredList] = useState<Array<any>>([]);

    const annotation = "annotation_workflow";
    const prediction = "annotation_prediction_workflow";

    const multiclass = "multiclass_classification";
    const multilabel = "multilabel_classification";
    const ner = "named_entity_recognition";



    let fetchProjects = async () => {
        try {
            let resp = await axios.get(`/project/all`);
            if (resp.data.result) {
                //console.log("Projects",resp.data)
                let projects: Array<any> = resp.data.data;
                //----make the add project visible if there is no project already created by the user---
                if (projects.length === 0) {
                    setAddProjectVisible(false);
                }
                //------------------------------------------------------------------------------------
                projects.sort((p1, p2) => p2.projectInfo.createdAt > p1.projectInfo.createdAt ? 1 : -1);
                projectStore.setProjects(projects);
                setFilteredList(projects);

            } else {
                toast("There was an error while fetching projects. Please reload the page.", {
                    type: "error",
                });
            }
        } catch (err) {
            toast("There was an error while fetching projects. Please reload the page.", {
                type: "error",
            });
        }
        finally{
            setLoading(false);
        }
    };

   

    const fetchProjectTypes = async () => {
        try {

            let res = await axios.get("/project/gettypes");

            if (res && res.data.result) {
                // console.log("=== Got Project Types ===",res.data.data);
                setProjectTypes(res.data.data);
                setWorkflowTypes(()=>{
                    const temp:any[] = [];

                    res.data.data.forEach((projectType:any)=>{
                            projectType.workflows.forEach((workflowType:any)=>{
                                if(!temp.find((w:any)=>w.id === workflowType.id)){
                                    temp.push(workflowType);
                                }
                            })
                    })

                   // console.log("=== workflowTypes ===",temp);



                    return temp;
                })
            }

        } catch {
            toast("Could Not fetch project types. Please reload the page.", { type: toast.TYPE.ERROR });
        }
    }


    //let's fetch all the projects
    useEffect(() => {
        if(!isAuthenticated) return;
        //add loading state here
       // setLoading(true);
      
           fetchProjectTypes();
          // fetchWorkflowTypes();
           fetchProjects();

        //add loading state here
    }, [isAuthenticated]);


    //filter projects when filters are clicked
    useEffect(() => {
        //console.log("=== Filters were changed ===", filters);

        if (!projectStore.projects.length) return;

        let list: Array<any> = projectStore
        .projects
        .filter((p:any)=>{

            if (filters.multiclass || filters.multilabel || filters.ner) {
                return (filters.multiclass && p.projectInfo.projectType === multiclass)
                    || (filters.multilabel && p.projectInfo.projectType === multilabel)
                    || (filters.ner && p.projectInfo.projectType === ner)
            }
            else {
                return true;
            }

        })
        .filter((p: any) => {
            if (filters.annotation || filters.prediction) {
                return (filters.annotation && p.projectInfo.workflowType === annotation)
                    || (filters.prediction && p.projectInfo.workflowType === prediction)
            }
            else {
                return true;
            }
        })
            .filter((p: any) => {
                if (filters.completed || filters.inProgress) {
                    return (filters.completed && p.workflow.stages[p.workflow.stages.length - 1].active === true)
                        || (filters.inProgress && p.workflow.stages[p.workflow.stages.length - 1].active === false);
                }
                else {
                    return true;
                }

            })
            .filter((p: any) => {

                if (filters.createdByMe) {

                    return p.projectInfo.createdBy === userDetails?.userId;
                }
                else {

                    return true;
                }


            });



        setFilteredList(list);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })


    }, [filters]);




  



    const DisabledButton = () => {
        return (
            <button
                className={`flex items-center bg-gray-500 px-[0.9vw] py-[0.5vw] rounded-[0.4vw]`}
            >
                <div className="text-white text-[1vw] ">CREATE NEW PROJECT</div>
                <img src="/images/add.svg" alt="add" className="w-[1.3vw] h-[1.3vw] ml-[0.8vw]" />
            </button>
        )
    }

    return (
        <AppLayout>

            <div className="py-[1vw]">
                <div className="text-[1.5vw] font-semibold text-gray-400">Welcome back!</div>
                <div className="text-[1.5vw] font-extrabold">{userDetails?.firstName}</div>
            </div>
            <ProjectSearchComp />
            <Divider />


            <div className="flex justify-between items-center px-[1vw]">

                <div className="flex items-center justify-start w-[80%] h-[5vw] space-x-2">
                    <div className="text-[1vw] text-[#999999]">Filter By</div>

                    <div className="flex space-x-2">
                        <div onClick={() => setFilters({ ...filters, completed: !filters.completed })}

                            className={`rounded-full text-[0.8vw]
                         ${filters.completed ? " text-white bg-[#11256D] border-2 border-[#11256D]" : "text-[#777777] border-2"}
                         hover1:bg-[#11256D] hover1:text-white duration-700 
                         hover:border-[#11256D] 
                          border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                            Completed
                        </div>


                        <div
                            onClick={() => setFilters({ ...filters, inProgress: !filters.inProgress })}
                            className={`rounded-full text-[0.8vw]
                        ${filters.inProgress ? " text-white bg-[#11256D] border-[#11256D]" : "text-[#777777]"}
                         duration-700 border
                        hover:border-[#11256D]
                        border-2 px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                            In Progress
                        </div>
                    </div>
                    <div className="border-l-2 h-[2vw]" ></div>


                    {
                    /* //---------------------------------------------------------------------------------
                        Multi Class and Multi Label
                       //--------------------------------------------------------------------------------- 
                    */}
                    <div className="flex space-x-2">

                        <div onClick={() => setFilters({ ...filters, multiclass: !filters.multiclass })}
                            className={`rounded-full text-[0.8vw]  px-[0.8vw] py-[0.3vw] 
                                    border hover1:bg-[#11256D] border-2 hover:border-[#11256D] hover1:text-white duration-700
                                ${filters.multiclass ? "bg-[#11256D] text-white border-[#11256D]" : "text-[#777777]"}  cursor-pointer`}>
                            Multiclass
                        </div>



                        <div onClick={() => setFilters({ ...filters, multilabel: !filters.multilabel })}
                            className={`rounded-full text-[0.8vw]
                     ${filters.multilabel ? "bg-[#11256D] text-white border-[#11256D]" : "text-[#777777]"}
                     hover1:bg-[#11256D] hover1:text-white
                     border-2 hover:border-[#11256D]
                     duration-700
                     border px-[0.8vw] py-[0.3vw] cursor-pointer`}>

                           Multilabel
                        </div>
                        <div onClick={() => setFilters({ ...filters, ner: !filters.ner})}
                            className={`rounded-full text-[0.8vw]  px-[0.8vw] py-[0.3vw] 
                                    border hover1:bg-[#11256D] border-2 hover:border-[#11256D] hover1:text-white duration-700
                                ${filters.ner ? "bg-[#11256D] text-white border-[#11256D]" : "text-[#777777]"}  cursor-pointer`}>
                            NER
                        </div>

                    </div>

                    <div className="flex space-x-2">
                    <div className="border-l-2 h-[2vw]" ></div>

<div onClick={() => setFilters({ ...filters, annotation: !filters.annotation })}
    className={`rounded-full text-[0.8vw]  px-[0.8vw] py-[0.3vw] 
            border hover1:bg-[#11256D] border-2 hover:border-[#11256D] hover1:text-white duration-700
        ${filters.annotation ? "bg-[#11256D] text-white border-[#11256D]" : "text-[#777777]"}  cursor-pointer`}>
    Annotation Flow
</div>



<div onClick={() => setFilters({ ...filters, prediction: !filters.prediction })}
    className={`rounded-full text-[0.8vw]
${filters.prediction ? "bg-[#11256D] text-white border-[#11256D]" : "text-[#777777]"}
hover1:bg-[#11256D] hover1:text-white
border-2 hover:border-[#11256D]
duration-700
border px-[0.8vw] py-[0.3vw] cursor-pointer`}>

    Complete Flow
</div>

</div>
                    

                    <div className="border-l-2 h-[2vw]" ></div>

                    <div onClick={() => setFilters({ ...filters, createdByMe: !filters.createdByMe })}
                        className={`rounded-full text-[0.8vw] 
                        ${filters.createdByMe ? "bg-[#11256D] text-white border-[#11256D]" : "text-[#777777]"}
                        hover1:bg-[#11256D] hover1:text-white
                        border-2 hover:border-[#11256D]
                        duration-700
                      border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                        Created By Me</div>

                    <div className="border-l-2 h-[2vw]" ></div>

                    <div onClick={() => setFilters(INITIAL_STATE)}
                        className={`rounded-full text-[0.8vw] 
                       hover:bg-red-400 hover:text-white  duration-700
                       border-2 text-[#777777] hover:border-red-400
                      border px-[0.8vw] py-[0.3vw]  cursor-pointer`}>
                        Clear All</div>

                    {/* Will Add Project Type Filters Later */}
                    {/* <div className="rounded-full text-[0.8vw] text-white border px-[0.8vw] py-[0.3vw] bg-[#11256D] cursor-pointer">MultiClass</div> */}
                </div>

                <div>
                    <AccessControl RestrictedComp={DisabledButton} RequestedPerm="canCreateProjects" SecondRequestedPerm="canCreateWorkflows">
                        <button
                            onClick={() => navigate("/project/create")}
                            className={`flex items-center bg-primaryBlue px-[0.9vw] py-[0.5vw] rounded-[0.4vw] duration-200 hover:scale-105`}
                        >
                            <div className="text-white text-[1vw]">CREATE NEW PROJECT</div>
                            <img src="/images/add.svg" alt="add" className="w-[1.3vw] h-[1.3vw] ml-[0.8vw]" />
                        </button>
                    </AccessControl>
                </div>
            </div>


            {/* Header */}
            <div className='flex w-full  h-[3vw] px-4'>

                <div className="flex-[1.5] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-start items-center">
                    <span className="uppercase">Name</span>
                    {/* <AiFillCaretDown color={"#999999"} /> */}
                </div>

                <div className=" flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                    <span className="uppercase">Version</span>
                    {/* <AiFillCaretDown color={"#999999"} /> */}
                </div>

                <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                    <span className="uppercase">Workflow</span>
                    {/* <AiFillCaretDown color={"#999999"} /> */}
                </div>

                <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                    <span className="uppercase">Project</span>
                    {/* <AiFillCaretDown color={"#999999"} /> */}
                </div>

                <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                    <span className="uppercase">Stage</span>
                    {/* <AiFillCaretDown color={"#999999"} /> */}
                </div>

                <div className="flex-[1] space-x-1 text-[#999999]  text-[1vw] uppercase flex justify-center items-center">
                    <span className="uppercase">Last Updated</span>
                    {/* <AiFillCaretDown color={"#999999"} /> */}
                </div>


            </div>


            {/* Data */}
            <div className="mt-[1vw] w-full">

                {
                    loading && <div className="h-[20vw] flex items-center justify-center">
                        {/* <img src="/images/loading_circle.svg" className="w-[12vw] animate animate-spin" alt="loading" /> */}
                        <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />
                    </div>
                }

                {
                    !loading && !filteredList.length
                    && <div className="h-[20vw] flex flex-col items-center justify-center">
                        <EmptyFolder />
                        <div className="uppercase text-[1.5vw] font-semibold text-[#777777]">No Projects Found</div>
                        {!projectStore.projects.length && <p className="text-center mt-4 text-[#777777] text-[1vw]">
                            Looks like you have not created a project yet.<br />
                            The project information will appear here as soon as you create your first project.
                        </p>}
                    </div>

                }

                {/* {!loading && projectStore.projects.length > 0 && */}
                {!loading && filteredList.length > 0 &&
                    //projectStore.projects.map((project: any) =>
                    filteredList.map((project: any) =>


                        <ProjectCard
                        key={project?.projectInfo?.projectId}
                            project={project} 
                            projectType={projectTypes?.find((p: any) => p.id === project?.projectInfo?.projectType)} 
                            workflowType={workflowTypes?.find((w: any) => w.id === project?.projectInfo?.workflowType)}
                            navigate= {navigate} />
                    )
                }






            </div>

        </AppLayout>
    );
};

export default ProjectDashboard;
