import React, { useEffect, useState } from 'react'
import Para from '../../shared/Para'
import useWorkflowStore from '../../../../store/useWorkflowStore';

//Icons
import {ReactComponent as UserAddedIcon} from '../../../../assets/userAddIcon.svg';
import MultiSelector from '../MultiSelector';
import ConfidenceScore from '../../shared/ConfidenceScore';



function Validations({active,setActive,index,
  answers,answer,readOnly,
  validation,
  canDelete,
  rankSet,
  setValidation}:any) {

  const [isMounted,setIsMounted]= useState<boolean>(false);
  const [rankOptions,setRankOptions] = useState<any[]>([]);

  const workflow = useWorkflowStore(state=>state.currentWorkflow);
  const inputs = workflow?.listviewInfo?.inputConfigs;

  const autoValidationScore = answer?.autoValidationScore || null;
  //console.log("=== validation ===",validation);

  useEffect(()=>{
   // console.log("=== Source ===>",answer.source);
    setIsMounted(true);
    return ()=>{
      setIsMounted(false);
    }
  },[])

  useEffect(()=>{
    const temp =[
      {
        text:1,
        id:1
      }
    ]

    answers?.forEach((ans:any,index:number) => {
        index!==0 && temp.push({
          text:index+1,
          id:index+1
        })
    });

    setRankOptions(temp);


  },[answers]);

  return (      
    <div 
    data-testid="validation-div"
    onClick={()=>setActive(index)}
    className={`min-h-[10vw]
    relative
                      
                      border rounded-[0.2vw]
                      bg-white hover:shadow-md
                      p-[1vw]
                      space-x-[1vw]
                      duration-500 border
                      ${isMounted?"":"-translate-y-[2vw]"}
                      ${active?" border-primaryBlue":""}
                      `}>
         {answer.source==="user" &&  <div className='absolute top-[-0.55vw] left-[-0.2vw] z-[10]'>
                     <UserAddedIcon 
                     className='w-[4.5vw] h-[4.5vw]'
                     />
          </div>}
          {answer.source !== "user" &&
        <div className='flex justify-end mb-[0.5vw] px-[0.2vw]'>

          <ConfidenceScore 
          autoValidationScore={autoValidationScore}/>
        </div>
      }

        <div className={`flex items-start space-x-[2vw] 
                    ${answer.source==="user"?"pt-[1vw]":""}`}>

        <div className={`w-[72%] `}>
        <Para
        active={active}
        text={answer?.text} />
        </div>

        <div className='w-[25%] space-y-[1vw] flex flex-col items-center '>
        {
            inputs && inputs.map((i:any)=>{
              return <MultiSelector 
              testId={'multiselector-'+i.paramName}
              readOnly={readOnly}
              parameter={i}
              value={validation && validation[i.paramName] }
              onChange={(value:any)=>{
                //console.log('=== calling set validation ===',{...validation,[i.paramName]:value})
                if(setValidation) setValidation({...validation,[i.paramName]:value});
              }}
              options={
                i.type==="rank-dropdown"?rankOptions:
                i.values}

              rankSet={i.type==='rank-dropdown'?rankSet:null}
              />
            })
          }
        
        </div>
        </div>


    </div>

   
  )
}

export default Validations