import create from "zustand";
import { persist, devtools } from "zustand/middleware";
//import { ProjectStoreType } from "../types/store/ProjectStoreTypes";

let useProjectStore = create<any>(
  //  persist(
        (set, _get) => ({
            projects: [],
            setProjects: (projects:any) => set({ projects }),
            addProject: (project:any) =>
                set((state:any) => ({
                    projects: [project, ...state.projects],
                })),
        }),
    //    {
    //        name: "project_store",
    //    }
    //)
);

export default useProjectStore;
