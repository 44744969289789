import React, { useState, useEffect } from "react";
import AuthContainer from "../../components/auth/AuthContainer";
import LoginForm from "../../components/auth/LoginForm";
import useAuthStore from "../../store/authStore";
import axios from "../../utils/Axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCompanyUsersStore from "../../store/useCompanyUsersStore";

interface Values {
    email: string;
    password: string;
    recaptchaToken: string;
}

let SignIn: React.FC = () => {
    let authStore = useAuthStore();
    let navigator = useNavigate();
    const { setCompanyUsers } = useCompanyUsersStore();
    let [loginError, setLoginError] = useState({ show: false, msg: "" });
    let [signupMessage, setSignupMessage] = useState({ show: false, msg: "" });

    let handleFormSubmit = async (values: Values) => {
        //making server call for login
        console.log("=== handleForm was called ===")
        try {
            authStore.setIsProcessComplete(false);
            //console.log("Values",{...values})
            let loginRes = await axios.post("/user/signin", {
                ...values
            })

            if (loginRes.data.result) {

                // console.log("After call", loginRes.data);

                // if (loginRes.data.data.user.status === "WAITING") {
                //     navigator("/billing", {
                //         state: { 
                //             userId: loginRes.data.data.user.userId,
                //             isBillingAccount: loginRes.data.data.user.isBillingAccount,
                //             associatedBillingAccounts: loginRes.data.data.user.associatedBillingAccounts
                //         }
                //     });
                // } else {

                    // === Setting user details ===
                    authStore.setIsAuthenticated(true);
                    authStore.setUserDetails({
                        userId:loginRes.data.data.user.userId,
                        firstName:loginRes.data.data.user.firstName,
                        lastName:loginRes.data.data.user.lastName,
                        company:loginRes.data.data.user.company,
                        email:loginRes.data.data.user.email,
                        role:loginRes.data.data.user.role,
                        isBillingAccount:loginRes.data.data.user.isBillingAccount,
                        associatedBillingAccounts:loginRes.data.data.user.associatedBillingAccounts,
                    });
                    authStore.setToken(loginRes.data.data.token);
                    localStorage.setItem("token",loginRes.data.data.token);
                    // console.log(loginRes.data.data)
                    // console.log("authenticated");

                    let accountInfo = await axios.post("/billing/getAccountDetails", {
                        userId: loginRes.data.data.user.userId,
                        isBillingAccount: loginRes.data.data.user.isBillingAccount,
                        associatedBillingAccounts: loginRes.data.data.user.associatedBillingAccounts
                    });
                    // console.log("accountss", accountInfo)

                    if (accountInfo.data.result) {

                        if (accountInfo.data.data.activePlan == "The company does not have an active plan") {
                            // navigator("/");
                            authStore.setIsProcessComplete(true);
                        } else {
                            // === Setting billing account details ===
                            
                            authStore.setBillingDetails(accountInfo.data.data?.billingDetails);
                            authStore.setActivePlan(accountInfo.data.data?.activePlan);
                            authStore.setUsage(accountInfo.data.data?.usage);
                            authStore.setIsProcessComplete(true);
                            
                            // === Setting company users ===
                            let usersData = await axios.get(`/user/companyuser`);
                            if (usersData && usersData.data.result) {
                                console.log("setting");
                                setCompanyUsers(usersData.data.data);
                                // navigator("/");
                            } else {
                                setLoginError({
                                    show: true,
                                    msg: accountInfo.data.message || "Could not fetch company users",
                                });
                            }
                            
                        }

                    } else {
                        if (accountInfo?.data?.data === "Could not fetch billing account") {
                            console.log(accountInfo.data);
                        }

                        authStore.setIsProcessComplete(true);

                        setLoginError({
                            show: true,
                            msg: accountInfo.data.message || "Could not fetch account details",
                        });
                    }
                // }

            } else {
                authStore.setIsProcessComplete(true);

                setLoginError({
                    show: true,
                    msg: loginRes.data.message || "There was some issue",
                });
            }
        } catch (err: any) {
            authStore.setIsProcessComplete(true);
            console.log(err.response);
            setLoginError({
                show: true,
                msg: err.response.data.data.message || "Some error occurred while login, please check the credentials.",
            });
        } finally {
            // authStore.setIsProcessComplete(true);
            // navigator("/");
        }
    };

    let [searchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.get("firstName")) {
            setSignupMessage({
                show: true, 
                msg: "" + searchParams.get("firstName")
            })
        }
    }, [])

    return (
        <AuthContainer>
            <LoginForm handleFormSubmit={handleFormSubmit} loginError={loginError} setLoginError={setLoginError} signupMessage={signupMessage} setSignupMessage={setSignupMessage} />
        </AuthContainer>
    );
};

export default SignIn;
