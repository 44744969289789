import React from 'react';

import {ReactComponent as LoadingIcon} from '../../assets/loading_circle.svg'

type Props = {}

function LLMLoading({}: Props) {
  return (
    <div className='w-full h-[38vw] space-y-[5vw] flex flex-col items-center justify-center'>
        
        <h1 className='text-[2vw] w-1/2 text-center text-[#9A9A9A]'>
            Please wait until the model is fine-tuned and uploaded in the system
            </h1>

        <LoadingIcon
        className='w-[24vw] animate-spin'
        />
        
        </div>
  )
}

export default LLMLoading