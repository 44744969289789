import React, { useEffect, useRef, useState } from 'react'
import {ChromePicker} from 'react-color';


type colorPickerProps ={
    color:any,
    setColor:(color:string)=>void
}


export default function ColorPicker({color,setColor}:colorPickerProps) {

    const [isVisible,setIsVisible] = useState<boolean>();
    const visibleRef = useRef(isVisible);
    const divRef= useRef<HTMLDivElement>(null);
    //const [color,setColor] = useState<any>();

    const handleVisibleChange=(value:boolean)=>{
        setIsVisible(value);
        visibleRef.current = value;
    }

    // useEffect(()=>{
    //     const handleMouseClick =(e:any)=>{
    
    //         if(divRef.current && !divRef.current.contains(e.target)){
    //                 visibleRef.current=false;
    //         }
    
    
    //     };

       
    //   document.addEventListener("mousedown",handleMouseClick)


    // return ()=>document.removeEventListener("mousedown",handleMouseClick);



    // },[])



  return (
    <div
    className=' flex items-center w-full mr-[0.1vw]'
    ref={divRef}
    >
        {
            visibleRef.current?
            <div className='absolute'>
                {isVisible && <div 
                onClick={()=>handleVisibleChange(false)}
                className=' fixed top-0 left-0 h-screen w-screen'>

                </div>}
                <ChromePicker
                disableAlpha
               // onChangeComplete={(value)=>setColor(value.hex)}
                onChange={(value)=>setColor(value.hex)}
                color={color}
                />
                </div>
            :<div 
            className='h-[1.2vw] w-[1.2vw]'
            style={{
                backgroundColor:color
            }}        
            onClick={()=>handleVisibleChange(true)}></div>
        }
        
        </div>
  )
}
