import React, { useEffect, useState } from "react";
import axios from "../../utils/Axios";
import { NavLink } from 'react-router-dom';
import { AiFillCaretDown } from "react-icons/ai";
import useAuthStore from "../../store/authStore";
import { ReactComponent as EmptyFolder } from '../../assets/empty_folder.svg';
import { ReactComponent as LoadingCircle } from '../../assets/loading_circle.svg';
import PlanCard from './PlanCard';
import moment from "moment";

import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from './InvoicePDF';

interface SortType {
  key: string,
  order: number,
}

const BillingInfo: React.FC = () => {
  const [sort, setSort] = useState<SortType | undefined>();
  const [loading, setLoading] = useState(true);
  const [filteredList, setFilteredList] = useState<Array<any>>([]);
  const [dayDiff, setDayDiff] = useState<number>();
  const authStore = useAuthStore();
  const activePlan = authStore.activePlan;

  useEffect(() => {
    let fetchPlans = async () => {
      setLoading(true);

      console.log(authStore);

      await axios.post("/billing/getAccountPlans", { billingAccountId: authStore.billingDetails?.billingAccountId })
        .then((res) => {

          if (res.data.result) {
            console.log(res.data);
            let tempArr: any = [];

            res.data.data.forEach((plan: any, index: number) => {
              if (plan.status !== "INACTIVE" && plan.payment.length > 0) {
                let payment = plan.payment[0]; // Initial payment

                let obj = {
                  invoiceName: "Invoice_" + moment(payment.date).format('MMM_YYYY'),
                  billingDate: payment.date,
                  amount: (payment.currency === "INR" ? "₹" : "$") + payment.amount,
                  plan: plan.planName,
                  status: plan.status,
                  // download: (
                  //   <PDFDownloadLink 
                  //     document={
                  //       <InvoicePDF 
                  //         title={"Invoice_" + moment(plan.billingDate).format('MMM_YYYY')}
                  //         name="John Doe"
                  //         email="johndoe@example.com"
                  //         company="Aviso"
                  //       />} 
                  //     fileName="invoice"
                  //     >
                  //     Download
                  //   </PDFDownloadLink>
                  // )
                }
                tempArr.push(obj);
              }
            })

            setFilteredList(tempArr);
            setSort({ key: "billingDate", order: -1 });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        })
    }

    if (activePlan) {
      let diff = Math.ceil((new Date(activePlan.expiryDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
      setDayDiff(diff);
    }
     
    fetchPlans();
  }, []);

  const handleSort = (newKey: string) => {
    if (sort && sort.key === newKey) {
      let newOrder = sort?.order * -1;
      setSort({ key: newKey, order: newOrder });
    }
    else {
      setSort({ key: newKey, order: -1 });
    }
  }

  //On Sort Change
  useEffect(() => {
    if (sort && sort.key === "amount") {
      let temp = [...filteredList].sort((a: any, b: any) => 
         parseInt(a[sort.key].substring(1)) > parseInt(b[sort.key].substring(1)) ? sort.order : -sort.order
      );
        
      setFilteredList(temp);

    } else if (sort) {
      let temp = [...filteredList].sort((a: any, b: any) => 
        a[sort.key] > b[sort.key] ? sort.order : -sort.order
      );
        
      setFilteredList(temp);
    }
  }, [sort]);

  return (
    <div className="w-full flex ">

      <div className="flex w-full flex-col space-y-5">

        <div className="grid grid-cols-3 gap-6">
          <div className="flex flex-col justify-between h-[32vh] px-6 py-4 bg-white rounded-[0.3vw] drop-shadow-[0_3px_5px_rgba(0,0,0,0.10)]">
            <div className="text-[#999999] text-[0.95vw]">
              Current Active Subscription
            </div>
            <div>
              <div className="text-[1.6vw] font-semibold">
                {activePlan?.planName}
              </div>
              <div className="mt-[0.5vh] text-[1vw] text-[#999999]">
                Activated On {moment(activePlan?.activationDate).format('D MMM YYYY')}
              </div>
                { 
                  dayDiff && 
                  (dayDiff === 1 ?
                    (<div className="mt-[1vh] text-[#e90707] text-[0.9vw]">{dayDiff} Day Remaining</div>) :
                    dayDiff > 1 && dayDiff <= 7 ? 
                    (<div className="mt-[1vh] text-[#e90707] text-[0.9vw]">{dayDiff} Days Remaining</div>) : 
                    dayDiff > 7 && dayDiff <= 15 ? 
                    (<div className="mt-[1vh] text-[#F59F00] text-[0.9vw]">{dayDiff} Days Remaining</div>) :
                    (<div className="mt-[1vh] text-[#1BA94C] text-[0.9vw]">{dayDiff} Days Remaining</div>)
                  )
                }
            </div>
            <div>
              <NavLink to='/billing' className="bg-[#11256D] text-[#fff] rounded-[0.3vw] text-[0.9vw] inline px-[1.8vw] py-[0.8vh] hover:text-[#fff]">
                Update Subscription
              </NavLink>
            </div>
          </div>

          <div className="flex flex-col justify-between h-[32vh] px-6 py-4 bg-white rounded-[0.3vw] drop-shadow-[0_3px_5px_rgba(0,0,0,0.10)]">
            <div className="text-[#999999] text-[0.95vw]">
              Next Payment Date
            </div>
            <div className="text-[1.6vw] font-semibold">
              {moment(activePlan?.expiryDate).format('DD/MM/YYYY')}
            </div>
            <div>
              &nbsp;
            </div>
          </div>

          {/* <div className="px-3 py-3 bg-white">
          </div> */}
        </div>

        {/* Header */}
        <div className='flex w-full h-[3vw] px-4 '>
          <div className="flex-[0.4] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
            <span className="uppercase cursor-pointer" onClick={() => handleSort("invoiceName")}>Invoice Name</span>
            {sort?.key === "invoiceName" &&
              <AiFillCaretDown color={"#999999"} className={`duration-200 ${sort.order > 0 ? "rotate-180" : "rotate-0"}`} />}
          </div>

          <div className="flex-[0.3] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
            <span className="uppercase cursor-pointer" onClick={() => handleSort("billingDate")}>Billing Date</span>
            {sort?.key === "billingDate" &&
              <AiFillCaretDown color={"#999999"} className={`duration-200 ${sort.order > 0 ? "rotate-180" : "rotate-0"}`} />}
          </div>

          <div className="flex-[0.2] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
            <span className="uppercase cursor-pointer" onClick={() => handleSort("amount")}>Amount</span>
            {sort?.key === "amount" &&
              <AiFillCaretDown color={"#999999"} className={`duration-200 ${sort.order > 0 ? "rotate-180" : "rotate-0"}`} />}
          </div>

          <div className="flex-[0.2] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
            <span className="uppercase">Plan</span>
          </div>

          <div className="flex-[0.2] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
            <span className="uppercase">Status</span>
          </div>

          {/* Download column */}
          {/* <div className="flex-[0.2] space-x-1 text-[#999999] text-[1vw] flex justify-start items-center">
            <span className="uppercase"></span>
          </div> */}
        </div>

        {/* Data */}
        <div className="w-full space-y-[0.7vw]" style={{ marginTop: "0.5vw" }}>
          {
            loading &&
            <div className="h-[20vw] flex items-center justify-center">
              <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />
            </div>
          }

          {
            !loading && !filteredList.length &&
            <div className="h-[20vw] flex flex-col items-center justify-center">
              <EmptyFolder />
              <div className="uppercase text-[1.5vw] font-semibold text-[#777777]">No Purchases Found</div>
            </div>
          }

          {
            !loading && filteredList.length > 0 &&
            filteredList.map((plan: any, index: number) =>
              <PlanCard key={index} plan={plan} />)
          }
        </div>
      </div>
    </div>
  );
};

export default BillingInfo;
