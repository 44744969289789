import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectType } from "../../types/store/ProjectStoreTypes";
import useProjectStore from "../../store/projectStore";
import { getMessage } from "./util";
import useAuthStore from "../../store/authStore";
import useWorkflowStore from "../../store/useWorkflowStore";

import { FiSearch } from 'react-icons/fi';
import { IconContext } from 'react-icons';


interface Props{
    data:Array<any>,
    searchTerm:string,
    setSearchTerm:any,
    setFilters:any,
    filters:any
}

const SearchBarDropDown:React.FC<Props> = ({data,searchTerm,setSearchTerm,setFilters,filters}) => {
  let [searchResult, setSearchResult] = useState<Array<ProjectType>>([]);
  let [showSuggestion,setShowSuggestion] = useState<boolean>();
  //let [searchTerm, setSearchTerm] = useState<string>("");
  let navigator = useNavigate();

  let authStore = useAuthStore();
  let workflowStore = useWorkflowStore();

  useEffect(() => {
    if (searchTerm !== "") {
      let temp: Array<ProjectType> = [];
      data.forEach((d: any) => {
        if (getMessage(d.errorCode,d.message,d.type)?.toLowerCase().includes(searchTerm.toLowerCase()) ) {

          if(!temp.find((data:any)=>d.errorCode === data.errorCode) )
          {
            
            temp.push(d);
          }
        }
      });

      setSearchResult(temp);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);




  return (
    <>
      {searchTerm &&  searchTerm.length > 0 && showSuggestion && <button className="fixed z-10 inset-0 w-full h-full cursor-default" onClick={() => { setSearchTerm("") }}></button>}
      <div className="relative z-50">
        <form onSubmit={(e:any)=>{
          e.preventDefault()
          setShowSuggestion(false)
          setFilters({...filters,message:searchTerm})
        }
        
        }>

        <div className={"flex"}>
        
          <input
            onChange={(e) =>{
               setSearchTerm(e.target.value)
              setShowSuggestion(true)
              }}
            value={searchTerm}
            placeholder={"E.G Sample Text"}
            type="text"
            className={
              "w-full flex-grow py-[0.8vw] px-[1.5vw] text-[1vw] bg-white rounded-l-full outline-none"
            }
          />
          <div
            className={
              "bg-gray-100 w-[7vw] flex justify-center items-center rounded-tr-full cursor-pointer rounded-br-full hover:bg-gray-200 duration-300"
            }
          >
            <IconContext.Provider
              value={{ color: `#2c2c2c`, size: `1.5vw` }}
            >
              <button type="submit" className="z-[51]">
                <FiSearch />
              </button>
            </IconContext.Provider>
          </div>
        </div>
        </form>
        <div className="absolute w-[99%] ml-[0.4vw] max-h-[45vh] overflow-y-auto shadow-md bg-white transform mt-3 z-50">
          {searchResult.length === 0 && searchTerm.length > 0 && (
            <div className="w-full flex justify-center p-[0.7vw] text-[1.3vw]">
              No Data Found
            </div>
          )}
          {showSuggestion && searchResult.length>0 && searchResult.map((d: any, idx) => {
            return (
              <div
                onClick={()=>{
                  setSearchTerm(getMessage(d.errorCode,d.message,d.type) || "")
                  setShowSuggestion(false)
                  setFilters({...filters,message:searchTerm})
                }}
                key={d._id}
                className={
                  "w-full px-[1vw] py-[0.5vw] flex justify-between hover:bg-gray-100 cursor-pointer"
                }
              >
                <div className="flex space-x-2 items-center">
                  <FiSearch  size={"1vw"}/>
                  <div className={"text-[1.1vw]"}>{getMessage(d.errorCode,d.message,d.type)}</div>
                  {/* <div className="text-gray-300 text-[1vw]">{project.projectInfo.acronym}</div> */}
                </div>

              </div>
            );
          })}
        </div>
      </div>
     
    </>

  );
};

export default SearchBarDropDown;