import React, { useState } from "react";
import Axios from "../../utils/Axios";
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from "../../store/authStore";

interface BillingColPropsType {
    plan: any
}

let BillingCol: React.FC<BillingColPropsType> = ({ plan }) => {

    let authStore = useAuthStore();
    let navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    let handleClick = () => {
        setButtonLoading(true);
        if (authStore.isAuthenticated) {
            Axios.post("/billing/createSession", {
                planId: plan.planId
            })
                .then((res) => {
                    window.open(res.data.data.url, '_blank');
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setButtonLoading(false);
                })
        } else {
            setButtonLoading(false);
            navigate("/signin");
        }
    }

    return (
        <div className='w-[28vw] h-[70vh] border-[2px] border-[#222222] rounded-[10px] px-[1vw] py-[2vh] flex flex-col justify-between items-center'>
            <div className="flex flex-col">
                <div className='text-center pb-[0.6vw] px-[1vw]'>
                    <h1 className='text-[1.4vw] font-medium'>{plan.title.h1}</h1>
                    <p className='text-[1vw] text-[#9A9A9A]'>{plan.title.p}</p>
                </div>

                <div className="overflow-y-scroll custom_scroll px-[1vw] h-[45vh]">
                    <div className='border-b-[1px] border-[#C4C4C4]'>
                        <h3 className="text-center font-bold text-[1vw] pt-[0.6vw] pb-[1vw] border-b-[1px] border-[#C4C4C4]">{plan.details1.title}</h3>
                        {plan.details1?.points && plan.details1.points.map((point: any, index: any) => {
                            return (
                                <div key={index} className="flex items-center py-[0.5vw] px-[0.5vw] first-of-type:pt-[1vw] last-of-type:pb-[1vw]">
                                    <img src={point.img} className="w-[1.6vw] mr-[1vw]" />
                                    <p className="text-[1vw]">{point.desc}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className=''>
                        <h3 className="text-center font-bold text-[1vw] pt-[1.2vw]">{plan.details2.title}</h3>
                        <ul className="list-disc px-[2vw]">
                            {plan.details2?.points && plan.details2.points.map((point: any, index: any) => {
                                return (
                                    <li key={index} className="display-[list-item] py-[0.3vw] first-of-type:pt-[0.7vw] text-[1vw]">{point}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="mt-[2vh]">
                {!plan.button.disabled ?
                    <button className={`w-[14vw] h-[2.5vw] border-[#11256D] border-[1px] font-medium text-[#11256D] text-[1vw] transition duration-150 ease-in-out hover:bg-[#11256D] hover:text-[#fff]
                    ${buttonLoading ? 'bg-primarycolor' : 'bg-white'}`}
                        onClick={handleClick}
                        disabled={plan.button.disabled}>
                        {
                            buttonLoading ?
                                <img src="/images/loading_white.svg" alt="loading" className='w-[1.6vw] mx-auto ' /> :
                                plan.button.title
                        }

                    </button>
                    :
                    <p className="relative top-[1vh] h-[5vh] text-[1vw] font-bold">
                        Contact us at <a className='underline hover:underline' href={`mailto:${plan.button.title}`}>{plan.button.title}</a>
                    </p>}

            </div>
        </div>
    )
}

export default BillingCol;