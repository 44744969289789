import React from 'react'
import useAuthStore from '../../../store/authStore'
import { Popover } from 'antd';

import { ReactComponent as AchievedTarget } from '../../../assets/achieved_target.svg';
import { ReactComponent as TotalTarget } from '../../../assets/total_target.svg';
import useWorkflowStore from '../../../store/useWorkflowStore';

const HoverMenu = ({ children, text }: any) => {
    return (
  
      <Popover
        className='!text-[2vw]'
        placement="top"
        content={text} color="white"
  
      >
        {children}
      </Popover>
    )
  }




type ICounter={
    count:number,
    RetrainTarget:number
}

export default function Counter({count,RetrainTarget}:ICounter) {

    
    const userDetails = useAuthStore(state=>state?.userDetails);
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const currentBatchNumber:number = workflow
                                    ?.alpdsInfo
                                    ?.percentageStatus
                                    ?.nerStage1Progress
                                    ?.currentBatchNumber
                                    || 0;
                                    
    const currentTarget =(currentBatchNumber * RetrainTarget)-count;
  return (
    <div className='flex items-center justify-end gap-[1vw] text-[1vw] px-[0.5vw]'>
         <div className='flex-1 flex items-center justify-end gap-[0.2vw]'>
            <HoverMenu text={`Annotated by ${userDetails?.firstName} ${userDetails?.lastName}`}>

              <div><AchievedTarget className=' w-[1.8vw] h-[1.8vw]' /></div>
            </HoverMenu>
            <p className='text-[#1BA94C] select-none'>{count}</p>
          </div>
          <div className='flex items-center gap-[0.2vw]'>
          <div>
            <HoverMenu text="Target for retrain">
            <TotalTarget className=' w-[3.5vw] h-[2vw]' />

            </HoverMenu>
          </div>
          <p className='text-[#1BA94C] select-none'>
            {currentTarget>0?currentTarget:0}
            </p>
          </div>
         
    </div>
  )
}
