import React, { useState } from 'react'
import Validations from '../rlhf-fine-tuning/validation/Validations'
import Prompt from '../shared/prompt/Prompt';
import AddNewAnswer from './AddNewAnswer'

import {ReactComponent as LoadingWhite} from '../../../assets/loading_white.svg';
import {AiFillPlusCircle} from 'react-icons/ai';
import { toast } from 'react-toastify';
import { createPrompt } from '../_apiCalls';
import useWorkflowStore from '../../../store/useWorkflowStore';



const PredictionButton =({text,
  loading,onClick,
  disabled,disabledTitle,
  
  styles}:any)=>{

  return (<button
    disabled={disabled}
    title={disabled && disabledTitle}
    onClick={!disabled ? onClick:()=>{}}
    className={`uppercase duration-200  w-[16vw]
          text-white text-[0.9vw] 
           h-[3vw]  text-center rounded-md border-[0.1vw] hover:scale-105 
           disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
          border-transparent bg-primaryBlue hover:drop-shadow-md 
          ${styles}  `}
  >
    {loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                    :  <div className='flex items-center justify-center space-x-[0.5vw]'>
                    {/* <AiFillPlusCircle
                    className='h-[1.5vw] w-[1.5vw]' />  */}
                    <span>{text}</span>
                    </div>}
  </button>)

}





function AddNewPrompt({setEditState,callback,canGoBack,}:any) {

  const [prompt,setPrompt] = useState<string>("");
  const [answers,setAnswers] = useState<any[]>([]);

  const [rankSet,setRankSet] = useState<Set<number>>(new Set<number>([]));

  const [loading,setLoading] = useState<boolean>(false);

  
  const workflow = useWorkflowStore(state=>state.currentWorkflow);


  const resetStates = ()=>{
    setPrompt("");
    setAnswers([]);
   
  }

  return (
    <div 
    className='space-y-[1vw] bg-white px-[1vw]'
    data-testid="add-new-prompt"
    >
    {/* Prompt */}
    <div>
       <Prompt
       
       promptId={""}
       text={prompt}
       editable={false}
       edit={true}

       isNewPrompt={true}

       //  Functions here
       setText={setPrompt}
       setEditState={()=>{}}
       />
    </div>

    {/* Generate Predictions */}
    <div className='border-t border-b py-[0.5vw] space-x-[1vw]'>
            {canGoBack &&  <PredictionButton 
            text={"Back"}
            styles={`!bg-white !text-primaryBlue
                         border border-primaryBlue
                         `}
            onClick={()=>setEditState(false)}/>}
            <PredictionButton 
            loading={loading}
            disabled={loading}
            onClick={async()=>{
              if(!workflow) return;

              setLoading(true);
              await createPrompt(workflow.workflowId,{prompt,answers});
              resetStates();
              setLoading(false);
              callback && callback();
            }}
            text={"Save"}/>
        </div>

 

    {/*Add Answer */}
   {answers.length ===0 &&  <div className='space-y-[1vw]'>
      <AddNewAnswer
      rankSet={rankSet} 
      setActive={()=>{}}
      answers={answers}
      saveAnswer={async(ans:any,callback:any)=>{
        if(ans.text?.length<5){
          toast.warn("Answer must be 5 words long");
          return;
        }
        if(rankSet.has(ans.rank)){
          toast.warn("The rank is already taken!");
          return;
        }
        console.log("Answers to be added",ans);
        let temp = [ans,...answers];
        console.log("New Answers",temp);
        
        setAnswers(temp);
        if(callback) callback();
        
      }}
      />
    </div>}

    {/* List of All Answers */}
    {
      answers.map((ans:any,index:number)=>{
        return <Validations 
        key={ans.responseId}
        active={false}
        setActive={()=>{}}
        index={index}
        answer={ans}
        answers={answers}
        validation={ans}
        canDelete ={true}
        rankSet={rankSet}
        setValidation={(validation:any)=>{
          //console.log("=== inside set validation ===",validation);
            let temp =answers.map((oldAns:any)=>{

              if(oldAns.responseId === validation.responseId){
                return validation
              }
              return oldAns;

            })
            console.log("=== updating answers states ===",temp);
            
            setAnswers(temp);
            
        }}
        updateAnswer = {async(ans:string)=>{
            setAnswers([{...answers[0],text:ans}]);

      }}


        />
      })
    }

</div>
  )
}

export default AddNewPrompt