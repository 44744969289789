import ReactApexChart from "react-apexcharts";
import { InfoCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Button from '../../../components/controls/Button';
import Axios from '../../../utils/Axios';
import useWorkflowStore from "../../../store/useWorkflowStore";
import useAuthStore from "../../../store/authStore";
import useCompanyUsersStore from "../../../store/useCompanyUsersStore";
import { toast } from 'react-toastify';
import { nodesAtom } from '../../../components/masterlist/atoms';
import { useRecoilValue } from 'recoil';



import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsArrowsAngleExpand } from 'react-icons/bs'

import template from "../../../template";
import { Modal, Popover } from "antd";
import ExpandedClasswiseSummary from "./ExpandedClasswiseSummary";



interface UserStatistcsProps {
  userInfo: any,
  stage: number,
  batchNumber: number,
  requestType: number
}
const UserStatisticsComponent: React.FC<UserStatistcsProps> = ({ userInfo, stage, batchNumber, requestType }) => {


  //Loading States
  const [loadingVStat, setLoadingVStat] = useState(false);
  const [loadingPieChart, setLoadingPieChart] = useState(false);
  const [loadingOverlap, setLoadingOverlap] = useState(false);


  // main states
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isClasswiseOpen, setIsClasswiseOpen] = useState<boolean>(false);
  const [showExpandMesssage, setShowExpandMessage] = useState<boolean>(false);


  const [userStats, setUserStats] = useState<Array<any>>([]);
  const [overlapStats, setOverlapStats] = useState<any>();
  const workflowStore = useWorkflowStore();
  const authStore = useAuthStore();
  const { companyUsers } = useCompanyUsersStore();

  const projectType: string = workflowStore.currentProject?.projectType || "";
  const workflowType: string = workflowStore.currentProject?.workflowType || "";

  const [vStats, setVStats] = useState<any>({
    accepted: 0,
    rejected: 0,
    total: 0
  });
  const [pieChartOptions, setPieChartOptions] = useState<any>({
    series: [],
    options: {}
  });
  const nodes: any = useRecoilValue(nodesAtom);

  
  const getNodeName = (data: any) => {
    let noTagClassName = "No Tag";
    let nullName = "No Associated Class";
    if (data) {
      return Object.keys(data).map(key => {
        if (key === "null") {
          return nullName;
        }
        else if (key === noTagClassName) {
          return noTagClassName;
        }
        else {
          return nodes.find((n: any) => n.node_id === key)?.node_name
        }


      })
    }
    else {
      return [];
    }

  }

  const getOneNodeName = (data: string) => {
    let noTagClassName = "No Tag";
    let nullName = "No Associated Class";
    if (data) {

      if (data === "null") {
        return "No Associated Class";
      }

      if (data === noTagClassName) {
        return noTagClassName;
      }
      else {
        let node_name: string = nodes.find((n: any) => n.node_id === data)?.node_name;

        return node_name.length > 15 ? `${node_name.slice(0, 15)}...` : node_name;
      }


    }
  }


  // Lets Fetch Graph Info
  useEffect(() => {



    const fetchUserStats = async () => {
      let workflowId = workflowStore.currentWorkflow?.workflowId;

      try {
        setLoadingVStat(true);

        let endpoint = template[projectType][workflowType].summary.endpoints.vstats;
        let res_vstats: any = await Axios.post(`/summary/${endpoint}`, {

          workflowId,
          stage: stage,
          userId: userInfo.userId,
          batchNumber: batchNumber || 1,
          requestType

        });

        console.log("=== Got User Stats ===", res_vstats.data);
        let vTotal = 0;
        if (res_vstats.data.result) {

          setVStats({
            accepted: res_vstats.data.data.accepted
            , rejected: res_vstats.data.data.rejected
            , totalPredicted: res_vstats.data.data.total_predicted,
            totalParas: res_vstats.data.data.total_paras,
            addedByUser: res_vstats.data.data.addedByUser,
            userValidated:res_vstats.data.data.user_validated_paras
          });
          //console.log("current VStats",vStats);
        }
      }
      catch {
        toast("Could not get user statistics data. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setLoadingVStat(false);
      }
    }


    const fetchUserOverlap = async () => {
      let workflowId = workflowStore.currentWorkflow?.workflowId;


      try {
        setLoadingOverlap(true);
        let res = await Axios.post("/summary/overlapstats", {
          workflowId,
          userId: userInfo.userId,
          stage,
          batchNumber,
          requestType
        });

        // console.log("=== Response for overlap stats ===",res.data.data);

        //Omit the current user
        let data = res.data.data.filter((d: any) => d.user.userId !== userInfo.userId);

        // Populate the data array with userInfo from companyUsers
        for (let i = 0; i < data.length; i++) {
          companyUsers.forEach((user: any) => {
            if (data[i].user.userId === user.userId) {
              data[i].user.userInfo = {
                firstName: user.firstName,
                lastName: user.lastName,
              }
            }
          })
        }

        data.sort((d1: any, d2: any) => d1.user.userInfo.firstName > d2.user.userInfo.firstName ? 1 : -1);

        let s: Array<any> = [];
        data.forEach((d: any) => {
          let temp: any = {};
          temp.data = d.validations;
          temp.name = d.user.userInfo.firstName + d.user.userInfo.lastName;
          s.push(temp);
        })
        //console.log("Bar graph data",s);
        const barChartData = {
          series: [{
            data: data.map((d: any) => d.overlap.toFixed(2))
          }],
          options: {
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                barHeight: "20%"
              }
            },

            xaxis: {
              categories: data.map((d: any) => (d.user.userInfo.firstName + " " + d.user.userInfo.lastName))
            },
            yaxis: {
              min: 0,
              max: 100
            },
            colors: ["#03045E"],
            grid: {
              show: false,
            },
            noData: {
              text: "No Data To Show"
            }
          },

        };
        //console.log("=== Bar Chart Data ===",barChartData);
        setOverlapStats(barChartData);

      } catch (err) {
        //console.log(err);
        toast("Could not get user overlap statistics. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setLoadingOverlap(false);
      }
    }


    fetchUserStats();
    fetchUserOverlap();



  }, [userInfo])

  useEffect(() => {

    const fetchUserPieChart = async () => {
      let workflowId = workflowStore.currentWorkflow?.workflowId;
      let userId = userInfo.userId

      try {
        setLoadingPieChart(true);

        const endpoint = template[projectType][workflowType].summary.endpoints.userpiechart

        let res = await Axios.post(`/summary/${endpoint}`, {
          workflowId,
          stage,
          batchNumber,
          userId,
          requestType

        });
        // console.log("=== pie chart data ===", res.data);
        let graphData = res.data.data;


        if (Object.keys(graphData).length > 10) {
          setShowExpandMessage(true);
        }
        else {
          setShowExpandMessage(false);
        }

        // const series = Object.keys(graphData).map(k => graphData[k])
        const series = [{
          name: userInfo.firstName + "'s Validation",
          data: Object.keys(graphData)
            .sort((k1: string, k2: string) => graphData[k1] > graphData[k2] ? -1 : 1)
            .map((k, index) => graphData[k])
          // .map((k,index)=>{return {
          //   name:getOneNodeName(k),
          //   data:[graphData[k]]

        }]

        // }})

        // const options = {

        //   expandeOnClick: true,
        //   labels: getNodeName(graphData),
        //   toolbar: {
        //     show: false
        //   },
        //   legend: {
        //     show: false
        //   },
        //   noData: {
        //     text: "No Data To Show"
        //   }


        // }

        const options = {

          plotOptions: {
            bar: {

              horizontal: true,

              barHeight: "100%",
              distributed: true,
              colors: {


              },
            },

          },
          chart: {
          },
          legend: {
            show: false
          },
          colors: ["#fc5c65", "#fd9644", "#26de81", "#9980FA", "#45aaf2", "#a55eea",
            "#3867d6", "#3c40c6", "#18dcff", "#32ff7e", "#20bf6b", "#0fb9b1",
            "#ff3838", "#ff4d4d", "#ff9f1a", "#ff3838", "#20bf6b", "#0fb9b1"
          ],
          xaxis: {
            categories: Object.keys(graphData)
              .sort((k1: string, k2: string) => graphData[k1] > graphData[k2] ? -1 : 1)
              .map((k: string) => getOneNodeName(k))
          }

        }

        setPieChartOptions({ options, series });

      }
      catch (err) {
        console.log("error in single user pie chart", err);
        toast("Could not get user pie chart data. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setLoadingPieChart(false);
      }

    }
    fetchUserPieChart();

  }, [nodes, userInfo])


  const Loader = () => {
    return (

      <div className='p-3 flex-1  flex justify-center items-center '>
        <AiOutlineLoading3Quarters size={30} color={"gray"} className=" animate-spin" />
      </div>

    )
  }



  return (
    <>
      <div className="flex p-2 bg-white  shadow-md">
        <div className="flex flex-col flex-1 mx-3">
          <div className=" font-bold my-2">Full Name</div>
          <div className="flex border-2 bg-[#f9fafb] border-[#C4C4C4]  max-w-[325px]  rounded-[4px] px-4 py-2">
            <div className='color-[#7A7A7A]'>{`${userInfo.firstName} ${userInfo.lastName}`}</div>
          </div>
        </div>

        <div className="flex flex-col flex-1 ml-14">
          <div className="font-bold my-2">Email</div>
          <div className="flex border-2 bg-[#f9fafb] border-[#C4C4C4] max-w-[325px] rounded-[4px] px-4 py-2">
            <div className='color-[#7A7A7A]'>{userInfo.email}</div>
          </div>
        </div>

        <div className="flex flex-col flex-1 ml-[1.8rem]">
          <div className="flex border-2 bg-white border-[#C4C4C4]  max-w-[250px]  rounded-[4px] mt-[2.35rem] px-4 py-2">
            <div className='color-[#7A7A7A]'>Complete Project</div>
          </div>
        </div>
        <div className="relative" onClick={() => { setIsOpen((prevState: boolean) => !prevState) }}>{isOpen ? < UpOutlined style={{ position: 'relative', fontSize: '20px', top: "3px", right: "3px" }} /> : <DownOutlined style={{ position: 'relative', fontSize: '20px', top: "3px", right: "3px" }} />}</div>

      </div>
      {isOpen &&
        <div >
          <div className="flex   p-2 bg-white">
            {loadingVStat ? (
              <Loader />
            )
              : <div className="flex-col   flex-1 w-[50%]
                               shadow-[0px_0px_8px_rgba(122,122,122,0.15)]
                                px-5 py-1 my-5 mx-3 text-[0.9vw]">
                <div className="text-[#1BA94C] text-[0.9vw] p-[0.1vw] font-bold mt-2 mb-5">Validation Statistics</div>
                {/* <hr
                style={{
                  color: "#EAEAEA",
                  backgroundColor: "#EAEAEA"
                }}
              /> */}

                <div className="flex-col text-[#9A9A9A] py-2 ">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[65%]">Total Paragraphs</div>
                    <div className="mr-3">{vStats.totalParas}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

                <div className="flex-col text-[#9A9A9A] py-2 ">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[65%]">Paragraphs Validated by User</div>
                    <div className="mr-3">{vStats.userValidated}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[70%]">Cumulative Classes Predicted by Algorithm</div>
                    <div className="mr-3">{vStats.totalPredicted}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between">
                    <div className="ml-1  w-[70%]">Cumulative Classes Added by User</div>
                    <div className="mr-3 w-[50%] text-end">{vStats.addedByUser}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />


                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between  ">

                    <div className="ml-1 w-[65%]">Predicted Classes Accepted by User</div>

                    <div className="mr-3" >{vStats.accepted}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />
                <div className="flex-col text-[#9A9A9A] py-2">
                  <div className="flex justify-between">

                    <div className="ml-1  w-[60%] ">Predicted Classes Rejected by User</div>

                    <div className="mr-3" >{vStats.rejected}</div>
                  </div>
                </div>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA"
                  }}
                />

              </div>}

            {loadingPieChart ? <Loader /> : <div className="flex flex-col items-center justify-start my-5 mx-3 flex-1 shadow-[0px_0px_8px_rgba(122,122,122,0.15)] ">

              <div className="flex items-center justify-between w-full p-[0.5vw]">
                <div className="self-start text-[#1BA94C] font-bold text-[0.9vw] p-[0.1vw]">Classwise Validation Statistics</div>

                <div className='flex items-center gap-[1vw]'>
                  <p className="text-[0.7vw]">
                    {showExpandMesssage && "(Showing only 10 classes)"}</p>
                  <Popover
                    content="Expand">

                    <BsArrowsAngleExpand className='cursor-pointer hover:scale-110 duration-100'
                      size={17}
                      color={"#777777"}
                      onClick={() => setIsClasswiseOpen(!isClasswiseOpen)}
                    />
                  </Popover>
                </div>
              </div>

              <div id="chart" className="my-2 p-2 w-full h-full flex justify-center items-center">

                <ReactApexChart
                  options={{
                    ...pieChartOptions.options,
                    xaxis: {
                      ...pieChartOptions?.options?.xaxis
                      , categories: pieChartOptions?.options?.xaxis?.categories?.slice(0, 10)
                    }
                  }}
                  series={[{
                    ...pieChartOptions.series[0],
                    data: pieChartOptions.series[0]?.data?.slice(0, 10)
                  }]}
                  type="bar"
                  width={300}
                  height={300} />

                <Modal
                  open={isClasswiseOpen}
                  onCancel={() => setIsClasswiseOpen(false)}
                  footer={null}
                  width={"90vw"}

                >
                  {!loadingPieChart && <ExpandedClasswiseSummary
                    isOpen={isClasswiseOpen}
                    setIsOpen={setIsClasswiseOpen}
                    nodes={nodes}
                    pieChartOptions={pieChartOptions}
                    loading={loadingPieChart}

                  />}
                </Modal>
              </div>



            </div>}
            {loadingOverlap ? <Loader /> : <div className="flex flex-col items-center justify-start my-5 mx-3 flex-1 shadow-[0px_0px_8px_rgba(122,122,122,0.15)] ">
              <div className="self-start text-[#1BA94C] text-[0.9vw] p-[0.1vw] font-bold mt-3 mx-5">Overlap Statistics With other Validators</div>

              <div id="chart" className="flex justify-center items-center">


                <ReactApexChart
                  options={overlapStats ? overlapStats?.options : {}}
                  series={overlapStats ? overlapStats?.series : []}
                  type="bar"
                  width={300}
                  height={300} />

              </div>
            </div>}

          </div>



        </div>
      }
    </>);
}


export default UserStatisticsComponent;
