import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import FtSummaryTab from "./FtSummaryTab";
import Loading from "../../shared/Loading";
import { useNavigate } from "react-router-dom";
import useWorkflowStore from "../../../../store/useWorkflowStore";
import { callInstructFtSummary } from "../../_apiCalls";
import { toast } from "react-toastify";
import { TrainStatus, checkPromptTrainStatus } from "../../../../utils/navigationHelper";

export type SummaryDataType={
    generatedRes:number,
    userRes:number,
    total:number

}

const SummaryComp = ({ isModalOpen, setIsModalOpen }: any) => {

    const page = 1;
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const [summaryData,setSummaryData] = useState<SummaryDataType>({
        generatedRes:0,
        userRes:0,
        total:0
    });
    const navigate = useNavigate();
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const workflowId =workflow?.workflowId;
    const llmProgress = workflow?.alpdsInfo?.percentageStatus?.automlProgress?.llmProgress || 0;
    
    let handleClick = () => {
        if(!workflow) return;
        // if(!isCreator){
        //   toast.warn("Only project admin can access training results");
        //   return;
        // }
        let shouldNavigate:boolean = true;
        let navigatePoint:string ="";
        let isLLM = workflow?.alpdsInfo?.selectedModelInfo?.type==="llm"?true:false;
          switch(checkPromptTrainStatus(workflow)){
    
    
            case TrainStatus.NotTrained:
                navigatePoint="explore-llm-models";
                  //alert("You have not trained the model");
                  break;
            case TrainStatus.IsInLLMModelTuning:
                //alert("in tuning")
                navigatePoint="tune-llm-model";
                break;
            case TrainStatus.IsInLLMModelTrainig:
                //alert("in training")
                navigatePoint="llm-model-fine-tune";
                break;
            case TrainStatus.InModelSelection:
                   // alert("in selection")
                  navigatePoint = "model-comparision"
                  //alert("you are in model selection");
                  shouldNavigate =false;
                  break;
            case TrainStatus.Trained:
              console.log("=== is trained ===",isLLM);
              navigatePoint = "llm-model-fine-tune"
                  shouldNavigate=false;
                  //alert("You want to start iterate");
                  break;
            
            default:
                alert("could not find anything")
    
    
          }
          console.log("=== navigate point ===>",navigatePoint);
          navigatePoint.length && navigate(`/workflow/${navigatePoint}?workflowId=`+workflow?.workflowId);
          return;
      };

    useEffect(()=>{
        if(!workflowId) return;

        const fetchData =async()=>{
            setIsLoading(true)
            const {data,error} = await callInstructFtSummary(workflowId);

            if(error){
                setIsLoading(false)
                toast.warn("there was an error while generating the  ");
                return;
            }

            let tempUserRes=0;
            let tempGenRes=0;
            if(data && data?.sourceData){
                data.sourceData.forEach((d:any)=>{
                    if(d._id==="user"){
                        tempUserRes+=d?.count || 0;
                    }else{
                        tempGenRes+=d?.count || 0;
                    }
                })
            }

            setSummaryData({
                userRes:tempUserRes,
                generatedRes:tempGenRes,
                total:data.totalPrompts
            })
            console.log("=== summary ===",summaryData)
            setIsLoading(false);



        }
        if(isModalOpen){
            fetchData();

        }


    },[isModalOpen])

    return (
        <Modal
            title="Validation Summary"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            centered
            okButtonProps={{ className: "hidden" }}
            cancelButtonProps={{ className: "hidden" }}
            width={"50vw"}
            footer={null}

        >

            {page === 1 ? isLoading ? <div className='flex  items-center justify-center '>
                <Loading
                styles="!h-[200px]"

                />

            </div>

                : <div className=' '>
                    <FtSummaryTab
                    data={summaryData}
                    />


                </div> : null}


            {/* Confirm Buttons */}
            <div className='flex gap-[1vw] mt-[5vw] justify-end pb-[1vw]'>

                <div className='uppercase 
                            flex justify-center items-center cursor-pointer
                            w-[7vw] h-[2.5vw] text-center rounded-[0.2vw]
                            hover:border-primaryBlue border border-transparent hover:scale-110
                            duration-200
'
                    onClick={() => setIsModalOpen(false)}
                >
                    <span className='text-[1vw]'>Go Back</span>
                </div>



                <div
                    className={`uppercase bg-primaryBlue cursor-pointer
                                flex justify-center items-center hover:scale-110 duration-200
                                text-white w-[14vw] h-[2.5vw] text-center rounded-[0.2vw]`}
                    onClick={handleClick}
                    // onClick={()=>{
                    //     if(workflowId){
                    //         if(llmProgress === 100){
                    //             navigate("/workflow/llm-model-fine-tune?workflowId="+workflowId);
                    //         }else{

                    //             navigate("/workflow/explore-llm-models?workflowId="+workflowId);
                    //         }
                    //     }

                    // }}

                >
                    {llmProgress === 100?"To Deployment Screen":"Customize Model"}
                </div>
            </div>
        </Modal>
    )
}

export default SummaryComp;