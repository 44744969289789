import { useEffect, useState } from "react";
import useWorkflowStore from "../../../store/useWorkflowStore";
import Axios from "../../../utils/Axios";
import { useNavigate } from "react-router-dom";

type Props={
  showLlm:boolean

}

const ModelCompHeader = ({showLlm=false}:Props) => {
    interface ITrainInfo{
      trainingDocs:number,
      trainingParas:number,
      trainingIssues:number,
      totalDocs:number,
      
    }
  
    const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
    const trainInfo:ITrainInfo | undefined = currentWorkflow?.alpdsInfo?.trainInfo;
    const [totalParas,setTotalParas] = useState<number>(0);
    const navigate = useNavigate();
  
    useEffect(()=>{
  
      const fetchTotalParas =async()=>{
        let res = await Axios.post("/para/getParaCount",{
          workflowId:currentWorkflow?.workflowId
      });
      console.log("=== total paras ===",res.data.data);
      setTotalParas(res.data.data.totalParas.totalPara);
      }
  
      fetchTotalParas();
      
  
  
    },[trainInfo]);
  
    
    return <div className='flex justify-between items-center
                         w-full h-[5.5vw] px-[2vw]
                         text-[1.1vw]
                         border-b '>
  
  
      <div className='flex flex-col'>
        <p className='text-[#9A9A9A] '>Total Paragaphs</p>
        <p className='font-medium '>{totalParas}</p>
  
      </div>
  
      <div className='flex flex-col'>
        <p className='text-[#9A9A9A] '>NO. OF PARAGRAPHS VALIDATED</p>
        <p className='font-medium '>{trainInfo?.trainingParas}</p>
  
      </div>
      <div className='flex flex-col'>
        <p className='text-[#9A9A9A] '>NO. OF NODES</p>
        <p className='font-medium '>{trainInfo?.trainingIssues}</p>
  
      </div>
      <div className='flex flex-col'>
        {/* <p className='text-[#9A9A9A]'>TOTAL NO. OF DOCUMENTS</p>
        <p className='font-medium'>{trainInfo?.trainingDocs}</p> */}
        {showLlm ?<div className="w-[12vw] h-[3vw]
                        duration-200
                        cursor-pointer
                        bg-white hover:bg-primaryBlue
                        text-primaryBlue hover:text-white
                        flex items-center justify-center
                        border border-primaryBlue rounded-[0.2vw]"
                        
              onClick={()=>{
                navigate("/workflow/explore-llm-models?workflowId="+currentWorkflow?.workflowId);

              }}
              >
          Explore LLM
        </div>:<div className="w-[12vw]">
          </div>}
  
      </div>
  
  
  
  
    </div>
  
  
}


export default ModelCompHeader;