import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useWorkflowStore from '../../../store/useWorkflowStore';
import { triggerNERAnnotation } from '../masterlist/_apiCalls';
import {callAutoML} from './_apiCalls';

import {ReactComponent as LoadingWhite} from '../../../assets/loading_white.svg';
import { toast } from 'react-toastify';


const Button =({text,loading,onClick,disabled,disabledTitle}:any)=>{

  return (<button
    disabled={disabled}
    title={disabled && disabledTitle}
    onClick={!disabled && onClick}
    className={`uppercase duration-200  w-[13vw]
          text-white text-[0.9vw] 
           h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
           disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
          border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md   `}
  >
    {loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                    : text}
  </button>)

}

interface IFooter{
  totalParas:number,
  page:number,
  pageSize:number,
  setPage:any,
  count:number,
  parasLoading:boolean,
  RetrainTarget:number,
  AutoMLTarget:number
}

export default function Footer({
    totalParas,
    page,
    pageSize,
    setPage,
    parasLoading,
    count,
    RetrainTarget,
    AutoMLTarget
}:IFooter) {

   
    const navigate = useNavigate();
    const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
    const currentBatch = currentWorkflow
                                    ?.alpdsInfo
                                    .percentageStatus
                                    .nerStage1Progress;

    const currentBatchNumber:number = currentWorkflow
                                    ?.alpdsInfo
                                    ?.percentageStatus
                                    ?.nerStage1Progress
                                    ?.currentBatchNumber
                                    || 0;
                                    
    const canCallRetrain =count>=(RetrainTarget*currentBatchNumber);
    const canCallAutoML = count>=AutoMLTarget;
    const workflowId = currentWorkflow?.workflowId || "";


    

    const [loadMoreLoading,setLoadMoreLoading] = useState<boolean>();
    const [MLLoading,setMLLoading] = useState<boolean>();
    const [isMounted,setIsMounted] = useState<boolean>(false);

    const handleLoadMore =async()=>{

        if(loadMoreLoading) return;

        setLoadMoreLoading(true);
        await triggerNERAnnotation(workflowId,currentBatch.currentBatchNumber+1);
        setLoadMoreLoading(false);



    }

    const handleAutoML = async()=>{

      setMLLoading(true);
        const result = await callAutoML(workflowId);

        if(result){

          setTimeout(()=>{
            
            navigate("/workflow/modeltraining-1?workflowId="+workflowId);
            setMLLoading(false);
          },1500);
        }

        
        if(result){
        }


    }
    useEffect(()=>{

      if(MLLoading) return;
      const automlProgress = currentWorkflow?.alpdsInfo?.percentageStatus.automlProgress;
      //alert("inside validation use effect")
      if(automlProgress && automlProgress<100){
        toast.warn("Model training is in progress");
        navigate("/workflow?workflowId="+currentWorkflow.workflowId);
      }
  
    },[currentWorkflow?.alpdsInfo.percentageStatus]);
  


    useEffect(()=>{
      setIsMounted(true);

      return ()=>setIsMounted(false);
    },[])


    useEffect(()=>{

      if(!isMounted) return;
      
      if(canCallAutoML){
        toast.success("You can now train the model!");
      }

    },[canCallAutoML]);

    useEffect(()=>{

      if(!isMounted) return;
      
      if(canCallRetrain){
        toast.success("You can now retrain and call the next batch of paragraphs!");
      }


    },[canCallRetrain]);










  return (
    <div className='flex flex-col items-end gap-[2vw]'>

    <div className='  border-b flex items-center w-full justify-end  
                    h-[4vw] border-t
                    gap-[1vw] 
                    '>

        <Pagination className='bg-white p-[0.6vw] text-[1vw]'
        total={totalParas}
        showQuickJumper
        showSizeChanger={false}
        current={page}
        pageSize={pageSize}
        onChange={(page) => {
        !parasLoading && setPage(page)

        }} />
        <Button
        text={"Load More"}
        onClick={handleLoadMore}
        disabled={loadMoreLoading ||!canCallRetrain}
        loading={loadMoreLoading}
        disabledTitle={`Please validate atleast ${RetrainTarget} paragraphs to retrain the model`}
        />

     
    </div>

    <div>
      <div className='flex items-center'>
      <Button
      text={"Train Platform"}
      loading={MLLoading}
      onClick={handleAutoML}
      disabled={MLLoading ||  !canCallAutoML}
      disabledTitle={`Please validate atleast ${AutoMLTarget} paragraphs to start model training`}
      />

      <Button 
      text={"Validation Summary"}
      loading={false}
      onClick={()=>navigate(`/workflow/validation-summary?workflowId=${workflowId}`)}
      disabled={false}
      disabledTitle=""
      />
      
      </div>
     
    </div>
    </div>
  )
}
