
import { Select, Checkbox } from 'antd';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AddUserComp from './AddUserComp';
import Axios from '../../utils/Axios';
import useAuthStore from '../../store/authStore';
import useCompanyUsersStore from "../../store/useCompanyUsersStore";
import { useNavigate } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { a } from 'msw/lib/glossary-297d38ba';
import ChooseProjectType from './ChooseProjectType';
import AddWorkflowData from './AddWorkflowData';
import template from '../../template';

export default function CreateProjectComp() {

    const userDetails = useAuthStore(state => state.userDetails);
    const isAuthenticated = useAuthStore(state=>state.isAuthenticated);
    let { companyUsers } = useCompanyUsersStore();
    let { usage, setUsage } = useAuthStore();
    const navigate = useNavigate();

    const [listOfUsers, setListOfUser] = useState<Array<any>>([]);
    const [workflowTypeOptions, setWorkflowTypeOption] = useState<Array<any>>([]);
    const [projectTypeOptions, setProjectTypeOption] = useState<Array<any>>([]);
    const [billingAccount, setBillingAccount] = useState<any>({});

    const [name, setName] = useState("");
  //  const [acronym, setAcronym] = useState("");
    const [workflowType, setWorkflowType] = useState<null | string>(null);
    const [projectType, setProjectType] = useState<null | string>(null);
    const [allowMultiUser,setAllowMultiUser] = useState<boolean>(true);
    const [addedUsers, setAddedUsers] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [step,setStep] = useState<number>(0);

    // Setting associated billing account
    useEffect(() => {
        if (!userDetails?.isBillingAccount) {
            let temp = companyUsers.filter((user) => {return user.userId === userDetails?.associatedBillingAccounts[0]})
            if (temp) {
                setBillingAccount(temp[0]);
            }
        } else {
            setBillingAccount(userDetails);
        }
        
    }, []);

    //Fetch Users, WorkflowTypes And Project Types
    useEffect(() => {
        

        const fetchProjectTypes = async () => {
            try {

                setProjectTypeOption(
                    Object.keys(template).map((key)=>{
                        return {
                            id:key,
                            name:template[key].name,
                            icon:template[key].icon,
                            description:template[key].description,
                            allowMultiUser:template[key].allowMultiUser
                        }
                    })
                    
                    )
                //let res = await Axios.get("/project/gettypes");

                // if (res && res.data.result) {
                //     console.log("=== Got Project Types ===", res.data.data);
                //     setProjectTypeOption(res.data.data);
                // }

            } catch (err){
                console.log("=== Error while getting project types ===",err);
                toast("Could Not fetch project types. Please reload the page.", { type: toast.TYPE.ERROR });
            }
        }

        const fetchUsers = async () => {
            try {
                let res = companyUsers;

                if (res) {
                    console.log("=== Got Users From Same Company ===", res);
                   
                    setListOfUser(res.map((user: any) => {
                        if (user.email === userDetails?.email) {
                            user.UPLOAD_ACCESS = true
                            user.MASTERLIST_ACCESS = true
                            user.VALIDATION_ACCESS = true
                            user.TRAINING_ACCESS = true
                            user.DEPLOYMENT_ACCESS = true

                            setAddedUsers([...addedUsers, user]);
                            return user;
                        }
                        else {
                            user.UPLOAD_ACCESS = false
                            user.MASTERLIST_ACCESS = false
                            user.VALIDATION_ACCESS = false
                            user.TRAINING_ACCESS = false
                            user.DEPLOYMENT_ACCESS = false

                            return user;
                        }
                    }));
                }

            } catch {
                toast("Could not fetch users from same company. Please reload the page.", { type: toast.TYPE.ERROR });
            }
        }


        if(isAuthenticated)
        {

            //fetchWorkflowTypes();
            fetchProjectTypes();
            fetchUsers();
        }


    }, [isAuthenticated]);


    const handleSubmit = async () => {

        console.log({

            workflowType,
            projectType,
            name,
            //acronym,
            userProject:
                [...addedUsers]
        });


        // if (name === "" || acronym === "" || projectType === "" || workflowType === "") {
            if (name === "" || projectType === "" || workflowType === "") {
            toast("All the fields are required.", { type: toast.TYPE.ERROR });
            return;
        }

        if(projectType === "multilabel_classification"
            && workflowType === "annotation_workflow"
        ){
            toast("Annotation workflow for Multilabel is currently not supported!.", { type: toast.TYPE.ERROR });
            return;
        }

        try {
            setLoading(true);

            let res = await Axios.post("/project/create", {


                workflowType,
                projectType,
                name,
               // acronym,
                userProject:
                    [...addedUsers]



            });
            console.log("=== After Project Created ===", res.data);

            // Updating state
            let temp = usage
            if (temp) {
                temp.details.noOfProjects = temp.details.noOfProjects + 1;
                temp.details.noOfWorkflows = temp.details.noOfWorkflows + 1;
                setUsage(temp);
            }

            toast("The project was created successfully.", { type: toast.TYPE.SUCCESS });
            navigate("/workflow?workflowId=" + res.data.data.project.lastAccessedWorkflowId)
        } catch (err) {
            toast("An error occurred while creating a new project.", { type: toast.TYPE.ERROR });


        }
        finally {
            setLoading(false);
        }

    }


    return (
        <div className="flex space-y-[2vw]  flex-col min-h-[80vh]  px-[1vw] ">
           
        {
            step === 0 && <ChooseProjectType
                            projects={projectTypeOptions}
                            selectedProject={projectType}
                            setProject={setProjectType}
                            setStep={setStep}
                            />
        }
        {
            step ===1 && <AddWorkflowData
                    projectType={projectType!}
                    setStep={()=>setStep(prev=>prev-1)}
                    step={step}
                    
            />
        }
          

        </div >
    )
}
