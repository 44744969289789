import { Node } from "../../components/masterlist/utils/interface";
import { Action } from "../../utils/global-interfaces";
import {
  ACCEPT_PARA,
  REJECT_PARA,
  STAGE1_ADD_REJECT_PARAS,
  STAGE1_ADD_PARAS,
  STAGE1_NODES_REQUEST,
  STAGE1_PRE_PARAS,
  STAGE1_PROGRESS_UPDATE,
  UPDATE_PARA,
  INIT_REJECT_PARA,
  UPDATE_REJECT_PARA,
  UPDATE_NODE_FEEDBACK,
} from "../types/stage1-types";

export interface StageNodeType extends Node {
  completedPercentage: number;
  active: boolean;
  batchId: number;
  goal: number;
  feedback: number;
}

export interface ParaDataType {
  accepted: null | string;
  createdAt: Date;
  fileId: string;
  paraData: string;
  paraId: string;
  paraSeqId: string;
  practiceAreaId: string;
  _id: string;
  alpdsInfo: any;
  predictedIssueId:Array<any>;
  nextPredicted:any
}
export interface Stage1ReducerType {
  nodes: Array<any>;
  paras: Array<any>;
  rejectedParas: Array<any>;
}

let default_state: Stage1ReducerType = {
  nodes: [],
  paras: [],
  rejectedParas: [],
};

let Stage1Reducer = (
  state: Stage1ReducerType = default_state,
  action: Action
) => {
  switch (action.type) {
    case STAGE1_NODES_REQUEST: {
      return {
        ...state,
        nodes: action.payload,
      };
    }
    case STAGE1_PRE_PARAS: {
      return {
        ...state,
        paras: action.payload,
      };
    }
    case STAGE1_ADD_PARAS: {
      return {
        ...state,
        paras: state.paras?.map((para) => {
          if (para?.node_id === action.payload.node_id) {
            return action.payload;
          }
          return para;
        }),
      };
    }
    case STAGE1_PROGRESS_UPDATE: {
      return {
        ...state,
        nodes: state.nodes.map((node: any) => {
          if (node.node_id === action.payload.node_id) {
            node.completed_percentage = action.payload.data;
          }
          return node;
        }),
      };
    }
    case ACCEPT_PARA: {
      return {
        ...state,
        paras: state.paras.map((para: any) => {
          if (para.node_id === action.payload.node_id) {
            let data = para.data.map((p: any) => {
              if (p.para_info.paraId === action.payload.paraId) {
                p.accepted = "accepted";
              }
              return p;
            });
            para.data = data;
          }
          return para;
        }),
      };
    }
    case UPDATE_PARA: {
      return {
        ...state,
        paras: state.paras.map((para: any) => {
         
          if (para.node_id === action.payload.node_id) {
            //
            //
           
            let temp = para.data.filter(
              (p: any) => p.para_info.paraId !== action.payload.paraId
            );
            //
            para.data = temp;
          }
          return para;
        }),
      };
    }
    case REJECT_PARA: {
      return {
        ...state,
        paras: state.paras.map((para: any) => {
          if (para.node_id === action.payload.node_id) {
            let data = para.data.map((p: any) => {
              if (p.para_info.paraId === action.payload.paraId) {
                p.accepted = "rejected";
                p.nextPredicted= {};
                p.nextPredicted.selected = action.payload.selected;
                p.nextPredicted.issueId = action.payload.issueId;
              }
              return p;
            });
            para.data = data;
          }
          return para;
        }),
      };
    }
    case INIT_REJECT_PARA: {
      return {
        ...state,
        rejectedParas: [],
      };
    }
    case UPDATE_REJECT_PARA: {
      return {
        ...state,
       paras: state.paras.map((para: any) => {
        if(para.node_id === action.payload.node_id)
        {
          let data = para.data.map((p:any)=>{
            if(p.para_info.paraId === action.payload.paraId)
            {
              
              p.nextPredicted.selected = action.payload.selected;
              p.nextPredicted.issueId = action.payload.issueId;
            }
            return p;
          });
          para.data=data;
        }
         return para;
        }),
      };
    }
    case UPDATE_NODE_FEEDBACK: {
      return {
        ...state,
        nodes: state.nodes.map((node: any) => {
          if (node.node_id === action.payload.node_id) {
            if (action.payload.goal !== 0) {
              node.goal = action.payload.goal;
            }
            node.feedback += action.payload.feedback;
          }
          return node;
        }),
      };
    }
    default: {
      return state;
    }
  }
};

export default Stage1Reducer;
