import React, { useEffect, useState } from 'react'
import { ReactComponent as DownChevrolet } from '../../../../assets/down_chevrolet.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg';



interface IParameter {
    paramName: string,
    paramNominalValues:string[]
    paramType: "Numerical" | "Categorical",
    paramValue:string
}


interface ICategoricalProps{
    parameter:IParameter,
    value:string,
    onChange:any,
    options:any[],
    addError?:(message:string| null,paramName:string)=>void,
    error?:string
}


function CategoricalInput({
   parameter,
   value,
   onChange,
   options,
   addError,
   error
}: ICategoricalProps) {

    const [open, setOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    
    const getClassName = (options:string) => {
        console.log("=== options ===",options);
        if (options ) {
            return options
        }
        else return "Please Select A Value";
    }

    const handeStateChange = (value:any)=>{
        console.log("=== dropdown value changed ===",value);
        console.log("=== new value ===",{...parameter,paramValue:value});

        
        onChange({...parameter,paramValue:value});
    }
    // useEffect(()=>{
    //     if(parameter.paramValue === undefined || parameter.paramValue === null){
    //         handeStateChange(parameter.paramNominalValues[0])
    //     }

    // },[])


    useEffect(()=>{

        if(parameter.paramValue === "" || parameter.paramValue === null){
           addError && addError("Please select an option",parameter.paramName);
        }else{
            addError && addError(null,parameter.paramName);
        }

    },[parameter.paramValue]);

   
  

  
    return (
        <div className='w-full'>
            <p className='uppercase text-[0.8vw] font-medium mb-[1vw] '>
                {parameter.paramName.replaceAll("_"," ")}</p>
            {
                open && <div className='h-screen w-screen fixed top-0 left-0 right-0 z-[45]'
                    onClick={() => { setOpen(false);  }}
                ></div>
            }

            {/*  Container  */}
            <div
                onClick={() => { if (!open ) { setOpen(true); } }}
                className={`w-full border-2  py-[0.5vw] px-[0.5vw] rounded-[0.3vw]  
                relative flex items-center justify-between 
                ${
                     open ?   "bg-gray-100 border-transparent" : "bg-white"
                    }
                ${
                     "cursor-pointer hover:bg-gray-100"}
                `}
            >

              
                              <p className='text-[1vw] select-none'>
                            {getClassName(parameter.paramValue)}</p>

                <DownChevrolet className='w-[0.8vw] h-[0.8vw]' />


                {
                    <div className={`absolute  z-[49] left-0 top-[100%]
                        border rounded-md bg-white w-full duration-300 origin-top
                        ${open ? "" : " scale-y-0 opacity-0"}`}>

                        <ul className='bg-white custom_scroll max-h-[10.5vw]  shadow-lg rounded-b '>
                            {
                                options.length ? options.map((option: any) => {
                                    return (<li 
                                            key={option} 
                                            className='flex '
                                            onClick={()=>{
                                                handeStateChange(option);
                                                setOpen(false);
                                                }}>
                                        <label className='cursor-pointer select-none w-full px-[1vw] 
                                                                    flex items-center justify-start 
                                                                    hover:bg-gray-100 '>
                                        <div className='flex items-center justify-between'>
                                            <div className='
                                            h-[1.3vw] w-[1.3vw]
                                            border border-[0.15vw]  rounded-full p-[0.2vw]'>
                                                <div className={` 
                                                                h-full w-full rounded-full
                                                                ${option === parameter.paramValue?"bg-primaryBlue":""}`}></div>
                                            </div>
                                            
                                        </div>
                                        {/* <input  
                                        type={"checkbox"} 
                                        className="w-[1.2vw] h-[1.2vw] border-2"
                                        checked={parameter.paramValue === option ?true: false}
                                        onChange={(e:any) => {
                                            let temp:string | null = parameter.paramValue;
                                            if(e.target.checked){
                                                temp = option


                                            }else{
                                                temp = null

                                            }
                                            handeStateChange(temp);
                                        }}
                                        /> */}

                                        <div className=' px-[1vw] w-[90%] py-[0.6vw]  cursor-pointer  focus  :bg-gray-200 text-[0.9vw] ' >
                                        {option}</div>
                                        </label>
                                        
                                    </li>)
                                }) : <div className='p-[0.5vw] text-[1vw] text-center '>No Results</div>

                            }

                        </ul>
                    </div>
                }
            </div>
                {error && <p className='text-[0.9vw] text-red-500 px-[0.4vw]'>{error}</p>}
        </div>
    )
}

export default CategoricalInput

