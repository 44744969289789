import React, { ReactElement, useEffect, useState } from 'react';
import { ReactComponent as Refresh } from '../../../assets/reload.svg';
import { Popover } from 'antd';

import {callCoverageAPI} from './_apiCalls';
import useWorkflowStore from '../../../store/useWorkflowStore';
import { boolean } from 'mathjs';



interface ITRowProps{
    data:any;
    isLong:boolean
}

const headers:Array<any>=[
    {title:"Class Name",style:"w-[30%]2 border-r2 w-[30%] h-full2 "},
    {title:"Number of Keywords",style:"w-[15%]2 border-r2 w-[15%] text-center h-full2"},
    {title:"Number of Paragraphs",style:"w-[15%]2 border-r2 w-[15%] text-center h-full2"},
    {title:"Keywords with Zero Paragraphs",style:"w-[40%]2 border-r2  w-[40%] h-full2"}
    
];

const Thead=({
    headers,
    isLong
}:any):ReactElement=>{
    return (  
    // <div className={`flex items-center justify-start shadow-sm shadow-sm
    //     ${isLong?""
    //     :""}
    //     border-l
    // `}>
        <>
    {
        headers.map((head:any)=>{
              return  <th className={`flex2 items-center2 px-2 ${head.style}`}>
                    {head.title}
                </th>

        })
    }
        </>

// </div>

    )
}



const TRow =({   data ,isLong }:ITRowProps):JSX.Element=>{

const unusedKeywords = Object.keys(data?.keyword_info)?.filter((key:string)=>data.keyword_info[key]===0) || [];
//console.log("unusedKeyword",unusedKeywords)

  

   return (<tr className=' border-[#fAfAfA] border-b border-b-2 hover:bg-gray-100 h-[3vw]'>

                           <td className={` border-r border-l px-2`}>


                               <div className='  text-[0.8vw] font-bold'>{data.className}</div>
                           </td>

                           <td className={`justify-center  border-r px-2
                           
                           `}>
                               <div className=' text-[#9A9A9A] text-[0.8vw]'>{data.noOfKeywords}</div>
                           </td>

                           <td className={` justify-center border-r px-2
                          
                           `}>
                               <div className=' text-[#9A9A9A] text-[0.8vw]'>{data.noOfParagraphs}</div>
                           </td>

                           <td className={` border-r px-2 col-span-2
                          
                           `}>
                               <div className=' text-[0.8vw] '>{unusedKeywords.map((word:string,index:number)=>{
                                return word + (index<unusedKeywords.length-1? ", ":"")
                               })}</div>
                           </td>
                           
                       </tr>)
   
}


export default function CoverageTab({
    data,
    shouldCallCoverageApi,
    RefreshCoverageData
}:any) {

    const workflowId = useWorkflowStore(state=>state.currentWorkflow)
                                                        ?.workflowId;
    const [clicked,setClicked] = useState<boolean>(false);

   
  return (<div className=' w-full h-[34.5vw] custom_scroll  bg-[#fAFaFA] flex flex-col gap-[2vw] ' >
              

                {/* Warnings Container */}
                <div className=' flex flex-col bg-white  h-full p-[0.7vw]'>
                    
                    
                    <div className='flex items-center justify-between mb-[1vw]  '>
                        <div className='text-[1.1vw] text-[#7A7A7A]  pb-2'>Data Coverage </div>
                        <div className='px-[0.5vw]'>
                            <Popover
                            content={<p 
                                className='text-[0.9vw]'>
                                Refresh coverage data
                                </p>}
                            
                            >
                            <Refresh
                            
                            className='w-[2.2vw] h-[2.2vw]
                                        hover:scale-110 cursor-pointer
                                        duration-200'
                            onClick={async()=>{
                                if(clicked) return;
                                setClicked(true);
                                await RefreshCoverageData();
                            }}
                            />
                            </Popover>
                            </div>
                    </div>

                    {/* Warning Table */}
                    <table className=' '>
                        {/* Header */}
                        <thead className='sticky top-0 z-[2] bg-white h-[3vw] border border-t-0 my-1 shadow-sm'>
                        <tr className=''>
                        <Thead 
                        isLong={data && data.length>10}
                        headers={headers}
                        />
                        </tr>
                        </thead>
                      


                        {/*Rows */}
                        <tbody className='w-full  max-h-[27vw] custom_scroll '>

                        {
                          
                                 data?.length?
                                   data.map((d:any)=>{
                                    return   <TRow 
                                        isLong={false}
                                        key={d.className}
                                        data={d}
                                        
                                        
                                        />
                                }) 
                                    
                               
                            :<div className='w-full h-[20vw] flex items-center justify-center text-[#9A9A9A] text-[1vw]'>
                                No Data
                            </div>
                        }
                          
                         
                        
                        </tbody>

                      




                    </table>




                </div>

                   
            </div>
  )
}
