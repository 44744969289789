import React, { useEffect, useState } from "react";
import { DownOutlined } from '@ant-design/icons';
import CollapsibleComponent from "./CollapsibleComponent";
import useWorkflowStore from "../store/useWorkflowStore";


interface VersionComponent {
  listOfVersion: any;
  createClickHandler: any,
}
let VersionComponent: React.FC<VersionComponent> = ({ listOfVersion, createClickHandler }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const dropDownClickHandler = () => {
    setIsOpen(prevState => !prevState);
    setActiveIndex(-1);
  }

  let workflowStore = useWorkflowStore();

  const collapseClickHandler = (index: number) => {
    if (activeIndex === index)
      setActiveIndex(-1);
    else
      setActiveIndex(index);
  }
  return <>
    {isOpen && <button className="fixed z-10 inset-0  w-full h-full cursor-default" onClick={() => { setIsOpen(false) }}></button>}
    <div className={` relative mt-[1vw] ${isOpen && 'z-[100]'}`}>
      <div className={`flex justify-end relative`}>
        <div className="flex font-medium justify-between items-center rounded-[0.35vw] w-[15vw] bg-[#EAEAEA] py-[0.3vw] px-[0.8vw]">
          <div className="text-[1.2vw] pl-[0.2vw] cursor-pointer" onClick={dropDownClickHandler}>
            {workflowStore.currentWorkflow?.workflowName}
          </div>
          <button onClick={dropDownClickHandler}>
            <img src="/images/down_outline.svg" style={{
              width: "1.1vw",
              height: "1.5vw",
            }} alt="down_outline" />
          </button>
        </div>
      </div>
      {isOpen &&
        <div className="bg-white absolute right-0 border-[#EAEAEA] border-solid shadow-lg shadow-slate-300 pt-[0.2vw] rounded-[0.35vw]  w-[15vw]">
          {
            listOfVersion && listOfVersion.map((elem: any, index: number) => {
              //console.log(index);
              return (
                <CollapsibleComponent createClickHandler={createClickHandler} 
                key={index}
                 index={index}
                  version={elem}
                   activeIndex={activeIndex}
                    collapseClickHandler={collapseClickHandler} />
              )
            })
          }
        </div>
      }
    </div>
  </>
};

export default VersionComponent;
