import React from "react";
import {useNavigate}  from 'react-router-dom';


interface UploadCardProps {
  workflowStore:any
}
export let token =
  JSON.parse(localStorage.getItem("userInfo") as string) !== null
    ? JSON.parse(localStorage.getItem("userInfo") as string).token
    : "";

const UploadCard: React.FC<UploadCardProps> = ({workflowStore}) => {
 //console.log("== Current Workflow Info ==",workflowStore);
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "20vw",
        height: "15vw",
      }}
      className="p-3 bg-white shadow-lg"
    >
      <div className="flex space-x-2">
        <img src="/images/uploadBlue.svg" alt="upload" 
        style={{
          width: "2.5vw",
          height: "2.5vh",
        }}
        />
        
        <p className="text-[1.1vw] font-medium ">Upload</p>
      </div>
      <div className="flex w-full justify-center items-center"
        style={{
          height:"calc(100% - 1.1vw)"
        }}>
        <div className="flex w-full h-full justify-center items-center">
          <div
          
            className={`text-[1vw] flex justify-center items-center text-center -translate-y-[1vw]
            cursor-${!workflowStore?.currentWorkflow?.masterlistLocked && workflowStore?.userProject?.UPLOAD_ACCESS ? "pointer" : "not-allowed"
            } ${
              !workflowStore?.currentWorkflow?.masterlistLocked && workflowStore?.userProject?.UPLOAD_ACCESS
                ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                : "bg-gray-300 text-white"
            }`}
            onClick={() => {
              if (workflowStore?.currentWorkflow?.masterlistLocked === false && workflowStore?.userProject.UPLOAD_ACCESS) {
                navigate("/workflow/upload?workflowId="+workflowStore?.currentWorkflow?.workflowId)
              }
            }}
            style={{
              border: `1px solid ${
                !workflowStore?.currentWorkflow?.masterlistLocked && workflowStore?.userProject?.UPLOAD_ACCESS ? "#2C2C2C" : "white"
              }`,
             
              width: "15vw",
              height: "2.5vw",
            }}
          >
            Upload Files
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCard;
