import React, { useState, useEffect } from "react";
import Axios from "../utils/Axios";
import useWorkflowStore from "../store/useWorkflowStore";


interface CollapsibleComponent2 {
  index: number;
  userInfo: any;
  activeIndex: number;
  collapseClickHandler: any;
  createClickHandler: any;
}




let CollapsibleComponent2: React.FC<CollapsibleComponent2> = ({ index, userInfo, activeIndex, collapseClickHandler, createClickHandler }) => {

  const [uploadAccess, setUploadAccess] = useState<boolean>(userInfo.user_project_info ? userInfo.user_project_info.UPLOAD_ACCESS : false);
  const [masterlistAccess, setMasterlistAccess] = useState<boolean>(userInfo.user_project_info ? userInfo.user_project_info.MASTERLIST_ACCESS : false);
  const [validationAccess, setValidationAccess] = useState<boolean>(userInfo.user_project_info ? userInfo.user_project_info.VALIDATION_ACCESS : false);
  const [trainingAccess, setTrainingAccess] = useState<boolean>(userInfo.user_project_info ? userInfo.user_project_info.TRAINING_ACCESS : false);
  // const [deploymentAccess, setDeploymentAccess] = useState<boolean>(userInfo.user_project_info ? userInfo.user_project_info.DEPLOYMENT_ACCESS : false);


  const cancelClickHandler = () => {
    /** Cancel Selections and Close Div */
    setUploadAccess(userInfo.user_project_info ? userInfo.user_project_info.UPLOAD_ACCESS : false);
    setMasterlistAccess(userInfo.user_project_info ? userInfo.user_project_info.MASTERLIST_ACCESS : false);
    setValidationAccess(userInfo.user_project_info ? userInfo.user_project_info.VALIDATION_ACCESS : false);
    setTrainingAccess(userInfo.user_project_info ? userInfo.user_project_info.TRAINING_ACCESS : false);
    // setDeploymentAccess(userInfo.user_project_info ? userInfo.user_project_info.DEPLOYMENT_ACCESS : false);

    collapseClickHandler(-1);
  }

  let handleUploadAccess = (event: any) => {
    setUploadAccess(event.currentTarget.checked);
  };

  let handleMasterlistAccess = (event: any) => {
    setMasterlistAccess(event.currentTarget.checked);
  };

  let handleValidationAccess = (event: any) => {
    setValidationAccess(event.currentTarget.checked);
  };

  let handleTrainingAccess = (event: any) => {
    setTrainingAccess(event.currentTarget.checked);
  };


  const template:any={
    "multiclass_classification":{
      "annotation_workflow":{
        permissionInputs:[
          {
            name:"Upload",
            value:uploadAccess,
            onChange:handleUploadAccess
          },
          {
            name:"Masterlist",
            value:masterlistAccess,
            onChange:handleMasterlistAccess
          },
          {
            name:"Validate",
            value:validationAccess,
            onChange:handleValidationAccess
          },
          
        ]
  
      },
      "annotation_prediction_workflow":{
        permissionInputs:[
          {
            name:"Upload",
            value:uploadAccess,
            onChange:handleUploadAccess
          },
          {
            name:"Masterlist",
            value:masterlistAccess,
            onChange:handleMasterlistAccess
          },
          {
            name:"Validate",
            value:validationAccess,
            onChange:handleValidationAccess
          },
          {
            name:"Train",
            value:trainingAccess,
            onChange:handleTrainingAccess
          }
          
        ]
  
      }
    },
    "multilabel_classification":{
      "annotation_workflow":{
        permissionInputs:[
          {
            name:"Upload",
            value:uploadAccess,
            onChange:handleUploadAccess
          },
          {
            name:"Masterlist",
            value:masterlistAccess,
            onChange:handleMasterlistAccess
          },
          {
            name:"Validate",
            value:validationAccess,
            onChange:handleValidationAccess
          },
          
        ]
  
      },
      "annotation_prediction_workflow":{
        permissionInputs:[
          {
            name:"Upload",
            value:uploadAccess,
            onChange:handleUploadAccess
          },
          {
            name:"Masterlist",
            value:masterlistAccess,
            onChange:handleMasterlistAccess
          },
          {
            name:"Validate",
            value:validationAccess,
            onChange:handleValidationAccess
          },
          {
            name:"Train",
            value:trainingAccess,
            onChange:handleTrainingAccess
          }
          
        ]
  
      }
    }
  }

  const projectType = useWorkflowStore(state=>state.currentProject?.projectType) || "";
  const workflowType = useWorkflowStore(state=>state.currentProject?.workflowType) || "";
  //console.log("types",projectType,workflowType)
 
  //console.log(userInfo.user_project_info)
  // let handleDeploymentAccess = (event: any) => {
  //   setDeploymentAccess(event.currentTarget.checked);
  // };
  // console.log(userInfo.user_project_info)
  return <>
    <div className="flex justify-between py-[0.2vw] px-[0.9vw] cursor-pointer" onClick={() => { collapseClickHandler(index) }}>
      <div className={`font-normal text-[1vw]  `}>
        {userInfo.firstName + " " + userInfo.lastName}
      </div>
    </div>
    {
      activeIndex === index &&
      <div className="bg-white flex flex-col gap-3 py-[1vw] px-[1vw]">
        <div className="flex flex-col gap-1">
          <div className="font-thin text-black text-[0.9vw]">
            SELECT ACCESS(es)
          </div>
          {
            template[projectType][workflowType]?.permissionInputs.map((permission:any)=>{
             return( <div className="flex gap-2 items-center ">
              <input
                type="checkbox"
                checked={permission.value}
                onChange={permission.onChange}
                className="hover:cursor-pointer checked:bg-primarycolor  w-[1vw] h-[1vw]"
              />
              <div className="font-semibold text-[1vw]">{permission.name}</div>
            </div>)
            })
          }
          {/* <div className="flex gap-2 items-center ">
            <input
              type="checkbox"
              checked={uploadAccess}
              onChange={handleUploadAccess}
              className="hover:cursor-pointer checked:bg-primarycolor  w-[1vw] h-[1vw]"
            />
            <div className="font-semibold text-[1vw]">Upload</div>
          </div> */}
          {/* <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              checked={masterlistAccess}
              onChange={handleMasterlistAccess}
              className="hover:cursor-pointer checked:bg-primarycolor  w-[1vw] h-[1vw]"

            />
            <div className="font-semibold text-[1vw]">Masterlist</div>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              checked={validationAccess}
              onChange={handleValidationAccess}
              className="hover:cursor-pointer checked:bg-primarycolor  w-[1vw] h-[1vw]"

            />
            <div className="font-semibold text-[1vw]">Validate</div>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              checked={trainingAccess}
              onChange={handleTrainingAccess}
              className="hover:cursor-pointer checked:bg-primarycolor  w-[1vw] h-[1vw]"

            />
            <div className="font-semibold text-[1vw]">Train</div>
          </div> */}
         
        </div>

        <div className="flex justify-between items-center">

          <div className="flex flex-1">

            <button
              className="border border-[#1BA94C] bg-[#1BA94C] text-white px-[1.9vw] py-[0.25vw] hover:bg-white hover:text-[#1BA94C]  duration-700  outline-none rounded-[0.4vw]"
              type="button"
              onClick={() => createClickHandler(
                {
                  UPLOAD_ACCESS: uploadAccess,
                  MASTERLIST_ACCESS: masterlistAccess,
                  VALIDATION_ACCESS: validationAccess,
                  TRAINING_ACCESS: trainingAccess,
                  // DEPLOYMENT_ACCESS: deploymentAccess,
                }, userInfo)
              }
            >
              <p className="text-[0.9vw]">Save</p>
            </button>
          </div>
          <div className="flex flex-1">
            <button
              className="border border-primarycolor text-primarycolor  hover:bg-primarycolor hover:text-white  duration-700 px-[1.5vw] py-[0.25vw] outline-none rounded-[0.4vw]"
              type="button"
              onClick={cancelClickHandler}
            >
              <p className="text-[0.9vw]">Cancel</p>
            </button>
          </div>
        </div>
      </div>
    }
  </>
};

export default CollapsibleComponent2;
