
import React  from "react";
import { useState, useEffect,useRef } from "react";

import styles from "./modelTraining2.module.css";
import { Dropdown, Menu, Popover, Tooltip } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { nodesAtom } from "../../../masterlist/atoms";
import { DownOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import useWorkflow from "../../../../store/useWorkflowStore";
import Axios from '../../../../utils/Axios';
import { current } from "immer";
import { useNavigate ,useLocation} from "react-router-dom";
import useEntityStore from "../../../../store/entityStore";
// import ParameterTable from "../../../Training/ParameterTable";
import { callGetSelectedModelAPI } from "../../../llm-components/model-comparison/_apiCall";

const ParameterTable = ({data}:any)=>{

  const [isMounted,setIsMounted] = useState<boolean>(false);


  useEffect(()=>{
    setIsMounted(true);
  },[]);


  return <div className={`duration-200 origin-top
  ${isMounted?"scale-y-100":"scale-y-0"}
  `}>
      {
        data?.parameters && data?.parameters?.map((param:any)=>{

          return <div className="flex h-[3vw]  border-b items-center justify-between">
            <div
            className="text-[1.1vw] 
                    text-black capitalize
                    
                    "
            >{param.paramName.replaceAll("_"," ")}</div>
            <div className="text-[#7A7A7A]  text-[1.1vw]">
              {
                param.paramType ==="Float" || param.paramType === "Integer"?
                <div>
                    {param.paramStartValue} - {param.paramEndValue}
                </div>
                :<div className="select-none">
                    <Popover
                    content={
                      <div className="text-[1vw]">
                        {param?.paramNominalValues.join(", ")}
                      </div>
                    }
                    >
                    {param?.paramNominalValues.join(",").slice(0,15)}...
                    </Popover>

                    
                </div>
              }

            </div>
          </div>

        })
      }
  </div>
}

export interface ModelTraining2Props {}

export interface Report {
  f1_score: number;
  precision: number;
  recall: number;
  support: number;
  training_paras: number;
  info: string;
}

const ModelTraining2: React.SFC<ModelTraining2Props> = () => {

  const [details, setDetails] = useState({
    Microf1: 0,
    level: 0,
    trainingDocs: 0,
    trainingIssues: 0,
    trainingParas: 0,
    totalDocs: 0,
  });



  let { currentWorkflow } = useWorkflow();
  let navigate = useNavigate()


  const tableRef = useRef<HTMLDivElement>(null);
  const [showDetailedReport, setShowDetailedReport] = useState<boolean>(false);
  const [detailedReport, setDetailedReport] = useState<Array<Report>>([]);
  const entities = useEntityStore(state=>state.entities);

  const [selectedModel,setSelectedModel] = useState<any>(null);
  const [showModelParameter,setShowModelParameters] = useState<boolean>(false);



  useEffect(()=>{
    if(showDetailedReport)
    {
      tableRef.current?.scrollIntoView({behavior:"smooth"});
    }


  },[showDetailedReport]);

  useEffect(() => {

    if(currentWorkflow)
    {
      const TrainInfo = {

        MicroF1: 0.77,
        totalDocs: 2,
        trainingDocs: 1,
        trainingIssues: 4,
        trainingParas: 276
      }

        let data = currentWorkflow?.alpdsInfo.trainInfo || TrainInfo;
        if(!data)
        {
            toast("Trainind Data is not available");
        }
      setDetails({
               Microf1: data.MicroF1,
               level: 2,
               trainingDocs: data.trainingDocs,
               trainingIssues: data.trainingIssues,
               trainingParas: data.trainingParas,
               totalDocs: data.totalDocs,
             });
             console.log("training data",data);
             handleConcat(currentWorkflow.alpdsInfo?.classificationReports?.nodeReport);
    }
  }, [currentWorkflow]);
  const handleConcat = (reports: any) => {
    let temp: Array<any> = [];

    if(!reports){
      let temp:any={};
        entities.forEach(ent=>{
          temp[ent.entityId] ={};
          temp[ent.entityId].precision=12;
          temp[ent.entityId].recall=34;
          temp[ent.entityId]["f1-score"]=23;
          temp[ent.entityId].support=23;
          temp[ent.entityId]["training paras"]=23
          

        })
        reports =temp;


    }

    Object.entries(reports).forEach((item: any) => {
      let obj: any = {
        info: item[0],
        f1_score: item[1]["f1-score"],
        precision: item[1]["precision"],
        recall: item[1]["recall"],
        support: item[1]["support"],
        training_paras: item[1]["training paras"],
      };
      temp.push(obj);
    });
    console.log("=== temp ===",temp)

    setDetailedReport(temp);
  };
  const nodes = useRecoilValue(nodesAtom);

  const toggleDetailedReport = () => {
    setShowDetailedReport(!showDetailedReport);
  };

  let [info, setInfo] = useState({
    totalParas: 0,
    totalTrainingParas: 0,
    totalNoClasses: 0,
    totalDocs: 0,
  });

  // for calculating the infos
  useEffect(() => {

    const fetchTotalParas = async()=>{
        try{
           let res = await Axios.post("/para/getParaCount",{
               workflowId:currentWorkflow?.workflowId
           });

           console.log("===  total paras===",res.data.data);
           let data = res.data;
           let totalDocs = data.data.totalParas.fileUploaded as number;
           let totalParas = data.data.totalParas.totalPara as number;
   
           let totalClasses =
             currentWorkflow?.alpdsInfo?.trainInfo?.trainingIssues || 0;
           let totalTrainingParas =
             currentWorkflow?.alpdsInfo?.trainInfo?.trainingParas || 0;
   
           setInfo({
             totalParas: totalParas,
             totalTrainingParas: totalTrainingParas,
             totalNoClasses: totalClasses,
             totalDocs: totalDocs,
           });


        }catch{
            toast("Could no get total paras",{type:toast.TYPE.ERROR});

        }
    }
    const fetchUserSelectedModel = async()=>{
      if(!currentWorkflow) return;
        const res = await callGetSelectedModelAPI(currentWorkflow?.workflowId);
      console.log("user selected model",res.userSelectedModel);
        setSelectedModel(res.userSelectedModel);
    }


    fetchUserSelectedModel();
    fetchTotalParas();


  }, []); 

  return (
    <div className="w-full ">
      <div className="w-full flex border-b border-gray-200 py-5">
        <div className="w-1/4 h-full flex flex-col justify-center">
          <div className="text-gray-400  text-[1vw] uppercase">Total Paragraphs</div>
          <div className="font-semibold  text-[1vw]">{info.totalParas}</div>
        </div>
        <div className="w-1/4 h-full flex flex-col justify-center">
        <div className="text-gray-400 text-[1vw]  uppercase">
            No. of paragraphs validated
          </div>
          <div className="font-semibold  text-[1vw]">{info.totalTrainingParas}</div>
        </div>
        <div className="w-1/4 h-full flex flex-col justify-center">
          <div className="text-gray-400 uppercase  text-[1vw]">No. of nodes</div>
          <div className="font-semibold  text-[1vw]">{info.totalNoClasses}</div>
        </div>
        <div className="w-1/4 h-full flex flex-col justify-center">
          <div className="text-gray-400 uppercase  text-[1vw]">Total no. of documents</div>
          <div className="font-semibold  text-[1vw]">{info.totalDocs}</div>
        </div>
      </div>
      {/* <section className={styles.training_section}>
        <img src="/images/training_complete_green.svg" alt="Training Icon" />
        <div>Training complete</div>
        <div className={styles.training_percentage}>100%</div>
      </section> */}
      {/* Training Section */}
      {/* Data Section */}

      {/* Graph Section */}
      <section className={styles.graph_section}>
        <div className="flex w-full justify-between h-full items-center">
          <div
            className={`${styles.detailed_report_btn} hover:shadow-md text-[1vw] py-[0.5vw] px-[1vw] duration-150`}
            onClick={() => toggleDetailedReport()}
          >
            {showDetailedReport === false ? "VIEW" : "HIDE"} DETAILED REPORT
          </div>
        
        </div>
        <div className={'w-full'}>
          <div className={'flex w-full h-[8vw] bg-white'}>
            <div
              style={{
                width: `${details.Microf1 * 100}%`,
                backgroundColor: details.Microf1 < 0.75 ? "#F59F00" : "#1BA94C",
                // height:"8vw"
              }}
            ></div>
          </div>
        </div>
        <div className={"flex justify-between text-gray-400"}>
          {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((num) => {
            return <div className="text-[1vw]" >{num}</div>;
          })}
        </div>
        <br />
      </section>
      <section className={'flex justify-between mt-[1vw] pb-[2vw]'}>
        <div className={'flex flex-col w-[33%] text-[#7a7a7a] text-[1.2vw]'}>
          <div className={'flex items-center w-[72%] pb-[1vw] border-b-[0.1vw]'}>
            <img src="/images/tip_green.svg" className="w-[1.6vw] h-[1.6vw] mr-[0.6vw]" alt="Tip Icon" />
            <div className="text-[1.2vw] text-[#7A7A7A]">Model Information</div>
          </div>
          <div className={'flex items-center w-[72%] h-[3vw] border-b-[0.1vw]'}>
            <div className="text-[1.2vw] text-[#7A7A7A]">{selectedModel?.userSelectedModel}</div>
          </div>
          <div className={' w-[70%]'}>
            <ParameterTable 
            data={selectedModel || {}}
            isOpen={true}

            />
          </div>
        </div>
        <div className={'w-[25%]'}>
          <div className={'pb-[1vw] border-b-[0.1vw]'}>
            <div className="text-[1.4vw]">Accuracy Score</div>
          </div>
          <div className={'text-[3vw] pt-[1vw] pb-[1vw] text-primarycolor'}>
            {(details.Microf1 * 100).toFixed(2)}%
          </div>
          <div
            className={`flex justify-center items-center bg-primarycolor cursor-pointer rounded-[0.4vw] text-white text-[1.1vw] py-[1vw] hover:bg-white hover:text-primarycolor border-2 border-transparent duration-150 hover:border-primarycolor`}
            onClick={() => {
              navigate("/workflow/modeltraining-2/dep?workflowId="+currentWorkflow?.workflowId);
             // history.push("/app/pa/ws/model-training2/dep");
            }}
          >
            DEPLOYMENT OPTIONS
          </div>
        </div>
      </section>
      {/* Graph Section */}
      {/* Detailed Report Section */}
      {showDetailedReport && (
        <section className={'border-t-[0.1vw]'}>
          <div ref={tableRef } className={'border-b-[0.1vw] flex items-center py-[1vw]'}>
            <div className=" text-[1.2vw] mr-[1vw]">Detailed Report</div>
            <img
            className="w-[1.7vw] h-[1.7vw]"
              src="/images/detailed_report_green.svg"
              alt="Detailed Report Icon"
            />
          </div>
          <table className={`${styles.details_table} text-[1.1vw]`}>
            <tr>
              <th className={"text-left !text-[#000]"}>Class</th>
              <th>Precision</th>
              <th>Recall</th>
              <th>F1 Score</th>
              <th>Support</th>
              <th>Training Paras</th>
            </tr>
            {detailedReport.map(
              (
                { info, precision, recall, f1_score, support, training_paras },
                index
              ) => (
                <>
                  { (nodes.filter((node) => node.node_id === info)[0]?.is_model_class === true || info==="No Tag") && (
                    <tr>
                      <td>
                        {
                        
                          info==="No Tag"?info:nodes.filter((node) => node.node_id === info)[0]
                            .node_name
                        }
                      </td>
                      <td>{precision.toFixed(2)}</td>
                      <td>{recall.toFixed(2)}</td>
                      <td>{f1_score.toFixed(2)}</td>
                      <td>{support}</td>
                      <td>{training_paras}</td>
                    </tr>
                  )}
                </>
              )
            )}
          </table>
        </section>
      )}
    </div>
  );
};

export default ModelTraining2;
