import React, { useEffect, useRef, useState } from 'react'
import { IEntity } from '../types';
import useEntityStore from '../../../store/entityStore';
import EditMenu from './EditMenu';
import ColorPicker from './ColorPicker';
import { toast } from 'react-toastify';
import { updateEntity } from './_apiCalls';
import useWorkflowStore from '../../../store/useWorkflowStore';




type EntityProps={
    entity:IEntity,
    index:number
}

type EntityItemProps={
    entity:IEntity,
    index:number,
    active:boolean
}




const EditEntity =({entity,index,active}:EntityItemProps)=>{

    const workflowId = useWorkflowStore().currentWorkflow?.workflowId;
    const [isMounted,setIsMounted] = useState<boolean>(false);
    const [curColor,setCurColor] = useState<string>(entity.color);
    const [entityName,setEntityName] = useState<string>(entity.entity);
    const [description,setDescription] = useState<string>(entity.entityDescription);
    const [abbr,setAbbr] = useState<string>(entity.abbreviation);


    const inputRef = useRef<HTMLInputElement>(null);
    const nameRef = useRef(entityName);
    const abbrRef = useRef(abbr);
    const descriptionRef =useRef(description);
    const colorRef = useRef(curColor);
    
    const divRef = useRef<HTMLDivElement>(null);
    const entityStore = useEntityStore();
 
    const getContrastYIQ =(hexcolor:string)=>{
        var r = parseInt(hexcolor.substring(1,3),16);
        var g = parseInt(hexcolor.substring(3,5),16);
        var b = parseInt(hexcolor.substring(5,7),16);
        var yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }


    const rowWidth:string = "w-[20%]";
    const descriptionWidth:string = "w-[40%]";
    const inputStyle = "focus:outline-none";

  

    const saveData =(e:any)=>{
       // e.preventDefault();
        if(
            nameRef.current === "" 
            || abbrRef.current ===""

        ){
            console.log("values->",entityName,abbr);
            //toast.warn("Please fill all required fields")
            alert("Please fill out correct values->"+entityName+abbr);
            return false;
        }
        console.log("=== before save ==="
        ,entityStore.entities,
        nameRef.current.toLowerCase());

        if(entityStore
            .entities
            .find(
                (ent,i)=>i!== index && (ent.abbreviation === abbrRef.current 
                || ent.entity.toLowerCase() === nameRef.current.trim().toLowerCase())
                )
                ){
                    toast.warn(`An entity of same attributes already exists!
                    `)
                    
                    return false;
                }

        const tempEnt = [...entityStore.entities];
        tempEnt[index].isEditing=false;
        tempEnt[index].abbreviation =abbrRef.current;
        tempEnt[index].entityDescription=descriptionRef.current;
        tempEnt[index].entity = nameRef.current.trim();
        tempEnt[index].color = colorRef.current;

        if(workflowId){

            entityStore.setEntities(tempEnt);
            entityStore.setAddNew(true);
            alert("your changes are saved"); 
            toast.success("Saved",)
            updateEntity(workflowId,tempEnt[index]);
        }
        return true;

    }



    useEffect(()=>{
        
        const handleMouseClick =(e:any)=>{
           // e.preventDefault();
            if(divRef.current && !divRef.current.contains(e.target)){
                saveData(e);
            }
    
    
        };

        setIsMounted(true);
       if(active){
           document.addEventListener("mousedown",handleMouseClick)
       } else{
        const tempEnt = [...entityStore.entities];
        tempEnt[index].isEditing = false;
        entityStore.setEntities(tempEnt);
       }


    return ()=>document.removeEventListener("mousedown",handleMouseClick);



    },[active])

    useEffect(()=>{
        if(isMounted){
            inputRef.current?.focus();
        }


    },[isMounted])
    
    return (<div
    ref={divRef}
    className='flex items-center w-full  h-full px-2 text-[1vw]'>
        <div className={`${rowWidth}
                        
                        flex items-center justify-start
                        `}>

            <div 
            className={`rounded-[0.2vw] p-[0.5vw] w-[11vw] h-[2.5vw]`}
            style={{
                backgroundColor:curColor
            }}
            
            >
            
                <input
                type="text"
                
                ref={inputRef}
                value={entityName}
                onChange={(e)=>{
                    setEntityName(e.target.value)
                    //setAbbr(e.target.value.slice(0,3).toUpperCase())
                    nameRef.current = e.target.value;
                    //abbrRef.current=e.target.value.slice(0,3).toUpperCase();
                    
                    }}
                style={{
                    color:getContrastYIQ(curColor)
                }}
                    className={`${inputStyle} 
                    bg-transparent
                    w-full
                    
                    `}
                    maxLength={20}
                    placeholder='Enter entity name'
                    />
            </div>                            
        </div>

        <div className={`${descriptionWidth}
                        flex items-center justify-start
                        `}>
            <input
            value ={description}
            onChange={(e)=>{
                setDescription(e.target.value)
                descriptionRef.current = e.target.value;
            }}
            className={`${inputStyle} w-[80%] `}
            maxLength={50}
            placeholder='Enter description here' />
        </div>

        <div className={`${rowWidth} 
                        flex items-center justify-start
                        `}>
            <input
            value={abbr}
            onChange={(e)=>{
                setAbbr(e.target.value.toUpperCase())
                abbrRef.current=e.target.value.toUpperCase();
            }}
            className={`${inputStyle}`}
            placeholder='Enter abbreviation here'
            maxLength={3}
            />
        </div>

        <div className='flex gap-[1vw]'>
            <ColorPicker 
            color={curColor}
            setColor={(color)=>{
                setCurColor(color)
                colorRef.current=color
            }}
            />
            <EditMenu
           disabled={true}
            index={index} />
        </div>
    </div>)

}

const ShowEntity =({entity,index,active}:EntityItemProps)=>{

   
    const [curColor,setCurColor] = useState<string>(entity.color);
    const entityStore = useEntityStore();
 
    const getContrastYIQ =(hexcolor:string)=>{
        var r = parseInt(hexcolor.substring(1,3),16);
        var g = parseInt(hexcolor.substring(3,5),16);
        var b = parseInt(hexcolor.substring(5,7),16);
        var yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

  


    const rowWidth:string = "w-[20%]";
    const descriptionWidth:string = "w-[40%]";
    const inputStyle = "focus:outline-none";
    
    return (<div
    className='flex items-center w-full  h-full px-2 text-[1vw]'>
        <div className={`${rowWidth}
                        
                        flex items-center justify-start
                        `}>

            <div 
            className={`rounded-[0.2vw] p-[0.5vw] w-[11vw] h-[2.5vw] `}
            style={{
                backgroundColor:curColor
            }}
            
            >
            
                <p

                style={{
                    color:getContrastYIQ(curColor)
                }}
                    className={`${inputStyle} 
                    bg-transparent
                    w-full
                    overflow-hidden
                    
                    `}
                   
                    >{entity.entity}</p>
            </div>                            
        </div>

        <div className={`${descriptionWidth}
                        flex items-center justify-start
                        `}>
            <p
            className={`${inputStyle} w-[80%] `}
           >{entity.entityDescription}</p>
        </div>

        <div className={`${rowWidth} 
                        flex items-center justify-start
                        `}>
            <p
            className={`${inputStyle} font-medium`}
           
            >{entity.abbreviation}</p>
        </div>
        {
           
                 <div>
                    <EditMenu
                        disabled={false}
                        index={index}
                        isDefault={entity.type === "default"} />
                </div>
        }
       
    </div>)

}

export default function Entity({
    entity,
    index
}:EntityProps) {
    
    const entityStore = useEntityStore();
    const isActive = index === entityStore.activeEntity;


    return (
    <div className={`h-[3vw] bg-white my-2
                        hover:shadow-sm
                        ${isActive?"border-2":""}
                        `}
            onClick={()=>{
                if(isActive) return;
                entityStore.setActiveEntity(index);
            }}>
        {/* Add Inputs */}
        {entity.isEditing ? <EditEntity 
                                entity={entity}
                                index={index}
                                active={isActive}
                                 />
                        :<ShowEntity
                        entity={entity}
                        index={index}
                        active={isActive}
                        />
                        }

        {/* Add Information Components */}
    </div>
  )
}
