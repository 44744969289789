

import { Divider } from "antd";
import * as React from "react";
import { useState } from "react";
// import { apiNetworkCall } from "../../../../../../utils/api";
// import { GENERATE_TOKEN } from "../../../../../../utils/server-urls";
import { Modal, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import Axios from "../../../../utils/Axios";
import './index.css'
import Button from "../../../mlc-components/model-comparison/Button";
const { confirm } = Modal;
export interface Deploy3Props {}

const FtDeploy3: React.SFC<Deploy3Props> = () => {
  function showConfirm() {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "On Regenerating keys your previous key will stop working!",
      onOk() {
        generateToken();
      },
      onCancel() {},
      centered: true,
      okText: "Proceed",
    });
  }

  let [passConVisible, setPassVisible] = useState(false);
  const [loading,setLoading] = useState<boolean>(false);

  let [token, setToken] = useState("");

  let [password, setPassword] = useState("");

  let validateGenerateToken = () => {
    if (token !== "") {
      showConfirm();
    } else {
      generateToken();
    }
  };

  let generateToken = async() => {
    if (password !== "") {
      setLoading(true);
      try{

        let res = await Axios.post("/integration/generate-token",{
          password
        });
        if(res.data.result)
        {
          setToken(res.data.data.token);
          toast("Token was successfully generated.",{type:toast.TYPE.SUCCESS});
        }

      }catch(err)
      {
        console.log("=== Error while generating token. Please reload the page. ===");
        toast(" Error while generating token. Please reload the page.",{type:toast.TYPE.ERROR});
      }
      setLoading(false);
      setPassVisible(false);
    } else {
      alert("Please enter a password");
    }
  };

  return (
    <div className="w-full">
      <div className="bg-white rounded-md w-full p-[1vw]">
        <p className="font-sans text-[1.3vw]" style={{ color: "#2C2C2C" }}>
          Prediction API Support
        </p>
        <Divider />
        <p
          className="font-sans font-normal text-[1.1vw] pb-[1.5vw]"
        >
          Perform Predictions at real time using our API and PyPI package on new
          data using your trained model. Refer to api documentation to know the
          exact steps of Performing Prediction and deploying our algorithm to
          your application. Use the below API token for secure authentication
          process.
        </p>
      </div>
      <div className="flex mt-[2vw] w-full">
        <input
          //   onMouseEnter={(e) => {
          //     navigator.clipboard.writeText(token);
          //     notification.success({
          //       message: "Copied to clipboard",
          //     });
          //   }}
          disabled
          value={token}
          onChange={(e) => setToken(e.target.value)}
          type="text"
          className="px-[0.5vw] cursor-pointer py-[0.1vw] border border-black bg-transparent rounded-l-[0.5vw] w-9/12"
        ></input>
        <div
          onClick={() => {
            navigator.clipboard.writeText(token);
            toast("Copied successfully", { type: "success" });
          }}
          className={"flex items-center border-b border-t border-blueprimary px-[0.5vw] py-[0.2vw] cursor-pointer"}
        >
          <img className="w-[1.5vw]" src="images/copy_squares.svg" alt="copy" />
        </div>
        <button
          onClick={() => setPassVisible(true)}
          className="px-[0.5vw] w-[15vw] py-[0.7vw] text-blueprimary border border-blueprimary text-[1.1vw] rounded-r-[0.5vw]"
        >
          REGENERATE KEY
        </button>
      </div>
      <Modal
        open={passConVisible}
        title="Please enter your password"
        width={"30vw"}
        centered
        onOk={() => generateToken()}
        onCancel={() => setPassVisible(false)}
        footer={[
          <Button
          styles={'!w-[5vw] !bg-white !text-primaryBlue !h-[2.5]'}
          text="Cancel"
          loading={loading}
          disabled={loading}
          onClick={()=>setPassVisible(false)} 
          ></Button>,
          <Button
          styles={"!w-[5vw] !h-[2.6vw]"}
          text="Submit"
          loading={loading}
          disabled={loading}
          onClick={()=>generateToken()} 
          ></Button>,
          
        ]}
        className="w-[25vw]"
      >
        {/* <div className="text-[1.1vw]">Please enter your password </div> <br /> */}
        <Input
          onChange={(e) => setPassword(e.target.value)}
          type={"password"}
          placeholder={"Password"}
          className="text-[1.1vw]"
        />
      </Modal>
    </div>
  );
};

export default FtDeploy3;
