import { useEffect, useState } from "react";

const ParameterTable = ({data}:any)=>{

    const [isMounted,setIsMounted] = useState<boolean>(false);
  
    useEffect(()=>{
      setIsMounted(true);
    },[]);
  
  
    return <div className={`duration-200 origin-top
    ${isMounted?"scale-y-100":"scale-y-0"}
    `}>
        {
          data?.parameters && data?.parameters?.map((param:any)=>{
  
            return <div className="flex h-[3vw]  border-b items-center justify-between">
              <div
              className="text-[1.1vw] 
                      text-black capitalize
                      
                      "
              >{param.paramName.replaceAll("_"," ")}</div>
              <div className="text-[#7A7A7A]  text-[1.1vw]">{param.paramValue}</div>
            </div>
  
          })
        }
    </div>
  }

  export default ParameterTable;