import React, { RefObject, useEffect, useRef, useState } from 'react'
import {read,utils,writeFileXLSX,writeFile} from 'xlsx';


import {AiOutlineCloudDownload,AiOutlineLoading3Quarters} from 'react-icons/ai';


import Axios from '../../utils/Axios';
import useCompanyUsersStore from '../../store/useCompanyUsersStore';
import usePermissionStore from '../../store/usePermissionStore';
import useWorkflowStore from '../../store/useWorkflowStore';
import {useRecoilValue} from 'recoil';
import { nodesAtom } from '../masterlist/atoms';
import { toast } from 'react-toastify';
import { d } from 'msw/lib/glossary-297d38ba';
import { Popover } from 'antd';

export default function Index() {

    const [loading,setLoading] = useState<boolean>(false); 
    const [canDownload,setCanDownload] = useState<boolean>(false);
    const [csvData,setCsvData] = useState<any[]>([]); 
    const [csvHeaders,setCsvHeaders] = useState<any[]>([]);

    const companyUsers = useCompanyUsersStore(state=>state.companyUsers);
    const userPermissions = usePermissionStore(state=>state.permissions);

    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const project = useWorkflowStore(state=>state.currentProject);
    const nodes = useRecoilValue(nodesAtom);
    // const separator = ";"
    // type linkType =typeof CSVLink

    // const csvRef = useRef<any>(null);



   // console.log("=== company users ===",companyUsers);
    //console.log("=== user permission ===",userPermissions);


    const getClassName=(node_id:string)=>{

         if(node_id === "No Tag") return "'No Tag'";

         if(!node_id) return "'No Associated Class'" 

       return `'${nodes.find(n=>n.node_id === node_id)?.node_name}'`
 
    }

    const getUsername=(userId:string)=>{

        const user = companyUsers.find(u=>u.userId === userId);

        if(!user) return "Use Not Found ";

        return `${user.firstName} ${user.lastName}`
        
 
    }

    const formatClasses = (classes:any[]) =>{

        if(!classes.length) return "";
        
        return "[" 
                + classes.map((c:any)=>getClassName(c.issueId))
                + "]"

        


    }



    const downloadData =async()=>{
        

         const res1:any = await Axios.post("/para/downloadAllData",{
            workflowId:workflow?.workflowId,
            stage:"1"
        });
        const res2:any = await Axios.post("/para/downloadAllData",{
            workflowId:workflow?.workflowId,
            stage:"2"
        });
        const res3:any = await Axios.post("/para/downloadAllData",{
            workflowId:workflow?.workflowId,
            stage:"seed"
        });

        const stage1Data:any[] =res1.data.data;
        const stage2Data:any[] = res2.data.data;
        const seedData:any[] = res3.data.data;

        const data:any[] = [];

       // console.log("=== stage1 ===",stage1Data);
       // console.log("=== stage2 ===",stage2Data);

       if(seedData.length){
        seedData.forEach((data,index,array)=>{

             if(index === 0) console.log(data);
             const issueIds:string[] = array[index]
                             .workflows[workflow?.workflowId || ""]
                             .uploadType["0"].issueID;
             
             
             array[index].stage ="seed";
             array[index].validations =[];
             array[index].paraInfo ={};
             array[index].paraInfo.paraData =array[index].paraData;

             


             array[index].result=issueIds.map((issueId:string)=>{
                 return {
                     issueId,
                     action:"accepted"
                 }
             })


             
         })

         data.push(...seedData);
     }
        if(stage1Data.length){

            stage1Data.forEach((data,index,array)=>{
                array[index].stage ="1"
            });
           // console.log("=== stage1 ===",stage1Data);

            data.push(...stage1Data);
        }


        if(stage2Data.length){
            stage2Data.forEach((data,index,array)=>{
                array[index].stage ="2";
            })

            data.push(...stage2Data);
        }

      
       // console.log(data);
      
       
        let dataForExcel:any[] =[];
        let userValidations:any ={}
        data.forEach((d)=>{
            let temp:any = {};
            temp.Id = d.paraId;
            temp["Paragraph Text"] = d.paraInfo.paraData;
            temp["Stage"]=d.stage;
            temp["Batch"]=d.stage ==="2"?d.batchId:"";
            
            
            Object.keys(d.validations).forEach((key:string)=>{
                if(!userValidations[key]){
                    userValidations[key]=getUsername(key);
                }

                //find user's first name and lastname and add it here
                temp[userValidations[key]] = formatClasses(d.validations[key]);
            });

            temp["Class Name"] = formatClasses(d.result);
            dataForExcel.push(temp);
            
            
            
        });
        
        
        console.log("=== data for excel ===",dataForExcel);
       // return;
        // console.log("=== users ===",userValidations);
        
        let headers= [
            {name:"Id",key:"paraId"},
            {name:"Paragraph Text",key:"para_text"},
            {name:"Stage",key:"stage"},
            {name:"Batch",key:"batch"}
        ];
        
        Object.keys(userValidations).forEach(key=>headers.push({
            name:userValidations[key],
            key:userValidations[key]
        }));
        
        headers.push({
            name:"Class Name",
            key:"result"
        });
        
        if(dataForExcel.length>1)
        {
            
           

           // console.log("converting to sheet");
           let sheetHeaders:string[] = headers.map((h)=>h.name);
            const jsData = utils.json_to_sheet(dataForExcel,{header:sheetHeaders});
            //console.log("creating ");
            
            // set headers 
            //console.log("Headers",sheetHeaders);
            //utils.sheet_add_aoa(jsData,sheetHeaders,{origin});

            const newBook = utils.book_new();

            utils.book_append_sheet(newBook, jsData, "User Validation Data");

            writeFile(newBook, `${project?.name}_${workflow?.workflowName}_validated_paras.xlsx`);
        }else{
            toast.warn("No Data To Export")
        }


        // let body:any[]=dataForExcel.map((d)=>{
              
        //     let temp:string[]=[];
        //     //add parid
        //     temp.push(d.paraId)
        //     //add para text
        //     temp.push(d.para_text)
        //     //add user validations
        //    Object.keys(userValidations).forEach(h=>temp.push(d[h]?`[${d[h]}]`.toString():"[]"));
        //     //add result
        //     temp.push(d["result"] ?`[${d["result"]}]`: "[]")

        //     return temp;
        // })
        

        // downloadExcel({
        //     fileName:"project_version1",
        //     sheet:"project_version1",
        //     tablePayload:{
        //         header:headers,
        //         body:body
        //     }
        // })
     // console.log("=== headers ===",headers);
       // alert("function completed");
        setCsvHeaders(headers);
        setCsvData(dataForExcel);
        setCanDownload(true);
        //setLoading(false);
        

    }
    
    const downloadUnValidatedData =async()=>{
        //setLoading(true);

        const res = await Axios.post("/para/downloadUnvalidatedData",{
            workflowId:workflow?.workflowId,
        });



        const data:string[] = res.data.data.map((d:any)=>{return {paraData:d.paraData}});
        console.log("===  paraData ===",data);

       
        let dataForExcel:any[] =data;
        let userValidations:any ={}
      
        
        
      
        
        if(dataForExcel.length>1)
        {
            
           

           // console.log("converting to sheet");
            const jsData = utils.json_to_sheet(dataForExcel,{skipHeader:true});
           
            const newBook = utils.book_new();

            utils.book_append_sheet(newBook, jsData, "User Validation Data");

            writeFile(newBook, `${project?.name}_${workflow?.workflowName}_raw_paras.xlsx`);
        }else{
            toast.warn("No Data To Export")
        }


        // let body:any[]=dataForExcel.map((d)=>{
              
        //     let temp:string[]=[];
        //     //add parid
        //     temp.push(d.paraId)
        //     //add para text
        //     temp.push(d.para_text)
        //     //add user validations
        //    Object.keys(userValidations).forEach(h=>temp.push(d[h]?`[${d[h]}]`.toString():"[]"));
        //     //add result
        //     temp.push(d["result"] ?`[${d["result"]}]`: "[]")

        //     return temp;
        // })
        

        // downloadExcel({
        //     fileName:"project_version1",
        //     sheet:"project_version1",
        //     tablePayload:{
        //         header:headers,
        //         body:body
        //     }
        // })
     // console.log("=== headers ===",headers);
       // alert("function completed");
        //setCsvHeaders(headers);
        setCsvData(dataForExcel);
        setCanDownload(true);
        //setLoading(false);
        

    }

   

  return (
    <>
 
   
    <Popover content={()=><div className='text-[1vw]'>{loading?"Loading":"Download All Data"}</div>}>
    <div
   // data={csvData}

    className='flex items-center justify-center gap-2 px-[0.4vw] py-[0.5vw]
                    border rounded-full border-[0.15vw] border-gray-500
                    duration-200 w-[2.5vw] h-[2.5vw] cursor-pointer
                    '
    onClick={async()=>{
        if(loading) return;
        setLoading(true);
        await downloadData()
        await downloadUnValidatedData()
        setLoading(false);
        }} >
        {loading?<AiOutlineLoading3Quarters className='w-[1.3vw] h-[1.3vw] animate-spin text-gray-500' />
        :<AiOutlineCloudDownload className='w-[1.6vw] h-[1.5vw] text-gray-500' />}
        {/* {loading?<div className='text-[1vw]'></div>
        :<div className='text-[1vw]'></div>} */}
    </div>

    </Popover>
    </>
  )
}
