import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApexChart from "./ApexChart";
import Chart from "./Chart";
import useWorkflowStore from '../../../store/useWorkflowStore';
import './trainCard.css'
import { toast } from "react-toastify";

import useAuthStore from "../../../store/authStore";
import { TrainStatus, checkCurrentTrainStatus } from "../../../utils/navigationHelper";;

interface TrainCardProps {
  // practiceAreaId: string | undefined;
  // activeStage: number;
}
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Card = ({handleClick,children,trained}:any)=>{
  return  <div
  className={`p-3 bg-white w-full shadow-lg ${trained?"cursor-pointer":""}`}
  style={{ height: "15vw", width: "20vw" }}
  onClick={() => handleClick()}
>
  <div className="flex space-x-2">
    <img
      style={{
        width: "2.5vw",
        height: "2.5vh",
      }}
      src="/images/trainCardIcon.svg"
      alt="validate"
    ></img>
    <p style={{ fontWeight: 500, fontSize: "1.1vw" }}>Train</p>
  </div>
    {children}

  
</div>
}

const EmptyGraph = ()=>{
  return <div className="flex h-[90%] justify-center items-center">
      <Chart stage1={0} stage2={0} stage3={0} />
     
    </div>

}

const ActiveGraph = ({details}:any)=>{

  return <div className="flex justify-between items-center ">
  <ApexChart
    
    stage1={details.stage1}
    stage2={details.stage2}
    stage3={details.stage3}
    height={250}
  />
  <div className="flex flex-col text-[0.9vw] text-left py-1 ml-5 w-[30%]">
    <div
      style={{
        width: "120px",
      }}
    >
      <p className="text-gray-400 -mb-3">Stage 1</p>&nbsp;
      <p className="mb-2">{details.stage1.toFixed(2)}%</p>
    </div>
    <div
      style={{
        width: "120px",
      }}
    >
      <p className="text-gray-400 -mb-3">Stage 2</p>&nbsp;
      <p className="mb-2">{details.stage2.toFixed(2)}%</p>
    </div>
    <div
      style={{
        width: "120px",
      }}
    >
      <p className="text-gray-400 -mb-3">Stage 3</p>&nbsp;
      <p className="mb-2">{details?.stage3?.toFixed(2)}%</p>
    </div>
  </div>
</div>

}

const AccuracyScore = ({currentWorkflow}:any)=>{
  return (<div className="my-[0.5vw] mx-2">
  <p
  className="text-[#2C2C2C] text-[1.3vw] mb-[0.3vw]"
    
  >
    Accuracy Score
  </p>
  <p            className="text-[1.4vw]"
>
    {Math.ceil(currentWorkflow?.alpdsInfo?.trainInfo?.MicroF1* 100)}%
  </p>
  <div className="flex w-full bg-white h-12 border-2 mt-2">
    <div
      style={{
        backgroundColor:
          currentWorkflow?.alpdsInfo?.trainInfo?.MicroF1<
          0.75
            ? "#F59F00"
            : "#1BA94C",
        width: `${
          currentWorkflow?.alpdsInfo?.trainInfo?.MicroF1 *
          100
        }%`,
      }}
    ></div>
  </div>
</div>)
}

const MLCTrainCard: React.FC<TrainCardProps> = () => {
  let navigate = useNavigate();
  let currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
  const currentProject = useWorkflowStore(state=>state.currentProject);
  const userDetails = useAuthStore(state=>state.userDetails);
  const isCreator = currentProject?.createdBy === userDetails?.userId;


  let handleClick = () => {
    if(!currentWorkflow) return;
    if(!isCreator){
      toast.warn("Only project admin can access training results");
      return;
    }
    let shouldNavigate:boolean = true;
    let navigatePoint:string ="";
    let isLLM = currentWorkflow?.alpdsInfo?.selectedModelInfo?.type==="llm"?true:false;
      switch(checkCurrentTrainStatus(currentWorkflow)){



        case TrainStatus.NotTrained:
              //alert("You have not trained the model");
              break;
        case TrainStatus.IsInLLMModelTuning:
                navigatePoint ="tune-llm-model";
                break;
        case TrainStatus.IsInLLMModelTrainig:
                navigatePoint ="train-llm";
                break;
        case TrainStatus.InModelTuning:
              navigatePoint ="model-tuning"
              shouldNavigate=false;
              break;
        case TrainStatus.InModelSelection:
              navigatePoint = "model-comparision"
              //alert("you are in model selection");
              shouldNavigate =false;
              break;
      
        case TrainStatus.InTraining:
              navigatePoint = "modeltraining-1"
              //alert("You are in training right now");
              shouldNavigate=false;
              break;
        case TrainStatus.Trained:
              navigatePoint = isLLM?"train-llm": "modeltraining-2"
              shouldNavigate=false;
              //alert("You want to start iterate");
              break;
        case TrainStatus.InIterate:
              navigatePoint = ""
              shouldNavigate=false;
              //alert("You are in iterate mode");
              break;
        default:
            //alert("could not find anything")


      }
      navigatePoint.length && navigate(`/workflow/${navigatePoint}?workflowId=`+currentWorkflow?.workflowId);
      return;
  };

  let autoMlInfo = currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress; 
  const trainStage:any = currentWorkflow?.stages[4];
  const modelComparisionProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.modelComparisionProgress;


  const currentStatus = checkCurrentTrainStatus(currentWorkflow);
  console.log("current status",currentStatus);

  const [details, setDetails] = useState({
    trainingProgress: 0,
    dataPoint: "bussiness",
    totalUpload: 0,
    estimatedTime: "12 minutes 30 seconds",
    stage1: autoMlInfo?.dataLoadingProgress || 0,
    stage2:  autoMlInfo?.automlClassifier1Progress || 0,
    stage3: autoMlInfo?.automlClassifier1Progress,
  });

  
 

   let [trained, setTrained] = useState(false);

  useEffect(() => {
    setTrained(currentWorkflow?.stages.find(s=>s.name==="train")?.active || false);
    
    let data =
      currentWorkflow?.alpdsInfo?.percentageStatus
        ?.automlProgress;

    if (data) {
      let s1 = data?.dataLoadingProgress || 0;
      let s2 = data.automlClassifier1Progress || 0;
      let s3 = data.automlClassifier2Progress || 0;

      setDetails({
        ...details,
        trainingProgress: Math.round((s1 + s2 + s3) / 3),
        stage1: s1,
        stage2: s2,
        stage3: s3,
      });
    }
    
    
    return () => {};


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkflow?.stages]);

  if(currentStatus === TrainStatus.Trained || currentStatus === TrainStatus.IsInLLMModelTrainig ){
    return <Card handleClick={handleClick} trained={trained}>
      <AccuracyScore currentWorkflow={currentWorkflow} />
      </Card>
  }
  
  if(currentStatus === TrainStatus.InTraining){
    return <Card handleClick={handleClick} trained ={trained}>
      <ActiveGraph details={details} />
    </Card>
  }else if(currentStatus === TrainStatus.InModelTuning 
    || currentStatus === TrainStatus.IsInLLMModelTuning){
    return <Card handleClick={handleClick} trained ={trained}>
    <EmptyGraph />
  </Card>
  }else if(currentStatus=== TrainStatus.InModelSelection){
    return <Card handleClick={handleClick} trained ={trained}>
      {modelComparisionProgress===100?<AccuracyScore currentWorkflow={currentWorkflow} />:<EmptyGraph />}
    </Card>
     
  }

  return <Card handleClick={handleClick} trained ={trained}>
      <EmptyGraph />
  </Card>

  

  

  
  
};

export default MLCTrainCard;
