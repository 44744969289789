import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthLogo } from "../../components/Logo";
import Marquee from "react-fast-marquee";
import { head } from "lodash";

interface AuthContainerProps {

}

let AuthContainerBilling: React.FC<AuthContainerProps> = ({ children }) => {
    let navigate = useNavigate();

    return (
        <div className="w-full h-[83vh] bg-[#fbfbfa] flex justify-center">
            <div className="flex-col  justify-center bg-white h-[99%] w-[30vw] px-[3vw] flex">
                <div className=" h-[15%] flex justify-center">
                    <AuthLogo
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                </div>
                <div className=" h-[80%] flex items-center justify-center">
                    <div className="w-full">
                        {children}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AuthContainerBilling;
