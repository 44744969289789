import { Modal } from 'antd'
import React, { useState } from 'react'
import Button from '../llm-components/model-comparison/Button';

import axios from '../../utils/Axios';

type Props = {
    open:boolean,
    setOpen:(value:boolean)=>void,
    onClick:(value:boolean)=>Promise<void>,
    workflowDetails:any

}

function ConfirmationModal({open,setOpen,onClick,workflowDetails}:Props) {

    const [loading,setLoading]=useState<boolean>(false);

    const close =()=>{
        setTimeout(()=>{
            setLoading(false);
            setOpen(false);
        },1000);

    }

  return (
        <Modal
        centered
        title={`Forking ${workflowDetails?.workflowName}`}
        width={"40vw"}
        open={open}
        // onOk={() => generateToken()}
         onCancel={() => setOpen(false)}
        footer={[
            // <button className="py-[0.2vw] px-[0.5vw] text-[1.1vw] text-white bg-primaryBlue rounded mr-[0.5vw]" onClick={()=>generateToken()} >Submit</button>,
            <Button
                styles={'!w-[5vw] !bg-white !text-primaryBlue !h-[2.5]'}
                text="No"
                loading={loading}
                disabled={loading}
                onClick={async() =>{
                    setLoading(true);
                    await onClick(false);
                    close();
                    
                }}
            ></Button>,
            <Button
                styles={"!w-[5vw] !h-[2.6vw]"}
                text="Yes"
                loading={loading}
                disabled={loading }
                onClick={async()=>{
                    setLoading(true);
                    onClick(true);
                    close();
                }}
            ></Button>,

        ]}
        className=""
        >
            <p>
            The current version already has a trained model.
             Do you want to carry the model info to the new version?
            </p>

        </Modal>
  )
}

export default ConfirmationModal