
import { Divider, Popover } from "antd";
import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import {AiOutlineWarning} from 'react-icons/ai';
//import { useHistory, useLocation } from "react-router-dom";
import useWorkflowStore from "../../../../store/useWorkflowStore";

import Axios from '../../../../utils/Axios'
import { useRecoilValue } from "recoil";
import { nodesAtom } from "../../../masterlist/atoms";
import { toast } from "react-toastify";
import {useState} from 'react';
//import ReactGA from "react-ga";
import {useLocation} from 'react-router-dom';
import template from "../../../../template";

export interface DeploymentScreenProps {
  dep:string
}


const DisabledFeature:React.FC<any> =({title,Icon})=>{


  return <Popover content={()=><p>Feature is under development</p>} >
      <div
    className="flex cursor-not-allowed  justify-start
     pl-[1.5vw] items-center py-[0.5vw] px-[0.2vw] 
     rounded-[0.2vw] mt-[0.7vw] 
     shadow-md hover:shadow-lg duration-150 w-[18vw]"
   >
  {/* <AiOutlineWarning  className="w-[1.2vw] h-[1.2vw]"/> */}
    {Icon && <img src={Icon} className="w-[1.2vw] h-[1.2vw]" alt={Icon} />}
    <p className={` mx-2 text-[1.1vw]`} >
      {title}
    </p>

    </div>
  </Popover>

}

const EmptyComp:React.FC<any>=()=>{
 return <></>
}


const DeploymentScreen: React.SFC<DeploymentScreenProps> = ({dep}) => {
  console.log("=== dep ===",dep);
  const [active, setActive] = React.useState<Number>(0);
  let prediction = "/images/prediction.svg";
  let prediction_grey = "/images/prediction_grey.svg";
  let prediction_service = "/images/prediction_service.svg";
  let prediction_service_grey = "/images/prediction_service_grey.svg";
  let export_dataset = "/images/export_dataset.svg";
  let export_dataset_grey = "/images/export_dataset_grey.svg";
  let file_grey = "/images/file_grey.svg";
  let file_white = "/images/file_white.svg";

  const project = useWorkflowStore(state=>state.currentProject);
  const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);

  //console.log("=== ===workflow type,projecttype",project)

  const DeploymentComponents =  project &&  template[project?.projectType || ""][project?.workflowType || ""]?.deploy;

  //const Deploy1 =DeploymentComponents &&  DeploymentComponents["deploy1"] ;
  const Deploy2 = DeploymentComponents && DeploymentComponents["deploy2"] ;
  const Deploy3 = DeploymentComponents && DeploymentComponents["deploy3"] ;
  const APIDocumentation =DeploymentComponents && DeploymentComponents["apiDocumentation"]


  let nodes = useRecoilValue(nodesAtom);

  let [info, setInfo] = React.useState({
    numTotalPrompts: currentWorkflow?.alpdsInfo?.trainInfo?.numTotalPrompts || 0,
    numTrainingPrompt:currentWorkflow?.alpdsInfo?.trainInfo?.numTrainingPrompt || 0,
    totalDocs: currentWorkflow?.alpdsInfo?.trainInfo?.totalDocs || 0,
    numTrainingDocs: 0,
  });



  const [currentDeployPage,setCurrentDeployPage] = useState<string>(dep);

  // for calculating the infos

  return (
    <div className="w-full mt-6" style={{ minHeight: "65vh" }}>
      <div className="w-full flex justify-around">
      <div className="h-20 w-1/4">
          <div className="uppercase text-[1vw] font-light">Total Documents</div>
          <div className="font-bold text-[1vw]">{info.totalDocs}</div>
        </div>
        <div className="h-20 w-1/4">
          <div className="uppercase text-[1vw] font-light">Total Prompts</div>
          <div className="font-bold text-[1vw]">{info.numTotalPrompts}</div>
        </div>
        <div className="h-20 w-1/4">
          <div className="uppercase text-[1vw] font-light">
            {" "}
            Training Prompts
          </div>
          <div className="font-bold text-[1vw]">{info.numTrainingPrompt}</div>
        </div>
       
        {/* <div className="h-20 w-1/4">
          <div className="uppercase text-[1vw] font-light">Total Number of Training Documents</div>
          <div className="font-bold text-[1vw]">{info.numTrainingDocs}</div>
        </div> */}
      </div>

      <Divider className={"mt-0 p-0 mb-[0.5vw]"} />

      <div className="flex justify-between mb-[0.5vw]">
        <p className="w-full font-sans text-[1.5vw] text-skin-darkGrey">
          Deployment Options
        </p>

       
      </div>
      <Divider className={"m-0"} />

      <div className="flex my-[0.7vw] w-full">
        <div className="w-1/4">
        
            {/* <div
              className="flex cursor-pointer justify-start pl-[1.5vw]  items-center py-[0.5vw] px-[0.2vw] text-center rounded-[0.2vw] my-[0.7vw] shadow-md hover:shadow-lg duration-150"
              style={
                currentDeployPage==="deploy1"
                  ? { background: "#11256D", width: "20vw", height:"3vw" }
                  : {
                      background: "#FFF",
                      width: "18vw", height:"3vw",
                    }
              }
              onClick={() =>{ 
                setCurrentDeployPage("deploy1")  
                setActive(0)}}
            >
              <img
                src={currentDeployPage==="deploy1"? prediction : prediction_grey}
                alt="prediction"
                className="mr-[0.2vw] w-[1.3vw]"
              ></img>
              <p
                className={`${
                  currentDeployPage==="deploy1" ? "text-white" : "text-skin-darkGrey"
                } mx-[0.2vw] text-[1.1vw]`}
              >
                Prediction on Ingested Data
              </p>
            </div>
           */}
         
            <div
              className="flex cursor-pointer   justify-start pl-[1.5vw] items-center py-[0.5vw] px-[0.2vw] rounded-[0.2vw] my-[0.7vw] shadow-md hover:shadow-lg duration-150"
              style={
                currentDeployPage==="deploy2"
                  ? { background: "#11256D", width: "20vw", height:"3vw" }
                  : {
                      background: "#FFF",
                      width: "18vw", height:"3vw",
                    }
              }
              onClick={() => {
                setCurrentDeployPage("deploy2")  
                setActive(1)}}
            >
              <img
                src={currentDeployPage==="deploy2" ? export_dataset : export_dataset_grey}
                alt="prediction"
                className="mr-[0.2vw] w-[1.3vw]"
              ></img>
              <p
                className={`${
                  currentDeployPage==="deploy2" ? "text-white" : "text-skin-darkGrey"
                } mx-[0.2vw] text-[1.1vw]`}
              >
                Export Dataset
              </p>
            </div>
      

          
           {Deploy3?.active? <div
            onClick={() => {
              setCurrentDeployPage("deploy3")
              setActive(2)
              }}
              className="flex cursor-pointer  justify-start pl-[1.5vw] items-center py-[0.5vw] px-[0.2vw] rounded-[0.2vw] mt-[0.7vw] shadow-md hover:shadow-lg duration-150"
              style={
                currentDeployPage==="deploy3"
                  ? { background: "#11256D", width: "20vw", height:"3vw"}
                  : {
                      background: "#FFF",
                      width: "18vw", height:"3vw",
                    }
              }
             
            >
              <img
                src={
                  currentDeployPage==="deploy3" ? prediction_service : prediction_service_grey
                }
                alt="prediction"
                className="mr-[0.2vw] w-[1.3vw]"
              ></img>
              <p
                className={`${
                  currentDeployPage==="deploy3" ? "text-white" : "text-skin-darkGrey"
                } mx-2 text-[1.1vw]`}
              >
                Model API
              </p>
            </div>:<DisabledFeature  
            title="Model API"
            
            />}
       

         {/* Api Documentation */}
          
          <div className="flex mt-0 w-full ml-[1.5vw] " 
         
                >
              <img
                src="/images/arrow_deployment.svg"
                alt="Api documentation"
                className="w-[1.3vw] h-[4vw] mr-[0.8vw]"
              ></img>
            {APIDocumentation?.active?  <div
                className={`bg-white flex cursor-pointer justify-start pl-[1.5vw] items-center py-[0.5vw] px-[0.2vw] rounded-[0.2vw] my-[0.7vw] shadow-md hover:shadow-lg duration-150`}
                onClick={() => {
                  setCurrentDeployPage("deploy4")
                  setActive(3)
                  }}
                style={
                  {
                    background: `${currentDeployPage==="deploy4" ? "#11256D" : "#FFF"}`,
                    boxShadow: `${
                      currentDeployPage==="deploy4"
                        ? "0px 0px 8px rgba(122, 122, 122, 0.25)"
                        : ""
                    }`,
                    width: "16.4vw",
                    height:'3vw'
                  }
                  // active === 2
                  // 	? { background: "#FFF", width: "260px" }
                  // 	: { background: "#FFF", width: "220px", boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.25)" }
                }
              >
                <img
                  src={currentDeployPage==="deploy4" ? file_white : file_grey}
                  alt="prediction"
                  className="mr-[0.2vw] w-[1.1vw]"
                  ></img>
                <p
                  className={`${
                    currentDeployPage==="deploy4" ? "text-white" : "text-skin-darkGrey"
                  } mx-2 text-[1.1vw]`}
                >
                  API Documentation
                </p>
              </div>:<DisabledFeature title="API Documentation" Icon={file_grey} />}
            </div>
            
        </div>
        <div className="flex-grow mx-[1vw] w-3/4">
{/*             
            {
             currentDeployPage ==="deploy1" && 
             React.createElement(Deploy1?.component || EmptyComp,{
              setCurrentPage:setCurrentDeployPage,
               setActive:setActive
             })
            //  <Deploy1 
            //     setCurrentPage={setCurrentDeployPage} setActive={setActive} />
            } */}
            {

             currentDeployPage ==="deploy2" && 
             React.createElement(Deploy2?.component || EmptyComp,{
              setCurrentPage:setCurrentDeployPage,
               setActive:setActive
             })
            }
            {

             currentDeployPage === "deploy3" && 
             React.createElement(Deploy3?.component || EmptyComp,{
              setCurrentPage:setCurrentDeployPage,
               setActive:setActive
             })
            }
            {

             currentDeployPage === "deploy4" && 
             React.createElement(APIDocumentation?.component || EmptyComp,{
              setCurrentPage:setCurrentDeployPage,
               setActive:setActive
             })
            }
            
         
     
        </div>
      </div>
    </div>
  );
};

export default DeploymentScreen;
