import { Divider, Modal } from "antd";
import { current } from "immer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContentProps } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import useAuthStore from "../../../store/authStore";
import useWorkflowStore from '../../../store/useWorkflowStore';

import {TrainStatus,
  checkCurrentTrainStatus,
} from '../../../utils/navigationHelper';
import ConfirmIterateModel from "./ConfirmIterateModel";



interface StageCardProps {
}

const StageCard: React.FC<StageCardProps> = () => {
 
  const {currentWorkflow,currentProject} = useWorkflowStore();
  const userDetails = useAuthStore(state=>state.userDetails)

  const isCreator = userDetails?.userId === currentProject?.createdBy;

  const [activeStage,setActiveStage] =useState<number>(1);
  const [iterateBoxOpen,setIterateBoxOpen]  = useState<boolean>(false);

  const navigator = useNavigate();
 
  const active = currentWorkflow?.stages[3].active===true ?2
                :currentWorkflow?.stages[2].active===true || currentWorkflow?.masterlistLocked ?1:0;
  //console.log("=== active value in stage card ===",active)

  const checkForRetrain = async()=>{

    if(!currentWorkflow) return;
    let shouldNavigate:boolean = true;
    let message = "";
      switch(checkCurrentTrainStatus(currentWorkflow)){



        case TrainStatus.NotTrained:
              //alert("You have not trained the model");
              break;
        case TrainStatus.InModelSelection:
              //alert("you are in model selection");
              message="Please complete the model training first!"
              shouldNavigate =false;
              break;
        
        case TrainStatus.InModelTuning:
              //alert("you are in model tuning");
              message="Please complete the model training first!"
              shouldNavigate=false;
              break;
        case TrainStatus.InTraining:
              //alert("You are in training right now");
              message="Please complete the model training first!"
              shouldNavigate=false;
              break;
        case TrainStatus.Trained:
                    
        //shouldNavigate=false;
              //alert("You want to start iterate");
              // isCreator ? setIterateBoxOpen(true)
              //           :toast.warn("Please ask project admin to trigger iteration to access validation.");
              break;
        default:
            //alert("could not find anything")


      }
      if(message!== ""){
        toast.warn(message);
      }

      if(shouldNavigate){
        //alert("Navigating to validation page now");
        if(active>1)
                {
                  navigator("/workflow/stage2?workflowId="+currentWorkflow?.workflowId);
                }
        
                else{
                  toast("Create a Masterlist and initiate Stage 1 Validation.",{type:toast.TYPE.WARNING});
                }
      }
    


  }



  useEffect(()=>{
    if(active>1)
    {
      setActiveStage(2)
    }
    else  if(active>0)
    {
      setActiveStage(1)
    }

  },[])
  return (
    <div
      className="flex items-center w-full  shadow-lg"
      style={{
        width: "20vw",
        height: "15vw",
      }}      
      onClick={() => {
        // if(currentWorkflow?.masterlistLocked)
        // navigator("/workflow/stage1?workflowId="+currentWorkflow?.workflowId);

        // else{
       
        // }
      }}
    >
      {/* <ConfirmIterateModel
      open={iterateBoxOpen}
      setOpen={setIterateBoxOpen}
      /> */}
      <Modal
      centered
      footer={<ConfirmIterateModel
      callBatch={true}
      hasStages={true}
      resetStage={true}
        open={iterateBoxOpen}
        setOpen={setIterateBoxOpen} />}
      width={"45vw"}
      open={iterateBoxOpen}
      onCancel={()=>setIterateBoxOpen(false)}
      title={"Iterative training of the model triggered"}
      
      >
        <p className="text-left" >
        This will take this workflow back to the validation stage, 
where you can validate more paragraphs and retrigger the training process to get a new model.
Once you are in the validation stage, you won't be able to access the previous training results.

        </p>
        <br/>
        <p className="font-medium">
        Note:  
        Your saved prediction API will continue to work.
        </p>
      </Modal>
      <div
        className="py-3 px-3 bg-white w-full overflow-hidden "
        style={{
          width: "20vw",
          height: "15vw",
        }}      
      >
        <div className="flex space-x-2 items-center">
          <img
             style={{
              width: "2.5vw",
              height: "2.5vh",
            }} 
            src="/images/circle_tick.svg"
            alt="validate"
          ></img>
          <p className="text-[1.1vw] font-medium ">Validate</p>
          <div className="flex items-center justify-center cursor-pointer ">
          {/* <p className="mx-1 text-green-400" style={{ color: "#7A7A7A" }}> */}
          <p className={`mx-1 text-[0.9vw]   ml-[1vw] text-center ${activeStage===1 && 'underline'}`} 
          style={{
           color:activeStage === 1  ?"#1BA94C":"#7A7A7A"}}
           onClick ={()=>setActiveStage(1)}
           >
            Stage 1
          </p>
            <Divider type={"vertical"} />
          <p className={`mx-1 text-[0.9vw] cursor-pointer ${activeStage ===2 && 'underline'}`} 
          style={{color:activeStage===2 ? "#1BA94C":"#7A7A7A"}}
          onClick ={()=>setActiveStage(2)}
          >    
            Stage 2
          </p>

          </div>
        
        
        </div>
        {/* {active >2 ? ( */}
          {true ? (
          <div
          className="  h-full mx-auto  flex transition duration-500 ease-in-out  justify-between items-center"
          style={{width:"240%",transform:activeStage ===1?"translateX(-0%)":"translateX(-50%)"}}
          
          >

            <div className="flex flex-col items-center justify-center -translate-y-3  ">
              
            <div
              className={`text-[1vw] mb-2 flex justify-center items-center ml-[22%] text-center cursor-${
                active ===1 ? "pointer" : "not-allowed"
              } ${
                active ===1
                  ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                  : "bg-gray-300 text-white"
              }`}
              onClick={() =>
               {
                if( active===1)
                {

                  navigator("/workflow/stage1?workflowId="+currentWorkflow?.workflowId);
                }
                else{
                  if(active===0)
                  toast("Create a Masterlist and to initiate Stage 1 Validation.",{type:toast.TYPE.WARNING});
                }
               }
              }
              style={{
                border: `1px solid ${active ===1 ? "#2C2C2C" : "white"}`,
                
                width: "15vw",
                height: "2.5vw",
              }}
            >
              Stage 1 Validation
            </div>
           { currentProject?.createdBy === userDetails?.userId && <div
              className={`text-[1vw] ml-[22%]  flex justify-center items-center text-center cursor-${
               active >0  && currentWorkflow?.alpdsInfo?.percentageStatus?.stage1Progress===100?  "pointer" : "not-allowed"
              } ${
                active >0 && currentWorkflow?.alpdsInfo?.percentageStatus?.stage1Progress===100
                  ? "hover:bg-primarycolor hover:text-white text-primarycolor "
                  : "bg-gray-300 text-white"
              }`}
             
              onClick={() =>
               {
                if(active>0 && currentWorkflow?.alpdsInfo?.percentageStatus?.stage1Progress===100)
                navigator("/workflow/stage1-summary?workflowId="+currentWorkflow?.workflowId);
        
                else{
                   toast("create a masterlist and initiate stage 1 to see validation summary",{type:toast.TYPE.WARNING});
                }
               }}
               
              style={{
                border: `1px solid ${active>0 && currentWorkflow?.alpdsInfo?.percentageStatus?.stage1Progress===100? "#2C2C2C" : "white"}`,
                
                width: "15vw",
                height: "2.5vw",
              }}
            >
            Validation Summary
            </div>}

            </div>

            <div className="flex flex-col items-center w-full  ml-[7%] -translate-y-3  ">

            <div
              className={`text-[1vw] mb-2  flex justify-center items-center  text-center cursor-${
                active > 1 ? "pointer" : "not-allowed"
              } ${
                active > 1
                  ? "hover:bg-primarycolor hover:text-white text-primarycolor"
                  : "bg-gray-300 text-white"
              }`}
              onClick={() =>
               {
                checkForRetrain();
                
               }
              }
              style={{
                border: `1px solid ${active > 1 ? "#2C2C2C" : "white"}`,
               
                width: "15vw",
                height: "2.5vw",
              }}
            >
             Stage 2 Validation
            </div>
           { currentProject?.createdBy === userDetails?.userId && <div
              className={`text-[1vw]   flex justify-center items-center text-center cursor-${
               active>1 && currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress===100 ? "pointer" : "not-allowed"
              } ${
                active>1 && currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress===100
                  ? "hover:bg-primarycolor hover:text-white text-primarycolor "
                  : "bg-gray-300 text-white"
              }`}
             
              onClick={() =>
               {
               
                if(activeStage ===2 && active>1  && currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress===100)
                navigator("/workflow/stage2-summary?workflowId="+currentWorkflow?.workflowId);
                else{
                   toast("Initiate stage 2 to see validation summary",{type:toast.TYPE.WARNING});
                }
               }}

              style={{
                border: `1px solid ${active>1 && currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress===100 ? "#2C2C2C" : "white"}`,
                
                width: "15vw",
                height: "2.5vw",
              }}
            >
            Validation Summary
            </div>}
            </div>

          </div>
        ) : (
         <div></div>
       
        )}
      </div>
    </div>
  );
};

export default StageCard;
