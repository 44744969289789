import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import useWorkflowStore from '../../store/useWorkflowStore';
import template from '../../template';
import Button from '../../components/llm-components/model-comparison/Button';
import axios from '../../utils/Axios';



type Props = {}


const DownloadBlob =async(url:string | null)=>{

    try{

    //   const res= await  axios.post("/llm/get-model-url",{
    //         workflowId,
    //         modelName
    //     })

    //const url = res.data.data.url;
    if(url){
        const link =document.createElement("a");
        link.href = url;
        link.target="_"

        link.click();

        link.remove();




    }else{
        toast.warn("Could not find model, please contact DataNeuron support");
    }


        return true;
    }catch(err){
        console.log("error in model download",err)
        toast.error("There was an error while downloading your model")
        return null;
    }

}





function DownloadLLMModel({}: Props) {

  const workflow= useWorkflowStore(state=>state.currentWorkflow);
  const projectType= useWorkflowStore(state=>state.currentProject)?.projectType;
  const userSelectedModel = workflow?.alpdsInfo?.selectedModelInfo?.modelName;
//   const modelLink:string | undefined = projectType && userSelectedModel
//                             && template[projectType]?.modelLinks[userSelectedModel];
  

  const [loading,setLoading] =useState<boolean>(false);
  const [modelName,setModelName] = useState<string>("");
 // console.log(modelLink);

  useEffect(()=>{

    if(userSelectedModel){
        setModelName(userSelectedModel);
    }

  },[userSelectedModel]);
  

  return (
    <div className=''>
        <Button 
        onClick={(async()=>{
            if(!workflow ) return;
            setLoading(true);
            await DownloadBlob(workflow?.alpdsInfo?.selectedModelInfo?.modelUrl || null);
            setLoading(false);
        })}
        loading={loading}
        text={"Download Model"}
         styles={`!w-full mx-auto ml-0 !bg-white hover:!bg-primaryBlue
                        text-primaryBlue  hover:!text-white
                        border border-primaryBlue`}
        />
    </div>
  )
}

export default DownloadLLMModel