import React, { useEffect, useState } from 'react';
import { ReactComponent as SwitchTick } from '../../assets/switch_tick.svg';
import { ReactComponent as Loading } from '../../assets/loading_circle.svg';


import useWorkflowStore from '../../store/useWorkflowStore';
import useAuthStore from '../../store/authStore';
import Axios from '../../utils/Axios';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';

interface switchProps{
    disable:boolean,
    disableMessage:string,
    onClick:any,
    state:boolean,
    width:number,
    innerWidth:number,
    
    title?:string | undefined
}

 const Switch:React.FC<switchProps> = ({disable,disableMessage,
    onClick,state,
    title,
    width,innerWidth})=> {

        

    const [loading, setLoading] = useState<boolean>(false);
    const [showMessage,setShowMessage] = useState<boolean>(false);
    const [isClicked,setIsClicked] = useState<boolean>(false);
    const [ml,setMl] = useState(0.3);

    const handleClick=()=>{
        setIsClicked(true);

        setTimeout(()=>{
            setIsClicked(false);
        },500);
    }

    useEffect(()=>{

        if(state)
        {
            setMl(width - Math.ceil(innerWidth));
        }
        else{
            setMl(0.3);
        }
    },[state]);

    return (

        <Tooltip
        className='text-[1vw]'
        title={disableMessage}
                visible={showMessage}
        >

        <div
        
        onClick={async() =>{
            if(!disable && !loading)
            {
                //handleClick();
                setLoading(true)
                await onClick(!state);
                setLoading(false);
            }
        } }
        className={`cursor-pointer shadow-inner relative border inset-md w-[${width}vw]  h-[2.2vw] rounded-full duration-100
                        ${ !disable?state  ? "bg-[#1BA94C]" : "bg-[#CACACA]"
                                        :"bg-[#CACACA]"
                    }
                        ${isClicked?"animate-ping":""}
                    `}
                    onMouseEnter={()=>disable && setShowMessage(true)}
                    onMouseLeave={()=>setShowMessage(false)}
                    
                    >
            <div className={` w-[85%]  h-full flex  items-center  duration-500
              ${state ? "ml-[12%]":"ml-[60%]"}
              ${loading?"cursor-progress":""}
            `}>
                <p className={`cursor-pointer select-none text-[1vw] mt-[0.1vw] text-center font-strong ${state?"text-white":""}`}>
                    {title} {state?"On":"Off"}</p>
            </div>
            <div
                data-testid="toolbar-no-tag-test"
               
                style={{marginLeft:`${ml}vw`}}
                className={`absolute flex justify-center  items-center rounded-full  
                w-[${innerWidth}vw] top-[12.5%]  bg-white h-[75%] shadow-lg duration-500 
                ${loading?"cursor-progress":"cursor-pointer"}
               `}>
                {
                    loading ?
                    <Loading width={"60%"} height={"60%"}  className={` animate-spin`} />
                    :
                    <SwitchTick width={"60%"} height={"60%"}  className={`${state && !disable?"":"opacity-0"} duration-500`} />
                    
                }

            </div>
        </div>
        </Tooltip>
    )
}


export default Switch;
