import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApexChart from "./ApexChart";
import Chart from "./Chart";
import useWorkflowStore from '../../store/useWorkflowStore';
import './trainCard.css'
import { toast } from "react-toastify";
import { TrainStatus, checkCurrentTrainStatus, checkPromptTrainStatus } from "../../utils/navigationHelper";
import useAuthStore from "../../store/authStore";
import FtChart from "./FtChart";

interface TrainCardProps {
  // practiceAreaId: string | undefined;
  // activeStage: number;
}
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Card = ({handleClick,children,trained}:any)=>{
  return  <div
  className={`p-3 bg-white w-full shadow-lg ${trained?"cursor-pointer":""}`}
  style={{ height: "15vw", width: "20vw" }}
  onClick={() => handleClick()}
>
  <div className="flex space-x-2">
    <img
      style={{
        width: "2.5vw",
        height: "2.5vh",
      }}
      src="/images/trainCardIcon.svg"
      alt="validate"
    ></img>
    <p style={{ fontWeight: 500, fontSize: "1.1vw" }}>Train</p>
  </div>
    {children}

  
</div>
}

const EmptyGraph = ()=>{
  return <div className="flex h-[100%] -my-[1vw]  justify-center items-center">
      <FtChart progress={0} />
     
    </div>

}
const ActiveGraph = ({progress}:any)=>{
  return <div className="flex h-[100%] justify-center items-center -my-[1vw]">
      <FtChart progress={progress} />
     
    </div>

}

// const ActiveGraph = ({details}:any)=>{

//   return <div className="flex justify-between items-center ">
//   <ApexChart
    
//     stage1={details.stage1}
//     stage2={details.stage2}
//     stage3={details.stage3}
//     height={250}
//   />
//   <div className="flex flex-col text-[0.9vw] text-left py-1 ml-5 w-[30%]">
//     <div
//       style={{
//         width: "120px",
//       }}
//     >
//       <p className="text-gray-400 -mb-3">Stage 1</p>&nbsp;
//       {/* <p className="mb-2">{details.stage1.toFixed(2)}%</p> */}
//     </div>
//     <div
//       style={{
//         width: "120px",
//       }}
//     >
//       <p className="text-gray-400 -mb-3">Stage 2</p>&nbsp;
//       <p className="mb-2">{details.stage2.toFixed(2)}%</p>
//     </div>
//     <div
//       style={{
//         width: "120px",
//       }}
//     >
//       <p className="text-gray-400 -mb-3">Stage 3</p>&nbsp;
//       {/* <p className="mb-2">{details.stage3.toFixed(2)}%</p> */}
//     </div>
//   </div>
// </div>

// }

const AccuracyScore = ({currentWorkflow}:any)=>{
  return (<div className="flex justify-center items-center -my-[1vw]">

  <FtChart progress={100} />
</div>)
}

const FtTrainCard: React.FC<TrainCardProps> = () => {
  let navigate = useNavigate();
  let currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
  const currentProject = useWorkflowStore(state=>state.currentProject);
  const userDetails = useAuthStore(state=>state.userDetails);
  const isCreator = currentProject?.createdBy === userDetails?.userId;


  let handleClick = () => {
    if(!currentWorkflow) return;
    if(!isCreator){
      toast.warn("Only project admin can access training results");
      return;
    }
    let shouldNavigate:boolean = true;
    let navigatePoint:string ="";
    let isLLM = currentWorkflow?.alpdsInfo?.selectedModelInfo?.type==="llm"?true:false;
    //alert("inside fn")  
    switch(checkPromptTrainStatus(currentWorkflow)){



        case TrainStatus.NotTrained:
              //alert("You have not trained the model");
              break;
        case TrainStatus.IsInLLMModelTuning:
            //alert("tuning")
            navigatePoint="tune-llm-model";
            break;
        case TrainStatus.IsInLLMModelTrainig:
            navigatePoint="llm-model-fine-tune";
            break;
        case TrainStatus.InModelSelection:
              navigatePoint = "model-comparision"
              //alert("you are in model selection");
              shouldNavigate =false;
              break;
      
        case TrainStatus.InTraining:
          console.log("=== in training ===");
          navigatePoint = isLLM?"llm-model-fine-tune": "modeltraining-2"
              //alert("You are in training right now");
              shouldNavigate=false;
              break;
        case TrainStatus.Trained:
          console.log("=== is trained ===",isLLM);
          navigatePoint = "llm-model-fine-tune"
              shouldNavigate=false;
              //alert("You want to start iterate");
              break;
        case TrainStatus.InIterate:
              navigatePoint = ""
              shouldNavigate=false;
              //alert("You are in iterate mode");
              break;
        default:
            //alert("could not find anything")


      }
      console.log("=== navigate point ===>",navigatePoint);
      navigatePoint.length && navigate(`/workflow/${navigatePoint}?workflowId=`+currentWorkflow?.workflowId);
      return;
  };

  let autoMlInfo = currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress; 
  const trainStage:any = currentWorkflow?.stages[4];
  const modelComparisionProgress = currentWorkflow
                                    ?.alpdsInfo?.percentageStatus
                                    ?.modelComparisionProgress;


  const currentStatus = checkPromptTrainStatus(currentWorkflow);
  console.log("current status",currentStatus);

  const llmProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress?.llmProgress || 0;

  
 

   let [trained, setTrained] = useState(false);

  useEffect(() => {
    setTrained(currentWorkflow?.stages.find(s=>s.name==="train")?.active || false);
    
    // let data =
    //   currentWorkflow?.alpdsInfo?.percentageStatus
    //     ?.automlProgress;

    // if (data) {
    //   let s1 = data?.dataLoadingProgress || 0;
    //   let s2 = data.automlClassifier1Progress || 0;
    //   let s3 = data.automlClassifier2Progress || 0;

    //   setDetails({
    //     ...details,
    //     trainingProgress: Math.round((s1 + s2 + s3) / 3),
    //     stage1: s1,
    //     stage2: s2,
    //     stage3: s3,
    //   });
    // }
    
    
    return () => {};


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkflow?.stages]);

  if(currentStatus === TrainStatus.Trained  ){
    return <Card handleClick={handleClick} trained={trained}>
      <AccuracyScore currentWorkflow={currentWorkflow} />
      </Card>
  }
  
  if(currentStatus === TrainStatus.InTraining){
    return <Card handleClick={handleClick} trained ={trained}>
      <ActiveGraph progress={llmProgress} />
    </Card>
  }else if(currentStatus === TrainStatus.InModelTuning){
    return <Card handleClick={handleClick} trained ={trained}>
    <AccuracyScore currentWorkflow={currentWorkflow} />
  </Card>
  }else if( currentStatus === TrainStatus.IsInLLMModelTuning){
    return <Card handleClick={handleClick} trained ={trained}>
    <EmptyGraph />
  </Card>
  }else if(currentStatus=== TrainStatus.InModelSelection){
    return <Card handleClick={handleClick} trained ={trained}>
      {modelComparisionProgress===100?<AccuracyScore currentWorkflow={currentWorkflow} />:<EmptyGraph />}
    </Card>
     
  }else  if(currentStatus === TrainStatus.IsInLLMModelTrainig){
    return <Card handleClick={handleClick} trained ={trained}>
      <ActiveGraph progress={llmProgress} />
    </Card>
  }

  return <Card handleClick={handleClick} trained ={trained}>
      <EmptyGraph />
  </Card>

  

  

  
  
};

export default FtTrainCard;
