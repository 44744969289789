import React from 'react';
import StageCard from './StageCard';
import AW_StageCard from './AW_StageCard';
import useWorkflowStore from '../../../store/useWorkflowStore';
import template from '../../../template';


function Index() {
  const currentProject = useWorkflowStore(state=>state.currentProject);
  const projectType =  currentProject?.projectType || "";
  const workflowType = currentProject?.workflowType || "";


  return currentProject && React.createElement(template[projectType][workflowType]?.workflowDashboard.stageCard)
}

export default Index