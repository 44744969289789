import { Popover } from 'antd'
import React, { useEffect,useState   } from 'react';
import Axios from '../../utils/Axios';


//Icons
import { ReactComponent as SeedIcon } from '../../assets/seed_icon.svg';
import { ReactComponent as Loading } from '../../assets/loading_blue.svg';
import { ReactComponent as Refresh } from '../../assets/reload.svg';
import { toast } from 'react-toastify';


interface ICounterProps{
    stageNodeProgress:any,
    active_node:any,
    stage2AccuracyProgress:number,
    currentBatchNumber:number,
    currentWorkflow:any
}




export default function Counter({stageNodeProgress,
    active_node,
    stage2AccuracyProgress,
    currentWorkflow,
    currentBatchNumber
}:ICounterProps) {

    const [isMounted,setIsMounted] = useState<boolean>(false);

    // const RefreshAccuracyReports =async()=>{
   

    //     let response =await Axios.post("/integration/refreshAccuractReport",{
    //         workflowId:currentWorkflow?.workflowId,
    //         batchNumber:currentBatchNumber
    //     });
    
       
    
    
    // }
    useEffect(() => {
        setIsMounted(true);

    }, [])


    // useEffect(()=>{

    //     if(stage2AccuracyProgress === 100 && isMounted)
    //     {
    //       toast("Counts were updated",{type:"success"});
    //       //setStageNodeProgress(stage2AccuracyReport);
    //     }
      
    //   },[stage2AccuracyProgress])



  return (
    <div className="flex space-x-3 my-[1px]">
    <Popover content="Achieved Target">

      <div className="flex space-x-1 items-center">
        <img src="/images/achieved_target.svg" className="w-[1.8vw]" alt="" />
        <span className="cursor-default text-[#1BA94C]">{
          stageNodeProgress[active_node?.node_id || ""]?.validatedParas
          //batchAnnoProgress
        }</span>
      </div>
    </Popover>
    <Popover content="Target For Current Batch">

      <div className="flex space-x-1 items-center">
        <img src="/images/current_target.svg" className="w-[1.8vw]" alt="" />
        <span className="cursor-default text-[#1BA94C]">
          {21 - stageNodeProgress[active_node?.node_id || ""]?.validatedParas > 0 ?
           21 - stageNodeProgress[active_node?.node_id || ""]?.validatedParas 
           : 0}
        </span>
      </div>
    </Popover>
    <Popover content="Total Target">

      <div className="flex space-x-1 items-center">
        <img src="/images/total_target.svg" className="w-[3vw]" alt="" />
        <span className="cursor-default text-[#1BA94C]">21</span>
      </div>
    </Popover>

  
        
    {/* <Popover content="Refresh Data" className="!ml-[2vw]">

      <div className="flex space-x-1 items-center">
        {
          stage2AccuracyProgress<100  ? <Loading  className="w-[1.8vw] h-[1.8vw] hover:scale-110 duration-200 cursor-pointer" />
            : <Refresh className="w-[1.8vw] h-[1.8vw] hover:scale-110 duration-200 cursor-pointer"
            onClick={() => RefreshAccuracyReports()} />
        }

      </div>
    </Popover> */}
        
    <div>



    </div>


  </div>
  )
}
