import { toast } from 'react-toastify';
import Axios from '../../utils/Axios';

export const skipUpload =async (workflowId:string)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/skip-upload",{
            workflowId
        });
        return {
            error:null,
            data:null
        }

    }catch(error:any){
        toast.error("There was an error while updating your progress");
        return {
            error:error,
            data:null
        }
    }

}

export const getPromptsByPage =async (workflowId:string,pageNumber:number)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/",{
            workflowId,
            pageNumber
        });
        console.log("",res.data.data);
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error while fetching the prompt");
        return {
            error:error,
            data:null
        }
    }

}

const delay = ()=>{
    return new Promise((resolve,reject)=>{
        setTimeout(()=>resolve(true),3000);
    })
}

export const createPrompt =async (workflowId:string,prompt:any)=>{

    
    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        console.log("==create prompt was called");
        const res =await Axios.post("/prompt/create",{
            workflowId,
            ...prompt
            
        });
        await delay();
        console.log("=== prompt saved ===>")
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error while creating the prompt");
        return {
            error:error,
            data:null
        }
    }

}


export const annotatePrompt =async (workflowId:string,promptId:string,validations:any[])=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/annotate",{
            workflowId,
            promptId,
            validations
            
        });
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error while creating the prompt");
        return {
            error:error,
            data:null
        }
    }

}

export const updatePrompt =async (workflowId:string,promptId:string,text:string)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/edit",{
            workflowId,
            promptId,
            prompt:text
            
        });
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error while creating the prompt");
        return {
            error:error,
            data:null
        }
    }

}

export const addAnswer =async (workflowId:string,promptId:string,answer:any)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/add-answer",{
            workflowId,
            promptId,
            answer
            
        });
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error while creating the prompt");
        return {
            error:error,
            data:null
        }
    }

}

export const addISelectAnswer =async (workflowId:string,promptId:string,answer:any)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/add-iselect-answer",{
            workflowId,
            promptId,
            answer
            
        });
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error while creating the prompt");
        return {
            error:error,
            data:null
        }
    }

}

export const updateAnswer =async (workflowId:string,
    promptId:string,
    answer:string,
    responseId:string)=>{
    try{

        if(!workflowId) throw new Error("Workflow Id is empty");
        console.log("=== making request ===");
        const res =await Axios.post("/prompt/update-answer",{
            workflowId,
            promptId,
            answer,
            responseId
            
        });
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error while updating the answer");
        console.log("===error in making update answer request===>",error);
        return {
            error:error,
            data:null
        }
    }

}

export const getAnswers =async (workflowId:string,promptId:string,
    prompt:string,model:string,
    workflowType:string
    )=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/get-prediction",{
            workflowId,
            promptId,
            prompt,
            model,
            workflowType
            
        });
        return {
            error:null,
            answers:res?.data?.data
        }

    }catch(error:any){
        //toast.error("There was an error while generating response for the given prompt");
        console.log(error.response.data.message);
        let message = error.response.data.message || "There was an error while generating response for the given prompt";
        return {
            error:message.toUpperCase(),
            answers:null
        }
    }

}

export const getOneAnswer =async (workflowId:string,promptId:string,
                                prompt:string,model:string)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/get-fine-tune-prediction",{
            workflowId,
            promptId,
            prompt,
            model
            
        });
        return {
            error:null,
            answers:res?.data?.data
        }

    }catch(error:any){
       // toast.error("There was an error while generating response for the given prompt");
       console.log("== Error ==",error?.response);
       let message = error?.response?.data?.message || "There was an error while generating response for the given prompt";
       return {
            error:message?.toUpperCase(),
            answers:null
        }
    }

}
export const getAnnotatedPrompts =async (workflowId:string)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/get-annotated-prompt-data",{
            workflowId, 
        });
        return {
            error:null,
            data:res?.data?.data
        }

    }catch(error:any){
        toast.error("There was an error getting data for exprot");
        return {
            error:error,
            data:null
        }
    }

}


export const toggleAutoValidation =async (workflowId:string,value:boolean)=>{

    try{

        if(!workflowId) throw new Error("Workflow Id is empty")
        const res =await Axios.post("/prompt/toggle-autovalidate",{
            workflowId,
            value
        });
        return {
            error:null,
            data:null
        }

    }catch(error:any){
        toast.error(error.response.message);
        return {
            error:error,
            data:null
        }
    }

}

export const callInstructFtVM = async(workflowId:string)=>{

    const baseUrl = "http://172.188.96.151:5000";
    const modelUrl = "llama_LLM_instruct_tune";
    try{
        let res = Axios.post("/prompt/ft-automl",{
            workflowId,
        });

        await delay();
        return {
            error:null,
            data:"API was called successfully"
        }

    }catch(e:any){
        console.log("There was an error while calling LLM model traiing api");
        return {
            error:e.message,
            data:null
        }
        //toast.error("There was an error while calling model training api");
    }
}

export const callInstructFtSummary = async(workflowId:string)=>{

    try{
        
        const res = await Axios.post("/prompt/get-ft-validation-summary",{
            workflowId
        })

        return {
            data:res.data.data,
            error:null
        }

    }catch(e:any){
        return {
            error:e.message,
            data:null
        }
    }

}






