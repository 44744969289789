import { Select } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { IWarning } from '../../masterlist/summary/MasterlistSummary';
import useMasterlistSummary from '../../../store/useMasterlistSummary';
import useWorkflowStore from '../../../store/useWorkflowStore';
import { nodesAtom } from '../../masterlist/atoms';
import { useRecoilState } from 'recoil';

interface ISummaryTabProps{
    modalNodes:number,
    nodes:Array<any>,
    avgKeyWrd:number,
    dataSummary:any,
    warnings:Array<IWarning>,
    updateNodeId:any,
    selectAsModelClass:any,
    lockMasterlist:any,
    setIsModalOpen:any


}

interface ITRowProps{
    data:IWarning;
    nodes:Array<any>;
    selectAsModelClass:(className:string)=>void;
    updateNodeId:(paraIds:Array<string>,nodeId:string)=>void;

}

const headers:Array<any>=[
    {title:"Class Name",style:"flex-[2] "},
    {title:"Warning",style:"flex-[2]"},
    {title:"Number of Paragraphs",style:"flex-[1.4]"},
    {title:"Resolve",style:"flex-[2]"}
    
]

const Thead=({
    headers
}:any):ReactElement=>{
    return (  <div className='flex items-center justify-start  border-b shadow-sm'>

    {
        headers.map((head:any)=>{
              return  <div className={`flex  p-2 ${head.style}`} key={head.title}>
                    <div className=' text-[#9A9A9A] text-[0.75vw]'>{head.title}</div>
                </div>

        })
    }

</div>

    )
}

const SelectAsModeClass = ({selectAsModelClass,className,isResolved,setIsResolved}:any):JSX.Element=>{

    

    return (
        <div className={`${isResolved?"border-gray-400 bg-gray-400 text-white cursor-not-allowed ":
                            " text-primaryBlue bg-white border-primaryBlue hover:scale-105 hover:bg-primaryBlue hover:text-white"}
                    duration-200 border
                    py-[0.3vw] w-[11vw]
                     rounded-md text-center cursor-pointer`}
                     onClick={async()=>{
                        if(isResolved) return;

                        setIsResolved(true);
                       await selectAsModelClass(className);
                        
                    }}
                     >
            { isResolved?"Enabled No Tag":"Enable No Tag"}
        </div>
    )

}

const getWarning =(type:number)=>{

    let warning = "";

    switch(type){
        case -1:
            warning ="No Tag is turned off";
            break;
        case 0:
            warning="Does not exist in masterlist";
            break;
        case 1:
            warning="Not a model class";
            break;
        
    }
    return warning;
}





const TRow =({   data ,nodes,selectAsModelClass,updateNodeId  }:ITRowProps):JSX.Element=>{

    const warning:string =getWarning(data.warningType);
    const project:string = useWorkflowStore(store=>store.currentProject)?.projectType || "";
    const isNoTag:boolean = useWorkflowStore(state=>state.currentWorkflow)?.useNoTag || false;
    const [isResolved,setIsResolved] = useState<boolean>(false);
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const summaryStore = useMasterlistSummary();
   // const ActionElement:JSX.Element =

    const updateUserActions = (paras:string[],node_id:string,key:string)=>{

       console.log("on dropdown value change")
       const node_name:string = nodes.find(node=>node.node_id === node_id)?.node_name;

       
            
            console.log("=== node_id on dropdown change ===",node_id);
            const tempUserActions = summaryStore.userActions;
            tempUserActions[key].node_id = node_id;
            tempUserActions[key].autoSynced = false;
            summaryStore.setUserActions(tempUserActions);
            console.log("=== after update temp ===",node_name,tempUserActions[node_name]);
            console.log("=== after update ===",summaryStore.userActions);

        


    }

    const getValidClassNodeId =(nodeId:string,className:string)=>{

        let node:any = nodes.find(node=>node.node_id === nodeId && node.is_model_class);
        let tempUserActions = summaryStore.userActions;

        if(node){
            return nodeId
        }else {

            // tempUserActions[className].node_id = null;
            // summaryStore.setUserActions(tempUserActions);


            return null;
        }


    }

  

   return (<div className='flex items-center justify-start border-[#fAfAfA] border-b border-b-2 hover:bg-gray-100'>

                           <div className='flex flex-[2] p-2'>
                               <div className='  text-[0.75vw] font-bold'>{data.className}</div>
                           </div>
                           <div className='flex flex-[2] p-2 '>
                               <div className=' text-[#9A9A9A] text-[0.75vw]'> {warning}</div>
                           </div>
                           <div className='flex flex-[1.4] p-2 '>
                               <div className=' text-[#9A9A9A] text-[0.75vw]'>{data.noOfPara}</div>
                           </div>
                           <div className='flex flex-[2] p-2 '>
                               <div className=' text-[#9A9A9A] text-[0.75vw] w-full'>
                                   {
                                        data.warningType!==-1 ? <Select
                                        value={getValidClassNodeId(summaryStore.userActions[data.className]?.node_id,data.className) || null}
                                        disabled={isLoading}
                                        onChange={async(e)=>{
                                           setIsLoading(true);
                                           //updateNodeId(data.paraIds,e);
                                           updateUserActions(data.paraIds,e,data.className);
                                           setIsLoading(false);
                                        }}
                                         placeholder="Select a class"
                                         className='w-[11vw]  text-[0.75vw] '>
                                            <Select.Option
                                                       
                                                       className="text-[1vw]"
                                                       value={null}>
                                                           {"No Associated Class"}
                                                       </Select.Option>
                                           {
                                               nodes.map((node:any)=>{
                                                       return node.is_model_class?<Select.Option
                                                       key={node.node_id}
                                                       className="text-[1vw]"
                                                       
                                                       value={node.node_id}>
                                                           {node.node_name}
                                                       </Select.Option>:null
                                               })
                                           }
                                               
                                        </Select>
                                        :<SelectAsModeClass
                                        selectAsModelClass={selectAsModelClass}
                                        className={data.className}
                                        isResolved={isNoTag}
                                        setIsResolved={setIsResolved}
                                        />
                                   }
                                   
                                    </div>
                           </div>
                       </div>)
   
}


export default function MLCSummaryTab({
    modalNodes,
    nodes,
    avgKeyWrd,
    dataSummary,
    warnings,
    updateNodeId,
    selectAsModelClass,
    lockMasterlist,
    setIsModalOpen

}:ISummaryTabProps) {

    const nodesState = useRecoilState(nodesAtom);
    const classes = useMasterlistSummary(state=>state.classes);  
    const summaryStore = useMasterlistSummary();
    
      
  return (<div className=' w-full h-full bg-[#fAFaFA] flex flex-col gap-[2vw] ' >
                {/* Summary Container */}
                <div className=' flex gap-[1vw] '>
                    {/* Masterlist Summary */}
                    <div
                    className=' flex-1  p-[0.7vw]  h-[11vw] bg-white drop-shadow-md'

                    >
                        <h1 className="text-[1vw] font-bold text-[#7A7A7A]">MLC Masterlist Summary</h1>
                        <div className='flex flex-col mt-3 gap-[1vw]'>
                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Model Nodes</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{modalNodes}</p>
                            </div>
                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Total Nodes</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{nodes.length}</p>
                            </div>
                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Average Keywords</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{avgKeyWrd.toFixed(0)}</p>
                            </div>


                        </div>

                    </div>
                    {/* Data Summary */}
                    <div className=' flex-1  p-[0.7vw] h-[11vw] bg-white drop-shadow-md '>
                        <h1 className="text-[1vw] font-bold text-[#7A7A7A]">Data Summary</h1>
                        <div className='flex flex-col mt-3 gap-[1vw] '>
                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Total Paragraphs</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{classes.totalParas}</p>
                            </div>
                            <div className='flex justify-between border-b  '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Seed Paragraphs</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{classes.seedParas}</p>
                            </div>
                            <div className='flex justify-between border-b '>
                                <p className='!text-[0.9vw] text-[#7A7A7A]'>Number Of Seed Classes</p>
                                <p className='!text-[1vw] text-[#7A7A7A]'>{classes.seedClasses}</p>
                            </div>


                        </div>

                    </div>

                </div>

                {/* Warnings Container */}
                <div className=' flex flex-col bg-white drop-shadow-md  p-[0.7vw]'>
                    <div className='text-[1.1vw] text-[#9A9A9A] border-b pb-2'>Warnings({warnings.length}) </div>

                    {/* Warning Table */}
                    <div className='flex flex-col gap-[0.5vw]   '>
                        {/* Header */}
                        <Thead 
                        headers={headers}
                        />
                      


                        {/*Rows */}
                        <div className='flex flex-col gap-[0.5vw] h-[15vw] custom_scroll '>

                        {
                            warnings.length>0? warnings.map((warning)=>{
                                return (

                                    <TRow 
                                    key={warning.className}
                                    data={warning}
                                    nodes={nodes}
                                    selectAsModelClass={selectAsModelClass}
                                    updateNodeId={updateNodeId}
                                    
                                    />
                                )
                            }):<div className='w-full h-full flex items-center justify-center text-[#9A9A9A] text-[1vw]'>
                                No Warnings
                            </div>
                        }
                          
                         
                        
                        </div>

                      




                    </div>




                </div>

                    {/* Confirm Buttons */}
                    {/* <div className='flex gap-[1vw] justify-end pb-[1vw]'>

                    <div className='uppercase 
                        flex justify-center items-center cursor-pointer
                        w-[7vw] h-[2.5vw] text-center rounded-[0.2vw]
                        hover:border-primaryBlue border border-transparent hover:scale-110 duration-200
                        '
                        onClick={()=>setIsModalOpen(false)}
                        >
                            <span className='text-[1vw]'>Cancel</span>
                        </div>
                        
                        <div className='uppercase bg-primaryBlue cursor-pointer
                        flex justify-center items-center hover:scale-110 duration-200
                        text-white w-[7vw] h-[2.5vw] text-center rounded-[0.2vw]'>
                            <span className='text-[1vw]'
                            onClick={()=>{
                                if(nodes.filter(node=>node.is_model_class).length<2)
                                {
                                    toast("The number of model nodes should be more than 1 and should be less than 101",
                                    {
                                        type:"warning"
                                    })
                                }else{
                                    lockMasterlist();
                                }
                            }}
                            >Continue</span>
                        </div>
                    </div> */}
            </div>
  )
}
