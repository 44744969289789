import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as DownChevrolet } from '../../../assets/down_chevrolet.svg'
import { ReactComponent as SearchIcon } from '../../../assets/search.svg'
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';

interface IClassFilterSelector {
    options: any,
    onChange: (value: string) => void,
    defaultOption: any,
    value: string


}


export const FilterOption = ({ setOpen, onChange, option }: any) => {




    return (<li
        onClick={() => {
            //setCurrent(option);
            setOpen(false);
            onChange(option.value)
        }}
        key={option.id}
        className='cursor-pointer select-none w-full px-[1vw] flex items-center justify-between hover:bg-[#E6F7FF] '>

        <div className=' py-[0.5vw]  cursor-pointer  focus  :bg-gray-200 text-[0.9vw] ' >
            {option.text.length>32? option.text.slice(0,32) +"...":option.text}</div>

        <div
            style={{ color: option?.color }}
            className=' py-[0.6vw]  cursor-pointer  focus  :bg-gray-200 text-[0.9vw] ' >
            {option.count}</div>


    </li>)


}


function ClassFilterSelector({
    options,
    onChange,
    defaultOption,
    value

}: IClassFilterSelector) {

    const [open, setOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const optionsRef = useRef<HTMLUListElement>(null);


   // const dropOptions = options.sort((a: any, b: any) => a.count > b.count ? -1 : 1);

    const current: any = value === "" ? defaultOption
        : options.find((op: any) => op.value === value);


    // const filteredList = searchTerm.length > 0 ?
    // dropdownOptions.filter((option: any) => option.text.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
    // : dropdownOptions


    useEffect(() => {
        if (open) {
            optionsRef.current && optionsRef?.current?.scrollTo({ top: 0 });
        }


    }, [open]);


    return (
        <div className='w-full   '>
            {
                open && <div className='h-screen w-screen fixed top-0 left-0 right-0 z-[45]'
                    onClick={() => {
                        setOpen(false);
                        //setKeyboardEnabled(true); 
                    }}
                ></div>
            }

            {/*  Container  */}
            <div
                onClick={() => { if (!open) { setOpen(true); } }}
                className={`w-full border-2  py-[0.6vw] px-[0.6vw] rounded-[0.3vw]  
                relative flex items-center justify-between  cursor-pointer
                ${true ? "bg-gray-100 border-transparent" : ""}`}

            >
                <div className='flex justify-between w-full'>
                    <p className='text-[1vw] select-none'>
                    {current?.text.length>30? current.text.slice(0,30) +"...":current.text}</p>
                    <p
                        style={{ color: current?.color }}
                        className={`text-[1vw] select-none mr-2 `}>
                        {current?.count}</p>

                </div>


                <DownChevrolet className='w-[0.8vw] h-[0.8vw]' />


                {
                    <div className={`absolute  z-[49] left-0 top-[100%]
                        border rounded-md bg-white w-full duration-300 origin-top
                      
                        ${open ? "" : " scale-y-0 opacity-0"}`}>

                        {/* <div className='flex items-center justify-center m-[0.7vw]'>
                            <div className='flex items-center justify-between rounded-full w-full border px-[0.8vw] py-[0.4vw]'>
                                <input className='focus:outline-none w-[85%] text-[0.8vw]'
                                    placeholder='Search class'
                                    value={searchTerm}
                                    onChange={(e: any) => setSearchTerm(e.target.value)}

                                />
                                {searchTerm.length ? <CrossIcon className='w-[0.9vw] h-[0.9vw] cursor-pointer'
                                    onClick={() => setSearchTerm("")} /> : <SearchIcon className='w-[1vw] h-[1vw]' />}
                            </div>

                        </div>
                        <hr /> */}
                        <ul className='bg-white custom_scroll max-h-[12.8vw]  shadow-lg rounded-b '
                            ref={optionsRef}
                        >
                            <FilterOption
                                    setOpen={setOpen}
                                    option={ defaultOption}
                                    onChange={onChange}
                                />
                            {options
                                .sort(((a:any,b:any)=>a.count>b.count?-1:1))
                                .map((option: any) => <FilterOption
                                    setOpen={setOpen}
                                    option={ option}
                                    onChange={onChange}
                                />)


                            }


                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}

export default ClassFilterSelector