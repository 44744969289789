import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import { toast } from "react-toastify";

import { useRecoilValue } from "recoil";
import { nodesAtom } from "../../../masterlist/atoms";
import NerPara from "../../../ner-components/NerPara";
import useWorkflowStore from "../../../../store/useWorkflowStore";
import Axios from '../../../../utils/Axios';

import ValidationControls from "./MLCStage2ValidationControls";
import useKeyboard from "../../../../hooks/useKeyboard";
import { getClassesInAlphabeticOrder } from "../../../stage-2/helper";


export interface StageParaProps {
 
  para:any;
  
  accepted: null | string;
  stage: number;
  
  index:number;
  activePara:number;
  setActivePara:any;
  next:()=>void;
  prev:()=>void;
  readOnly?:boolean;
  keyboardEnabled?:boolean;
  setKeyboardEnabled?:any;

  setParas:any;
  setCount:any;
  setNodeCount:any;
  fileInfo:any;

  others?:any
  
}

const ListViewPara: React.FC<StageParaProps> = (props) => {

  const {
    
    
    para,
    //paraData,
    //paraId,
    accepted,
    stage,
    //predictedIssueId,
   // nextPredictedIssueId,
    index,
    activePara,
    setActivePara,
    next,
    prev,
    readOnly,
    keyboardEnabled,
    setKeyboardEnabled,
    setParas,
    setCount,

    setNodeCount,
    fileInfo,



    others

  } = props;

 // console.log("=== para ===",para)
  //let [expanded, setExpanded] = useState(false);
  let [fade, setFade] = useState(false);
  let [accept, setAccept] = useState<boolean | null>();
  let [loading,setLoading] = useState<boolean>(false);
  const [tempValidations,setTempValidations] = useState<any[]>(para.validations);
  
  //--- manage delta changes in validated nodes ---
  const {initNodeDelta,isActiveBatch} = others;
  const [delta,setDelta] = useState<any>(initNodeDelta);
  
  
  const {currentWorkflow} = useWorkflowStore();
  const isActive = index === activePara;
  let nodes = useRecoilValue(nodesAtom);

  //sorted in alphabetic order
  const nextClasses:any[] =useMemo(()=>getClassesInAlphabeticOrder(nodes),[nodes] ) ;
  const [isMounted,setIsMounted] = useState<boolean>(false);

  //Keyboard Shortcuts
  
  const divRef = useRef<HTMLDivElement>(null);
  const Enter = useKeyboard({keyCode:"Enter"})
  const BackSpace = useKeyboard({keyCode:"Tab"})
  let waitFor = (duration:number)=>{
    return new Promise(resolve=>setTimeout(resolve,duration));
  }


  useEffect(()=>{

    const wait = async()=>{
      await waitFor(100);
      //divRef.current?.scrollIntoView({block:"start"})
      divRef.current?.scrollIntoView({block:"nearest"});
      if(activePara === 0)
      {
        divRef.current?.scrollIntoView({block:"nearest",behavior:"smooth"});
        window.scrollTo({top:0,behavior:"smooth"});
      
      }
    };
    if(isActive)
    {
      wait();
     
    
      
    }
   
  },[isActive]);

  useEffect(()=>{
    const accepteParaAndMove =async()=>{
      if(!keyboardEnabled) return;
      if(Enter && isActive)
      {
        
        if((!accepted || accepted!=="accepted") && !loading && !readOnly) {
          //next();
          //await acceptPara();
          
        }
        
       
      }

    }
    accepteParaAndMove();



  },[Enter,isActive])

  useEffect(()=>{
    const rejectParaAndMove = async()=>{
      if(!keyboardEnabled) return;
      if(!BackSpace) return;
      if(!isActive) return;
     
        if((!accepted || accepted!=="rejected") && !loading && !readOnly ){
         // console.log("--para id--",para.paraId);
         // await rejectPara();
          //next();
        } 
      

    }
    rejectParaAndMove();
    
    
  },[isActive,BackSpace])
  //Keyboard Shortcuts

  React.useEffect(() => {
    setIsMounted(true);

    // let temp:any={};
    // nodes.forEach((node:any)=>{
    //   if(node.is_model_class)
    //   temp[node.node_id] = 0; 
    // });
    // setDelta(temp);
  }, []);

  


  React.useEffect(() => {
    setAccept(accepted === "accepted");
  }, [accepted]);


  //-----------------------------------------------------------
  // Handle Class Selection
  //-----------------------------------------------------------
  let handleTagSelection = async (validations: Array<any>,delta:any) => {
   
    setLoading(true);
    // updating the counting
      //if validations is empty
        //if already annotated 
            // if action is rejected then do not decrease count
            
            // if actions is accepted then decrease count

      //else
        // if action is accepted then do not increase count
        // else increase the count

      let isAnnotated=para.action;
      const nodeCount = { ...others.nodeCount }

      if(!validations.length){
        //Increase node count from delta
       // alert("Inside null condition");
        if(readOnly){

        }
        
        
       // alert("validation is empty")
        if(isAnnotated){
          
          if(para.action === "rejected")
          {

           // alert("already rejected")
              //do nothing
          }else{
             // alert("removed class from unannoted para");
              setCount((prev:number)=>prev-1);
              Object.keys(nodeCount).forEach((key)=>{
                nodeCount[key].count += delta[key]
                if(nodeCount[key] <= 0) nodeCount[key] =0;
              });
          }
        }

        
        setParas((prev:any)=>{
        let paras = [...prev];
       // const found = prev.findIndex((p:any)=>p.paraId === para.paraId);
        
        paras[para.index].validations=validations;
        paras[para.index].action="rejected";
        return paras;
    })



      }else{
        if(isAnnotated){
         

          //Increase node count from delta
          Object.keys(nodeCount).forEach((key)=>{
            nodeCount[key].count += delta[key]
            if(nodeCount[key] <= 0) nodeCount[key] =0;
          });
          if(para.action === "accepted")
          {
              //do nothing
          }else{
              setCount((prev:number)=>prev+1);
          }
        }else{
          //increase count for suggested classes
          setCount((prev:number)=>prev+1);
         // console.log("untouched para validated",validations)
          validations.forEach((key)=>{
            nodeCount[key].count += 1
          });
        }


        
        
        setParas((prev:any)=>{
        let paras = [...prev];
       //const found = prev.findIndex((p:any)=>p.paraId === para.paraId); 
       paras[para.index].validations=validations;
        paras[para.index].action="accepted";
        return paras;
    })
    


      }



   
   
   
    // console.log("=== before updating state ===",nodeCount,initNodeDelta);     
     isActiveBatch &&  setNodeCount(nodeCount);
     setDelta(initNodeDelta);
      

      const reject_res = await Axios.post("/annotations/mlc-para-action", {
        paraId:para.paraId,
        //action: "accepted",
        stage,
        workflowId: currentWorkflow?.workflowId,
        validations:validations

      });

          


   
      
    setLoading(false);


  };

  //-----------------------------------------------------------
  // Handle Is Saved change
  //-----------------------------------------------------------
  let handleIsSaved = (value:boolean)=>{

    setParas((prev:any)=>{
      let paras = [...prev];
     //const found = prev.findIndex((p:any)=>p.paraId === para.paraId); 
      paras[para.index].isSaved=value;
      return paras;
  })



  }
  

  let dropDownOptions = useMemo(() =>
  {
    if(currentWorkflow?.useNoTag)
    {
      
      return [{ value: "No Tag", text: "No Tag", id: "No Tag" }].concat(
       nextClasses.map((nd:any)=>{
        let node:any = nodes.find((node)=>node.node_id === nd[0]);
        let value= node.node_id
        let text=node.node_name
        let id= node.node_id
        return {value,text,id};

       })
      )
    }
    else{
      return  nextClasses.map((nd:any)=>{
        let node:any = nodes.find((node)=>node.node_id === nd[0]);
        let value= node.node_id
        let text=node.node_name
        let id= node.node_id
        return {value,text,id};

       })
  

    }


  },[nodes,nextClasses,currentWorkflow?.useNoTag]
);



  const getClassName =(nodeId:string)=>{

    if(currentWorkflow?.useNoTag)
    {
      if(nodeId ==="No Tag") return "No Tag";
    }
 
    return nodes.find((node:any)=>node.node_id === nodeId)?.node_name ||""


  }
 

  return (
    <div
    ref={divRef}
    onClick={()=>setActivePara(index)}
      onMouseDown={(ev) => {
        //nerStore.setCurrentParaId(paraId);
      }}
      key={para.paraId}
      className={`w-full flex  duration-200  border min-h-[8vw]
      scroll-mt-[10vw] scroll-mb-[5vw]
      ${  fade ? "opacity-0" : ""}
      ${isMounted?"":"opacity-0"}
      ${isActive?"border-[#11256d] shadow-lg":"border-transparent"}
      ${para.action?"bg-gray-100":"bg-white"}
      `}
    >
      <div
        className={"w-[60%]  rounded-[0.3vw] py-[1vw] px-[1.5vw] "}
      >
        {/* <div className="flex border-b pb-[0.2vw] justify-between w-full">
      <p className="text-[0.8vw]   pb-1">{para?.paraSeqId} </p>
       <p className="text-[0.8vw]  pb-1">{fileInfo[para?.fileId || ""]?.fileName }</p>
        </div> */}
        <NerPara 
        key={para.paraId} 
        paraData={para.para_info.paraData} 
        paraId={para.para_info.paraId} 
        expanded={isActive}
        minlength={670}
        />
      </div>

      <div className=" border my-[1vw] border-dashed border-l-1"></div>

      <div className="w-[40%]">

        <ValidationControls
        
        handleTagSelection={handleTagSelection}
        loading={loading}
        readOnly={readOnly || false }
        
        validations={para.validations}
        dropdownOptions={dropDownOptions}
        accepted={accepted}
        node_name={getClassName(para?.predictedIssueId[0])}
        keyboardEnabled={keyboardEnabled || false}
        setKeyboardEnabled={setKeyboardEnabled}
        active={isActive}

        others={{
          delta,
          setDelta,
          tempValidations,
          setTempValidations,
          initNodeDelta,
          isSaved:para.isSaved,
          handleIsSaved
          
        }}
        


        />
      </div>
      
    </div>
  );
};

export default React.memo(ListViewPara);
