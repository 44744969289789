import React, { useState, useEffect } from 'react'
import useWorkflowStore from '../../../store/useWorkflowStore';
import { useRecoilValue } from "recoil";
import { nodesAtom } from '../../masterlist/atoms';
import Axios from '../../../utils/Axios';
import { toast } from 'react-toastify';
import useAuthStore from '../../../store/authStore';
import useKeyboard from '../../../hooks/useKeyboard';
import MLCStagePara from './validation/MLCStagePara';
import { Pagination, Popover, Select, Tooltip } from 'antd';
import {Navigate, useNavigate} from 'react-router-dom';
import ClassFilterSelector from '../_shared/ClassFilterDropdown';


//Icons
import { ReactComponent as Loading } from '../../../assets/loading_blue.svg';
import {ReactComponent as LoadingGreen} from '../../../assets/loading_circle.svg';
import { ReactComponent as Refresh } from '../../../assets/reload.svg';
import {ReactComponent as MultiUserIcon} from '../../../assets/mutiuser.svg';
import {ReactComponent as AchievedTarget} from '../../../assets/achieved_target.svg';
import {ReactComponent as TotalTarget} from '../../../assets/total_target.svg';
import {ReactComponent as LoadingWhite}  from '../../../assets/loading_white.svg';
import NoPara from '../../stage-2/NoPara';
import template from '../../../template';
import LoadingScreen from '../../loading-screen';


const HoverMenu = ({children,text}:any)=>{
  return (

  <Popover
  className='!text-[2vw]'
  placement="top"
  content={text} color="white"
  
  >
      {children}
  </Popover>
  )
}


function Index() {

  //data store
  const [paras, setParas] = useState<Array<any>>([]);
  const [fileInfo,setFileInfo] = useState<any>({});
  const [ filter,setFilter ] = useState<string>("");
  const [dropdownOptions,setDropDownOptions] = useState<any>({});

  //count states
  const [count, setCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalParas, setTotalParas] = useState<number>(0);
  const [nodeCount,setNodeCount] = useState<any>({});
  const [multiUserNodeCount,setMultiUserNodeCount] = useState<any>({}); 
  const MIN_USER_THRESHOLD =1;

  //Loading States
  const [loading, setLoading] = useState<boolean>(false);
  const [pieLoading, setPieLoading] = useState<boolean>(false);
  const [stage2Loading, setStage2Loading] = useState<boolean>(false);

  //control states
  const [canMoveToStage2,setCanMoveToStage2] = useState<boolean>(false);
  const classTargetMessage = "Complete the target for atleast 2 classes";
  const noTagTargetMessage = "Complete the target for No Tag";
  const [message,setMessage] = useState<string>(classTargetMessage);
  const targetParas = 30;



  //global states
  const projectType:string =useWorkflowStore(state=>state.currentProject)?.projectType || "";
  const workflowType:string =useWorkflowStore(state=>state.currentProject)?.workflowType || "";
  //const retrainEndpoint:string = template[projectType][workflowType]?.stage1?.endpoints?.initStage2;
  const stage2Endpoint:string = template[projectType][workflowType]?.stage1.endpoints.initStage2;
  const GetParasEndpoint:string = template[projectType][workflowType]?.stage1.endpoints.getParas;


  const navigate = useNavigate();
  const nodes = useRecoilValue(nodesAtom);
  const currentWorkflow = useWorkflowStore(state => state.currentWorkflow);
  const userId: string = useAuthStore(state => state.userDetails?.userId) || "";
  const firstName: string = useAuthStore(state => state.userDetails?.firstName) || "";
  const lastName: string = useAuthStore(state => state.userDetails?.lastName) || "";
  const createdBy:string = useWorkflowStore(state=>state.currentProject?.createdBy) || "";
  const isCreater = userId === createdBy;
  const stage1Progress =currentWorkflow?.alpdsInfo?.percentageStatus?.stage1Progress || 0;
  const stage2Progress =currentWorkflow?.alpdsInfo?.percentageStatus?.stage2Progress?.currentBatchProgress;


  //Listview information
  const trainingOffset =currentWorkflow?.listviewInfo?.retrainTarget || 45; //200;
  const listviewInfo:any = currentWorkflow?.listviewInfo;
  // const [page, setPage] = useState<number>(listviewInfo?.userInfo?listviewInfo?.userInfo[userId]?.lastAccessedPage || 1:1);
  const [page, setPage] = useState<number>(1);
  const pageSize = 20;
  const target = (listviewInfo?.lastTrainingCount | 0) +trainingOffset;
  const [isMinThreshold,setIsMinThreshold] = useState<boolean>(false);
  const [allUsersData,setAllUsersData] = useState<any>({});
  // const canCallRetrain = totalCount >=target || count>=target;
  const [initNodeDelta,setInitNodeDelta] = useState<any>({});
  
  const noTagName ="No Tag"
  


  //keyboard navigation
  const [activePara, setActivePara] = useState<number>(0);
  const [keyboardEnabled, setKeyboardEnabled] = useState<boolean>(true);
  const ArrowDown = useKeyboard({ keyCode: "ArrowDown" });
  const ArrowUp = useKeyboard({ keyCode: "ArrowUp" });
  
  //Pagination states
  const offset = 20;
  const start = (page-1) * pageSize;
  const end = start + offset;
  const current_paras =  paras
  ?.filter((p:any)=>filter.length>0? p.validations.indexOf(filter)!==-1:true);
  
  
  

  const PrevPara = () => {
    if (!keyboardEnabled) return;
    if (ArrowUp && activePara > 0) {
      setActivePara(prev => prev - 1);
    }

  }


  const NextPara = () => {
    if (!keyboardEnabled) return;
    if (paras && activePara < 19) {

      setActivePara(prev => prev + 1);
    }
  }
  //Move to Next Paragraph on Arrow Down
  useEffect(() => {
    ArrowDown && NextPara();
  }, [ArrowDown]);

  //Move to previous paragraphs on Arrow Up
  useEffect(() => {
    ArrowUp && PrevPara();

  }, [ArrowUp]);

    //Update Class Selector when nodecounts changes
    useEffect(() => {
     // alert("count changed");
     let threshold:number =0;
     let noTagThreshold:number = 0;
     const temp =  Object.keys(nodeCount).map((key)=>{
      
      //check for minimum target
      if(key !== noTagName){

        if(nodeCount[key]?.count>=targetParas 
          || (multiUserNodeCount[""]?.count ||0 )>= targetParas
          ) threshold++;
      }else{
        if(nodeCount[key]?.count>=targetParas
          || (multiUserNodeCount[key]?.count ||0) >= targetParas
          ) noTagThreshold++;
      }

      
      



      return { text:nodeCount[key].node_name,
                                    value:key,
                                    count:nodeCount[key].count, 
                                    color:nodeCount[key].count<targetParas?"#E63946":null}
    })


    if(threshold>=2)
    {
      if(currentWorkflow?.useNoTag)
      {
        if(noTagThreshold>0)
        {
          setCanMoveToStage2(true);
        }else{
          setCanMoveToStage2(false);
          setMessage(noTagTargetMessage);
        }
      }else{

        setCanMoveToStage2(true);
      }
    }else{
      setCanMoveToStage2(false);
      setMessage(classTargetMessage);
    }

     setDropDownOptions(temp);
     
  
    }, [nodeCount,count,multiUserNodeCount]);

  // ----- Calculate Initial Value of NodeDelta when page mounts -----
  useEffect(()=>{
    if(nodes.length){
      const temp:any = {};
      nodes.filter((node:any)=>node.is_model_class).forEach((node:any)=>{
        temp[node.node_id] =0;
      });
      
      //Add No Tag
      
      if(currentWorkflow?.useNoTag){
        temp[noTagName] = 0;
      }

      setInitNodeDelta(temp);
     // console.log("=== initial node delta ===",initNodeDelta);
     // console.log("=== setting initNodeDelta",temp);

    }


  },[nodes]);


  // ------ get total annotated paragraphs on all pages from multiuser pie chart -----
  const fetchCountDetails = async (isMounted: boolean) => {


    if (currentWorkflow) {
      try {

        //Set Loading True
        setPieLoading(true)

        let res = await Axios.post("/summary/multiuserpiechart", {
          workflowId: currentWorkflow.workflowId,
          stage: "stage1",
          batchNumber: 1
        })
        //console.log("graph data =>", res.data.data);
        let result = res.data.data;
        setAllUsersData(result);
        let users = "num_user_"+MIN_USER_THRESHOLD;

        let classes = Object.keys(result[users]).filter((key: string) => key !== "null");

        let total = 0;
        let tempCount:any = {};
        classes.forEach((key: string) => {
          total += result[users][key] || 0;
          tempCount[key]={}
          tempCount[key].count = result[users][key];

        })
        console.log(classes,tempCount);
        setTotalCount(total);
        setMultiUserNodeCount(tempCount);
        


      } catch {
        toast("Could not get user pie chart data. Please reload the page.", { type: toast.TYPE.ERROR });
      }
      finally {
        setPieLoading(false);
      }

    }

  }

  // ------ fetch paragraphs ------
  // ------ get total paragraphs ----
  const fetchParas = async () => {

    //if (loading || !currentWorkflow) return;
    if(!currentWorkflow) return;
    setLoading(true);
    try {

      let res: any = await Axios.post("/annotations/"+GetParasEndpoint, {
        workflowId: currentWorkflow?.workflowId,
        page: page,
        pageSize: pageSize,
        stage:1
      });

      let result: Array<any> = res.data.data.result.paras;
      let total_paras = res.data.data.result.total_paras;
      let count = res.data.data.result.total_count || 0;


      let class_count:any = {};

      nodes.filter((node:any)=>node.is_model_class).forEach((node:any)=>{

        class_count[node.node_id] = {};
        class_count[node.node_id].node_name =node.node_name;
        class_count[node.node_id].count = 0;


      })
      //Add No Tag
      const noTagName ="No Tag"
      if(currentWorkflow.useNoTag){
        class_count[noTagName] = {};
        class_count[noTagName].node_name =noTagName;
        class_count[noTagName].count = 0;

      }

      let annotated: number = 0;
      //result.sort((para1:any,para2:any)=>para1.paraSeqId >para2.paraSeqId?1:-1);
      result.sort((a:any,b:any)=>{
        
        if(a.predictedIssueId[0] === noTagName) return 1;
        if(b.predictedIssueId[0] === noTagName) return -1;
        return a.predictedIssueId.length>b.predictedIssueId.length?-1:1
      
      });
      result.forEach((para: any) => {
        //console.log("=== fetched paras ===",para);
        let validations: any = para?.validations;
        let userValidation: Array<any> | undefined = validations[userId];
        if (userValidation && userValidation?.length ) {
            if(userValidation[0]?.issueId !== null)
            {
                para.action = "accepted";
                para.validations=userValidation.map(u=>u.issueId);
                delete para.para_info.validations;
                delete para.workflows;
                annotated++;
                para.validations.forEach((v:any)=>{
                  class_count[v].count++;
                })


            }else{
                para.action = "rejected";
                para.validations=[];
                delete para.para_info.validations;
                delete para.workflows;
                

            }


        }
        

        else {
          para.action = null;
          para.validations=para.predictedIssueId;
        }



      });

    //  console.log("=== class count ===",class_count);


      //console.log(result);
      setCount(count);
      setNodeCount(class_count);
      setParas(result);
      setTotalParas(total_paras);
      setActivePara(0);
    } catch (err) {
      toast("Could not fetch paras", { type: toast.TYPE.ERROR });
      console.log("Error while fetching paragraphs",err);

    } finally {
      setLoading(false);
    }
  }

  // ------ fetch files ------
  const fetchFiles = async()=>{

    try{

      let files:any = await Axios.post("/file/getAllUploadedFile",{
        workflowId:currentWorkflow?.workflowId
      });

      const file_info = files.data.data;
      

      let file_state:any = {}
      file_info.forEach((file:any)=>{
        let object:any ={}
        object.fileId = file.fileId;
        let fileName:string = file.fileName || "";
        fileName = fileName.slice(13);
        object.fileName = fileName;
        
        file_state[file.fileId] = object;
      });
      setFileInfo(file_state);
      //console.log(file_state);


    }catch(err){
      toast("There was an error while fetching file information.Please reload the page and try again",
      {
        type:toast.TYPE.ERROR
      })
    }

  }

  // -------- check if atleast 2 classes have paras annotated -------

  const checkConstraints = ()=>{
    let classThreshold:any ={};
    paras?.forEach((para:any)=>{

      if(para.accepted === "accepted" && para?.predictedIssueId[0]!=="No Tag"){
        let node_id:string = para?.predictedIssueId[0];
        
        if(classThreshold[node_id]){
          classThreshold[node_id]++;
        }
        else{
          classThreshold[node_id] =1;
        }

      }else if(para.accepted === "rejected"){
        let node_id = para.nextPredicted?.issueId;
          if(para.nextPredicted.selection && node_id!=="No Tag")
          {
            let node_id = para.nextPredicted.issueId;
            if(classThreshold[node_id]){
              classThreshold[node_id]++;
            }
            else{
              classThreshold[node_id] =1;
            }

          }
      }
    });
   // console.log("classes info",classThreshold);

    let result = {...allUsersData};
    let users = Object.keys(result)[0];

    let classes = Object.keys(result[users]).filter((key: string) => key !== "null" && key!=="No Tag");

    //console.log("classes from all users data",classes);

    if(Object.keys(classThreshold).length<2 && classes.length<2)
    {
      return false;
    }

    return true;


  }

  // ------ call retrain api ------
  const triggerStage2 =async()=>{
    if(!currentWorkflow || stage2Loading) return;
   // toast("calling")

    setStage2Loading(true);
   
    try{
     await Axios.post(`/integration/${stage2Endpoint}`,{
        workflowId:currentWorkflow.workflowId,
        batchNumber:1
      });

    }catch{

    }finally{
      setStage2Loading(false);
    }


  }

  //----------------------------------------------------
  //Unlock Masterlist & Redirect User To Masterlist Page
  //----------------------------------------------------
  let resetStage1 = async () => {

    //--- Make a request to reset Stage 1 here ---

    Axios.post("/masterlist/markLocked", {
      workflowId: currentWorkflow?.workflowId,
      markAs: false,
      user: userId
    });

    navigate("/workflow/masterlist?workflowId=" + currentWorkflow?.workflowId);
  };

  
  //----------------------------------------------------
  //Fetch paragraphs on component load or refresh
  //----------------------------------------------------
  useEffect(() => {

    if(stage1Progress === 100 && !loading)
    {

      let p1 = fetchParas();
        isCreater && fetchCountDetails(true);
      //fetchFiles();
      //Promise.all([p1, p2]);
      //window.addEventListener("scroll", onScroll);
  
      // return () => window.removeEventListener("scroll", onScroll);
    }





  }, [stage1Progress])

  //----------------------------------------------------
  //Fetch paragraphs on page number change
  //----------------------------------------------------
  useEffect(() => {
    if(!loading)
    {
      setActivePara(0);
      window.scrollTo({top:0,behavior:"smooth"});
     
    }
  }, [page]);

  useEffect(() => {
    if(!loading)
    {
      setActivePara(0);
      setPage(1);
      window.scrollTo({top:0,behavior:"smooth"});
      
    }
  }, [filter]);

  
  //----------------------------------------------------
  //notify user that they can mvoe to stage2
  //----------------------------------------------------
  useEffect(()=>{
    if(createdBy === userId && canMoveToStage2)
    {
        
        toast("You can now move to stage 2.",{type:toast.TYPE.SUCCESS});
    }



  },[canMoveToStage2]);


  if(stage1Progress !== 100){
    return <LoadingScreen
            msg='Please wait while we annotate paragraphs for you'
            compleated={stage1Progress}
            />
  }


  if(stage2Progress !== undefined)
  {
     navigate("/workflow/stage2?workflowId="+currentWorkflow?.workflowId)
  }




  if(!loading && (totalParas<30 ))
  {
    return (
      <div className="uppercase w-full min-h-[25vw] flex justify-center px-[1.5vw] py-[0.6vw] text-[1.3vw] flex-col items-center text-center">
      You don't have enough data for annotation. Please provide
      relevant keywords for the nodes. <br />
      <button
        onClick={() => {
          resetStage1();
        }}
        className="uppercase bg-primaryBlue text-white px-[1vw] py-[0.7vw] mt-[1.5vw] text-[1.1vw] rounded-[0.5vw] shadow-[0.2vw]"
      >
        Go to masterlist
      </button>
    </div>
    )
  }


  return (
    <div>
      <div className={`z-[50]  w-full  bg-[#fafafa] sticky top-[4.9vw]
                      text-[1.2vw] text-[#7a7a7a]  p-2 
                      flex justify-between items-center  border-b flex-1`}>
        <p>Suggested Paragraphs</p>
        <div className=' flex space-x-[1vw] items-center'>

          <div className='w-[20vw]'>
          {/* <Select 
          //mode={"multiple"}
          className='w-[20vw] !text-[1.1vw] !py-[0.4vw]  rounded bg-[#EAEAEA]'
          bordered={false}
         // maxTagCount={"responsive"}
          //allowClear
          showSearch
          placeholder="Showing result for all classes"
          onChange={(e:any)=>setFilter(e)}
          
          >
           <Select.Option value={""} className="text-[0.8vw] h-[2vw]" >
                  <div className='flex justify-between items-center   text-[0.9vw]'>
                    <p>All</p>
                    <p>{count}</p>
                      
                  </div>
                  </Select.Option>
            {
              Object.keys(nodeCount)
              .sort((a:string,b:string)=>nodeCount[a].count < nodeCount[b].count?1:-1)
              .map((key:string)=>{
                return <Select.Option value={key}  classsName="h-[2vw]">
                  <div className='flex justify-between items-center  text-[0.9vw]'>
                    <p>{nodeCount[key].node_name}</p>
                    <p>{nodeCount[key]?.count || 0}</p>
                      
                  </div>
                  </Select.Option>
              })
            }
          </Select> */}

             <ClassFilterSelector 
             defaultOption={{
              text:"All Classes",
              value:"",
              color:"",
              count:count
             }}
             options={dropdownOptions}
             onChange={(e:any)=>setFilter(e)}
             value={filter}
             />

          </div>



          <div className='flex-1 flex items-center space-x-[0.2vw]'>
            <HoverMenu text={`Annotated by ${firstName} ${lastName}`}>

           <div><AchievedTarget className=' w-[1.8vw] h-[1.8vw]'/></div>
            </HoverMenu>
            <p className='text-[#1BA94C] select-none'>{count}</p>
          </div>
          {isCreater &&  <div className='flex-1 flex items-center space-x-[0.3vw]'>
            <div>
             <HoverMenu text="Annotated by all users">

              <MultiUserIcon className=' w-[2vw] h-[2vw]' />
             </HoverMenu>

             
            </div>
            

            <p className='text-[#1BA94C] select-none'>
              {
                filter === ""?totalCount
                :multiUserNodeCount[filter]?.count || 0
              }</p>
           
            </div>}
            <div className='flex-1 flex items-center space-x-[0.3vw]'>
            <div>
             <HoverMenu text="Target for Stage 2">

              <TotalTarget className=' w-[3.5vw] h-[2vw]' />
              
             </HoverMenu>

             
            </div>
            

            <p className='text-[#1BA94C] select-none'>{targetParas}</p>
           
            </div>
            <div>
              
              </div>

            <div className="flex-1 flex justify-center">
          {pieLoading ? (
            <Loading className=' w-[1.8vw] h-[1.8vw] ' />
          ) :( isCreater && <HoverMenu text="Refresh all user's data">

            <Refresh className=' w-[1.8vw] h-[1.8vw] cursor-pointer hover:scale-110 duration-200'  
            
            onClick={() => !pieLoading && fetchCountDetails(false)} />
          </HoverMenu>)}
            </div>
           
          
        </div>
      </div>

      {
        loading ? (
          <div className='h-[65vh] w-full flex items-center justify-center'>
            <Loading className='w-[6vw] h-[6vw]' />
          </div>
        ) :
        paras?.length>0?(
          <div className='mt-[1vw] '>



            <div className={`flex flex-col space-y-[1vw] `}>
              {
                current_paras
                .slice(start,end)
                .map((para: any, index: number) => {
                
                  return <MLCStagePara
                  
                    key={para._id}
                    index={index}
                    para={para}
                    activePara={activePara}
                    next={NextPara}
                    prev={PrevPara}
                    setActivePara={setActivePara}

                    accepted={para.action}

                   
                    stage={1}
                    

                    setNodeCount={setNodeCount}

                    //keyboard toggle
                    keyboardEnabled={keyboardEnabled}
                    setKeyboardEnabled={setKeyboardEnabled}

                    //update state
                    setParas={setParas}
                    setCount={setCount}
                    

                    //fileInfo
                    fileInfo={fileInfo}

                    others ={{
                      initNodeDelta,
                      nodeCount
                    }}
                  />
                })
              }
            </div>
            <div className='py-[1vw] flex justify-end'>
          <Pagination className='bg-white p-[0.6vw] text-[1vw] self-end'
          total={current_paras.length}
          showQuickJumper
          showSizeChanger={false}
          current={page}
          pageSize={pageSize}
          onChange={(page) => {
          !loading && setPage(page)

          }} />
              </div>

          </div>
        ):<div className='flex items-center justify-center
        h-[28vw] text-gray-400 text-[1.5vw]
        capitalize 
        '>
          No more paragraphs available for this class

        </div>
      }

      <div className='flex justify-end
      w-full  border-t py-[1vw]
      '>

      


      {isCreater ?  <div className='flex items-center'>
        <button
          disabled={!canMoveToStage2}
          title={canMoveToStage2?"":message}
          onClick={()=>!stage2Loading && canMoveToStage2 && triggerStage2()}
          className={`uppercase duration-200  w-[13vw]
                text-white text-[0.9vw] 
                 h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
                 disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
                border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md   `}
        >
          {stage2Loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                          : "Move To Stage2"}
        </button>
        <button
          onClick={()=>navigate(`/workflow/stage1-summary?workflowId=${currentWorkflow?.workflowId}`)}
          className={`uppercase duration-200  w-[13vw]
                text-white text-[0.9vw] 
                 h-[3vw] ml-[0.7vw] text-center rounded-md border-2 hover:scale-105 
                 disabled:opacity-75 disabled:cursor-not-allowed
                border-transparent my-[0.3vw] bg-primaryBlue hover:drop-shadow-md   `}
        >Validation Summary
        </button>
        
        </div>
        :<div></div>}


       
      </div>

    </div>
  )
}

export default Index