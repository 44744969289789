import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ReactComponent as LoadingCircle } from '../../assets/loading_circle.svg';
import Axios from '../../utils/Axios'
import { SunburstChart } from "./SunburstChart";
import useAuthStore from "../../store/authStore";
import UsageTable from './UsageTable';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface TableType {
  head1: string,
  head2: string,
  body: Array<any>
}

interface UsageProps { }
const Usage: React.FC<UsageProps> = () => {
  const [usageInMb, setUsageInMb] = useState<any>([]);
  const [usageMessage, setUsageMessage] = useState<string>("");
  const [workflowStats, setWorkflowStats] = useState<any>(null);
  const [chartData, setChartData] = useState<any>();
  let authStore = useAuthStore();
  let usage: any = authStore.usage;
  let activePlan: any = authStore.activePlan;
  const [usageData, setUsageData] = useState<TableType>(); 
  const [configData, setConfigData] = useState<TableType>(); 

  useEffect(() => {
    let fetchStats = async () => {
      try {
        const response = await Axios.get("/user/workflowStats");
        // console.log("Response", response.data.data);
        setWorkflowStats(response.data.data)
      }
      catch (err) {

      }
    }
    fetchStats();
  }, [])


  useEffect(() => {
    let fetchDataUsage = async () => {
      try {
        if (usage) {
          
          let dataUsage = Math.round(usage?.details?.secondaryMemory * 1024);
          let allowedUsage = Math.round(activePlan?.details?.secondaryMemory * 1024);
          setUsageInMb([dataUsage, allowedUsage]);
          
          if (allowedUsage >= 100) {
            if (dataUsage >= 1024) {
              setUsageMessage(`${(dataUsage / 1024).toFixed(2)} GB / ${allowedUsage / 1024} GB`);
            } else {
              setUsageMessage(`${(dataUsage)} MB / ${allowedUsage / 1024} GB`);
            }
          } else {
            if (dataUsage >= 1024) {
              setUsageMessage(`${(dataUsage / 1024).toFixed(2)} GB / ${allowedUsage} GB`);
            } else {
              setUsageMessage(`${(dataUsage)} MB / ${allowedUsage} MB`);
            }
          }

          let usageDetails = {
            head1: "Plan Details",
            head2: "Specification",
            body: [{
              key: "Number of Projects",
              value: `${usage.details.noOfProjects} / ${activePlan.details.noOfProjects}`
            }, {
              key: "Number of Users",
              value: `${usage.details.noOfUsers} / ${activePlan.details.noOfUsers}`
            }, {
              key: "Number of Workflows",
              value: `${usage.details.noOfWorkflows} / ${activePlan.details.noOfWorkflows}`
            }, {
              key: "Number of Prediction Calls",
              value: `${activePlan.details.noOfPredictionCalls - usage.details.noOfPredictionCalls} Left For This Plan`
            }, {
              key: "Number of Classes",
              value: `${activePlan.details.noOfClasses} Classes Per Project`
            }]
          }

          let configDetails = {
            head1: "Configuration Details",
            head2: "Specification",
            body: [{
              key: "Instance Name",
              value: activePlan.details.instance.instanceName
            }, {
              key: "Cores",
              value: activePlan.details.instance.cores
            }, {
              key: "RAM",
              value: `${activePlan.details.instance.ram} GB`
            }, {
              key: "Storage",
              value: `${activePlan.details.instance.storage} GB`
            }, {
              key: "Database",
              value: `${activePlan.details.instance.database} GB`
            }]
          }

          setUsageData(usageDetails);
          setConfigData(configDetails);
        }
        
      }
      catch (err) {
        console.log(err);
      }
    }
    fetchDataUsage();
  }, [usage])

  useEffect(() => {
    let fetchSunburstData = async () => {
      try {
        const response = await Axios.get("/user/sunburst");
        console.log("Response", response.data.data);

        let data = {
          name: "Sunburst Chart",
          children: response.data.data.map((element: any) => {
            console.log("element", element)
            if (element.workflowDetails.length > 0) {
              return {
                name: element.name,
                children: element.workflowDetails.map((workflowElement: any) => {
                  return {
                    name: workflowElement.workflowName,
                    children: [{ name: `${workflowElement.nodeCount} Modal Nodes`, value: workflowElement.nodeCount }]
                  }
                })
              }
            }
            else {
              return {
                name: element.name,
                children: []
              }
            }
          })
        }
        console.log(data)
        setChartData(data);
      }
      catch (err) {
        console.log(err)
      }

    }
    fetchSunburstData();
  }, [])

  return (
    <div className="flex justify-between gap-[1.5vw]">
      <div
        className="w-[50%] bg-white p-[1vw]"
        style={{
          boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
          minHeight: "40vw"
        }}
      >
        <p
          style={{
            fontWeight: 500,
            fontSize: "1.1vw",
            lineHeight: "18px",
            color: "#1BA94C",
            marginBottom: "0.5vw"
          }}
        >
          Masterlist Annotations
        </p>
        <div
          className="p-[1vw] flex justify-center items-center h-full w-full"
        >
          {
            chartData ? (chartData.children.length > 0 ?
              <SunburstChart
                data={chartData}
              />

              :
              <div className="text-gray-400  text-[1.2vw]">
                No Project Found
              </div>
            )
              :


                <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />


          }
        </div>
      </div>
      <div className="flex flex-col gap-[1.5vw] w-[50%]">
        <div
          className="bg-white p-[1vw] h-1/2 w-full"
          style={{
            boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
          }}
        >
          <p style={{ fontSize: "1.1vw", fontWeight: 500, color: "#1BA94C", marginBottom: "1vw" }}>
            Data Usage
          </p>
          <div className="flex ">
            { usageData && <UsageTable table={usageData} /> }

            <div className="flex justify-center items-center w-[35%]">
              <div className="w-[12vw]">
                {usageInMb.length ? (
                  <CircularProgressbar 
                  value={usageInMb[0]} 
                  maxValue={usageInMb[1]} text={usageMessage} 
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textSize: "10px",
                    textColor: "#11256D",
                    pathColor: "#F59F00",
                    trailColor: "#F1F1F1"
                  })}/>
                  // <ReactApexChart
                  //   type="donut"
                  //   height={200}
                  //   series={usageInMb}
                  //   options={options1}
                  // />
                ) : (
                    <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />
                )}
              </div>
            </div>
          </div>

        </div>
        <div
          className="bg-white p-[1vw] h-1/2 w-full"
          style={{
            boxShadow: "0px 0px 8px rgba(122, 122, 122, 0.15)",
          }}
        >
          <p style={{ fontSize: "1.1vw", fontWeight: 500, color: "#1BA94C", marginBottom: "1vw" }}>
            Instance Configuration Details
          </p>
          { configData && <UsageTable table={configData} /> }
          {/* <div className="flex justify-center h-full items-center">
            {workflowStats ? (
              workflowStats.total > 0 ?
                <ReactApexChart
                  type="pie"
                  height={210}
                  series={[workflowStats?.total, workflowStats?.trained]}
                  options={
                    {
                      dataLabels: { enabled: false },
                      chart: {
                        width: 380,
                        type: 'pie',
                        toolbar: {
                          show: false
                        },

                      },
                      legend: {
                        show: false
                      },
                      stroke: {
                        show: false
                      },
                      labels: ["Total Workflows", "Deployed Workflows"],
                      colors: ["#F59F00", "#F50072"],

                      responsive: [
                        {
                          breakpoint: 2555,
                          options: {
                            chart: {
                              height: 320,
                            },
                            plotOptions: {
                              pie: {
                                donut: {
                                  labels: {
                                    value: {
                                      fontSize: "24px",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        {
                          breakpoint: 2159,
                          options: {
                            chart: {
                              height: 300,
                            },
                            plotOptions: {
                              pie: {
                                donut: {
                                  labels: {
                                    value: {
                                      fontSize: "22px",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        {
                          breakpoint: 1920,
                          options: {
                            chart: {
                              height: 220,
                            },
                            plotOptions: {
                              pie: {
                                donut: {
                                  labels: {
                                    value: {
                                      fontSize: "18px",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        {
                          breakpoint: 1280,

                          options: {
                            chart: {
                              height: 200,
                            },
                            plotOptions: {
                              pie: {
                                donut: {
                                  labels: {
                                    value: {
                                      fontSize: "16px",
                                    },
                                  },
                                },
                              },
                            },
                          },

                        },
                      ],
                    }}
                />
                :
                <div className="text-gray-400 text-[1.2vw]">
                  No Project Found
                </div>
            ) : (
                <LoadingCircle height="12vw" width="12vw" className="animate animate-spin" />
            )}
          </div> */}
        </div> 
      </div>
    </div>
  );
};

export default Usage;
