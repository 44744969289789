import React, { useEffect, useState } from 'react';
//import styles from "./accuracy.module.css";
import useWorkflow from "../../../store/useWorkflowStore";
import { Popover, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import axios from '../../../utils/Axios';
import {AiFillCaretDown} from 'react-icons/ai';
import produce from 'immer';
import useEntityStore from '../../../store/entityStore';

import LoadingScreen from '../../loading-screen';
import { removeFromAnnotation } from '../masterlist/_apiCalls';



export default function NERAccuracy() {

    let [selected, setSelected] = useState<Array<string>>([]);
    let [canDeselect, setCanDeselect] = useState<boolean>();
    let [loading,setLoading] = useState<boolean>(false);

    

    const entities = useEntityStore(state=>state.entities);
    const setEntities = useEntityStore(state=>state.setEntities);
   


    
    let workflow = useWorkflow(state => state.currentWorkflow);

    
    let currentBatch = workflow?.alpdsInfo?.percentageStatus?.nerStage1Progress?.currentBatchNumber || 0;
    const key = "batch"+currentBatch;
    
    let classificationReport = workflow && workflow?.alpdsInfo?.stage2ClassificationReports;

    //detailedReport =classificationReport &&  (key ? (classificationReport[key] || {})  :  ( classificationReport[`batch${currentBatch}`] || {}));
    const dummyReport:any = {}
    entities.forEach(ent=>{
        dummyReport[ent.entityId]={
            validatedParas : 20,
            accuracy:0.85,
            color:"green",

        }
    });
    let detailedReport=classificationReport && key ?  classificationReport[key]:undefined;
    const stage2AccuracyProgress= workflow?.alpdsInfo?.percentageStatus?.stage2AccuracyProgress || null;

    let DeselectForClassfication = async () => {
        if (!selected.length) return;

        if(!canDeselect)
        {
            toast("You Need Atleast 1 Entity For Model Training",{type:"warning"});
            return;

        } 

        let new_entities = entities.map((ent)=>{
            if(ent.entityId === selected.find(id=>ent.entityId == id))
            {
                ent.status =false;
            }
            return ent;
        })
        setEntities(new_entities);
        setSelected([]);
        setLoading(true);
        await removeFromAnnotation(workflow?.workflowId || ""
            ,selected,);
        setLoading(false);


       // await new Promise(resolve=>setTimeout(resolve,1000))
       




    }

    let canDeselectForClassification = () => {
        if (!selected.length) return false;

        let valid_entities = entities.filter(ent => ent.status);

        if (valid_entities.length - selected.length >= 1) {
            return true;
        }
        else {
         
            return false;
        }
    }

    useEffect(() => {

        setCanDeselect(canDeselectForClassification());


    }, [selected]);

   
  

    let getNodeColor = (color: string) => {

        if (color === "red") return "!text-[#E63946]";

        if (color === "yellow") return "!text-[#F59F00]";

        if (color === "green") return "!text-[#1BA94C]";

    }

    // let getAncestors = (node_id: string): Array<string> => {
    //     // console.log("Inside Ancestors");
    //     let nodeInfo = nodes.find(n => n.node_id === node_id);
    //     let ancestors: Array<string> = [];

    //     if (nodeInfo?.node_level === 0) return [nodeInfo.node_name];

    //    // let temp_parent:string;
    //     let current_node = nodeInfo;
    //     for (let i: number = nodeInfo?.node_level || 0; i >= 0; i--) {

    //         ancestors.push(current_node?.node_name || "");
    //         let temp_parent = current_node?.parent_id || "";
    //         let parentInfo = nodes.find(n => n._id === temp_parent);
    //         //  ancestors.push(parentInfo?.node_name);
    //         current_node = parentInfo;
    //     }
    //     ancestors.reverse();
    //     // console.log("=== ancestors ===",ancestors);
    //     return ancestors;




    // }

    // const RefreshAccuracyReports =async()=>{
    //     setLoading(true);

    //     const currentBatchNumber:number = workflow?.alpdsInfo.percentageStatus.stage2Progress.currentBatchNumber || 0;

    //     if(!currentBatchNumber)
    //     {
    //         toast("Batch number information is not present in current workflow",{type:"warning"});
    //         setLoading(false)
    //         return;
    //     }
       
    //     let response =await axios.post("/integration/refreshAccuractReport",{
    //         workflowId:workflow?.workflowId,
    //         batchNumber:currentBatch
    //     });

    //     setLoading(false);


    // }

    // const Content = (ancestors: Array<string>) => {

    //     if(!nodes) return;
    //     return (
    //         <div className='max-w-[30vw] text-center overflow-x-'>
    //             {
    //                 ancestors.map((a, i) => {
    //                     return <span key={i} className='!w-[5vw] text-ellipsis text-[#7a7a7a] text-[1vw]'>{a}{i !== ancestors.length - 1 && " > "}</span>
    //                 })
    //             }
    //         </div>
    //     )

    // }

    const TData =(entityId:string)=>{
       
        
        if(!entities || !entities.length) return ;
        let entity= entities.find(ent=>ent.entityId === entityId);


        if(!entity) return (
            <span>
                <p className='text-center'>Entity not found</p>
            </span>
        )
   

        return entity && ( <td className={`text-center1   ${entity.status?getNodeColor(detailedReport[entityId].color) :"text-[#777777]"}`}>
        {
     
                    // <Popover content={Content(getAncestors(node_id))}>
                    // </Popover>
                        <span className={`text-[1.1vw]  text-ellipsis ${!entity.status ? "opacity-50" : ""}`}>
                            {`${entity &&  entity.entity.length>25?entity.entity.substring(0,22)+"...":entity.entity}`}

                        </span>
            
            
        }
    </td>);
    }

    const Table = ()=>{
        if(!detailedReport) 
        return <div className='flex justify-center text-[1.4vw] h-[20vw] flex items-center'>
            Accuracy report is not available for this batch.
            </div>
        return (
            <table className={`styles.details_table text-[1vw] table-auto w-full`}>
            <thead>

            <tr className=' bg-[#f3f3f3] h-[5vw]'>

                <th className={"text-center  text-[#999999]  "}></th>
                <th className={"text-left text-[#999999]"}>Entities</th>
                <th className={"text-left text-[#999999] "}>Validated Paragraphs</th>
                <th className={"text-left text-[#999999] "}>Validated Instances</th>
                <th className={"text-left text-[#999999] mr-[3vw] "}>Support Paragraphs</th>
                <th className={"text-left text-[#999999] "}>Support Instances</th>
                <th className={"text-left text-[#999999] mr-[3vw] "}>Expected F1 Score</th>

            </tr>
            </thead>
            <tbody>

            {
               detailedReport && Object.keys(detailedReport)
               .sort((k1:string,k2:string)=>detailedReport[k1]?.accuracy>detailedReport[k2].accuracy?-1:1)
               .map((entityId: string) => {

                    //getAncestors(node_id) 
                    return (entityId !== "timestamp" && entityId!== "lastUpdatedTimestamp") &&
                        (<tr key={entityId} className="!border-b !border-b-[#c4c4c4] h-[5vw] hover:bg-gray-100 ">
                        <td className='text-center  !pr-0 !w-[3vw]  '>
                            <input type="checkbox"
                            
                                checked={selected.find(n => n === entityId)!== undefined}
                                 disabled = {!entities.find(ent=>ent.entityId === entityId)?.status || loading}
                               
                                onChange={(e) => {
                                    //console.log("=== event  ===",e.target.checked);
                                    if (e.target.checked) {
                                        setSelected([...selected, entityId]);
                                    } else {
                                        setSelected(
                                            selected.filter(
                                                (data) => data !== entityId
                                            )
                                        );
                                    }

                                }}
                                className='!w-[1.3vw] h-[1.3vw] hover:cursor-pointer disabled:cursor-not-allowed'
                                 />             
                            </td>
                            {TData(entityId)}

                          
                            <td className={"text-center1 "}>
                                {detailedReport[entityId]["training paras"] || 0}
                            </td>
                            <td className={"text-center1 "}>

                                {detailedReport[entityId]["training instances"] || 0}
                            </td>
                            <td className={"text-left "}>{detailedReport[entityId]["support"] || 0}</td>
                            <td className={"text-left mr-[3vw] "}>{
                                detailedReport[entityId]["support instances"]>0?
                                detailedReport[entityId]["support instances"]:0 || 0}</td>
                            <td className={"text-center1 "}>
                                {
                                 detailedReport[entityId]["f1-score"]>0?   
                                detailedReport[entityId]["f1-score"].toFixed(2):0 || 0}
                            </td>

                        </tr>)

                })
            }
            
            </tbody>




        </table>

        )
    }




    return (
        <div className=''>
         {/* { stage2AccuracyProgress===100?   <div className=' flex justify-end'>
                <Popover content="Refresh Accuracy">

                    <div
                        style={{
                            alignSelf: "center",
                            justifySelf: "flex-end"
                        }}
                        className="duration-700 rounded  p-2 cursor-pointer flex items-center -mr-2 border-1 hover:scale-105"
                        onClick={() => RefreshAccuracyReports()}
                    >
                        <img src="/images/reload.svg" className="w-[3vw]" alt="" />
                    </div>
                </Popover>
            </div>:null} */}
              
            {
            false? <LoadingScreen  msg='Recalculating Accuracy Report' 
            compleated={stage2AccuracyProgress || 0}/>: <Table />
            }
            


            


            { detailedReport ? <div className='flex justify-end items-center h-[5vw] '>


                <button
                    title={!canDeselect && selected.length ? "You Need Atleast 1 Entity For Model Training":""}
                    disabled={!selected.length}
                    onClick={DeselectForClassfication}
                    className={`${!canDeselect|| loading?"bg-[#777777] text-white opacity-60":""} w-[20vw] text-center h-[3vw] bg-[#E63946] rounded font-semibold text-white hover:scale-[105%] text-[1vw] duration-700`}>
                    {loading? <img src="/images/loading_white.svg" alt="" className='w-[2vw] mx-auto'  />: "Deselect for Classification"}
                </button>


            </div>:null}
        </div>
    )
}
