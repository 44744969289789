//import all pages here


//dashboard cards
import StageCard from './components/workflow-cards/stagecard/StageCard';
import AW_StageCard from './components/workflow-cards/stagecard/AW_StageCard';
import MasterListCard from './components/workflow-cards/masterListCard';
import TrainCard from './components/workflow-cards/trainCard';
import NERTrainCard from './components/ner-components/cards/NERTrainCard';



//multiclass
import Listview from './components/listview';
import Masterlist from './components/masterlist';
import Stage1 from './components/stage-1';
import Stage2 from './components/stage-2';
import Stage1Summary from './components/validation-summary/Stage1Summary';
import Stage2Summary from './components/validation-summary/Stage2Summary';
import ValidationSummary from './components/validation-summary/ValidationSummary';
import TrainingParagraphs from './components/validation-summary/TrainingParagraphs';
import UserStatisticsComponent from './components/validation-summary/UserStatisticsComponent';
import Deploy1 from './components/workflow/model-training-2/deployment/deploy1';
import Deploy2 from './components/workflow/model-training-2/deployment/deploy2';
import Deploy3 from './components/workflow/model-training-2/deployment/deploy3';
import APIDocumentation from './components/workflow/model-training-2/deployment/deploy4';


//multilabel
import MLCListview from './components/mlc-components/Listview';
import MLCStage1 from './components/mlc-components/Stage1';
import MLCStage2 from './components/mlc-components/Stage2';
import MLCTrainingParagraphs from './components/mlc-components/mlc-summary/MLCTrainingParas';
import MLCStage1Summary from './components/mlc-components/mlc-summary/MLCStage1Summary';
import MLCStage2Summary from './components/mlc-components/mlc-summary/MLCStage2Summary';
import MLCValidationSummary from './components/mlc-components/mlc-summary/MLCValidationSummary';
import MLCUserStatisticsComponent from './components/mlc-components/mlc-summary/MLCUserStatistics';
import MLCProjectSummary from './components/mlc-components/mlc-summary/tabs/MLCProjectSummary';
import MLCDeploy3 from './components/mlc-components/deployment/MLCDeploy3';
import MLCAPIDocumentation from './components/mlc-components/deployment/MLCAPIDocumentation';
import MLCSummaryTab from './components/mlc-components/masterlist/MLCSummaryTab';
import EntityComp from './components/ner-components/masterlist/EntityComp';

// ner 
import NERListview from './components/ner-components/validation';
import NERModelTraing1 from './components/ner-components/train/ner-modal-training-1';

//training 
import ModelTraining1 from './components/workflow/modal-training-1';
import ModelTraining2 from './components/workflow/model-training-2/model_training2';
import LLMHyperparamerterTuning from './components/llm-components/model-comparison/LLMHyperparamerterTuning';
import NERMasterListCard from './components/ner-components/cards/NERMasterlistCard';
import NERDeploy2 from './components/workflow/model-training-2/model_training2/deploy/NERdeploy2';
import NERValidationSummary from './components/ner-components/summary/NERValidationSummary';
import NERAPIDocumentation from './components/ner-components/train/NERAPIDocumentation';

import MLCModelComparison from './components/mlc-components/model-comparison/MLCModelComparison';
import MLCHyperparamerterTuning from './components/mlc-components/model-comparison/MLCHyperparamerterTuning';
import MLCTrainCard from './components/mlc-components/cards/MLCTrainCard';
import DeployCard from './components/workflow-cards/deployCard';
import NERDeployCard from './components/ner-components/cards/NERDeployCard';

import NERModelTraining2 from './components/ner-components/train/ner-modal-training-2/NERModelTraining2';
import ModelComparison from './components/Training/ModelComparision';

//Upload
import Upload from './components/UploadComp';
import LLMUploadComp from './components/llm-customization/shared/LLMUploadComp';

//RLHF
import RLHFRValidation from './components/llm-customization/rlhf-ranking-components/validation';
import Ft_ModelTraining1 from './components/llm-customization/rlhf-fine-tuning/ft-modal-training-1'


import RLHFFineTuneValidation from './components/llm-customization/rlhf-fine-tuning/validation';
import RLHFOneVsAllValidation from './components/llm-customization/rlhf-onvsall-components/validation';
import PromptCreateProjectControls from './components/llm-customization/createProjectControls';

//Icons
import {ReactComponent as LangIcon} from './assets/lang_icon.svg';
import {ReactComponent as TranslationIcon } from './assets/translation.svg';
import {ReactComponent as SummarizationIcon} from './assets/summarization.svg';
import {ReactComponent as MultiClassIcon } from './assets/multiclass.svg';
import {ReactComponent as MultiLabelIcon } from './assets/multilabel.svg';
import {ReactComponent as NerIcon } from './assets/ner.svg';
import LLMIcon from './assets/LLMIcon';

//LLM
import LLM_StageCard from './components/workflow-cards/stagecard/LLM_StageCard';
import LLM_ExportCard from './components/llm-customization/rlhf-ranking-components/cards/LLMExportCard';
import FtTrainCard from './components/workflow-cards/FtTrainCard';
import FtHyperparamerterTuning from './components/llm-customization/model-comparison/FtHyperparamerterTuning';
import LoadingScreen from './components/loading-screen';
import LLMDeploy from './components/llm-customization/rlhf-fine-tuning/training/LLMDeploy';
import FtDeploy1 from './components/llm-customization/shared/llm-deployment/Ftdeploy1';
import FtDeploy2 from './components/llm-customization/shared/llm-deployment/Ftdeploy2';
import FtDeploy3 from './components/llm-customization/shared/llm-deployment/Ftdeploy3';
import FtAPIDocumentation from './components/llm-customization/shared/llm-deployment/Ftdeploy4';



//define template
const template:any={
   multiclass_classification: {
    hasMasterlist:true,
    hasAMasterlist:true,
    hasNoTag:true,
    hasForking:true,
    allowMultiUser:true,
    hasLLM:true,
    modelType:"MultiClass",
    dockerEndpoint:"Multiclass_LLM",

    icon:{
        comp:MultiClassIcon,
        styles:"w-[4.2vw] h-[3vw]"
    },
    name:"MultiClass Classification",
    description:"One class label may be predicted for each example.",
    workflows:[
        {
            id:"annotation_workflow",
            text:"Annotation Workflow"
        },
        {
            id:"annotation_prediction_workflow",
            text:"Complete Workflow"
        }
    ],
    
    
     annotation_workflow:{

        workflowDashboard:{
            stageCard:AW_StageCard,
            trainCard:TrainCard,
            masterlistCard:MasterListCard

        },



        upload:{
            component:Upload
        },
        masterlist:{
            component:Masterlist,
            summary:{
                summaryTab:MLCSummaryTab
            },
            useNoTag:true,
            endpoints:{
                validation:"",
                initStage1:"aw_stage1"
            }
        },
        validation:{
            component:Listview,
            endpoints:{
                validation:"",
                initStage2:"aw_stage2"
            }
        },
        summary:{
            trainingParas:TrainingParagraphs,
            stage1Summary:Stage1Summary,
            stage2Summary:Stage2Summary,

            //user statistics
            validationSummary:ValidationSummary,
            userStatisticsComp:UserStatisticsComponent,
            showCommonParas:true,

            //project summary
            commonParas:null,
            classWiseValidation:null,
            overlapStats:null,

           


            endpoints:{
                userpiechart:"userpiechart",
                vstats:"vstats",
                useroverlap:"overlapstats",
                stage2_accuracy_refresh:"refreshAccuractReport",
                multiuserpiechart:"multiuserpiechart",
                multiuseroverlap:"multiuseroverlapstats"
            }

        },
        export:{
            endpoints:null
        }



     },
     annotation_prediction_workflow:{

        workflowDashboard:{
            stageCard:StageCard,
            trainCard:TrainCard,
            masterlistCard:MasterListCard,
            deployCard:DeployCard
        },


        upload:{
            component:Upload
        },
        masterlist:{
            component:Masterlist,
            summary:{
                summaryTab:MLCSummaryTab
            },
            useNoTag:true,
            endpoints:{
                initStage1:"stage1"
            }
        },
        stage1:{
            component:Stage1,
            endpoints:{
                validation:"",
                initStage2:"stage2_init"
            }
        },
        stage2:{
            component:Stage2,
            endpoints:{
                validation:"",
                
            }
        },
        summary:{
            trainingParas:TrainingParagraphs,
            stage1Summary:Stage1Summary,
            stage2Summary:Stage2Summary,
            validationSummary:ValidationSummary,
            userStatisticsComp:UserStatisticsComponent,
            showCommonParas:true,
            endpoints:{
                userpiechart:"userpiechart",
                vstats:"vstats",
                useroverlap:"overlapstats",
                stage2_accuracy_refresh:"refreshAccuractReport",

                multiuserpiechart:"multiuserpiechart",
                multiuseroverlap:"multiuseroverlapstats"
            }

        },
        export:{
            endpoints:null
        },
        modelComparison:{
            component:ModelComparison
        },
        modelTuning:{
            component:MLCHyperparamerterTuning,
            automlEndpoint:"automl"
            
        },
        llmModelTuning:{
            component:LLMHyperparamerterTuning,
            automlEndpoint:"automl"
            
        },
        training:{
             //training
             modelTraining1:{
                component:ModelTraining1
             },
             modelTraining2:{
                component:ModelTraining2
             },
        },


        deploy:{
            deploy1:{
                component:Deploy1,
                active:true
            },
            deploy2:{
                component:Deploy2,
                active:true
            },
            deploy3:{
                component:Deploy3,
                active:true
            },
            apiDocumentation:{
                component:APIDocumentation,
                active:true
            }
        
        
    
        }
    



     }
   },
   multilabel_classification: {
    allowMultiUser:true,
    hasMasterlist:true,
    hasAMasterlist:true,
    hasNoTag:true,
    hasForking:true,
    hasLLM:true,
    modelType:"MultiLabel",
    dockerEndpoint:"Multilabel_LLM",

    icon:{
        comp:MultiLabelIcon,
        styles:"w-[4vw] h-[3vw]"
    },
    name:"MultiLabel Classification",
    description:"One or more class labels may be predicted for each example.",
    workflows:[
       
        {
            id:"annotation_prediction_workflow",
            text:"Complete Workflow"
        }
    ],

    
    annotation_workflow:{

        workflowDashboard:{
            stageCard:AW_StageCard,

            masterlistCard:MasterListCard,
            trainCard:TrainCard

        },

       upload:{
           component:Upload
       },
       masterlist:{
           component:Masterlist,
           summary:{
            summaryTab:MLCSummaryTab
        },
           useNoTag:true,
           endpoints:{
               validation:"",
               initStage1:"aw_stage1"
           }
       },
       validation:{
           component:MLCListview,
           endpoints:{
            validation:"",
            initStage2:"aw_stage2"
        }
       },
       summary:{

        trainingParas:MLCTrainingParagraphs,
        stage1Summary:MLCStage1Summary,
        stage2Summary:MLCStage2Summary,


        validationSummary:MLCValidationSummary,
        userStatisticsComp:MLCUserStatisticsComponent,
        projectSummary:MLCProjectSummary,
        showCommonParas:false,

         //project summary
         commonParas:null,
         classWiseValidation:null,
         overlapStats:null,

        endpoints:{
            userpiechart:"mlc-userpiechart",
            vstats:"mlc-vstats",
            useroverlap:"overlapstats",

            multiuserpiechart:"multiuserpiechart",
            multiuseroverlap:"multiuseroverlapstats"
        }

    },
       export:{
           endpoints:null
       }



    },
    annotation_prediction_workflow:{

        workflowDashboard:{
            stageCard:StageCard,
            trainCard:MLCTrainCard,
            masterlistCard:MasterListCard,
            deployCard:DeployCard

        },
       upload:{
           component:Upload
       },
       masterlist:{
        component:Masterlist,
        summary:{
            summaryTab:MLCSummaryTab
        },
        useNoTag:true,
        endpoints:{
            initStage1:"stage1"
        }
    },
    stage1:{
        component:MLCStage1,
        endpoints:{
            getParas:"mlc-paras",
            validation:"mlc-para-action",
            initStage2:"stage2_init"
        }
    },
    stage2:{
        component:MLCStage2,
        endpoints:{
            getParas:"mlc-paras",
            validation:"mlc-para-action",
            initBatch:"/stage2_init",
            autoML:"mlc-automl"
        }
    },
    summary:{
        trainingParas:MLCTrainingParagraphs,
        stage1Summary:MLCStage1Summary,
        stage2Summary:MLCStage2Summary,
        validationSummary:MLCValidationSummary,

        
        userStatisticsComp:MLCUserStatisticsComponent,

         //project summary
         projectSummary:MLCProjectSummary,
         showCommonParas:false,


        endpoints:{
            userpiechart:"mlc-userpiechart",
            vstats:"mlc-vstats",
            useroverlap:"overlapstats",

            multiuserpiechart:"multiuserpiechart",
            multiuseroverlap:"multiuseroverlapstats"
        }


    },

    modelComparison:{
        component:MLCModelComparison
    },
    modelTuning:{
        component:MLCHyperparamerterTuning,
        automlEndpoint:"mlc-automl"
    },
    llmModelTuning:{
        component:LLMHyperparamerterTuning,
        automlEndpoint:"mlc-automl"
        
    },
    training:{
        //training
        modelTraining1:{
           component:ModelTraining1
        },
        modelTraining2:{
           component:ModelTraining2
        },
   },

    export:{
           endpoints:null
    },
    deploy:{
        deploy1:{
            component:Deploy1,
            active:true
        },
        deploy2:{
            component:Deploy2,
            active:true
        },
        deploy3:{
            component:MLCDeploy3,
            active:true
        },
        apiDocumentation:{
            component:MLCAPIDocumentation,
            active:true
        }
    
    

    }

    



    },


    modelLinks:{
        "Bert-Based-Uncased":"Multilabel_Bert"
    }
    
  },
  named_entity_recognition: {
    allowMultiUser:false,
    hasEntity:true,
    hasNoTag:true,
    hasForking:false,

    icon:{
        comp:NerIcon,
        styles:"w-[3vw] h-[3vw]"
    },
    name:"Named Entity Recognition(NER)",
    description:"Tasks that involve detecting and categorizing important information in text known as named entities.",
    workflows:[
        {
            id:"annotation_prediction_workflow",
            text:"Complete Workflow"
        }
    ],
  
    annotation_prediction_workflow:{

        workflowDashboard:{
            stageCard:AW_StageCard,
            masterlistCard:NERMasterListCard,
            trainCard:NERTrainCard,
            deployCard:NERDeployCard

        },


       upload:{
           component:Upload
       },
       masterlist:{
        component:EntityComp,
        summary:{
            summaryTab:MLCSummaryTab
        },
        useNoTag:false,
        endpoints:{
            initStage1:"stage1"
        }
    },
    validation:{
        component:NERListview,
        endpoints:{
            getParas:"mlc-paras",
            validation:"mlc-para-action",
            initStage2:"stage2_init"
        }
    },
    

    summary:{
        trainingParas:MLCTrainingParagraphs,
        // stage1Summary:MLCStage1Summary,
        // stage2Summary:MLCStage2Summary,
        validationSummary:NERValidationSummary,

        
        userStatisticsComp:MLCUserStatisticsComponent,

         //project summary
         projectSummary:MLCProjectSummary,
         showCommonParas:false,


        endpoints:{
            userpiechart:"mlc-userpiechart",
            vstats:"mlc-vstats",
            useroverlap:"overlapstats",

            multiuserpiechart:"multiuserpiechart",
            multiuseroverlap:"multiuseroverlapstats"
        }


    },
    training:{
        //training
        modelTraining1:{
           component:NERModelTraing1
        },
        modelTraining2:{
           component:NERModelTraining2
        },
   },

    export:{
           endpoints:null
    },
    deploy:{
        deploy1:{
            component:Deploy1,
            active:true
        },
        deploy2:{
            component:NERDeploy2,
            active:true
        },
        deploy3:{
            component:MLCDeploy3,
            active:true
        },
        apiDocumentation:{
            component:NERAPIDocumentation,
            active:true
        }
    
    

    }


    },
    
  },

  llm_cutomization: {
    hasMasterlist:false,
    hasAMasterlist:false,
    hasNoTag:false,
    hasForking:false,
    allowMultiUser:false,
    hasLLMInputs:true,
    modelType:"Finetune",
    
    createProjectControls:PromptCreateProjectControls,

    llmModels:[
        {
            id:"cohere",
            name:"Cohere"
        },
        {
            id:"openai",
            name:"Open AI"
        }
    ],

    icon:{
        comp:LLMIcon,
        styles:"!w-[2.8vw] !h-[3vw]"
    },
    name:"LLM Customization",
    description:"Fine tune Large Language Models with prompts.",
    workflows:[
        {
            id:"fine_tune_workflow",
            text:"Instruct Fine Tune"
        },
        {
            id:"rlhf_onevsall_workflow",
            text:"Instruct Select"
        },
        {
            id:"rlhf_ranking_workflow",
            text:"Instruct Rank"
        }
    ],
    
    
    rlhf_ranking_workflow:{

        workflowDashboard:{
            stageCard:LLM_StageCard,
            exportCard:LLM_ExportCard
        },



        upload:{
            component:LLMUploadComp
        },
       
        validation:{
            component:RLHFRValidation,
            endpoints:{
                validation:"",
                initStage2:"aw_stage2"
            }
        },
        summary:{
            trainingParas:TrainingParagraphs,
            stage1Summary:Stage1Summary,
            stage2Summary:Stage2Summary,

            //user statistics
            validationSummary:ValidationSummary,
            userStatisticsComp:UserStatisticsComponent,
            showCommonParas:true,

            //project summary
            commonParas:null,
            classWiseValidation:null,
            overlapStats:null,

           


            endpoints:{
                userpiechart:"userpiechart",
                vstats:"vstats",
                useroverlap:"overlapstats",
                stage2_accuracy_refresh:"refreshAccuractReport",
                multiuserpiechart:"multiuserpiechart",
                multiuseroverlap:"multiuseroverlapstats"
            }

        },
        export:{
            endpoints:null
        }



     },
     rlhf_onevsall_workflow:{

        workflowDashboard:{
            stageCard:LLM_StageCard,
            exportCard:LLM_ExportCard
        },



        upload:{
            component:LLMUploadComp
        },
       
        validation:{
            component:RLHFOneVsAllValidation,
            endpoints:{
                validation:"",
                initStage2:"aw_stage2"
            }
        },
        summary:{
            trainingParas:TrainingParagraphs,
            stage1Summary:Stage1Summary,
            stage2Summary:Stage2Summary,

            //user statistics
            validationSummary:ValidationSummary,
            userStatisticsComp:UserStatisticsComponent,
            showCommonParas:true,

            //project summary
            commonParas:null,
            classWiseValidation:null,
            overlapStats:null,

           


            endpoints:{
                userpiechart:"userpiechart",
                vstats:"vstats",
                useroverlap:"overlapstats",
                stage2_accuracy_refresh:"refreshAccuractReport",
                multiuserpiechart:"multiuserpiechart",
                multiuseroverlap:"multiuseroverlapstats"
            }

        },
        export:{
            endpoints:null
        }



     },
     fine_tune_workflow:{

        trainingPageUrl:"llm-model-fine-tune",

        workflowDashboard:{
            stageCard:LLM_StageCard,
            //exportCard:LLM_ExportCard,
            trainCard:FtTrainCard,
            deployCard:null
        },



        upload:{
            component:LLMUploadComp
        },
       
        validation:{
            component:RLHFFineTuneValidation,
            endpoints:{
                validation:"",
                initStage2:"aw_stage2"
            }
        },
        modelComparison:{
            component:MLCModelComparison
        },
        modelTuning:{
            component:FtHyperparamerterTuning,
            automlEndpoint:"mlc-automl"
        },
        llmModelTuning:{
            component:FtHyperparamerterTuning,
            automlEndpoint:"automl"
            
        },
        modelTraining:{
            component:{
                loading: Ft_ModelTraining1,
                deploy: LLMDeploy
            }
        },
        deploy:{
            deploy1:{
                component:FtDeploy1,
                active:true
            },
            deploy2:{
                component:FtDeploy2,
                active:true
            },
            deploy3:{
                component:FtDeploy3,
                active:false
            },
            apiDocumentation:{
                component:FtAPIDocumentation,
                active:false
            }
        },
        export:{
            endpoints:null
        }



     },
    
   },
 


} as const;

export default template;