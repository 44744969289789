import React, { useEffect, useState } from 'react'
import Para from '../../shared/Para'
import useWorkflowStore from '../../../../store/useWorkflowStore';

//Icons
import { ReactComponent as UserAddedIcon } from '../../../../assets/userAddIcon.svg';
import { ReactComponent as TickIcon } from '../../../../assets/Tick.svg';
import MultiSelector from '../MultiSelector';
import ConfidenceScore from '../../shared/ConfidenceScore';



function Validations({ active, setActive, index,
  answers, answer, readOnly,
  validation,
  canDelete,
  rankSet,
  setValidation }: any) {

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [rankOptions, setRankOptions] = useState<any[]>([]);

  const workflow = useWorkflowStore(state => state.currentWorkflow);
  const inputs = workflow?.listviewInfo?.inputConfigs;

  const isAccepted = validation && validation.responseId === answer.responseId;
  const autoValidationScore = answer?.autoValidationScore || null;
  console.log("=== answer ===",answer);

  useEffect(() => {
    //console.log("=== Source from one vs all ===>", answer.source);
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    }
  }, [])



  return (<>
    
  
    <div
      onClick={() => setActive(index)}
      className={`min-h-[11vw]
                      relative
                      
                      border rounded-[0.2vw]
                      bg-white hover:shadow-md
                      p-[1vw] space-x-[1vw]
                      ${answer.source === "user"?"pt-[2vw]":""}
                      duration-500 border
                      ${isMounted ? "" : "-translate-y-[2vw]"}
                      ${active ? " border-primaryBlue" : ""}
                      `}>
    
      {answer.source === "user" && <div className='absolute top-[-0.55vw] left-[-0.2vw] z-[10]'>
        <UserAddedIcon
          className='w-[4.5vw] h-[4.5vw]'
        />
           
     
      </div>}

      {
        <div className='flex justify-end  mb-[0.5vw] px-[0.2vw] '>

          <ConfidenceScore 
          autoValidationScore={autoValidationScore}/>
        </div>
      }

      <div className='flex items-start'>

      <div className='w-[85%]'>
        
        <Para
          active={active}
          text={answer?.text} />
      </div>

      <div className='flex justify-center  w-[15%] my-auto'>
        <div 
        data-testid="save-validation"
        className={`relative border-[0.14vw] 
                        flex justify-center items-center group
                        w-[4.2vw] h-[2.8vw]
                        rounded-md
                        ${isAccepted ?
            "border-[#1BA94C] bg-[#1BA94C] " : ""}
                        ${!isAccepted ?
            readOnly ? "bg-[#C5C3C3] cursor-not-allowed" : "border-[#c7c7c7] hover:border-[#1BA94C] cursor-pointer"
            : ""}
                     
                    `}

          onClick={() => {
            if(readOnly) return;
            if(isAccepted){
              setValidation(null);

            }else{
              setValidation({
                responseId:answer.responseId
                ,rank:null,
                relevance:null
              })
            }
          }}
        >


          <TickIcon
            className={`  duration-200  transition-transform w-[1.3vw] h-[1.3vw] 
      ${isAccepted ? "stroke-white " : " "}
      ${!isAccepted ? readOnly ? "stroke-white " : "stroke-[#c7c7c7] group-hover:scale-125 group-hover:stroke-[#1BA94C]"
                : ""}
       `}


          />
        </div>
      </div>
      </div>




    </div>
  </>




  )
}

export default Validations