import { RecoilRoot } from "recoil";
import RoutingComp from "./components/RoutingComp";

import { Provider } from 'react-redux';
import store from './store/reduxStore';
import { SocketContext, socket } from './contexts/socket';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist'


function App() {

    const persistor = persistStore(store)
    return (
        <RecoilRoot>
            <SocketContext.Provider value={socket}>
                <Provider store={store}>
                    <RoutingComp />
                </Provider>
            </SocketContext.Provider>
        </RecoilRoot>
    );
}

export default App;
