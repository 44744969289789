
import { Divider } from "antd";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import useWorkflowStore from "../../../../store/useWorkflowStore";
import Axios from '../../../../utils/Axios';
import { saveAs } from 'file-saver'
import useAuthStore from "../../../../store/authStore";



export interface Deploy2Props { }

const FtDeploy2: React.FC<Deploy2Props> = () => {
  const [stat, setStat] = useState({
    count: 0,
    download: 0,
  });
  let { currentWorkflow } = useWorkflowStore();
  let token = useAuthStore(state => state.token);
  let [masterlistId, setMasterlistId] = useState<string>("");
  let [trainingId, setTrainingId] = useState<string>("");
  let [predictionId, setPredictionId] = useState<string>("");
  let [selectedCount, setSelectedCount] = useState<number>(0);
  let [count, setCount] = useState<number>(0);
  let [isLoading, setIsLoading] = useState<boolean>();



  let [requiredExports, setRequiredExports] = React.useState({
    model: false,
    training: false,
    prediction: false,
  });

  // let io = React.useContext(SocketContext);

  const [disabled, setDisabled] = React.useState(false);


  useEffect(() => {
    console.log("count", count);
    console.log("selected count", selectedCount);
    if (count === selectedCount) {
      setCount(0);
      setSelectedCount(0);
      setIsLoading(false);
    }

  }, [count]);


  let [masterlistloading, setMasterlistLoading] = React.useState(false);
  let [trainingloading, setTrainingLoading] = React.useState(false);
  let [predictloading, setPredictLoading] = React.useState(false);

  //for masterlist
  useEffect(() => {
    let masterListProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.masterlistExportProgress;
    //console.log("==Progrss M ==",masterListProgress);
    if (masterlistId !== "" && masterListProgress === 100) {
      //  console.log("=== Init MasterList Data ===")
      initDownload(masterlistId, token || "", setMasterlistId);

    }

  }, [currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.masterlistExportProgress]);

  //for training data

  //For Training Progress
  useEffect(() => {
    let trainingProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.trainDataExportProgress;

    // console.log("==Progrss T ==",trainingProgress);
    // console.log("===training ID===",trainingId);
    if (trainingId !== "" && trainingProgress === 100) {
      //console.log("=== Init Train Data ===")
      initDownload(trainingId, token || "", setTrainingId);
    }

  }, [currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.trainDataExportProgress]);


  //for prediction data
  useEffect(() => {
    let predictionProgress = currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.predictedDataExportProgress;

    // console.log("==Progrss P ==",predictionProgress);
    if (predictionId !== "" && predictionProgress === 100) {
      // console.log("=== Init Prediction Data ===")
      initDownload(predictionId, token || "", setPredictionId);
    }
    

  }, [currentWorkflow?.alpdsInfo?.percentageStatus?.exportData?.predictedDataExportProgress]);


  // Intialize Downloads
  let generateTaskIds = async () => {
    setIsLoading(true);

    toast("Your Datasets will start downloading shortly.", { type: toast.TYPE.SUCCESS });

    // console.log("=== options ===",requiredExports);
    //For Training
    if (requiredExports.training) {

      const trainingTaskId = await Axios.post("/integration/export_training_data", {
        workflowId: currentWorkflow?.workflowId,
        activeToken: token
      });
      //Increment Selected Count by 1
      setSelectedCount((prev) => prev + 1);

      setTrainingId(trainingTaskId.data.data);
      // console.log("=== training task id ===", trainingId);
    }


    //For Model
    if (requiredExports.model) {

     //get model url
     //const modelUrl = currentWorkflow?.alpdsInfo.selectedModelInfo?.modelUrl;
     try{
       const res = await Axios.post("/llm/get-model-url",{
        modelName:"Llama_FT",
        workflowId:currentWorkflow?.workflowId
       });
       const modelUrl = res.data.data.url;
       console.log("data received from model url",modelUrl);
       if(modelUrl){
         setSelectedCount((prev) => prev + 1);
         setCount(prev=>prev+1);
        saveAs(modelUrl)
       }
     }catch(err:any){

     } 
     
    }

    //For Prediction
    if (requiredExports.prediction) {
      const predictionTaskId = await Axios.post("/integration/export_predicted_data", {
        workflowId: currentWorkflow?.workflowId,
        activeToken: token
      });

      //Increment Selected Count by 1
      setSelectedCount((prev) => prev + 1);
      setPredictionId(predictionTaskId.data.data);
      //  console.log("=== prediction task id ===", masterlistId);
    }
  }

  //Save Files To Local Storage
  const initDownload = async (taskId: any, token: string, setState: any) => {

    const tData = await Axios.post("/integration/download_data", {
      taskId: taskId,
      activeToken: token,
      workflowId: currentWorkflow?.workflowId

    });
    setState("");

    //console.log("===Task Data ===",tData.data.data);
    // download the file

    if (tData.data.data.url) {
      //Increment Count by 1
      setCount((prev) => prev + 1);
      console.log("=== Incremented Count ===", count);
      saveAs(tData.data.data.url);
    }
    //  saveFile(tData.data.data.url);

    return;

  }








  return (
    <div className="my-[0.2vw] mx-[0.5vw] w-full">
      <div className="bg-white rounded-md w-full p-[1vw]">
        <p className="font-sans text-[1.3vw]" style={{ color: "#2C2C2C" }}>
          Information about exporting the Dataset
        </p>
        <Divider />
        <p className={"font-thin mb-[0.5vw] text-[1.1vw] w-11/12"}>
          Download the data by selecting the checkboxes below and then click on
          "EXPORT DATASET" button. The Download process will initiate
          automatically. Data will be downloaded in .xlsx format.
        </p>
        <p className="font-sans text-[1.1vw] ">
          Select which dataset you wish to export
        </p>
        <div className="my-[0.7vw]">
          <div className="flex items-center mt-[0.8vw]">
            <input
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  model: !requiredExports.model,
                })
              }
              type="checkbox"
              className="rounded-[0.3vw] mr-[1vw] w-[1vw] h-[1vw]"

            />
            <p className="font-sans text-[1.1vw] mt-[0.2vw]">
              Adaptive Layer</p>
          </div>
          <div className="flex items-center mt-3">
            <input
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  training: !requiredExports.training,
                })
              }
              type="checkbox"
              className="rounded-[0.3vw] mr-[1vw] w-[1vw] h-[1vw]"

            />
            <p className="font-sans text-[1.1vw] mt-[0.2vw]">

              Training Dataset</p>
          </div>
          <div className="flex items-center mt-[0.8vw]">
            <input
              disabled={
                currentWorkflow?.alpdsInfo?.percentageStatus
                  ?.predictionOnDatabaseProgress != 100
              }
              onChange={(e) =>
                setRequiredExports({
                  ...requiredExports,
                  prediction: !requiredExports.prediction,
                })
              }
              type="checkbox"
              className="rounded-[0.3vw] mr-[1vw] w-[1vw] h-[1vw]"
            />

            <p className="font-sans text-[1.1vw] mt-[0.2vw]">
              Prediction Results on Ingested Data
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end my-10">
        {isLoading ? (
          <div className="px-4 rounded-md py-3 border-primarycolor bg-white border-2">
            <img className="w-24 h-6" src="/images/loading_blue.svg" alt="loader" />
          </div>
        ) : (
          <button
            onClick={() => {
              if(
                requiredExports.model === false &&
                requiredExports.training === false &&
                requiredExports.prediction === false
              ) return;

              if (
              
                !isLoading
              ) {

                generateTaskIds();
                //initDownloadData();
              } else {
                toast("Please select any one export option.", {
                  type: "warning",
                });
              }
            }}
            title={
              requiredExports.model === true ||
                requiredExports.training === true ||
                requiredExports.prediction === true
                ? ""
                : "Please select any one export option."
            }
            className={`${requiredExports.model === true ||
              requiredExports.training === true ||
              requiredExports.prediction === true
              ? "bg-primarycolor hover:scale-105 duration-200"
              : "bg-gray-400 cursor-not-allowed"
              } px-[1.5vw] py-[0.5vw] text-white font-sans text-[1.1vw] rounded-[0.3vw]`}
          >
            EXPORT DATASET
          </button>
        )}
      </div>
      <div className={"mt-[2vw] w-1/2"}>
        <div className="flex">
          <img src="/images/tip_green.svg" alt="tip" className="mr-[0.5vw] w-[1.5vw]"></img>
          <p className="font-sans text-[1.1vw] text-skin-darkGrey">Tip</p>
        </div>
        <Divider style={{ margin: "10px 0" }} />
        <p
          className="text-gray-500 font-sans text-[1.1vw]"
        >
          In order to download data please enable "Pop Up" through your browser.
          <br />
          Please perform "Prediction on Ingested Data" before exporting
          "Prediction Results on Ingested Data".
        </p>
      </div>
    </div>
  );
};

export default FtDeploy2;
