import { Divider } from 'antd'
import React from 'react'

import {ReactComponent as BackIcon} from '../../assets/back_icon.svg'

type Props = {
    title:string,
    canGoBack:boolean,
    onClick:any | null
}

function Header({
    title,
    canGoBack,
    onClick

}: Props) {
  return (
    <div >
        <div className='flex items-center gap-[1vw] mt-[1vw]'>
            <div 
            className='cursor-pointer'
            onClick={onClick}
            >{canGoBack && <BackIcon 
            className='w-[1.8vw] h-[1.8vw]'
            /> }</div>
            <div className='text-[2vw] text-primaryBlue'>{title}</div>
        </div>
        {/* <hr className='mt-[1vw] mb-[1vw]' /> */}
    </div>
  )
}

export default Header