import React, { useEffect, useState } from 'react'
import { IEntity } from '../../types'
import { getContrastYIQ } from '../../utils';
import useEntityStore from '../../../../store/entityStore';
import { toast } from 'react-toastify';
import { saveAllValidations } from '../_apiCalls';
import useWorkflowStore from '../../../../store/useWorkflowStore';

interface siderBarEntityProps{
    entity:IEntity,
    setCount:any
}


export default function NERSidebarEntity({entity,setCount}:siderBarEntityProps) {


    const [fontColor,setFontColor] = useState<string>();
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const {
        selection,
        setSelection,

        paras,
        setParas,

        
        activePara
        

    } = useEntityStore(state=>({
        selection:state.selection,
        setSelection:state.setSelection,

        paras:state.paras,
        setParas:state.setParas,

        activePara:state.activePara

        
    }));

    


    useEffect(()=>{

        setFontColor(getContrastYIQ(entity.color));


    },[entity.entityId]);

    const addTagToWord = ()=>{
            if(!selection){
                toast.warn("Invalid Selection!, please reselect the text and try again!");
                return;
            }
            if(!workflowId){
                toast.warn("Could not save changes,please refresh the page and try again!");
                return;
            }
            let tempParas = [...paras];

            let tempValidations = tempParas[activePara].validation;
            tempValidations.push({
                word:selection?.word,
                startIndex:selection?.startIndex,
                endIndex:selection?.endIndex,
                entityId:entity.entityId
            });

            tempParas[activePara].validation= tempValidations;
            if(!tempParas[activePara].saved){
                setCount((prev:number)=>prev+1);
              }
            tempParas[activePara].saved = true;
            setParas(tempParas);
            setSelection(null);

            //call api
            const paraId = tempParas[activePara].paraId;
            saveAllValidations(tempValidations,paraId,workflowId);
    }

  return (
    <div 
    onClick={()=>addTagToWord()}
    className=' py-[0.2vw] px-[0.74vw] 
                mx-[0.2vw] text-[1vw]
                 rounded-[0.2vw]
                 cursor-pointer
                 hover:scale-110 duration-200 hover:shadow-md
                 '
    style={{
        backgroundColor:entity.color,
        color:fontColor
    }}
    >
        {entity.entity}
    </div>
  )
}
