export enum TrainStatus{
    NotTrained,
    Trained,
    InTraining,
    InModelSelection,
    InModelTuning,
    InIterate,
    IsInLLMModelTrainig,
    IsInLLMModelTuning
}

export const checkCurrentTrainStatus =(workflow:any):TrainStatus=>{

    const trainStage = workflow?.stages?.find((s:any)=>s.name==="train");
    const automlProgress = workflow?.alpdsInfo?.percentageStatus?.automlProgress;
    const trainInfo = workflow?.alpdsInfo?.trainInfo;
    const selectedModelInfo = workflow?.alpdsInfo?.selectedModelInfo;

    //alert("selectedModelInfo"+selectedModelInfo?.type);
    //check if workflow is trained
    if(!trainStage){
        return TrainStatus.NotTrained
    }

    if(!automlProgress &&  selectedModelInfo && selectedModelInfo?.type){
        //alert("in iterate")
        return TrainStatus.InIterate;

    }
    if(selectedModelInfo && selectedModelInfo.type === "llm"){

        
        if(automlProgress !== undefined && Object.keys(automlProgress).length>1){
            if(trainStage?.active && automlProgress?.llmProgress === 100){
                //alert("Workflow is trained");
                return TrainStatus.Trained;
            }

            
            return TrainStatus.IsInLLMModelTrainig;
        }else{
          

            return TrainStatus.IsInLLMModelTuning;
        }


    }

    //check if workflow is in model tuning
    if(trainStage.active && trainStage.inModelTuning === true){
        return TrainStatus.InModelTuning
    }

    //check if workflow is in model selection
    if(trainStage.active && trainStage.inModelSelection === true){
        return TrainStatus.InModelSelection
    }

    //check if workflow is in training

   

        if(!automlProgress){
            return TrainStatus.NotTrained;
        }
    
        let isTrained:boolean =true;
        Object.keys(automlProgress).forEach((key)=>{
            if(automlProgress[key] < 100){
                isTrained = false;
            }
        });

        if(Object.keys(automlProgress).length===1){
            isTrained =false;
        }
    
        
    
        if(!isTrained && !trainStage.active){
            return TrainStatus.InTraining
        }

       
        if(isTrained && trainStage.active){
            return TrainStatus.Trained;
        }
       

    return TrainStatus.NotTrained;




   

   

   












}

export const checkPromptTrainStatus =(workflow:any):TrainStatus=>{

    const trainStage = workflow?.stages?.find((s:any)=>s.name==="train");
    const automlProgress = workflow?.alpdsInfo?.percentageStatus?.automlProgress;
    const trainInfo = workflow?.alpdsInfo?.trainInfo;
    const selectedModelInfo = workflow?.alpdsInfo?.selectedModelInfo;

    //alert("selectedModelInfo"+selectedModelInfo?.type);
    //check if workflow is trained
    if(!trainStage){
        return TrainStatus.NotTrained
    }

    // if(!automlProgress &&  selectedModelInfo && selectedModelInfo?.type){
    //     //alert("in iterate")
    //     return TrainStatus.InIterate;

    // }
    if(selectedModelInfo && selectedModelInfo.type === "llm"){
        //alert("inside model")
        
        if(automlProgress !== undefined){
            if(automlProgress?.llmProgress === 100){
                //alert("Workflow is trained");
                return TrainStatus.Trained;
            }

            
            return TrainStatus.IsInLLMModelTrainig;
        }else{
          
            
            return TrainStatus.IsInLLMModelTuning;
        }


    }

    //check if workflow is in model tuning
    if(trainStage.active && trainStage.inModelTuning === true){
        return TrainStatus.InModelTuning
    }

    //check if workflow is in model selection
    if(trainStage.active && trainStage.inModelSelection === true){
        return TrainStatus.InModelSelection
    }

    //check if workflow is in training

   

        if(!automlProgress){
            return TrainStatus.NotTrained;
        }
    
        let isTrained:boolean =true;
        Object.keys(automlProgress).forEach((key)=>{
            if(automlProgress[key] < 100){
                isTrained = false;
            }
        });

        if(Object.keys(automlProgress).length===1){
            isTrained =false;
        }
    
        
    
        if(!isTrained && !trainStage.active){
            return TrainStatus.InTraining
        }

       
        if(isTrained && trainStage.active){
            return TrainStatus.Trained;
        }
       

    return TrainStatus.NotTrained;




   

   

   












}