import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useWorkflowStore from '../../store/useWorkflowStore';
import MasterlistSummary from '../masterlist/summary/MasterlistSummary';
import { useRecoilValue } from 'recoil';
import { nodesAtom } from '../masterlist/atoms';




function TriggerAnnotation({startAnnoEnabled,lockMasterlist,isSideBarOpen}:any) {
 
    const [isModelOpen,setIsModelOpen] = useState<boolean>(false);
    
    const nodes = useRecoilValue(nodesAtom);
    const disabled:boolean = nodes.length<2;
    //let currentProject= useWorkflowStore(state=>state.currentProject);
    const currentWorkflow =useWorkflowStore(state=>state.currentWorkflow);
    

  return (
       <>
       {!isModelOpen && <div
          className={`text-white font-normal w-[17vw] duration-700 justify-center flex 
          ${isSideBarOpen?"bottom-[2.5vw] right-[25vw]"
          :"bottom-[2.5vw] right-[9.8vw]"} text-[1.1vw] fixed py-[0.8vw] px-[1vw] 
          cursor-${currentWorkflow?.masterlistLocked && startAnnoEnabled
            ? "not-allowed"
            : "pointer hover:scale-105 "
            } 
            uppercase shadow-[0.5vw] rounded-[0.5vw]
            ${disabled?"!bg-gray-400"
            :"!bg-[#11256D]"}
            
            
          `}

         
          data-testid="start-auto-annotation-test"
          onClick={() => {
            if(disabled) {
             toast.warning("Require atleast 2 classes to show masterlist summary")
              return;
            
            }
            if (
              currentWorkflow &&
              currentWorkflow.masterlistLocked === false &&
              startAnnoEnabled === true
            ) {
              //lockMasterlist();
              setIsModelOpen(true);
            } else {
              toast(
                "Masterlist is locked. Please create a new workflow to define new masterlist",
                {
                  type: "info",
                }
              );
            }
          }}
        >
          {startAnnoEnabled ? (
            "Masterlist Summary"
          )  :(

            <img className=" w-[2vw] h-[2vw]" src="/images/loading_white.svg" alt="loading" />
          )}
        </div>}

          <MasterlistSummary 
          isModalOpen={isModelOpen}
          setIsModalOpen={setIsModelOpen}
          lockMasterlist={lockMasterlist}
           />
       
       </>
  )
}
export default TriggerAnnotation