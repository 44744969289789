import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Popover } from 'antd';
import PermissionComponent from '../PermissionComponent';
import useWorkflowStore from '../../store/useWorkflowStore';
import authStore from '../../store/authStore';
import { useMatch } from 'react-router-dom';
import { Steps } from 'antd';
import VersionComponent from '../VersionComponent'
import template from "../../template";

interface PageLayoutProps {
    pageSubHeading: string;
    pageTitle: string
    pageIconUrl: string;
}

let PageLayout: React.FC<PageLayoutProps> = ({ pageSubHeading, pageIconUrl, children, pageTitle }) => {
    const workflowStore = useWorkflowStore();
    const userDetails = authStore(state => state.userDetails);
    const isStage1 = useMatch("/workflow/stage1");
    const isStage2 = useMatch("/workflow/stage2");
    const isMasterlist = useMatch("/workflow/masterlist");
    const isSummary1 = useMatch("/workflow/stage1-summary");
    const isSummary2 = useMatch("/workflow/stage2-summary");
    const isDashboard = useMatch("/workflow");
    const isInsideWorkflow = useMatch("/workflow/*");
    
    const projectType:string = workflowStore?.currentProject?.projectType || "";
    const workflowType:string = workflowStore?.currentProject?.workflowType || "";

    const allowMultiUser:boolean =(projectType && workflowType) ?  template[projectType]?.allowMultiUser : true;
    //console.log("=== allow multi user? ===",allowMultiUser)
    const [isPopupOpen, setPopupOpen] = useState<boolean>(false);

    useEffect(()=>{
       
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })

  
},[])


    return (
        <>
            <div className="w-full flex justify-between items-center pt-[0.5vw]">
                <div className="flex items-center">
                    <div className="w-[3vw] h-[3vw] rounded-[0.5vw] p-[0.2vw] flex justify-center items-center bg-primaryBlue">
                        <img src={pageIconUrl} className='w-[2vw]' alt="page icon" />
                    </div>
                    <div className="ml-[0.8vw]">
                        <div className="text-gray-500 text-[1vw]">{`${pageSubHeading.toUpperCase()} /`}</div>
                        <div className="text-primaryBlue text-[1.2vw] font-bold">{pageTitle}</div>
                    </div>
                    <div>

                    </div>
                </div>
                <div className={`flex items-center justify-center ${isStage1 || isStage2 ? "flex" : "hidden"}`}>
                    <div className="flex items-center justify-center">

                        <div
                            className="text-[1.1vw]  bg-primaryBlue rounded-full text-white flex items-center justify-center"
                            style={{ height: "3vw", width: "3vw" }}
                            >
                            1
                        </div>
                        {isStage1 && <span className="font-bold mx-3 text-[1.1vw] uppercase">Validation - Stage 1</span>}
                    </div>
                    {isStage2 && <div className="bg-primaryBlue" style={{ width: "30px", height: "5px" }} > </div>}
                    <div
                        className={`text-[1.1vw] ${workflowStore.currentWorkflow?.stages[3]?.active ? "bg-primaryBlue text-white" : "text-primaryBlue  bg-white cursor-not-allowed ml-[10vw]"} rounded-full  flex items-center justify-center`}
                        style={{ height: "3vw", width: "3vw" }}
                    >
                        2
                    </div>
                    {isStage2 && <span className="font-bold mx-3 text-[1.1vw] uppercase">Validation - Stage 2</span>}
                </div>
                {allowMultiUser && isInsideWorkflow && workflowStore?.currentProject?.createdBy === userDetails?.userId ? (<div className="flex items-center">
                    <div>{isPopupOpen && <PermissionComponent setPopupOpen={setPopupOpen} isPopupOpen={isPopupOpen} />}</div>
                    <img className="w-[2.7vw]  h-[2.7vw]  ml-4 duration-200 cursor-pointer hover:opactiy-75 transform hover:scale-110" src="/images/mutiuser.svg" alt="mutiuser" onClick={() => { setPopupOpen((prevState: boolean) => !prevState) }} />
                </div>) : (<div></div>)}
            </div>
            <Divider style={{margin:"0px 0px",marginTop:"0.5vw"}} />
            {children}
        </>
    );
};

export default PageLayout;
