import React from 'react'
import useWorkflowStore from '../../store/useWorkflowStore'
import template from '../../template';
import AppLayout from '../../components/layouts/AppLayout';
import PageLayout from '../../components/layouts/PageLayout';
import { useNavigate } from 'react-router-dom';
import LlmModelsComp from '../../components/llm-components/LlmModelsComp';
import LlmAPIDocumentation from '../../components/llm-components/LllmApiDocumentationComp';
import LLMLoading from './LLMLoading';
import LLMClassificationReport from './LLMClassificationReport';

export default function LlmApiDocumentationPage() {

    const project = useWorkflowStore(state=>state.currentProject);
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;
    const workflow = useWorkflowStore(state=>state.currentWorkflow);

    const progress = workflow?.alpdsInfo?.percentageStatus.automlProgress;
    const navigate = useNavigate();

    const projectType = project?.projectType ;
    const workflowType = project?.workflowType;
    const progressKey = "llmProgress"

  if(progress && progressKey in progress && progress[progressKey] <100){

    return <AppLayout>
    <PageLayout 
    pageIconUrl='/images/active_automl.svg'
    pageTitle={`${project?.name}`}
    pageSubHeading='LLM Trai Result'
    >
        <LLMLoading />
    </PageLayout>
</AppLayout>

  }
  if(progress && progressKey in progress && progress[progressKey] ===100){

    return <AppLayout>
    <PageLayout 
    pageIconUrl='/images/active_automl.svg'
    pageTitle={`${project?.name}`}
    pageSubHeading='Training In Progress'
    >
        <LLMClassificationReport />
    </PageLayout>
</AppLayout>

  }


  return (
    <AppLayout>
        <PageLayout 
        pageIconUrl='/images/active_automl.svg'
        pageTitle={`${project?.name}`}
        pageSubHeading='LLM API Documentation'
        >
            <LlmAPIDocumentation />
        </PageLayout>
    </AppLayout>
  )
}
