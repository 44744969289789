import React from 'react';

interface TableType {
    head1: string,
    head2: string,
    body: Array<any>
}

function UsageTable(table: any) {
    return (
        <table className='w-[65%] text-[0.85vw] border-[0.06vw] border-[#ccc] border-solid border-b-0'>
            <thead className='bg-[#eee] text-left'>
                <tr className='border-b-[0.06vw] border-[#ccc] border-solid h-[5vh]'>
                    <th className='px-[0.7vw] pt-[0.3vh] w-[50%] border-r-[0.06vw] border-[#ccc] border-solid'>{table.table.head1}</th>
                    <th className='px-[0.7vw] pt-[0.3vh] w-[50%]'>{table.table.head2}</th>
                </tr>
            </thead>
            <tbody>
                {
                    table.table.body.map((data: any) => {
                        return (
                            <tr className='border-b-[0.06vw] border-[#ccc] border-solid h-[5vh]'>
                                <td className='px-[0.7vw] pt-[0.3vh] w-[50%] border-r-[0.06vw] border-[#ccc] border-solid'>{data.key}</td>
                                <td className='px-[0.7vw] pt-[0.3vh] w-[50%]'>{data.value}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>

    )
}

export default UsageTable;