import { Divider, Dropdown} from "antd";
import * as React from "react";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DownOutlined } from "@ant-design/icons";
import axios from "../../utils/Axios";
import Switch from "../data-validation/Switch";
import template from "../../template";


import {
  activeNodeAtom,
  layersAtom,
  masterlistIdAtom,
  nodesAtom,
} from "./atoms";
import { deleteNodes, getNodeInfo, markAsModalClass } from "./utils/helper";
import { toast } from "react-toastify";
import useAuthStore from "../../store/authStore";
import useWorkflowStore from "../../store/useWorkflowStore";

const UPDATE_NODE = "/updateNode";
const Toolbar: React.FC = () => {
  let activeNode = useRecoilValue(activeNodeAtom);
  let [activenode2,setActiveNode] = useRecoilState(activeNodeAtom);
  let [nodes, setNodes] = useRecoilState(nodesAtom);
  let [layers, setLayers] = useRecoilState(layersAtom);

  // let [useNoTag, setUseNoTag] = useState(false);
  let masterlist = useRecoilValue(masterlistIdAtom);

  //let { currentWorkflow } = usePracticeArea();
  let { currentWorkflow,currentProject} = useWorkflowStore();
  let userDetails = useAuthStore(state=>state.userDetails);

  //check if user can select no tag
  const projectType:string = currentProject?.projectType ||"";
  const workflowType:string = currentProject?.workflowType||"";
  const masterlistSettings:any = template[projectType][workflowType]?.masterlist;
  const canUseNoTag:boolean = masterlistSettings?.useNoTag || false;

 const updateUseTag = async(value:boolean)=>{

    
    try {
      await axios.post("/workflow/setUseNoTag", {
          workflowId: currentWorkflow?.workflowId,
          useNoTag: value
      });
  }
  catch {
      toast("Could Not Change No Tag");

  }

 }


  //Temporarily commented 

  let deleteNode = async () => {
    if (activeNode !== null) {
      console.log("=== deleting node ===",activeNode)

        setActiveNode(null);

      
      console.log(masterlist);
      
      if (masterlist !== null) {
        let new_state = deleteNodes(activeNode, nodes, layers, masterlist._id);
        setNodes(new_state?.nodes);
        setLayers(new_state?.layers?.splice(0, new_state.layers.length - 1));
      }

      await axios.post("/masterlist/deleteNode", {
        nodeId: activeNode._id,
        // updated_node: {
        //   child_node_ids: getNodeInfo(
        //     activeNode.parent_id,
        //     nodes
        //   )?.child_node_ids.filter((id) => id !== activeNode?._id),
        // },
      })

    } else {
      toast("Please select a node to delete it.", { type: "info" });
      // setNotifications([...notifications, "Please select a node to delete it."]);
    }
  };

  //Temporarily commented 

  // useEffect(() => {
  //   // if (currentWorkflow?.useNoTag !== undefined) {
  //   //   setUseNoTag(currentWorkflow.useNoTag);
  //   // }

  //   window.onscroll = (e) => {
  //     let toolbarEle = document.getElementById("toolbar_masterlist");
  //     let topbar = document.getElementById("topbar");
  //     let ph = document.getElementById("placeholder");
  //     if (toolbarEle !== null && topbar !== null) {
  //       let stopAt = topbar.getBoundingClientRect().bottom;
  //       if (window.pageYOffset - 10 > stopAt) {
  //         toolbarEle.style.position = "fixed";
  //         toolbarEle.style.top = `${stopAt}px`;
  //         toolbarEle.style.width = "89%";
  //         toolbarEle.style.backgroundColor = "white";
  //         toolbarEle.style.padding = "10px";
  //         toolbarEle.style.zIndex = "100";
  //         if (ph) {
  //           ph.style.height = "50px";
  //         }
  //       } else {
  //         toolbarEle.style.position = "relative";
  //         toolbarEle.style.top = `initial`;
  //         toolbarEle.style.width = "98%";
  //         toolbarEle.style.backgroundColor = "#fafafa";
  //         if (ph) {
  //           ph.style.height = "0px";
  //         }
  //       }
  //     }
  //   };

  //   return () => {
  //     window.onscroll = null;
  //   };
  // }, []);

  let selectAsModelClass = () => {
    if (activeNode !== null) {
      console.log("=== selecting node ===", activeNode);
      
      if (activeNode.is_model_class !== true) {
        let new_state = markAsModalClass(activeNode, nodes, layers);
        setNodes(new_state.nodes);
        setLayers(new_state.layers);
      } else {
        toast("The selected node is already a model node.", { type: "success" });
      }
    } else {
      toast("Select a node to mark it as a model node.", { type: "success" });
    }
  };

  return (
    <div className="  z-[20] sticky top-[4.9vw] bg-gray-50 shadow-sm ">
      {/* <div id={"placeholder"}></div> */}
      <div
        id={"toolbar_masterlist"}
        className={"flex justify-between items-center mt-4 "}
        // style={{
        //   width: "100%",
          
        // }}
        
      >
        <div className="flex space-x-[0.8vw] py-[0.5vw]">
          <button
                 style={{verticalAlign:'center', height:'2.5vw'}}
            className={`text-white text-center text-[1.1vw] py-[0.4vw] px-[1.3vw] rounded-[0.3vw] 
                      ${(currentWorkflow && currentWorkflow.masterlistLocked === false
                         && currentProject?.createdBy === userDetails?.userId  ) ? 'bg-[#1BA94C] hover:scale-105 duration-700 ' : 'bg-[#acacac]'}`}
            data-testid="toolbar-select-modal-test"
            onClick={() => {
              if (currentWorkflow 
                && currentWorkflow.masterlistLocked === false
                && currentProject?.createdBy === userDetails?.userId) {
                
                  selectAsModelClass();
              } else {
                toast(
                  "Masterlist is locked or you don't have the rights to edit the masterlist",
                  {
                    type: "info",
                  }
                );
              }
            }
   
          }
          >
            Select as Model Class
          </button>

          <button
            data-testid="toolbar-delete-class-test"
            style={{verticalAlign:'center', height:'2.5vw'}}

            onClick={() => {
              if (
                currentWorkflow &&
                currentWorkflow.masterlistLocked === false
                && currentProject?.createdBy === userDetails?.userId
              ) {
                deleteNode();
              } else {
                toast(
                  "Masterlist is locked or you don't have the rights to edit the masterlist",
                  {
                    type: "info",
                  }
                );
              }
            }}
            className={`text-white text-center text-[1.1vw] py-[0.4vw] px-[1.3vw] rounded-[0.3vw] 
            ${(currentWorkflow 
              && currentWorkflow.masterlistLocked === false
              && currentProject?.createdBy === userDetails?.userId) ? 'bg-[#E63946] hover:scale-105 duration-700 ' : 'bg-[#C4C4C4]'}`}


          >
            Delete Class
          </button>
        </div>



        {currentProject?.createdBy === userDetails?.userId 
        && canUseNoTag
        &&<div>
        <div className="flex items-center space-x-[1vw]">
          
          <p className="text-[1vw]">No Tag</p>
          <Switch
            innerWidth={1.6}
            
            width={5}
            disable={currentWorkflow?.masterlistLocked?currentWorkflow?.masterlistLocked:false}
            disableMessage="Masterlist is locked"
            onClick={updateUseTag}
            state={currentWorkflow?.useNoTag?currentWorkflow?.useNoTag:false}
          />
          
        </div>
        </div>
        }
        {/* <div className={"flex space-x-3"}>
          <Dropdown overlay={<></>}>
            <div
              className="px-5 flex justify-between items-center rounded-md"
              style={{
                width: "200px",
                height: "38px",
                background: "#EAEAEA",
                fontSize: "15px",
                fontWeight: 400,
              }}
            >
              <p>Version 1</p>
              <DownOutlined />
            </div>
          </Dropdown>
        </div> */}
      </div>
      <Divider className={"mt-[0.3vw] p-0 z-"} />
    </div>
  );
};

export default Toolbar;
