

import React, { useState, useEffect } from "react";
import ApexChart from "./ApexChart";
import styles from "./modelTraining1.module.css";
import { useContext } from "react";
//import useWorkflow from "../../../../../store/zustand/useWorkflow";
import useWorkflowStore from "../../../../store/useWorkflowStore";
// import { sendWorkflowInfo } from "../../../../../realtime";
//import useCurrentUser from "../../../../../store/zustand/useCurrentUser";
import useAuthStore from "../../../../store/authStore";
import {useNavigate} from 'react-router-dom';
//import ReactGA from "react-ga";

export interface ModelTraining1Props {}

interface detailsState {
  trainingProgress: number;
 
}

const ModelTraining1: React.FC<ModelTraining1Props> = () => {
  let workflowStore = useWorkflowStore();
  let navigate = useNavigate();

  let initial =
    workflowStore.currentWorkflow?.alpdsInfo?.percentageStatus?.automlProgress;

  const [details, setDetails] = useState({
    trainingProgress: 0,

  });

  useEffect(() => {
    //setting the state

    let data:any =
      workflowStore.currentWorkflow?.alpdsInfo?.percentageStatus
        ?.automlProgress || 0;
        console.log("=== data ==",data);

    if (data) {
      let s1 = data;
     // let s2 = data.automlClassifier1Progress || 0;
     // let s3 = data.automlClassifier2Progress || 0;

      setDetails({
        ...details,
        trainingProgress: s1,
       
       
      });
    }
  }, [workflowStore.currentWorkflow?.alpdsInfo]);

  //let { stages, setStages } = useContext(StageContext);


  let [counter, setCounter] = useState<number>(0);

  let  user  = useAuthStore(state=>state.userDetails);



 
  useEffect(() => {
    //setStages(setActive(stages, 4));
  }, []);

  useEffect(() => {
    if (details.trainingProgress === 100) {
     // history.push("/app/pa/ws/model-training2");
     navigate("/workflow/modeltraining-2?workflowId="+workflowStore.currentWorkflow?.workflowId);

    }
  }, [details]);

  return (
    <div className="w-full mt-4">
      <section className={styles.training_section}>
        <img src="/images/training_icon.svg" width={40} alt="Training Icon" />
        <div>Training in progress ...</div>
        <div className={styles.training_percentage}>
          {details.trainingProgress}%
        </div>
      </section>
      {/* Training Section */}
      {/* Data Section
      <section className={styles.data_section}>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>DATA POINT</div>
          <div className={styles.data_value}>{details.dataPoint}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>DATA POINT</div>
          <div className={styles.data_value}>{details.dataPoint}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>TOTAL UPLOADS</div>
          <div className={styles.data_value}>{details.totalUpload}</div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.data_heading}>ESTIMATED TIME</div>
          <div className={styles.data_value}>{details.estimatedTime}</div>
        </div>
      </section>  */}
      {/* Data Section */}
      {/* Graph Section */}
      <div className=" justify-center">

      <section className={` flex p-[1vw] justify-between  h-[60vh] `}>
        <div className="w-[15%]">

        </div>
       
        <div className={`w-[40vw] h-[35vw] flex items-center justify-center   relative  `}>
          <div className="w-full h-full mt-[3vw] ">

          <ApexChart
            stage1={details.trainingProgress}
           
            height={600}
          />
          </div>

          <div className="w-full h-full absolute flex items-center justify-center">
            <p className="text-[3.5vw] text-[#1BA94C]">
                {details.trainingProgress}%

            </p>
          </div>

        </div>

        <div className={`flex flex-col p-[0.4vw] justify-end`}>
          <div className={styles.tip_container}>
            <img src="/images/tip_green.svg" alt="Tip Icon" />
            <div>Tip</div>
          </div>
          <div className={styles.tip_description}>
            You can choose to minimize this process and come back to it later.
            You can access it by peeking into the dashboard.
          </div>
        </div>

      </section>
      </div>
    </div>
  );
};

export default ModelTraining1;
