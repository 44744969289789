

const checkForCriticalProgress =(workflow:any)=>{



    const percentageStatus = workflow?.alpdsInfo?.percentageStatus;


    
    const isStage1Progress:boolean =( percentageStatus?.stage1Progress || 100) && percentageStatus.stage1Progress<100;
    
    const isStage2InProgress:boolean = (percentageStatus?.stage2Progress?.currentBatchProgress || 100 )
                                        && percentageStatus?.stage2Progress?.currentBatchProgress<100;

    const automlProgress:any = percentageStatus?.automlProgress;
    const autoML1:boolean = (automlProgress?.dataLoadingProgress || 100) && automlProgress?.dataLoadingProgress<100;
    const autoML2:boolean = (automlProgress?.automlClassifier1Progress || 100) && automlProgress?.automlClassifier1Progress<100;
    const autoML3:boolean = (automlProgress?.automlClassifier2Progress || 100) && automlProgress?.automlClassifier2Progress<100;

    const isAutoMLInProgress:boolean = autoML1 || autoML2 || autoML3;

    const isStage2AccuracyInProgress:boolean = (percentageStatus?.stage2AccuracyProgress || 100 )
                                                        && percentageStatus?.stage2AccuracyProgress<100;


    const isForkInProgress:boolean = workflow?.forkInfo?.forkInProgress

    console.log("checking",isStage1Progress,isStage2InProgress,isAutoMLInProgress,isStage2AccuracyInProgress);

    return (isStage1Progress || isStage2InProgress 
                            || isAutoMLInProgress || isStage2AccuracyInProgress 
                            || isForkInProgress) as boolean; 



}

export default checkForCriticalProgress;

