import React from "react";
import {BiCrosshair} from 'react-icons/bi'
import {IoClose} from 'react-icons/io5'

interface ErrorCompProps {
    errorMsg: string;
    show: boolean;
    onClose?: () => void;
}

let ErrorComp: React.FC<ErrorCompProps> = ({ errorMsg, show, onClose }) => {
    if (!show) return <></>;

    return (
        <div  className="w-full mb-[0.5vw] rounded-md px-2 py-1 bg-red-50 text-red-500 border-red-500 border flex justify-between">
            <div>{errorMsg}</div>
            <div
                className="cursor-pointer hover:font-bold flex items-center"
                onClick={() => {
                    if (onClose) onClose();
                }}
            >
                <IoClose className="w-[1.2vw] h-[1.2vw]" />
            </div>
        </div>
    );
};

export default ErrorComp;
