import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { toast } from 'react-toastify';
import Axios from '../../../utils/Axios';
import IICon from '../../IICon';


interface StackedBarProps {
    graphData: any,
    batchNumber:number,
    workflowId:string | undefined,
    stage:number,
    requestType:number,
    canShowCommonParas:boolean 
}

const MLCStackedBar: React.FC<StackedBarProps> = ({ graphData ,
    batchNumber
    ,workflowId
    ,stage
    ,requestType
    ,canShowCommonParas}) => {

    const [data, setData] = useState<any>({
        series: [],
        options: {}
    });

    const [totalParas,setTotalParas] = useState<any>(0);

    const isActive = requestType ===3?false:true;
    //console.log("=== stacked bar data ===",graphData)

    useEffect(()=>{
      //fetch total paras in the current stage
      const fetchTotalParas = async()=>{

        if(!workflowId || requestType===3 || !stage || !batchNumber || !canShowCommonParas)
        {
            
            return;
        }
        try{
            let res = await Axios.post("/summary/gettotalparas",{
                workflowId,
                batchNumber,
                stage,
                requestType:requestType
            });
            if(res.data?.data?.count)
            {
                setTotalParas(res.data?.data?.count)
            }
            

        }
        catch{
            toast("Could not get total paragraph. Please reload the page.",{type:"error"});
        }

    }
    fetchTotalParas();
    },[graphData,workflowId])

    useEffect(() => {

  



        if (graphData) {
            const series = [
               
                {
                    name: "Common Paras",
                    //total common paras data
                    data:isActive ? Object.keys(graphData).map((key, i) => {

                        let total = 0;
                        let notag = 0;
                        
                        return graphData[key];

                    }):[]
                },
                {
                    name:"Not Validated",
                    data:isActive ? Object.keys(graphData).map((key, i) => {

                        let total = graphData[key];
                        let notag = 0;
                        
                        return totalParas-total;

                    }):[]
                },
                // {
                //     name: "No Tag",
                //     //total no tags data
                //     data: Object.keys(graphData).map((key, i) => {

                //         let total = 0;
                //         let notag = 0;
                //         Object.keys(graphData[key]).forEach((key2) => {
                //             if (key2 === "null") {
                //                 total += graphData[key][key2]

                //             }

                //         })
                //         return total;

                //     })
                // },
              


            ]

            const options = {
               
                noData:{
                    text:`No data available for multiuser voting,
                     validate more paragraphs to see this graph.`
                  },
                labels: isActive ? Object.keys(graphData).map((key, i) => i + 1):[],
                colors: ['#03045E', 'rgba(3, 4, 94,0.2)'],
               
                toolbar: {
                    show: false
                },
                grid: {
                    show: false
                },
                chart: {
                    stacked: true
                },
                yaxis:{
                    title:{
                        text:"Common Paragraphs Validated",
                        style:{
                           color:"#bfbfbf",
                           fontFamily:"",
                           fontWeight:"bold"
                        }
                    }
                }
            


            }
            setData({ series, options });
           // console.log("=== Data ===", series);

        }



    }, [totalParas]);

   


    return <div className='p-2 '>
        <div className="flex items-center gap-[0.5vw] self-start text-[#1BA94C] font-bold text-[1.1vw] mt-3 mx-5">
            
            <p>Common Paras</p>
            {requestType ===1 &&  <IICon 
                message="This graph shows common validated paras between users for the data validated by user which have currently not undergone multiuser voting.
                    If user has not validated any new paragraphs then this graph will be empty"
        />}
    
             </div>

        <Chart options={data.options || {}} height={450} width={'100%'} type={"bar"} series={data.series || []} />
        {requestType===3 && <div className="text-red-400 text-[1.1vw]">*Bar graph for All Annotated Data is not available</div>}
        {!canShowCommonParas && <div className="text-red-400 text-[1.1vw]">*Common Paras graph for Multilabel Annotation is not available</div>}
    </div>

}

export default MLCStackedBar;