import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useWorkflowStore from '../../../store/useWorkflowStore';
import useEntityStore from '../../../store/entityStore';
import NERMasterlistSummary from './masterlist-summary/NERMasterlistSummary';
import {triggerNERAnnotation} from './_apiCalls';
import { useNavigate } from 'react-router';




function NERTriggerAnnotation({startAnnoEnabled}:any) {
  
    const navigate = useNavigate();

    const entities = useEntityStore(state=>state.entities);
    const currentWorkflow =useWorkflowStore(state=>state.currentWorkflow);
    const isMasterlistLocked = currentWorkflow?.masterlistLocked;
    
    const [isModelOpen,setIsModelOpen] = useState<boolean>(false);
    const disabled:boolean = entities?.length<1;
    //let currentProject= useWorkflowStore(state=>state.currentProject);

    const lockMasterlist = async () =>{
      if(currentWorkflow){
        triggerNERAnnotation(currentWorkflow?.workflowId,1);
        navigate("/workflow/validation?workflowId="+currentWorkflow?.workflowId);
      }


    }
    

  return (
       <>
       {!isModelOpen && <div
          className={`text-white font-normal w-[17vw] duration-700 justify-center flex 
           ${false?"bottom-[2.5vw] right-[25vw]"
          :"bottom-[2.5vw] right-[9.8vw]"} text-[1.1vw] fixed py-[0.8vw] px-[1vw] 
          cursor-${currentWorkflow?.masterlistLocked && startAnnoEnabled
            ? "pointer hover:scale-105"
            : "pointer hover:scale-105 "
            } 
            uppercase shadow-[0.5vw] rounded-[0.5vw]
            ${disabled?"!bg-gray-400"
            :"!bg-[#11256D]"}
            
            
          `}

          // style={{
          //   background: currentWorkflow?.masterlistLocked
          //     ? "rgb(172, 172, 172)"
          //     : "#11256D",
          //   boxShadow: "0.2vw 0.2vw 0.01px rgb(172, 172, 172, 0.2)",
          // }}
          data-testid="start-auto-annotation-test"
          onClick={() => {

            if(isMasterlistLocked){
              navigate(`/workflow/validation?workflowId=${currentWorkflow.workflowId}`);
              return 
            }
            if(disabled) {
             toast.warning("Require atleast 1 entity to start annotation");
              return;
            
            }
            if (
              currentWorkflow &&
              currentWorkflow.masterlistLocked === false &&
              startAnnoEnabled === true
            ) {
              lockMasterlist();
              //setIsModelOpen(true);
            } else {
              toast(
                "Masterlist is locked. Please create a new workflow to define new masterlist",
                {
                  type: "info",
                }
              );
            }
          }}
        >
          {/* {startAnnoEnabled ? (
            "Masterlist Summary"
          )  :(

            <img className=" w-[2vw] h-[2vw]" src="/images/loading_white.svg" alt="loading" />
          )} */}

          {
          isMasterlistLocked?
          "Proceed To Validation"
          :
          startAnnoEnabled ? (
            "Start Annotation"
          )  :(

            <img className=" w-[2vw] h-[2vw]" src="/images/loading_white.svg" alt="loading" />
          )}
        </div>}

        {/* <NERMasterlistSummary
        isModalOpen ={isModelOpen}
        setIsModalOpen={setIsModelOpen}
        lockMasterlist={()=>{}}
        /> */}

          {/* <MasterlistSummary 
          isModalOpen={isModelOpen}
          setIsModalOpen={setIsModelOpen}
          lockMasterlist={lockMasterlist}
           /> */}
       
       </>
  )
}
export default NERTriggerAnnotation