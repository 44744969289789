import React from 'react'

type Props = {
   className:string
}

function LLMIcon({className}: Props) {
  return (
    <img 
    className={`${className}`}
    alt="dataneuron llm customization"
    src={"/Group 48095578.png"}
    />
  )
}

export default LLMIcon