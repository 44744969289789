import create from "zustand";
import { WorkflowStoreType } from "../types/store/WorkflowType";

type VersionItemType={
    workflowId:string,
    workflowName:string,
    stages:any[],
    percentageStatus:any
}

type VersionStoreType={
    listOfVersion:VersionItemType[],
    setListOfVersion:any,

}

let useVersionStore = create<VersionStoreType>(
          (set, _get) => ({
            listOfVersion:[],
            setListOfVersion:(data:VersionItemType[])=>{
                console.log("=== updating list of versions ===",data);
                set({listOfVersion:data})
            }
        })
);

export default useVersionStore;
