import React, { useContext, useEffect, useState } from 'react'
import useEntityStore from '../../../store/entityStore';
import { IEntity } from '../types';
import {getContrastYIQ} from '../utils';
import {Popover,} from 'antd';
import {ReactComponent as BinIcon} from '../../../assets/Bin_Icon.svg';
import './styles.css'
import useWorkflowStore from '../../../store/useWorkflowStore';
import { toast } from 'react-toastify';
import { saveAllValidations } from './_apiCalls';

interface ITaggedText{
    text:string[],
    entity:string,
    validation:string[],
    isActive:boolean,
    isReadOnly?:boolean
}

const PopeOverMenu =({entity,unTagWord,validationData,close,isReadOnly}:any)=>{

    const paras = useEntityStore(state=>state.paras);
    const setParas = useEntityStore(state=>state.setParas);
    const activePara = useEntityStore(state=>state.activePara);
    const workflowId = useWorkflowStore(state=>state.currentWorkflow)?.workflowId;

    const removeTagFromWord = ()=>{
        
        let tempParas = [...paras];
        if(!workflowId){
            toast.warn("Could not save changes,please refresh the page and try again");
            return;
        }
        close();

        let tempValidations = tempParas[activePara].validation;
        const paraId = tempParas[activePara].paraId;
        
        tempValidations = tempValidations.filter((v:any)=>{

            if(v.startIndex !== validationData.startIndex
                && v.endIndex !== validationData.endIndex
                
                ){
                    return true;
                }
                else{
                    return false;
                }

        })

        tempParas[activePara].validation= tempValidations;
        tempParas[activePara].saved = true;
        
        saveAllValidations(tempValidations,paraId,workflowId);
        setParas(tempParas);
        
}

    // useEffect(()=>{
    //     close();
    // },[activePara])

    return <div className='flex min-w-[10vw]  items-center justify-center '>
        <p  className={`text-[1vw] px-[0.3vw]  
                        border-gray-400 ${isReadOnly?"":"border-r flex-[7]   border-dashed"} font-bold`}>
                {entity}
        </p>
        {!isReadOnly && <div className='flex items-center justify-center flex-[1]'>
            <BinIcon className=' 
                                duration-200
                                h-[1.2vw] w-[1.2vw] ml-[1vw]
                                hover:scale-125 cursor-pointer '
                                
                       onClick={()=>removeTagFromWord()} />
        </div>}

    </div>
}


export default function TaggedText({text,
                                    entity,
                                    validation,
                                    isActive,
                                    isReadOnly
                                    }:ITaggedText) {

 // const nerStore = useContext(nerContext);
 // const Entity = nerStore.entities.find((e)=>e.entity === entity);
    const [Entity,setEntity] = useState<IEntity>();
    const entities = useEntityStore(state=>state.entities);
    const [open,setOpen] = useState<boolean>(false);
    const [fontColor,setFontColor] = useState<string>("");
    
   
    useEffect(()=>{
       // console.log("=== paragraph updated ===");
        const Entity = entities
                        .find((e)=>e.entityId === entity);
        setEntity(Entity);
        Entity && setFontColor(getContrastYIQ(Entity?.color));
     //   console.log("=== Entity===",Entity);
    },[entity]);

    const handleOpenChange = ( value:boolean )=>{
        isActive &&  setOpen(value)
    }

  return (
    <Popover
    placement="topRight"
    className={` ${open?"shadow-md":""} `}
    
    overlayClassName='custom_popover'
    trigger={"click"}
    open={open}
    onOpenChange={handleOpenChange}
   
    content={<PopeOverMenu
        entity={Entity?.entity}
        validationData={validation}
        close={()=>setOpen(false)}
        isReadOnly={isReadOnly}


    />}>

        <span 
        className=' px-[0.4vw] py-[0.3vw] rounded-[0.2vw] mr-[0.2vw] cursor-pointer'
        style={{
            backgroundColor:Entity?.color,
            color:fontColor
        }}
        // onClick={()=>{
        //   //let nerInfo =nerStore.para.alpdsInfo[0].nerInfo;
        //   let 
        //   //console.log(validation);
    
        //   let newNerInfo = nerInfo.filter((v)=>{
        //       if(validation.startIndex!== v.startIndex && validation.endIndex !== v.endIndex) return v;
        //   });
    
        //   nerStore.setPara({
        //     ...nerStore.para,
        //     alpdsInfo:[
        //     {
        //       nerInfo:newNerInfo
        //     } 
        //     ]
        //   })
    
    
    
        // }}
            >
        {text}
        <span 
        className={` text-[0.9vw] ${fontColor==="white"?"font-extrabold":"font-bold"} `}
        style={{
            fontWeight:"",
          marginLeft:"10px",
          userSelect:"none"
        }}> 
        {Entity?.abbreviation}
        </span>
        
      </span>
    </Popover>
    )
}
