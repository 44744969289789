import React from 'react';
import { TrainStatus, checkCurrentTrainStatus } from '../../utils/navigationHelper';
import useWorkflowStore from '../../store/useWorkflowStore';
import { toast } from 'react-toastify';





function MoveToAnnotationButton({ isSideBarOpen, startAnnoEnabled, OnClick }: any) {
   // console.log("=== is Sidebar open? ===",isSideBarOpen)
   const workflow = useWorkflowStore(state=>state.currentWorkflow);

   const CanMoveToValidation = ()=>{
    // const currentStatus = checkCurrentTrainStatus(workflow);

    // if(currentStatus === TrainStatus.NotTrained 
    //     || currentStatus === TrainStatus.InIterate){
    //     return true;
    // }
    return true;
   // return false;

}
    return (
        <div>
            <div
                className={`text-white justify-center flex  w-[17vw]  text-[1.1vw] fixed 
                        ${isSideBarOpen ? "bottom-[2.5vw] right-[25vw]"
                        : "bottom-[2.5vw] right-[9.8vw]" }
                         py-[0.8vw] px-[1vw] cursor-pointer hover:scale-105 duration-700 uppercase 
                         shadow-[0.5vw] rounded-[0.5vw]`}

                style={{
                    background: "#11256D",
                    boxShadow: "0.2vw 0.2vw 0.01px rgb(172, 172, 172, 0.9)",
                }}
                data-testid="proceed-to-validation-test"
                onClick={()=>{
                    if(CanMoveToValidation()){
                        OnClick();
                    }else{
                        toast.warn("Please trigger iteration from workflow dashboard to go to validation screen");
                    }

                } }
            >
               
                    Proceed To Validation
                
            </div>

            
        </div>
    )
}

export default MoveToAnnotationButton