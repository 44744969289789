import React from 'react'
import CreateProjectComp from '../components/project/CreateProjectComp'
import PageLayout from "../components/layouts/PageLayout"
import AppLayout from "../components/layouts/AppLayout";
import CreateProjectPageLayout from '../components/layouts/CreateProjectPageLayout';

export default function CreateProjectPage() {
  return (
    <AppLayout>
    {/* <PageLayout pageSubHeading="Create Project" pageIconUrl="/images/add.svg"  pageTitle='Create A New Project'>
      
    </PageLayout> */}
    <CreateProjectPageLayout >

    <CreateProjectComp />
    </CreateProjectPageLayout>
</AppLayout>
  )
}
