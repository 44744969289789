import React, { useState } from "react";
import AppLayout from "../components/layouts/AppLayout";
import MasterlistComp from '../components/masterlist'
import PageLayout from '../components/layouts/PageLayout';
import useWorkflowStore from '../store/useWorkflowStore';

//import template 
import template from "../template";


interface MasterlistProps {}
let Masterlist: React.FC<MasterlistProps> = () => {
    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const project =useWorkflowStore(state=>state.currentProject);

    const masterlist =project? template[project?.projectType || ""][project?.workflowType || ""]?.masterlist || undefined:undefined;
    
   
    return (


        <AppLayout>
            <PageLayout pageSubHeading="masterlist" pageTitle={`${project?.name} (${workflow?.workflowName})`} pageIconUrl="/images/active_masterlist.svg">
          
            {masterlist!== undefined?React.createElement(masterlist.component):null}
            </PageLayout>
        </AppLayout>
    );
};

export default Masterlist;
