import React, { useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { message } from "antd";
import { toast } from "react-toastify";
import axios from "../../../utils/Axios";

import useAdvancedMasterlist, { UpdateRecentFolders } from '../../../store/useAdvancedMasterlist';
import useWorkflowStore from "../../../store/useWorkflowStore";


let AddFolderComponent: React.FC = () => {
  let [active, setActive] = React.useState(false);
  let inputRef = useRef<HTMLInputElement>(null);
  let AMStore = useAdvancedMasterlist();
  let workflowStore = useWorkflowStore();


  useEffect(() => {

    active && inputRef.current?.focus();

  }, [active]);


  let saveFolder = async (word: string) => {
    // console.log(word);

    let folders: any = AMStore.folders;
    if (!folders[word]) {
      let newObject: any = folders;

      newObject[word] = { updatedOn: new Date() }
      AMStore.setFolders(newObject);

      AMStore.setRecentFolders(UpdateRecentFolders(AMStore.folders));

      toast("New folder is created", { type: toast.TYPE.SUCCESS });

      // API to add folder to DB
      let userProject = workflowStore.userProject;
      let currWorkflowId = workflowStore.currentWorkflow?.workflowId; 
      axios.post("/wishlist/addFolder", {
        userId: userProject?.userId,
        projectId: userProject?.projectId,
        workflowId: currWorkflowId, 
        folder: word
      })

      setActive(false);
    }
    else {
      toast("Folder already exists", { type: toast.TYPE.ERROR });
    }

  };

  React.useEffect(() => {
    window.onkeydown = (ev) => {
      if (ev.key === "Escape") {
        setActive(false);
      }
    };
    return () => {
      window.onkeydown = null;
    };
  }, []);

  return (
    <div
      onClick={active === false ? () => setActive(true) : undefined}
      className={`border border-black rounded-full 
                  px-2 m-[0.5vw]
                  ${active ? "w-[12vw]" : "w-[8vw] "} h-[2vw] 
                  flex items-center justify-center text-[1vw]
                  cursor-pointer duration-500
                  `}
      data-testid="add-folder-button-test"
    >
      {active ? (
        <div className="flex">
          <input
            onBlur={() => setActive(false)}
            ref={inputRef}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                //@ts-ignore
                let word = ev.target.value;
                saveFolder(word);
              }
            }}
            maxLength={15}
            placeholder="Enter Folder Name"
            type="text"
            className="outline-none px-2 w-full bg-gray-50 text-center w-[90%]"
            data-testid="add-folder-input-test"
          />
          <div
            data-testid="close-folder-button-test"
            onClick={() => setActive(false)}
            className="pr-2 font-bold text-[1vw] text-gray-300 hover:text-gray-600 h-full flex items-center"
          >
            X
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          Add
          <FaPlus className="ml-3 w-[1vw] h-[1vw]" />
        </div>
      )}
    </div>
  );
};

export default AddFolderComponent;
