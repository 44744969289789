import React,{useState,useEffect} from 'react';
import Chart from 'react-apexcharts';
import {Slider,Modal, Popover} from 'antd';
import Axios from '../../../utils/Axios';
import useWorkflowStore from '../../../store/useWorkflowStore';
import {nodesAtom} from '../../../components/masterlist/atoms';
import {useRecoilValue}  from 'recoil';
import {BsArrowsAngleExpand} from 'react-icons/bs';
import {toast } from 'react-toastify';
import ExpandedClasswiseSummary from './ExpandedClasswiseSummary';
import IICon from '../../IICon';

interface MultiUserBarGraphProps{
    stage:number,
    graphData:any,
    max:number,
    requestType:number
  
}

const MultiUserBarGraph:React.FC<MultiUserBarGraphProps> = ({graphData,
                                                                max,
                                                                requestType})=> {


  const [users,setUsers] =useState(1);
  const [showModal,setShowModal] = useState<boolean>(false);
  const [showExpandMesssage,setShowExpandMessage] = useState<boolean>(false);
  //const [max,setMax] = useState(0);
  const [pieChartOptions,setPieCharOptions] = useState<any>({
    series:[],
    options:{}
  })
  const currentWorkflow = useWorkflowStore(state=>state.currentWorkflow);
  const nodes = useRecoilValue(nodesAtom);
  let [marks,setMarks] = useState<any>({});

  const getOneNodeName = (data: string) => {
    let noTagClassName = "No Tag";
    let nullName = "No Associated Class";
    if (data) {

      if (data === "null") {
        return "No Associated Class";
      }

      if (data === noTagClassName) {
        return noTagClassName;
      }
      else {
        let node_name: string = nodes.find((n: any) => n.node_id === data)?.node_name || "";

        return node_name.length > 15 ? `${node_name.slice(0, 15)}...` : node_name;
      }


    }
  }

  //Config series and options object on the basis of user numbers
  const getNodeName = (data:any,key:any)=>{
    let noTagClassName = "No Tag";
    let nullName = "No Associated Class";
    if(data)
    {
      return Object.keys(data[key]).map(key=>{
        if(key === "null")
        {
          return nullName;
        }
        else if(key === noTagClassName)
        {
          return noTagClassName;
        }
        else{
          return nodes.find(n=>n.node_id===key)?.node_name
        }
      });
    }
    else {
      return [];
    }

  }



   useEffect(()=>{

    if(!nodes.length)
    {
      toast("Could not find nodes.")
     
    }
   
    if(graphData && nodes.length)
    {
      let key = "num_user_"+users;
      let options:any = {}
      let series:any[] = []
      try{
        
        const key =  "num_user_"+users
        let data = graphData[key];
        
        if(Object.keys(data).length>10)
        {
          setShowExpandMessage(true);
        }
        else{
          setShowExpandMessage(false);
        }

         series =[
            {
              name:`validated by ${users} user${users>1?"'s":""}`,
              data: Object.keys(data)
              .sort((k1: string, k2: string) => data[k1] > data[k2] ? -1 : 1)
              .map(k=> data[k])
            }
         ]
        options = {
          noData:{
            text:`No data available for multiuser voting.`
            ,align:"center",
            verticalAlign:"middle",
            
            style:{
              fontSize:"1vw",
              whiteSpace:"pre-wrap"
             
            }
          },

          plotOptions: {
            bar: {

              horizontal: true,

              barHeight: "100%",
              distributed: true,
             
            },

          },
          chart: {
          },
          legend: {
            show: false
          },
          colors: ["#fc5c65", "#fd9644", "#26de81", "#9980FA", "#45aaf2", "#a55eea",
            "#3867d6", "#3c40c6", "#18dcff", "#32ff7e", "#20bf6b", "#0fb9b1",
            "#ff3838", "#ff4d4d", "#ff9f1a", "#ff3838", "#20bf6b", "#0fb9b1"
          ],
          xaxis: {
            categories: Object.keys(data)
              .sort((k1: string, k2: string) => data[k1] > data[k2] ? -1 : 1)
              .map((k: string) => getOneNodeName(k))
          }

        }
      }
      catch(err)
      {
        console.log("Error while parsing data in multiusepie chart",err);
        toast("There was an error while parsing data in multi-user pie chart.");
      }

      let temp:any ={}
      for(let i =0;i<max;i++)
      {
        temp[`${i+1}`] = `${i+1}`;
      }
      setMarks(temp);
      
      setPieCharOptions({options,series})



    }

  },[users,graphData,nodes])
  ////console.log("graph data",graphData);

  const getChartHeight =()=>{

    let model_nodes = pieChartOptions.option?.xaxis?.categories.length || 0;
    if(model_nodes.length<10){
      return 100


    }
    if(model_nodes.length<25){
      return 600


    }
    if(model_nodes.length<50){
      return 700

    }
    if(model_nodes.length<75){
      return 800


    }

    return 500;


  }

  
  return (
    <div className=' h-full'>
      { graphData && 
      <> 
      <div className='flex items-center justify-between p-2'>
      <div className="flex item-center gap-[0.5vw] self-start text-[#1BA94C] font-bold text-[1.1vw] mt-3 mx-5">
       <p>Classwise Validation Statistics</p> 
        {requestType ===1 &&  <IICon 
                message="This graph shows classwise validated paras between users for the data which have currently not undergone multiuser voting.
                    If user has not validated any new paragraphs then this graph will be empty"
        />}
        </div>

      <div className='flex items-center gap-[1vw]'>
      <p>{showExpandMesssage && "(Showing only 10 classes)"}</p>
      <Popover
      content="Expand"
      >

      <BsArrowsAngleExpand 
      className='cursor-pointer hover:scale-110 mr-[0.5vw]' 
      size={17} color={"#777777"}
      onClick={()=>setShowModal(true)} />
      </Popover>
      </div>


        <Modal open={showModal} 
        footer={null}
         width={'90vw'}
          className="custom_scroll !p-[1vw]"
          onCancel={()=>setShowModal(false)}>
          {/* <div className="self-start text-[#1BA94C] font-bold text-[1.1vw] p-[1vw]">Classwise Validation Statistics</div> */}
          <div className=' flex flex-col justify-center items-center'>
         {/* { <Chart 
            options={pieChartOptions.options}
               width={650} 
               height={getChartHeight()}
               type={"bar"}
                series={pieChartOptions.series} />} */}

                <ExpandedClasswiseSummary 
                isOpen={showModal}
                setIsOpen={setShowModal}
                pieChartOptions={pieChartOptions}
                nodes={nodes}
                loading={false}
                />
            <div className='p-4 w-1/2 mx-auto'> 
            <Slider min={1} max={max} value={users}  onChange={(num) => setUsers(num)} marks={marks} />
            <div className='text-center'>Number Of Users</div>
        </div>
          </div>
        </Modal>
      </div>


        <div className='w-full mt-5 flex justify-center items-center '>

      <Chart 
      height={"450"}
      width={550}
      type={"bar"} 
      options={{
        ...pieChartOptions.options,
        xaxis: {
          ...pieChartOptions?.options?.xaxis
          , categories: pieChartOptions?.options?.xaxis?.categories?.slice(0, 10)
        }
      }}
      series={[{
        ...pieChartOptions.series[0],
        data: pieChartOptions.series[0]?.data?.slice(0, 10)
      }]}
         />
        </div>
         
         
       {max>1 && <div data-testid="slider" className='p-4 w-1/2 mx-auto'>
           <Slider min={1} max={max} value={users}  onChange={(num) => setUsers(num)} marks={marks} />
            <div className='text-center'>Number Of Users</div>
        </div>}
        </>
          
      }
    </div>
  )
}

export default MultiUserBarGraph;
