import React from "react";
import TopbarBilling from "../controls/TopbarBilling";

let AppLayout: React.FC = ({ children }) => {
    return (
        <div className="bg-gray-50 px-[3vw] pt-[6.5vw] pb-[1.5vw] rounded-md min-h-screen">
            <TopbarBilling />
            {children}
        </div>
    );
};

export default AppLayout;
