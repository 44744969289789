import React, { useEffect, useState } from 'react'
import { ReactComponent as DownChevrolet } from '../../../assets/down_chevrolet.svg'



interface IParameter {
    type:string,
    placeholder:string,
    values:any[],
    
    
}


interface ICategoricalProps{
    readOnly?:boolean,
    parameter:any
    value:any,
    onChange:any,
    options:any[],
    rankSet:Set<number> | null,
    testId?:string
}


function CategoricalInput({
   parameter,
   value,
   onChange,
   options,
   rankSet,
   readOnly,
   testId
}: ICategoricalProps) {

    const [open, setOpen] = useState<boolean>(false);
    //console.log("=== parameter ===",parameter);

    const handeStateChange = (value:any)=>{
        onChange(value);
    }

    const getValueName = (value:any)=>{
        if(value){
            return value;
        }else{
            return parameter.placeholder;
        }
    }



  
    return (
        <div className='w-full'>
            {
                open && <div className='h-screen w-screen fixed top-0 left-0 right-0 z-[45]'
                    onClick={() => { setOpen(false);  }}
                ></div>
            }

            {/*  Container  */}
            <div
                data-testid={testId}
                onClick={() => { if (!open && !readOnly ) { setOpen(true); } }}
                className={`w-full border-[0.15vw]  py-[0.5vw] px-[0.5vw] rounded-[0.3vw]  
                relative flex items-center justify-between 
                ${
                     open || readOnly?   "bg-gray-100 border-transparent" : "bg-white"
                    }
                ${
                     "cursor-pointer hover:bg-gray-100"}
                `}
            >

              
                              <p className='text-[1vw] select-none'>
                            {getValueName(value)}</p>

                <DownChevrolet className='w-[0.8vw] h-[0.8vw]' />


                {
                    <div className={`absolute  z-[49] left-0 top-[100%]
                        border rounded-md bg-white w-full duration-300 origin-top
                        ${open ? "" : " scale-y-0 opacity-0"}`}>

                        <ul className='bg-white custom_scroll max-h-[10.5vw]  shadow-lg rounded-b '>
                            {
                                options.length ? options.map((option: any) => {
                                    return (<li 
                                            data-testid={testId+"-"+option.id}
                                            key={option.id} 
                                            className={`flex `}
                                            onClick={()=>{
                                                
                                                if(option.id === value){
                                                    handeStateChange(null);
                                                    return;
                                                }
                                                if(rankSet?.has(option.id)) return;
                                                handeStateChange(option.id);
                                                setOpen(false);
                                                }}>
                                        <label className={`cursor-pointer select-none w-full px-[1vw] 
                                                                    flex items-center justify-start 
                                                                    hover:bg-gray-100 
                                                                    ${rankSet?.has(option.id) && option.id!== value?"bg-gray-100":""}`}>
                                        <div className='flex items-center justify-between'>
                                            <div className='
                                            h-[1.3vw] w-[1.3vw]
                                            border border-[0.15vw]  rounded-full p-[0.2vw]'>
                                                <div className={` 
                                                                h-full w-full rounded-full
                                                                ${option.id === value?"bg-primaryBlue":""}
                                                                ${rankSet?.has(option.id) && option.id!== value?"bg-gray-300":""}`}></div>
                                            </div>
                                            
                                        </div>
                                     

                                        <div className={` px-[1vw] w-[90%] py-[0.6vw]  
                                        ursor-pointer text-[0.9vw]
                                        
                                        `}>
                                        {option.text}</div>
                                        </label>
                                        
                                    </li>)
                                }) : <div className='p-[0.5vw] text-[1vw] text-center '>No Results</div>

                            }

                        </ul>
                    </div>
                }
            </div>
                {/* {error 
                && 
                <p className='text-[0.9vw] text-red-500 px-[0.4vw]'>
                    {error}</p>} */}
        </div>
    )
}

export default CategoricalInput

