import React, { useState } from "react";
import { useFormik } from "formik";
import InputField from "../controls/InputField";
import ErrorComp from "../controls/ErrorComp";
import { useNavigate } from "react-router-dom";
import {ReactComponent as LoadingWhite} from '../../assets/loading_white.svg';

interface Values {
    email: string;
    password: string;
    repassword:string;
   // recaptchaToken: string;
    firstName: string;
    company: string;
    lastName: string;
}

interface SignUpFormProps {
    signUpError: any,
    handleFormSubmit: any,
    setSignUpError: any
}

let SignUpForm: React.FC<SignUpFormProps> = ({ signUpError, handleFormSubmit, setSignUpError }) => {
    //  let [signUpError, setSignUpError] = useState({ show: false, msg: "" });
    let navigator = useNavigate();
    let validate = (values: Values) => {
        let regEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let errors: Partial<Values> = {};
        if (!values.email) {
            errors.email = "Required";
            // } else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(values.email) === false) {
        } else if (!regEmail.test(values.email)) {
            errors.email = "Please enter a valid email Id";
        }
        if (!values.password) {
            errors.password = "Required";
        }



        if (!values.company) {
            errors.company = "Required";
        }


        if (!values.firstName) {
            errors.firstName = "Required";
        }
        if (!values.lastName) {
            errors.lastName = "Required";
        }

      

        if(values.repassword !== values.password)
        {
            errors.repassword = "Confirm password must match password."
        }
        if(!values.repassword)
        {
            errors.repassword = "Required"
        }

        return errors;
    };
    let formik = useFormik<Values>({
        initialValues: {
            password: "",
            repassword:"",
            email: "",
            //recaptchaToken: "",
            firstName: "",
            lastName: "",
            company: "",
        },
        validate,
        onSubmit: handleFormSubmit,
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {/* <ErrorComp errorMsg={signUpError.msg} show={signUpError.show} onClose={() => setSignUpError({ show: false, msg: "" })} /> */}
                <div className="space-y-10">
                    <div className="text-center font-medium -mb-2 text-[1.1vw]">
                        Sign Up for the DataNeuron ALP
                    </div>

                    <div className="space-y-[1.8vw] ">
                    <ErrorComp errorMsg={signUpError.msg} show={signUpError.show} onClose={() => setSignUpError({ show: false, msg: "" })} />
                        <div className="flex space-x-3">
                            <div className="w-1/2">
                                <InputField
                                    placeholder="First Name"
                                    type="text"
                                    name="firstName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                    data-testid="firstName"
                                />
                                <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.firstName && formik.errors.firstName}</div>                    </div>
                            <div className="w-1/2">
                                <InputField
                                    placeholder="Last Name"
                                    type="text"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                    data-testid="lastName"
                                />

                                <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.lastName && formik.errors.lastName}</div>
                            </div>
                        </div>
                        <div>
                            <InputField
                                placeholder="Organisation Email Address"
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                data-testid="email"
                            />
                            <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.email && formik.errors.email}</div>
                        </div>
                        <div>
                            <InputField
                                placeholder="Organisation Name"
                                type="text"
                                name="company"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.company}
                                data-testid="company"
                            />

                            <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.company && formik.errors.company}</div>
                        </div>

                        <div>
                            <InputField
                                placeholder="Password"
                                type="password"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                data-testid="password"
                            />
                            <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.password && formik.errors.password}    </div>
                        </div>
                        <div>
                            <InputField
                                placeholder="Confirm Password"
                                type="password"
                                name="repassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.repassword}
                                data-testid="repassword"
                            />
                            <div className="absolute text-red-500 ml-[0.2vw] text-[0.75vw]">{formik.touched.repassword && formik.errors.repassword}    </div>
                        </div>

                    </div>
                    <div className="bg-primarycolor text-center rounded-[0.25vw] " >
                        <button className="w-full h-[3vw] cursor-pointer text-white text-[1vw]  "
                            disabled={formik.isSubmitting}
                            type={"submit"}
                            onClick={() => {
                                formik.submitForm();
                            }}
                        >
                            {formik.isSubmitting?<LoadingWhite width={"1.8vw"} height="2vw" />:"Sign Up"} 
                        </button>
                    </div>
                    <div className="!mt-[1vw] flex space-x-[0.5vw] text-[1vw]" >
                        <span>
                            Already a registered user?
                        </span>
                        <span className="underline font-semibold cursor-pointer" onClick={() => navigator("/signin")}>Sign In</span>
                    </div>
                </div>
            </form>
        </>
    );
};

export default SignUpForm;
