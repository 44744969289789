/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export let getWithoutNan = (val) => {
    if (isNaN(val)) {
      return 0;
    } else {
      return val;
    }
  };


  export function indexes(source, find) {
    if (!source) {
      return [];
    }
    if (!find) {
      return source.split("").map(function (_, i) {
        return i;
      });
    }
    var result = [];
    var i = 0;
    while (i < source.length) {
      if (source.substring(i, i + find.length) == find) {
        result.push([i, i + find.length]);
        i += find.length;
      } else {
        i++;
      }
    }
    return result.map((res) => {
      let b = res[0];
      let f = res[1];
  
      while (source[b] !== " ") {
        b -= 1;
        if (b == 0) {
          break;
        }
      }
  
      while (source[f] !== " ") {
        f += 1;
  
        if (f === source.length) {
          break;
        }
      }
  
      return [b + 1, f];
    });
  }