import React, { useEffect, useState } from 'react'
import Prompt from '../../shared/prompt/Prompt'
import {ReactComponent as LoadingWhite} from '../../../../assets/loading_white.svg';
import {AiFillPlusCircle} from 'react-icons/ai';
import Validations from './Validations';
import { Pagination } from 'antd';
import Edit from '../../shared/prompt/Edit';
import { addAnswer, annotatePrompt, getAnswers, getPromptsByPage, updatePrompt } from '../../_apiCalls';
import useWorkflowStore from '../../../../store/useWorkflowStore';
import AddNewAnswer from '../AddNewAnswer';
import useAuthStore from '../../../../store/authStore';
import { toast } from 'react-toastify';
import Loading from '../../shared/Loading';
import useIsPromptInProgress from '../../shared/useIsPromptInProgress';
import AutoValidationSwitch from '../../shared/AutoValidationSwitch';
import { getSortedAnswers } from '../../shared/helpers';

const PredictionButton =({text,loading,onClick,disabled,disabledTitle,styles,logoStyles}:any)=>{

    return (<button
      disabled={disabled}
      title={disabled && disabledTitle}
      onClick={!disabled ? onClick:()=>{}}
      className={`uppercase duration-200  w-[13vw]
            text-white text-[0.9vw] 
             h-[3vw] rounded-md border-[0.1vw] hover:scale-105 
             disabled:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400
            border-transparent bg-primaryBlue hover:drop-shadow-md 
            ${styles}  `}
    >
      {loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                      :  <div className='flex items-center justify-center px-[1vw] space-x-[0.5vw]'>
                      <AiFillPlusCircle
                      className={`${logoStyles} h-[1.5vw] w-[1.5vw] duration-200`} /> 
                      <span className='text-start w-full'>{text}</span>
                      </div>}
    </button>)
  
  }

const AddNewPromptButton =({text,loading,onClick,disabled,disabledTitle,styles}:any)=>{

    return (<button
      disabled={disabled}
      title={disabled && disabledTitle}
      onClick={!disabled ? onClick:()=>{}}
      className={`uppercase duration-200  w-[12vw]
            text-primaryBlue text-[0.9vw] 
             h-[3vw]  text-center rounded-md border-[0.1vw] hover:scale-105 
             disabled:opacity-75 disabled:cursor-not-allowed disabled:text-white disabled:border-transparent disabled:bg-gray-400
            border-primaryBlue  hover:drop-shadow-md 
            ${styles}  `}
    >
      {loading?<LoadingWhite className='w-[2vw] h-[2vw]' /> 
                      :  <div className='flex items-center justify-center space-x-[0.5vw]'>
                      <span>Add New Prompt</span>
                      </div>}
    </button>)
  
  }




function Index() {

  const workflow = useWorkflowStore(state=>state.currentWorkflow);
  const userDetails = useAuthStore(state=>state.userDetails);
  
  const [prompt,setPrompt] = useState<any>({});
  const [answers,setAnswers] = useState<any[]>([]);
  const [validations,setValidations] = useState<any[]>([]);
  const [predictionLoading,setPredctionLoading] = useIsPromptInProgress({workflow:workflow || {},promptId:prompt.promptId});

  const lastPage = workflow?.listviewInfo?.lastAccessedPage || 1;
  const [page,setPage]= useState<number>(lastPage);
  const [total,setTotal]= useState<number>(0);

  const [rankSet,setRankSet] = useState<Set<number>>(new Set<number>([]));

  const [addingNewAnswer,setAddingNewAnswer] = useState<boolean>(false);

  const [active,setActive] = useState<number>(0);
  const [addingNewPrompt,setAddingNewPrompt] = useState<boolean>(false);

  const [isEditable,setIsEditable] = useState<boolean>(false);
  const [isPromptInEdit,setIsPromptInEdit] = useState<boolean>(false);

  const [loading,setLoading] = useState<boolean>(true);
  //const [predictionLoading,setPredctionLoading] = useState<boolean>(false);
  const [isPredicted,setIsPredicted] = useState<boolean>(false);

 

  const model = workflow?.alpdsInfo?.meta?.llmType || "cohere";
  const hasAnswers = answers.length>0;



  const updateRanks =(validations:any[])=>{
    let newSet = new Set<number>();

    validations.forEach((val:any)=>{

      if(val.rank){
        newSet.add(val.rank);
      }

    })
   // console.log("=== rank set ===",newSet);
    setRankSet(newSet);

  }

  // const getSortedAnswers = (arr:any[])=>{
  //   if(!arr || arr.length===0){
  //     return [];
  //   }

  //   arr.sort((v1:any,v2:any)=>{
  //     if(v1?.source === "user") return -1;

  //     if(v2?.source === "user") return -1;

  //     return 1;


  //   });

  //   return arr;

  // }

  const resetStates = ()=>{
    setPrompt({});
    setRankSet(new Set([]));
    setValidations([]);
    setAnswers([]);
    setIsEditable(false);
    setAddingNewAnswer(false);
    setPredctionLoading(false);
    setActive(0);
    setIsPredicted(false);
  }


  const fetchData = async(pageNumber:number)=>{
    if(!workflow) return;
    if(!userDetails) return;
    setLoading(true);
    let res = await getPromptsByPage(workflow?.workflowId,pageNumber);

    if(!res.error){

     // console.log(res);

      
      setTotal(res.data.total);
      if(res.data.total===0){
        setLoading(false);
        setAddingNewPrompt(true);

        return;
      }
      if(res.data.prompt?.alpdsInfo?.uploadType["0"].hasOwnProperty("uploadedBy")){
        setIsEditable(true);
      }
      setPrompt({
        promptId:res.data.prompt.promptId,
        prompt:res.data.prompt.prompt
      })

     let data:any[] = res.data.prompt.data;
      if(data && data?.length){
        for(let i=0;i<data.length;i++){
          if(data[i]?.source !== "user"){
            setIsPredicted(true);
            break;
          }
        }
      }
      setAnswers(getSortedAnswers(res.data.prompt.data));
      let prompt_data:any = res.data?.prompt;
      if(prompt_data.annotation_data?.validationCount>0){
        if(prompt_data.annotation_data.validations
              ?.hasOwnProperty(userDetails?.userId))
            
              setValidations(prompt_data.annotation_data
                                ?.validations[userDetails
                                ?.userId]);
              
              updateRanks(prompt_data.annotation_data
                ?.validations[userDetails
                ?.userId]);

          
      }else{
        updateRanks([]);
      }

    }
    setLoading(false);
  }
  useEffect(()=>{
    fetchData(page);
  },[]);
  useEffect(()=>{
    // fetchData(); 

    return ()=>resetStates();
  },[page]);

  useEffect(()=>{
    console.log("=== prompt hook updated ===",predictionLoading);
    if(!predictionLoading && !loading){
      fetchData(page); 
    }
  },[predictionLoading])



  if(loading){
    return <Loading />
  }

  if(addingNewPrompt){
    return <Edit
    canGoBack={total>0}
    setEditState={setAddingNewPrompt}
    callback={async()=>{
      setAddingNewPrompt(false);
      setPage(total+1);
      await fetchData(total+1);
      // setTotal(total+1);
      // setPage(total+1);
    }} />
  }

  return (
    <div className='space-y-[1vw] bg-white px-[1vw] pb-[1vw]'>
        {/* Prompt */}
        <div>
           <Prompt
           promptId={prompt.promptId}
           text={prompt.prompt}
           editable={isEditable}
           edit={isPromptInEdit}
          //  Functions here
           setText={(text:string)=>{
            setPrompt({...prompt,prompt:text})
           }}
           setEditState ={setIsPromptInEdit}

           savePrompt ={async(text:string)=>{
              if(!workflow) return;
              await updatePrompt(workflow?.workflowId,prompt.promptId,text);
           }}


           isPredicted={isPredicted || hasAnswers}
           predictionLoading={predictionLoading}
           predictOnPrompt={async()=>{
             if(!workflow) return;
              setPredctionLoading(true);
              setAddingNewAnswer(false);
              let {error,answers:resAnswers} = await getAnswers(workflow?.workflowId,
                                                          prompt.promptId,
                                                          prompt.prompt,
                                                            model,
                                                            "ranking");
              if(error) {
                toast.error(error);
                setPredctionLoading(false);
                return;
              }
              setIsPredicted(true);
              //setAnswers([...resAnswers]);
              setPredctionLoading(false);
              updateRanks([]);
           }}
           
           />
        </div>

        {/* Generate Predictions */}
        <div className='border-t border-b py-[0.5vw] w-full flex items-center justify-between'>
            <PredictionButton
            disabled={predictionLoading}
            text={addingNewAnswer?"Close":"Add New Answer"}
            logoStyles={addingNewAnswer?"rotate-45":""}
            styles={addingNewAnswer?"!bg-white !text-primaryBlue border border-primaryBlue":""}
            onClick={()=>setAddingNewAnswer(!addingNewAnswer)}
            />

            <AutoValidationSwitch 
            predictionLoading={predictionLoading}
            />
        </div>

        {/* Answer */}
        <div className='space-y-[1vw] min-h-[40vh]'>
           
           {
            addingNewAnswer && <AddNewAnswer 
            answers={answers}
            rankSet={rankSet}
            saveAnswer={async(ans:any,callback:any)=>{
              if(!ans.text || ans.text.length<3){
                toast.warn("The answer is too short");
                return;
              }
              if(rankSet.has(ans.rank)){
                toast.warn("The Rank is already taken!");
                return;
              }
              let temp = [ans,...answers];
              let tempValidations = [{...ans},...validations]
              // console.log("Old Answers",answers);
              // console.log("New Answer",temp);
              // console.log("Old Validations",answers);
              // console.log("New Validations",temp);
             
              setAnswers(temp);
              setValidations(tempValidations);
              updateRanks(tempValidations);
              if(callback) callback();

              if(!workflow) return;
              await addAnswer(workflow.workflowId,prompt.promptId,ans)
              
            }}            
            />
           }

          {
            answers.map((ans:any,index:number)=>{
              //console.log("answer and validation",ans,validations)
              return  <Validations 
              readOnly={predictionLoading}
              key={ans.responseId}
              active={active === index}
              setActive={setActive}
              index={index} 
              answer={ans}
              answers={answers}
              rankSet={rankSet}
              setValidation={async(validation:any)=>{
                //console.log("insde update validation",validation);
                let tempValidations =[];
                let found = validations.find((val:any)=>val.responseId === validation.responseId);

                if(!found){
                  // console.log("=== could not find validation data ===",{
                  //   responseId:ans.responseId,
                  //   ...validation
                  // })
                  tempValidations = [...validations,{
                    responseId:ans.responseId,
                    ...validation
                  }];
                }else{
                  tempValidations = validations.map((val)=>{
                    if(val.responseId === validation.responseId){
                      return validation;
                    }
                    return val;
                  });

                }
               // console.log("=== new validations ===",tempValidations,ans);
                setValidations(tempValidations);
                updateRanks(tempValidations);
                if(!workflow) return;
                await annotatePrompt(workflow?.workflowId,
                                prompt.promptId,
                                tempValidations);


              }}
              
              validation={validations?.find((v:any)=>v.responseId === ans.responseId) || null}
              />
            })
          }
           
          
        </div>

        {/* Pagination */}
        <div className='flex items-center space-x-[1vw]'>
          <Pagination 
          pageSize={1}
          current={page}
          total={total}
          showQuickJumper={true}
          className='text-[1vw]'
          onChange={p=>{
            setPage(p)
            fetchData(p)
          }}
          disabled={predictionLoading}
          />
        <AddNewPromptButton
        disabled={predictionLoading}
        onClick={()=>setAddingNewPrompt(true)}
        />
          

        </div>

    </div>
  )
}

export default Index