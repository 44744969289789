import * as React from "react";
import { useState } from "react";
import { /*Upload,*/ Modal, Progress } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "../utils/Axios";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import AppLayout from "../components/layouts/AppLayout";
import PageLayout from "../components/layouts/PageLayout";
import useAuthStore from "../store/authStore";
import useWorkflowStore from "../store/useWorkflowStore";
import './index.css';
import AccessControl from "../components/AccessControl/AccessControl";
import useMasterlistSummary from "../store/useMasterlistSummary";

import { AiOutlineCloudDownload } from 'react-icons/ai';
import DownloadTemplates from "../components/DownlaodTemplates";
import template from "../template";

export interface UloadedFile {
    extension: string;
    fileId: string;
    fileName: string;
    filePath: string;
    practiceAreaId: string;
    size: number;
    status: string;
    uploadedBy: string;
    _id: string;
}

const UploadPage: React.SFC = () => {

   const workflow = useWorkflowStore(state=>state.currentWorkflow);
   const project = useWorkflowStore(state=>state.currentProject);

   const projectType = project?.projectType || "";
   const workflowType = project?.workflowType || "";

   const UploadComp = projectType 
                        && workflowType
                         && template[projectType][workflowType]?.upload;

    console.log("=== upload comp ===",UploadComp);


    return (
        <AppLayout>
            <PageLayout pageTitle={`${project?.name} (${workflow?.workflowName})`} pageSubHeading="upload" pageIconUrl="/images/active_upload.svg">
                {UploadComp && React.createElement(UploadComp.component)}
            </PageLayout>
        </AppLayout>
    );
};

export default UploadPage;
