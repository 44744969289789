import React from 'react'
import Button from '../mlc-components/model-comparison/Button'
import Header from './Header'

type Props = {
    projects: any[],
    selectedProject:string | null,
    setProject:any,

    setStep:any
}

type CardProps={
    project:any,
    active:boolean,
    setProject:any
  
}

const Card = ({project,active,setProject}:CardProps) =>{
    return <div className={`w-[25vw] h-[12vw] 
                            bg-white cursor-pointer
                            p-[1vw]
                            shadow-md hover:shadow-lg
                            border
                            select-none
                            hover:scale-105
                            duration-200
                            ${active?"border-primaryBlue":"border-transparent"}`}
                onClick={()=>setProject(project.id)}            
                >
            <div className=''>
                {
                    project.icon && React.createElement(project.icon.comp,{
                        className:`w-[60px] h-[60px] ${project.icon.styles ?? ""}`
                    })
                }
            </div>
            
            <div 
            className='text-[1.2vw]'
            >{project.name}</div>

            <p className='mt-[0.3vw] text-[0.9vw]'>{project.description}</p>
        </div>
}



function ChooseProjectType({projects,selectedProject,setProject,setStep}: Props) {



    
  return (
    <div className='flex flex-col'>
        <Header 
        title={"Choose a project type"}
        onClick={null}
        canGoBack={false}
        />
        <div className='py-[2vw] bg-white shadow-md p-[2vw] min-h-[72vh]  mt-[2vw]'>
        <div className='grid grid-cols-3 gap-[3vw]'>

                {
                    projects.map((p)=>{
                        return <Card 
                        active={p.id === selectedProject}
                        project={p}
                        setProject={setProject}
                        
                        />
                    })
                }
        </div>
        <div className='flex items-center justify-end '>
            <div className='mt-[50px]'>

                <Button
                text={"Next"}
                styles={"!w-[18vw]"}
                onClick={()=>setStep(1)}
                disabled={!selectedProject}
                />
            </div>
        </div>
        </div>

    </div>
  )
}

export default ChooseProjectType