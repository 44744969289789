
import { RevertToStage2 } from './_apiCalls';
import useWorkflowStore from '../../../store/useWorkflowStore';
import { useNavigate } from 'react-router-dom';
import { CallNextBatch } from '../../stage-2/helper';
import Button from '../../mlc-components/model-comparison/Button';
import { useState } from 'react';

function ConfirmIterateModel({open,setOpen,callBatch,resetStage,hasStages}:any) {

    const workflow = useWorkflowStore(state=>state.currentWorkflow);
    const navigate = useNavigate();
    const [loading,setLoading] = useState<boolean>(false);


  return (
    <div className='flex justify-end items-center gap-[0.5vw]'>
      <button
      className='w-[6vw] h-[3vw] rounded-[0.2vw] text-[0.9vw]
      cursor-pointer border-transparent border
      hover:scale-105 hover:border-primaryBlue 
      duration-200
      '
      onClick={()=>!loading && setOpen(false)}
      >Cancel</button>
      <Button
      styles={"!w-[8vw]"}
      text="Confirm"
      loading={loading}
      onClick={async()=>{
        if(!workflow) return
        setLoading(true);
      resetStage && await RevertToStage2(workflow.workflowId);
      callBatch &&  await CallNextBatch(workflow);
       setTimeout(()=>{
        setLoading(true);
         navigate(`/workflow/${hasStages?"stage2":"validation"}?workflowId=`+workflow.workflowId);        
       },1000);
      }}
      >
        Confirm
      </Button>
      {/* <button 
      className='w-[6vw] py-[0.5vw] rounded-[0.2vw] text-[0.9vw]
                  bg-primaryBlue text-white
                  cursor-pointer
                  hover:scale-110 duration-200
                  '
      onClick={async()=>{
        if(!workflow) return
       await RevertToStage2(workflow.workflowId);
       await CallNextBatch(workflow);
       setTimeout(()=>{
         navigate("/workflow/stage2?workflowId="+workflow.workflowId);        
       },1000);
      }}
      >Confirm</button> */}
    </div>
  )
}

export default ConfirmIterateModel