import { Slider } from 'antd'
import { parse } from 'path'
import React, { useEffect, useState } from 'react'


interface IParameter {
      paramName: string,
      paramStartValue: number,
      paramEndValue: number,
      paramValue:any,
      paramType: "Numerical" | "Categorical"
}

interface IRangeProps {
    parameter:IParameter,
    onChange:any,
    min:number,
    max:number,
    error?:string,
    isFloat?:boolean
    addError?:any,
    removeError?:any,
    

}

const InputBox = ({
                    setValue,value,
                min,max,isFloat,onBlur,floatPoint}:any)=>{


    
    return <div className='bg-white w-full h-[2.7vw] 
                             rounded-[0.25vw] 
                             flex items-center justify-center
                             
                             '>
       
   <input 
   className='w-full h-full focus:outline-none rounded-[0.25vw]  text-[1vw] 
                border-2  px-[0.5vw] peer
                invalid:border-red-200
                '
   onBlur={onBlur}
   value={value}
   type="number"
   onChange={(e)=>{
    console.log("before onchange",e.target.value,typeof e.target.value)
    setValue(e.target.value)
}}
   step={isFloat?floatPoint:1}
   required
   min={min}
   max={max}
   />

    </div>
}


export default function RangeInput({
    parameter,
    onChange,
    error,
    addError,
    min,
    max,
    isFloat}:IRangeProps) {

    const [value,setValue] = useState<string>( parameter?.paramValue + "" );
    const floatPoint = parameter.paramName === "learning_rate"?0.000001:0.01 ;
    const checkInputs = (value:number,paramName:string)=>{
        console.log("=== checking for error ===");
        //removeError(paramName);
        if(Number.isNaN(value)){
            addError("All fields are required",paramName);
            return;

        }
        
        if(value<=min || value>max){
            addError(`Input should between ${min} and ${max}`,paramName );
            return;

        }
        addError(null,paramName)
       

    }
   
    
    const handleInputChange = ()=>{

        let minValue=0;
        
        
        if(isFloat){
            let fixedLength = parameter.paramName === "learning_rate"?6:2
            minValue = parseFloat(parseFloat(value+"").toFixed(fixedLength));
           
        }else{
            minValue = parseInt(value+"");
        }
        // const temp:any[] = [minValue,maxValue];
        // console.log("=== values ===",temp)
        setValue(minValue + "")
        onChange({
            ...parameter,
            paramValue:minValue,
            
        })
        
    }

    useEffect(()=>{
        console.log("=== calling add error ===");
        
       addError &&  checkInputs(+value,parameter.paramName);
    },[value]);

    useEffect(()=>{ 
        if(parameter.paramValue && parameter.paramValue == value){
            
        }else{
            setValue(parameter.paramValue+"")
        }

    },[parameter.paramValue])

  return (
    <div className=''>
        <p className='uppercase text-[0.8vw] font-medium mb-[1vw] '>
            {parameter.paramName.replace("_"," ")}
        </p>
           
            
            <div className='flex items-center justify-start  w-full'>
            <InputBox
            value={value}
            setValue={(v:string)=>setValue(v)}
            min={min}
            max={max}
            isFloat={isFloat}
            onBlur={()=>handleInputChange()}
            floatPoint ={floatPoint}

            />
           

        </div>
        {error && <p className='text-[0.9vw] text-red-500 px-[0.4vw]'>{error}</p>}
    </div>
  )
}
