import React, { useContext } from 'react'
import { toast } from 'react-toastify';
import useEntityStore from '../../../store/entityStore';

interface IRawText{
    text:string[],
    topOffset:number,
    paraData:string,
    isActive:boolean,
    paraId:string
}


export default function RawText({text,topOffset,paraData,isActive,paraId}:IRawText) {

  const setSelection = useEntityStore(state=>state.setSelection);
  const activeSlice = useEntityStore(state=>state.activeSliceOffset);
  const setActiveSlice = useEntityStore(state=>state.setActiveSliceOffset);

  const sanitizeSelectionData = (start:number,end:number)=>{
    if(start === null || end === null) return null;
    console.log("sanizing selection data",start,end);
    console.log("=== before sanitization ===",paraData.slice(start+topOffset,end+topOffset));
    console.log("==== slice info ====",topOffset);
    end-=1;
    console.log(text[start]);
    console.log(text[end]);
    //console.log(paraData);
    try{
        if(start === null || end === null) throw Error("invalid values");
        if(start >= end) throw Error("start and end cannot be same");
        if(text[start] === " " || text[start] === "." || text[start] === "," ){
          
          start+=1;
        } 
        if(text[end] === " " || text[end] === "." || text[end] === ","){
          end-=1;
        }

        //sanitize start index
        let i =0;
        console.log("starting start while loop")
        while(true){
          if(i>20) break;
          i++;
          if(start<1) break;
          if(text[start-1] === " ") break;
          if(text[start-1] === ",") break;
          if(text[start-1] === ".") break;
          start--;
        }

        //sanitize end index
        i=0;
        console.log("starting end while loop")
        while(true){
          if(i>20) break;
          i++;
          if(end === text.length-1) break;
          if(text[end+1] === ",") break;
          if(text[end+1] === " ") break;
          if(text[end+1] === ".") break;
          end++;
        }

        console.log("=== sanitized selection  ===",text.slice(start,end+1))
        return {start,end:end+1}


    }catch(e){
      console.log("Invalid selection",e);
      return null;
    }



  }

  const handleMouseUp = ()=>{

    if(activeSlice !== topOffset) {
      console.log("=== offset didn't match ===");
      return null
    };
    
    console.log("=== mouse up offset ===",topOffset);
    let text = "";
    let selectedTextData:any={}
      if (window.getSelection) {
        //@ts-ignore
        text = window.getSelection().toString();
        // console.log("=== selection ===",window.getSelection().anchorOffset,window.getSelection().focusOffset , topOffset);

        
        let selectionData = window.getSelection();
        if(!selectionData || window.getSelection()?.type!=="Range"){
            //toast("invalid selection")
            return;
        }
        if(selectionData.anchorOffset === selectionData.focusOffset){
            return;
        }
        let start = selectionData.anchorOffset < selectionData.focusOffset 
                                                ?selectionData.anchorOffset:selectionData.focusOffset;
        let end = selectionData.anchorOffset > selectionData.focusOffset 
                                                ?selectionData.anchorOffset:selectionData.focusOffset;

       const result:any =  sanitizeSelectionData(start
                            ,end);

        if(result){

          selectedTextData = {
            paraId:paraId,
            startIndex :result.start + topOffset,
            endIndex:result.end + topOffset,
            word: text
          }
         // console.log("=== selection object ===",window.getSelection());
          console.log("=== selection data ==="
          ,selectedTextData
          ,topOffset
          ,paraData
          .slice(selectedTextData.startIndex,
              selectedTextData.endIndex));
  
          setSelection(selectedTextData);
        }else{
          setSelection(null);
        }
      } 

    

      //if(text.length) nerStore.setSelectedText(selectedTextData);

  }

  const handleMouseDown =()=>{
    console.log("=== mouse down offset ===",topOffset);
    setActiveSlice(topOffset);
    setSelection(null);

  }



  return (
    <span
    onMouseUp={()=>isActive &&  handleMouseUp()}
    onMouseDown={handleMouseDown}
    
    >
        {text}
    </span>
  )
}
