import { useEffect } from 'react';
import useWorkflowStore from '../store/useWorkflowStore';
import { toast } from "react-toastify";
import useVersionStore from '../store/versionStore';
import { useNavigate } from 'react-router-dom';

interface VersionProcessProps {
    selectedStages: any
    uploadCompleted: boolean;
    masterlistCompleted: boolean;
    validateCompleted: boolean;
    trainCompleted: boolean;
    inProgress: string;
    forkInProcess: boolean;
}

const disabledStyle = "bg-white text-[#C4C4C4] border-white";
const inProgressStyle = "bg-white text-[#1BA94C] border-[#1BA94C]";
const completedStyle = "bg-[#1BA94C] text-white border-[#1BA94C]"
const notReachedStyle = "bg-white text-[#11256D] border-[#11256D]"

const disabledText = "text-[#C4C4C4]";
const greenText = "text-[#1BA94C]";
const notReachedText = "text-[#11256D]";



const VersionProcess: React.FC<VersionProcessProps> = ({ selectedStages, uploadCompleted, masterlistCompleted, validateCompleted, trainCompleted, inProgress, forkInProcess }) => {
    let workflow = useWorkflowStore().currentWorkflow;
    const containsTrain = workflow?.stages.find((stage:any)=>stage.name==="train")

    const listOfVersion = useVersionStore(state=>state.listOfVersion);
    const navigate = useNavigate();

    

    useEffect(() => {
        console.log("updated workflow on Upload Change", workflow);

        if (workflow?.forkInfo?.upload === true) {
            // setUploadCompleted(true);
            toast("Upload stage forked successfully.", {
                type: "info",
            });
            // setProgressStatus("MASTERLIST");
        }
    }, [workflow?.forkInfo?.upload]);

    useEffect(() => {
        console.log("updated workflow on masterlist Change", workflow);

        if (workflow?.forkInfo?.masterlist === true) {
            // setMasterlistCompleted(true);
            toast("Masterlist stage forked successfully.", {
                type: "info",
            });
            // setProgressStatus("VALIDATE");
        }
    }, [workflow?.forkInfo?.masterlist]);
 

    useEffect(() => {
        console.log("updated workflow on validate Change", workflow);

        if (workflow?.forkInfo?.validate === true) {
            // setValidateCompleted(true);
            toast("Validate stage forked successfully.", {
                type: "info",
            });
            // setProgressStatus("TRAIN");
        }
    }, [workflow?.forkInfo?.validate]);


    // useEffect(() => {
    //     console.log("updated workflow on train Change", workflow);

    //     if (workflow?.forkInfo?.train === true) {
    //         // setTrainCompleted(true);
    //         toast("Train stage forked successfully.", {
    //             type: "info",
    //         });
    //         // setProgressStatus("NULL");
    //     }
    // }, [workflow?.forkInfo?.train]);

    return <>
        <div className="text-[3vw] text-[#9A9A9A] text-center my-[3vw]">Forking Workflow...</div>
        <div className="mb-[6vw] mt-[2vw] flex justify-center space-x-[5vw]">
            <div className="flex space-x-[1.2vw] items-center justify-center">
                <div className={
                    `text-[1.2vw] font-semibold flex items-center 

                    ${selectedStages.upload
                        ? (inProgress === "UPLOAD"
                            ?
                            inProgressStyle
                            : (uploadCompleted
                                ? completedStyle
                                : notReachedStyle))
                        : disabledStyle} 
                    border-2  justify-center  rounded-full w-[3.3vw] h-[3.3vw]`}
                >
                    1
                </div>
                <div className={
                    `text-[1.1vw] font-semibold
                    ${selectedStages.upload
                        ? ((uploadCompleted || inProgress == "UPLOAD") ? greenText : notReachedText)
                        : disabledText
                    }`
                }
                >
                    UPLOAD
                </div>
            </div>

            <div className="flex space-x-[1.2vw] items-center justify-center">

                <div className={
                    `text-[1.2vw] font-semibold flex items-center 

                    ${selectedStages.masterlist
                        ? (inProgress === "MASTERLIST"
                            ?
                            inProgressStyle
                            : (masterlistCompleted
                                ? completedStyle
                                : notReachedStyle))
                        : disabledStyle} 
                    border-2  justify-center  rounded-full w-[3.3vw] h-[3.3vw]`}
                >
                    2
                </div>

                <div className={
                    `text-[1.1vw] font-semibold 
                    ${selectedStages.masterlist
                        ? ((masterlistCompleted || inProgress == "MASTERLIST") ? greenText : notReachedText)
                        : disabledText
                    }`
                }
                >
                    MASTERLIST
                </div>

            </div>

            <div className="flex space-x-[1.2vw] items-center justify-center">

                <div className={
                    `text-[1.2vw] font-semibold flex items-center 

                    ${selectedStages.validate
                        ? (inProgress === "VALIDATE"
                            ?
                            inProgressStyle
                            : (validateCompleted
                                ? completedStyle
                                : notReachedStyle))
                        : disabledStyle} 
                    border-2  justify-center  rounded-full w-[3.3vw] h-[3.3vw]`}
                >
                    3
                </div>

                <div className={
                    `text-[1.1vw] font-semibold
                    ${selectedStages.validate
                        ? ((validateCompleted || inProgress == "VALIDATE") ? greenText : notReachedText)
                        : disabledText
                    }`
                }
                >
                    VALIDATE
                </div>


            </div>

          {/* {containsTrain && <div className="flex space-x-[1.2vw] items-center justify-center">

                <div className={
                    `text-[1.2vw] font-semibold flex items-center 

                    ${selectedStages.train
                        ? (inProgress === "TRAIN"
                            ?
                            inProgressStyle
                            : (trainCompleted
                                ? completedStyle
                                : notReachedStyle))
                        : disabledStyle} 
                    border-2  justify-center  rounded-full w-[3.3vw] h-[3.3vw]`}
                >
                    4
                </div>

                <div className={
                    `text-[1.1vw] font-semibold
                    ${selectedStages.train
                        ? ((trainCompleted || inProgress === "TRAIN") ? greenText : notReachedText)
                        : disabledText
                    }`
                }
                >
                    TRAIN
                </div>
            </div>} */}
        </div>
        {
            forkInProcess &&
            <div className='flex justify-center py-[5vw]'>
                <img className='animate animate-spin w-[20vw] h-[20vw]' src="/images/loading_circle.svg" alt="Loading" />
            </div>
        }
    </>

}

export default VersionProcess;