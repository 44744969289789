import React, { useState, useEffect } from "react";
import { DeleteFilled } from '@ant-design/icons';
import Axios from "../utils/Axios";
import useWorkflowStore from "../store/useWorkflowStore";
import { useNavigate } from "react-router-dom";
import useProjectStore from "../store/projectStore";
import useAuthStore from "../store/authStore";
import AccessControl from "./AccessControl/AccessControl";
import { TrainStatus, checkCurrentTrainStatus } from "../utils/navigationHelper";
import { current } from "immer";
import ConfirmationModal from "./Fork/ConfirmationModal";

interface CollapsibleComponent {
  index: number;
  version: any;
  activeIndex: number;
  collapseClickHandler: any;
  createClickHandler: any,
}
let CollapsibleComponent: React.FC<CollapsibleComponent> = ({ index, version, activeIndex, collapseClickHandler, createClickHandler }) => {
  const [upload, setUpload] = useState(false);
  const [masterlist, setMasterlist] = useState(false);
  const [validate, setValidate] = useState(false);
  const [train, setTrain] = useState(false);

  const [isModelOpen,setIsModelOpen] = useState<boolean>(false);

  let workflowStore = useWorkflowStore();
  let project = workflowStore.currentProject;
  const workflow = workflowStore.currentWorkflow;

  let authStore = useAuthStore();
  const navigator = useNavigate();
  const handleupload = (event: any) => {
    setUpload(true);
    setMasterlist(false);
    setValidate(false);
    setTrain(false);
  }
  const handleMasterlist = (event: any) => {
    setUpload(true);
    setMasterlist(true);
    setValidate(false);
    setTrain(false);
  }
  const handleValidate = (event: any) => {
    setUpload(true);
    setMasterlist(true);
    setValidate(true);
    setTrain(false);
  }
  const handleTrain = (event: any) => {
    setUpload(true);
    setMasterlist(true);
    setValidate(true);
    setTrain(true);
  }

  const deleteClickHandler = () => {
    // To be Discussed Later
  }
  const selectHandler = () => {
    //Navigate to Selected Workflow Id
    const workflowId = workflowStore.currentWorkflow?.workflowId;
    navigator(`/workflow?workflowId=${version.workflowId}`);
    //window.location.reload();
  }

  const CreateHandler = async()=>{

    // createClickHandler(version, upload, masterlist, validate, train);
    // return;

    if(!validate){
      //alert("normal fork");
      createClickHandler(version, upload, masterlist, validate, train);
      return;
    }

    const currentStatus = checkCurrentTrainStatus(version);
    console.log("Checking for version",version);
        const llmProgress = workflow?.alpdsInfo?.percentageStatus?.automlProgress?.llmProgress;
        if((currentStatus !== TrainStatus.Trained
          && currentStatus !== TrainStatus.IsInLLMModelTrainig
          )){
         // alert("Normal Forking upto validate")  
        createClickHandler(version, upload, masterlist, validate, train);

        }else{
          // alert("Showing Confirmation Model")
          setIsModelOpen(true);
        }


  }

  const callWorkflowForkApi = async(copyModel:boolean):Promise<void>=>{

    await createClickHandler(version, upload, masterlist, validate, copyModel);    

  }


  const cancelClickHandler = () => {
    /** Cancel Selections and Close Div */
    console.log("=== cancel button was called ===")
    collapseClickHandler(-1);
    setUpload(false);
    setMasterlist(false);
    setValidate(false);
    setTrain(false);
  }

  const NoFork = () => {
    return(
      <div className="flex items-center space-x-2 justify-center">
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" className="fill-[#CC0000]" width='1.1vw' height='1.1vw' viewBox="0 0 24 24"><path d="M21 3c0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.323.861 2.433 2.05 2.832.168 4.295-2.021 4.764-4.998 5.391-1.709.36-3.642.775-5.052 2.085v-7.492c1.163-.413 2-1.511 2-2.816 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.305.837 2.403 2 2.816v12.367c-1.163.414-2 1.512-2 2.817 0 1.657 1.343 3 3 3s3-1.343 3-3c0-1.295-.824-2.388-1.973-2.808.27-3.922 2.57-4.408 5.437-5.012 3.038-.64 6.774-1.442 6.579-7.377 1.141-.425 1.957-1.514 1.957-2.803zm-16.8 0c0-.993.807-1.8 1.8-1.8s1.8.807 1.8 1.8-.807 1.8-1.8 1.8-1.8-.807-1.8-1.8zm3.6 18c0 .993-.807 1.8-1.8 1.8s-1.8-.807-1.8-1.8.807-1.8 1.8-1.8 1.8.807 1.8 1.8zm10.2-16.2c-.993 0-1.8-.807-1.8-1.8s.807-1.8 1.8-1.8 1.8.807 1.8 1.8-.807 1.8-1.8 1.8z" /></svg>      
        </button>
      </div>
    )
  }
  return <>

    <ConfirmationModal 
    open={isModelOpen}
    setOpen={setIsModelOpen}
    onClick={callWorkflowForkApi}
    workflowDetails={version}
    />
    <div className={`flex justify-between w-full hover:bg-[#F4F4F4] py-[0.2vw] px-[1vw] ${activeIndex === index && 'bg-[#F4F4F4]'}`}>
      <div className="font-medium text-[1vw] cursor-pointer" onClick={selectHandler}>
        {activeIndex === index ? "Forking " + version.workflowName : version.workflowName}
      </div>

      
        {
          authStore.userDetails?.userId === project?.createdBy &&
          <AccessControl RestrictedComp={NoFork} RequestedPerm="canCreateWorkflows">
          <div className="flex items-center space-x-2 justify-center">
            <button onClick={() => { collapseClickHandler(index) }}>

              <svg xmlns="http://www.w3.org/2000/svg" className={` ${activeIndex === index ? `fill-[#1BA94C]` : `fill-[#7A7A7A]`}`} width='1.1vw' height='1.1vw' viewBox="0 0 24 24"><path d="M21 3c0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.323.861 2.433 2.05 2.832.168 4.295-2.021 4.764-4.998 5.391-1.709.36-3.642.775-5.052 2.085v-7.492c1.163-.413 2-1.511 2-2.816 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.305.837 2.403 2 2.816v12.367c-1.163.414-2 1.512-2 2.817 0 1.657 1.343 3 3 3s3-1.343 3-3c0-1.295-.824-2.388-1.973-2.808.27-3.922 2.57-4.408 5.437-5.012 3.038-.64 6.774-1.442 6.579-7.377 1.141-.425 1.957-1.514 1.957-2.803zm-16.8 0c0-.993.807-1.8 1.8-1.8s1.8.807 1.8 1.8-.807 1.8-1.8 1.8-1.8-.807-1.8-1.8zm3.6 18c0 .993-.807 1.8-1.8 1.8s-1.8-.807-1.8-1.8.807-1.8 1.8-1.8 1.8.807 1.8 1.8zm10.2-16.2c-.993 0-1.8-.807-1.8-1.8s.807-1.8 1.8-1.8 1.8.807 1.8 1.8-.807 1.8-1.8 1.8z" /></svg>      </button>
            {/* <button onClick={deleteClickHandler}>
            <img src="/images/delete.svg"
              style={{
                width: "1vw",
                height: "1vw",
              }}
              alt="delete" />
          </button> */}
          </div>
          </AccessControl>
        }
      
    </div>
    {
      activeIndex === index &&
      <div className={`bg-white flex flex-col gap-[1vw] py-[1vw] px-[1.2vw] `}>
        <div className="flex flex-col gap-1">
          <div className="font-thin text-black mb-[0.3vw] text-[0.9vw]">
            SELECT STAGE(s)
          </div>
          <div className="flex gap-2 items-center ">
            <input
              type="checkbox"
              checked={upload}
              onChange={handleupload}
              className="hover:cursor-pointer  disabled:cursor-not-allowed w-[1vw] h-[1vw]"
              disabled={!(version.stages[0].active === true)}
            />
            <div className="font-semibold text-[1vw]">Upload</div>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              checked={masterlist}
              onChange={handleMasterlist}
              className="hover:cursor-pointer  disabled:cursor-not-allowed w-[1vw] h-[1vw]"
              disabled={!(version.stages[1].active === true)}
            />
            <div className="font-semibold text-[1vw]">Masterlist</div>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              checked={validate}
              onChange={handleValidate}
              className="hover:cursor-pointer  disabled:cursor-not-allowed w-[1vw] h-[1vw]"
              disabled={!(version.stages[2].active === true || version.stages[3].active === true)}

            />
            <div className="font-semibold text-[1vw]">Validate</div>
          </div>
          {/* {version.stages[4] && <div className="flex gap-2 items-center">
            <input
              type="checkbox"
              checked={train}
              onChange={handleTrain}
              className="hover:cursor-pointer  disabled:cursor-not-allowed w-[1vw] h-[1vw]"
              disabled={!(version.stages[4].active === true)}
            />
            <div className="font-semibold text-[1vw]">Train</div>
          </div>
} */}

        </div>

        <div className="flex justify-between items-center">
          <button
            className="border border-primarycolor text-primarycolor hover:bg-primarycolor hover:text-white duration-700 px-[1.5vw] py-[0.25vw] outline-none rounded-[0.4vw]"
            type="button"
            onClick={() => CreateHandler()}
          >
            <p className="text-[0.9vw]">Create</p>

          </button>
          <button
            className="border border-[#E63946] bg-[#E63946] text-white hover:bg-white hover:text-[#E63946] duration-700 px-[1.5vw] py-[0.25vw]  outline-none rounded-[0.4vw]"
            type="button"
            onClick={() => cancelClickHandler()}
          >
            <p className="text-[0.9vw]">Cancel</p>
          </button>
        </div>
      </div>
    }
  </>
};

export default CollapsibleComponent;
