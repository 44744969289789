import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//import LogRocket from "logrocket";

//LogRocket.init('s9kb5e/dataneuron');


let siteKey= process.env.REACT_APP_RECAPTCH_SITE_KEY;
ReactDOM.render(
    <React.StrictMode>
       

        <App />
    
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
